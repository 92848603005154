import { useMediaQuery } from "react-responsive";
import { Breakpoints } from "../../enums";

// * Breakpoint specific hooks
export function useSmallPhoneMediaQuery() {
  return useMediaQuery({ maxWidth: Breakpoints.SM - 1 });
}
export function useRegularPhoneMediaQuery() {
  return useMediaQuery({
    minWidth: Breakpoints.SM,
    maxWidth: Breakpoints.MD - 1,
  });
}
export function useTabletMediaQuery() {
  return useMediaQuery({
    minWidth: Breakpoints.MD,
    maxWidth: Breakpoints.LG - 1,
  });
}
export function useDesktopMediaQuery() {
  return useMediaQuery({
    minWidth: Breakpoints.LG,
    maxWidth: Breakpoints.XL - 1,
  });
}
export function useLargeDesktopMediaQuery() {
  return useMediaQuery({
    minWidth: Breakpoints.XL,
    maxWidth: Breakpoints.XXL - 1,
  });
}
export function useExtraLargeDesktopMediaQuery() {
  return useMediaQuery({
    minWidth: Breakpoints.XXL,
  });
}

// * Breakpoint and below hooks
export function usePhoneAndBelowMediaQuery() {
  return useMediaQuery({ maxWidth: Breakpoints.MD - 1 });
}
export function useTabletAndBelowMediaQuery() {
  return useMediaQuery({ maxWidth: Breakpoints.LG - 1 });
}
export function useDesktopAndBelowMediaQuery() {
  return useMediaQuery({ maxWidth: Breakpoints.XL - 1 });
}
export function useLargeDesktopAndBelowMediaQuery() {
  return useMediaQuery({ maxWidth: Breakpoints.XXL - 1 });
}

// * Breakpoint and above hooks
export function usePhoneAndAboveMediaQuery() {
  return useMediaQuery({ minWidth: Breakpoints.SM });
}
export function useTabletAndAboveMediaQuery() {
  return useMediaQuery({ minWidth: Breakpoints.MD });
}
export function useDesktopAndAboveMediaQuery() {
  return useMediaQuery({ minWidth: Breakpoints.LG });
}
export function useLargeDesktopAndAboveMediaQuery() {
  return useMediaQuery({ minWidth: Breakpoints.XL });
}
