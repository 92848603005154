import React, { Component, createRef } from "react";

import { Button, Spinner } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { FTSEmail } from "../../../components/fts/email";
import Checkbox from "../../../components/@vuexy/checkbox/CheckboxesVuexy";
import { Check } from "react-feather";
import { phoneNumberMask } from "../../../utility/masks";
import FormikMaskedInput from "../../../components/fts/formik/masked-input";
import { toast } from "react-toastify";
import { sendLinkMessage } from "../../../redux/actions/invoices";
import { connect } from "react-redux";
import Icon from "../Icons";
import { sendTemplate } from "../../../redux/actions/marketing";

import { activities } from "../../../constants";
import { createActivityVendor } from "../../../redux/actions/activities";
import logger from "../../../utility/logger";

class SendPaymentLink extends Component {
  state = {
    activeTab: "email",
    errorMessage: "",
    inProgress: false,
  };

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.sendLinkRef = this;
  }

  handleInProgress = () => {
    this.setState({
      inProgress: !this.state.inProgress,
    });
  };

  sendLinkRef = createRef();

  initialFormData = {
    addedEmail: "",
    email: "",
    listingEmail: "",
    additionalEmail: "",
    additionalPhoneNumber: "",
    mobilePhone: "",
    phoneNumber: "",
    phoneNumberSecondary: "",
    lisitngPhoneNumber: "",
    listingPhoneNumberSecondary: "",
    afterHours: "",
    recipientName: "",
  };

  formSchema = Yup.object().shape({
    addedEmail: Yup.string().email("Invalid email"),
    email: Yup.string().email("Invalid email"),
    additionalEmail: Yup.string().email("Invalid email"),
  });

  updateActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
      errorMessage: "",
    });
  };

  handleLoading = (isLoading) => {
    this.setState({
      inProgress: isLoading,
    });
  };

  extractEmails = (text) => {
    return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
  };

  checkDuplicatePhoneNumber = (value) => {
    const accountPhoneNumbers = [];
    const accountData = this.props.accountData;
    if (accountData?.mobilePhone) {
      accountPhoneNumbers.push(accountData.mobilePhone);
    }
    if (accountData?.primaryPhoneNumber) {
      accountPhoneNumbers.push(accountData.primaryPhoneNumber);
    }
    if (accountData?.phoneNumberSecondary) {
      accountPhoneNumbers.push(accountData.phoneNumberSecondary);
    }

    if (accountPhoneNumbers.includes(value)) {
      return true;
    }
    return false;
  };

  checkDuplicateEmail = (value) => {
    if (!this.extractEmails(value)?.length) {
      return true;
    }
    const accountEmails = [];
    const accountData = this.props.accountData;

    if (accountData?.email) {
      accountEmails.push(accountData.email);
    }
    if (accountData?.additionalEmail) {
      accountEmails.push(accountData.additionalEmail);
    }

    if (accountEmails.includes(value)) {
      return true;
    }
    return false;
  };

  checkSelectedValues = (values) => {
    if (
      !values.addedEmail &&
      !values.email &&
      !values.additionalEmail &&
      !values.listingEmail &&
      this.state.activeTab === "email"
    ) {
      this.setState({
        errorMessage: "Please provide or select an email address",
      });
      return false;
    } else if (
      !values.mobilePhone &&
      !values.phoneNumber &&
      !values.phoneNumberSecondary &&
      !values.additionalPhoneNumber &&
      !values.listingPhoneNumber &&
      !values.listingPhoneNumberSecondary &&
      !values.afterHours &&
      this.state.activeTab === "message"
    ) {
      this.setState({
        errorMessage: "Please provide or select an phone number",
      });
    } else {
      this.setState({
        errorMessage: "",
      });
      return true;
    }
  };

  handleSubmit = (values) => {
    if (this.state.inProgress) {
      return;
    }

    if (this.checkSelectedValues(values)) {
      if (this.props.sendReport) {
        this.setState({
          inProgress: true,
        });
        const ref = this.sendLinkRef;
        this.props.sendReport({ activeTab: this.state.activeTab, values, ref });
        return;
      }

      if (this.props.sendProof) {
        this.props.sendProof({ activeTab: this.state.activeTab, values });
        return;
      }

      this.setState({
        inProgress: true,
      });

      if (this.state.activeTab === "message") {
        let phoneNumbers = [
          values.mobilePhone,
          values.phoneNumber,
          values.phoneNumberSecondary,
          values.additionalPhoneNumber,
          values.listingPhoneNumber,
          values.listingPhoneNumberSecondary,
          values.afterHours,
        ].filter(Boolean);

        let payload = {
          type: this.props.type,
          phoneNumbersList: phoneNumbers,
          userId: this.props.userData?.accountLink || this.props.userData?.id,
          recipientName: values?.recipientName,
          selectedInvoices: this.props.selectedInvoices,
          sendBy: `${this.props.userData.firstName} ${this.props.userData.lastName}`,
        };

        this.props
          .sendLinkMessage(payload)
          .then(() => {
            this.props.handleMenu();
            toast.success("Link sent successfully!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });

            this.handleLinkActivity({ phoneNumbers });
            this.setState({
              inProgress: false,
            });
          })
          .catch((err) => logger.error(err));
      } else {
        let emailsList = [
          values.addedEmail,
          values.additionalEmail,
          values.email,
          values.listingEmail,
        ].filter(Boolean);

        if (this.props.type === "email") {
          let payload = {
            emailsList,
            html: encodeURIComponent(this.props.template),
            templateId: this.props.templateId,
            userId: this.props.accountData.id,
            templateFolderName: this.props.activeTableTab,
          };

          return this.props
            .sendTemplate(payload)
            .then(() => {
              this.props.handleMenu();
              // this.postEmailSentActivity(emailsList);
              toast.success("Email sent successfully!", {
                position: toast.POSITION.TOP_RIGHT,
              });

              if (this.props.getSystemTemplates)
                this.props.getSystemTemplates();
            })
            .catch((err) => {
              this.setState({
                inProgress: false,
              });
              logger.error(err);
            });
        }

        let payload = {
          userId: this.props.userData?.id,
          adId: this.props.listingData?.id,
          type: this.props.type,
          emailsList,
          selectedInvoices: this.props.actionItem
            ? [this.props.actionItem]
            : this.props.selectedInvoices,
          sourceId: this.props.actionItem
            ? this.props.actionItem["payments.sourceId"]
            : this.props.selectedInvoices[0]["payments.sourceId"],
          sendBy: `${this.props.userData.firstName} ${this.props.userData?.lastName}`,
          recipientName: values?.recipientName,
        };

        this.props
          .sendLinkMessage(payload)
          .then(() => {
            this.props.handleMenu();
            // this.postInvoiceSendActivity(emailsList);
            toast.success("Link sent successfully!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            this.setState({ inProgress: false });
          })
          .catch((err) => {
            this.setState({
              inProgress: false,
            });
            logger.error(err);
          });
      }
      return;
    } else {
      toast.error("Unable to proceed. Invalid form!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  handleLinkActivity = async ({ phoneNumbers }) => {
    const numbers = phoneNumbers.toString();
    let invoicesIds = this.props.selectedInvoices.map(
      (selectedInvoice) => `#${selectedInvoice.id}`,
    );

    let activityPayload = {
      status: 1,
      userId: this.props.userData.id,
      iconName: "SendActivity",
    };

    if (this.props.type === "receipt") {
      activityPayload.activityType =
        this.props.activitiesTypes.SHARED_RECEIPT_LINK;
      activityPayload.activity = activities.sendReceiptLink
        .replace(
          "{{admin_name}}",
          `${this.props.userData.firstName} ${this.props.userData?.lastName}`,
        )
        .replace("{{invoiceId}}", `#${invoicesIds.toString()}`)
        .replace("{{numbers}}", `${numbers}`);
    } else {
      activityPayload.activityType =
        this.props.activitiesTypes.SHARED_PAYMENT_LINK;
      activityPayload.activity = activities.sendPaymentLink
        .replace(
          "{{admin_name}}",
          `${this.props.userData.firstName} ${this.props.userData.lastName}`,
        )
        .replace("{{user_id}}", this.props.userData.id)
        .replace("{{invoiceId}}", `${invoicesIds.toString()}`)
        .replace("{{numbers}}", `${numbers}`);
    }

    await this.props.createActivityVendor(activityPayload);
  };

  postEmailSentActivity = async (emailsList) => {
    const emails = emailsList.toString();
    let activityPayload = {
      status: 1,
      userId: this.props.accountData.id,
      activityType: this.props.activitiesTypes.EMAIL_SENT,
      iconName: "SendActivity",
      activity: activities.emailSent
        .replace(
          "{{admin_name}}",
          `${this.props.userData.firstName} ${this.props.userData.lastName}`,
        )
        .replace("{{emailType}}", "Email")
        .replace("{{recipientEmail}}", `${emails}`),
      adminUserId: this.props.userData.adminAccountOwner || null,
    };
    await this.props.createActivity(activityPayload);
  };

  postInvoiceSendActivity = async (emailsList) => {
    const emails = emailsList.toString();
    let activityPayload = {
      status: 1,
      invoiceId: this.props.invoiceId,
      userId: this.props.actionItem.userId,
      activityType: this.props.activitiesTypes.SEND_INVOICE,
      iconName: "SendActivity",
      activity: activities.sendInvoice
        .replace(
          "{{admin_name}}",
          `${this.props.userData.firstName} ${this.props.userData.lastName}`,
        )
        .replace("{{invoiceId}}", `${this.props.invoiceId}`)
        .replace("{{recipientEmail}}", `${emails}`),
      adminUserId: this.props.userData.adminAccountOwner || null,
    };

    await this.props.createActivity(activityPayload);
  };

  render() {
    const { activeTab, errorMessage, inProgress } = this.state;
    const {
      accountData,
      listingData,
      type,
      // showIcon = true,
      isDisabled,
    } = this.props;

    let buttonText;
    switch (type) {
      case "paymentLink": {
        buttonText = "Send link";
        break;
      }
      case "receipt": {
        buttonText = "Send receipt";
        break;
      }
      case "report": {
        buttonText = "Send Link";
        break;
      }
      case "send-proof": {
        buttonText = "Send proof";
        break;
      }

      case "send-liting-proof": {
        buttonText = "Send Listing proof";
        break;
      }
      default: {
        buttonText = "Send";
      }
    }

    let tabs = [
      {
        label: "By email",
        type: "email",
      },
      {
        label: "By text message",
        type: "message",
      },
    ];

    return (
      <div className="payment-link-invoice-details">
        <div className="payment-link-popover">
          {type !== "email" && (
            <div className="nav">
              {tabs.map((itm) => {
                return (
                  <button
                    key={itm.type}
                    type="button"
                    className={`tab ${
                      activeTab === itm.type ? "selected" : "unselected"
                    }`}
                    onClick={() => {
                      this.updateActiveTab(itm.type);
                    }}
                  >
                    {itm.label}
                  </button>
                );
              })}
            </div>
          )}
          <Formik
            initialValues={this.initialFormData}
            validationSchema={this.formSchema}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={this.handleSubmit}
          >
            {() => {
              return (
                <Form id="payment-link-form">
                  {activeTab === "email" && (
                    <div>
                      <div className="payment-popover-title">
                        RECEIVING E-MAIL ADDRESS
                      </div>

                      <FTSEmail
                        name="addedEmail"
                        placeholder="Additional email address"
                      />

                      {(accountData?.email || accountData?.additionalEmail) && (
                        <div className="payment-popover-title">
                          ACCOUNT EMAILS
                        </div>
                      )}

                      {accountData?.email && (
                        <Field name="email">
                          {({ field: { value }, form }) => (
                            <Checkbox
                              className="link-checkbox"
                              color="#647074"
                              icon={<Check className="vx-icon" size={16} />}
                              name="email"
                              label={accountData.email}
                              checked={value}
                              onChange={() => {
                                if (value) {
                                  form.setFieldValue("email", "");
                                } else {
                                  form.setFieldValue(
                                    "email",
                                    accountData.email,
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      )}

                      {accountData?.additionalEmail && (
                        <Field name="additionalEmail">
                          {({ field: { value }, form }) => (
                            <Checkbox
                              className="link-checkbox"
                              color="#647074"
                              icon={<Check className="vx-icon" size={16} />}
                              name="additionalEmail"
                              label={accountData.additionalEmail}
                              checked={value}
                              onChange={() => {
                                if (value) {
                                  form.setFieldValue("additionalEmail", "");
                                } else {
                                  form.setFieldValue(
                                    "additionalEmail",
                                    accountData.additionalEmail,
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      )}

                      {listingData?.email &&
                        !this.checkDuplicateEmail(listingData?.email) && (
                          <div className="phone-email-section pb-1">
                            LISTING EMAIL
                          </div>
                        )}

                      {listingData?.email &&
                        !this.checkDuplicateEmail(listingData?.email) && (
                          <Field name="listingEmail">
                            {({ field: { value }, form }) => (
                              <Checkbox
                                className="link-checkbox"
                                color="#647074"
                                icon={<Check className="vx-icon" size={16} />}
                                name="listingEmail"
                                label={this.extractEmails(listingData.email)[0]}
                                checked={value}
                                onChange={() => {
                                  if (value) {
                                    form.setFieldValue("listingEmail", "");
                                  } else {
                                    form.setFieldValue(
                                      "listingEmail",
                                      this.extractEmails(listingData.email)[0],
                                    );
                                  }
                                }}
                              />
                            )}
                          </Field>
                        )}
                    </div>
                  )}
                  {activeTab === "message" && (
                    <div>
                      <div className="payment-popover-title mb-1">
                        RECEIVING PHONE NUMBER
                      </div>

                      <FormikMaskedInput
                        name="additionalPhoneNumber"
                        placeholder="Additional phone number"
                        mask={phoneNumberMask}
                      />

                      {(accountData?.mobilePhone ||
                        accountData?.phoneNumber ||
                        accountData?.phoneNumberSecondary) && (
                        <div className="payment-popover-title">
                          ACCOUNT PHONE NUMBERS
                        </div>
                      )}

                      {accountData?.mobilePhone && (
                        <Field name="mobilePhone">
                          {({ field: { value }, form }) => (
                            <Checkbox
                              className="link-checkbox"
                              color="#647074"
                              icon={<Check className="vx-icon" size={16} />}
                              name="mobilePhone"
                              label={accountData.mobilePhone}
                              checked={value}
                              onChange={() => {
                                if (value) {
                                  form.setFieldValue("mobilePhone", "");
                                } else {
                                  form.setFieldValue(
                                    "mobilePhone",
                                    accountData.mobilePhone,
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      )}

                      {accountData?.phoneNumber && (
                        <Field name="phoneNumber">
                          {({ field: { value }, form }) => (
                            <Checkbox
                              className="link-checkbox"
                              color="#647074"
                              icon={<Check className="vx-icon" size={16} />}
                              name="phoneNumber"
                              label={accountData.phoneNumber}
                              checked={value}
                              onChange={() => {
                                if (value) {
                                  form.setFieldValue("phoneNumber", "");
                                } else {
                                  form.setFieldValue(
                                    "phoneNumber",
                                    accountData.phoneNumber,
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      )}

                      {accountData?.phoneNumberSecondary && (
                        <Field name="phoneNumberSecondary">
                          {({ field: { value }, form }) => (
                            <Checkbox
                              className="link-checkbox"
                              color="#647074"
                              icon={<Check className="vx-icon" size={16} />}
                              name="phoneNumberSecondary"
                              label={accountData.phoneNumberSecondary}
                              checked={value}
                              onChange={() => {
                                if (value) {
                                  form.setFieldValue(
                                    "phoneNumberSecondary",
                                    "",
                                  );
                                } else {
                                  form.setFieldValue(
                                    "phoneNumberSecondary",
                                    accountData.phoneNumberSecondary,
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      )}

                      {((listingData?.phoneNumber &&
                        !this.checkDuplicatePhoneNumber(
                          listingData?.phoneNumber,
                        )) ||
                        (listingData?.phoneNumberSecondary &&
                          !this.checkDuplicatePhoneNumber(
                            listingData?.phoneNumberSecondary,
                          )) ||
                        (listingData?.afterHours &&
                          !this.checkDuplicatePhoneNumber(
                            listingData?.afterHours,
                          ))) && (
                        <div className="phone-email-section mb-1">
                          LISTING PHONE NUMBERS
                        </div>
                      )}

                      {listingData?.phoneNumber &&
                        !this.checkDuplicatePhoneNumber(
                          listingData?.phoneNumber,
                        ) && (
                          <Field name="listingPhoneNumber">
                            {({ field: { value }, form }) => (
                              <Checkbox
                                className="link-checkbox"
                                color="#647074"
                                icon={<Check className="vx-icon" size={16} />}
                                name="listingPhoneNumber"
                                label={listingData.phoneNumber}
                                checked={value}
                                onChange={() => {
                                  if (value) {
                                    form.setFieldValue(
                                      "listingPhoneNumber",
                                      "",
                                    );
                                  } else {
                                    form.setFieldValue(
                                      "listingPhoneNumber",
                                      listingData.phoneNumber,
                                    );
                                  }
                                }}
                              />
                            )}
                          </Field>
                        )}

                      {listingData?.phoneNumberSecondary &&
                        !this.checkDuplicatePhoneNumber(
                          listingData?.phoneNumberSecondary,
                        ) && (
                          <Field name="listingPhoneNumberSecondary">
                            {({ field: { value }, form }) => (
                              <Checkbox
                                className="link-checkbox"
                                color="#647074"
                                icon={<Check className="vx-icon" size={16} />}
                                name="listingPhoneNumberSecondary"
                                label={listingData.phoneNumberSecondary}
                                checked={value}
                                onChange={() => {
                                  if (value) {
                                    form.setFieldValue(
                                      "listingPhoneNumberSecondary",
                                      "",
                                    );
                                  } else {
                                    form.setFieldValue(
                                      "listingPhoneNumberSecondary",
                                      listingData.phoneNumberSecondary,
                                    );
                                  }
                                }}
                              />
                            )}
                          </Field>
                        )}

                      {listingData?.afterHours &&
                        !this.checkDuplicatePhoneNumber(
                          listingData?.afterHours,
                        ) && (
                          <Field name="afterHours">
                            {({ field: { value }, form }) => (
                              <Checkbox
                                className="link-checkbox"
                                color="#647074"
                                icon={<Check className="vx-icon" size={16} />}
                                name="afterHours"
                                label={listingData.afterHours}
                                checked={value}
                                onChange={() => {
                                  if (value) {
                                    form.setFieldValue("afterHours", "");
                                  } else {
                                    form.setFieldValue(
                                      "afterHours",
                                      listingData.afterHours,
                                    );
                                  }
                                }}
                              />
                            )}
                          </Field>
                        )}
                    </div>
                  )}

                  {errorMessage && (
                    <span className="error-message">{errorMessage}</span>
                  )}
                  <Button
                    type="submit"
                    disabled={isDisabled}
                    color="primary"
                    className="w-100 mt-1"
                  >
                    {inProgress || this.props.inProgress ? (
                      <Spinner size="sm" />
                    ) : (
                      <>{buttonText}</>
                    )}
                  </Button>
                  {type === "report" && (
                    <div className="d-flex send-report-text">
                      <div className="d-flex align-items-center">
                        <Icon name="InfoCircle" />
                      </div>
                      <div>
                        This report will be visible for 7 days. You can always
                        re-send it.
                      </div>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
    activitiesTypes: state.essentialLists.activitiesTypes,
    userData: state.authState.user,
    selectedInvoices: state.invoices.selectedInvoices,
  };
};

const mapDispatchToProps = {
  sendLinkMessage,
  sendTemplate,
  createActivityVendor,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendPaymentLink);
