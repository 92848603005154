import { setUserData } from "../../actions/v2/auth";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import { all, call, put, take, takeLatest } from "redux-saga/effects";
import { CREATE_LISTING_REGISTER_REQUEST } from "../../reducers/createListingAccount";
import { setLoggedUserCookie } from "../../../cookies";
import {
  COLLECT_ESSENTIALS_SUCCESS,
  collectEssentialsRequest,
} from "../../actions/v2/essentials";
import {
  createListingAccountFailed,
  createListingAccountSetEmailAlreadyUsed,
  createListingAccountSuccess,
} from "../../actions/createListingAccount";
import { EMAIL_IN_USE_ERROR } from "../../../components/fts-components-v2/GetListedAccount/constants";
import { history } from "../../../history";

const CREATE_LISTING_ACCOUNT_ERROR_MSG = "Error while creating listing account";
const getErrorMsg = (e, defaultMessage) =>
  e.response ? e.response.data.message : defaultMessage;

function* createListingAccount(action) {
  const { accountData, selectedListingPriceId } = action.payload.data;
  try {
    const { data } = yield call(
      FTSAxiosV2.post,
      `/users/get-listed/account`,
      accountData,
    );

    const bearerToken = data?.data?.token?.bearerToken;
    const user = data?.data?.userData;

    setLoggedUserCookie(user, bearerToken);

    yield all([
      put(setUserData({ data: user })),
      put(collectEssentialsRequest()),
      take(COLLECT_ESSENTIALS_SUCCESS),
    ]);

    if (!selectedListingPriceId)
      history.push("/dashboard/vendor-advertise-listings");
    else
      history.push(
        `/dashboard/create-listing/business-details?selectedListingPriceId=${selectedListingPriceId}`,
      );

    yield put(createListingAccountSuccess());
  } catch (e) {
    if (e?.response?.data?.message === EMAIL_IN_USE_ERROR)
      yield put(createListingAccountSetEmailAlreadyUsed(true));

    const message = getErrorMsg(e, CREATE_LISTING_ACCOUNT_ERROR_MSG);
    const inlineMessage =
      e?.response?.data?.inlineMessage || "Something went wrong";

    yield put(createListingAccountFailed({ data: { message, inlineMessage } }));
  }
}

export default function* createListingWatcher() {
  yield takeLatest(CREATE_LISTING_REGISTER_REQUEST, createListingAccount);
}
