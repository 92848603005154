import React from "react";
import { getListingTitle } from "../../../utility/getListingTitle";

const TitlePaidListing = ({ priceId, price }) => {
  return (
    <div className="choose-your-plan-container-header">
      <span className="choose-your-plan-container-header-title">{`${getListingTitle(
        { priceId }
      )} Listing`}</span>
      <span className="choose-your-plan-container-header-price">{` $${price}`}</span>
      <span className="choose-your-plan-container-header-year">/year</span>
    </div>
  );
};

export default TitlePaidListing;
