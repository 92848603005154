import { gapStyles, initialButtonStyles, loadingSvgAnimation, sizingStyles, spacingStylesOnlyIcon, spacingStylesWithLabel, } from "@find-truck-service/ui/src/FtsButton/constants/styles";
import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { ButtonDirections, ButtonProviders, ButtonSizes } from "@find-truck-service/types/ui/button";
import { defaultProps, defaultStyleProps } from "@find-truck-service/ui/src/default";
const buttonProps = ["label", "loading", "provider"];
const buttonStyle = (loading, label) => {
    return `
    ${initialButtonStyles}
    ${sizingStyles[ButtonSizes.md]}
    ${gapStyles[ButtonSizes.md]}
    ${spacingStylesOnlyIcon[ButtonSizes.md]}
    ${label ? spacingStylesWithLabel[ButtonSizes.md][ButtonDirections.row] : ""}

    outline: ${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]};
    background: ${ColorsValue["greyscale-100"]};
    color: ${ColorsValue["greyscale-800"]};

    &:focus, &:focus-visible {
      outline: ${Spacing["3xs"]} solid ${ColorsValue["blue-accent-300"]};
      background: ${ColorsValue["greyscale-100"]};
    }

    &:hover {
      outline: ${Spacing["4xs"]} solid ${ColorsValue["greyscale-200"]};
      background: ${ColorsValue["greyscale-200"]};
    };

    &:active {
      outline: ${Spacing["4xs"]} solid ${ColorsValue["greyscale-300"]};
      background: ${ColorsValue["greyscale-300"]};
    };

    &:disabled {
      outline: ${Spacing["4xs"]} solid ${ColorsValue["greyscale-50"]};
      background: ${ColorsValue["greyscale-50"]};
      color: ${ColorsValue["greyscale-300"]};
      pointer-events: none;
      cursor: default;
    };

    ${loading && loadingSvgAnimation}
    ${loading &&
        `outline: ${Spacing["4xs"]} solid ${ColorsValue["greyscale-50"]};
      background: ${ColorsValue["greyscale-50"]};
      color: ${ColorsValue["greyscale-300"]};
      path { stroke: ${ColorsValue["greyscale-300"]}; }
      pointer-events: none;
      cursor: default;`}
  `;
};
const styleByProvider = ({ loading, provider, label }) => {
    switch (provider) {
        case ButtonProviders.apple:
            return buttonStyle(loading, label);
        case ButtonProviders.google:
            return buttonStyle(loading, label);
        case ButtonProviders.facebook:
            return buttonStyle(loading, label);
        case ButtonProviders.paypal:
            return buttonStyle(loading, label);
        default:
            return "";
    }
};
const buttonAndDefaultProps = [...buttonProps, ...defaultProps];
export const FtsProviderButton = styled.button.withConfig({
    shouldForwardProp: (prop) => !buttonAndDefaultProps.includes(prop),
}) `
  ${defaultStyleProps};
  ${styleByProvider};
`;
