import React, { useEffect } from "react";
import { FtsSideOutWrapper } from "@find-truck-service/ui/src/FtsSideOut/FtsSideOutWrapper";
import { FtsSideOutDrawerContainer } from "@find-truck-service/ui/src/FtsSideOut/FtsSideOutDrawerContainer";
import { DrawerContent } from "@find-truck-service/ui/src/components/react/Drawer/components/DrawerContent";
import { ZIndexValues } from "@find-truck-service/types/constants/zIndexValues";
export const Drawer = (props) => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(FtsSideOutWrapper, { isOpen: true, zIndex: ZIndexValues.SIDE_OUT_WRAPPER, onClick: props.closeSideOut, display: { xs: "block", sm: "block", md: "block", lg: "none" } }),
        React.createElement(FtsSideOutDrawerContainer, { isOpen: true, boxShadow: "md", display: { xs: "block", sm: "block", md: "block", lg: "none" } },
            React.createElement(DrawerContent, { onClose: props.closeSideOut, userName: props.userName, activeLink: props.activeLink }))));
};
