import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MemoizedDescriptionModal } from "./DescriptionModal";
import { MobileDescriptionField } from "./MobileDescriptionField";
import { selectListingDataDescription } from "../../../../redux/reducers/v2/listing";

export const MobileDescription = () => {
  const [showModal, setShowModal] = useState(false);
  const description = useSelector(selectListingDataDescription);

  return (
    <MobileDescriptionField
      description={description}
      setShowModal={setShowModal}
    >
      <MemoizedDescriptionModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </MobileDescriptionField>
  );
};
