import React from "react";
import { useSelector } from "react-redux";
import { setListingData } from "../../../../redux/actions/v2/listing";
import { ServiceAmenitiesModalContentFields } from "./ServiceAmenitiesModalContentFields";
import { selectListingAdsServiceAmenities } from "../../../../redux/reducers/v2/listing";

export const ServiceAmenitiesModalContent = () => {
  const selectedServiceAmenities = useSelector(
    selectListingAdsServiceAmenities,
  );

  return (
    <ServiceAmenitiesModalContentFields
      selectedServiceAmenities={selectedServiceAmenities}
      setListingData={setListingData}
    />
  );
};
