import React, { useCallback } from "react";
import { isObject } from "lodash";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { PhoneNumberInput } from "@find-truck-service/ui/src/components/react/PhoneNumberInput";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { selectStates } from "../../../redux/reducers/essentialLists";
import ButtonsMd from "./components/ButtonsMd";
import ButtonsXs from "./components/ButtonsXs";
import {
  companyDriverProfileSetFieldValues,
  companyDriverProfileSetFinishLaterLoading,
  companyDriverProfileSetInvalidFormFields,
  companyDriverProfileSetUpdateLoading,
  companyDriverProfileUpdateRequest,
} from "../../../redux/actions/v2/companyDriverProfile";
import { toast } from "react-toastify";

import {
  selectCompanyDriverProfileData,
  selectCompanyDriverProfileInvalidFormFields,
} from "../../../redux/reducers/v2/company-driver-profile";
import { companyDriverProfileValidator } from "./validators";
import { selectUserData } from "../../../redux/reducers/v2/auth";
import { USER_TYPE } from "../../../constants";

import { ZipCodeInput } from "@find-truck-service/ui/src/components/react/ZipCodeInput";
import { useHistory } from "react-router-dom";
import { Pages } from "../../../views/constants/pages";
import logger from "../../../utility/logger";

const CompanyDriverInfo = () => {
  const dispatch = useDispatch();
  const states = useSelector(selectStates);
  const userData = useSelector(selectUserData);
  const companyDriverProfileData = useSelector(selectCompanyDriverProfileData);
  const history = useHistory();
  const invalidFormFields = useSelector(
    selectCompanyDriverProfileInvalidFormFields,
  );

  const onChange = (event) => {
    dispatch(
      companyDriverProfileSetFieldValues({
        [event.target.name]: event.target.value,
      }),
    );
  };

  const onUpdateCompanyDriverSuccess = () => {
    history.push(Pages.VERIFY_EMAIL);
  };

  const onUpdateCompanyDriverFailure = (e) => {
    const message = e.response
      ? e.response.data.message
      : "Error while updating user data";

    toast.error(message);
  };

  const handleUpdateCompanyDriver = useCallback(async () => {
    try {
      await companyDriverProfileValidator.validate(
        companyDriverProfileData || {},
      );

      dispatch(
        companyDriverProfileSetInvalidFormFields({
          data: null,
        }),
      );
      dispatch(companyDriverProfileSetUpdateLoading({ data: true }));
      dispatch(
        companyDriverProfileUpdateRequest({
          data: {
            ...companyDriverProfileData,
            onboardingFinished: true,
            id: userData.id,
            type: USER_TYPE.COMPANY_DRIVER,
            userType: USER_TYPE.COMPANY_DRIVER,
            // TODO: CHANGE USER ROLE
            userRole: 1,
          },
          onSuccess: onUpdateCompanyDriverSuccess,
          onError: onUpdateCompanyDriverFailure,
        }),
      );
    } catch (e) {
      logger.error(e);
      if (e.name === "ValidationError") {
        dispatch(
          companyDriverProfileSetInvalidFormFields({
            data: {
              [e.path]: e.errors[0],
            },
          }),
        );
      }
    }
  });

  const handleCompanyDriverFinishLater = useCallback(async () => {
    try {
      dispatch(
        companyDriverProfileSetInvalidFormFields({
          data: null,
        }),
      );

      dispatch(companyDriverProfileSetFinishLaterLoading({ data: true }));

      dispatch(
        companyDriverProfileUpdateRequest({
          data: {
            ...companyDriverProfileData,
            onboardingFinished: true,
            id: userData.id,
            type: USER_TYPE.COMPANY_DRIVER,
            userType: USER_TYPE.COMPANY_DRIVER,
            // TODO: CHANGE USER ROLE
            userRole: 1,
          },
          onSuccess: onUpdateCompanyDriverSuccess,
          onError: onUpdateCompanyDriverFailure,
        }),
      );
    } catch (e) {
      const message = e.response
        ? e.response.data.message
        : "Error while updating user data";

      toast.error(message);
    }
  });

  return (
    <>
      <FtsContainer>
        <FtsRow mt={{ lg: "4xl", md: "4xl", sm: "4xl", xs: "2xl" }}>
          <FtsColumn
            size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
            offset={{ xs: 0, sm: 0, md: 0, lg: 3 }}
            rowGap={"2xl"}
            columnGap={"2xl"}
          >
            <FtsWrapper
              p={{
                xs: "2xl",
                md: "4xl",
                lg: "4xl",
              }}
              bg={ColorsValue["greyscale-0"]}
              boxShadow="sm"
              borderRadius="2xs"
            >
              <FtsColumn
                size={{ xs: 12, md: 12, lg: 12 }}
                rowGap={"2xl"}
                columnGap={"2xl"}
              >
                <Span
                  variant={VariantsValues["text-md"]}
                  color={ColorsValue["greyscale-800"]}
                  weight={WeightSize.bold}
                >
                  Driver Info
                </Span>
              </FtsColumn>

              <FtsRow rowGap={"2xl"} columnGap={"lg"} mt={"4xl"}>
                <FtsColumn size={{ xs: 12, md: 12, lg: 12 }} rowGap={"2xl"}>
                  <Input
                    name={"address"}
                    required
                    value={companyDriverProfileData?.address}
                    onChange={onChange}
                    label={"Street"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["address"]
                    }
                  />
                </FtsColumn>
                <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
                  <Input
                    name={"city"}
                    required
                    value={companyDriverProfileData?.city}
                    onChange={onChange}
                    label={"City"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) && invalidFormFields["city"]
                    }
                  />
                </FtsColumn>
                <FtsColumn size={{ xs: 6, md: 3, lg: 3 }}>
                  <Dropdown
                    required
                    size={DropdownSizesValues.md}
                    variant={DropdownVariantsValues.outlined}
                    name={"state"}
                    label={"State"}
                    options={states}
                    placeholder={""}
                    value={states.find(
                      (e) => e.label === companyDriverProfileData?.state,
                    )}
                    errorMessage={invalidFormFields?.["state"]}
                    onChange={(val) =>
                      onChange({
                        target: { name: "state", value: val?.label || "" },
                      })
                    }
                  />
                </FtsColumn>
                <FtsColumn size={{ xs: 6, md: 3, lg: 3 }}>
                  <ZipCodeInput
                    name={"zip"}
                    required
                    value={companyDriverProfileData?.zip}
                    onChange={onChange}
                    label={"Zip Code"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) && invalidFormFields["zip"]
                    }
                  />
                </FtsColumn>
                <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
                  <PhoneNumberInput
                    name={"phoneNumber"}
                    required
                    value={companyDriverProfileData?.phoneNumber}
                    onChange={onChange}
                    label={"Phone (primary)"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["phoneNumber"]
                    }
                  />
                </FtsColumn>
                <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
                  <Input
                    name={"referal"}
                    required
                    value={companyDriverProfileData?.referal}
                    onChange={onChange}
                    label={"How did you hear about us?"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["referal"]
                    }
                  />
                </FtsColumn>
              </FtsRow>
              <ButtonsMd
                handleUpdateCompanyDriver={handleUpdateCompanyDriver}
                handleCompanyDriverFinishLater={handleCompanyDriverFinishLater}
              />
            </FtsWrapper>
            <ButtonsXs
              handleUpdateCompanyDriver={handleUpdateCompanyDriver}
              handleCompanyDriverFinishLater={handleCompanyDriverFinishLater}
            />
          </FtsColumn>
        </FtsRow>
      </FtsContainer>
    </>
  );
};

export default CompanyDriverInfo;
