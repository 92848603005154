import React from "react";
import FileItem from "./FileItem";
import { FileItemSm } from "./FileItemSm";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";

export const UploadedFiles = ({
  mt,
  adsAssets,
  listingData,
  setListingData,
  uploadAdsAssetsRequest,
}) => {
  if (!adsAssets?.length) return <EmptyComponent />;
  return (
    <FtsColumn size={{ xs: 12 }} mt={mt}>
      <FtsRow rowGap={"lg"}>
        <FtsColumn size={{ xs: 12 }}>
          <Span
            weight={WeightSize.medium}
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["blue-accent-500"]}
          >
            UPLOADED FILES
          </Span>
        </FtsColumn>
        {adsAssets.map((file) => (
          <FtsColumn size={{ xs: 12 }} key={file.fileName}>
            <FileItem
              fileData={file}
              listingData={listingData}
              setListingData={setListingData}
              uploadAdsAssetsRequest={uploadAdsAssetsRequest}
            />
            <FileItemSm
              fileData={file}
              listingData={listingData}
              setListingData={setListingData}
              uploadAdsAssetsRequest={uploadAdsAssetsRequest}
            />
          </FtsColumn>
        ))}
      </FtsRow>
    </FtsColumn>
  );
};
