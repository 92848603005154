import React from "react";
import { useSelector } from "react-redux";
import {
  setListingData,
  setListingInvalidFormFields,
} from "../../../../redux/actions/v2/listing";
import {
  selectListingData,
  selectListingInvalidFormFields,
} from "../../../../redux/reducers/v2/listing";
import { ListingContactFields } from "./ListingContactFields";

export const ListingContactRow = () => {
  const invalidFormFields = useSelector(selectListingInvalidFormFields);
  const listingData = useSelector(selectListingData);

  return (
    <ListingContactFields
      listingData={listingData}
      setListingData={setListingData}
      invalidFormFields={invalidFormFields}
      setListingInvalidFormFields={setListingInvalidFormFields}
    />
  );
};
