import React, { PureComponent } from "react";
import { Button } from "@find-truck-service/ui/src/components/react/Button";

import FTSModal from "../fts-modal";

import "../../../assets/scss/components/fts/modals/fts-confirmation-modal.scss";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";

class ConfirmationModal extends PureComponent {
  render() {
    const { isOpen, title, closeModal, confirmModal, message, onClose } =
      this.props;

    return (
      <FTSModal
        {...{
          isOpen,
          title,
          closeModal,
          onClose,
          modalClasses: "fts-modal fts-confirmation-modal",
        }}
        footerCTA={() => {
          return (
            <FtsWrapper display="flex" justifyContent="flex-end" gap={"lg"}>
              <Button
                label="Cancel"
                onClick={closeModal}
                size={ButtonSizes.md}
                type={ButtonTypes.outlined}
                color={ButtonColorsValue.neutral}
              />
              <Button
                form=""
                label="Confirm"
                size={ButtonSizes.md}
                onClick={confirmModal}
                type={ButtonTypes.contained}
                color={ButtonColorsValue.accent}
              />
            </FtsWrapper>
          );
        }}
      >
        {message}
      </FTSModal>
    );
  }
}

export default ConfirmationModal;
