import { toast } from "react-toastify";
import { history } from "../history";
import logger from "./logger";

class socketHandler {
  listingId = null;
  adminId = null;
  socket = null;
  subscribedEvents = [];
  events = {
    saveConnection: {
      onOpen: () => {},
      onMessage: () => {},
    },
    subscribeToListingAnalytics: {
      onOpen: () => {},
      onMessage: () => {},
    },
    usSubscribeToListingAnalytics: {
      onOpen: () => {},
      onMessage: () => {},
    },
    subscribeToAccountAnalytics: {
      onOpen: () => {},
      onMessage: () => {},
    },
    usSubscribeToAccountAnalytics: {
      onOpen: () => {},
      onMessage: () => {},
    },
    logOutUser: {
      onOpen: () => {},
      onMessage: (message) => {
        logger.info("MESSAGE: ", message);
        // window.location.href = "/dashboard/login";
        history.push("/dashboard/login");
        toast.success(
          "Find truck services changed your permissions, please log in to the dashboard again and review the changes",
        );
      },
    },
    subscribeToAccountAccessRequest: {
      onOpen: () => {},
      onMessage: () => {},
    },
    usSubscribeToAccountAccessRequest: {
      onOpen: () => {},
      onMessage: () => {},
    },
  };
  connect() {
    this.socket = new WebSocket(process.env.REACT_APP_WEB_SOCKET_URL);
    this.socket.onopen = () => {
      for (let subscribedEvent of this.subscribedEvents) {
        this.events[subscribedEvent].onOpen();
      }
    };
    this.socket.onmessage = (event) => {
      logger.info("onmessage");
      let data = JSON.parse(event.data);
      logger.info(data);
      logger.info(this.events[data.event]);
      if (data.event && this.events[data.event]) {
        this.events[data.event].onMessage(data);
      }
    };
    this.socket.onclose = () => {
      logger.info("CLOSED");
      this.connect();
    };
    // setTimeout(() => {
    //   this.socket.close();
    // }, 5000);
  }
  constructor() {
    this.connect();
  }

  subscribeEvent(action) {
    this.subscribedEvents.push(action);
  }

  send(action, message) {
    this.socket.send(
      JSON.stringify({
        action,
        message,
      }),
    );
    return;
  }
}

const instance = new socketHandler();

export default instance;
