import FTSAxios from "../../../axios/fts.instance";
import { call, put, all, takeLatest } from "redux-saga/effects";
import {
  COLLECT_ESSENTIALS_REQUEST,
  COLLECT_PUBLIC_ESSENTIALS_REQUEST,
  collectEssentialsFailed,
  collectEssentialsSuccess,
  collectPublicEssentialsFailed,
  collectPublicEssentialsSuccess,
} from "../../actions/v2/essentials";
import logger from "../../../utility/logger";

const fetchAmenities = () => FTSAxios.get("search/amenities");
const fetchServiceAmenities = () => FTSAxios.get("search/service-amenities");
const fetchCategories = () => FTSAxios.get("user/categories");
const fetchSubCategories = () => FTSAxios.get("search/subcategories");
const fetchTypes = () => FTSAxios.get("search/todo/types");
const fetchStates = () => FTSAxios.get("profile/states");
const fetchActivitiesTypes = () =>
  FTSAxios.get("/vendor/activity/getActivitiesTypes");
const fetchReputations = () => FTSAxios.get("/user/reputations");
const fetchExperiences = () => FTSAxios.get("/experiences/all");
const fetchTruckTypes = () => FTSAxios.get("/user/truck-types");
const fetchUserTruckTypes = () => FTSAxios.get("/user-truck-types");
const fetchRates = () => FTSAxios.get("rates");
const fetchUserTypes = () => FTSAxios.get("/user-types");
const fetchAdsTypes = () => FTSAxios.get("admin/lists/ads-type");

const UPDATE_AMENITIES_LIST = "UPDATE_AMENITIES_LIST";
const UPDATE_SERVICE_AMENITIES_LIST = "UPDATE_SERVICE_AMENITIES_LIST";
const UPDATE_CATEGORIES_LIST = "UPDATE_CATEGORIES_LIST";
const UPDATE_SUBCATEGORIES_LIST = "UPDATE_SUBCATEGORIES_LIST";
const UPDATE_TYPES_LIST = "UPDATE_TYPES_LIST";
const UPDATE_STATES_LIST = "UPDATE_STATES_LIST";
const UPDATE_ACTIVITIES_TYPES = "UPDATE_ACTIVITIES_TYPES";
const UPDATE_REPUTATIONS_LIST = "UPDATE_REPUTATIONS_LIST";
const UPDATE_EXPERIENCES_LIST = "UPDATE_EXPERIENCES_LIST";
const UPDATE_TRUCK_TYPES_LIST = "UPDATE_TRUCK_TYPES_LIST";
const UPDATE_USER_TRUCK_TYPES_LIST = "UPDATE_USER_TRUCK_TYPES_LIST";
const UPDATE_RATES = "UPDATE_RATES";
const UPDATE_USER_TYPES = "UPDATE_USER_TYPES";
const GET_ADS_TYPES_DATA = "GET_ADS_TYPES_DATA";

const formatAmenitiesData = (amenities) =>
  amenities.data.sort(({ name: nameA }, { name: nameB }) =>
    ("" + nameA).localeCompare(nameB),
  );

const formatServiceAmenitiesData = (serviceAmenities) =>
  serviceAmenities.data.sort(({ name: nameA }, { name: nameB }) =>
    ("" + nameA).localeCompare(nameB),
  );

const formatCategoriesData = (categories) => categories.data;
const formatSubCategoriesData = (subCategories) => subCategories.data;
const formatTypesData = (types) => types.data;

const formatStatesData = (states) =>
  states.data.map((st) => ({
    value: st.key,
    label: st.stateName,
  }));

const formatActivitiesTypesData = (activitiesTypes) =>
  activitiesTypes.data.activitiesTypes;

const formatReputationsData = (reputations) => reputations.data.reputations;
const formatExperiencesData = (experiences) => experiences.data;
const formatTruckTypesData = (truckTypes) => truckTypes.data;
const formatUserTruckTypesData = (userTruckTypes) => userTruckTypes.data;
const formatRatesData = (rates) => rates.data;
const formatUserTypesData = (userTypes) => userTypes.data;
const formatAdsTypesData = (adsTypes) => adsTypes.data;

function* collectPublicEssentialsSaga() {
  try {
    const [
      amenities,
      serviceAmenities,
      categories,
      subCategories,
      states,
      rates,
      userTypes,
      adsTypes,
    ] = yield all([
      call(fetchAmenities),
      call(fetchServiceAmenities),
      call(fetchCategories),
      call(fetchSubCategories),
      call(fetchStates),
      call(fetchRates),
      call(fetchUserTypes),
      call(fetchAdsTypes),
    ]);

    yield put({
      type: UPDATE_AMENITIES_LIST,
      data: formatAmenitiesData(amenities),
    });

    yield put({
      type: UPDATE_SERVICE_AMENITIES_LIST,
      data: formatServiceAmenitiesData(serviceAmenities),
    });

    yield put({
      type: UPDATE_CATEGORIES_LIST,
      data: formatCategoriesData(categories),
    });

    yield put({
      type: UPDATE_SUBCATEGORIES_LIST,
      data: formatSubCategoriesData(subCategories),
    });

    yield put({
      type: UPDATE_STATES_LIST,
      data: formatStatesData(states),
    });

    yield put({
      type: UPDATE_RATES,
      data: formatRatesData(rates),
    });

    yield put({
      type: UPDATE_USER_TYPES,
      data: formatUserTypesData(userTypes),
    });

    yield put({
      type: GET_ADS_TYPES_DATA,
      data: formatAdsTypesData(adsTypes),
      totalPages: 1,
    });

    yield put(collectPublicEssentialsSuccess());
  } catch (error) {
    yield put(collectPublicEssentialsFailed());
    logger.error("Error fetching public essential data:", error);
  }
}

function* collectEssentialsSaga() {
  try {
    const [
      amenities,
      serviceAmenities,
      categories,
      subCategories,
      types,
      states,
      activitiesTypes,
      reputations,
      experiences,
      truckTypes,
      userTruckTypes,
      rates,
      userTypes,
      adsTypes,
    ] = yield all([
      call(fetchAmenities),
      call(fetchServiceAmenities),
      call(fetchCategories),
      call(fetchSubCategories),
      call(fetchTypes),
      call(fetchStates),
      call(fetchActivitiesTypes),
      call(fetchReputations),
      call(fetchExperiences),
      call(fetchTruckTypes),
      call(fetchUserTruckTypes),
      call(fetchRates),
      call(fetchUserTypes),
      call(fetchAdsTypes),
    ]);

    yield put({
      type: UPDATE_AMENITIES_LIST,
      data: formatAmenitiesData(amenities),
    });

    yield put({
      type: UPDATE_SERVICE_AMENITIES_LIST,
      data: formatServiceAmenitiesData(serviceAmenities),
    });

    yield put({
      type: UPDATE_CATEGORIES_LIST,
      data: formatCategoriesData(categories),
    });

    yield put({
      type: UPDATE_SUBCATEGORIES_LIST,
      data: formatSubCategoriesData(subCategories),
    });

    yield put({
      type: UPDATE_TYPES_LIST,
      data: formatTypesData(types),
    });

    yield put({
      type: UPDATE_STATES_LIST,
      data: formatStatesData(states),
    });

    yield put({
      type: UPDATE_ACTIVITIES_TYPES,
      data: formatActivitiesTypesData(activitiesTypes),
    });

    yield put({
      type: UPDATE_REPUTATIONS_LIST,
      data: formatReputationsData(reputations),
    });

    yield put({
      type: UPDATE_EXPERIENCES_LIST,
      data: formatExperiencesData(experiences),
    });

    yield put({
      type: UPDATE_TRUCK_TYPES_LIST,
      data: formatTruckTypesData(truckTypes),
    });

    yield put({
      type: UPDATE_USER_TRUCK_TYPES_LIST,
      data: formatUserTruckTypesData(userTruckTypes),
    });

    yield put({
      type: UPDATE_RATES,
      data: formatRatesData(rates),
    });

    yield put({
      type: UPDATE_USER_TYPES,
      data: formatUserTypesData(userTypes),
    });

    yield put({
      type: GET_ADS_TYPES_DATA,
      data: formatAdsTypesData(adsTypes),
      totalPages: 1,
    });

    yield put(collectEssentialsSuccess());
  } catch (error) {
    yield put(collectEssentialsFailed());
    logger.error("Error fetching essential data:", error);
  }
}

export function* essentialsWatcher() {
  yield takeLatest(COLLECT_ESSENTIALS_REQUEST, collectEssentialsSaga);
  yield takeLatest(
    COLLECT_PUBLIC_ESSENTIALS_REQUEST,
    collectPublicEssentialsSaga,
  );
}
