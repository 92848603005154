import { useHistory } from "react-router-dom";
import { ButtonsFooter } from "@find-truck-service/ui/src/components/react/ButtonsFooter";
import React from "react";
import { selectTemporaryLinkListingSelectedPrice } from "../../../../redux/reducers/v2/temporaryListingLink";
import { useSelector } from "react-redux";
import { TemporaryLinkPages } from "../../../constants/pages";

export const SelectedUpgradeListingFooterButtons = () => {
  const history = useHistory();
  const selectedUpgradeListingPrice = useSelector(
    selectTemporaryLinkListingSelectedPrice,
  );

  return (
    <ButtonsFooter
      containedButtonProps={{
        label: `Continue with ${selectedUpgradeListingPrice.label}`,
        onClick: () => history.push(TemporaryLinkPages.UPGRADE_LISTING_PAYMENT),
      }}
    />
  );
};
