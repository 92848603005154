import React, { useEffect } from "react";
import loader from "./loader.json";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { FtsFullPageLoaderIndicatorWrapper } from "@find-truck-service/ui/src/FtsLoader/FtsLoaderIndicatorWrapper";
export const FullPageLoader = () => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);
    return (React.createElement(FtsFullPageLoaderIndicatorWrapper, null,
        React.createElement(DotLottieReact, { data: loader, loop: true, autoplay: true, style: { width: "192px", height: "192px" } })));
};
