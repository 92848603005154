import React from "react";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Button } from "@find-truck-service/ui/src/components/react/Button";

const VerifyEmailActionsLg = ({ onVerify, onResendCode, onLogin }) => {
  return (
    <FtsWrapper
      display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}
      direction="column"
      gap="xl"
    >
      <Button
        width="100%"
        size={ButtonSizes.md}
        onClick={onVerify}
        color={ButtonColorsValue.accent}
        type={ButtonTypes.contained}
        label={"Verify"}
      />

      <Button
        width="100%"
        size={ButtonSizes.md}
        onClick={onResendCode}
        color={ButtonColorsValue.accent}
        type={ButtonTypes.outlined}
        label={"Resend Code"}
      />

      <Button
        width="100%"
        size={ButtonSizes.md}
        onClick={onLogin}
        color={ButtonColorsValue.accent}
        type={ButtonTypes.text}
        label={"Back to Login"}
      />
    </FtsWrapper>
  );
};

export default VerifyEmailActionsLg;
