import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LoginButtons } from "./components/LoginButtons";
import { LoginFormTitle } from "./components/LoginFormTitle";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { LoginFormInputFields } from "./components/LoginFormInputFields";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { DontHaveAnAccountText } from "./components/DontHaveAnAccountText";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import { selectSocialAuthIsLoading } from "../../../redux/reducers/v2/social-auth";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { TermsAndConditions } from "../create-account/components/TermsAndConditions";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import {
  selectLoadingIsLoading,
  selectLoginError,
  selectLoginInlineMessage,
} from "../../../redux/reducers/v2/login";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import { selectHasUserData } from "../../../redux/reducers/v2/auth";
import { history } from "../../../history";
import { Pages } from "../../constants/pages";

const Login = () => {
  const hasUserData = useSelector(selectHasUserData);
  const isLoading = useSelector(selectLoadingIsLoading);
  const inlineMessage = useSelector(selectLoginInlineMessage);
  const errorMessage = useSelector(selectLoginError);
  const isSocialAuthLoading = useSelector(selectSocialAuthIsLoading);
  const [showNoAccountError, setShowNoAccountError] = useState(false);

  const inProgress = isLoading || isSocialAuthLoading;

  useEffect(() => {
    if (hasUserData) history.replace(Pages.DASHBOARD);
  }, [hasUserData]);

  return (
    <>
      {inProgress && <FullPageLoader />}
      <HeaderWrapper />
      <FtsContainer>
        <FtsRow mt={{ xs: "0", sm: "0", md: "4xl", lg: "4xl" }}>
          <FtsColumn size={{ md: 12, lg: 6 }} offset={{ md: 0, lg: 3 }}>
            <FtsRow
              boxShadow="sm"
              borderRadius="2xs"
              bg={ColorsValue["greyscale-0"]}
              px={{ xs: "xl", sm: "2xl", md: "4xl", lg: "4xl" }}
              py={{ xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" }}
              rowGap={{ xs: "2xl", sm: "2xl", md: "4xl", lg: "4xl" }}
            >
              {inlineMessage && (
                <FtsColumn size={{ xs: 12 }}>
                  <Message
                    type={"error"}
                    title={errorMessage}
                    subtitle={inlineMessage}
                  />
                </FtsColumn>
              )}

              {showNoAccountError && (
                <FtsColumn size={{ xs: 12 }}>
                  <Message
                    type={"error"}
                    title={""}
                    subtitle={
                      "No account was found for this email address, please register to create an account."
                    }
                  />
                </FtsColumn>
              )}
              <LoginFormTitle />
              <LoginFormInputFields />
              <LoginButtons setShowNoAccountError={setShowNoAccountError} />
              <DontHaveAnAccountText />
            </FtsRow>
            <TermsAndConditions />
          </FtsColumn>
        </FtsRow>
      </FtsContainer>
    </>
  );
};

export default Login;
