import { FtsSelectableBox } from "@find-truck-service/ui/src/FtsSelectableBox/FtsSelectableBox";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import React from "react";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { H3 } from "@find-truck-service/ui/src/FtsText";
import { RadioButton } from "@find-truck-service/ui/src/components/react/RadioButton";
export const SelectableBox = (props) => {
    const { wrapperProps, title, subtitle, isSelected, setSelectedAccountType, id, prependIcon } = props;
    return (React.createElement(FtsSelectableBox, { onClick: () => setSelectedAccountType(id), borderRadius: "2xs", outline: `${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]}`, p: "2xl", isSelected: isSelected, display: "flex", justify: "space-between", ...wrapperProps },
        React.createElement(FtsWrapper, { display: "flex", gap: "lg", align: "center" },
            prependIcon && React.createElement(FtsIcons, { ...prependIcon }),
            React.createElement(FtsWrapper, null,
                React.createElement(H3, { variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-800"], weight: WeightSize.bold, mb: "2xs", textAlign: "left" }, title),
                React.createElement(H3, { textAlign: "left", variant: VariantsValues["text-3xs"], color: ColorsValue["greyscale-800"] }, subtitle))),
        React.createElement(RadioButton, { size: "sm", checked: isSelected, label: "" })));
};
