import React from "react";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

export const ListingInfoSmFooterComponent = ({
  onCancel,
  primaryButtonProps,
}) => {
  return (
    <FtsWrapper
      p="xl"
      gap="lg"
      width="100%"
      direction="column"
      display={{ xs: "flex", sm: "none", md: "none", lg: "none" }}
    >
      {primaryButtonProps && (
        <Button
          width="100%"
          size={ButtonSizes.md}
          type={ButtonTypes.contained}
          color={ButtonColorsValue.accent}
          {...primaryButtonProps}
        />
      )}
      {onCancel && (
        <Button
          width="100%"
          label="Cancel"
          onClick={onCancel}
          size={ButtonSizes.md}
          type={ButtonTypes.outlined}
          color={ButtonColorsValue.accent}
        />
      )}
    </FtsWrapper>
  );
};
