import React, { createRef, useCallback, useState } from "react";
import { isObject } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { PasswordValidator } from "@find-truck-service/ui/src/components/react/PasswordValidator";
import {
  selectCompleteRegistrationData,
  selectCompleteRegistrationEmail,
  selectCompleteRegistrationInvalidFormFields,
} from "../../../../redux/reducers/v2/completeRegistration";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { Collapsible } from "@find-truck-service/ui/src/components/react/Collapsible";
import { setCompleteRegistrationData } from "../../../../redux/actions/v2/completeRegistration";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";

export const CreateRegistrationFormInputFields = () => {
  const dispatch = useDispatch();
  const recaptchaRef = createRef();
  const [showPassword, setShowPassword] = useState(false);
  const [showValidator, setShowValidator] = useState(false);

  const completeRegistrationData = useSelector(selectCompleteRegistrationData);
  const invalidFormFields = useSelector(
    selectCompleteRegistrationInvalidFormFields,
  );

  const email = useSelector(selectCompleteRegistrationEmail);

  const showPasswordValidator = useCallback(() => {
    setShowValidator(true);
  }, []);

  const showPasswordValidatorMsg =
    showValidator ||
    (isObject(invalidFormFields) && invalidFormFields["password"]);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const setStoreData = useCallback(
    (data) => dispatch(setCompleteRegistrationData({ data })),
    [dispatch],
  );

  const onChangeRecaptcha = useCallback((value) => {
    setStoreData({ recaptcha: value });
  }, []);

  const onChange = useCallback((event) => {
    setStoreData({ [event.target.name]: event.target.value });
  }, []);

  const handleToggleMarketingOptIn = useCallback((newValue) => {
    setStoreData({ primaryMarketingOptIn: newValue });
  }, []);

  return (
    <FtsColumn size={{ xs: 12 }}>
      <FtsRow columnGap={"lg"} rowGap={"2xl"}>
        <FtsColumn size={{ xs: 12 }}>
          <Input
            disabled
            name={"accountEmail"}
            label={"Account Email"}
            onChange={onChange}
            size={InputSizes.md}
            value={email}
            variant={InputVariants.outlined}
            // errorMessage={emailErrorMessage}
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }}>
          <FtsWrapper display={"flex"} gap={"lg"} direction={"column"}>
            <Input
              required
              name={"password"}
              label={"Password"}
              onChange={onChange}
              size={InputSizes.md}
              value={completeRegistrationData?.password}
              onFocus={showPasswordValidator}
              variant={InputVariants.outlined}
              appendIcon={IconNames.EyeIcon}
              onAppendIconClick={toggleShowPassword}
              type={showPassword ? "text" : "password"}
            />
            <Collapsible show={showPasswordValidatorMsg}>
              <PasswordValidator
                password={completeRegistrationData?.password || ""}
              />
            </Collapsible>
          </FtsWrapper>
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }}>
          <Checkbox
            size={CheckboxSizes.md}
            checked={completeRegistrationData?.primaryMarketingOptIn}
            label={"Receive emails regarding my account"}
            onClick={() =>
              handleToggleMarketingOptIn(
                !completeRegistrationData?.primaryMarketingOptIn,
              )
            }
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }} style={{ minHeight: "78px" }}>
          <ReCAPTCHA
            ref={recaptchaRef}
            onChange={onChangeRecaptcha}
            sitekey={process.env.REACT_APP_SITE_KEY}
          />
          {isObject(invalidFormFields) && invalidFormFields["recaptcha"] && (
            <Span variant={"text-3xs"} color={ColorsValue["red-brand-500"]}>
              {invalidFormFields["recaptcha"]}
            </Span>
          )}
        </FtsColumn>
      </FtsRow>
    </FtsColumn>
  );
};
