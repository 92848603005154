import React, { useEffect, useState } from "react";
import { HeaderWrapper } from "../components/fts-components-v2/HeaderWrapper";
import { useDispatch, useSelector } from "react-redux";
import { getShareProviderRequest } from "../redux/actions/v2/shareProvider";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { useHistory, useParams } from "react-router-dom";
import {
  SHARE_PROVIDER_REDIRECT_ROUTES,
  SHARE_PROVIDER_TYPES,
  USER_STATUSES,
} from "../utility/constants";
import { Pages } from "../views/constants/pages";
import { selectUserData } from "../redux/reducers/v2/auth";
import { removeAllCookies } from "../cookies";
import { setUserData } from "../redux/actions/v2/auth";

const TemporaryLinkProxy = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedUserData = useSelector(selectUserData);

  const { temporaryToken } = useParams();
  const [isTokenSet, setIsTokenSet] = useState(false);

  useEffect(() => {
    if (!temporaryToken) return;

    sessionStorage.setItem("temporaryToken", temporaryToken);
    // TODO: CHECK THIS
    setIsTokenSet(true);
  }, []);

  useEffect(() => {
    if (!isTokenSet) return;

    dispatch(
      getShareProviderRequest({
        onSuccess: handleProxyRedirect,
      }),
    );
  }, [isTokenSet]);

  const handleProxyRedirect = async ({ shareProviderData, userData }) => {
    // Remove cookies if user is logged in but temporary link is linked to different account
    if (loggedUserData && shareProviderData?.userId !== loggedUserData?.id) {
      await removeAllCookies();
      dispatch(setUserData({ data: null }));
    }
    let redirectUrl = SHARE_PROVIDER_REDIRECT_ROUTES[shareProviderData?.type];

    const isUserDeactivated = userData.status === USER_STATUSES.DEACTIVATED;
    const isUserDisabled = userData.status === USER_STATUSES.DISABLED;
    const isReactivateAccountRoute =
      redirectUrl ===
      SHARE_PROVIDER_REDIRECT_ROUTES[SHARE_PROVIDER_TYPES.ACCOUNT_DEACTIVATED];

    if (isUserDisabled) redirectUrl = Pages.USER_DISABLED;

    if (isUserDeactivated && !isReactivateAccountRoute)
      redirectUrl = Pages.USER_DEACTIVATED;

    history.push(redirectUrl);
  };

  return (
    <>
      <HeaderWrapper />
      <FullPageLoader />
    </>
  );

  // Jan 14th, 2025: All errors should redirect to /login
  // if (error) {
  //   return (
  //     <>
  //       <HeaderWrapper />
  //       <FtsWrapper mt="4xl">
  //         <Error
  //           title={message || SOMETHING_WENT_WRONG}
  //           subtitle={inlineMessage || UNEXPECTED_ISSUE}
  //           iconName="AlertTriangle"
  //           containedButton={{
  //             label: "Dashboard",
  //             color: ButtonColorsValue.accent,
  //             onClick: () => {
  //               history.push("/dashboard");
  //             },
  //           }}
  //         />
  //       </FtsWrapper>
  //     </>
  //   );
  // }
};

export default TemporaryLinkProxy;
