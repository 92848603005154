const listingTypes = [
  { label: "Premium listing type", value: 1 },
  { label: "Standard listing type", value: 2 },
  { label: "Basic plus listing type", value: 3 },
  { label: "Basic listing type", value: 4 },
];
export default listingTypes;

export const listingTypesArray = [
  {
    label: "Premium",
    value: 1,
    price: 498,
  },
  {
    label: "Standard",
    value: 2,
    price: 378,
  },
  {
    label: "Basic Plus",
    value: 3,
    price: 258,
  },
  {
    label: "Basic",
    value: 4,
    price: 138,
  },
  {
    label: "Free",
    value: 5,
    price: 0,
  },
];

export const listingStatusTypes = [
  {
    label: "Inactive",
    value: 0,
  },
  {
    label: "Active",
    value: 1,
  },
];
