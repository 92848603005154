import styled from "styled-components";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { defaultProps } from "@find-truck-service/ui/src/default";
import { ZIndexValues } from "@find-truck-service/types/constants/zIndexValues";
export const FtsTopBar = styled.header.withConfig({
    shouldForwardProp: (prop) => !defaultProps.includes(prop),
}) `
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: ${ZIndexValues.HEADER};
  background-color: ${ColorsValue["red-brand-500"]};
`;
//TODO Add headerBg background-image: url(${"/dist/backgrounds/HeaderBg.svg"});
