import { call, takeLatest, put } from "redux-saga/effects";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import {
  OWNER_OPERATOR_PROFILE_UPDATE_REQUEST,
  OWNER_OPERATOR_CREATE_TRUCK_REQUEST,
} from "../../reducers/v2/owner-operator-profile";
import { ownerOperatorProfileUpdateSuccess } from "../../actions/v2/ownerOperatorProfile";
import logger from "../../../utility/logger";

function* updateOwnerOperatorProfile(action) {
  const { data, onSuccess, onError } = action.payload;

  try {
    const response = yield call(FTSAxiosV2.put, "/users/update", {
      userData: data,
    });
    yield put(ownerOperatorProfileUpdateSuccess());
    onSuccess(response);
  } catch (e) {
    onError(e);
  }
}

function* createOwnerOperatorTruck(action) {
  const { data, onSuccess, onError } = action.payload;
  logger.info("DATA", data);
  try {
    const response = yield call(FTSAxiosV2.post, "/users-trucks/create", {
      truckData: data,
    });
    onSuccess(response);
  } catch (e) {
    onError(e);
  }
}

export default function* ownerOperatorProfileWatcher() {
  yield takeLatest(
    OWNER_OPERATOR_PROFILE_UPDATE_REQUEST,
    updateOwnerOperatorProfile,
  );
  yield takeLatest(
    OWNER_OPERATOR_CREATE_TRUCK_REQUEST,
    createOwnerOperatorTruck,
  );
}
