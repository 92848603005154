import axios from "axios";

import appConfig from "../configs/appConfig";
import FTSCookies, {
  AUTH_KEY,
  LISTING_DATA_ANALYTIC_API_KEY,
  PURCHASE_LISTING_TEMPORARY_TOKEN,
  REFRESH_KEY,
  removeAllCookies,
  TEMPORARY_TOKEN,
} from "../cookies";
import { withTrailingSlash } from "../utility/trailingSlash";
import { rfc3986DecodeURIComponent } from "../utility/encode-rfc";
import { catchHandle } from "../handlers/errorHandler";
import isObject from "lodash/isObject";
import { extractParameter } from "../utility/param-extract";
// import { history } from "../history";

const instance = axios.create({ baseURL: appConfig.apiURL });

export let isRefreshing = false;
export let failedQueue = [];
const TIMEOUT_SEC = 30;
instance.interceptors.request.use(
  async (req) => {
    const xAuthCookie = FTSCookies.get(AUTH_KEY);
    const temporaryToken = FTSCookies.get(TEMPORARY_TOKEN);
    const purchaseListingTemporaryToken = FTSCookies.get(
      PURCHASE_LISTING_TEMPORARY_TOKEN,
    );

    const temporaryLinkToken = extractParameter("temporaryToken");

    if (temporaryLinkToken)
      req.headers = {
        ...req.headers,
        [TEMPORARY_TOKEN]: temporaryLinkToken,
      };

    if (temporaryToken) {
      req.headers = {
        [TEMPORARY_TOKEN]: temporaryToken,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      };
    }

    if (xAuthCookie) {
      req.headers = {
        Authorization: `Bearer ${xAuthCookie}`,
      };
    }

    if (purchaseListingTemporaryToken) {
      req.headers = {
        [PURCHASE_LISTING_TEMPORARY_TOKEN]: purchaseListingTemporaryToken,
      };
    }

    req.headers[LISTING_DATA_ANALYTIC_API_KEY] =
      process.env.REACT_APP_LISTING_DATA_ANALYTIC_API_KEY;

    req.timeout = TIMEOUT_SEC * 1000;

    return req;
  },
  async (error) => {
    return Promise.reject(error);
  },
);

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  async (response) => {
    if (response.data) {
      response.data = rfc3986DecodeURIComponent(response.data);
    }
    if (
      (response.status === 200 || response.status === 201) &&
      response.data.token
    ) {
      if (!response.data.lastFourDigital) {
        await removeAllCookies();
        if (response.data?.token && isObject(response.data?.token)) {
          await storeCookies(response.data.token);
        } else await storeCookies(response.data);
      }
    }

    return response;
  },
  async (error) => {
    catchHandle(error);
    const originalRequest = error?.config;
    if (originalRequest.data && typeof originalRequest.data === "string") {
      originalRequest.data = JSON.parse(originalRequest.data);
    }
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (originalRequest.url !== "login" && originalRequest.url !== "logout") {
        window.location.href = "/dashboard";
      }
      await removeAllCookies();
    }
    if (error?.response?.status === 403 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return instance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      const refreshToken = FTSCookies.get(REFRESH_KEY);
      const token = FTSCookies.get(AUTH_KEY);

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        const baseURL = withTrailingSlash(appConfig.apiURL);

        axios
          .post(
            baseURL + "refresh_token",
            { refreshToken },
            { headers: { Authorization: `Bearer ${token}` } },
          )
          .then(async ({ data, status }) => {
            if (status === 201) {
              await removeAllCookies();
              await storeCookies(data);
              axios.defaults.headers.common["Authorization"] =
                `Bearer ${data.token}`;

              processQueue(null, data.token);
              resolve(instance(originalRequest));
            }
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  },
);

export async function storeCookies(data) {
  const path = "/";
  await FTSCookies.set(AUTH_KEY, data.token, { path });
  await FTSCookies.set(REFRESH_KEY, data.refreshToken, { path });
}

export default instance;
