import { FREE_LISTING_PRICE_ID } from "@find-truck-service/ui/src/components/react/ListingItem/constants";

export const getBiggerListingOptions = (prices, priceId) =>
  prices?.filter((price) => price.id < priceId).sort((a, b) => b.id - a.id);

export const getSmallerListingOptions = (
  prices,
  priceId,
  hasFreeActiveListing,
) =>
  prices
    ?.filter((price) => {
      if (price.id === priceId) return false;
      if (price.id === FREE_LISTING_PRICE_ID) return !hasFreeActiveListing;
      return price.id > priceId;
    })
    .sort((a, b) => a.id - b.id);
