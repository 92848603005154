import React from "react";
import { useSelector } from "react-redux";
import {
  selectTemporaryLinkInvalidFormFields,
  selectTemporaryLinkEditListingData,
} from "../../../../../redux/reducers/v2/temporaryListingLink";
import {
  setTemporaryLinkInvalidFormFields,
  setTemporaryLinkEditListingData,
} from "../../../../../redux/actions/v2/temporaryLinkListing";
import { ListingContactFields } from "../../components/ListingContactFields";

export const TemporaryLinkListingContactRow = () => {
  const invalidFormFields = useSelector(selectTemporaryLinkInvalidFormFields);
  const listingData = useSelector(selectTemporaryLinkEditListingData);

  return (
    <ListingContactFields
      listingData={listingData || {}}
      invalidFormFields={invalidFormFields || []}
      setListingData={setTemporaryLinkEditListingData}
      setListingInvalidFormFields={setTemporaryLinkInvalidFormFields}
    />
  );
};
