import React from "react";
import { useSelector } from "react-redux";
import { setListingData } from "../../../../redux/actions/v2/listing";
import { DescriptionModalContentField } from "./DescriptionModalContentField";
import { selectListingDataDescription } from "../../../../redux/reducers/v2/listing";

export const DescriptionModalContent = () => {
  const descriptionStore = useSelector(selectListingDataDescription);

  return (
    <DescriptionModalContentField
      descriptionStore={descriptionStore}
      setListingData={setListingData}
    />
  );
};
