/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";

import InfoCard from "../info-card";
import ChartHeader from "../ChartHeading/ChartHeading.js";
import ChartHeading from "../chart-header";

import "../../../../src/assets/scss/exposure-chart.scss";
import {
  getDataForChart,
  ANALYTICS_COLOR_PALETTE,
  getCustomRangeLabels,
} from "../../../utility/chart";
import ChartListingInfo from "../chart-listing-info";
import ChartLegendItem from "../../../components/fts/chart-legend-item";
import { numberWithCommas } from "../../../utility/numberWithCommas";
import SkeletonEmptyState from "../skeleton-empty-state/index.js";
import { isArray } from "lodash";

ChartJS.register(...registerables);

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "white";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "#32383A";
    tooltipEl.style.border = "1px solid #E5E8E9";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element

  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text

  if (tooltip.body) {
    tooltip.title[0] = tooltip.title[0].split(",").join(", ");
    const titleLines = tooltip.title || [];
    const bodyLines =
      tooltip.title[0] === "Mar, 2024"
        ? [
            "We've combined data from all platforms to better track user actions, searches, and listings interactions. Some data from March 2024 was lost during the transition.",
          ]
        : tooltip.body.map((b) => {
            if (parseInt(b.lines) > 1) {
              return `${b.lines} times`;
            }
            return `${b.lines} time`;
          });

    const tableHead = document.createElement("thead");
    if (tooltip.title[0] === "Mar, 2024")
      tooltip.title[0] = "New website and apps launched!";

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      const textWrapper = document.createElement("div");
      if (tooltip.title[0] === "New website and apps launched!") {
        textWrapper.style.marginLeft = "8px";
      }
      textWrapper.style.display = "flex";
      const text = document.createTextNode(title);
      textWrapper.appendChild(text);
      th.appendChild(textWrapper);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      if (tooltip.title[0] !== "New website and apps launched!") {
        span.style.background = colors.borderColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = "2px";
        span.style.marginRight = "8px";
        span.style.height = "12px";
        span.style.width = "12px";
        span.style.display = "inline-block";
        span.style.borderRadius = "2px";
      }

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      const textWrapper = document.createElement("div");
      td.appendChild(span);
      const text = document.createTextNode(body);
      if (tooltip.title[0] === "New website and apps launched!") {
        textWrapper.style.marginLeft = "8px";
        textWrapper.appendChild(text);
        td.appendChild(textWrapper);
      } else {
        td.appendChild(text);
      }
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    tableRoot.style.borderLeft = "unset";
    if (tooltip.title[0] === "New website and apps launched!") {
      tableRoot.style.borderLeft = "2px solid #CE1B3B";
    }
    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font

  let tooltipLeftPosition = positionX + tooltip.caretX + 25 + "px";
  let tooltipTopPosition = positionY + tooltip.caretY / 2;
  let isMobile = window.innerWidth < 900;

  if (tooltip.caretX + positionX > chart.chartArea.right - 100) {
    tooltipLeftPosition = tooltip.caretX - tooltip.width + 50 + "px";
  }

  if (isMobile) {
    tooltipLeftPosition = tooltip.width + "px";
    tooltipTopPosition -= 50;
  }

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = tooltipLeftPosition;
  tooltipEl.style.top = tooltipTopPosition + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

const hoverBackgroundEffect = {
  id: "hoverBackgroundEffect",
  beforeDatasetsDraw(chart) {
    const {
      ctx,
      tooltip,
      chartArea: { top, height },
      // scales: { x, y },
    } = chart;
    const isTooltipActive = tooltip?._active?.length;

    if (isTooltipActive) {
      const isFirstElement = tooltip._active[0].index === 0;
      let bgWidth = isFirstElement ? 20 : 40;
      let offset = isFirstElement ? 0 : 20;

      const startingPoint = tooltip.caretX - offset;
      ctx.fillStyle = "#e6f8fe80";
      if (tooltip.title[0] === "New website and apps launched!") {
        ctx.fillStyle = "#CE1B3B";
        ctx.fillStyle = "rgba(206,27,59,0.1)";
      }
      ctx.fillRect(startingPoint, top, bgWidth, height);
    }
  },

  afterDraw: function (chart) {
    const ctx = chart.ctx;
    const indexOfMarch = chart.scales.x.ticks.find(
      ({ label }) => label[0] === "Mar" && label[1] === 2024,
    ).value;
    const xAxis = chart.scales.x;
    const xValue = xAxis.getPixelForValue(indexOfMarch);
    let wrapper = document.getElementsByClassName("FTS-chart");

    ctx.save();
    ctx.strokeStyle = "rgb(255, 99, 132)";
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(xValue, 10);
    ctx.lineTo(xValue, chart.height - 40);
    ctx.stroke();
    ctx.restore();
  },
};

const options = {
  scales: {
    x: {
      grid: {
        borderDash: [4, 8],
        drawBorder: false,
      },
    },
    y: {
      grid: {
        borderDash: [4, 8],
        drawBorder: false,
      },
      ticks: {
        precision: 0,
      },
      beginAtZero: true,
      precision: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: "nearest",
      external: externalTooltipHandler,
    },
  },
  elements: {
    point: {
      // CAREFUL: THIS AFFECTS TOOLTIP STYLING: Line: 77 onwards
      pointBackgroundColor: "white",
      pointHoverBackgroundColor: "white",
      pointLabelFontSize: 20,
      pointRadius: 0.1,
      pointHoverRadius: 6,
    },
  },
  maintainAspectRatio: false,
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
};

const plugins = [hoverBackgroundEffect];

let infoCardsEnums = {
  top3: {
    position: 3,
    amount: 0,
    label: "In Top 3 Results",
    borderColor: ANALYTICS_COLOR_PALETTE.TOP_3,
    tooltipText:
      "This is how many times your business listing was shown in the first 3 search results to users searching on Find Truck Service desktop, mobile and apps.",
  },
  top1: {
    position: 2,
    amount: 0,
    label: "Shown as #1 Vendor",
    borderColor: ANALYTICS_COLOR_PALETTE.FIRST_VENDOR,
    tooltipText:
      "This is how many times your business listing was shown as the first result to users searching on Find Truck Service desktop, mobile and apps.",
  },
  views: {
    position: 1,
    label: "Potential Customers",
    amount: 0,
    borderColor: ANALYTICS_COLOR_PALETTE.VIEWS,
    tooltipText:
      "This is how many users clicked and viewed your business listing. Find Truck Service tracks views only when users click on your listing or land on your vendor page.",
  },
  calls: {
    label: "Calls",
    amount: 0,
    borderColor: ANALYTICS_COLOR_PALETTE.CALLS,
    tooltipText:
      "These are tracked calls your business received from our users.",
  },
};

let dataSetsEnum = {
  listingViewed: {
    position: 3,
    label: "",
    data: [],
    backgroundColor: ANALYTICS_COLOR_PALETTE.VIEWS,
    borderColor: ANALYTICS_COLOR_PALETTE.VIEWS,
    borderWidth: 1.5,
  },
  top1: {
    position: 2,
    label: "",
    data: [],
    backgroundColor: ANALYTICS_COLOR_PALETTE.FIRST_VENDOR,
    borderColor: ANALYTICS_COLOR_PALETTE.FIRST_VENDOR,
    borderWidth: 1.5,
  },
  top3: {
    position: 1,
    label: "",
    data: [],
    backgroundColor: ANALYTICS_COLOR_PALETTE.TOP_3,
    borderColor: ANALYTICS_COLOR_PALETTE.TOP_3,
    borderWidth: 1.5,
  },
  calls: {
    position: 4,
    label: "",
    data: [],
    backgroundColor: ANALYTICS_COLOR_PALETTE.CALLS,
    borderColor: ANALYTICS_COLOR_PALETTE.CALLS,
    borderWidth: 1.5,
  },
};

const ExposureChart = ({
  report,
  activeTab,
  hideTitle,
  headingInfo,
  selectedListingInfo,
  accountReport,
  wrapperClass = "",
  activeTabCounters,
  showChart = true,
  showTooltips,
  buttonAction,
  reportFrom,
  reportTo,
}) => {
  const refChart = useRef(null);

  let labels = getCustomRangeLabels(reportFrom, reportTo);

  const [infoCards, setInfoCards] = useState(Object.values(infoCardsEnums));
  const [data, setData] = useState({
    labels,
    datasets: Object.values(dataSetsEnum),
  });

  useEffect(() => {
    let newInfoCards = [];
    if (activeTabCounters) {
      Object.entries(infoCardsEnums).map(([key, val]) => {
        if (activeTabCounters[key]) {
          infoCardsEnums[key].amount = numberWithCommas(activeTabCounters[key]);
          newInfoCards.push(infoCardsEnums[key]);
        }
      });
    }
    setInfoCards(newInfoCards);
  }, [
    activeTabCounters,
    activeTabCounters?.top1,
    activeTabCounters?.top3,
    activeTabCounters?.views,
    activeTabCounters?.calls,
  ]);

  useEffect(() => {
    let newDatasets = [];

    if (report) {
      Object.entries(report).map(([key, val]) => {
        if (
          isArray(val) &&
          val.length &&
          (key === "top1" ||
            key === "top3" ||
            key === "listingViewed" ||
            key === "calls")
        ) {
          dataSetsEnum[key].data = getDataForChart(
            val || [],
            activeTab,
            labels,
          );
          newDatasets.push(dataSetsEnum[key]);
        }
      });
      setData({
        ...data,
        datasets: newDatasets.sort((a, b) => a.position - b.position),
      });
    }
  }, [
    report?.top1.length,
    report?.top3.length,
    report?.listingViewed.length,
    report?.calls.length,
  ]);

  return (
    <>
      {selectedListingInfo && !accountReport && (
        <ChartListingInfo selectedListingInfo={selectedListingInfo} />
      )}

      {wrapperClass && !hideTitle && (
        <ChartHeader
          heading="Search Exposure"
          subHeading="See the potential customers, times you were the #1 vendor and the times you were in the top 3 search results."
          headingInfo={headingInfo}
        />
      )}
      <div className={"FTS-chart-wrapper " + wrapperClass}>
        <div className="FTS-chart-cards">
          {infoCards.map((itm, index) => {
            return (
              <InfoCard
                key={Math.random() * 10000}
                label={itm.label}
                amount={itm.amount}
                borderColor={itm.borderColor}
                tooltipText={showTooltips && itm.tooltipText}
                cardId={`ExposureCard${index}`}
              />
            );
          })}
        </div>
        {showChart && report ? (
          <>
            <div className="FTS-exposure-chart-wrapper">
              <div className="FTS-chart">
                <Line
                  id={"FTS-exposure-chart"}
                  data={data}
                  options={options}
                  plugins={plugins}
                  ref={(e) => (refChart.current = e)}
                />
              </div>
            </div>
            <div className="FTS-exposure-chart-legend">
              {infoCards.map((infoCard, i) => {
                return (
                  <ChartLegendItem
                    key={i}
                    item={infoCard}
                    refChart={refChart}
                    index={i}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <SkeletonEmptyState
            title="To see the results in a graph, click below."
            iconSize={40}
            iconName="Diagram"
            buttonTitle="Show Graph"
            buttonColor="primary"
            inProgress={showChart && !report}
            buttonAction={buttonAction}
          />
        )}
      </div>
    </>
  );
};

export default ExposureChart;
