import {
  GENERATE_USER_LOGIN_MFA_REQUEST,
  GENERATE_USER_LOGIN_MFA_SUCCESS,
  GENERATE_USER_LOGIN_MFA_FAILED,
  SET_USER_MFA_CODE_VALIDATION_MESSAGE,
  TEMPORARY_TOKEN_VERIFY_USER_MFA_REQUEST,
  TEMPORARY_TOKEN_VERIFY_USER_MFA_SUCCESS,
  TEMPORARY_TOKEN_VERIFY_USER_MFA_FAILED,
  TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_REQUEST,
  TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_SUCCESS,
  TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_FAILED,
} from "../../../reducers/v2/userMfas";

export const generateUserLoginMfaRequest = (payload) => ({
  type: GENERATE_USER_LOGIN_MFA_REQUEST,
  payload,
});
export const generateUserLoginMfaSuccess = (payload) => ({
  type: GENERATE_USER_LOGIN_MFA_SUCCESS,
  payload,
});

export const generateUserLoginMfaFailed = (payload) => ({
  type: GENERATE_USER_LOGIN_MFA_FAILED,
  payload,
});

export const setUserMfaCodeValidationMessage = (payload) => ({
  type: SET_USER_MFA_CODE_VALIDATION_MESSAGE,
  payload,
});

export const temporaryTokenVerifyUserMfaRequest = (payload) => ({
  type: TEMPORARY_TOKEN_VERIFY_USER_MFA_REQUEST,
  payload,
});
export const temporaryTokenVerifyUserMfaSuccess = (payload) => ({
  type: TEMPORARY_TOKEN_VERIFY_USER_MFA_SUCCESS,
  payload,
});
export const temporaryTokenVerifyUserMfaFailed = (payload) => ({
  type: TEMPORARY_TOKEN_VERIFY_USER_MFA_FAILED,
  payload,
});

export const temporaryTokenResendVerifuUserMfaRequest = (payload) => ({
  type: TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_REQUEST,
  payload,
});

export const temporaryTokenResendVerifuUserMfaSuccess = (payload) => ({
  type: TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_SUCCESS,
  payload,
});

export const temporaryTokenResendVerifuUserMfaFailed = (payload) => ({
  type: TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_FAILED,
  payload,
});
