import { createSelector } from "reselect";

export const SET_CREATE_ACCOUNT_INVALID_FORM_FIELDS =
  "CREATE_ACCOUNT/SET_CREATE_ACCOUNT_INVALID_FORM_FIELDS";
export const SET_CREATE_ACCOUNT_DATA = "CREATE_ACCOUNT/SET_CREATE_ACCOUNT_DATA";
export const SET_CREATE_ACCOUNT_EMAIL_ALREADY_USED =
  "CREATE_ACCOUNT/SET_CREATE_ACCOUNT_EMAIL_ALREADY_USED";

export const CREATE_ACCOUNT_REQUEST = "CREATE_ACCOUNT/CREATE_ACCOUNT_REQUEST";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT/CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAILED = "CREATE_ACCOUNT/CREATE_ACCOUNT_FAILED";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  inlineMessage: null,
  invalidFormFields: null,
  emailAlreadyUsed: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CREATE_ACCOUNT_DATA:
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
      };

    case SET_CREATE_ACCOUNT_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: action.payload.data,
      };

    case SET_CREATE_ACCOUNT_EMAIL_ALREADY_USED:
      return {
        ...state,
        emailAlreadyUsed: action.payload.data,
      };

    case CREATE_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
        invalidFormFields: null,
      };

    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        inlineMessage: null,
      };

    case CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data.message,
        inlineMessage: action.payload.data.inlineMessage,
      };
    default:
      return state;
  }
}

const selectCreateAccountState = (state) => state.createAccountState;

export const selectCreateAccountInvalidFormFields = createSelector(
  selectCreateAccountState,
  (createAccountState) => {
    return createAccountState.invalidFormFields;
  },
);

export const selectCreateAccountData = createSelector(
  selectCreateAccountState,
  (createAccountState) => {
    return createAccountState.data;
  },
);

export const selectCreateAccountEmailAlreadyUsed = createSelector(
  selectCreateAccountState,
  (createAccountState) => {
    return createAccountState.emailAlreadyUsed;
  },
);

export const selectCreateAccountIsLoading = createSelector(
  selectCreateAccountState,
  (createAccountState) => {
    return createAccountState.isLoading;
  },
);
