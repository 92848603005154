import { call, takeLatest, put } from "redux-saga/effects";
import FTSV2Axios from "../../../axios/ftsv2.instance";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";
import {
  GET_SINGLE_INVOICE_REQUEST,
  GET_UPGRADE_LISTING_INVOICE_REQUEST,
} from "../../reducers/v2/invoices";
import {
  getSingleInvoiceFailed,
  getSingleInvoiceSuccess,
  getUpgradeListingInvoiceActionFailed,
  getUpgradeListingInvoiceActionSuccess,
} from "../../actions/v2/invoices";
import logger from "../../../utility/logger";

function* getSingleInvoiceSaga(action) {
  try {
    const response = yield call(FTSV2Axios.get, `/invoices/${action.payload}`);
    logger.info("RES DATA", response.data);
    yield put(getSingleInvoiceSuccess({ data: response.data.data }));
  } catch (e) {
    logger.error(e);
    const message = e.response
      ? e.response.data.message
      : "Error while getting invoice";
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(
      getSingleInvoiceFailed({
        data: { message, inlineMessage },
      }),
    );
  }
}

function* getUpgradeListingInvoiceSaga(action) {
  try {
    const response = yield call(
      FTSAxiosTemporaryLink.post,
      `/ads/upgrade-listing/temporary-token`,
      action.payload.data,
    );
    logger.info("RES DATA", response.data);
    yield put(
      getUpgradeListingInvoiceActionSuccess({ data: response.data.data }),
    );
  } catch (e) {
    logger.error(e);
    const message = e.response
      ? e.response.data.message
      : "Error while getting invoice";
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(
      getUpgradeListingInvoiceActionFailed({
        data: { message, inlineMessage },
      }),
    );
  }
}

export default function* getListingInvoiceWatcher() {
  yield takeLatest(GET_SINGLE_INVOICE_REQUEST, getSingleInvoiceSaga);
  yield takeLatest(
    GET_UPGRADE_LISTING_INVOICE_REQUEST,
    getUpgradeListingInvoiceSaga,
  );
}
