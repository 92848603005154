import React from "react";
import { useSelector } from "react-redux";
import {
  selectTemporaryLinkInvalidFormFields,
  selectTemporaryLinkListingAdsCategories,
  selectTemporaryLinkListingAdsSubCategories,
} from "../../../../../redux/reducers/v2/temporaryListingLink";
import { CategoriesPickers } from "../../components/CategoriesPickers";
import {
  setTemporaryLinkInvalidFormFields,
  setTemporaryLinkEditListingData,
} from "../../../../../redux/actions/v2/temporaryLinkListing";

export const TemporaryLinkCategoriesPickerRow = () => {
  const invalidFormFields = useSelector(selectTemporaryLinkInvalidFormFields);
  const selectedCategories = useSelector(
    selectTemporaryLinkListingAdsCategories,
  );
  const selectedSubCategories = useSelector(
    selectTemporaryLinkListingAdsSubCategories,
  );

  return (
    <CategoriesPickers
      invalidFields={invalidFormFields}
      setListingData={setTemporaryLinkEditListingData}
      selectedCategories={selectedCategories || []}
      selectedSubCategories={selectedSubCategories || []}
      setInvalidFields={setTemporaryLinkInvalidFormFields}
      showErrorMsg={invalidFormFields && invalidFormFields.adsSubCategories}
    />
  );
};
