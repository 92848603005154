import React from "react";
import { connect } from "react-redux";

import { Button, Spinner } from "reactstrap";
import Icon from "../../../components/fts/Icons";
import "../../../assets/scss/pages/account-deactivated.scss";
import { history } from "../../../history";
import { deleteDeactivatedAccount } from "../../../redux/actions/auth/loginActions";
import { toast } from "react-toastify";
import { extractParameter } from "../../../utility/param-extract";
import FTSCookies, {
  removeAllCookies,
  TEMPORARY_TOKEN,
} from "../../../cookies";
import { getUserByHash } from "../../../redux/actions/users";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import logger from "../../../utility/logger";

class DeleteAccountConfirmation extends React.Component {
  state = {
    token: null,
    deleteInProgress: false,
    inProgress: true,
    user: null,
  };

  async componentDidMount() {
    await removeAllCookies();

    const token = extractParameter("token");
    FTSCookies.set(TEMPORARY_TOKEN, token, { path: "/" });

    this.props
      .getUserByHash({ hash: token })
      .then(({ data }) => {
        const { data: user } = data;

        this.setState({
          inProgress: false,
          token,
          user,
        });
      })
      .catch((err) => {
        logger.error(err);
        history.push("/");
      });
  }

  handleDeleteAccount = async () => {
    const { token } = this.state;
    const { deleteDeactivatedAccount } = this.props;

    this.setState({
      deleteInProgress: true,
    });

    try {
      await deleteDeactivatedAccount({ token });

      this.setState({
        deleteInProgress: false,
      });

      history.push("/dashboard/register");
    } catch (err) {
      logger.error(err);
      toast.error("Something went wrong. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  render() {
    const { deleteInProgress, inProgress } = this.state;

    return (
      <div className="fts-account-deactivated">
        <HeaderWrapper />
        <div className="fts-account-deactivated-message">
          {!inProgress ? (
            <div className="fts-account-deactivated-message-box">
              <div className="fts-account-deactivated-icon-wrapper">
                <Icon name="AlertTriangle" size={40} />
              </div>
              <h3>Your account is about to be deleted permanently!</h3>
              <p>
                Are you sure you want to delete your account and create a new
                one?
              </p>
              <div className="d-flex">
                <Button
                  tag="a"
                  onClick={() => history.push("/")}
                  size="lg"
                  className="mt-2 mr-2"
                >
                  Cancel
                </Button>
                <Button
                  tag="a"
                  onClick={() => this.handleDeleteAccount()}
                  color="red"
                  size="lg"
                  className="mt-2"
                >
                  {!deleteInProgress ? `Yes, I'm sure` : <Spinner size="sm" />}
                </Button>
              </div>
            </div>
          ) : (
            <Spinner size="md" />
          )}
        </div>
      </div>
    );
  }
}

export default connect(null, {
  deleteDeactivatedAccount,
  getUserByHash,
})(DeleteAccountConfirmation);
