import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

export const CreateAccountFormTitle = () => {
  return (
    <FtsColumn size={{ xs: 12 }}>
      <Span
        weight={WeightSize.bold}
        variant={VariantsValues["text-md"]}
        color={ColorsValue["greyscale-800"]}
      >
        Create Account
      </Span>
    </FtsColumn>
  );
};
