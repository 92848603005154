import React from "react";

import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";

const FTSTableBody = (props) => {
  const onColumnMoved = () => {
    if (props?.tableName) {
      props.saveState(props?.tableName);
    } else {
      props.saveState();
    }
  };

  const onSelectionChanged = (event) => {
    props.getSelectedInvoices && props.getSelectedInvoices(props?.promoCode);
    props.getSelectedActiveLeads && props.getSelectedActiveLeads(event);
    props.getSelectedAccounts && props.getSelectedAccounts(event);
  };

  const isRowSelectable = (rowNode) =>
    rowNode.data.statusForSort !== "Paid" &&
    rowNode.data.statusForSort !== "Voided"
      ? true
      : false;

  return (
    <AgGridReact
      onColumnMoved={onColumnMoved}
      onSelectionChanged={onSelectionChanged}
      isRowSelectable={props.getSelectedInvoices && isRowSelectable}
      suppressDragLeaveHidesColumns={true}
      suppressRowClickSelection={true}
      components={{
        customLoadingOverlay: CustomLoadingOverlay,
      }}
      loadingOverlayComponent={"customLoadingOverlay"}
      loadingOverlayComponentParams={{
        loadingMessage: "One moment please...",
      }}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    promoCode: state.promoCodes.promoCode,
  };
};

export default connect(mapStateToProps)(React.memo(FTSTableBody));

function CustomLoadingOverlay() {}

CustomLoadingOverlay.prototype.init = function () {
  this.eGui = document.createElement("div");
  this.eGui.innerHTML = `
    <div role="status" class="m-1 spinner-border-lg spinner-border text-primary"/>`;
};

CustomLoadingOverlay.prototype.getGui = function () {
  return this.eGui;
};
