import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  TEMPORARY_LINK_CREATE_USER_CARD_REQUEST,
  TEMPORARY_LINK_GET_USER_CARDS_REQUEST,
  selectTemporaryLinkCardsData,
} from "../../reducers/v2/temporaryLinkUserCards";
import handleError from "../../../handlers/error";
import {
  temporaryLinkCreateUserCardFailed,
  temporaryLinkCreateUserCardSuccess,
  temporaryLinkGetUserCardsFailed,
  temporaryLinkGetUserCardsSuccess,
} from "../../actions/v2/temporaryLinkCards";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";

function* getTemporaryLinkUserCardsSaga() {
  try {
    const { data } = yield call(
      FTSAxiosTemporaryLink.get,
      "/cards/temporary-token/list",
    );

    yield put(temporaryLinkGetUserCardsSuccess({ data: data.data }));
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while getting cards";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    yield put(
      temporaryLinkGetUserCardsFailed({ data: { message, inlineMessage } }),
    );
  }
}
function* createTemporaryLinkUserCardSaga(action) {
  try {
    let cards = yield select(selectTemporaryLinkCardsData);
    const { data: payloadData } = action.payload;

    const { data } = yield call(
      FTSAxiosTemporaryLink.post,
      "/cards/temporary-token/create",
      {
        cardData: payloadData.cardData,
        paymentGateway: "stripe",
      },
    );

    yield put(temporaryLinkCreateUserCardSuccess({ data: data.data }));
    let newCardsData = [...cards, data.data];
    if (data.data.isDefault) {
      const cardsWithoutDefaultTag = cards.map((card) => ({
        ...card,
        isDefault: false,
      }));
      newCardsData = [data.data, ...cardsWithoutDefaultTag];
    }

    if (typeof action.payload.onSuccess === "function") {
      action.payload.onSuccess(data.data);
    }

    yield put(temporaryLinkGetUserCardsSuccess({ data: newCardsData }));
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while creating card";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    yield put(
      temporaryLinkCreateUserCardFailed({ data: { message, inlineMessage } }),
    );
  }
}
export default function* temporaryLinkUserCardsWatcher() {
  yield takeLatest(
    TEMPORARY_LINK_CREATE_USER_CARD_REQUEST,
    createTemporaryLinkUserCardSaga,
  );
  yield takeLatest(
    TEMPORARY_LINK_GET_USER_CARDS_REQUEST,
    getTemporaryLinkUserCardsSaga,
  );
}
