/*eslint-disable no-unused-vars*/

import React, { useState } from "react";
import CreditCard from "../../../views/pages/payment/CreditCard";
import { Button, Spinner } from "reactstrap";
import FTSModal from "../fts-modal";
import CloseButton from "../CloseButton";
import classnames from "classnames";
import Icon from "../Icons";

const ClientPaymentMethodModal = ({
  total,
  isOpen,
  title,
  closeModal,
  onClose,
  selectedInvoices,
  payWithPaypalClient,
  onClientPaymentSuccessfull,
  hash,
  payWithStripe,
}) => {
  const [activePaymentMethod, setActivePaymentMethod] = useState("");

  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const paymentStepsDefinition = {
    creditCard: {
      label: "CREDIT CARD",
      icon: "CardMethod",
      component: null,
    },

    paypal: {
      label: "PAYPAL",
      icon: "Paypal",
      component: null,
    },
  };

  return (
    <FTSModal
      {...{ isOpen, title }}
      closeModal={() => {
        !paymentInProgress && closeModal();
      }}
      onClose={() => {
        !paymentInProgress && onClose && onClose();
      }}
      modalClasses={"fts-payment-modal"}
      centered={true}
      style={{ maxWidth: "800px" }}
    >
      <div className="payment-modal-body">
        <span className="payment-modal-body-wrapper">
          <span className="payment-method-title">
            <div className="payment-method-title-text">
              <h3 className="payment-method-title-text-heading">
                Select payment method
              </h3>
              {paymentInProgress && <Spinner size={"sm"} />}
            </div>
            <CloseButton
              onClick={() => {
                !paymentInProgress && closeModal();
              }}
            />
          </span>
          <span className="payment-method-buttons">
            {Object.keys(paymentStepsDefinition).map((payment) => {
              return (
                <Button
                  disabled={paymentInProgress}
                  key={payment}
                  onClick={() => {
                    setPaymentInProgress(true);
                    setActivePaymentMethod(payment);
                    if (payment === "paypal") {
                      payWithPaypalClient({ total, selectedInvoices, hash });
                    }
                    if (payment === "creditCard") {
                      payWithStripe({ total, selectedInvoices, hash });
                    }
                  }}
                  color={
                    activePaymentMethod === payment ? "primary" : "secondary"
                  }
                  className="ach-button"
                >
                  <span className={classnames("button-body")}>
                    <Icon
                      name={paymentStepsDefinition[payment].icon}
                      size="24"
                      stroke={activePaymentMethod === payment ? "#fff" : "#000"}
                      color={activePaymentMethod === payment ? "#fff" : "#000"}
                    />
                    <span className={"ml-1"}>
                      {paymentStepsDefinition[payment].label}
                    </span>
                  </span>
                </Button>
              );
            })}
          </span>
        </span>
      </div>

      {paymentStepsDefinition[activePaymentMethod]?.component}
    </FTSModal>
  );
};

export default ClientPaymentMethodModal;
