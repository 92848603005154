import React, { useCallback, useEffect, useMemo, useState } from "react";

import axios from "axios";

import appConfig from "../../../configs/appConfig";
import {
  Button,
  Card,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap";

import { formatResponse } from "../../recipient";

import classnames from "classnames";
import { connect } from "react-redux";
import { setSelectedInvoices } from "../../../redux/actions/invoices";

import { payWithPaypal } from "../../../redux/actions/payments";
import { defineInvoicesColumnsForProofs } from "../invoices-page/invoices-columns";
import TitleRow from "../../../components/fts/TitleRow";
import ReactTable from "./ReactTable";
import ListingItem from "../../../components/fts/listing-item";
import "../../../assets/scss/pages/proofs.scss";
import { activities, LISTING_TYPE } from "../../../constants";
import { toast } from "react-toastify";
import Toggle from "react-toggle";

import RejectProofModal from "../../../components/fts/reject-proof-modal";
import Icon from "../../../components/fts/Icons";
import Icons from "../../../components/fts/Icons";
import Checkbox from "../../../components/fts/Checkbox";
import Footer from "../../../components/fts/footer";
import { removeAllCookies } from "../../../cookies";
import Breakpoint from "../../../components/hoc/Breakpoint";
import ProofsTypePicker from "../../../components/fts/proofs-type-picker";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import logger from "../../../utility/logger";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";

const Proofs = ({ match }) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [showRejectReason, setShowRejectReason] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const [activeTabListing, setActiveTabListing] = useState(null);
  const [proofs, setProofs] = useState([]);
  const [proofIds, setProofIds] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedProofs, setSelectedProofs] = useState([]);
  const [proofsCount, setProofsCount] = useState(0);
  const [clientHeight, setClientHeight] = useState(522);
  const [listingCounts, setListingCounts] = useState({
    premium: 0,
    standard: 0,
    basicPlus: 0,
    basic: 0,
    free: 0,
  });
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    removeAllCookies();
  }, []);

  const getFirstActiveTab = ({ counters }) => {
    const tabs = [
      {
        label: "Premium",
        value: "premium",
      },
      {
        label: "Standard",
        value: "standard",
      },
      {
        label: "Basic Plus",
        value: "basicPlus",
      },
      {
        label: "Basic",
        value: "basic",
      },
      {
        label: "Free",
        value: "free",
      },
    ];

    for (let tab of tabs) {
      if (counters[tab.value]) return tab.label;
    }
  };

  const getProofs = useCallback(() => {
    const type = activeTabListing?.replace(/ /g, "_").toUpperCase();
    const priceId = LISTING_TYPE[type] || LISTING_TYPE.PREMIUM;
    axios
      .get(appConfig.apiURL + "user/proofs/count", {
        headers: { "temporary-token": match.params.token },
      })
      .then(({ data }) => {
        let newData = formatResponse(JSON.stringify(data));
        newData = JSON.parse(newData);
        setListingCounts(newData);
        let count =
          newData.premium +
          newData.standard +
          newData.basicPlus +
          newData.basic +
          newData.free;

        if (!activeTabListing) {
          const activeTab = getFirstActiveTab({ counters: newData });
          setActiveTabListing(activeTab);
        }

        setProofsCount(count);
      });
    axios
      .get(appConfig.apiURL + "user/proofs", {
        params: { priceId },
        headers: { "temporary-token": match.params.token },
      })
      .then(({ data }) => {
        let newData = formatResponse(JSON.stringify(data));
        newData = JSON.parse(newData);
        setProofs(newData.rows);
        setIsLoading(false);
      })
      .catch((e) => {
        logger.error(e);
        setError("Oops! Something went wrong. Please try again.");
      });
  }, [activeTabListing, match.params.token]);

  const getUserData = useCallback(() => {
    FTSAxiosV2.get("/users/me", {
      headers: { "temporary-token": match.params.token },
    })
      .then(({ data }) => {
        setUserData(data?.user);
      })
      .catch((err) => {
        logger.error(err);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [match.params.token]);

  useEffect(() => {
    setIsLoading(true);
    if (match?.params?.token) {
      getProofs();
      getUserData();
    }
  }, [match.params.token, getProofs, getUserData]);

  const columns = useMemo(defineInvoicesColumnsForProofs, []);

  const getActiveListingCounter = useCallback(() => {
    const currentCounter =
      activeTabListing.charAt(0).toLowerCase() + activeTabListing.slice(1);

    return currentCounter.replace(/\s/g, "");
  }, [activeTabListing]);

  const handleSelectProofs = useCallback(
    (selectedProofs) => {
      let allProofsSelected =
        selectedProofs.length === listingCounts[getActiveListingCounter()];

      if (allProofsSelected) {
        setAllSelected(true);
      }

      if (!selectedProofs.length) {
        setAllSelected(false);
      }

      setSelectedProofs(selectedProofs);
    },
    [getActiveListingCounter, listingCounts],
  );

  const updateProofStatus = useCallback(
    (proofId, status) => {
      let proofIndex = proofs.findIndex((e) => e.id === proofId);
      let newProof = proofs[proofIndex];
      newProof.status = status;
      setProofs([
        ...proofs.slice(0, proofIndex),
        newProof,
        ...proofs.slice(proofIndex + 1, 100000),
      ]);
    },
    [proofs],
  );

  const handleIndividualActivities = useCallback(
    ({ type, adIds, userId }) => {
      let activityPayload = {};
      switch (type) {
        case "approved_proof":
          activityPayload = {
            status: 1,
            adIds,
            activityType: "approved_proof",
            activity: activities.approvedMultipleProofs
              .replace(
                "{{adIds}}",
                adIds.map((adId) => `#${adId}`),
              )
              .replace("{{userId}}", userId),
            iconName: "Listings",
          };
          break;

        case "rejected_proof":
          activityPayload = {
            status: 1,
            adIds,
            activityType: "rejected_proof",
            activity: activities.rejectedMultipleProofs.replace(
              "{{adIds}}",
              adIds.map((adId) => `#${adId}`).replace("{{userId}}", userId),
            ),
            iconName: "Listings",
          };
          break;

        default:
          break;
      }

      return axios.post(
        appConfig.apiURL + "user/multiple/activities",
        { ...activityPayload },
        { headers: { "temporary-token": match.params.token } },
      );
    },
    [match.params.token],
  );

  const handleMultipleProofsActivity = useCallback(
    ({ type, adIds, userId }) => {
      let activityPayload = {};
      switch (type) {
        case "approved_proof":
          activityPayload = {
            status: 1,
            userId,
            activityType: "approved_proof",
            activity: activities.approvedMultipleProofs.replace(
              "{{adIds}}",
              adIds.map((adId) => `#${adId}`).replace("{{userId}}", userId),
            ),
            iconName: "Listings",
          };
          break;

        case "rejected_proof":
          activityPayload = {
            status: 1,
            userId,
            activityType: "rejected_proof",
            activity: activities.rejectedMultipleProofs.replace(
              "{{adIds}}",
              adIds.map((adId) => `#${adId}`).replace("{{userId}}", userId),
            ),
            iconName: "Listings",
          };
          break;

        default:
          break;
      }

      return axios.post(
        appConfig.apiURL + "user/activity",
        { ...activityPayload },
        { headers: { "temporary-token": match.params.token } },
      );
    },
    [match.params.token],
  );

  const handleAcceptActivity = useCallback(
    async ({ proofs, proofIds }) => {
      if (!proofIds.length) return;

      if (proofIds.length === 1) {
        let activityPayload = {
          status: 1,
          userId: proofs[0].userId,
          adId: proofs[0].adId,
          proofId: proofs[0].id,
          activityType: "approved_proof",
          activity: activities.approvedProof
            .replace("{{adId}}", proofs[0].adId)
            .replace("{{userId}}", proofs[0].userId),
          iconName: "Listings",
        };

        axios
          .post(
            appConfig.apiURL + "user/activity",
            { ...activityPayload },
            { headers: { "temporary-token": match.params.token } },
          )
          .then((data) => logger.info(data, "SUCCESS"))
          .catch((err) => logger.error(err));
      }

      if (proofIds.length > 1) {
        let adIds = proofs.map((proof) => proof.adId);
        try {
          await handleIndividualActivities({
            type: "approved_proof",
            adIds,
            userId: proofs[0].userId,
          });
          await handleMultipleProofsActivity({
            type: "approved_proof",
            adIds,
            userId: proofs[0].userId,
          });
        } catch (err) {
          logger.error(err);
        }
      }
    },
    [
      match.params.token,
      handleMultipleProofsActivity,
      handleIndividualActivities,
    ],
  );

  const handleRejectActivity = useCallback(
    async ({ proofs, proofIds }) => {
      if (!proofIds.length) return;

      if (proofIds.length === 1) {
        let activityPayload = {
          status: 1,
          userId: proofs[0].userId,
          adId: proofs[0].adId,
          proofId: proofs[0].id,
          activityType: "rejected_proof",
          activity: activities.rejectedProof
            .replace("{{proofId}}", proofIds[0])
            .replace("{{adId}}", proofs[0].adId)
            .replace("{{userId}}", proofs[0].userId),
          iconName: "Listings",
        };

        axios
          .post(
            appConfig.apiURL + "user/activity",
            { ...activityPayload },
            { headers: { "temporary-token": match.params.token } },
          )
          .then((data) => logger.info(data, "SUCCESS"))
          .catch((err) => logger.error(err));
      }

      if (proofIds.length > 1) {
        let adIds = proofs.map((proof) => proof.adId);
        try {
          await handleIndividualActivities({
            type: "rejected_proof",
            adIds,
            userId: proofs[0].userId,
          });
          await handleMultipleProofsActivity({
            type: "rejected_proof",
            adIds,
            userId: proofs[0].userId,
          });
        } catch (err) {
          logger.error(err);
        }
      }
    },
    [
      handleIndividualActivities,
      handleMultipleProofsActivity,
      match.params.token,
    ],
  );

  const handleProofsNotification = useCallback(
    async ({ action = "approved", rows }) => {
      const adIds = rows.map((e) => `#${e?.adId}`);

      const payload = {
        title: `Listing proof(s) ${action}!`,
        description: `Vendor, ${userData?.companyName} ${action} listing proof(s) for listing(s): ${adIds}`,
        icon: "SendProof",
        sendTo: userData?.adminAccountOwner,
        status: 1,
        linkTitle: "Go to sent proofs",
        link: `/accounts/${userData?.id}?activeTab=7`,
      };

      return await axios.post(
        appConfig.apiURL + "profile/notifications/admin",
        payload,
        {
          headers: { "temporary-token": match.params.token },
        },
      );
    },
    [match.params.token, userData],
  );

  const handleButtonClick = useCallback(
    (type, rows = []) => {
      if (!type || !rows) return;

      let ids = rows.map((e) => e.id);

      if (type === "approve") {
        ids.map((id) => updateProofStatus(id, 1));
        axios
          .put(
            appConfig.apiURL + "user/proofs",
            { proofIds: ids, status: 1, lastUpdatedBy: "Vendor" },
            { headers: { "temporary-token": match.params.token } },
          )
          .then(() => {
            handleAcceptActivity({ proofs: rows, proofIds: ids });
            handleProofsNotification({ action: "approved", rows });

            toast.success("Successfully approved");
            setProofIds([]);
            setShowRejectReason(false);
          });
      }
      if (type === "reject") {
        setProofIds(ids);
        setRows(rows);
        setShowRejectReason(true);
        handleProofsNotification({ action: "rejected", rows });
      }

      if (type === "view_vendor_page") {
        window.open(
          `${window.location.origin}/page/${rows[0].listing.seoUrlIdentifier}`,
          "_blank",
        );
      }
    },
    [
      updateProofStatus,
      match.params.token,
      handleAcceptActivity,
      handleProofsNotification,
    ],
  );

  const onReject = useCallback(
    (rejectReason) => {
      proofIds.map((e) => updateProofStatus(e, 2));
      axios
        .put(
          appConfig.apiURL + "user/proofs",
          {
            proofIds,
            status: 2,
            rejectedReason: rejectReason,
            lastUpdatedBy: "Vendor",
          },
          { headers: { "temporary-token": match.params.token } },
        )
        .then(() => {
          handleRejectActivity({ proofs: rows, proofIds: proofIds });

          toast.success("Successfully rejected");
          setProofIds([]);
          setRows([]);
          setShowRejectReason(false);
        });
    },
    [
      proofIds,
      rows,
      updateProofStatus,
      match.params.token,
      setProofIds,
      setShowRejectReason,
      handleRejectActivity,
    ],
  );

  const renderRowSubComponent = useCallback(
    ({ row }) => {
      let listingData = row.original.listing;
      let originalWorkingHours = row.original.listing.workingHours;
      let { isExpanded } = row;
      const onMount = (ref) => {
        setClientHeight(ref.clientHeight);
      };

      return (
        <tr
          className="position-relative animate-expand"
          style={{ height: isExpanded ? clientHeight : 0 }}
        >
          <td
            className="d-block listing-item-wrapper"
            style={{ opacity: isExpanded ? 1 : 0, position: "absolute" }}
          >
            {isExpanded && (
              <>
                <ListingItem
                  showBtn={row.original.status === PROOF_STATUSES_ENUM.PENDING}
                  handleButtonClick={(type) =>
                    handleButtonClick(type, [row.original])
                  }
                  onMount={onMount}
                  data={listingData}
                  workingHours={originalWorkingHours}
                  hideSelectBtn
                />
              </>
            )}
          </td>
        </tr>
      );
    },
    [clientHeight, handleButtonClick],
  );

  if (error) {
    return (
      <>
        <HeaderWrapper />
        <div
          style={{ height: "50vh", width: "100vw" }}
          className={
            "d-flex flex-column justify-content-center align-items-center"
          }
        >
          <h2>{error}</h2>
        </div>
        <Footer />
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <HeaderWrapper />
        <div
          style={{ height: "50vh", width: "100vw" }}
          className={
            "d-flex flex-column justify-content-center align-items-center"
          }
        >
          <Spinner size="lg" />
        </div>
        <Footer />
      </>
    );
  }

  if (!proofsCount && !isLoading) {
    return (
      <>
        <HeaderWrapper />
        <Container>
          <Col lg={{ size: 8, offset: 2 }}>
            <Card
              style={{ padding: "80px" }}
              className="d-flex justify-content-center align-items-center rounded-lg m-5"
            >
              <Icons name={"NoProofs"} size={100} color="#fafafa" />
              <h2 className={"mt-3 mb-1"}>
                There are no listing proofs to review.
              </h2>
              <p className="text-center">
                If you need to make changes to any of your listings, please{" "}
                <a href={"/dashboard/login"}>log into your account</a> or
                contact us on (847) 586-9110.
              </p>
            </Card>
          </Col>
        </Container>
        <Footer />
      </>
    );
  }

  const generateProofTypeOptions = () => {
    return [
      {
        label: `Premium (${listingCounts.premium || 0})`,
        value: "Premium",
      },
      {
        label: `Standard (${listingCounts.standard || 0})`,
        value: "Standard",
      },
      {
        label: `Basic Plus (${listingCounts.basicPlus || 0})`,
        value: "Basic Plus",
      },
      {
        label: `Basic (${listingCounts.basic || 0})`,
        value: "Basic",
      },
      {
        label: `Free (${listingCounts.free || 0})`,
        value: "Free",
      },
    ];
  };

  return (
    <>
      <HeaderWrapper />
      <div className="custom-proofs-container">
        <h2 className={"mt-2 mb-2"}>Your Listing proofs ({proofsCount})</h2>
        <Breakpoint md up>
          <Nav className="fts-nav-tabs mb-2" tabs>
            <Tabs
              listingCounts={listingCounts}
              activeTabListing={activeTabListing}
              setActiveTabListing={setActiveTabListing}
              setSelectedProofs={setSelectedProofs}
              setAllSelected={setAllSelected}
            />
          </Nav>
        </Breakpoint>
        <Breakpoint sm down>
          <div className="proof-type-picker">
            <ProofsTypePicker
              name="proofType"
              value={activeTabListing}
              options={generateProofTypeOptions()}
              onChange={(option) => {
                if (option) {
                  setSelectedProofs([]);
                  setAllSelected(false);
                  setActiveTabListing(option?.value);
                }
              }}
              placeholder="Select type"
            />
          </div>
        </Breakpoint>
        <Row className={"mt-2 proof-row"}>
          <Col xs={{ size: 12 }} style={{ position: "unset" }}>
            <Card
              className="p-2"
              style={{ position: "unset", minHeight: "60vh" }}
            >
              <div className="ml-1">
                <TitleRow title={activeTabListing} />
              </div>
              <div className="d-flex w-100 justify-content-end controlledSwitch">
                <Toggle
                  checked={expandAll}
                  onChange={() => setExpandAll(!expandAll)}
                  name="controlledSwitch"
                  value="Expand listing view"
                />

                <span className={"ml-1"}>Expand listing view</span>
              </div>
              {isLoading ? (
                <div
                  className={
                    "d-flex flex-column justify-content-center align-items-center"
                  }
                >
                  <Spinner size={"lg"} color="primary" />
                </div>
              ) : !proofs.length ? (
                <div
                  className={
                    "d-flex flex-column justify-content-center align-items-center"
                  }
                >
                  <p>
                    You dont have pending proofs for {activeTabListing} listings
                  </p>
                </div>
              ) : (
                <div style={{ overflowX: "auto", overflowY: "hidden" }}>
                  <ReactTable
                    allSelected={allSelected}
                    expandAll={expandAll}
                    data={proofs}
                    columns={columns}
                    renderRowSubComponent={renderRowSubComponent}
                    setSelectedProofs={handleSelectProofs}
                  />
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </div>
      <div
        className="position-sticky bg-white p-2 custom-proofs-container-footer"
        style={{
          width: "100%",
          bottom: 0,
          left: 200,
          boxShadow: " 0px -2px 16px #EAEAEA",
        }}
      >
        <Container className="position-relative d-flex justify-content-center align-items-center">
          <span
            onClick={() => setAllSelected(!allSelected)}
            className="d-flex align-items-center mr-1"
            style={{ position: "absolute", left: 50 }}
          >
            <Checkbox
              id={"name"}
              checked={allSelected}
              onChange={(e) => {
                setAllSelected(e.target.checked);
              }}
            />
            <span className={"ml-1"}>
              {allSelected ? "Unselect" : "Select"} all (
              {listingCounts[getActiveListingCounter()]})
            </span>
          </span>
          <div className="d-flex custom-proofs-container-footer-content">
            <Button
              disabled={!selectedProofs.length}
              onClick={() => handleButtonClick("reject", selectedProofs)}
              color={"warning"}
              className={"btn-reject d-flex align-items-center mr-1"}
            >
              <Icon size={30} color={"#fff"} name={"Close"} />
              <span className={"ml-1"}>
                Reject selected ({selectedProofs.length})
              </span>
            </Button>
            <Button
              disabled={!selectedProofs.length}
              onClick={() => handleButtonClick("approve", selectedProofs)}
              color={"warning"}
              className={"btn-approve d-flex align-items-center"}
            >
              <Icon size={30} name={"CheckWhite"} />
              <span className={"ml-1"}>
                Approve selected ({selectedProofs.length})
              </span>
            </Button>
          </div>
        </Container>
      </div>
      {showRejectReason && (
        <RejectProofModal
          isOpen={showRejectReason}
          toggleRejectedReasonModal={() =>
            setShowRejectReason(!showRejectReason)
          }
          onReject={onReject}
        />
      )}
      <Footer />
    </>
  );
};

const mapDispatchToProps = {
  setSelectedInvoices,
  payWithPaypal,
};

export default connect(null, mapDispatchToProps)(Proofs);

const Tabs = ({
  activeTabListing,
  setActiveTabListing,
  listingCounts,
  setSelectedProofs,
  setAllSelected,
}) => {
  const tabs = [
    {
      label: "Premium",
      type: "premium",
      total: listingCounts.premium,
    },
    {
      label: "Standard",
      type: "standard",
      total: listingCounts.standard,
    },
    {
      label: "Basic Plus",
      type: "basicPlus",
      total: listingCounts.basicPlus,
    },
    { label: "Basic", type: "basic", total: listingCounts.basic },
    { label: "Free", type: "free", total: listingCounts.free },
  ];

  return tabs.map((itm) => (
    <NavItem key={itm.label}>
      <NavLink
        className={classnames({
          active: activeTabListing === itm.label,
        })}
        onClick={() => {
          setSelectedProofs([]);
          setAllSelected(false);
          setActiveTabListing(itm.label);
        }}
      >
        {itm.label} ({Number(itm.total.toFixed(1)).toLocaleString()})
      </NavLink>
    </NavItem>
  ));
};
