import React from "react";
import { useSelector } from "react-redux";
import { DesktopDescriptionField } from "../../components/DesktopDescriptionField";
import { setTemporaryLinkEditListingData } from "../../../../../redux/actions/v2/temporaryLinkListing";
import { selectTemporaryLinkListingDescription } from "../../../../../redux/reducers/v2/temporaryListingLink";

export const TemporaryLinkDesktopDescription = () => {
  const description = useSelector(selectTemporaryLinkListingDescription);

  return (
    <DesktopDescriptionField
      description={description}
      setListingData={setTemporaryLinkEditListingData}
    />
  );
};
