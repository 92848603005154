import React, { useCallback, useState } from "react";
import { Modal } from "@find-truck-service/ui/src/components/react/Modal";
import { FreeListingNoticeModalHeader } from "@find-truck-service/ui/src/components/react/FreeListingNoticeModal/components/FreeListingNoticeModalHeader";
import { FreeListingNoticeModalContent } from "@find-truck-service/ui/src/components/react/FreeListingNoticeModal/components/FreeListingNoticeModalContent";
import { FreeListingNoticeModalFooter } from "@find-truck-service/ui/src/components/react/FreeListingNoticeModal/components/FreeListingNoticeModalFooter";
export const FreeListingNoticeModal = (props) => {
    const [proceedWithFreeListingChecked, setProceedWithFreeListingChecked] = useState(false);
    const { isOpen, onClose, onError, onSuccess, onBackdropClick, size, errorIsLoading, successDisabled } = props;
    const handleChangeProceedWithFreeListing = useCallback(() => {
        setProceedWithFreeListingChecked((prevState) => !prevState);
    }, [proceedWithFreeListingChecked]);
    return (React.createElement(Modal, { onBackdropClick: onBackdropClick, modalWrapperProps: {
            isOpen: isOpen,
        }, size: size, isOpen: isOpen, modalHeaderComponent: () => React.createElement(FreeListingNoticeModalHeader, { onClose: onClose }), modalContentComponent: () => (React.createElement(FreeListingNoticeModalContent, { onClick: handleChangeProceedWithFreeListing, proceedWithFreeListingChecked: proceedWithFreeListingChecked })), modalFooterComponent: () => (React.createElement(FreeListingNoticeModalFooter, { errorDisabled: !proceedWithFreeListingChecked, onError: onError, onSuccess: onSuccess, errorIsLoading: errorIsLoading, successDisabled: successDisabled })) }));
};
