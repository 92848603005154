import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { getListingDataByIdRequest } from "../../../redux/actions/v2/listing";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import GetListedStepperIndicator from "../../../components/fts-components-v2/GetListedStepperIndicator";
import EditListingInfoContent from "../../../components/fts-components-v2/ListingBusinessInfo/EditListing/Components/EditListingInfoContent";
import { EditListingInfoSmFooter } from "../../../components/fts-components-v2/ListingBusinessInfo/EditListing/Components/EditListingInfoSmFooter";
import {
  selectIsLoadingListingData,
  selectListingDataError,
} from "../../../redux/reducers/v2/listing";
import {
  SOMETHING_WENT_WRONG,
  UNEXPECTED_ISSUE,
} from "../../../utility/errorMessages";

const EditListingBusinessDetails = (props) => {
  const dispatch = useDispatch();
  const error = useSelector(selectListingDataError);
  const isLoading = useSelector(selectIsLoadingListingData);

  useEffect(() => {
    const id = props.match.params.adId;
    if (id) dispatch(getListingDataByIdRequest({ data: { id } }));
  }, []);

  if (error)
    return (
      <FtsWrapper mt="4xl">
        <Error
          title={SOMETHING_WENT_WRONG}
          subtitle={error?.inlineMessage || UNEXPECTED_ISSUE}
          iconName="AlertTriangle"
          containedButton={{
            label: "Refresh",
            color: ButtonColorsValue.accent,
            onClick: () => history.go(0),
          }}
        />
      </FtsWrapper>
    );

  return (
    <>
      <GetListedStepperIndicator activeStepId={1} />
      <FtsContainer>
        {isLoading && <FullPageLoader />}
        <EditListingInfoContent />
        <EditListingInfoSmFooter />
      </FtsContainer>
    </>
  );
};

export default EditListingBusinessDetails;
