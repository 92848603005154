import React from "react";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import {
  SOMETHING_WENT_WRONG,
  UNEXPECTED_ISSUE,
} from "../../utility/errorMessages";

export const ErrorComponent = ({ errorMessage }) => {
  return (
    <FtsWrapper mt="4xl">
      <Error
        title={SOMETHING_WENT_WRONG}
        iconName={IconNames.AlertTriangle}
        subtitle={errorMessage || UNEXPECTED_ISSUE}
        containedButton={{
          label: "Refresh",
          color: ButtonColorsValue.accent,
          onClick: () => history.go(0),
        }}
      />
    </FtsWrapper>
  );
};
