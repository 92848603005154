import React from "react";

import { Button, Container } from "reactstrap";

import classNames from "classnames";
import moment from "moment";
import "./report-header.scss";

import html2pdf from "html2pdf.js";
const ReportHeading = ({
  renewalDate = moment().unix(),
  isAccountReport,
  reportFrom,
  reportTo,
  // renewal,
  viewType,
  // token,
  // listingReportPreview,
  // totalAmount,
}) => {
  let date = moment.unix(renewalDate);

  let startDate = reportFrom
    ? moment(reportFrom).format("MMM DD, YYYY")
    : date.subtract(1, "years").format("MMM DD, YYYY");
  let endDate = reportTo
    ? moment(reportTo).format("MMM DD, YYYY")
    : renewalDate < moment().unix()
      ? moment.unix(renewalDate).format("MMM DD, YYYY")
      : moment().format("MMM DD, YYYY");

  const downloadReport = () => {
    let outerHTML = document.getElementById("pdf").outerHTML;
    let canvas = document.getElementById("FTS-exposure-chart");
    let image = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let imageElement = document.createElement("img");

    imageElement.src = image;
    imageElement.height = canvas.offsetHeight;
    imageElement.width = canvas.offsetWidth;
    outerHTML = outerHTML.replace(canvas.outerHTML, imageElement.outerHTML);
    outerHTML = outerHTML.replace(canvas.outerHTML, imageElement.outerHTML);

    html2pdf(outerHTML, {
      html2canvas: { scale: 5 },
      filename: `FTS_Report_${startDate}-${endDate}.pdf`,
      jsPDF: { unit: "px", format: [794, 1123], orientation: "p" },
    });
    return;
  };

  return (
    <div className={classNames("header-wrapper sticked")}>
      <Container>
        <div className={"headerContentLeft"}>
          <div className={"account"}>
            <div className={"d-flex align-items-center"}>
              <span> {isAccountReport ? "Account" : "Listing"} report </span>
              <span className="beta-tag">BETA</span>
            </div>
            <span className="font-bold">
              {startDate} - {endDate}
            </span>
          </div>
        </div>

        {viewType !== "table" && (
          <div className="donwnload-pdf-report-button-wrapper">
            <Button
              onClick={downloadReport}
              variant="secondary"
              size="sm"
              className="mt-6 md:mt-0 ml-1 font-medium text-primary donwnload-pdf-report-button"
            >
              Download Report
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
};

export default ReportHeading;
