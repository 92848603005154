/* eslint-disable indent */
import { createSelector } from "reselect";

const initialState = {
  amenities: [],
  serviceAmenities: [],
  states: [],
  userStatuses: [],
  leadsStatuses: [],
  categories: [],
  subCategories: [],
  employees: [],
  types: [],
  emailsFolders: [],
  permissions: [],
  reputations: [],
  experiences: [],
  emailTypes: null,
  activitiesTypes: null,
  truckTypes: [],
  userTruckTypes: [],
  userTypes: [],
  adsPrices: null,
};

const EssentialListsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_AMENITIES_LIST":
      return { ...state, amenities: action.data };

    case "UPDATE_SERVICE_AMENITIES_LIST":
      return { ...state, serviceAmenities: action.data };

    case "UPDATE_STATES_LIST":
      return {
        ...state,
        states: action.data,
        statesList: action.data.map((g) => ({
          label: g.value,
          value: g.value,
        })),
      };

    case "UPDATE_CATEGORIES_LIST":
      return { ...state, categories: action.data };

    case "UPDATE_SUBCATEGORIES_LIST":
      return { ...state, subCategories: action.data };

    case "UPDATE_EMPLOYEES_LIST":
      return { ...state, employees: action.data };

    case "UPDATE_TYPES_LIST":
      return { ...state, types: action.data };

    case "UPDATE_EMAILS_FOLDERS_LIST":
      return { ...state, emailsFolders: action.data };

    case "UPDATE_EMAILS_TYPES":
      return { ...state, emailTypes: action.data };

    case "UPDATE_ACTIVITIES_TYPES":
      return { ...state, activitiesTypes: action.data };

    case "UPDATE_PERMISSIONS_LIST":
      return { ...state, permissions: action.data };

    case "UPDATE_REPUTATIONS_LIST":
      return { ...state, reputations: action.data };

    case "UPDATE_STATUSES_LIST":
      return {
        ...state,
        userStatuses: action.userStatuses.map((status) => ({
          value: status.id,
          label: status.name,
        })),
      };

    case "UPDATE_LEADS_STATUSES_LIST":
      return {
        ...state,
        leadsStatuses: action.leadsStatuses.map((status) => ({
          value: status.id,
          label: status.name,
        })),
      };

    case "UPDATE_PERMISSIONS":
      return {
        ...state,
        permissions: action.data,
      };

    case "UPDATE_EXPERIENCES_LIST":
      return { ...state, experiences: action.data };

    case "UPDATE_TRUCK_TYPES_LIST":
      return { ...state, truckTypes: action.data };

    case "UPDATE_USER_TRUCK_TYPES_LIST":
      return { ...state, userTruckTypes: action.data };
    case "UPDATE_RATES":
      return {
        ...state,
        rates: action.data,
      };

    case "GET_ADS_TYPES_DATA":
      return {
        ...state,
        adsPrices: action.data,
      };

    case "UPDATE_USER_TYPES":
      return { ...state, userTypes: action.data };
    default:
      return state;
  }
};

export default EssentialListsReducer;

const selectEssentialListState = (state) => state.essentialLists;

export const selectAdsRates = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.rates;
  },
);

export const selectAmenities = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.amenities;
  },
);

export const selectServiceAmenities = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.serviceAmenities;
  },
);

export const selectUsersTypes = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.userTypes;
  },
);

export const selectCategories = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.categories;
  },
);

export const selectSubCategories = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.subCategories;
  },
);

export const selectStates = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.statesList;
  },
);

export const selectAdsPrices = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.adsPrices;
  },
);

export const selectTruckTypes = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.truckTypes;
  },
);
