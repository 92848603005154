import React from "react";
import { useSelector } from "react-redux";
import { ListingInfoRatesFields } from "../../components/ListingInfoRatesFields";
import { setTemporaryLinkEditListingData } from "../../../../../redux/actions/v2/temporaryLinkListing";
import { selectTemporaryLinkListingAdsRates } from "../../../../../redux/reducers/v2/temporaryListingLink";

export const TemporaryLinkListingInfoRatesRow = () => {
  const listingAdsRates = useSelector(selectTemporaryLinkListingAdsRates);

  return (
    <ListingInfoRatesFields
      listingAdsRates={listingAdsRates ?? {}}
      setListingData={setTemporaryLinkEditListingData}
    />
  );
};
