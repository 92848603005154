import FTSAxios from "../../../axios/fts.instance";
import { apps, auth, initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/database";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import { all, put, take, takeLatest } from "redux-saga/effects";
import {
  socialAuthFailed,
  socialAuthSuccess,
} from "../../actions/v2/socialAuth";
import { createPayload, getProvider, providerEndpoints } from "./helpers";
import { SOCIAL_AUTH_REQUEST } from "../../reducers/v2/social-auth";
import { config } from "../../../authServices/firebase/firebaseConfig";
import { setUserData } from "../../actions/v2/auth";
import { history } from "../../../history";
import { setLoggedUserCookie } from "../../../cookies";
import {
  COLLECT_ESSENTIALS_SUCCESS,
  collectEssentialsRequest,
} from "../../actions/v2/essentials";

if (!apps.length) initializeApp(config);
const FIREBASE_AUTH = auth();

function* socialAuthSaga({ payload: { data, onError } }) {
  try {
    const { provider, navigateTo, type } = data;
    const authProvider = getProvider(provider);
    authProvider.addScope("email");

    const result = yield FIREBASE_AUTH.signInWithPopup(authProvider);
    if (!result.user) return yield put(socialAuthFailed());
    const payload = createPayload(result, type, provider);
    const response = yield FTSAxios.post(providerEndpoints[provider], payload);

    if (!response.data?.success) return yield put(socialAuthFailed());
    const res = yield FTSAxiosV2.get("/users/me");

    const bearerToken = response.data.token;
    let user = res?.data?.data;

    setLoggedUserCookie(user, bearerToken);

    if (type) {
      const { data: responseData } = yield FTSAxiosV2.post(
        "/users/insert-permissions",
      );

      user = responseData?.data;
    }

    yield all([
      put(setUserData({ data: user })),
      put(collectEssentialsRequest()),
      take(COLLECT_ESSENTIALS_SUCCESS),
    ]);

    history.push(navigateTo);
    yield put(socialAuthSuccess());
  } catch (error) {
    yield put(socialAuthFailed());
    if (onError) onError(error);
  }
}

export default function* socialAuthWatcher() {
  yield takeLatest(SOCIAL_AUTH_REQUEST, socialAuthSaga);
}
