import { createSelector } from "reselect";

export const SET_SHARE_PROVIDER_DATA = "SHARE_PROVIDER/SET_SHARE_PROVIDER_DATA";
export const GET_SHARE_PROVIDER_REQUEST =
  "SHARE_PROVIDER/GET_SHARE_PROVIDER_REQUEST";
export const GET_SHARE_PROVIDER_REQUEST_FAILED =
  "SHARE_PROVIDER/GET_SHARE_PROVIDER_REQUEST_FAILED";
export const GET_SHARE_PROVIDER_REQUEST_SUCCESS =
  "SHARE_PROVIDER/GET_SHARE_PROVIDER_REQUEST_SUCCESS";

const initialState = {
  data: null,
  shareProviderUserData: null,
  error: null,
  isLoading: false,
  message: null,
  inlineMessage: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SHARE_PROVIDER_DATA:
      return {
        ...state,
        invalidFormFields: null,
        data: action.payload.data,
      };

    case GET_SHARE_PROVIDER_REQUEST:
      return { ...state, isLoading: true };

    case GET_SHARE_PROVIDER_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
        message: action.payload.data.message,
        inlineMessage: action.payload.data.inlineMessage,
      };

    case GET_SHARE_PROVIDER_REQUEST_SUCCESS:
      return {
        ...initialState,
        data: action.payload.data.shareProviderData,
        shareProviderUserData: action.payload.data.shareProviderUserData,
      };

    default:
      return state;
  }
}

const selectShareProviderState = (state) => state.shareProviderState;

export const selectShareProviderData = createSelector(
  selectShareProviderState,
  (shareProviderState) => shareProviderState.data,
);

export const selectShareProviderIsLoading = createSelector(
  selectShareProviderState,
  (shareProviderState) => shareProviderState.isLoading,
);

export const selectShareProviderError = createSelector(
  selectShareProviderState,
  (shareProviderState) => shareProviderState.error,
);

export const selectShareProviderMessage = createSelector(
  selectShareProviderState,
  (shareProviderState) => shareProviderState.message,
);

export const selectShareProviderInlineMessage = createSelector(
  selectShareProviderState,
  (shareProviderState) => shareProviderState.inlineMessage,
);

export const selectShareProviderUserData = createSelector(
  selectShareProviderState,
  (shareProviderState) => shareProviderState.shareProviderUserData,
);
