import React from "react";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";

export const TemporaryLinkContentContainer = ({ children }) => {
  return (
    <FtsWrapper
      flex={1}
      display="flex"
      overflow="auto"
      direction="column"
      bg={ColorsValue["greyscale-50"]}
    >
      {children}
    </FtsWrapper>
  );
};
