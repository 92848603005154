import React from "react";

import "../../../assets/scss/components/fts/register-title-row.scss";
import classNames from "classnames";

const RegisterTitleRow = (props) => {
  return (
    <h3 className={classNames("register-title-row", props.className)}>
      {props.title}
    </h3>
  );
};

export default RegisterTitleRow;
