import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { TextStyle } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { defaultProps, defaultStyleProps } from "@find-truck-service/ui/src/default";
import { LineHeightVariantsValues, VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { activeBgLinkStyle, activeOutlineStyle, bgLinkStyle, linkStyle, outlineLinkStyle } from "./constants/style";
const activeTextStyle = `
  color: ${ColorsValue["greyscale-0"]};
`;
const textDrawerLinkStyle = `
  text-decoration: none;

  ${TextStyle({
    weight: WeightSize.medium,
    variant: VariantsValues["text-2xs"],
    color: ColorsValue["greyscale-0"],
    lineHeightVariant: LineHeightVariantsValues.relaxed,
})}

  &:hover { color: ${ColorsValue["greyscale-0"]}; }
  &:focus-visible { color: ${ColorsValue["greyscale-0"]}; }
  &:active { ${activeTextStyle} }
`;
const svgDrawerLinkStyle = `
  svg { width: ${Spacing["3xl"]}; height: ${Spacing["3xl"]}; }
  path { stroke: ${ColorsValue["greyscale-0"]}; }
`;
const spacingDrawerLinkStyle = `
  padding: ${Spacing.md};
  gap: ${Spacing.md};
`;
export const FtsDrawerLink = styled.a.withConfig({
    shouldForwardProp: (prop) => ![...defaultProps, "active"].includes(prop),
}) `
  ${bgLinkStyle};
  ${outlineLinkStyle};
  ${linkStyle};
  ${textDrawerLinkStyle};
  ${spacingDrawerLinkStyle};
  ${svgDrawerLinkStyle};
  ${({ active }) => (active ? activeTextStyle : "")};
  ${({ active }) => (active ? activeBgLinkStyle : "")};
  ${({ active }) => (active ? activeOutlineStyle : "")};
  ${defaultStyleProps};
`;
