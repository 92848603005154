import React from "react";
import { history } from "../../../../../history";
import { useDispatch, useSelector } from "react-redux";
import { extractParameter } from "../../../../../utility/param-extract";
import { updateListingRequest } from "../../../../../redux/actions/v2/listing";
import { selectListingAdsAssetsIsLoading } from "../../../../../redux/reducers/v2/listing";
import { ListingInfoSmFooterComponent } from "../../components/ListingInfoSmFooterComponent";

export const EditListingImagesMobileFooter = () => {
  const dispatch = useDispatch();
  const isDisabled = useSelector(selectListingAdsAssetsIsLoading);

  function onSuccessfulUpdate(id) {
    const priceId = extractParameter("selectedListingPriceId");
    const convertedToVendor = extractParameter("convertedToVendor");

    const searchParams = new URLSearchParams();

    if (priceId) {
      searchParams.set("selectedListingPriceId", priceId);
    }

    if (convertedToVendor) {
      searchParams.set("convertedToVendor", convertedToVendor);
    }

    history.push({
      pathname: `/dashboard/get-listed/preview-listing/${id}`,
      search: `?${searchParams.toString()}`,
    });
  }

  function updateListing() {
    dispatch(updateListingRequest({ onSuccess: onSuccessfulUpdate }));
  }

  const primaryButtonProps = {
    label: "Continue",
    disabled: isDisabled,
    onClick: updateListing,
  };

  return (
    <ListingInfoSmFooterComponent primaryButtonProps={primaryButtonProps} />
  );
};
