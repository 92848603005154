import React, { Component } from "react";

import "../../../assets/scss/components/fts/listing-item.scss";
import { Button } from "reactstrap";
import Icon from "../Icons";
import { LISTING_TYPE, LISTING_IMAGE_SIZES } from "../../../constants";
import WorkingHoursPreview from "../working-hours-preview";
import ListingItemActionBar from "../listing-item-action-bar";
import "react-quill/dist/quill.snow.css";
import "../../../assets/scss/components/fts/listing-description.scss";
import { formatImageLink } from "../../../utility";
import moment from "moment";
import TitlePaidListing from "../title-paid-listing";
import SelectListingTypeButton from "../select-listing-type-button";
import Breakpoint from "../../hoc/Breakpoint";
import BenefitsModal from "../benefits-modal";
import ListingInfoModal from "../go-free-listing-modal/listing-info-modal";
import { getListingTitle } from "../../../utility/getListingTitle";
import classNames from "classnames";
import { getListingCurrentHours } from "../../../redux/actions/listings";
import { connect } from "react-redux";
import WorkingHoursIcon from "../working-hours-icon";
import PrivateLocationBadge from "../private-location-badge";

class ListingItem extends Component {
  state = {
    collapsed: false,
    collapseButtonText: "Expand Actions",
    offsetHeight: 0,
    showBenefitsModal: false,
    showListingPreview: false,
    currentHours: null,
  };
  componentDidMount() {
    const { data: listingData } = this.props;
    if (!listingData?.listingCurrentTime) {
      (async () => {
        const { data: currentHours } = await this.props.getListingCurrentHours({
          city: listingData?.city,
          state:
            typeof listingData?.state === "object"
              ? listingData?.state?.value
              : listingData?.state,
          latitude: listingData?.latitude,
          longitude: listingData?.longitude,
        });

        this.setState({ currentHours });
      })();
    }
  }

  toggleListingInfoModal = () => {
    this.setState({
      showListingPreview: !this.state.showListingPreview,
    });
  };

  handleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      collapseButtonText: this.state.collapsed
        ? "Expand Actions"
        : "Collapse Actions",
    });
  };

  getMaxHeight = (priceId) => {
    switch (priceId) {
      case 1:
        return 244;
      case 2:
        return 120;
      case 3:
        return 60;
      default:
        return 0;
    }
  };

  toggleBenefitsModal = () => {
    this.setState({
      showBenefitsModal: !this.state.showBenefitsModal,
    });
  };

  handleSelectBenefit = () => {
    const { selectListingTypeAction, data } = this.props;
    const { priceId } = data;

    selectListingTypeAction({ priceId });
    this.setState({
      showBenefitsModal: false,
    });
  };

  generateListingWrapperClass = () => {
    const { priceId } = this.props.data;
    return getListingTitle({ priceId }).split(" ").join("-").toLowerCase();
  };

  TemporaryImagePlacement = () => {
    const { name } = this.props.data;

    return (
      <div
        className={`temporary-image-wrapper temporary-image-wrapper-${this.generateListingWrapperClass()}`}
      >
        <div
          className={`temporary-image-wrapper-title temporary-image-wrapper-${this.generateListingWrapperClass()}-title`}
        >
          {name}
        </div>
      </div>
    );
  };

  render() {
    const {
      name,
      // id,
      distance,
      address,
      city,
      state,
      alwaysOpen,
      image,
      priceId,
      description,
      includeInAuthorizedPromotions,
      fuelPrice,
      specialHours,
      mobileRoadServiceOpen,
      towingRecoveryOpen,
      // promotionsCount,
      // // seoUrlIdentifier,
      // listingCurrentTime,
    } = this.props.data;

    const listingCurrentTime =
      this.state.currentHours || this.props.data.listingCurrentTime;

    const {
      price,
      isSelected,
      selectListingTypeAction,
      hideActions,
      hideSelectBtn,
      isPrivateLocation,
    } = this.props;

    const {
      collapsed,
      collapseButtonText,
      showBenefitsModal,
      showListingPreview,
    } = this.state;
    let workingHours = this.props.workingHours || this.props.data.workingHours;
    if (workingHours?.MondayFrom) {
      let newWh = {};
      let weekArray = moment.weekdays();

      for (let day of weekArray) {
        day = day[0].toUpperCase() + day.slice(1, day.length);
        let from = workingHours[`${day}From`]?.label || "";
        let to = workingHours[`${day}To`]?.label || "";
        day = day.toLowerCase();

        newWh[day] = `${from} - ${to}`;
      }

      workingHours = newWh;
    }

    const inCity = true;
    const miles = distance?.toFixed(2);
    const imageSize = LISTING_IMAGE_SIZES[priceId];
    const fuel = fuelPrice > 0 && fuelPrice?.toFixed(2);
    const hasImageAndDescription =
      LISTING_TYPE.FREE !== priceId && LISTING_TYPE.BASIC !== priceId;
    const isFree = LISTING_TYPE.FREE === priceId;
    const freeOrBasic =
      priceId === LISTING_TYPE.FREE || priceId === LISTING_TYPE.BASIC;

    const removeStyleAttribute = (html) => {
      const regex = /style="[^"]+"/;
      return html?.replaceAll(new RegExp(regex, "g"), "");
    };

    return (
      <div
        className={classNames("fts-listing-item", {
          "fts-listing-item-free": priceId === 5,
          "fts-listing-item-bordered": isPrivateLocation,
        })}
      >
        {!hideActions && !isPrivateLocation && (
          <Breakpoint sm down>
            <TitlePaidListing priceId={priceId} price={price} />
          </Breakpoint>
        )}
        <div
          className={`fts-listing-item-content ${
            freeOrBasic ? "align-items-center" : "align-items-start"
          }`}
        >
          <div className="fts-listing-item-info">
            {isPrivateLocation && (
              <Breakpoint sm down>
                <PrivateLocationBadge />
              </Breakpoint>
            )}
            <div
              className={`title-row ${
                hideActions || isPrivateLocation ? "border-none" : ""
              }`}
            >
              <div
                className={classNames("title", {
                  "title-no-padding": isPrivateLocation,
                })}
              >
                <span>{name}</span>
              </div>
              {isPrivateLocation && (
                <Breakpoint md up>
                  <PrivateLocationBadge />
                </Breakpoint>
              )}
              {includeInAuthorizedPromotions && (
                <div className="authorized">
                  <span className="card-icon">
                    <Icon name="CreditCardRed" size="32" />
                  </span>
                  <span>Authorized Vendor</span>
                  <span className="info-icon">
                    <Icon name="IconInfo" size="13" />
                  </span>
                </div>
              )}
            </div>
            <div className="info-row">
              {!inCity && (
                <div className="info-row-item">
                  <Icon name="Miles" size="16" />
                  <span className="text">{miles} Miles</span>
                </div>
              )}
              <div className="info-row-item">
                <span>
                  <Icon name="MapPin" size="16" />
                </span>
                <span className="text">
                  {`${address}, ${city}, ${
                    typeof state === "object" ? state?.value : state
                  }`}{" "}
                  {/* <span
                    style={{

                      color: "#2C2F36",
                    }}
                  >
                    (view map)
                  </span> */}
                </span>
              </div>

              <Breakpoint lg up>
                {this.state.collapsed || !isFree ? (
                  <div className="info-row-item">
                    {/* <Icon name="Clock" size="16" /> */}
                    <WorkingHoursIcon
                      listingData={this.props.data}
                      listingCurrentTime={listingCurrentTime}
                      alwaysOpen={alwaysOpen}
                      workingHours={workingHours}
                      specialHours={specialHours}
                      mobileRoadServiceOpen={mobileRoadServiceOpen}
                      towingRecoveryOpen={towingRecoveryOpen}
                    />
                    <span className="text">
                      {(workingHours || alwaysOpen) && (
                        <WorkingHoursPreview
                          listingData={this.props.data}
                          listingCurrentTime={listingCurrentTime}
                          alwaysOpen={alwaysOpen}
                          workingHours={workingHours}
                          specialHours={specialHours}
                          mobileRoadServiceOpen={mobileRoadServiceOpen}
                          towingRecoveryOpen={towingRecoveryOpen}
                        />
                      )}
                    </span>
                  </div>
                ) : null}
              </Breakpoint>
              {fuel && (
                <div className="info-row-item">
                  <Icon name="Fuel" size="16" />
                  <span className="text">Fuel: ${fuel}</span>
                </div>
              )}
            </div>

            <div
              className={classNames("d-flex image-and-description", {
                hasImageAndDescription: "mt-6",
              })}
            >
              {hasImageAndDescription && (
                <>
                  <div className="flex justify-center cursor-pointer">
                    {imageSize &&
                      (image ? (
                        <img
                          width={imageSize.width}
                          height={imageSize.height}
                          // style={{ marginRight: "32px" }}
                          src={formatImageLink(image)}
                          alt={name}
                        />
                      ) : (
                        <this.TemporaryImagePlacement />
                      ))}
                  </div>

                  <div
                    id={"listing_item_descriptions"}
                    className="description"
                    style={{
                      maxHeight: this.getMaxHeight(priceId),
                      overflow: "hidden",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: removeStyleAttribute(description),
                    }}
                  />
                </>
              )}
            </div>
          </div>
          {!isFree && (
            <div
              className="fts-listing-item-buttons"
              style={{
                width: "145px",
              }}
            >
              <Button className="contact-button">Contact</Button>
              {/* <Button
                className="start-repair-button"
                style={{ marginTop: "12px" }}
              >
                Start Repair
              </Button> */}
            </div>
          )}

          {isFree && (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {!collapsed && !isPrivateLocation && (
                <Breakpoint md up>
                  <div
                    className={"collapse-button no-select not-collapsed"}
                    onClick={() => {
                      this.handleCollapse();
                    }}
                  >
                    <Icon name="CollapseArrow" size="12" />
                    <span>{collapseButtonText}</span>
                  </div>
                </Breakpoint>
              )}
              {collapsed && (
                <div
                  className={`fts-listing-item-buttons flex ${
                    isFree && "flex-col align-items-center"
                  }`}
                >
                  <Button
                    className="contact-button"
                    style={{ marginRight: "12px" }}
                  >
                    Contact
                  </Button>
                  {/* <Button className="start-repair-button">Start Repair</Button> */}
                </div>
              )}
            </div>
          )}
        </div>

        {!hideActions && !isPrivateLocation && (
          <Breakpoint sm down>
            {!isFree ? (
              <div className="fts-listing-item-small-buttons">
                <div className="fts-listing-item-small-buttons-preview">
                  <Button
                    color="secondary"
                    onClick={() => this.toggleListingInfoModal()}
                    className="w-100"
                  >
                    Preview
                  </Button>

                  <Button
                    color="secondary"
                    onClick={() => this.toggleBenefitsModal()}
                    className="w-100"
                  >
                    Benefits
                  </Button>
                </div>
                {!hideSelectBtn && (
                  <SelectListingTypeButton
                    isSelected={isSelected}
                    priceId={priceId}
                    action={selectListingTypeAction}
                  />
                )}
              </div>
            ) : (
              <Button
                color="secondary"
                className="mt-1 w-100"
                onClick={() => selectListingTypeAction({ priceId })}
              >
                Select Free Listing
              </Button>
            )}
          </Breakpoint>
        )}

        <ListingItemActionBar
          isFree={isFree}
          hideActions={!collapsed && !isPrivateLocation}
          isPrivateLocation={isPrivateLocation}
        />

        {showBenefitsModal && (
          <BenefitsModal
            priceId={priceId}
            toggleModal={this.toggleBenefitsModal}
            isSelected={isSelected}
            action={this.handleSelectBenefit}
          />
        )}

        {showListingPreview && (
          <ListingInfoModal
            // titleDescription={
            //   "You can update your listing information any time yourself or by calling us."
            // }
            listingData={{ ...this.props.data, priceId }}
            isOpen={true}
            handleClose={this.toggleListingInfoModal}
            hideActions
          />
        )}
      </div>
    );
  }
}

export default connect(null, { getListingCurrentHours })(ListingItem);
