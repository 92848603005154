import React from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import themeConfig from "../configs/themeConfig";
import Icon from "../components/fts/Icons";

const FullPageLayout = ({ children, bgColor }) => {
  const location = useLocation();

  return (
    <div
      style={{ backgroundColor: bgColor }}
      className={classnames("full-layout wrapper blank-page dark-layout", {
        "layout-dark": themeConfig.layoutDark,
        "bg-login-screen-image": location.pathname === "/dashboard/login",
        "bg-full-screen-image":
          location.pathname !== "/dashboard/vendor-advertise-listings",
      })}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            {location.pathname === "/login" && (
              <div
                className="FTS-logo-wrapper"
                style={{ paddingTop: 30, height: "10vh" }}
              >
                <Icon
                  name="Logo"
                  size={320}
                  strokeOpacity={0}
                  style={{ maxHeight: 30 }}
                />
              </div>
            )}
            <div
              className="flexbox-container"
              style={{
                height: location.pathname === "/login" ? "90vh" : "100vh",
              }}
            >
              <main className="main w-100">{children}</main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullPageLayout;
