import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import { all, call, put, select, take, takeLatest } from "redux-saga/effects";
import {
  CREATE_ACCOUNT_REQUEST,
  selectCreateAccountData,
} from "../../reducers/v2/createAccount";
import {
  createAccountFailed,
  createAccountSuccess,
  setCreateAccountEmailAlreadyUsed,
} from "../../actions/v2/createAccount";
import { EMAIL_IN_USE_ERROR } from "../../../components/fts-components-v2/GetListedAccount/constants";
import { USER_TYPE } from "../../../constants";
import { setUserData } from "../../actions/v2/auth";
import { history } from "../../../history";
import { setLoggedUserCookie } from "../../../cookies";
import {
  COLLECT_ESSENTIALS_SUCCESS,
  collectEssentialsRequest,
} from "../../actions/v2/essentials";
import { userRoles } from "../../../utility/constants";

const CREATE_ACCOUNT_ERROR_MSG = "Error while creating account";
const getErrorMsg = (e, defaultMessage) =>
  e.response ? e.response.data.message : defaultMessage;

function* createAccountSaga({ payload: { data } }) {
  const userData = yield select(selectCreateAccountData);
  const url = `/auth/register`;
  try {
    const response = yield call(FTSAxiosV2.post, url, {
      ...userData,
      type: data?.type,
      userType: data?.type,
      userRole: userRoles.SUPER_ADMIN,
      userTypeNamed:
        data?.type === USER_TYPE.SERVICE_VENDOR.toString() ? "vendor" : "user",
      disableUserTypeChange: true,
    });

    const { user, bearerToken } = response.data.data;
    setLoggedUserCookie(user, bearerToken);

    yield all([
      put(collectEssentialsRequest()),
      take(COLLECT_ESSENTIALS_SUCCESS),
    ]);
    yield put(setUserData({ data: user }));

    history.push(data.navigateTo);
    yield put(createAccountSuccess());
  } catch (e) {
    if (e?.response?.data?.message === EMAIL_IN_USE_ERROR)
      yield put(setCreateAccountEmailAlreadyUsed({ data: true }));

    const message = getErrorMsg(e, CREATE_ACCOUNT_ERROR_MSG);
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(createAccountFailed({ data: { message, inlineMessage } }));
  }
}

export default function* createAccountWatcher() {
  yield takeLatest(CREATE_ACCOUNT_REQUEST, createAccountSaga);
}
