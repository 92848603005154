import { put, call, takeLatest } from "redux-saga/effects";

import handleError from "../../../handlers/error";
import FTSV2Axios from "../../../axios/ftsv2.instance";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";

import {
  PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_REQUEST,
  PAY_UPGRADE_WITH_PAYPAL_REQUEST,
  PAY_WITH_EXISTING_CARD_REQUEST,
  PAY_WITH_PAYPAL_REQUEST,
  REGISTER_PAYPAL_PAYMENT_REQUEST,
  REGISTER_UPGRADE_PAYPAL_PAYMENT_REQUEST,
} from "../../reducers/v2/payments";
import {
  payUpgradeWithExistingCardByTmpTokenFailed,
  payUpgradeWithExistingCardByTmpTokenSuccess,
  payUpgradeWithPaypalFailed,
  // payUpgradeWithPaypalSuccess,
  payWithExistingCardFailed,
  payWithExistingCardSuccess,
  payWithPaypalFailed,
  registerPaypalPaymentFailed,
  registerPaypalPaymentSuccess,
  registerUpgradePaypalPaymentFailed,
  registerUpgradePaypalPaymentSuccess,
} from "../../actions/v2/payments";
import { toast } from "react-toastify";

import {
  setTemporaryLinkActiveBanner,
  temporaryLinkGetListingRequest,
} from "../../actions/v2/temporaryLinkListing";
import {
  bannerTitles,
  bannerTypes,
  bannerTypesIds,
} from "../../../constants/temporaryLinkBanners";

function* payWithExistingCardSaga(action) {
  try {
    yield call(FTSV2Axios.post, "/payments/stripe", action.payload.data);

    yield put(payWithExistingCardSuccess());
    if (typeof action.payload.onSuccess === "function") {
      action.payload.onSuccess();
    }
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    toast.error(message);
    yield put(payWithExistingCardFailed({ data: { message, inlineMessage } }));
  }
}

function* payWithPaypalSaga(action) {
  try {
    const { data } = yield call(
      FTSV2Axios.post,
      "/payments/paypal",
      action.payload.data,
    );
    window.open(data.data.link, "_self");
    // yield put(payWithPaypalSuccess());
    if (typeof action.payload.onSuccess === "function") {
      action.payload.onSuccess(data.data);
    }
  } catch (e) {
    const message = e.response
      ? e.response?.data?.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    toast.error(message);
    yield put(payWithPaypalFailed({ data: { message, inlineMessage } }));
  }
}
function* registerPaypalPaymentSaga(action) {
  try {
    const { data } = yield call(
      FTSV2Axios.post,
      "/payments/paypal/success",
      action.payload.data,
    );

    yield put(registerPaypalPaymentSuccess());
    if (typeof action.payload.onSuccess === "function") {
      action.payload.onSuccess(data.data);
    }
  } catch (e) {
    const message = e.response
      ? e.response?.data?.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    toast.error(message);
    yield put(
      registerPaypalPaymentFailed({ data: { message, inlineMessage } }),
    );
  }
}

function* payUpgradeWithExistingCardSaga(action) {
  try {
    yield call(
      FTSAxiosTemporaryLink.post,
      "/payments/temporary-token/upgrade/stripe",
      action.payload.data,
    );

    yield put(payUpgradeWithExistingCardByTmpTokenSuccess());
    if (typeof action.payload.onSuccess === "function") {
      action.payload.onSuccess();
    }
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    toast.error(message);
    yield put(
      payUpgradeWithExistingCardByTmpTokenFailed({
        data: { message, inlineMessage },
      }),
    );
  }
}

function* payUpgradeWithPaypalSaga(action) {
  try {
    const { data: payloadData, onSuccess } = action.payload;

    const { data } = yield call(
      FTSAxiosTemporaryLink.post,
      "/payments/temporary-token/upgrade/paypal",
      payloadData.paymentData,
    );

    window.open(data.data.link, "_self");
    if (typeof onSuccess === "function") {
      onSuccess(data.data);
    }
    // yield put(payUpgradeWithPaypalSuccess());
  } catch (e) {
    const message = e.response
      ? e.response?.data?.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    toast.error(message);
    yield put(payUpgradeWithPaypalFailed({ data: { message, inlineMessage } }));
  }
}

function* registerUpgradePaypalPaymentSaga(action) {
  try {
    const { data: payloadData, onSuccess } = action.payload;

    const { data } = yield call(
      FTSAxiosTemporaryLink.post,
      "/payments/temporary-token/upgrade/paypal/success",
      payloadData.paypalPayload,
    );

    yield put(temporaryLinkGetListingRequest());

    yield put(registerUpgradePaypalPaymentSuccess());
    yield put(
      setTemporaryLinkActiveBanner({
        data: {
          type: bannerTypes[bannerTypesIds.LISTING_LIVE_WAITING_PROOF],
          title: bannerTitles[bannerTypesIds.LISTING_LIVE_WAITING_PROOF],
        },
      }),
    );

    toast.success("Payment successful!");

    if (typeof onSuccess === "function") {
      onSuccess(data.data);
    }
  } catch (e) {
    const message = e.response
      ? e.response?.data?.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    toast.error(message);
    yield put(
      registerUpgradePaypalPaymentFailed({
        data: { message, inlineMessage },
      }),
    );
  }
}

export default function* paymentsWatcher() {
  yield takeLatest(PAY_WITH_EXISTING_CARD_REQUEST, payWithExistingCardSaga);
  yield takeLatest(
    PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_REQUEST,
    payUpgradeWithExistingCardSaga,
  );
  yield takeLatest(PAY_WITH_PAYPAL_REQUEST, payWithPaypalSaga);
  yield takeLatest(REGISTER_PAYPAL_PAYMENT_REQUEST, registerPaypalPaymentSaga);
  yield takeLatest(
    REGISTER_UPGRADE_PAYPAL_PAYMENT_REQUEST,
    registerUpgradePaypalPaymentSaga,
  );
  yield takeLatest(PAY_UPGRADE_WITH_PAYPAL_REQUEST, payUpgradeWithPaypalSaga);
}
