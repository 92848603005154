import { createSelector } from "reselect";

export const LISTING_PAYMENT_CREDIT_CARD_REQUEST =
  "CREATE_LISTING/LISTING_PAYMENT_CREDIT_CARD_REQUEST";
export const LISTING_PAYMENT_PAYPAL_REQUEST =
  "CREATE_LISTING/LISTING_PAYMENT_PAYPAL_REQUEST";
export const LISTING_PAYMENT_SET_INVOICE_DATA =
  "CREATE_LISTING/LISTING_PAYMENT_SET_INVOICE_DATA";

export const LISTING_PAYMENT_SET_DISCOUNT =
  "CREATE_LISTING/LISTING_PAYMENT_SET_DISCOUNT";
export const LISTING_PAYMENT_SET_APPLIED_CREDIT =
  "CREATE_LISTING/LISTING_PAYMENT_SET_APPLIED_CREDIT";
export const LISTING_PAYMENT_FAILED =
  "CREATE_LISTING/CREATE_LISTING_PAYMENT_FAILED";

const initialState = {
  creditCardLoading: false,
  payPalLoading: false,
  listingInvoice: null,
  totalToPay: 0,
  appliedCredit: 0,
  discount: 0,
  error: null,
  creditCardData: null,
  creditCardDataInvalidFormFields: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LISTING_PAYMENT_SET_INVOICE_DATA:
      return {
        ...state,
        listingInvoice: action.payload,
      };

    case LISTING_PAYMENT_SET_DISCOUNT:
      return {
        ...state,
        discount: action.payload,
      };
    case LISTING_PAYMENT_SET_APPLIED_CREDIT:
      return {
        ...state,
        appliedCredit: action.payload,
      };

    case LISTING_PAYMENT_FAILED:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}

const selectCreateListingInvoiceState = (state) => state.createListingInvoice;

export const selectCreateListingInvoiceTotalToPay = createSelector(
  selectCreateListingInvoiceState,
  (createListingInvoice) => {
    return createListingInvoice.totalToPay;
  },
);

export const selectCreateListingInvoiceDiscount = createSelector(
  selectCreateListingInvoiceState,
  (createListingInvoice) => {
    return createListingInvoice.discount;
  },
);
export const selectCreateListingInvoiceAppliedCredit = createSelector(
  selectCreateListingInvoiceState,
  (createListingInvoice) => {
    return createListingInvoice.appliedCredit;
  },
);

export const selectCreateListingCreditCardData = createSelector(
  selectCreateListingInvoiceState,
  (createListingInvoice) => {
    return createListingInvoice.creditCardData;
  },
);
export const selectCreateListingCreditCardDataInvalidFormFields =
  createSelector(selectCreateListingInvoiceState, (createListingInvoice) => {
    return createListingInvoice.creditCardDataInvalidFormFields;
  });
