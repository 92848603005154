import React, { memo } from "react";
import { AmenitiesModalContent } from "./AmenitiesModalContent";
import { ModalHeaderComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalHeaderComponent";
import { ModalFooterComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalFooterComponent";
import { FullSizeModal } from "@find-truck-service/ui/src/components/react/Modal/FullSizeModal";

const AmenitiesModal = ({ showModal, setShowModal }) => {
  function onSave() {
    setShowModal(false);
  }

  function onClose() {
    setShowModal(false);
  }

  return (
    <FullSizeModal
      isOpen={showModal}
      modalHeaderComponent={() => (
        <ModalHeaderComponent onClose={onClose} title={"Add Amenities"} />
      )}
      modalContentComponent={() => <AmenitiesModalContent />}
      modalFooterComponent={() => (
        <ModalFooterComponent successLabel={"Save"} onSuccess={onSave} />
      )}
    />
  );
};

export const MemoizedAmenitiesModal = memo(AmenitiesModal);
