import React from "react";
import "../../../assets/scss/pages/change-password-header.scss";

const ChangePasswordHeader = () => {
  return (
    <div className={"change-password-header"}>
      <div className={"change-password-header-left"}>
        <span>Change Password</span>
      </div>
    </div>
  );
};

export default ChangePasswordHeader;
