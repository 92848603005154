import React from "react";
import { useSelector } from "react-redux";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { ListingImagesMobile } from "../../../components/fts-components-v2/ListingBusinessInfo/components/ListingImagesMobile";
import { TemporaryLinkEditListingHeaderXs } from "../../../components/fts-components-v2/ListingBusinessInfo/TemporaryLinkEditListing/components/TemporaryLinkEditListingHeaderXs";
import { TemporaryLinkEditListingImagesMobileFooter } from "../../../components/fts-components-v2/ListingBusinessInfo/TemporaryLinkEditListing/components/TemporaryLinkListingImagesMobileFooter";
import {
  selectTemporaryLinkIsLoading,
  selectTemporaryLinkListingAdsAssets,
  selectTemporaryLinkEditListingData,
} from "../../../redux/reducers/v2/temporaryListingLink";
import {
  setTemporaryLinkInvalidFormFields,
  setTemporaryLinkEditListingData,
  temporaryLinkUploadAdsAssetsRequest,
} from "../../../redux/actions/v2/temporaryLinkListing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

const TemporaryLinkEditListingImages = () => {
  const isLoading = useSelector(selectTemporaryLinkIsLoading);
  const listingData = useSelector(selectTemporaryLinkEditListingData);
  const adsAssets = useSelector(selectTemporaryLinkListingAdsAssets);

  return (
    <FtsWrapper
      height={"100dvh"}
      direction={"column"}
      display={{ xs: "flex", sm: "none", md: "none", lg: "none" }}
      bg={ColorsValue["greyscale-50"]}
    >
      {isLoading && <FullPageLoader />}
      <FtsWrapper display={"flex"} direction={"column"}>
        <TemporaryLinkEditListingHeaderXs title="Listing Image (optional)" />

        <ListingImagesMobile
          adsAssets={adsAssets || []}
          listingData={listingData || {}}
          adsAssetsSize={adsAssets?.length || 0}
          setListingData={setTemporaryLinkEditListingData}
          uploadAdsAssetsRequest={temporaryLinkUploadAdsAssetsRequest}
          setListingInvalidFormFields={setTemporaryLinkInvalidFormFields}
        />
      </FtsWrapper>
      <TemporaryLinkEditListingImagesMobileFooter />
    </FtsWrapper>
  );
};

export default TemporaryLinkEditListingImages;
