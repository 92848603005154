import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchGeolocation } from "../helpers";
import { ListingGoogleMap } from "./ListingGoogleMap";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ListingMapPlaceholder } from "./ListingMapPlaceholder";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { listingMapValidator } from "../validators/listingMapValidator";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

export const ListingMapInfo = (props) => {
  const { listingData, latitude, longitude, invalidFormFields } = props;
  const { setListingData, setListingInvalidFormFields } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [mapHasError, setMapHasError] = useState(false);
  const mapRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (invalidFormFields["latitude"] || invalidFormFields["longitude"]) {
      mapRef.current.scrollIntoView({ behavior: "smooth" });
      setMapHasError(true);
    } else setMapHasError(false);
  }, [invalidFormFields]);

  useEffect(() => {
    if (latitude && longitude) setIsEditMode(true);
    else setIsEditMode(false);
  }, [latitude, longitude]);

  const fetchPosition = useCallback(async () => {
    const newLocation = await fetchGeolocation(listingData);
    const err = { ...invalidFormFields, latitude: false, longitude: false };
    dispatch(setListingData({ data: newLocation }));
    dispatch(setListingInvalidFormFields({ data: err }));
    setInProgress(false);
  }, [invalidFormFields]);

  const onConvert = useCallback(async () => {
    try {
      dispatch(setListingInvalidFormFields({ data: {} }));
      const newLocation = { latitude: null, longitude: null };
      dispatch(setListingData({ data: newLocation }));
      await listingMapValidator.validate(listingData, { abortEarly: false });
      setInProgress(true);
      await fetchPosition();
    } catch (e) {
      if (e.name !== "ValidationError") return;
      const data = e.inner.reduce((acc, error) => {
        acc[error.path] = error.errors[0];
        return acc;
      }, {});
      dispatch(setListingInvalidFormFields({ data }));
    }
  }, [listingData]);

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} width={"100%"} ref={mapRef}>
      <FtsColumn size={{ sm: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          MAP PREVIEW
        </Span>
      </FtsColumn>

      <FtsColumn size={{ sm: 12 }}>
        <FtsWrapper display={"flex"} justify={"space-between"} align={"center"}>
          {!mapHasError && (
            <Span
              weight={WeightSize.regular}
              variant={VariantsValues["text-3xs"]}
              color={ColorsValue["greyscale-800"]}
            >
              Set exact location to help users easily find you *
            </Span>
          )}
          {mapHasError && (
            <Span
              weight={WeightSize.regular}
              variant={VariantsValues["text-3xs"]}
              color={ColorsValue["red-error-500"]}
            >
              Please click "View Location on Map" and move pin if needed.
            </Span>
          )}
        </FtsWrapper>
      </FtsColumn>

      <FtsColumn size={{ sm: 12 }}>
        <ListingGoogleMap
          isEditMode={isEditMode}
          listingData={listingData}
          setListingData={setListingData}
        />
        {!isEditMode && (
          <ListingMapPlaceholder
            onConvert={onConvert}
            inProgress={inProgress}
            mapHasError={mapHasError}
          />
        )}
      </FtsColumn>
    </FtsRow>
  );
};
