import {
  CONVERT_TO_VENDOR_FAILED,
  CONVERT_TO_VENDOR_REQUEST,
  CONVERT_TO_VENDOR_SUCCESS,
  COUNT_SUB_USERS_FAILED,
  COUNT_SUB_USERS_REQUEST,
  COUNT_SUB_USERS_SUCCESS,
  GET_USER_TYPES_FAILED,
  GET_USER_TYPES_REQUEST,
  GET_USER_TYPES_SUCCESS,
  INSERT_USER_PERMISSIONS_REQUEST,
  RESEND_VERIFY_EMAIL_ADDRESS_FAILED,
  RESEND_VERIFY_EMAIL_ADDRESS_REQUEST,
  RESEND_VERIFY_EMAIL_ADDRESS_SUCCESS,
  SEND_REACTIVATE_ACCOUNT_EMAIL_REQUEST,
  SET_INVALID_FORM_FIELDS,
  SET_USER_DATA,
  UPDATE_USER_FAILED,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  VERIFY_EMAIL_ADDRESS_FAILED,
  VERIFY_EMAIL_ADDRESS_REQUEST,
  VERIFY_EMAIL_ADDRESS_SUCCESS,
} from "../../../reducers/v2/auth";
import { removeAllCookies } from "../../../../cookies";
import { history } from "../../../../history";
import { Pages } from "../../../../views/constants/pages";

export const convertToVendorRequest = (payload) => ({
  type: CONVERT_TO_VENDOR_REQUEST,
  payload,
});

export const convertToVendorFailed = (payload) => ({
  type: CONVERT_TO_VENDOR_FAILED,
  payload,
});

export const convertToVendorSuccess = (payload) => ({
  type: CONVERT_TO_VENDOR_SUCCESS,
  payload,
});

export const countSubUsersRequest = (payload) => ({
  type: COUNT_SUB_USERS_REQUEST,
  payload,
});

export const countSubUsersFailed = (payload) => ({
  type: COUNT_SUB_USERS_FAILED,
  payload,
});

export const countSubUsersSuccess = (payload) => ({
  type: COUNT_SUB_USERS_SUCCESS,
  payload,
});

export const verifyEmailAddressRequest = (payload) => ({
  type: VERIFY_EMAIL_ADDRESS_REQUEST,
  payload,
});

export const verifyEmailAddressSuccess = (payload) => ({
  type: VERIFY_EMAIL_ADDRESS_SUCCESS,
  payload,
});

export const verifyEmailAddressFailed = (payload) => ({
  type: VERIFY_EMAIL_ADDRESS_FAILED,
  payload,
});

export const resendVerifyEmailAddressRequest = (payload) => ({
  type: RESEND_VERIFY_EMAIL_ADDRESS_REQUEST,
  payload,
});

export const resendVerifyEmailAddressSuccess = (payload) => ({
  type: RESEND_VERIFY_EMAIL_ADDRESS_SUCCESS,
  payload,
});

export const resendVerifyEmailAddressFailed = (payload) => ({
  type: RESEND_VERIFY_EMAIL_ADDRESS_FAILED,
  payload,
});

export const updateUserRequest = (payload) => ({
  type: UPDATE_USER_REQUEST,
  payload,
});

export const updateUserSuccess = (payload) => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});

export const updateUserFailed = (payload) => ({
  type: UPDATE_USER_FAILED,
  payload,
});

export const setUserData = (payload) => ({
  type: SET_USER_DATA,
  payload,
});

export const setInvalidFormFields = (payload) => ({
  type: SET_INVALID_FORM_FIELDS,
  payload,
});

export const getUserTypesRequest = (payload) => ({
  type: GET_USER_TYPES_REQUEST,
  payload,
});

export const getUserTypesSuccess = (payload) => ({
  type: GET_USER_TYPES_SUCCESS,
  payload,
});

export const getUserTypesFailed = (payload) => ({
  type: GET_USER_TYPES_FAILED,
  payload,
});

export const logOutUser = () => async (dispatch) => {
  removeAllCookies().then(() => {
    history.push(Pages.LOGIN);
    localStorage.clear();
    dispatch(setUserData({ data: null }));
  });
};

export const insertUserPermissionsRequest = (payload) => ({
  type: INSERT_USER_PERMISSIONS_REQUEST,
  payload,
});

export const sendReactivateAccountEmailRequest = (payload) => ({
  type: SEND_REACTIVATE_ACCOUNT_EMAIL_REQUEST,
  payload,
});
