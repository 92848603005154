/* eslint-disable */

import React, { Component } from "react";

import { connect } from "react-redux";
import "../../../assets/scss/pages/account-report.scss";
import "../../../assets/scss/pages/fts-report-preview-page.scss";
import logo from "../../../assets/img/logo/fts-logo.svg";

import { Button, Container } from "reactstrap";

import ExposureChart from "../../../components/fts/exposure-chart";
import ListingItem from "../../../components/fts/listing-item";

import {
  getCategories,
  getListingReportPreview,
  getRelatedListingsCounters,
  getReportByHash,
} from "../../../redux/actions/reports";

import RevenueCalculator from "../../../components/fts/revenue-calculator/RevenueCalculator";
import ReportHeader from "../../../components/fts/report-header/ReportHeader";
import ReportImage from "../../../assets/img/svg/ReportImage.svg";
import { removeAllCookies } from "../../../cookies";
import moment from "moment";
import Footer from "../../../components/fts/footer";
import { rfc3986DecodeURIComponent } from "../../../utility/encode-rfc";
import ClientCardSection from "../../../components/fts/client-card-section/ClientCardSection";
import RenewBanner from "../../../components/fts/renew-banner";
import { formatReportForChart } from "../../../utility/chart";
import IndividualListingsPerformance from "../../../components/fts/individual-listings-performance";
import { toast } from "react-toastify";
import Icon from "../../../components/fts/Icons";
import queryString from "query-string";
import { REVENUE_CATEGORIES_IDS } from "../../../constants";
import TemporaryLinkExpired from "../misc/TemporaryLinkExpired";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import logger from "../../../utility/logger";

const accountIdsWithoutIndividualListings = [7058, 6729];
class Report extends Component {
  state = {
    isLoading: true,
    isExpired: false,
    report: null,
    rangeTab: "1y",
    reportFrom: "2021-08-23",
    reportTo: "2022-08-23",
    relatedAdsCount: null,
    totalListingsCount: null,
    reportTitle: "",
    error: false,
    callsNumber: 0,
    viewType: "report",
    listingReportPreview: false,
    listingPreviewInProgress: false,
  };

  componentDidMount = () => {
    removeAllCookies();

    this.setState({ isLoading: true });

    if (this.props.match?.params?.token) {
      let promises = [
        this.props.getReportByHash(this.props.match.params.token),
        this.props.getCategories(),
      ];

      Promise.all(promises)
        .then(([{ data: hashReportData }]) => {
          let data = rfc3986DecodeURIComponent(hashReportData);

          // TODO MEVLUDIN: Check this decocoding>>
          // data = JSON.parse(rfc3986DecodeURIComponent(data));
          const {
            reportData,
            reportFrom,
            reportTo,
            relatedAdsCount,
            expirationDate,
            includeRevenueCalculator,
          } = data.report;

          const report = formatReportForChart(JSON.parse(reportData) || "{}");
          let callsNumber = 0;
          let callsReportData = [];

          report.customRange.calls.map((e) => {
            callsReportData.push(e);
            return (callsNumber += e.value);
          });

          const queryParams = queryString.parse(this.props.location.search);

          this.setState({
            callsReportData,
            isExpired: moment() > moment(expirationDate),
            report,
            isLoading: !!(queryParams.listingId && data.isAccountReport),
            user: data?.user,
            isAccountReport: data.isAccountReport,
            accountReport: report,
            accountActiveTabCounters: this.handleReportCounters(report),
            reportFrom,
            reportTo,
            reportTitle: data.report.title,
            relatedAdsCount,
            totalListingsCount: data.totalListingsCount,
            includePaymentLink: data.report.includePaymentLink,
            includeFreeListingReport: data.report.includeFreeListingReport,
            listingData:
              data?.report?.listing || data.report?.relatedListings?.[0],
            accountReportListingData: data.report?.relatedListings?.[0],
            accountCallsReportData: callsReportData,
            accountReportTitle: data.report.title,
            callsNumber,
            activeTabCounters: this.handleReportCounters(report),
            totalAmount: data?.totalAmount,
            relatedListings: data?.report?.relatedListings,
            includeRevenueCalculator,
          });
          if (queryParams.listingId && data.isAccountReport) {
            return this.toggleListingReportPreview(queryParams.listingId);
          }
          if (queryParams.viewType) {
            return this.changeViewType(queryParams.viewType);
          }
        })
        .catch((e) => {
          logger.error(e);
          this.setState({ error: true });
        });
    }
  };

  changeViewType = (value) => {
    this.setState({ viewType: value }, () => {
      if (value === "table") {
        setTimeout(() => {
          let element = document.getElementById(
            "individual-listings-performance",
          );
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 200);
      }
    });
  };

  handleReportCounters = (reports) => {
    const customReportData = reports["customRange"];

    return {
      top1: customReportData?.top1Value,
      top3: customReportData?.top3Value,
      calls: customReportData?.callsValue,
      views: customReportData?.listingViewedValue,
    };
  };

  setIsAccountReport = (value) => {
    if (value) {
      if (this.state.accountReport) {
        return this.setState(
          {
            activeTabCounters: this.state.accountActiveTabCounters,
            listingData: this.state.accountReportListingData,
            report: this.state.accountReport,
            callsReportData: this.state.accountCallsReportData,
            reportTitle: this.state.accountReportTitle,
            isAccountReport: value,
          },
          () => this.changeViewType("report"),
        );
      }
    }
    this.setState({
      isAccountReport: value,
    });
  };

  toggleListingReportPreview = (listingId) => {
    if (this.state.listingPreviewInProgress) return;
    if (this.state.listingReportPreview) {
      this.setState({
        listingReportPreview: !this.state.listingReportPreview,
        listingReportPreviewData: null,
        viewType: "table",
        isLoading: false,
      });
    } else {
      this.setState({
        listingPreviewInProgress: true,
      });
      this.props
        .getListingReportPreview(this.props.match.params.token, {
          listingId,
          startAt: this.state.reportFrom,
          endAt: this.state.reportTo,
        })
        .then((res) => {
          const data = rfc3986DecodeURIComponent(res?.data);
          let { listing } = data;
          const report = formatReportForChart(data.report || "{}");
          let callsNumber = 0;
          let callsReportData = [];

          report.customRange.calls.map((e) => {
            callsReportData.push(e);
            return (callsNumber += e.value);
          });

          this.setState({
            activeTabCounters: this.handleReportCounters(report),
            accountReportListingData: listing,
            // accountReport: this.state.report,
            accountCallsReportData: this.state.callsReportData,
            accountReportTitle: this.state.reportTitle,
            callsReportData: report.customRange.calls,
            listingData: listing,
            report,
            reportTitle: this.state.reportTitle,
            listingReportPreview: !this.state.listingReportPreview,
            listingPreviewInProgress: false,
            isAccountReport: false,
            viewType: "report",
            isLoading: false,
            includeRevenueCalculator: res.data.report.includeRevenueCalculator,
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((e) => {
          logger.error(e);
          toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ error: true, listingPreviewInProgress: false });
        });
    }
  };

  render() {
    const {
      isLoading,
      rangeTab,
      report,
      user,
      isAccountReport,
      reportFrom,
      reportTo,
      reportTitle,
      relatedAdsCount,
      totalListingsCount,
      error,
      listingData,
      callsNumber,
      callsReportData,
      includePaymentLink,
      includeFreeListingReport,
      activeTabCounters,
      totalAmount,
      viewType,
      relatedListings,
      listingReportPreview,
      listingPreviewInProgress,
      isExpired,
      includeRevenueCalculator,
      accountReport,
    } = this.state;
    const { categories, totalRecordsListings } = this.props;
    if (error) window.location.href = "/dashboard/404";

    if (!isLoading && isExpired)
      return <TemporaryLinkExpired accountId={user?.id} />;

    return (
      <>
        {isLoading ? (
          <FullPageLoader />
        ) : (
          <div className="FTS-temporary-link-report">
            <div id={"pdf-wrapper"}>
              <div id={"pdf"}>
                <div
                  style={{
                    background: "#CE1B3B",
                    padding: 14,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img alt={"alt-logo-header"} src={logo} />
                </div>
                <AccountReportPdf
                  includeRevenueCalculator={includeRevenueCalculator}
                  callsNumber={callsNumber}
                  user={user}
                  isAccountReport={isAccountReport}
                  reportFrom={reportFrom}
                  reportTo={reportTo}
                  categories={categories}
                  rangeTab={rangeTab}
                  totalRecordsListings={totalRecordsListings}
                  totalListingsCount={totalListingsCount}
                  relatedAdsCount={relatedAdsCount}
                  userEngagementId={"FTS-user-engagement"}
                  hideHeader
                  hideRevenue
                  listingData={listingData}
                  callsReportData={callsReportData}
                  isTemporaryLink={!!this.props.match.params.token}
                  includePaymentLink={includePaymentLink}
                  includeFreeListingReport={includeFreeListingReport}
                  report={report["customRange"]}
                  activeTabCounters={activeTabCounters}
                  totalAmount={totalAmount}
                  token={this.props.match.params.token}
                  viewType={viewType}
                  changeViewType={this.changeViewType}
                  relatedListings={relatedListings}
                  preview
                  listingId={listingData.id}
                />
              </div>
            </div>
            <AccountReportPdf
              includeRevenueCalculator={includeRevenueCalculator}
              callsNumber={callsNumber}
              reportTitle={reportTitle}
              user={user}
              isAccountReport={isAccountReport}
              reportFrom={reportFrom}
              reportTo={reportTo}
              categories={categories}
              rangeTab={rangeTab}
              totalRecordsListings={totalRecordsListings}
              totalListingsCount={totalListingsCount}
              relatedAdsCount={relatedAdsCount}
              listingData={listingData}
              listingId={listingData.id}
              callsReportData={callsReportData}
              isTemporaryLink={!!this.props.match.params.token}
              includePaymentLink={includePaymentLink}
              includeFreeListingReport={includeFreeListingReport}
              report={report["customRange"]}
              activeTabCounters={activeTabCounters}
              totalAmount={totalAmount}
              token={this.props.match.params.token}
              viewType={viewType}
              changeViewType={this.changeViewType}
              relatedListings={relatedListings}
              toggleListingReportPreview={this.toggleListingReportPreview}
              listingReportPreview={listingReportPreview}
              listingPreviewInProgress={listingPreviewInProgress}
              setIsAccountReport={this.setIsAccountReport}
              showTooltips={true}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.essentialLists?.categories,
  };
};

const mapDispatchToProps = {
  getReportByHash,
  getCategories,
  getRelatedListingsCounters,
  getListingReportPreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);

export const AccountReportPdf = ({
  user,
  isAccountReport,
  reportFrom,
  reportTo,
  categories,
  report,
  rangeTab,
  totalRecordsListings,
  hideHeader,
  hideRevenue,
  reportTitle,
  relatedAdsCount,
  totalListingsCount,
  listingData,
  callsNumber,
  callsReportData,
  isTemporaryLink,
  includePaymentLink,
  includeFreeListingReport,
  activeTabCounters,
  totalAmount,
  token,
  viewType,
  changeViewType,
  relatedListings,
  preview,
  toggleListingReportPreview,
  listingReportPreview,
  setIsAccountReport,
  listingId,
  showTooltips,
  includeRevenueCalculator,
}) => {
  logger.info("report: ", report);
  return (
    <>
      {!hideHeader && (
        <>
          <HeaderWrapper />
          <ReportHeader
            isAccountReport={isAccountReport}
            reportTitle={reportTitle}
            reportFrom={reportFrom}
            reportTo={reportTo}
            renewal={listingData?.renewalTimestamp}
            viewType={viewType}
            token={token}
            listingReportPreview={listingReportPreview}
            totalAmount={totalAmount}
          />
        </>
      )}
      {(viewType === "report" || listingReportPreview) && (
        <div className="fts-report-preview-page">
          <Container>
            <div className="fts-listing-tab-wrapper account-report">
              <div className="account-report-title-row">
                <div>
                  {isAccountReport && (
                    <>
                      <h2 className="m-0 p-0">Account performance report</h2>
                      <div className={"redUnderline"} />
                      {relatedAdsCount ? (
                        <p>
                          The analytics below take into account combined metrics
                          for {relatedAdsCount}/{totalListingsCount} of your
                          listings.
                        </p>
                      ) : (
                        <p>
                          The analytics below take into account combined
                          analytics for all your {totalRecordsListings}{" "}
                          listing(s).
                        </p>
                      )}
                    </>
                  )}
                  {!isAccountReport && (
                    <>
                      {listingReportPreview && (
                        <div
                          onClick={() => {
                            toggleListingReportPreview(listingData.id);
                          }}
                          className="listing-report-preview-back-btn"
                        >
                          <Icon name="ChevronLeft" />
                          <div>Back to all Listings</div>
                        </div>
                      )}
                      <h2 className="m-0 p-0">Listing performance report</h2>
                      <div className={"redUnderline"} />
                      <p>
                        The analytics on this report are for the below listing.
                      </p>
                    </>
                  )}
                </div>
                {isAccountReport &&
                  relatedAdsCount > 1 &&
                  !accountIdsWithoutIndividualListings.includes(user.id) && (
                    <Button
                      color="secondary"
                      className="report-page-btn"
                      onClick={() => {
                        changeViewType("table");
                      }}
                    >
                      View all listings
                    </Button>
                  )}
                {/*todo revert this once update listing is ready*/}
                {/*{!isAccountReport && (*/}
                {/*  <a*/}
                {/*    href={`/dashboard/update-listing/${token}?listingId=${listingId}`}*/}
                {/*  >*/}
                {/*    <Button color="secondary" className="report-page-btn">*/}
                {/*      Update Listing*/}
                {/*    </Button>*/}
                {/*  </a>*/}
                {/*)}*/}
              </div>
              <div className={"account-details-banner"}>
                <div className={"logo"}>
                  <img
                    alt={"alt-logo-company-header"}
                    src={user?.image || ReportImage}
                  />
                </div>

                <div>
                  <h2>
                    {user?.companyName
                      .replace(/\+/g, " ")
                      .replace(/%22/g, "'")
                      .replace(/%27/g, "'")}
                  </h2>
                  <p>
                    {isAccountReport ? "Account " : "Listing "} Report:
                    {`${moment(reportFrom).format("MMMM Do YYYY")} - ${moment(
                      reportTo,
                    ).format("MMMM Do YYYY")}`}
                  </p>
                </div>
                <div className="arrow"></div>
              </div>
              {listingData && <ListingItem data={listingData} hideActions />}
              {relatedAdsCount > 1 && isAccountReport && (
                <>
                  <Button
                    color="secondary"
                    className="more-listings-btn justify-content-center align-items-center d-flex rounded-sm"
                    disabled={
                      !!accountIdsWithoutIndividualListings.includes(user.id)
                    }
                    onClick={() => {
                      changeViewType("table");
                    }}
                  >
                    <span>+{relatedAdsCount - 1} More</span>
                  </Button>
                </>
              )}
              <div className="exposure-chart-wrapper">
                <ExposureChart
                  wrapperClass={"new-wrapper"}
                  report={report}
                  reportFrom={reportFrom}
                  reportTo={reportTo}
                  activeTab={rangeTab}
                  totalRecordsListings={totalRecordsListings}
                  activeTabCounters={activeTabCounters}
                  showTooltips={showTooltips}
                />
              </div>
            </div>

            {!preview && includeRevenueCalculator ? (
              <div className="FTS-revenue-chart-heading">
                <h2>
                  See your advertising ROI and compare to industry averages.
                </h2>
              </div>
            ) : null}

            {!hideRevenue && includeRevenueCalculator ? (
              <div
                className={
                  "fts-listing-tab-wrapper account-report revenue-calculator-wrapper"
                }
              >
                <RevenueCalculator
                  categories={categories.filter((e) =>
                    REVENUE_CATEGORIES_IDS.includes(e.id),
                  )}
                  calls={callsNumber}
                  data={callsReportData}
                  reportFrom={reportFrom}
                  reportTo={reportTo}
                  isTemporaryLink={isTemporaryLink}
                />
              </div>
            ) : null}
          </Container>
        </div>
      )}

      {viewType === "table" && !preview && (
        <IndividualListingsPerformance
          changeViewType={changeViewType}
          relatedListings={relatedListings}
          token={token}
          listingReportPreview={listingReportPreview}
          toggleListingReportPreview={toggleListingReportPreview}
          setIsAccountReport={setIsAccountReport}
          includeFreeListingReport={includeFreeListingReport}
        />
      )}

      <ClientCardSection />
      <RenewBanner
        isAccountReport={isAccountReport}
        renewal={listingData?.renewalTimestamp}
        totalAmount={totalAmount}
        token={token}
      />
      <Footer />
    </>
  );
};

export const ListingReportPdf = ({
  user,
  isAccountReport,
  reportFrom,
  reportTo,
  report,
  rangeTab,
  totalRecordsListings,
  listingId,
  activeTabCounters,
  viewType,
}) => {
  return (
    <>
      <div className="fts-report-preview-page">
        <Container>
          <div className="fts-listing-tab-wrapper account-report ">
            <h1 className="m-0 p-0">Listing performance report</h1>
            <div className={"redUnderline"} />
            <p>
              The analytics below take into account combined metrics for your
              listing #{listingId}.
            </p>
            <div className={"account-details-banner"}>
              <div className={"logo"}>
                <img
                  alt={"alt-logo-company-header"}
                  src={user?.image || ReportImage}
                />
              </div>
              <div>
                <h2>{user?.companyName}</h2>
                <p>
                  {isAccountReport ? "Account " : "Listing "} Report:
                  {`${moment(reportFrom).format("MMMM Do YYYY")} - ${moment(
                    reportTo,
                  ).format("MMMM Do YYYY")}`}
                </p>
              </div>
              <div className="arrow"></div>
            </div>
            <ExposureChart
              wrapperClass={"new-wrapper"}
              report={report}
              activeTab={rangeTab}
              reportFrom={reportFrom}
              reportTo={reportTo}
              totalRecordsListings={totalRecordsListings}
              activeTabCounters={activeTabCounters}
            />
          </div>
        </Container>
      </div>
    </>
  );
};
