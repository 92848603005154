import React from "react";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { FtsButtonsFooter } from "@find-truck-service/ui/src/FtsButtonsFooter/FtsButtonsFooter";
export const ButtonsFooter = (props) => {
    const { textButtonProps, outlinedButtonProps, containedButtonProps, ...defaultProps } = props;
    return (React.createElement(FtsButtonsFooter, { ...defaultProps },
        textButtonProps && (React.createElement(Button, { size: ButtonSizes.md, type: ButtonTypes.text, color: ButtonColorsValue.accent, width: { xs: "100%", sm: "auto", md: "auto", lg: "auto" }, ...textButtonProps })),
        outlinedButtonProps && (React.createElement(Button, { size: ButtonSizes.md, type: ButtonTypes.outlined, color: ButtonColorsValue.accent, width: { xs: "100%", sm: "auto", md: "auto", lg: "auto" }, ...outlinedButtonProps })),
        containedButtonProps && (React.createElement(Button, { size: ButtonSizes.md, type: ButtonTypes.contained, color: ButtonColorsValue.accent, width: { xs: "100%", sm: "auto", md: "auto", lg: "auto" }, ...containedButtonProps }))));
};
