import React, { useEffect } from "react";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { CompleteRegistrationFormTitle } from "./components/CompleteRegistrationFormTitle";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { CreateRegistrationFormInputFields } from "./components/CompleteRegistrationFormInputFIelds";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import TermsAndConditions from "../TermsAndConditions";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { HeaderWrapper } from "../HeaderWrapper";
import { useDispatch, useSelector } from "react-redux";
import { extractParameter } from "../../../utility/param-extract";
import {
  completeRegistrationGetInvitationRequest,
  completeRegistrationUpdatePasswordRequest,
  setCompleteRegistrationInvalidFormFields,
} from "../../../redux/actions/v2/completeRegistration";
import {
  selectCompleteRegistrationData,
  selectCompleteRegistrationIsLoading,
} from "../../../redux/reducers/v2/completeRegistration";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { completeRegistrationValidator } from "./validators/index";
import { toast } from "react-toastify";
import { history } from "../../../history";
import logger from "../../../utility/logger";
import { selectShareProviderData } from "../../../redux/reducers/v2/shareProvider";
import { selectUserData } from "../../../redux/reducers/v2/auth";
import { SHARE_PROVIDER_STATUSSES } from "../../../constants";
import { SESSION_STORAGE_KEYS } from "../../../utility/constants";

const CompleteRegistration = () => {
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const data = useSelector(selectCompleteRegistrationData);
  const shareProvider = useSelector(selectShareProviderData);
  const isLoading = useSelector(selectCompleteRegistrationIsLoading);

  useEffect(() => {
    const hash = extractParameter("token");

    dispatch(completeRegistrationGetInvitationRequest({ data: { hash } }));
  }, [dispatch]);

  useEffect(() => {
    const isShareProviderDisabled =
      shareProvider?.status === SHARE_PROVIDER_STATUSSES.DISABLED;
    if (isShareProviderDisabled) {
      if (shareProvider?.userId === userData?.id) history.replace("/dashboard");
      else history.replace("/login");
    }
  }, [shareProvider, userData?.id]);

  const onCompleteRegistrationSuccess = () => {
    toast.success("Password updated successfully!");
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.TEMPORARY_TOKEN);
    history.push("/dashboard");
  };

  const onCompleteRegistrationFailure = (e) => {
    const message = e.response
      ? e.response.data.message
      : "Something went wrong!";

    toast.error(message);
  };

  const handleCompleteRegistration = async () => {
    try {
      await completeRegistrationValidator.validate(data || {});

      dispatch(
        setCompleteRegistrationInvalidFormFields({
          data: null,
        }),
      );

      const hash = extractParameter("token");
      dispatch(
        completeRegistrationUpdatePasswordRequest({
          data: {
            hash,
            password: data.password,
            recaptcha: data.recaptcha,
          },
          onSuccess: onCompleteRegistrationSuccess,
          onError: onCompleteRegistrationFailure,
        }),
      );
    } catch (e) {
      logger.error(e);
      if (e.name === "ValidationError") {
        dispatch(
          setCompleteRegistrationInvalidFormFields({
            data: {
              [e.path]: e.errors[0],
            },
          }),
        );
      }
    }
  };

  return (
    <>
      {isLoading && <FullPageLoader />}
      <HeaderWrapper />
      <FtsContainer mt={{ xs: "0", sm: "0", md: "4xl", lg: "4xl" }}>
        <FtsRow>
          <FtsColumn size={{ md: 12, lg: 6 }} offset={{ md: 0, lg: 3 }}>
            <FtsRow
              boxShadow="sm"
              borderRadius="2xs"
              bg={ColorsValue["greyscale-0"]}
              px={{ xs: "xl", sm: "2xl", md: "4xl", lg: "4xl" }}
              py={{ xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" }}
              rowGap={{ xs: "2xl", sm: "2xl", md: "4xl", lg: "4xl" }}
              mt={{ xs: "0", sm: "2xl", md: "3xl", lg: "4xl" }}
            >
              <CompleteRegistrationFormTitle />
              <CreateRegistrationFormInputFields />
              <FtsColumn size={{ xs: 12 }}>
                <Button
                  width={"100%"}
                  label={"Save"}
                  size={ButtonSizes.md}
                  onClick={handleCompleteRegistration}
                  type={ButtonTypes.contained}
                  color={ButtonColorsValue.accent}
                />
              </FtsColumn>
            </FtsRow>
            <TermsAndConditions />
          </FtsColumn>
        </FtsRow>
      </FtsContainer>
    </>
  );
};

export default CompleteRegistration;
