import React from "react";

import { Button } from "reactstrap";
import Icon from "../../../components/fts/Icons";
import "../../../assets/scss/pages/thank-you-page.scss";
import Breakpoint from "../../../components/hoc/Breakpoint";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";

class ThankYouPage extends React.Component {
  render() {
    return (
      <div className="fts-thank-you">
        <HeaderWrapper />
        <div className="fts-thank-you-message">
          <div className="fts-thank-you-message-box">
            <Breakpoint md up>
              <Icon name="EmailIllustration" />
            </Breakpoint>
            <Breakpoint sm down>
              <Icon name="EmailIllustrationMobile" />
            </Breakpoint>
            <div>
              <h3>Thank you</h3>
              <p>
                Your form has been submitted and one of our colleagues will be
                in touch shortly.
              </p>
            </div>

            <Button
              tag="a"
              onClick={() => {
                window.location.replace(window.location.origin);
              }}
              color="secondary"
              size="lg"
            >
              Back to Home
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default ThankYouPage;
