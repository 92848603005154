import React from "react";
import { X } from "react-feather";

import "../../assets/scss/components/fts/close-button.scss";
import { usePhoneAndBelowMediaQuery } from "../../hooks";

const CloseButton = ({ className, ...rest }) => {
  const isMobile = usePhoneAndBelowMediaQuery();

  return (
    <div className={`fts-close-btn ${className ?? ""}`} {...rest}>
      <X size={isMobile ? 24 : 20} />
    </div>
  );
};

export default CloseButton;
