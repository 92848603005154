import React from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CLIENT_FILE_SIZE_LIMIT_BYTES } from "../../../../constants";
import {
  ALLOWED_FILE_TYPES,
  FILES_LIMIT_MSG,
  LISTING_FILES_LIMIT,
  MAXIMUM_FILE_SIZE_MSG,
} from "../../../../data/supported-file-types";

export const UploadButton = ({
  adsAssets,
  fileInputRef,
  adsAssetsSize,
  uploadAdsAssetsRequest,
}) => {
  const dispatch = useDispatch();

  function onMaximumFilesLimit() {
    toast.error(FILES_LIMIT_MSG);
  }

  function clearFileInput() {
    fileInputRef.current.value = null;
  }

  function isAlreadySelected(file) {
    return adsAssets.some((asset) => asset.file?.name === file.name);
  }

  async function handleChange(e) {
    const selectedFiles = e.target.files;
    const selectedFilesLength = selectedFiles.length;
    // If no files selected, clear the input
    if (!selectedFilesLength) return clearFileInput();
    // If the total number of files exceeds the limit, show a toast and clear the input
    if (selectedFilesLength + adsAssetsSize > LISTING_FILES_LIMIT) {
      onMaximumFilesLimit();
      return clearFileInput();
    }
    // Check if the file size exceeds the limit
    for (let fileForUpload of selectedFiles)
      if (fileForUpload.size > CLIENT_FILE_SIZE_LIMIT_BYTES) {
        clearFileInput();
        return toast.error(MAXIMUM_FILE_SIZE_MSG);
      }
    // Check if the file is duplicate
    const filesArray = Array.from(selectedFiles);
    const files = filesArray.filter((file) => !isAlreadySelected(file));
    if (!files.length) return;

    dispatch(uploadAdsAssetsRequest({ data: files }));
    clearFileInput();
  }

  return (
    <input
      hidden
      type="file"
      ref={fileInputRef}
      multiple="multiple"
      onChange={handleChange}
      accept={ALLOWED_FILE_TYPES}
    />
  );
};
