import { useCallback, useEffect } from "react";
import { history } from "../../history";
import { useSelector } from "react-redux";
import { TemporaryLinkPages } from "../../views/constants/pages";
import { useTemporaryLinkRedirectToPreviewHandler } from "./useTemporaryLinkRedirectToPreviewHandler";
import { FREE_LISTING_PRICE_ID } from "@find-truck-service/ui/src/components/react/ListingItem/constants";
import {
  selectTemporaryLinkListingPreviewData,
  selectTemporaryLinkIsLoading,
  selectTemporaryLinkListingProofStatus,
  selectTemporaryListingLinkInvoices,
} from "redux/reducers/v2/temporaryListingLink";
import { LISTING_STATUSES } from "../../constants";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";
// TEMPORARILY COMMENTED OUT UNTIL IT'S TESTED FOR PROD
// import {
//   INVOICES_STATUSES_ENUM,
//   INVOICES_TYPES_ENUM,
// } from "@find-truck-service/types/constants/invoices";

//Proof preview page
const isProofPreview = window.location.pathname.startsWith(
  TemporaryLinkPages.PROOF_PREVIEW,
);

export const useTemporaryLinkRedirectHandler = () => {
  const handleRedirectToPreview = useTemporaryLinkRedirectToPreviewHandler();
  const listingInvoices = useSelector(selectTemporaryListingLinkInvoices);
  const listingData = useSelector(selectTemporaryLinkListingPreviewData);
  const proofStatus = useSelector(selectTemporaryLinkListingProofStatus);
  const isLoading = useSelector(selectTemporaryLinkIsLoading);

  const handleDraftRedirects = useCallback(() => {
    //Available only for draft listings
    const isEditRouteActive = window.location.pathname.startsWith(
      TemporaryLinkPages.EDIT_LISTING,
    );
    const isPaymentRouteActive = window.location.pathname.startsWith(
      TemporaryLinkPages.PAYMENT,
    );
    const isEditImageRouteActive = window.location.pathname.startsWith(
      TemporaryLinkPages.EDIT_LISTING_IMAGES,
    );
    const isDraftPreviewRouteActive = window.location.pathname.startsWith(
      TemporaryLinkPages.DRAFT_LISTING_PREVIEW,
    );

    const isDraftProofPreviewRouteActive = window.location.pathname.startsWith(
      TemporaryLinkPages.DRAFT_PROOF_PREVIEW,
    );

    if (
      listingData?.proofStatus === PROOF_STATUSES_ENUM.PENDING &&
      !isPaymentRouteActive
    ) {
      return history.replace(TemporaryLinkPages.DRAFT_PROOF_PREVIEW);
    }

    if (isLoading || isDraftPreviewRouteActive) return;
    if (
      isEditRouteActive ||
      isEditImageRouteActive ||
      isDraftProofPreviewRouteActive
    )
      return;
    if (listingInvoices?.length && isPaymentRouteActive) return;
    history.replace(TemporaryLinkPages.DRAFT_LISTING_PREVIEW);
  }, [isLoading, listingInvoices?.length, listingData]);

  useEffect(() => {
    if (!listingData) return;
    const isCompletedListing = listingData?.status !== LISTING_STATUSES.DRAFT;
    const isFreeListing = listingData?.priceId === FREE_LISTING_PRICE_ID;
    const isDisabled = listingData?.status === LISTING_STATUSES.DISABLED;
    const isDraft = listingData?.status === LISTING_STATUSES.DRAFT;
    const isProofActive = proofStatus === PROOF_STATUSES_ENUM.PENDING;
    const isDeleted = !!listingData?.deletedAt;

    // TEMPORARILY COMMENTED OUT UNTIL IT'S TESTED FOR PROD
    // const isRenewal =
    //   listingInvoices?.length &&
    //   listingInvoices[0].type === INVOICES_TYPES_ENUM.RENEWAL;
    // const isExpired =
    //   listingInvoices?.length &&
    //   listingInvoices[0].status === INVOICES_STATUSES_ENUM.VOIDED;

    if (isDeleted) return history.replace(TemporaryLinkPages.LISTING_NOT_FOUND);

    if (isDisabled)
      return history.replace(
        `${TemporaryLinkPages.LISTING_DISABLED}/${listingData.id}`,
      );

    if (isDraft) return handleDraftRedirects();

    // TEMPORARILY COMMENTED OUT UNTIL IT'S TESTED FOR PROD
    // if (isExpired) return history.push(TemporaryLinkPages.LISTING_RELIST);

    if (isFreeListing)
      return history.replace(TemporaryLinkPages.FREE_LISTING_PREVIEW);
    if (isProofActive && !isDraft)
      return history.replace(TemporaryLinkPages.PROOF_PREVIEW);

    // TEMPORARILY COMMENTED OUT UNTIL IT'S TESTED FOR PROD
    // if (isRenewal) return history.push(TemporaryLinkPages.LISTING_RENEWAL);

    if (isCompletedListing)
      return history.replace(TemporaryLinkPages.LISTING_PREVIEW);

    if (isProofPreview && !isProofActive) return handleRedirectToPreview();
  }, [
    proofStatus,
    listingData,
    isProofPreview,
    handleDraftRedirects,
    handleRedirectToPreview,
  ]);
};
