import { call, put, takeLatest, all, take } from "redux-saga/effects";

import {
  convertToVendorFailed,
  convertToVendorSuccess,
  countSubUsersFailed,
  countSubUsersSuccess,
  getUserTypesFailed,
  getUserTypesSuccess,
  resendVerifyEmailAddressFailed,
  resendVerifyEmailAddressSuccess,
  setUserData,
  updateUserFailed,
  updateUserSuccess,
  verifyEmailAddressFailed,
  verifyEmailAddressSuccess,
} from "../../actions/v2/auth";
import {
  CONVERT_TO_VENDOR_REQUEST,
  COUNT_SUB_USERS_REQUEST,
  GET_USER_TYPES_REQUEST,
  INSERT_USER_PERMISSIONS_REQUEST,
  RESEND_VERIFY_EMAIL_ADDRESS_REQUEST,
  SEND_REACTIVATE_ACCOUNT_EMAIL_REQUEST,
  UPDATE_USER_REQUEST,
  VERIFY_EMAIL_ADDRESS_REQUEST,
} from "../../reducers/v2/auth";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import FTSAxios from "../../../axios/fts.instance";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";
import handleError from "../../../handlers/error";
import { toast } from "react-toastify";
import {
  COLLECT_ESSENTIALS_SUCCESS,
  collectEssentialsRequest,
} from "../../actions/v2/essentials";
import logger from "../../../utility/logger";

function* convertToVendor(action) {
  const { onSuccess, onError } = action.payload;
  try {
    const response = yield call(FTSAxiosV2.post, `/users/convert-to-vendor`);
    logger.info("RESPONSE", response);
    yield put(convertToVendorSuccess());
    onSuccess(response);
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while converting to vendor";
    const inlineMessage = e?.response?.data?.inlineMessage;

    yield put(convertToVendorFailed({ data: { message, inlineMessage } }));
    onError(e);
  }
}

function* countSubUsers(action) {
  const { onSuccess, onError } = action.payload;
  try {
    const { data } = yield call(FTSAxiosV2.get, "/users/sub-users-count");
    yield put(countSubUsersSuccess());
    onSuccess(data);
  } catch (e) {
    yield put(countSubUsersFailed());
    onError(e);
  }
}

function* verifyEmailAddressRequestSaga(action) {
  const { data, onSuccess } = action.payload;
  try {
    const { data: responseData } = yield call(
      FTSAxiosV2.post,
      "/users/verify-email-address",
      data,
    );

    yield put(verifyEmailAddressSuccess({ data: responseData.data }));
    toast.success(responseData.message);
    if (typeof onSuccess === "function") {
      onSuccess(responseData);
    }
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while creating card";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    yield put(verifyEmailAddressFailed({ data: { message, inlineMessage } }));
  }
}

function* updateUserRequestSaga(action) {
  const { data, onSuccess } = action.payload;
  try {
    const { data: responseData } = yield call(FTSAxiosV2.put, "/users/update", {
      userData: data,
    });

    yield put(updateUserSuccess({ data: responseData.data }));

    if (typeof onSuccess === "function") {
      onSuccess(responseData);
    }
  } catch (e) {
    logger.error(e);
    const message = e.response
      ? e?.response?.data?.message
      : "Error while editing user";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    yield put(updateUserFailed({ data: { message, inlineMessage } }));
  }
}

function* insertUserPermissionsSaga(action) {
  const { onSuccess, onError } = action.payload;

  try {
    const { data: responseData } = yield FTSAxiosV2.post(
      "/users/insert-permissions",
    );

    const user = responseData?.data;

    yield all([
      put(setUserData({ data: user })),
      put(collectEssentialsRequest()),
      take(COLLECT_ESSENTIALS_SUCCESS),
    ]);

    if (typeof onSuccess === "function") onSuccess();
  } catch (e) {
    logger.error(e);
    if (typeof onError === "function") onError(e);
  }
}

function* resendVerifyEmailAddressRequestSaga() {
  try {
    const { data: responseData } = yield call(
      FTSAxiosV2.post,
      "/users/resend-verify-email-address",
    );
    yield put(resendVerifyEmailAddressSuccess(responseData));
    toast.success(responseData.message);
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while creating card";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    yield put(
      resendVerifyEmailAddressFailed({ data: { message, inlineMessage } }),
    );
  }
}

function* getUserTypes() {
  try {
    const { data } = yield call(FTSAxios.get, "/user-types");
    yield put(getUserTypesSuccess({ data }));
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while fetching user types";

    // TODO: handle inlineMessage properly on new api
    const inlineMessage =
      e?.response?.data?.inlineMessage || "Error while fetching user types";
    handleError(e);
    yield put(getUserTypesFailed({ data: { message, inlineMessage } }));
  }
}

function* sendReactivateAccountEmailSaga(action) {
  const { onSuccess, onError } = action.payload;
  try {
    yield call(FTSAxiosTemporaryLink.post, "/auth/send/reactivate/email");
    if (typeof onSuccess === "function") onSuccess();
  } catch (e) {
    handleError(e);
    if (typeof onError === "function") onError(e);
  }
}

export function* authWatcher() {
  yield takeLatest(UPDATE_USER_REQUEST, updateUserRequestSaga);
  yield takeLatest(VERIFY_EMAIL_ADDRESS_REQUEST, verifyEmailAddressRequestSaga);
  yield takeLatest(
    RESEND_VERIFY_EMAIL_ADDRESS_REQUEST,
    resendVerifyEmailAddressRequestSaga,
  );
  yield takeLatest(CONVERT_TO_VENDOR_REQUEST, convertToVendor);
  yield takeLatest(COUNT_SUB_USERS_REQUEST, countSubUsers);
  yield takeLatest(GET_USER_TYPES_REQUEST, getUserTypes);
  yield takeLatest(INSERT_USER_PERMISSIONS_REQUEST, insertUserPermissionsSaga);
  yield takeLatest(
    SEND_REACTIVATE_ACCOUNT_EMAIL_REQUEST,
    sendReactivateAccountEmailSaga,
  );
}
