import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useScrollToTopAndNavigate } from "../../../../../hooks";
import { TemporaryLinkPages } from "../../../../../views/constants/pages";
import { ListingInfoSmFooterComponent } from "../../components/ListingInfoSmFooterComponent";
import { temporaryLinkUpdateListingRequest } from "../../../../../redux/actions/v2/temporaryLinkListing";
import { selectTemporaryLinkContinueAssetsButtonIsDisabled } from "../../../../../redux/reducers/v2/temporaryListingLink";

export const TemporaryLinkEditListingImagesMobileFooter = () => {
  const dispatch = useDispatch();
  const scrollAndNavigate = useScrollToTopAndNavigate();
  const continueIsDisabled = useSelector(
    selectTemporaryLinkContinueAssetsButtonIsDisabled,
  );

  async function onSaveChanges() {
    dispatch(
      temporaryLinkUpdateListingRequest({ onSuccess: navigateToPreview }),
    );
  }

  function navigateToPreview() {
    scrollAndNavigate(TemporaryLinkPages.DRAFT_LISTING_PREVIEW);
  }

  const primaryButtonProps = {
    label: "Continue",
    onClick: onSaveChanges,
    disabled: continueIsDisabled,
  };

  return (
    <ListingInfoSmFooterComponent primaryButtonProps={primaryButtonProps} />
  );
};
