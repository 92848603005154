import React from "react";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { useSelector } from "react-redux";
import {
  selectCompanyDriverProfileFinishLaterLoading,
  selectCompanyDriverProfileUpdateLoading,
} from "../../../../redux/reducers/v2/company-driver-profile";

const ButtonsMd = ({
  handleUpdateCompanyDriver,
  handleCompanyDriverFinishLater,
}) => {
  const companyDriverUpdateLoading = useSelector(
    selectCompanyDriverProfileUpdateLoading,
  );
  const companyDriverFinishLaterLoading = useSelector(
    selectCompanyDriverProfileFinishLaterLoading,
  );

  return (
    <FtsRow
      display={{ xs: "none", sm: "grid", md: "grid", lg: "grid" }}
      mt={"4xl"}
      columnGap={"lg"}
    >
      <FtsColumn size={{ xs: 6, md: 6, lg: 6 }}>
        <Button
          onClick={handleCompanyDriverFinishLater}
          size={ButtonSizes.md}
          color={ButtonColorsValue.neutral}
          type={ButtonTypes.outlined}
          label={"Finish Later"}
          width={"100%"}
          loading={companyDriverFinishLaterLoading}
          disabled={
            companyDriverFinishLaterLoading || companyDriverUpdateLoading
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6, md: 6, lg: 6 }}>
        <Button
          onClick={handleUpdateCompanyDriver}
          size={ButtonSizes.md}
          color={ButtonColorsValue.accent}
          type={ButtonTypes.contained}
          label={"Save"}
          width={"100%"}
          loading={companyDriverUpdateLoading}
          disabled={
            companyDriverFinishLaterLoading || companyDriverUpdateLoading
          }
        />
      </FtsColumn>
    </FtsRow>
  );
};
export default ButtonsMd;
