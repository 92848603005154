import { createSelector } from "reselect";

export const OWNER_OPERATOR_PROFILE_SET_FIELD_VALUES =
  "OWNER_OPERATOR_PROFILE/SET_FIELD_VALUES";
export const OWNER_OPERATOR_PROFILE_UPDATE_REQUEST =
  "OWNER_OPERATOR_PROFILE/UPDATE_REQUEST";
export const OWNER_OPERATOR_PROFILE_UPDATE_SUCCESS =
  "OWNER_OPERATOR_PROFILE/UPDATE_SUCCESS";
export const OWNER_OPERATOR_PROFILE_UPDATE_FAILED =
  "OWNER_OPERATOR_PROFILE/UPDATE_FAILED";
export const OWNER_OPERATOR_PROFILE_SET_INVALID_FORM_FIELDS =
  "OWNER_OPERATOR_PROFILE/SET_INVALID_FORM_FIELDS";
export const OWNER_OPERATOR_PROFILE_SET_UPDATE_LOADING =
  "OWNER_OPERATOR_PROFILE/SET_UPDATE_LOADING";
export const OWNER_OPERATOR_PROFILE_SET_FINISH_LATER_LOADING =
  "OWNER_OPERATOR_PROFILE/SET_FINISH_LATER_LOADING";
export const OWNER_OPERATOR_CREATE_TRUCK_REQUEST =
  "OWNER_OPERATOR_PROFILE/OWNER_OPERATOR_CREATE_TRUCK_REQUEST";
export const OWNER_OPERATOR_CREATE_TRUCK_SUCCESS =
  "OWNER_OPERATOR_PROFILE/OWNER_OPERATOR_CREATE_TRUCK_SUCCESS";
export const OWNER_OPERATOR_CREATE_TRUCK_FAILED =
  "OWNER_OPERATOR_PROFILE/OWNER_OPERATOR_CREATE_TRUCK_FAILED";

const initialState = {
  updateLoading: false,
  finishLaterLoading: false,
  error: null,
  data: null,
  invalidFormFields: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OWNER_OPERATOR_PROFILE_SET_FIELD_VALUES:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    case OWNER_OPERATOR_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        invalidFormFields: null,
      };

    case OWNER_OPERATOR_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        error: null,
        invalidFormFields: null,
        updateLoading: false,
        finishLaterLoading: false,
      };
    case OWNER_OPERATOR_PROFILE_UPDATE_FAILED:
      return {
        ...state,
        error: action.payload,
        invalidFormFields: null,
        updateLoading: false,
        finishLaterLoading: false,
      };

    case OWNER_OPERATOR_PROFILE_SET_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: action.payload.data,
      };

    case OWNER_OPERATOR_PROFILE_SET_UPDATE_LOADING:
      return {
        ...state,
        updateLoading: action.payload.data,
      };
    case OWNER_OPERATOR_PROFILE_SET_FINISH_LATER_LOADING:
      return {
        ...state,
        finishLaterLoading: action.payload.data,
      };

    default:
      return state;
  }
}

const selectOwnerOperatorProfileState = (state) =>
  state.ownerOperatorProfileState;

export const selectOwnerOperatorProfileData = createSelector(
  selectOwnerOperatorProfileState,
  (ownerOperatorProfile) => {
    return ownerOperatorProfile.data;
  },
);

export const selectOwnerOperatorProfileInvalidFormFields = createSelector(
  selectOwnerOperatorProfileState,
  (ownerOperatorProfile) => {
    return ownerOperatorProfile.invalidFormFields;
  },
);

export const selectOwnerOperatorProfileUpdateLoading = createSelector(
  selectOwnerOperatorProfileState,
  (ownerOperatorProfile) => {
    return ownerOperatorProfile.updateLoading;
  },
);

export const selectOwnerOperatorProfileFinishLaterLoading = createSelector(
  selectOwnerOperatorProfileState,
  (ownerOperatorProfile) => {
    return ownerOperatorProfile.finishLaterLoading;
  },
);
