import React from "react";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

export const ListingInfoMdFooterComponent = ({
  primaryButtonProps,
  onCancel,
}) => {
  return (
    <FtsWrapper
      gap={"lg"}
      width={"100%"}
      direction={"row-reverse"}
      display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}
    >
      {primaryButtonProps && (
        <Button
          size={ButtonSizes.md}
          type={ButtonTypes.contained}
          color={ButtonColorsValue.accent}
          {...primaryButtonProps}
        />
      )}
      {onCancel && (
        <Button
          label={"Cancel"}
          onClick={onCancel}
          size={ButtonSizes.md}
          type={ButtonTypes.outlined}
          color={ButtonColorsValue.accent}
        />
      )}
    </FtsWrapper>
  );
};
