import { call, takeLatest, put, all, take } from "redux-saga/effects";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";
import { UPDATE_PASSWORD_REQUEST } from "../../reducers/v2/updatePassword";
import {
  updatePasswordFailed,
  updatePasswordSuccess,
} from "../../actions/v2/updatePassword";
import { setLoggedUserCookie } from "../../../cookies";
import { setUserData } from "../../actions/v2/auth";
import {
  COLLECT_ESSENTIALS_SUCCESS,
  collectEssentialsRequest,
} from "../../actions/v2/essentials";

function* updatePassword(action) {
  const { data, onSuccess, onError } = action.payload;

  try {
    const response = yield call(
      FTSAxiosTemporaryLink.put,
      "/auth/update-password",
      {
        newPassword: data.newPassword,
      },
    );
    const { user, bearerToken } = response.data.data;
    setLoggedUserCookie(user, bearerToken);

    yield all([
      put(setUserData({ data: user })),
      put(collectEssentialsRequest()),
      take(COLLECT_ESSENTIALS_SUCCESS),
      put(updatePasswordSuccess()),
    ]);

    onSuccess();
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(updatePasswordFailed({ data: { message, inlineMessage } }));
    onError(e);
  }
}

export default function* updatePasswordWatcher() {
  yield takeLatest(UPDATE_PASSWORD_REQUEST, updatePassword);
}
