import {
  UPDATE_PASSWORD_REQUEST_FAILED,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_REQUEST_SUCCESS,
  SET_UPDATE_PASSWORD_DATA,
  SET_UPDATE_PASSWORD_INVALID_FORM_FIELDS,
} from "../../../reducers/v2/updatePassword";

export const setUpdatePasswordData = (payload) => ({
  type: SET_UPDATE_PASSWORD_DATA,
  payload,
});

export const setUpdatePasswordInvalidFormFields = (payload) => ({
  type: SET_UPDATE_PASSWORD_INVALID_FORM_FIELDS,
  payload,
});

export const updatePasswordRequest = (payload) => ({
  type: UPDATE_PASSWORD_REQUEST,
  payload,
});

export const updatePasswordFailed = (payload) => ({
  type: UPDATE_PASSWORD_REQUEST_FAILED,
  payload,
});

export const updatePasswordSuccess = (payload) => ({
  type: UPDATE_PASSWORD_REQUEST_SUCCESS,
  payload,
});
