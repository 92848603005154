import { USER_TYPE } from "../constants";
import {
  fleetCarrierRoutes,
  ownerOperatorRoutes,
  serviceVendorRoutes,
  companyDriverRoutes,
  otherProfileRoutes,
} from "../routes";

const getRoutesByUserType = (userType) => {
  switch (userType) {
    case USER_TYPE.FLEET_CARRIER:
      return fleetCarrierRoutes;

    case USER_TYPE.OWNER_OPERATOR:
      return ownerOperatorRoutes;

    case USER_TYPE.SERVICE_VENDOR:
      return serviceVendorRoutes;

    case USER_TYPE.COMPANY_DRIVER:
      return companyDriverRoutes;

    case USER_TYPE.OTHER:
      return otherProfileRoutes;

    default:
      return [];
  }
};

export default getRoutesByUserType;
