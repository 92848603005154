import FTSAxios from "../../../axios/fts.instance";

export const getAllDrivers = (params) => {
  return () => {
    return FTSAxios.post("/users-drivers", params);
  };
};

export const getDriverById = (driverId) => {
  return () => {
    return FTSAxios.get(`/users-drivers/${driverId}`);
  };
};

export const getDriversCounters = () => {
  return () => {
    return FTSAxios.get("/users-drivers-count");
  };
};

export const createDriver = (payload) => {
  return () => {
    return FTSAxios.post("/users-drivers/create", payload);
  };
};

export const editDriver = (payload) => {
  return () => {
    return FTSAxios.put("/users-drivers/update", payload);
  };
};

export const acceptInvitation = () => {
  return () => {
    return FTSAxios.put("/users-drivers/invitation");
  };
};

export const checkDriverEmail = (email) => {
  return () => {
    return FTSAxios.get(`/users-drivers/get-by-email/${email}`);
  };
};

export const resendInvitation = (payload) => {
  return () => {
    return FTSAxios.post("/users-drivers/resend-invitation", payload);
  };
};
