import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import React from "react";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { WeightSize } from "@find-truck-service/types/ui/text";
export const DrawerButtonsLogged = ({ userName }) => {
    return (React.createElement(FtsWrapper, { p: "xl", pb: "3xl", gap: "lg", borderTop: "1px solid #DE667C", display: "flex", direction: "column" },
        React.createElement(Button, { width: "100%", label: "Dashboard", size: ButtonSizes.md, type: ButtonTypes.contained, color: ButtonColorsValue.onBrand, onClick: () => (window.location.href = "/dashboard") }),
        React.createElement(Span, { width: "100%", textAlign: "center", variant: "text-2xs", weight: WeightSize.semibold, color: ColorsValue["greyscale-0"], style: { maxWidth: "222px", textWrap: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } }, userName)));
};
