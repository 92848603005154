import React, { Component } from "react";

import { connect } from "react-redux";

import FTSModal from "../fts-modal";
import SendPaymentLink from "../send-payment-link";
import CloseButton from "../CloseButton";

import { activities } from "../../../constants";
import { createActivity } from "../../../redux/actions/activities";

import { Button, Spinner } from "reactstrap";
import { ChevronDown } from "react-feather";

import "../../../assets/scss/components/fts/listing-preview-modal.scss";
import classnames from "classnames";
import Icon from "../Icons";
import ListingItem from "../listing-item";
import {
  sendProof,
  updateProof,
  getProofById,
} from "../../../redux/actions/proofs";
import { toast } from "react-toastify";
import ListingProofSentSuccessfully from "../listing-proof-sent-succesfully";
import PermissionToApproveModal from "../permission-to-approve-modal";
import FTSBadge from "../fts-badge";
import ProofRevisedModal from "../proof-revised-modal";
import { addUserActivity } from "../../../redux/actions/user-activities";
import { LISTING_TYPES } from "../../../constants/analytics";
import logger from "../../../utility/logger";

class ListingPreviewModal extends Component {
  state = {
    isSendProofOpen: false,
    proofRevised: false,
    reviseInProgress: false,
    isOpenListingProofSentSuccessfully: false,
    isOpenPermissionToApproveModal: false,
    proofData: null,
  };

  sendReportRef = React.createRef();

  componentDidMount() {
    const proofToLoad = this.props.proofToLoad;
    if (proofToLoad) {
      this.props
        .getProofById(proofToLoad)
        .then(({ data }) => {
          this.setState({
            proofData: data.proof,
          });
        })
        .catch((err) => logger.error(err));
    }
  }

  closeModal = () => {
    this.props.toggleListingPreviewModal();
    this.setState({
      isSendProofOpen: false,
    });
  };

  confirmModal = () => {
    this.props.toggleListingPreviewModal();
  };

  handleListingProofSentSuccessfully = () => {
    this.setState({
      isOpenListingProofSentSuccessfully:
        !this.state.isOpenListingProofSentSuccessfully,
    });
  };

  handleProofRevisedModal = () => {
    this.setState({
      isOpenProofRevisedModal: !this.state.isOpenProofRevisedModal,
    });
  };

  sendReport = ({ activeTab, values }) => {
    let payload = {
      userId: this.props.listingData.userId,
      adId: this.props.listingData.id,
      type: 2,
      status: 3,
      sentBy: `${this.props.userData.firstName} ${this.props.userData.lastName}`,
      companyName: this.props.listingData?.name || "",
      city: this.props.listingData?.city || "",
      state: this.props.listingData?.state || "",
      adType: LISTING_TYPES[this.props.listingData.priceId]
        .toString()
        .toUpperCase(),
      firstName: this.props?.accountData?.firstName || "",
    };

    if (activeTab === "message") {
      let phoneNumbers = [
        values.mobilePhone,
        values.phoneNumber,
        values.phoneNumberSecondary,
        values.additionalPhoneNumber,
        values.listingPhoneNumber,
        values.listingPhoneNumberSecondary,
        values.afterHours,
      ].filter(Boolean);

      payload.phoneNumbersList = phoneNumbers;
    }

    if (activeTab === "email") {
      let emailsList = [
        values.addedEmail,
        values.additionalEmail,
        values.email,
        values.listingEmail,
      ].filter(Boolean);

      payload.emailsList = emailsList;
    }

    this.sendReportRef.handleLoading(true);

    this.props
      .sendProof(payload)
      .then(async (res) => {
        let activityPayload = {
          status: 1,
          userId: this.props.listingData.userId,
          adId: this.props.listingData.id,
          activityType: this.props.activitiesTypes.SENT_PROOF,
          proofId: res.data.id,
          activity: activities.sentProof
            .replace(
              "{{admin_name}}",
              `${this.props.userData.firstName} ${this.props.userData.lastName}`,
            )
            .replace(
              "{{proofRecievers}}",
              activeTab === "email"
                ? payload.emailsList
                : payload.phoneNumbersList,
            ),
          adminUserId: this.props.userData.adminAccountOwner || null,
          iconName: "Listings",
        };

        const proofActivity = await this.props.createActivity(activityPayload);
        this.props.addUserActivity(proofActivity.data);

        this.props.fetchProofs && this.props.fetchProofs();
        this.props.fetchProofsCounters && this.props.fetchProofsCounters();

        this.handleListingProofSentSuccessfully();

        toast.success("Proof successfully sent!", {
          position: toast.POSITION.TOP_RIGHT,
        });

        this.sendReportRef.handleLoading(false);
        this.setState({
          isSendProofOpen: false,
        });
      })
      .catch((err) => {
        logger.error(err);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.sendReportRef.handleLoading(false);
        this.setState({
          isSendProofOpen: false,
        });
      });
  };

  handleSendProofContext = () => {
    this.setState({
      isSendProofOpen: !this.state.isSendProofOpen,
    });
  };

  onProofRevisedCheck = () => {
    this.setState({ reviseInProgress: true });

    const payload = {
      proofId: this.props.actionItem?.id || this.state.proofData?.id,
      data: {
        status: 3,
        sendBy: `${this.props.userData.firstName} ${this.props.userData.lastName}`,
        lastUpdatedBy: `${this.props.userData.firstName} ${this.props.userData.lastName}`,
      },
    };

    this.props
      .updateProof(payload)
      .then(() => {
        this.setState({
          proofRevised: !this.state.proofRevised,
          reviseInProgress: false,
        });
        this.handleProofRevisedModal();

        const activityPayload = {
          status: 1,
          proofId: payload.proofId,
          userId:
            this.props.listingData.userId || this.props.actionItem?.userId,
          adId: this.props.listingData.id || this.props.actionItem?.adId,
          activityType: this.props.activitiesTypes.PROOF_REVISED,
          activity: activities.proofRevised
            .replace(
              "{{admin_name}}",
              `${this.props.userData.firstName} ${this.props.userData.lastName}`,
            )
            .replace("{{proofId}}", payload.proofId),
          adminUserId: this.props.userData.adminAccountOwner || null,
          iconName: "Listings",
        };

        this.props.createActivity(activityPayload);
        this.props.fetchProofs && this.props.fetchProofs();
        this.props.fetchProofsCounters && this.props.fetchProofsCounters();
      })
      .catch((err) => {
        logger.error(err);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  handlePermissionToApproveModal = () => {
    this.setState({
      isOpenPermissionToApproveModal:
        !this.state.isOpenPermissionToApproveModal,
    });
  };

  render() {
    const {
      isOpen,
      title,
      onClose,
      accountData,
      listingData,
      actionItem,
      modalTitle,
      // inProgress,
    } = this.props;

    const { isSendProofOpen } = this.state;
    return (
      <>
        <FTSModal
          {...{ isOpen, title, onClose }}
          modalClasses={"fts-listing-proof-modal"}
          centered={true}
          size="lg"
          style={{ maxWidth: "90%" }}
          footerCTA={() => {
            return (
              <div className="fts-listing-proof-confrimation-modal-cta">
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex">
                    <Button
                      onClick={() => {
                        const seoUrlIdentifier =
                          actionItem?.listing?.seoUrlIdentifier ||
                          this.state.proofData?.listing?.seoUrlIdentifier ||
                          listingData?.seoUrlIdentifier;

                        window.open(
                          `${window.location.origin}/page/${seoUrlIdentifier}`,
                          "_blank",
                        );
                      }}
                      color={"secondary"}
                      className="d-flex align-items-center mr-1"
                    >
                      <Icon name={"Eye"} />
                      <span className={"ml-1"}>View Vendor Page</span>
                    </Button>
                    {this.props.modalType !== "sendListingProof" && (
                      <div className="d-flex">
                        {(actionItem?.status === 3 ||
                          this.state.proofData?.status === 3) && (
                          <Button
                            type="button"
                            color="danger"
                            className="mr-1"
                            onClick={() => {
                              this.handlePermissionToApproveModal();
                            }}
                          >
                            <div className="d-flex align-center">
                              <span className="checked">
                                <Icon name="Checked" size="20" />
                              </span>
                              <span>Approve</span>
                            </div>
                          </Button>
                        )}

                        {(actionItem?.status === 2 ||
                          this.state.proofData?.status === 2) && (
                          <div className="d-flex align-center">
                            {!this.state.proofRevised &&
                              !this.state.reviseInProgress && (
                                <Button
                                  type="button"
                                  color="danger"
                                  className={`proof-revised-button mr-2 ${
                                    this.state.proofRevised &&
                                    !this.state.reviseInProgress
                                      ? "proof-revised-button-checked"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    this.onProofRevisedCheck();
                                  }}
                                >
                                  {this.state.reviseInProgress ? (
                                    <Spinner size="sm" />
                                  ) : (
                                    <div className="d-flex align-center">
                                      <span className="checked">
                                        <Icon name="Checked" size="20" />
                                      </span>
                                      <span>Proof Revised</span>
                                    </div>
                                  )}
                                </Button>
                              )}

                            {this.state.proofRevised &&
                              !this.state.reviseInProgress && (
                                <div className="d-flex align-items-center">
                                  <div
                                    style={{
                                      marginRight: "0.5rem",
                                    }}
                                  >
                                    The status of this proof has been changed to
                                  </div>
                                  <FTSBadge
                                    style={{
                                      backgroundColor: "#F5B053",
                                      color: "#fff",
                                    }}
                                  >
                                    Pending
                                  </FTSBadge>
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div>
                    <Button
                      color="tertiary"
                      type="button"
                      className="preview-listing-btn account-listing-header-btn mr-1"
                      onClick={() => {
                        this.closeModal();
                      }}
                    >
                      <span className="preview-listing-btn-label">Cancel</span>
                    </Button>

                    {isSendProofOpen && (
                      <div className="fts-send-listing-proof-context">
                        <SendPaymentLink
                          onRef={(ref) => (this.sendReportRef = ref)}
                          accountData={accountData}
                          listingData={listingData}
                          actionItem={actionItem || this.state.proofData}
                          sendReport={this.sendReport}
                          type="send-proof"
                          showIcon={false}
                        />
                      </div>
                    )}

                    <Button
                      id="todo-save-button"
                      color="primary"
                      className="ml-1"
                      onClick={() => {
                        this.handleSendProofContext();
                      }}
                    >
                      <div className="button-content-wrapper">
                        <span>Send listing proof</span>

                        <span
                          className={classnames("payment-link-button-arrow", {
                            opened: isSendProofOpen,
                            closed: !isSendProofOpen,
                          })}
                        >
                          <ChevronDown size={20} />
                        </span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            );
          }}
        >
          <div className="listing-proof-modal-body">
            <div className="listing-proof-modal-body-wrapper">
              <div className="listing-proof-modal-title">
                <h2>{modalTitle}</h2>
                <div>
                  <CloseButton onClick={this.closeModal} />
                </div>
              </div>

              <ListingItem
                data={listingData}
                workingHours={this.props.originalWorkingHours}
              />
            </div>
          </div>
        </FTSModal>

        <ListingProofSentSuccessfully
          isOpen={this.state.isOpenListingProofSentSuccessfully}
          closeModal={this.handleListingProofSentSuccessfully}
        />

        <ProofRevisedModal
          isOpen={this.state.isOpenProofRevisedModal}
          closeModal={this.handleProofRevisedModal}
        />

        <PermissionToApproveModal
          proofId={actionItem?.id || this.state.proofData?.id}
          adId={this.props.listingData?.id}
          userId={this.props.listingData?.userId}
          isOpen={this.state.isOpenPermissionToApproveModal}
          closeModal={this.handlePermissionToApproveModal}
          closePreviewModal={this.closeModal}
          fetchProofs={this.props.fetchProofs}
          fetchProofsCounters={this.props.fetchProofsCounters}
          handleListingProofSentSuccessfully={
            this.handleListingProofSentSuccessfully
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.authState.user,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

const mapDispatchToProps = {
  sendProof,
  createActivity,
  updateProof,
  getProofById,
  addUserActivity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingPreviewModal);
