import React, { useEffect } from "react";
import GetListedStepperIndicator from "../../../components/fts-components-v2/GetListedStepperIndicator";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDraftListingData,
  selectIsLoadingListingData,
  selectListingDataError,
} from "../../../redux/reducers/v2/listing";
import { history } from "../../../history";
import { getDraftListingRequest } from "../../../redux/actions/v2/listing";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import ListingInfoContent from "../../../components/fts-components-v2/ListingBusinessInfo/CreateListing/Components/ListingInfoContent";
import { ListingInfoSmFooter } from "../../../components/fts-components-v2/ListingBusinessInfo/CreateListing/Components/ListingInfoSmFooter";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import {
  SOMETHING_WENT_WRONG,
  UNEXPECTED_ISSUE,
} from "../../../utility/errorMessages";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";

const ListingBusinessDetails = () => {
  const isLoading = useSelector(selectIsLoadingListingData);
  const draftData = useSelector(selectDraftListingData);
  const error = useSelector(selectListingDataError);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDraftListingRequest());
  }, []);

  useEffect(() => {
    if (draftData)
      history.push("/dashboard/create-listing/draft-listing-exist");
  }, [draftData]);

  if (error)
    return (
      <FtsWrapper mt="4xl">
        <Error
          title={SOMETHING_WENT_WRONG}
          subtitle={error || UNEXPECTED_ISSUE}
          iconName="AlertTriangle"
          containedButton={{
            label: "Refresh",
            color: ButtonColorsValue.accent,
            onClick: () => {
              history.go(0);
            },
          }}
        />
      </FtsWrapper>
    );

  return (
    <>
      <GetListedStepperIndicator activeStepId={1} />
      <FtsContainer>
        {isLoading && <FullPageLoader />}
        <ListingInfoContent />
        <ListingInfoSmFooter />
      </FtsContainer>
    </>
  );
};

export default ListingBusinessDetails;
