import React from "react";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { ButtonProviders } from "@find-truck-service/types/ui/button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { FtsProviderButton } from "@find-truck-service/ui/src/FtsButton/FtsProviderButton";
export const PayPalButton = (props) => {
    return (React.createElement(FtsProviderButton, { ...props, provider: ButtonProviders.paypal },
        React.createElement(FtsIcons, { iconName: props.loading ? IconNames.Loader : IconNames.PayPal }),
        !!props.label && props.loading ? "Loading" : props.label));
};
