import { Field } from "formik";
import React, { Component, memo } from "react";
import { connect } from "react-redux";
// import { Edit, Trash2 } from "react-feather";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { Phone, MapPin } from "react-feather";
import moment from "moment";

import Divider from "../../../../../components/fts/Divider";
import FormikInput from "../../../../../components/fts/formik/formik-fast-input";
import TitleRow from "../../../../../components/fts/TitleRow";

import StatesPicker from "../../../../../components/fts/states-picker";

import FormikMaskedInput from "../../../../../components/fts/formik/masked-input";
import { zipCodeMask } from "../../../../../utility/masks";
import Icon from "../../../../../components/fts/Icons";
import AccountBox from "../../../../../components/fts/account-box-component";
import OnHoldPicker from "../../../../../components/fts/on-hold-picker";
import FTSMenu from "../../../../../components/fts/fts-menu";
import { checkPermissions } from "../../../../../utility/permissionsCheck";
import ConfirmationModal from "../../../../../components/fts/confirmation-modal";
import { updateListingStatus } from "../../../../../redux/actions/listings";
import { history } from "../../../../../history";
import {
  activities,
  PERMISSIONS,
  PERMISSION_GATE_PRINCIPLE,
} from "../../../../../constants";
import { createActivityVendor } from "../../../../../redux/actions/activities";
import FTSBadge from "../../../../../components/fts/fts-badge";
import { listingStatuses } from "../../../../../utility/constants";
import PermissionGate from "../../../../../components/fts/permission-gate/PermissionGate";
import Breakpoint from "../../../../../components/hoc/Breakpoint";
import logger from "../../../../../utility/logger";

class ListingInfo extends Component {
  state = {
    priceID: null,
    isOpen: false,
    confirmModalForRemoveListing: false,
  };

  options = [
    {
      id: "previewListing",
      componentLeft: <Icon name="Preview" />,
      text: "Preview listing",
      action: () => {
        this.handleMenu();
        this.props.setListingPreviewModalTitle("listingProofPreview");
        this.props.toggleListingPreviewModal();
      },
    },
    // {
    //   id: "duplicateListing",
    //   componentLeft: <Icon name="Duplicate" />,
    //   text: "Duplicate listing",
    //   action: () => {
    //     //   this.props.setActiveDropdown(null);
    //     this.handleMenu();
    //     this.props.toggleDuplicateListingModal();
    //   },
    // },
    // {
    //   id: "updateHistory",
    //   componentLeft: <Icon name="UpdatesMenuItem" />,
    //   text: "Update history",
    //   action: () => {
    //     this.handleMenu();
    //     this.props.callToAction("listing_update_history", false, null);
    //   },
    // },
    // {
    //   id: "reassignListing",
    //   componentLeft: <Icon name="Reassign" />,
    //   text: "Reassign listing",
    //   action: () => {
    //     this.handleMenu();
    //     this.props.toggleReassignListingModal();
    //   },
    // },
    {
      id: "removeListing",
      componentLeft: <Icon name="Trash" />,
      text: "Remove listing",
      red: true,
      action: () => {
        this.setState({ confirmModalForRemoveListing: true });
      },
    },
  ];

  handleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  componentDidMount() {
    let { adminPermissions, listingData } = this.props;
    let havePermissionForFeaturedAuthorizedVendorToggle = checkPermissions(
      [{ id: 4, subCat: [43] }],
      adminPermissions,
    );
    let havePermissionForAuthorizedVendorToggle = checkPermissions(
      [{ id: 4, subCat: [42] }],
      adminPermissions,
    );
    let havePermissionForAutomaticDowngradesToggle = checkPermissions(
      [{ id: 4, subCat: [40] }],
      adminPermissions,
    );
    let havePermissionForRenewalEmailsToggle = checkPermissions(
      [{ id: 4, subCat: [39] }],
      adminPermissions,
    );

    let havePermissionForApplyToMultiple = checkPermissions(
      [{ id: 4, subCat: [38] }],
      adminPermissions,
    );

    window.scrollTo(0, 0);
    this.setState({
      priceID: listingData?.priceId,
      havePermissionForApplyToMultiple,
      havePermissionForFeaturedAuthorizedVendorToggle,
      havePermissionForAuthorizedVendorToggle,
      havePermissionForAutomaticDowngradesToggle,
      havePermissionForRenewalEmailsToggle,
    });
  }

  handlePriceIdChange = (priceID) => {
    this.setState({
      priceID: priceID,
    });
  };

  showUpgradeButton = () => {
    const { status, priceId, renewalTimestamp, update } =
      this.props.listingData;
    const currentTimestamp = moment().unix();

    if (status !== 1 || update) return false;

    return (
      priceId === 5 ||
      (priceId > 1 && status === 1 && renewalTimestamp > currentTimestamp)
    );
  };

  handleDeleteListing = () => {
    const { listingData } = this.props;
    this.props
      .updateListingStatus({ id: listingData.id, status: 0 })
      .then(() => {
        let activityPayload = {
          status: 1,
          userId: this.props.userData.accountLink || this.props.userData.id,
          activityType: this.props.activitiesTypes.DELETED_LISTING,
          activity: activities.removeListing
            .replace(
              "{{admin_name}}",
              `${this.props.userData.firstName} ${this.props.userData.lastName}`,
            )
            .replace("{{user_id}}", this.props.userData.id)
            .replace("{{listingId}}", `${this.props.listingData.id}`),
          iconName: "Listings",
        };
        this.props.createActivityVendor(activityPayload);
        this.setState(
          {
            confirmModalForRemoveListing: false,
          },
          () => {
            history.push("/dashboard/listings");
          },
        );
      })
      .catch((err) => logger.error(err));
  };

  render() {
    const {
      createMode,
      editMode,
      onEditToggle,
      resetForm,
      // onPreviewListing,
      // setFieldTouched,
      // setFieldError,
      // validForm,
      // validForm,
      listingData,
      accountInfo,
      updateListingFromEmail,
      updateListingSidebar,
      preview,
      keys,
      changedSections,
      right,
      toggleEditConfirmationModal,
      listingLocked,
      // handleUpgrade,
    } = this.props;

    if (listingData?.isDeleted) {
      this.options[4] = {
        id: "enableListing",
        componentLeft: <Icon name="Update" />,
        text: "Enable listing",
        action: this.props.onRemoveListing,
      };
    }

    // const iconProps = {
    //   size: 20,
    // };

    const statesPicker = (
      <FormGroup>
        <Field name="addressInfo.state">
          {({
            meta,
            form: { setFieldValue, setFieldError, setFieldTouched },
            field: { onBlur, name, value },
          }) => (
            <span
              className={`${
                keys && keys.includes(name.split(".").pop()) && "field-changed"
              }`}
            >
              <StatesPicker
                isDisabled={!editMode}
                label="State"
                required
                placeholder="Select state..."
                onStateChange={(option) => {
                  if (option) {
                    setFieldError(name, undefined);
                  }
                  setFieldValue(name, option);
                  setTimeout(() => {
                    setFieldTouched(name, true, true);
                  }, 0);
                }}
                {...{ meta, onBlur, name, value }}
              />{" "}
            </span>
          )}
        </Field>
      </FormGroup>
    );

    const companyName = (
      <span className={`${keys && keys.includes("name") && "field-changed"}`}>
        <FormikInput
          disabled={!editMode}
          name="name"
          label="Company Name"
          required
          labelId="name"
          placeholder="Enter company name"
        />
      </span>
    );

    const address = (
      <span
        className={`${keys && keys.includes("address") && "field-changed"}`}
      >
        <FormikInput
          disabled={!editMode}
          name="addressInfo.address"
          label="Address"
          required
          labelId="addressInfo.address"
          placeholder="Enter street address"
        />
      </span>
    );

    const city = (
      <span className={`${keys && keys.includes("city") && "field-changed"}`}>
        <FormikInput
          disabled={!editMode}
          name="addressInfo.city"
          label="City"
          required
          labelId="addressInfo.city"
          placeholder="Enter city name"
        />
      </span>
    );

    const zip = (
      <span className={`${keys && keys.includes("zip") && "field-changed"}`}>
        <FormikMaskedInput
          disabled={!editMode}
          name="addressInfo.zip"
          label="Zip Code"
          required
          labelId="addressInfo.zip"
          placeholder="Enter zip code"
          mask={zipCodeMask}
        />
      </span>
    );

    const interstate = (
      <span
        className={`${keys && keys.includes("interstate") && "field-changed"}`}
      >
        <FormikInput
          disabled={!editMode}
          name="interstate"
          label="Interstate"
          labelId="interstate"
          placeholder="Enter interstate"
        />
      </span>
    );

    const mileMarker = (
      <span
        className={`${keys && keys.includes("mileMarker") && "field-changed"}`}
      >
        <FormikInput
          disabled={!editMode}
          name="mileMarker"
          label="Mile marker"
          labelId="mileMarker"
          placeholder="Enter mile marker"
        />
      </span>
    );

    if (createMode) {
      return (
        <React.Fragment>
          <TitleRow
            title="Business info"
            className={"fts-business-info-title"}
          />
          <Breakpoint sm down>
            <span className="fts-business-info-title-message">
              Fill out the required fields and preview your listing below.
            </span>
          </Breakpoint>
          <Divider textColor="primary" position="left">
            Company
          </Divider>

          <Row>
            <Col md="5">{companyName}</Col>
          </Row>
          <Row>
            <Col md="6" lg="4">
              {address}
            </Col>
            <Col md="12" lg="8">
              <Row>
                <Col md="6" lg="4">
                  {city}
                </Col>
                <Col md="6" lg="4">
                  {statesPicker}
                </Col>
                <Col md="6" lg="4">
                  {zip}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="6" lg="6">
              {interstate}
            </Col>
            <Col md="6" lg="6">
              {mileMarker}
            </Col>
          </Row>
        </React.Fragment>
      );
    }

    return (
      <div>
        {updateListingFromEmail && (
          <AccountBox
            accountInfo={accountInfo}
            listing
            className="mb-0 mt-0"
            updateListingFromEmail={updateListingFromEmail}
          />
        )}
        <div
          className={`${
            right && changedSections.includes("listingInfo")
              ? "section-changed"
              : right
                ? "section-not-change"
                : null
          }`}
        >
          <TitleRow
            title={listingData?.name + " #" + listingData?.id}
            className="mb-0 mt-0 pt-0"
          >
            <Breakpoint md up>
              <div className="text-right overflow-visible">
                {editMode && !updateListingFromEmail && (
                  <>
                    <>
                      {updateListingSidebar &&
                        !this.props.reviewListingData && (
                          <div className="d-flex align-items-center">
                            <span
                              style={{
                                display: "inline-block",
                                marginRight: "1rem",
                              }}
                            >
                              <FTSMenu
                                options={this.options}
                                isOpen={this.state.isOpen}
                                handleMenu={this.handleMenu}
                              />
                            </span>
                            <OnHoldPicker
                              className="mr-1"
                              listingData={listingData}
                              handleSidebar={this.props.handleSidebar}
                            />
                          </div>
                        )}
                    </>

                    {!updateListingSidebar ? (
                      <>
                        <Button
                          color="tertiary"
                          type="button"
                          className="preview-listing-btn account-listing-header-btn"
                          onClick={() => {
                            toggleEditConfirmationModal();
                          }}
                        >
                          <span className="preview-listing-btn-label">
                            Cancel
                          </span>
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          className="account-listing-header-btn acc-list-primary-btn mr-1"
                        >
                          Save changes
                        </Button>
                      </>
                    ) : null}
                  </>
                )}

                {!editMode && (
                  <PermissionGate
                    permissions={[
                      PERMISSIONS[this.props.userData?.userType]
                        .VENDOR_CREATE_LISTING,
                    ]}
                    principle={PERMISSION_GATE_PRINCIPLE.ALL}
                  >
                    <>
                      {!updateListingFromEmail && !preview && (
                        <span
                          style={{
                            display: "inline-block",
                            marginRight: "1rem",
                          }}
                        >
                          <FTSMenu
                            options={this.options}
                            isOpen={this.state.isOpen}
                            handleMenu={this.handleMenu}
                          />
                        </span>
                      )}

                      {!preview && (
                        <PermissionGate
                          permissions={[
                            PERMISSIONS[this.props.userData?.userType]
                              ?.VENDOR_EDIT_LISTING,
                          ]}
                          principle={PERMISSION_GATE_PRINCIPLE.ALL}
                        >
                          <span className="edit-btn-wrapper mr-1">
                            <Button
                              type="button"
                              className="edit-btn transparent p-1"
                              disabled={listingLocked}
                              onClick={() => {
                                resetForm();
                                onEditToggle();
                              }}
                            >
                              <Icon name="Edit" size={20} color="#32383A" />
                              <span className="edit-btn-label">Edit</span>
                            </Button>
                          </span>
                        </PermissionGate>
                      )}
                    </>
                  </PermissionGate>
                )}
              </div>
            </Breakpoint>
          </TitleRow>

          <span className="listing-info-row listing-info-wrapper">
            <span className="listing-location-info">
              <MapPin size={16} />{" "}
              <span
                className={`${
                  keys && keys.includes("city") && "field-changed"
                }`}
              >{`${listingData?.city}`}</span>
              <span
                className={`${
                  keys && keys.includes("state") && "field-changed"
                }`}
              >
                <span> </span>
                {`, ${listingData?.state}`}
              </span>
            </span>
            <span
              className={`listing-phone-info ${
                keys && keys.includes("phoneNumber") && "field-changed"
              }`}
            >
              <Phone size={16} /> {listingData?.phoneNumber}
            </span>
            {(listingData.status === 0 || listingData.status === 3) && (
              <FTSBadge
                style={{
                  backgroundColor:
                    listingData.status === 3 ? "#F5B053" : "#F36565",
                  color: "fff",
                  fontWeight: "bold",
                  marginLeft: 20,
                }}
              >
                {listingStatuses[listingData.status]}
              </FTSBadge>
            )}
          </span>

          <Breakpoint sm down>
            {!editMode && (
              <PermissionGate
                permissions={[
                  PERMISSIONS[this.props.userData?.userType]
                    .VENDOR_CREATE_LISTING,
                ]}
                principle={PERMISSION_GATE_PRINCIPLE.ALL}
              >
                <div className="listing-info-actions-mobile">
                  {!preview && (
                    <PermissionGate
                      permissions={[
                        PERMISSIONS[this.props.userData?.userType]
                          .VENDOR_EDIT_LISTING,
                      ]}
                      principle={PERMISSION_GATE_PRINCIPLE.ALL}
                    >
                      <span className="edit-btn-wrapper mr-1">
                        <Button
                          type="button"
                          className="edit-btn transparent p-1"
                          disabled={listingLocked}
                          onClick={() => {
                            resetForm();
                            onEditToggle();
                          }}
                        >
                          <Icon name="Edit" size={20} color="#32383A" />
                          <span className="edit-btn-label">Edit</span>
                        </Button>
                      </span>
                    </PermissionGate>
                  )}

                  {!updateListingFromEmail && !preview && (
                    <FTSMenu
                      options={this.options}
                      isOpen={this.state.isOpen}
                      handleMenu={this.handleMenu}
                      label="More"
                    />
                  )}
                </div>
              </PermissionGate>
            )}

            {/* POST RELEASE */}
            {/* {this.showUpgradeButton() && !editMode && (
              <Button
                onClick={handleUpgrade}
                className="w-100 mt-1"
                color="primary"
              >
                Upgrade Listing
              </Button>
            )} */}
          </Breakpoint>
        </div>

        <div
          className={`mt-2 ${
            right && changedSections.includes("company")
              ? "section-changed"
              : null
          }`}
        >
          <Divider textColor="primary" position="left">
            Company
          </Divider>

          <Row>
            <Col md="6" lg="4" xl="3">
              {companyName}
            </Col>
            <Col md="6" lg="4" xl="3">
              {interstate}
            </Col>
            <Col md="6" lg="4" xl="3">
              {mileMarker}
            </Col>
          </Row>
          <Row>
            <Col md="6" lg="4" xl="3">
              {address}
            </Col>
            <Col md="6" lg="4" xl="3">
              {city}
            </Col>
            <Col md="6" lg="4" xl="3">
              {statesPicker}
            </Col>
            <Col md="6" lg="4" xl="3">
              {zip}
            </Col>
          </Row>
        </div>

        <ConfirmationModal
          title="Confirmation"
          message={`Are you sure you want to remove this listing?`}
          closeModal={() =>
            this.setState({ confirmModalForRemoveListing: false })
          }
          confirmModal={this.handleDeleteListing}
          isOpen={this.state.confirmModalForRemoveListing}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    userData: state.authState.user,
    activitiesTypes: state.essentialLists.activitiesTypes,
  }),
  { createActivityVendor, updateListingStatus },
)(memo(ListingInfo));
