import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";

import "../../../../assets/scss/components/fts/update-history.scss";

import {
  getNotifications,
  getNotificationsCount,
  markAsRead,
  setNotificationUnreadCount,
} from "../../../../redux/actions/notifications";
import { requestParticipation } from "../../../../redux/actions/todo";
import Flatpickr from "react-flatpickr";
import Icon from "../../Icons";
import { activities, NOTIFICATION } from "../../../../constants";
import FormikInputValue from "../../formik/formik-input-value";
import { setUserActivities } from "../../../../redux/actions/user-activities";
import {
  fetchActivitiesAccount,
  fetchActivitiesListing,
  createActivity,
} from "../../../../redux/actions/activities";
import Breakpoint from "../../../hoc/Breakpoint";
import classNames from "classnames";
import { capitalizeFirstLetter } from "../../../../utility/googleCalendar";

class Notifications extends Component {
  state = {
    isLoading: false,
    notifications: null,
    startDate: null,
    endDate: null,
  };

  menuRef = React.createRef();
  dateRef = React.createRef();

  componentDidMount() {
    this.getUpdatesHistory();
  }

  getUpdatesHistory = () => {
    this.setState({
      isLoading: true,
    });

    const payload = {
      startDate: moment(this.state.startDate).toISOString(),
      endDate: moment(this.state.endDate).add(1, "days").toISOString(),
    };

    this.props
      .getNotifications(payload)
      .then((res) => {
        this.setState({
          notifications: res.data,
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
    this.props
      .getNotificationsCount()
      .then((res) => this.props.setNotificationUnreadCount(res.data?.result));
  };

  clearFlatPickr = () => {
    this.dateRef.current.flatpickr.clear();
    this.setState({ startDate: null, endDate: null }, () => {
      this.getUpdatesHistory();
    });
  };

  handleDate = (values) => {
    this.setState(
      {
        startDate: values[0],
        endDate: values[1],
      },
      () => {
        this.getUpdatesHistory();
      },
    );
  };

  markAllAsRead = () => {
    this.props.markAsRead(0);
    let allNotifications = [...this.state.notifications];

    allNotifications.map((e) => (e.read = true));
    this.setState({ notifications: [...allNotifications] });
    this.props.setNotificationUnreadCount(0);
  };

  handleRequestStatus = (status, selectedNotification) => {
    const {
      link,
      sendBy,
      id: notificationId,
      description: todoTitle,
      title: companyName,
    } = selectedNotification;

    let todoId = link.split("?")[1].split("=")[1];
    let ownerId = link.split("/")[2].split("?")[0];

    const params = {
      todoId,
      sendBy,
      status,
      notificationId,
      todoTitle,
      companyName,
      ownerId,
    };
    let notifications = [...this.state.notifications];
    let activityId = 0;
    if (this.props.activities) {
      let activities = [...this.props.activities];
      let newActivities = [];
      for (let activity of activities) {
        newActivities.push({ ...activity });
      }
      for (let activity of newActivities) {
        if (activity.notificationId === notificationId) {
          activity.requestHandled = true;
          activityId = activity.id;
        }
      }

      this.props.setUserActivities([...newActivities]);
    }

    let index = notifications.findIndex((e) => e.id === notificationId);

    let activityPayload = {
      status: 1,
      toDoId: todoId,
      userId: ownerId,
      activityId,
      notificationId,

      activityType: this.props.activitiesTypes.HANDLED_PARTICIPATION_REQUEST,
      activity: activities.handleParticipationRequest
        .replace(
          "{{admin_name}}",
          `${this.props.userData.firstName} ${this.props.userData.lastName}`,
        )
        .replace("{{status}}", status)
        .replace("{{todoId}}", `${todoId}`),
      adminUserId: this.props.userData.adminAccountOwner || null,
      iconName: "Todos",
    };

    this.props.createActivity(activityPayload).then(async () => {
      let path = window.location.pathname.split("/");
      let isListing = path.find((e) => e === "listings");
      let isAccount = path.find((e) => e === "accounts");

      if (isListing) {
        let { data } = await this.props.fetchActivitiesListing(path[2]);
        await this.props.setUserActivities(data);
      }
      if (isAccount) {
        let { data } = await this.props.fetchActivitiesAccount(path[2]);
        await this.props.setUserActivities(data);
      }
    });

    notifications[index].linkTitle = "View todo";
    notifications[index].read = true;

    this.setState({ notifications });
    this.props.requestParticipation(params);
  };

  render() {
    const { notifications, isLoading, startDate } = this.state;

    const allRead = notifications && notifications.find((e) => !e.read);
    return (
      <div className="update-history-wrapper">
        <div
          className="update-history-date"
          style={{
            position: "relative",
          }}
        >
          <div
            onClick={this.markAllAsRead}
            style={{ position: "absolute", top: -50, right: 360 }}
          >
            {allRead && (
              <Breakpoint md up>
                <span className="fts-link">
                  <Icon color={"#fff"} name={"CheckIcon"} /> Mark all as read
                </span>
              </Breakpoint>
            )}
          </div>
          <div className="range-picker" style={{ top: -65 }}>
            <div className="range">
              <div className="position-relative has-icon-right">
                <div className="fts-date-and-time">
                  <div
                    id="datepickerWrapperFrom"
                    className="u-datepicker input-group"
                  >
                    <Flatpickr
                      ref={this.dateRef}
                      placeholder="Select date range"
                      className="fts-date-picker form-control date-picker"
                      options={{
                        mode: "range",
                        dateFormat: "M d, Y",
                        locale: {
                          rangeSeparator: " - ",
                        },
                      }}
                      onChange={(values) => {
                        if (values.length === 2) {
                          this.handleDate(values);
                        }
                      }}
                    />
                  </div>
                </div>

                {startDate ? (
                  <div
                    className="form-control-position clear-calendar-button"
                    onClick={() => {
                      if (this.dateRef?.current?.flatpickr) {
                        this.clearFlatPickr();
                      }
                    }}
                  >
                    <Icon name="ClearCalendar" size={16} />
                  </div>
                ) : (
                  <div className="form-control-position">
                    <Icon name="Calendar" size={17} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="notifications-wrapper"
          style={{
            overflow: "auto",
            maxHeight: window.innerHeight - 85,
          }}
        >
          {isLoading || !notifications ? (
            <div className="d-flex justify-content-center pt-2">
              <Spinner size="sm" />
            </div>
          ) : notifications.length ? (
            notifications.map((e) => (
              <Notification
                handleRequestStatus={this.handleRequestStatus}
                key={"Notification_" + e.id}
                notification={e}
                employees={this.props.employees}
                markAsRead={(id) => {
                  this.props.markAsRead(id);
                  let allNotifications = [...notifications];
                  let index = allNotifications.findIndex((e) => e.id === id);
                  allNotifications[index].read = true;
                  this.setState({ notifications: [...allNotifications] });
                  this.props.setNotificationUnreadCount(
                    this.props.unReadCount - 1,
                  );
                }}
              />
            ))
          ) : (
            <div className="d-flex flex-column align-items-center">
              <Icon
                name="EmptySearch"
                style={{ marginBottom: "1rem" }}
                size={182}
              />
              <p className="empty-text">No results found.</p>
              <p className="empty-small-text">
                We can’t find any item matching your search.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const Notification = ({
  notification = NOTIFICATION,
  markAsRead,
  handleRequestStatus,
}) => {
  // const getAdminName = (id) => {
  //   return employees.find((employee) => employee.value === id);
  // };

  return (
    <div
      className="notification-wrapper"
      style={{
        backgroundColor: notification.read ? "#FAFAFC" : "#fff",
      }}
    >
      <div
        className={classNames("notification-header", {
          "notification-header-unread": !notification.read,
        })}
      >
        <div className="d-flex align-items-center">
          <span
            style={{ padding: 10 }}
            // className={notification.read ? "" : "unread"}
            className={classNames("icon-wrapper", {
              unread: !notification.read,
              "icon-wrapper-read": notification.read,
            })}
          >
            <Icon size={25} name={notification.icon} />
          </span>
          <h5 className={"ml-1 mb-0"}>{notification.title}</h5>
        </div>
        <div className="notification-action">
          {!notification.read && (
            <span
              onClick={() => markAsRead(notification.id)}
              className={"fts-link mr-1"}
            >
              <Icon color={"#fff"} name={"CheckIcon"} /> Mark as read
            </span>
          )}
          <span>{moment(notification.createdAt).format("ll")}</span>
        </div>
      </div>
      {/* <div style={{ margin: "12px 0" }}>
        <h6 className={"m-0 p-0"}>
          {getAdminName(notification.sendBy)?.label}{" "}
          {notification.notification.toLowerCase()}
        </h6>
      </div> */}
      {notification.description && (
        <div
          className={"rounded mt-1"}
          style={{
            padding: 10,
            background: "#F9FAFC",
            border: "1px solid #E5E8E9",
          }}
        >
          <FormikInputValue className={"text-dark"}>
            {decodeURIComponent(notification.description)}
          </FormikInputValue>
        </div>
      )}
      {notification.linkTitle === "requestParticipation" ? (
        <div
          style={{ marginTop: 16 }}
          className="d-flex justify-content-between"
        >
          <a href={notification.link}>
            View todo <Icon color={"#fff"} name={"ArrowLink"} />
          </a>
          <div className="d-flex">
            <p
              onClick={() => handleRequestStatus("Rejected", notification)}
              className={"cursor-pointer d-flex align-items-center "}
              style={{ color: "#4B5457" }}
            >
              <Icon size={30} color={"#fff"} name={"Close"} />
              <span>Reject</span>
            </p>
            <p
              onClick={() => handleRequestStatus("Approved", notification)}
              className="ml-1 d-flex align-items-center  color-primary cursor-pointer"
            >
              <Icon size={30} color={"#fff"} name={"ApproveTodo"} />
              <span>Approve</span>
            </p>
          </div>
        </div>
      ) : (
        notification.link && (
          <div className="notification-actions">
            <a href={notification.link}>
              {capitalizeFirstLetter(notification.linkTitle)}{" "}
              <Icon color={"#fff"} name={"ArrowLink"} />
            </a>
          </div>
        )
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    unReadCount: state.notifications.unReadCount,
    employees: state.essentialLists.employees,
    activities: state.accountActivities.activities,
    userData: state.authState.user,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};
const mapDispatchToProps = {
  getNotifications,
  markAsRead,
  setNotificationUnreadCount,
  getNotificationsCount,
  requestParticipation,
  setUserActivities,
  fetchActivitiesListing,
  fetchActivitiesAccount,
  createActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
