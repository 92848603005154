import React, { memo } from "react";
import { FullSizeModal } from "@find-truck-service/ui/src/components/react/Modal/FullSizeModal";
import { ModalHeaderComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalHeaderComponent";
import { ModalFooterComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalFooterComponent";
import { TemporaryLinkServiceAmenitiesModalContent } from "./TemporaryLinkServiceAmenitiesModalContent";

const TemporaryLinkServiceAmenitiesModal = ({
  setShowModal,
  showModal,
  onSaveServices,
}) => {
  function onSave() {
    onSaveServices ? onSaveServices() : setShowModal(false);
  }

  function onClose() {
    setShowModal(false);
  }

  return (
    <FullSizeModal
      isOpen={showModal}
      modalHeaderComponent={() => (
        <ModalHeaderComponent onClose={onClose} title={"Add Services"} />
      )}
      modalContentComponent={() => (
        <TemporaryLinkServiceAmenitiesModalContent />
      )}
      modalFooterComponent={() => (
        <ModalFooterComponent successLabel={"Save"} onSuccess={onSave} />
      )}
    />
  );
};

export const MemoizedTemporaryLinkServiceAmenitiesModal = memo(
  TemporaryLinkServiceAmenitiesModal,
);
