import React from "react";
import { X } from "react-feather";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { deleteTodoAsset } from "../../../redux/actions/todo";
import { Spinner } from "reactstrap";
import ConfirmationModal from "../confirmation-modal";

import capitalize from "../../../utility/capitalize";

import "../../../assets/scss/components/fts/file-item.scss";
import { ReactComponent as Preview } from "../../../assets/img/svg/Icon/Preview.svg";
import Icon from "../Icons";
import { formatImageLink } from "../../../utility";
import logger from "../../../utility/logger";

const renderSize = ({ imageSize = 0 }) => {
  return `${imageSize?.toFixed(2)} MB (size)`;
};
const FileItem = ({
  file,
  url,
  onRemove,
  hideRemoveBtn,
  isUploadedFile,
  isLocalFile,
  arrayName,
  isUppyFile,
  // taskManagement,
  removeFileHandler,
  dateModified,
  listFile,
  isFlagged,
  disableImagePreview,
  imageSize,
}) => {
  let extension, fileName, fileUrl;

  const dispatch = useDispatch();
  const [isItemRemoving, setIsItemRemoving] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  if (!isUppyFile && isLocalFile) {
    const fileNameWithExt = file?.name;
    const fileNameParts = fileNameWithExt.split(".");
    extension = fileNameParts.pop();
    fileName = fileNameParts.join(".");
  } else if (isUppyFile && isLocalFile) {
    const fileObj = file?.data;

    const fileNameWithExt = fileObj?.name;
    const fileNameParts = fileNameWithExt.split(".");
    extension = fileNameParts.pop();
    fileName = fileNameParts.join(".");
  } else if (isUploadedFile) {
    fileUrl = file?.path;

    fileName = fileUrl.split("/").pop();
    extension = file?.type;
  } else {
    fileUrl = file?.uploadURL ?? file?.url ?? url ?? "";

    const fileNameWithExt = fileUrl.split("/").pop();
    const fileNameParts = fileNameWithExt.split(".");
    extension = fileNameParts.pop();
    fileName = fileNameParts.join(".");
  }
  fileName = decodeURIComponent(fileName);

  const removeItemHandler = () => {
    setConfirmationModalOpen(true);
  };

  if (fileUrl) {
    fileUrl = formatImageLink(fileUrl);
  }

  return (
    <div
      className="file-item"
      style={isFlagged ? { border: "1px solid #01B5F5" } : {}}
    >
      <div className="file-item__content">
        <div className="file-item__image">
          <div className="file-item__extension">
            {extension ? capitalize(extension) : ""}
          </div>
        </div>
        <div className="file-item__data">
          <div className="file-item__name">
            {isLocalFile || listFile ? (
              <div className="file-item__name-container">
                {fileName}{" "}
                {dateModified && (
                  <span className="file-item__size ">({dateModified})</span>
                )}
              </div>
            ) : (
              <>
                {!disableImagePreview && (
                  <a
                    className="file-item__name-container"
                    href={fileUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    title={fileName}
                  >
                    {fileName}
                  </a>
                )}
                {disableImagePreview && (
                  <div className="file-item__name-container">{fileName}</div>
                )}
              </>
            )}
          </div>
          <div className="file-item__size">{renderSize({ imageSize })}</div>
        </div>
        {!hideRemoveBtn && (
          <div className="file-item__remove">
            {!isItemRemoving && (
              <X
                size={16}
                onClick={() => {
                  removeItemHandler(file);
                }}
              />
            )}
            {isItemRemoving && <Spinner size="sm" />}
          </div>
        )}
        {isFlagged && (
          <div className="file-item__remove">
            <Icon name={"Flag"} color={"#01B5F5"} />
          </div>
        )}
        {listFile && (
          <div className="file-item__remove">
            {!isItemRemoving && (
              <>
                <Preview
                  size={16}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(fileUrl, "_blank").focus();
                  }}
                />
              </>
            )}
          </div>
        )}
      </div>
      <ConfirmationModal
        title="Confirmation"
        message="Are you sure you want to delete this file?"
        closeModal={() => {
          setConfirmationModalOpen(false);
        }}
        confirmModal={() => {
          setConfirmationModalOpen(false);

          if (onRemove) {
            onRemove(file, arrayName);
          } else {
            setIsItemRemoving(true);
            dispatch(deleteTodoAsset(file.id))
              .then(() => {
                setIsItemRemoving(false);
                removeFileHandler(file.id);
              })
              .catch((err) => logger.error(err));
          }
        }}
        isOpen={confirmationModalOpen}
      />
    </div>
  );
};

export default FileItem;
