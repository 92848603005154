import React from "react";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { useSelector } from "react-redux";
import {
  selectOwnerOperatorProfileFinishLaterLoading,
  selectOwnerOperatorProfileUpdateLoading,
} from "../../../../redux/reducers/v2/owner-operator-profile";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";

const TruckFormButtonsMd = ({
  handleUpdateOwnerOperatorTruck,
  handleOwnerOperatorTruckFinishLater,
}) => {
  const ownerOperatorUpdateLoading = useSelector(
    selectOwnerOperatorProfileUpdateLoading,
  );
  const ownerOperatorFinishLaterLoading = useSelector(
    selectOwnerOperatorProfileFinishLaterLoading,
  );

  return (
    <FtsWrapper display={{ xs: "none", sm: "grid", md: "grid", lg: "grid" }}>
      <FtsRow mt={"4xl"} columnGap={"lg"}>
        <FtsColumn size={{ xs: 6, md: 6, lg: 6 }}>
          <Button
            onClick={handleOwnerOperatorTruckFinishLater}
            size={ButtonSizes.md}
            color={ButtonColorsValue.neutral}
            type={ButtonTypes.outlined}
            label={"Finish Later"}
            width={"100%"}
            loading={ownerOperatorFinishLaterLoading}
            disabled={
              ownerOperatorFinishLaterLoading || ownerOperatorUpdateLoading
            }
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 6, md: 6, lg: 6 }}>
          <Button
            onClick={handleUpdateOwnerOperatorTruck}
            size={ButtonSizes.md}
            color={ButtonColorsValue.accent}
            type={ButtonTypes.contained}
            label={"Save"}
            width={"100%"}
            loading={ownerOperatorUpdateLoading}
            disabled={
              ownerOperatorFinishLaterLoading || ownerOperatorUpdateLoading
            }
          />
        </FtsColumn>
      </FtsRow>
    </FtsWrapper>
  );
};
export default TruckFormButtonsMd;
