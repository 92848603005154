export const addOrUpdateAsset = (assets, newAsset) => {
  const fileName = newAsset.file.name;
  const isDuplicate = !!assets.find(({ file }) => file?.name === fileName);

  if (!isDuplicate) return [...assets, newAsset];

  return assets.map((asset) =>
    asset.file.name === fileName ? newAsset : asset,
  );
};

export const updateAssetStatus = (assets, fileName, updates) => {
  return assets.map((asset) =>
    asset.fileName === fileName ? { ...asset, ...updates } : asset,
  );
};
