import React from "react";
import "../../../assets/scss/pages/password-expired.scss";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";

const PasswordExpired = () => {
  return (
    <div
      className="d-flex flex-column position-relative w-100 bg-theme"
      style={{ height: "100vh" }}
    >
      <div style={{ flex: "1 1 0%" }}>
        <HeaderWrapper />
        <div className="FTS-password-expired-wrapper">
          <div className="FTS-password-expired">
            <h2>Update password!</h2>
            <div className="FTS-password-expired-expiry-message">
              <span>Your password has expired on March 2, 2024.</span>
            </div>
            <p className="FTS-password-expired-message">
              Our most recent security update requires that you update your Find
              Truck Service account password. Please follow the instructions in
              your email to update your password.
            </p>
            <p className="FTS-password-expired-contact-us">
              Thank you, <br />
              Your Find Truck Service team!
            </p>
            <span className="FTS-password-expired-contact-us">
              If you didn’t received an e-mail or need any assistance, please{" "}
              <a href={`${window.location.origin}/contact`}>Contact us.</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordExpired;
