import React from "react";
import { Route } from "react-router-dom";
import { HeaderWrapper } from "../components/fts-components-v2/HeaderWrapper";

const RouteWithHeader = (props) => {
  return (
    <>
      <HeaderWrapper />
      <Route {...props} />
    </>
  );
};

export default RouteWithHeader;
