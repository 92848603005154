import styled from "styled-components";
import { FtsWrapper } from "../FtsWrapper/FtsWrapper";
export const FtsRadioButtonWrapper = styled(FtsWrapper) `
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
