import React, { useEffect } from "react";
import { history } from "../../../history";
import { Pages } from "../../constants/pages";
import { useDispatch, useSelector } from "react-redux";
import { extractParameter } from "../../../utility/param-extract";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { HaveAnAccountText } from "./components/HaveAnAccountText";
import { TermsAndConditions } from "./components/TermsAndConditions";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { CreateAccountFormTitle } from "./components/CreateAccountFormTitle";
import { setCreateAccountData } from "../../../redux/actions/v2/createAccount";
import { CreateAccountFormButtons } from "./components/CreateAccountFormButtons";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { selectCreateAccountIsLoading } from "../../../redux/reducers/v2/createAccount";
import { CreateAccountFormInputFields } from "./components/CreateAccountFormInputFields";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { selectSocialAuthIsLoading } from "../../../redux/reducers/v2/social-auth";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import { selectHasUserData } from "../../../redux/reducers/v2/auth";

const CreateAccount = () => {
  const type = extractParameter("type");
  const hasUserData = useSelector(selectHasUserData);
  const isLoading = useSelector(selectCreateAccountIsLoading);
  const isSocialAuthLoading = useSelector(selectSocialAuthIsLoading);
  const dispatch = useDispatch();

  const inProgress = isLoading || isSocialAuthLoading;

  useEffect(() => {
    if (hasUserData) history.replace(Pages.DASHBOARD);
  }, [hasUserData]);

  useEffect(() => {
    if (!type) return history.push(Pages.CHOOSE_ACCOUNT_TYPE);
    dispatch(setCreateAccountData({ data: { type } }));
  }, [type]);

  return (
    <>
      {inProgress && <FullPageLoader />}
      <HeaderWrapper />
      <FtsContainer>
        <FtsRow>
          <FtsColumn
            size={{ md: 12, lg: 6 }}
            offset={{ md: 0, lg: 3 }}
            mt={{ xs: "0", sm: "0", md: "4xl", lg: "4xl" }}
          >
            <FtsRow
              boxShadow="sm"
              borderRadius="2xs"
              bg={ColorsValue["greyscale-0"]}
              px={{ xs: "xl", sm: "2xl", md: "4xl", lg: "4xl" }}
              py={{ xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" }}
              rowGap={{ xs: "2xl", sm: "2xl", md: "4xl", lg: "4xl" }}
            >
              <CreateAccountFormTitle />
              <CreateAccountFormInputFields />
              <CreateAccountFormButtons type={type} />
              <HaveAnAccountText />
            </FtsRow>
            <TermsAndConditions />
          </FtsColumn>
        </FtsRow>
      </FtsContainer>
    </>
  );
};

export default CreateAccount;
