import React from "react";
import { Input, Button } from "reactstrap";
import FilterChip from "../filter-chip";
import "../../../assets/scss/components/fts/table-header.scss";
import classNames from "classnames";

const FTSTableHeader = ({
  updateSearchQuery,
  onCreateNewPress,
  searchVal,
  searchValType,
  tableName,
  filterChips,

  isListing,
  searchPlaceholder,
  accountId,
  totalAmount,
}) => {
  return (
    <div className="ag-grid-actions d-flex align-items-center justify-content-between flex-wrap mb-1 pt-1">
      <div
        className={classNames("filter-section d-flex", {
          "w-100": !!filterChips,
        })}
      >
        <div
          className={classNames("d-flex", {
            // "w-75": !!accountId,
          })}
        >
          <Input
            className="mr-1 mb-1 mb-sm-0"
            style={{
              width: !!accountId || isListing ? "150px" : "370px",
            }}
            type="text"
            placeholder={
              !accountId && !isListing ? searchPlaceholder : "Search..."
            }
            onChange={(e) =>
              updateSearchQuery(searchValType, e.target.value, tableName)
            }
            defaultValue={searchVal}
          />
        </div>

        <div
          className={classNames("d-flex justify-content-between", {
            // "mt-2": !!accountId,
            "w-100": !!filterChips,
          })}
        >
          <div className="d-flex">
            {!!filterChips &&
              filterChips.map((filterChip, index) => {
                return (
                  <FilterChip
                    key={index}
                    chipLabel={filterChip.chipLabel}
                    chipNumber={filterChip.chipNumber}
                    id={filterChip?.id}
                    color={filterChip.color}
                    active={filterChip.active}
                    onClickChip={filterChip.onClickChip}
                  />
                );
              })}
          </div>
          {!!filterChips && (
            <div className="FTS-price-amount">
              <div className="FTS-price-amount-content">
                <span className="FTS-price-amount-label">TOTAL AMOUNT </span>
                <span className="FTS-price-amount-number">${totalAmount}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        {onCreateNewPress && (
          <Button
            type="button"
            outline
            className="p-1"
            color="primary"
            onClick={() =>
              onCreateNewPress ? onCreateNewPress(tableName) : null
            }
          >
            + Create New
          </Button>
        )}
      </div>
    </div>
  );
};

export default React.memo(FTSTableHeader);
