import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { AmenitiesMobile } from "../../components/AmenitiesMobile";
import { AmenitiesDesktop } from "../../components/AmenitiesDesktop";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { selectAmenities } from "../../../../../redux/reducers/essentialLists";
import { MemoizedTemporaryLinkAmenitiesModal } from "./TemporaryLInkAmenitiesModal";
import { setTemporaryLinkEditListingData } from "../../../../../redux/actions/v2/temporaryLinkListing";
import { selectTemporaryLinkListingAdsAmenities } from "../../../../../redux/reducers/v2/temporaryListingLink";

export const TemporaryLinkAmenitiesRow = () => {
  const [searchString, setSearchString] = useState("");
  const dispatch = useDispatch();
  const selectedAmenities = useSelector(selectTemporaryLinkListingAdsAmenities);
  const amenities = useSelector(selectAmenities);
  const [filteredAmenities, setFilteredAmenities] = useState(null);
  const hasAmenities = !!filteredAmenities?.length;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!searchString) return setFilteredAmenities(amenities);
    const filtered = amenities.filter((e) =>
      e.name.toLowerCase().includes(searchString.toLowerCase()),
    );
    setFilteredAmenities(filtered);
  }, [searchString, amenities]);

  const onChangeAmenities = useCallback(
    (id, value, name) => {
      let adsAmenities = [
        ...(selectedAmenities ?? []),
        { amenityId: id, name },
      ];
      if (!value)
        adsAmenities = adsAmenities.filter(({ amenityId }) => amenityId !== id);
      dispatch(setTemporaryLinkEditListingData({ data: { adsAmenities } }));
    },
    [selectedAmenities],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} width={"100%"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          AMENITIES
        </Span>
      </FtsColumn>
      <AmenitiesMobile
        selectedAmenities={selectedAmenities}
        setShowModal={setShowModal}
      >
        <MemoizedTemporaryLinkAmenitiesModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </AmenitiesMobile>
      <AmenitiesDesktop
        searchString={searchString}
        hasAmenities={hasAmenities}
        setSearchString={setSearchString}
        selectedAmenities={selectedAmenities}
        onChangeAmenities={onChangeAmenities}
        filteredAmenities={filteredAmenities}
      />
    </FtsRow>
  );
};
