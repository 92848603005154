import React from "react";
import Icon from "../Icons";
import "../../../assets/scss/components/requested-access-empty-state.scss";

const RequestedAccessEmptyState = ({
  iconName,
  iconSize = 88,
  header = "Access request rejected",
  message = "Find Truck Service will be notified that you rejected their request to access your account.",
}) => {
  return (
    <div className="FTS-requested-access-empty-state">
      <div className="FTS-requested-access-empty-state-icon-wrapper">
        <Icon name={iconName} size={iconSize} />
      </div>
      <h3 className="FTS-requested-access-empty-state-header">{header}</h3>
      <span className="FTS-requested-access-empty-state-message">
        {message}
      </span>
    </div>
  );
};

export default RequestedAccessEmptyState;
