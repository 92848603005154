/* eslint-disable indent */
import React, { PureComponent, createRef } from "react";
import { Button, Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { connect } from "react-redux";
// import { Trash2 } from "react-feather";
import { Form, Formik } from "formik";

import Section from "./Section";
import ListingInfo from "./sections/listing-info";
import BusinessDetails from "./sections/BusinessDetails";
import Description from "./sections/Description";
import WorkingHours from "./sections/WorkingHours";
import Amenities from "./sections/Amenities";
// import Note from "./sections/Note";

import GoogleMap from "../../../components/fts/formik/google-map";
import Services from "../../../components/fts/services";
import Divider from "../../../components/fts/Divider";
import FormValidationEffect from "../../../components/fts/form-validation-effect";

import { weekdays } from "./data";

// toast styles
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";

import "../../../assets/scss/pages/account-settings.scss";
import "../../../assets/scss/pages/listing-settings.scss";
import "../../../assets/scss/pages/account-listing-shared.scss";
import "../../../assets/scss/components/fts/form-fields.scss";
import PromptWrapper from "../../../components/fts/PromptWrapper";
import FileItem from "../../../components/fts/files/FileItem";
import moment from "moment";
import { setUpdateListingTypePayload } from "../../../redux/actions/ads";
import AdsRates from "../../../components/fts/listing-rates";
import Breakpoint from "../../../components/hoc/Breakpoint";
import { formatImageLink } from "../../../utility";
import { LISTING_IMAGE_SIZES } from "../../../constants";
import { getListingTitle } from "../../../utility/getListingTitle";
import Icons from "../../../components/fts/Icons";

import FtsContactFormModal from "../../../components/fts/fts-contact-form-modal";

class ListingDetails extends PureComponent {
  formRef = createRef();
  state = {
    listingFiles: null,
    listingData: this.props.listingData,
    contactFormModalIsOpen: false,
  };
  componentDidMount = () => {
    setTimeout(() => {
      this.formRef?.current?.resetForm && this.formRef.current.resetForm();
    }, 100);
  };

  handleContactFormModal = () => {
    this.setState({
      contactFormModalIsOpen: !this.state.contactFormModalIsOpen,
    });
  };
  render() {
    const {
      updateListingFromEmail,
      inProgress,
      editMode,

      imageLink,
      imageSize,
      validationSchema,
      onFormSubmission,
      onSubmitError,
      toggleEdit,
      onRemoveListing,
      onPreviewListing,

      callToAction,
      resetImageLink,
      updateListingSidebar,
      handleSidebar,

      toggleDuplicateListingModal,
      toggleReassignListingModal,
      onKeyDown,
      accountInfo,
      formikRef,
      listingFormRef,
      preview,
      differences,
      changedServiceAmenities,
      changedAmenities,
      changedServices,
      changedSections,
      right,
      setFormikSidebarRef,
      listingData,
      toggleEditConfirmationModal,
      toggleMultipleListingsModal,
      saveUpdatePayload,
      toggleListingPreviewModal,
      setListingPreviewModalTitle,
      listingLocked,
      handleUpgrade,
    } = this.props;

    // let { listingFiles } = this.state;

    return (
      <>
        {inProgress ? (
          <React.Fragment>
            <Row>
              <Col sm="12">
                <Spinner size="sm" className="float-right" />
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <Formik
            innerRef={(e) => {
              this.formRef.current = e;
              formikRef && formikRef(e);
              listingFormRef && listingFormRef(e);
              setFormikSidebarRef && setFormikSidebarRef(e);
            }}
            enableReinitialize
            onSubmit={onFormSubmission}
            initialValues={listingData} //{formikInitialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onChange={(e) => {
              if (e.target.id === "addressInfo.address") {
                this.props.handleMapPinSet && this.props.handleMapPinSet(false);
              }
            }}
          >
            {(props) => {
              const {
                dirty,
                values,
                setFieldValue,
                resetForm,
                isValid,
                setFieldError,
                setFieldTouched,
                validateForm,
              } = props;

              return (
                <>
                  <FormValidationEffect
                    formik={props}
                    onSubmissionError={onSubmitError}
                  />
                  <PromptWrapper dirty={dirty} />
                  <Card>
                    <CardBody
                      className={`${
                        right
                          ? "fts-listing-tab-wrapper-right"
                          : "fts-listing-tab-wrapper"
                      }`}
                    >
                      <Form
                        autoComplete="off"
                        onKeyDown={onKeyDown}
                        onChange={(e) => {
                          if (e.target.id === "addressInfo.address") {
                            this.props.handleMapPinSet(false);
                          }
                        }}
                      >
                        <Section id="section1" first notWrapped>
                          <ListingInfo
                            updateListingFromEmail={updateListingFromEmail}
                            listingData={listingData}
                            toggleDuplicateListingModal={
                              toggleDuplicateListingModal
                            }
                            toggleEditConfirmationModal={
                              toggleEditConfirmationModal
                            }
                            toggleReassignListingModal={
                              toggleReassignListingModal
                            }
                            onEditToggle={toggleEdit}
                            onRemoveListing={onRemoveListing}
                            resetImageLink={resetImageLink}
                            onPreviewListing={onPreviewListing}
                            validForm={isValid}
                            resetForm={resetForm}
                            setFieldError={setFieldError}
                            setFieldTouched={setFieldTouched}
                            accountInfo={accountInfo}
                            updateListingSidebar={updateListingSidebar}
                            handleSidebar={handleSidebar}
                            preview={preview}
                            keys={differences && Object.keys(differences)}
                            changedSections={changedSections}
                            right={right}
                            toggleMultipleListingsModal={
                              toggleMultipleListingsModal
                            }
                            saveUpdatePayload={saveUpdatePayload}
                            values={values}
                            validateForm={validateForm}
                            formRef={this.formRef}
                            adminPermissions={this.props.adminPermissions}
                            toggleListingPreviewModal={
                              toggleListingPreviewModal
                            }
                            setListingPreviewModalTitle={
                              setListingPreviewModalTitle
                            }
                            reviewListingData={this.props.reviewListingData}
                            listingLocked={listingLocked}
                            handleUpgrade={handleUpgrade}
                            {...{
                              editMode,
                              callToAction,
                            }}
                          />

                          <div
                            className={`${
                              right && changedSections.includes("mapPreview")
                                ? "section-changed"
                                : null
                            }`}
                          >
                            <Divider textColor="primary" position="left">
                              Map Preview
                            </Divider>

                            <GoogleMap
                              {...{
                                editMode,
                                values,
                                setFieldValue,
                                mainListingPage: true,
                              }}
                              preview={preview}
                              disableMapScroll={preview}
                              keys={differences && Object.keys(differences)}
                              mapPinSet={this.props.mapPinSet}
                              handleMapPinSet={this.props.handleMapPinSet}
                              mapPinNotSetError={this.props.mapPinNotSetError}
                              handleMapPinError={this.props.handleMapPinError}
                            />
                          </div>
                        </Section>
                        <div
                          className={`${
                            right &&
                            (changedSections.includes("contact") ||
                              changedSections.includes("website"))
                              ? "section-changed"
                              : null
                          }`}
                        >
                          <Section
                            id="section2"
                            title="Business details"
                            notWrapped
                          >
                            <BusinessDetails
                              {...{ editMode }}
                              updateListingFromEmail={updateListingFromEmail}
                              right={right}
                              changedSections={changedSections}
                              keys={differences && Object.keys(differences)}
                              listingData={listingData}
                            />
                          </Section>
                        </div>
                        <div
                          className={`${
                            right && changedSections.includes("description")
                              ? "section-changed"
                              : null
                          }`}
                        >
                          <Section
                            id="section-description"
                            title="Listing extended description"
                            notWrapped
                          >
                            <Description
                              {...{ values, editMode, setFieldValue }}
                              keys={differences && Object.keys(differences)}
                            />
                          </Section>
                        </div>
                        <div
                          className={`${
                            right && changedSections.includes("workingHours")
                              ? "section-changed"
                              : null
                          }`}
                        >
                          <Section
                            id="section3"
                            title="Hours of operation"
                            notWrapped
                          >
                            <WorkingHours
                              {...{
                                editMode,
                                weekdays,
                                preview,
                              }}
                            />
                          </Section>
                        </div>
                        <div
                          className={`${
                            right && changedSections.includes("adsRates")
                              ? "section-changed"
                              : null
                          }`}
                        >
                          <Section
                            id="section5"
                            title={"Standard Rates"}
                            notWrapped
                          >
                            <AdsRates
                              form={props}
                              adsRates={listingData?.adsRates}
                              notWrapped
                              priceId={values?.priceId}
                              {...{
                                editMode,
                              }}
                              keys={differences && Object.keys(differences)}
                            />
                          </Section>
                        </div>
                        <div
                          className={`${
                            right && changedSections.includes("services")
                              ? "section-changed"
                              : null
                          }`}
                        >
                          <Section
                            id="section4"
                            title={editMode ? "Select services" : "Services"}
                            notWrapped
                          >
                            {listingData?.categories &&
                            Object.keys(listingData?.categories).length === 0 &&
                            !editMode ? (
                              "Listing does not have any services selected, yet."
                            ) : (
                              <Services
                                isListing={true}
                                notWrapped
                                refer={this.formRef}
                                right={right}
                                changedServices={changedServices}
                                priceId={values?.priceId}
                                {...{
                                  editMode,
                                }}
                              />
                            )}
                          </Section>
                        </div>
                        {listingData && (
                          <div
                            className={`${
                              right &&
                              changedSections.includes("adsServiceAmenities")
                                ? "section-changed"
                                : null
                            }`}
                          >
                            <Section
                              id="section-service-amenities"
                              title={
                                editMode
                                  ? "Select service amenities"
                                  : "Service amenities"
                              }
                              notWrapped
                            >
                              {listingData?.serviceAmenities &&
                              Object.keys(listingData?.serviceAmenities)
                                .length === 0 &&
                              !editMode ? (
                                "Listing does not have any service amenities selected, yet."
                              ) : (
                                <Amenities
                                  areSearchable={true}
                                  areService={true}
                                  changedServiceAmenities={
                                    changedServiceAmenities
                                  }
                                  right={right}
                                  {...{
                                    editMode,
                                    selectedAmenities:
                                      listingData?.adsServiceAmenities,
                                  }}
                                />
                              )}
                            </Section>
                          </div>
                        )}
                        {listingData && (
                          <div
                            className={`${
                              right && changedSections.includes("adsAmenities")
                                ? "section-changed"
                                : null
                            }`}
                          >
                            <Section
                              id="section6"
                              title={
                                editMode ? "Select amenities" : "Amenities"
                              }
                              notWrapped
                            >
                              {Object.keys(listingData?.amenities).length ===
                                0 && !editMode ? (
                                "Listing does not have any amenities selected, yet."
                              ) : (
                                <Amenities
                                  right={right}
                                  changedAmenities={changedAmenities}
                                  {...{
                                    editMode,
                                    selectedAmenities:
                                      listingData?.adsAmenities,
                                  }}
                                />
                              )}
                            </Section>
                          </div>
                        )}
                        {!updateListingFromEmail && !preview && (
                          <>
                            {/* <Section
                              id="section-note"
                              title={editMode ? "Add note" : "Notes"}
                              notWrapped
                            >
                              <Note
                                noteRequired={false}
                                isAddNoteBtn={false}
                                name="adminNote"
                                typeName="adminNoteType"
                                noSticky
                                keys={differences && Object.keys(differences)}
                                {...{
                                  editMode,
                                  noteLabelValue,
                                  setFieldError,
                                  setFieldTouched,
                                }}
                              />
                            </Section> */}

                            {!editMode && (
                              <Section
                                id="section7"
                                title={editMode ? "Upload image" : "Image"}
                                notWrapped
                              >
                                {imageLink ? (
                                  <FileItem
                                    listFile={true}
                                    url={imageLink}
                                    isFlagged={false}
                                    dateModified={moment(
                                      listingData.updatedAt,
                                    ).format("lll")}
                                    hideRemoveBtn={true}
                                    imageSize={imageSize}
                                  />
                                ) : (
                                  <div className="no-image">
                                    No image selected
                                  </div>
                                )}
                              </Section>
                            )}
                          </>
                        )}
                        {/* //todo todo revert promotions once is ready */}
                        {/*<Section*/}
                        {/*  id="section-promotions"*/}
                        {/*  title="Promotions"*/}
                        {/*  notWrapped*/}
                        {/*>*/}
                        {/*  <Promotions {...{ callToAction }} />*/}
                        {/*</Section>*/}

                        {editMode && !updateListingFromEmail && (
                          <Row className={"mt-1"}>
                            {/* <Col> */}
                            {/* <Button
                                color="primary"
                                type="button"
                                className="account-listing-header-btn transparent"
                                onClick={() => {
                                  if (isValid) {
                                    onRemoveListing();
                                  }
                                }}
                              >
                                <Trash2 {...iconProps} />
                                <Icon name="Delete" {...iconProps} />
                              </Button> */}
                            {/*//todo revert this once is ready*/}
                            {/*<Button*/}
                            {/*  color="secondary"*/}
                            {/*  type="button"*/}
                            {/*  className="preview-listing-btn account-listing-header-btn"*/}
                            {/*  onClick={onPreviewListing}*/}
                            {/*>*/}
                            {/*  <Icon name="Eye" {...iconProps} />*/}
                            {/*  <span className="ml-1 preview-listing-btn-label">*/}
                            {/*    Preview Listing*/}
                            {/*  </span>*/}
                            {/*</Button>*/}
                            {/* </Col> */}
                            <Col className="d-flex justify-content-end">
                              {!updateListingSidebar ? (
                                <Breakpoint md up>
                                  <Button
                                    color="tertiary"
                                    type="button"
                                    className="preview-listing-btn account-listing-header-btn mr-1"
                                    onClick={() => {
                                      toggleEditConfirmationModal();
                                    }}
                                  >
                                    <span className="preview-listing-btn-label">
                                      Cancel
                                    </span>
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    className="account-listing-header-btn acc-list-primary-btn"
                                  >
                                    Save changes
                                  </Button>
                                </Breakpoint>
                              ) : (
                                <>
                                  <Button
                                    color="secondary"
                                    type="button"
                                    className="preview-listing-btn account-listing-header-btn mr-1"
                                    onClick={() => {
                                      handleSidebar(false);
                                    }}
                                  >
                                    <span className="preview-listing-btn-label">
                                      Cancel
                                    </span>
                                  </Button>
                                  {this.props.reviewListingData ? (
                                    <Button
                                      color="primary"
                                      type="button"
                                      className="account-listing-header-btn acc-list-primary-btn"
                                      onClick={() => {
                                        let newUpdateListingTypePayload = {
                                          ...this.props
                                            .updateListingTypePayload,
                                        };
                                        newUpdateListingTypePayload.newListingData =
                                          values;

                                        this.props.setUpdateListingTypePayload(
                                          newUpdateListingTypePayload,
                                        );
                                        // setTimeout(() => {
                                        this.props.callToAction(
                                          "preview_listing_details",
                                          false,
                                          {
                                            values,
                                          },
                                        );
                                        // }, 1000);
                                        // this.props.handleSidebar(false);
                                      }}
                                    >
                                      Next Step: Preview Listing
                                    </Button>
                                  ) : (
                                    <Button
                                      color="primary"
                                      type="submit"
                                      className="account-listing-header-btn acc-list-primary-btn"
                                    >
                                      Update listing
                                    </Button>
                                  )}
                                </>
                              )}
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                  <ListingImagePreview
                    handleContactFormModal={this.handleContactFormModal}
                    editMode={editMode}
                    {...{ ...listingData }}
                  />
                </>
              );
            }}
          </Formik>
        )}

        {editMode && (
          <Breakpoint sm down>
            <div className="listing-actions-mobile">
              <Button
                color="secondary"
                type="button"
                className="preview-listing-btn account-listing-header-btn mr-1 w-100"
                onClick={() => {
                  toggleEditConfirmationModal();
                }}
              >
                <span className="preview-listing-btn-label">Cancel</span>
              </Button>
              <Button
                color="primary"
                type="submit"
                className="account-listing-header-btn acc-list-primary-btn w-100"
              >
                Save changes
              </Button>
            </div>
          </Breakpoint>
        )}
        {this.state.contactFormModalIsOpen && (
          <FtsContactFormModal
            subject={"Update listing image"}
            isOpen={true}
            closeModal={this.handleContactFormModal}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.authState.user,
    adminPermissions: state.auth.login.permissions,
    updateListingTypePayload: state.listingCounts.updateListingTypePayload,
  };
};

export default connect(mapStateToProps, {
  setUpdateListingTypePayload,
})(ListingDetails);

const ListingImagePreview = (props) => {
  const { editMode, priceId, image, name, handleContactFormModal } = props;
  const imageSize = LISTING_IMAGE_SIZES[priceId];
  if (!editMode || !imageSize) return null;

  return (
    <Card className="create-listing-wrapper data-list-fields p-2">
      <Section id="section7" title={"Listing image"} notWrapped>
        <div className="d-flex">
          {image ? (
            <img
              width={imageSize.width}
              height={imageSize.height}
              // style={{ marginRight: "32px" }}
              src={formatImageLink(image)}
              alt={name}
            />
          ) : (
            <TemporaryImagePlacement name={name} priceId={priceId} />
          )}
          <div className="ml-2">
            <p className="mb-1 text-bold-600">
              <span className="mr-1">
                <Icons name={"BenefitsImage"} size={36} />
              </span>
              Your current listing image
            </p>
            <p className="mb-1">
              To update your listing image please contact us.
            </p>
            <Button
              onClick={handleContactFormModal}
              color="primary"
              outline
              type="submit"
              className="rounded"
            >
              Contact Us
            </Button>
          </div>
        </div>
      </Section>
    </Card>
  );
};
const TemporaryImagePlacement = (props) => {
  const { name, priceId } = props;

  const generateListingWrapperClass = () => {
    return getListingTitle({ priceId }).split(" ").join("-").toLowerCase();
  };
  return (
    <div
      className={`temporary-image-wrapper temporary-image-wrapper-${generateListingWrapperClass()}`}
    >
      <div
        className={`temporary-image-wrapper-title temporary-image-wrapper-${generateListingWrapperClass()}-title`}
      >
        {name}
      </div>
    </div>
  );
};
// let isEqual = (left, right) => {
//   let newLeft = left;
//   let newRight = "images" + right.split("images").pop();
//
//   return newLeft === newRight;
// };
