import React, { useCallback, useEffect, useState } from "react";

import { history } from "../history";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../redux/reducers/v2/auth";
import { Route } from "react-router-dom";
import { extractParameter } from "../utility/param-extract";
import { USER_TYPE } from "../constants";
import { HeaderWrapper } from "../components/fts-components-v2/HeaderWrapper";
import { getActiveAndFreeListingsCountRequest } from "../redux/actions/v2/vendorAdvertiseListings";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { setInitialListingData } from "../redux/actions/v2/listing";
import { registerPaypalPaymentRequest } from "../redux/actions/v2/payments";
import logger from "../utility/logger";

const GetListedRouter = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector(selectUserData);
  const selectedListingPriceId = extractParameter("selectedListingPriceId");
  const dispatch = useDispatch();

  const onSuccess = (hasActiveOrFreeListings) => {
    logger.info("hasActiveOrFreeListings: ", hasActiveOrFreeListings);
    if (
      hasActiveOrFreeListings &&
      window.location.pathname.includes(
        "/dashboard/create-listing/business-details",
      )
    ) {
      if (parseInt(selectedListingPriceId) === 5)
        history.push(`/dashboard/create-listing/free-listing-exist`);
    }
    setIsLoading(false);
  };

  const handleOnSuccess = useCallback(() => {
    const convertedToVendor = extractParameter("convertedToVendor");

    if (convertedToVendor) {
      const searchParams = new URLSearchParams();
      searchParams.set("messageComponent", "paymentSuccessfull");

      history.push({
        pathname: "/dashboard/complete-account-info",
        search: `?${searchParams.toString()}`,
      });
    } else {
      history.push("/dashboard?openModal=paymentSuccessfulModal");
    }
    dispatch(setInitialListingData());
  }, [history]);

  useEffect(() => {
    const paymentId = extractParameter("paymentId");
    const status = extractParameter("status");
    const hash = extractParameter("hash");

    if (status === "success") {
      dispatch(
        registerPaypalPaymentRequest({
          data: { paymentId, hash },
          onSuccess: handleOnSuccess,
        }),
      );
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (!userData) {
      if (selectedListingPriceId) {
        return history.push(
          `/dashboard/get-listed/account?selectedListingPriceId=${selectedListingPriceId}`,
        );
      }
      setIsLoading(false);
      return history.push(`/dashboard/get-listed/account`);
    }

    if (userData) {
      if (
        userData.userType !== USER_TYPE.SERVICE_VENDOR &&
        window.location.pathname !== "/dashboard/get-listed/wrong-account"
      ) {
        setIsLoading(false);
        return history.push(
          `/dashboard/get-listed/wrong-account?selectedListingPriceId=${selectedListingPriceId}`,
        );
      }

      if (
        userData.userType === USER_TYPE.SERVICE_VENDOR &&
        window.location.pathname === "/dashboard/get-listed/wrong-account"
      ) {
        setIsLoading(false);
        return history.push("/dashboard/vendor-advertise-listings");
      }

      dispatch(getActiveAndFreeListingsCountRequest({ onSuccess }));
    }
  }, [userData, window.location.pathname]);

  if (isLoading || !userData)
    return (
      <FtsContainer>
        <FtsWrapper height={"100vh"}>
          <FullPageLoader />
        </FtsWrapper>
      </FtsContainer>
    );

  return (
    <>
      <HeaderWrapper />
      <Route {...props} />
    </>
  );
};

export default GetListedRouter;
