import FTSAxios from "../../../axios/fts.instance";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import { serialize, generateFilters } from "../../../utility/serialize";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";
import { GET_ACCOUNT_COUNTS } from "../../reducers/accounts";
import collectEssentialData from "../../../axios/collect-data";
import { toast } from "react-toastify";
import { history } from "../../../history";
import { extractParameter } from "../../../utility/param-extract";
import { setUserData } from "../v2/auth";
import logger from "../../../utility/logger";
// import FTSCookies, { PURCHASE_LISTING_DATA } from "../../../cookies";

export const searchVendorAccounts = ({
  page,
  resultsPerPage,
  sort,
  searchString,
  fields,
  ...rest
}) => {
  return () => {
    const queryParams = {
      searchString: "",
      page: page || 1,
      pageSize: resultsPerPage,
    };

    const newFilters = {
      ...rest,
      companyName: encodeURIComponent(rest.companyName || ""),
      adminIsMerchant: rest.adminIsMerchant ? 1 : "",
    };

    const { multi_match, filter } = generateFilters(newFilters, {
      term: {
        userTypeNamed: "vendor",
      },
    });

    let newSearchString = searchString && searchString.trim();

    if (newSearchString) {
      const slugRegex = new RegExp("^[0-9]*$");
      if (slugRegex.test(newSearchString))
        newSearchString = "#" + newSearchString;

      multi_match.push({
        query_string: {
          query:
            "(" +
            rfc3986EncodeURIComponent(newSearchString)
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND ") +
            ") OR (" +
            newSearchString.replace(/ /g, " AND ") +
            ")",
          // rfc3986EncodeURIComponent(searchString || "")
          // .replace(/%40/g, "@")
          // .replace(/\+/g, " AND "),
          fields,
          type: "phrase_prefix",
        },
      });
    }

    return FTSAxios.post("admin/users/search", {
      ...queryParams,
      multi_match,
      filter,
      sort,
    });
  };
};

export const searchVendorAccountsUser = ({
  page,
  resultsPerPage,
  sort,
  searchString,
  fields,
  ...rest
}) => {
  return () => {
    const queryParams = {
      searchString: "",
      page: page || 1,
      pageSize: resultsPerPage,
    };

    let newFilters = {
      ...rest,
      companyName: encodeURIComponent(rest.companyName),
      adminIsMerchant: rest.adminIsMerchant ? 1 : "",
    };
    // delete newFilters.companyName;

    const { multi_match, filter } = generateFilters(newFilters, {
      match_phrase_prefix: {
        userTypeNamed: "user",
      },
    });
    let newSearchString = searchString && searchString.trim();

    if (newSearchString) {
      multi_match.push({
        query_string: {
          query:
            "(" +
            rfc3986EncodeURIComponent(newSearchString.trim())
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND ") +
            ") OR (" +
            newSearchString.replace(/ /g, " AND ") +
            ")",
          // rfc3986EncodeURIComponent(searchString)
          // .replace(/%40/g, "@")
          // .replace(/\+/g, " AND "),
          fields,
          type: "phrase_prefix",
        },
      });
    }

    return FTSAxios.post("admin/users/search", {
      ...queryParams,
      multi_match,
      filter,
      sort,
    });
  };
};

export const getUsersData = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    const stringifiedParams = serialize(queryParams);

    return FTSAxios.get(`admin/users/all?${stringifiedParams}`);
  };
};
export const createAccountAltContact = (data) => {
  return () => {
    return FTSAxios.post("profile/contact/create", data);
  };
};

export const updateAccountAltContact = (data, contactId) => {
  return () => {
    return FTSAxios.put(`profile/contact/${contactId}`, data);
  };
};

export const removeAccountInfoAltContact = (contactId) => {
  return () => {
    return FTSAxios.delete(`profile/contact/${contactId}`);
  };
};

export const getUserPhoneCalls = (userId) => {
  return () => {
    return FTSAxios.get(`admin/user-calls/${userId}`);
  };
};

export const filterUserTypes = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_USER_TYPES_DATA", value });
};

export const getUserTypesData = () => {
  return (dispatch) => {
    return FTSAxios.get("admin/lists/user-types").then((response) => {
      dispatch({
        type: "GET_USER_TYPES_DATA",
        data: response.data,
        totalPages: 1,
      });
    });
  };
};

export const setUsersListings = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: "SET_USERS_LISTINGS",
      payload,
    });
  };
};

export const updateUserTypeData = (payload) => {
  return () => {
    return FTSAxios.put(`admin/lists/user-type/${payload.id}`, payload);
  };
};

export const createUserTypeData = (payload) => {
  return () => {
    return FTSAxios.post("admin/lists/user-type/create", payload);
  };
};

export const deleteUserTypeData = (payload) => {
  return () => {
    return FTSAxios.delete(`admin/lists/user-type/${payload.id}`);
  };
};

export const getUserData = (userId) => {
  return () => {
    return FTSAxios.get(`admin/users/${userId}`);
  };
};

export const getAccountCounts = (userId) => {
  return (dispatch) => {
    return FTSAxios.get(`admin/users/${userId}`).then((response) => {
      dispatch({
        type: GET_ACCOUNT_COUNTS,
        data: response.data,
      });
    });
  };
};

export const postUserData = (payload) => {
  return () => {
    return FTSAxios.post("admin/users", payload);
  };
};

export const getUserTypes = () => {
  return () => {
    return FTSAxios.get("admin/lists/user-types");
  };
};

export const getAccountAltContacts = (accountId) => {
  return () => {
    return FTSAxios.get(`profile/contact/get/user/0/${accountId}`);
  };
};

export const getUserListings = (userId, params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    const stringifiedParams = serialize(queryParams);
    return FTSAxios.get(`admin/users/listings/${userId}?${stringifiedParams}`);
  };
};
export const getAccountSubUser = ({ resultsPerPage, page = 1 }) => {
  return () => {
    return FTSAxios.get("profile/sub-accounts", {
      params: { resultsPerPage, page },
    });
  };
};

export const updateUserData = (data) => {
  return () => {
    return FTSAxios.put(`admin/users/${data.id}`, data);
  };
};

export const createUserData = (data) => {
  return () => {
    return FTSAxios.post("admin/user/create", data);
  };
};

export const filterData = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_USERS_DATA", value });
};

export const updateUserFilter = (data) => {
  return (dispatch) => dispatch({ type: "UPDATE_USER_FILTER", data });
};

export const clearUserFilters = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_USER_FILTERS" });
    return Promise.resolve();
  };
};

export const reassignUser = (data) => {
  return () => {
    return FTSAxios.put(`admin/users/reassign/${data.userId}`, data);
  };
};

export const reassignTo = (data) => {
  return () => {
    return FTSAxios.post("admin/users/bulk/assign", data);
  };
};

export const getActiveLeads = (params) => {
  return () => {
    return FTSAxios.get("admin/sales/active", { params });
  };
};

export const deleteAccount = (userId) => {
  return () => {
    return FTSAxios.delete(`admin/users/${userId}`);
  };
};

export const editVendor = (payload) => {
  return () => {
    return FTSAxios.put("profile-update", payload);
  };
};

export const editVendorSub = (payload) => {
  return () => {
    return FTSAxios.put("profile/update-sub", payload);
  };
};

export const getUserDetails = (onSuccess) => {
  return (dispatch) => {
    return FTSAxiosV2.get("/users/me")
      .then(async (res) => {
        let loggedInUser = res.data.data;
        if (typeof onSuccess === "function") {
          onSuccess(loggedInUser);
        }
        dispatch(setUserData({ data: loggedInUser }));
        dispatch({
          type: "UPDATE_USER_INFO",
          payload: { loggedInUser, loggedInWith: "jwt" },
        });
      })
      .catch((error) => logger.error(error));
  };
};

export const getVendorDataWithEssentialLists = async (
  dispatch,
  loggedInWith,
) => {
  FTSAxiosV2.get("/users/me")
    .then(async (res) => {
      const loggedInUser = res.data.data;

      dispatch({
        type: "UPDATE_USER_INFO",
        payload: { loggedInUser, loggedInWith: loggedInWith || "jwt" },
      });
      await collectEssentialData(dispatch);

      const redirect = extractParameter("redirect");
      const openModal = extractParameter("openModal");
      const listingId = extractParameter("listingId");

      if (redirect) {
        if (loggedInUser?.onboardingFinished) {
          if (redirect.startsWith("/search")) {
            history.push({
              pathname: `${redirect}&openModal=${openModal}&listingId=${listingId}`,
            });
          } else {
            history.push({
              pathname: redirect,
              search: `?openModal=${openModal}`,
            });
          }
        } else {
          history.push({
            pathname: "/dashboard/on-boarding",
            search: `?redirect=${redirect}&openModal=${openModal}&listingId=${listingId}`,
          });
        }
      } else {
        history.push("/dashboard");
      }
    })
    .catch((err) => {
      logger.error(err);
      toast.error("Something went wrong. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
};

export const getUserListingsMarkers = ({ searchString }) => {
  return () => {
    return FTSAxios.post("/user/listing-markers", { searchString });
  };
};
export const editSubUser = (payload) => {
  return () => {
    return FTSAxios.put(`subuser-update/${payload.id}`, payload);
  };
};

export const setDefaultPayment = (id) => {
  return () => {
    return FTSAxios.put(`/payment-method/${id}`);
  };
};

export const deletePayment = (id) => {
  return () => {
    return FTSAxios.delete(`/payment-method/${id}`);
  };
};

export const deleteSubUser = ({ id }) => {
  return () => {
    return FTSAxios.delete(`/profile/sub-account/${id}`);
  };
};

export const createPayment = (data) => {
  return () => {
    return FTSAxios.post("/payment-method", data);
  };
};

export const setRecurringPayment = (data) => {
  return () => {
    return FTSAxios.put("/payment/update/recurring", data);
  };
};

export const getSubUsers = () => {
  return () => {
    return FTSAxios.get("/user/sub-users");
  };
};

export const updateUserInfo = (payload) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_USER_INFO", payload });
  };
};

export const deactivateAccount = () => {
  return () => {
    return FTSAxios.get("user/deactivate");
  };
};

export const reassignListing = (data) => {
  return () => {
    return FTSAxios.post("purchase-listing/reassign", data);
  };
};

export const reassignListingToTemporaryAccount = (data) => {
  return () => {
    return FTSAxios.post(
      "purchase-listing/reassign-to-temporary-account",
      data,
    );
  };
};

export const getCorporateDetails = () => {
  return () => {
    return FTSAxios.get("corporate-details");
  };
};

export const getUserByHash = ({ hash }) => {
  return () =>
    FTSAxiosV2.get("/users/get-by-hash", {
      headers: { "temporary-token": hash },
    });
};

export const updateUserEmailSubscription = (payload) => {
  return () => {
    return FTSAxios.put("profile/update-email-subscription", payload);
  };
};

export const sendBulkUploadEmail = () => {
  return () => {
    return FTSAxios.get("profile/send-bulk-upload-notification");
  };
};
