import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../history";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { DraftListingExistComponent } from "../../../components/fts-components-v2/DraftListingExist/DraftListingExist";
import { selectDraftListingData } from "../../../redux/reducers/v2/listing";
import { getDraftListingRequest } from "../../../redux/actions/v2/listing";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";

const DraftListingExist = () => {
  const [isLoading, setIsLoading] = useState(false);
  const draftData = useSelector(selectDraftListingData);
  const dispatch = useDispatch();

  const onSuccess = useCallback(
    (data) => {
      if (!data)
        return history.push("/dashboard/create-listing/business-details");
      setIsLoading(false);
    },
    [setIsLoading],
  );
  useEffect(() => {
    setIsLoading(true);
    dispatch(getDraftListingRequest({ onSuccess }));
  }, []);

  const onClick = useCallback(() => {
    if (draftData.draftStep === 3)
      return history.push(`/dashboard/payment-overview/${draftData.id}`);
    history.push(`/dashboard/edit-listing/business-details/${draftData.id}`);
  }, [draftData]);

  if (isLoading) return <FullPageLoader />;

  return (
    <FtsWrapper>
      <DraftListingExistComponent onClick={onClick} />
    </FtsWrapper>
  );
};

export default DraftListingExist;
