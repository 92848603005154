/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useRef, useState } from "react";
import ListingDetails from "../pages/listing-settings/ListingDetails";
import { weekdays } from "../pages/listing-settings/data";
import formatHour from "../../utility/formatHour";
import logo from "../../assets/img/logo/fts-new-logo.svg";
import FTSAxios from "../../axios/fts.instance";
import {
  hoursListFrom,
  hoursListTo,
} from "../../components/fts/formik/hour-input/options";
import {
  formatFromUnix,
  formatRawDate,
  rawToUnix,
} from "../../configs/dateConfig";
import {
  extractFormikAmenitiesData,
  extractFormikCategoriesData,
  formatFormikAmenitiesData,
  formatFormikCategoriesData,
} from "../../utility/extract";
import "../../assets/scss/components/fts/header.scss";
import "../../assets/scss/pages/update-listing.scss";

import collectEssentialData from "../../axios/collect-data";
import { connect, useDispatch } from "react-redux";
import FTSCookies, { removeAllCookies, TEMPORARY_TOKEN } from "../../cookies";
import { Button, Card, CardBody, Col } from "reactstrap";
import moment from "moment";
import { NAVIGATION } from "../../constants";
import { formatAdsRates } from "../../utility/formatAdsRates";
import { generateAdsRatesKey } from "../../utility/serialize";
import queryString from "query-string";
import { history } from "../../history";
import { toast } from "react-toastify";
import { rfc3986DecodeURIComponentString } from "../../utility/encode-rfc";
import { formatWorkingHoursTo } from "../../utility/formatWorkingHours";
import { HeaderWrapper } from "../../components/fts-components-v2/HeaderWrapper";
import logger from "../../utility/logger";

const UpdateListing = (props) => {
  let [inProgress, setInProgress] = useState(true);
  let [listingData, setListingData] = useState(null);
  let [error, setError] = useState("");
  let [success, setSuccess] = useState(false);
  let formRef = useRef(null);
  let submitBtnRef = useRef(null);
  let dispatch = useDispatch();

  useEffect(() => {
    setInProgress(true);

    (async () => {
      try {
        await removeAllCookies();

        let { token } = props.match.params;
        const queryParams = queryString.parse(props.location.search);

        await FTSCookies.set(TEMPORARY_TOKEN, token, { path: "/" });

        const [{ data }] = await Promise.all([
          FTSAxios.get("admin/updates/get-listing-info", {
            params: queryParams,
          }),
          collectEssentialData(dispatch),
        ]);

        let newData = formatData(data);
        setListingData(newData);
      } catch (e) {
        logger.error(e.response);
        setError("This listing has already been updated!");
      } finally {
        setInProgress(false);
      }
    })();
  }, [dispatch, props.match.params, props.location.search]);

  const onFormSubmission = async (values) => {
    setInProgress(true);
    const { userCategories: categories, userSubCategories: subCategories } =
      extractFormikCategoriesData(values.categories);
    const hoursOfOperation = {
      alwaysOpen: values.workingHours.open24hrs ? 1 : 0,
      specialHours: {
        towingRecovery: !!values.workingHours.towingRecoveryOpen,
        mobileRoad: !!values.workingHours.mobileRoadServiceOpen,
      },
      weekDays: weekdays.map(({ day }) => ({
        day,
        // "08:00 PM",
        from:
          typeof values.workingHours[`${day}From`] === "object"
            ? values.workingHours[`${day}From`]?.value ?? ""
            : values.workingHours[`${day}From`],
        // "08:00 PM",
        to: formatWorkingHoursTo(values, day),
      })),
    };

    if (values.renewalDate) {
      if (typeof values.renewalDate === "object") {
        values.renewalTimestamp = rawToUnix(values.renewalDate[0].toString());
        values.renewalLaterDate = rawToUnix(values.renewalDate[0].toString());
      }
    }
    values.adsRates = formatAdsRates(values, props.rates);

    const payload = {
      ...values,
      updatedAt: moment().format("lll"),
      alwaysOpen: values.workingHours.open24hrs ? 1 : 0,
      ...values.workingHours,
      adsCategories: [...categories],
      adsSubCategories: [...subCategories],
      ...values.addressInfo,
      milemarker: values.mileMarker,
      latitude: values.geoLocation.lat,
      longitude: values.geoLocation.lng,
      ...extractFormikAmenitiesData(values),
      priceId:
        typeof values.priceId === "object"
          ? values.priceId.value
          : values.priceId,
      state:
        typeof values.addressInfo.state === "object"
          ? values.addressInfo.state.value
          : values.addressInfo.state,
      adminStatus:
        typeof values?.adminStatus === "object"
          ? values?.adminStatus?.value
          : values?.adminStatus,
      status:
        typeof values.status === "object" ? values.status.value : values.status,
      hoursOfOperation,
      paymentStatus: values.isItPaidFor ? "paid" : "not_paid",
    };

    const queryParams = queryString.parse(props.location.search);
    try {
      if (queryParams.listingId) {
        delete payload.adType;
        await FTSAxios.put(`/vendor-listing/${queryParams.listingId}`, payload);
        let { token } = props.match.params;

        toast.success("Listing updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return history.push(
          `/dashboard/report/${token}?listingId=${queryParams.listingId}`,
        );
      } else {
        await FTSAxios.post("admin/request-update", {
          adId: payload.id,
          requestedUpdate: payload,
        });
      }
      setInProgress(false);
      setSuccess(true);
      await removeAllCookies();
    } catch (e) {
      logger.error(e);
      setInProgress(false);
    }
    // /listing/:uid
  };

  if (error) {
    return (
      <>
        <HeaderWrapper />
        <div className={"container mt-5"}>
          <Col xs={12} md={{ size: 10, offset: 1 }}>
            <Card>
              <CardBody className={"p-2"}>
                <h2 className="text-center">{error}</h2>
              </CardBody>
            </Card>
          </Col>
        </div>
      </>
    );
  }
  if (success) {
    return (
      <>
        <HeaderWrapper />
        <div className={"container mt-5"}>
          <Col xs={12} md={{ size: 10, offset: 1 }}>
            <Card>
              <CardBody className={"p-2"}>
                <h2 className="text-center">
                  Thank you for updating your listing!
                </h2>
                <p className={"text-center"}>
                  Your changes to the listing information will be sent to the
                  admins on review. Only when they approve it, the listing will
                  be up to date with the new information.
                </p>
              </CardBody>
            </Card>
          </Col>
        </div>
      </>
    );
  }

  return (
    <div className="fts-update-listing">
      <HeaderWrapper />
      <div className={"container"}>
        <div className={"col-10 offset-1"}>
          <h2 className={"mb-5"}>Update Listing information</h2>
          <ListingDetails
            onFormSubmission={onFormSubmission}
            updateListingFromEmail={true}
            inProgress={inProgress}
            editMode={true}
            formikRef={(e) => (formRef.current = e)}
            listingData={listingData || {}}
            submitBtnRef={(e) => {
              submitBtnRef.current = e;
            }}
            mapPinSet={true}
          />
        </div>
      </div>
      <Footer
        formRef={formRef}
        submitBtnRef={submitBtnRef}
        onFormSubmission={onFormSubmission}
      />
    </div>
  );
};

const Footer = ({ formRef }) => {
  return (
    <div
      style={{
        position: "sticky",
        bottom: 0,
        backgroundColor: "rgba(255,255,255,0.8)",
        padding: "1rem",
        zIndex: 1,
      }}
      className="fts-update-listing-footer"
    >
      <Col
        xs={{ size: 10, offset: 1 }}
        className="d-flex justify-content-end p-0"
      >
        <Button
          onClick={() => {
            formRef.current.submitForm();
          }}
          style={{ width: 200 }}
          color="primary"
          type="submit"
          className="account-listing-header-btn acc-list-primary-btn rounded "
        >
          Submit
        </Button>
      </Col>
    </div>
  );
};
export const Header = ({ success, showNavigation = true }) => {
  return (
    <>
      <div className="fts-vendor-header">
        <img src={logo} alt={"logo"} className={"ml-3"} />
        {showNavigation && (
          <>
            <div>
              <ul className="fts-vendor-header-navigation">
                {NAVIGATION.map((item) => (
                  <li
                    className="last:mr-0 text-white font-futura-light"
                    key={item.title}
                  >
                    <a href={item.path}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mr-3 fts-vendor-header-auth">
              <a className="mr-2" href="/">
                Login
              </a>

              <a className="register-button" href="/">
                Get Listed
              </a>
            </div>
          </>
        )}
      </div>
      {!success && (
        <div
          style={{
            backgroundColor: "#FAC02A",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 0px",
          }}
          className={"mb-5 fts-header-update-listing-message"}
        >
          <p className={"mb-0"}>
            Your changes to the listing information will be sent to the admins
            on review. Only when they approve it, the listing will be up to date
            with the new information.
          </p>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    rates: state.essentialLists.rates,
  };
};
export default connect(mapStateToProps)(UpdateListing);

const formatData = (data) => {
  const workingHours = {};

  weekdays.forEach(({ day }) => {
    if (!data?.workingHours || data.alwaysOpen) return;

    let [from, to] = data?.workingHours
      ? data?.workingHours[`${day.toLowerCase()}`].split("-")
      : ["", ""];
    from = formatHour(from?.trim() || "");
    to = formatHour(to?.trim() || "");

    // if day is empty string
    if (data?.workingHours[`${day.toLowerCase()}`] === "") {
      // set from & to to null
      from = null;
      to = null;
    }
    workingHours[`${day}From`] = !!from
      ? hoursListFrom.find(({ value }) => value === from) ?? ""
      : null;
    workingHours[`${day}To`] = !!to
      ? hoursListTo.find(({ value }) => value === to) ?? ""
      : null;
  });

  if (data.adsRates.length) {
    data.adsRates.map((e) => {
      const key = generateAdsRatesKey(e.rate.name, e.rate.id);

      data[key] = e.value;
      return e;
    });
  }
  // this.setState({ imageLink: data.image });
  // this.setState({ initialImageLink: data.image });
  return {
    ...data,
    name: rfc3986DecodeURIComponentString(data.name),
    isItPaidFor: data.paymentStatus === "paid" ? true : false,
    createdAt: formatRawDate(data.createdAt),
    renewalDate: formatFromUnix(data.renewalTimestamp),
    updatedAt: formatRawDate(data.updatedAt),
    addressInfo: {
      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zip,
    },
    mileMarker: data.milemarker,
    geoLocation: { lat: data.latitude, lng: data.longitude },
    description: rfc3986DecodeURIComponentString(data.description),
    includeInAuthorizedPromotions: !!data.includeInAuthorizedPromotions,
    excludeRenewalEmails: !!data.excludeRenewalEmails,
    excludeDowngrades: !!data.excludeDowngrades,
    workingHours: {
      ...workingHours,
      open24hrs: !!data.alwaysOpen,
      mobileRoadServiceOpen: data.mobileRoadServiceOpen,
      towingRecoveryOpen: data.towingRecoveryOpen,
      specialHours: data.specialHours,
    },
    categories: {
      ...formatFormikCategoriesData({
        categories: data.adsCategories,
        subCategories: data.adsSubCategories,
      }),
    },
    ...formatFormikAmenitiesData(data.adsAmenities),
    ...formatFormikAmenitiesData(data.adsServiceAmenities, true),
  };
};
