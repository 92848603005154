import FTSAxios from "../../../axios/fts.instance";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";
import { generateFilters, serialize } from "../../../utility/serialize";

export const searchListings = ({
  page,
  resultsPerPage,
  searchString,
  sort,
  range,
  ...rest
}) => {
  return () => {
    let adsServiceAmenitiesStrings = rest.adsServiceAmenitiesStrings;
    let adsSubCategoriesStrings = rest.adsSubCategoriesStrings;
    if (rest.serviceAmenities && rest.serviceAmenities.length) {
      adsServiceAmenitiesStrings = rest.serviceAmenities.map((e) => e.name);
    }
    if (rest.subCategory) {
      adsSubCategoriesStrings = rest.subCategory;
    }
    const newFilters = {
      ...rest,
      adsServiceAmenitiesStrings,
      adsSubCategoriesStrings,
      name: rfc3986EncodeURIComponent(rest.name),
      includeInAuthorizedPromotions: rest.includeInAuthorizedPromotions
        ? 1
        : "",
      includeInFeaturedVendors: rest.includeInFeaturedVendors ? 1 : "",
    };

    let { multi_match, filter } = generateFilters(newFilters);

    filter = filter.filter((e) => !!e);
    if (range) filter.push({ range });
    let newSearchString = searchString && searchString.trim();

    if (newSearchString) {
      const slugRegex = new RegExp("^[0-9]*$");
      if (slugRegex.test(newSearchString))
        newSearchString = "#" + newSearchString;

      multi_match.push({
        query_string: {
          query:
            "(" +
            rfc3986EncodeURIComponent(newSearchString.trim())
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND ") +
            ") OR (" +
            newSearchString.replace(/ /g, " AND ") +
            ")",
          fields: [
            "statusString",
            "idString",
            "userIdString",
            "paymentStatus",
            "updatedAtString",
            "renewalTimestampString",
            "email",
            "name",
            "address",
            "city",
            "state",
            "phoneNumber",
            "phoneNumberSecondary",
            "afterHours",
          ],

          type: "phrase_prefix",
        },
      });
    }

    return FTSAxios.post("profile/listings/search", {
      page: page || 1,
      pageSize: resultsPerPage,
      multi_match,
      filter,
      sort,
    });
  };
};

export const getListingsData = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    const stringifiedParams = serialize(queryParams);

    return FTSAxios.get(`admin/listings/all?${stringifiedParams}`);
  };
};

export const getListingData = (listingId) => {
  return () => {
    return FTSAxios.get(`listing-details/${listingId}`);
  };
};

export const getListingCounts = (listingId) => {
  return (dispatch) => {
    return FTSAxios.get(`admin/listing/${listingId}`).then((response) => {
      dispatch({
        type: "GET_LISTING_COUNTS",
        data: response.data,
      });
    });
  };
};

export const updateListingData = (listingId, payload) => {
  return () => {
    return FTSAxios.put(`vendor-listing/${listingId}`, payload);
  };
};

export const updateMultipleListings = (data) => {
  return () => {
    return FTSAxios.put(`admin/multiple/listings`, data);
  };
};

export const createListing = (payload) => {
  return () => {
    return FTSAxios.post("listing/create", payload);
  };
};
export const createListingAsset = (payload) => {
  return () => {
    return FTSAxios.post("listing/asset/create", payload);
  };
};
export const deleteListingAsset = (assetId) => {
  return () => {
    return FTSAxios.delete(`listing/asset/${assetId}`);
  };
};

export const duplicateListing = (data) => {
  return () => {
    return FTSAxios.post("admin/listing/bulk-create", data);
  };
};

export const removeListing = (listingId) => {
  return () => {
    return FTSAxios.delete(`admin/listing/${listingId}`);
  };
};

export const updateListingType = (payload) => {
  return () => {
    return FTSAxios.put(`admin/listings/update-listing-type`, payload);
  };
};

export const updateListingStatus = ({ id, status }) => {
  return () => {
    return FTSAxios.put(`listing/status/${id}`, { status });
  };
};

export const getAccountListings = ({
  page,
  resultsPerPage,
  priceId,
  status,
  searchString,
  sort = [],
}) => {
  return () => {
    return FTSAxios.get(`profile/listings`, {
      params: {
        page,
        resultsPerPage,
        priceId,
        status,
        searchString,
        sort: sort.length ? sort[0] : [],
      },
    });
  };
};

export const getUserListingsCount = ({ status, searchString }) => {
  return () => {
    return FTSAxios.post(`profile/get-listings-counters`, {
      status,
      searchString,
    });
  };
};

export const updateListing = (listingId, payload) => {
  return () => {
    return FTSAxios.put(`vendor-listing/${listingId}`, payload);
  };
};

export const getAccountProofs = ({
  page,
  pageSize,
  priceId,
  status,
  searchString,
  sort,
}) => {
  return () => {
    return FTSAxios.get(`profile/proofs`, {
      params: {
        page,
        pageSize,
        priceId,
        status,
        searchString,
        sort,
      },
    });
  };
};

export const getUserPendingProofsCount = ({ searchString, status }) => {
  return () => {
    return FTSAxios.post(`profile/get-proofs-counters`, {
      searchString,
      status,
    });
  };
};

export const getSubUser = () => {
  return () => {
    return FTSAxios.get("profile/sub-user");
  };
};

export const getListingInvoice = ({ adId }) => {
  return () => {
    return FTSAxios.get(`purchase-listing-invoice/${adId}`);
  };
};

export const getDraftStepCounters = () => {
  return () => FTSAxios.get(`/listing/draft/counter`);
};

export const getListingForAnalytic = ({ adId }) => {
  return () => FTSAxios.get(`/listing/analytic-data/${adId}`);
};

export const getListingCurrentHours = ({
  city,
  state,
  latitude,
  longitude,
}) => {
  return () =>
    FTSAxios.get("/get-listing-current-hours", {
      params: { city, state, latitude, longitude },
    });
};

export const createPrivateLocation = (payload) => {
  return () => {
    return FTSAxios.post("listing/create/private", { listingData: payload });
  };
};
