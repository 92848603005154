import { createSelector } from "reselect";

export const CREATE_LISTING_SET_USER_VALUES = "CREATE_LISTING/SET_USER_VALUES";
export const CREATE_LISTING_REGISTER_REQUEST =
  "CREATE_LISTING/REGISTER_REQUEST";
export const CREATE_LISTING_REGISTER_SUCCESS =
  "CREATE_LISTING/REGISTER_SUCCESS";
export const CREATE_LISTING_REGISTER_FAILED = "CREATE_LISTING/REGISTER_FAILED";
export const CREATE_LISTING_SET_LISTING_DATA_INVALID_FORM_FIELDS =
  "CREATE_LISTING/CREATE_LISTING_SET_LISTING_DATA_INVALID_FORM_FIELDS";
export const CREATE_LISTING_SET_LISTING_DATA_EMAIL_ALREADY_USED =
  "CREATE_LISTING/CREATE_LISTING_SET_LISTING_DATA_EMAIL_ALREADY_USED";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  invalidFormFields: null,
  emailAlreadyUsed: false,
  inlineMessage: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_LISTING_SET_USER_VALUES:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    case CREATE_LISTING_REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        invalidFormFields: null,
      };

    case CREATE_LISTING_REGISTER_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        invalidFormFields: null,
      };
    case CREATE_LISTING_REGISTER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data.message,
        inlineMessage: action.payload.data.inlineMessage,
      };

    case CREATE_LISTING_SET_LISTING_DATA_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: action.payload.data,
      };
    case CREATE_LISTING_SET_LISTING_DATA_EMAIL_ALREADY_USED:
      return {
        ...state,
        emailAlreadyUsed: action.payload,
      };

    default:
      return state;
  }
}

const selectCreateListingAccountState = (state) => state.createListingAccount;

export const selectCreateListingAccountData = createSelector(
  selectCreateListingAccountState,
  (createListingAccount) => {
    return createListingAccount.data;
  },
);

export const selectCreateListingAccountIsLoading = createSelector(
  selectCreateListingAccountState,
  (createListingAccount) => {
    return createListingAccount.isLoading;
  },
);

export const selectCreateListingAccountInvalidFormFields = createSelector(
  selectCreateListingAccountState,
  (createListingAccount) => {
    return createListingAccount.invalidFormFields;
  },
);
export const selectCreateListingAccountEmailUsed = createSelector(
  selectCreateListingAccountState,
  (createListingAccount) => {
    return createListingAccount.emailAlreadyUsed;
  },
);

export const selectCreateListingAccountError = createSelector(
  selectCreateListingAccountState,
  (createListingAccount) => {
    return createListingAccount.inlineMessage;
  },
);
