/* eslint-disable indent */
const borderColor = "#d0d8e8";
const searchBorderColor = "#E3E7EB";

const getControlBorder = (
  isFocused,
  menuIsOpen,
  hasValue,
  options,
  customBorderColor,
) =>
  isFocused &&
  menuIsOpen &&
  ((hasValue && options.meta && !options.meta.error) ||
    (!hasValue && options.meta && !options.meta.touched))
    ? "#00b5f4"
    : !hasValue && options.meta && options.meta.touched && !!options.meta.error
    ? "#f20933"
    : isFocused && menuIsOpen
    ? "#00b5f4"
    : customBorderColor || borderColor;

const getSearchControlBorder = (isFocused, menuIsOpen, hasValue, options) =>
  isFocused &&
  menuIsOpen &&
  ((hasValue && options.meta && !options.meta.error) ||
    (!hasValue && options.meta && !options.meta.touched))
    ? "#00b5f4"
    : !hasValue && options.meta && options.meta.touched && !!options.meta.error
    ? "#f20933"
    : isFocused && menuIsOpen
    ? "#00b5f4"
    : searchBorderColor;

export const controlStyles = (
  styles,
  { isFocused, isDisabled, hasValue, selectProps: { menuIsOpen } },
  additional = {},
  options = {},
  customBorderColor,
) => ({
  ...styles,
  borderRadius: 1,
  backgroundColor: isDisabled ? "#fff" : styles.backgroundColor,
  borderWidth: isDisabled ? 0 : styles.borderWidth,
  // ":hover": {
  //   borderColor: "#a2a2a2",
  // },
  // borderColor: isFocused ? "#a2a2a2" : "#a2a2a2",
  zIndex: 0,
  transition: "all 0.3s ease",
  boxShadow: isFocused && menuIsOpen ? "none" : "none",
  borderColor: getControlBorder(
    isFocused,
    menuIsOpen,
    hasValue,
    options,
    customBorderColor,
  ),
  ":hover": {
    borderColor: getControlBorder(
      isFocused,
      menuIsOpen,
      hasValue,
      options,
      customBorderColor,
    ),
  },
  ":focus": {
    transition: "all 0.3s ease",
    borderColor: getControlBorder(
      isFocused,
      menuIsOpen,
      hasValue,
      options,
      customBorderColor,
    ),
  },
  ...additional,
});

export const pickerStyles = {
  control: controlStyles,
  container: (
    styles,
    { isDisabled, selectProps: { menuIsOpen, isMulti } },
  ) => ({
    ...styles,
    zIndex: menuIsOpen ? 3 : 0,
    height: isDisabled && !isMulti ? "25px" : styles.height,
  }),
  valueContainer: (
    styles,
    { selectProps: { isSearchable, isMulti }, isDisabled },
  ) => ({
    ...styles,
    paddingLeft: isDisabled ? 0 : styles.paddingLeft,
    paddingTop: isDisabled
      ? styles.paddingTop
      : isSearchable
      ? "0.32rem"
      : "0.605rem",
    paddingBottom: isDisabled
      ? styles.paddingBottom
      : isSearchable
      ? "0.32rem"
      : "0.605rem",
    fontSize: isDisabled ? styles.fontSize : "1.1rem",
    alignItems: isMulti ? "center" : styles.alignItems,
  }),
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    opacity: isDisabled ? 0 : styles.opacity,
    height: isDisabled ? 0 : styles.height,
  }),
  indicatorSeparator: (styles, { selectProps: { isClearable }, hasValue }) => ({
    ...styles,
    display: isClearable && hasValue ? styles.display : "none",
  }),
  dropdownIndicator: (styles, { isDisabled }) => ({
    ...styles,
    paddingTop: isDisabled ? styles.paddingTop : "9px",
  }),
  clearIndicator: (styles, { isDisabled }) => ({
    ...styles,
    paddingTop: isDisabled ? styles.paddingTop : "9px",
  }),
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    top: isDisabled ? "30%" : styles.top,
    color: "#2c3335",
    marginLeft: isDisabled ? 0 : styles.marginLeft,
    // fontFamily: isDisabled ? styles.fontFamily : "Futura Lt BT",
  }),
  multiValue: (styles, { isDisabled }) => ({
    ...styles,
    top: isDisabled ? "30%" : styles.top,
    marginLeft: isDisabled ? 0 : styles.marginLeft,
  }),
  multiValueLabel: (styles, { isDisabled }) => ({
    ...styles,
    paddingRight: isDisabled ? "6px" : styles.paddingRight,
    paddingTop: isDisabled ? "4px" : styles.paddingTop,
  }),
  multiValueRemove: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? "none" : styles.display,
  }),
  placeholder: (
    styles,
    { selectProps: { /*isSearchable,*/ isMulti }, isDisabled, isFocused },
  ) => ({
    ...styles,
    top: isDisabled ? "30%" : "25%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // fontFamily: isDisabled ? "Futura Book BT" : "Futura Lt BT",
    // fontSize: "1.1rem",
    color: "#757575",
    transform: isDisabled
      ? "translateY(-70%)"
      : isFocused
      ? "translateX(5px)"
      : "translateX(0)",
    transition: "all 0.3s ease",
    paddingLeft: isDisabled ? 0 : styles.paddingLeft,
    // paddingTop: isDisabled
    //   ? styles.paddingTop
    //   : isSearchable
    //   ? "0.32rem"
    //   : "0.605rem",
    // paddingBottom: isDisabled
    //   ? styles.paddingBottom
    //   : isSearchable
    //   ? "0.32rem"
    //   : "0.605rem",
    fontSize: isDisabled ? styles.fontSize : "1.1rem",
    alignItems: isMulti ? "center" : styles.alignItems,
  }),
};

export const searchControlStyles = (
  styles,
  { isFocused, isDisabled, hasValue, selectProps: { menuIsOpen } },
  additional = {},
  options = {},
) => ({
  ...styles,
  borderRadius: 1,
  backgroundColor: isFocused || isDisabled ? "#fff !important" : "#FBFCFD",
  borderWidth: isDisabled ? 0 : styles.borderWidth,
  width: "100%",
  zIndex: 0,
  transition: "all 0.3s ease",
  boxShadow: isFocused && menuIsOpen ? "none" : "none",
  borderColor: getSearchControlBorder(isFocused, menuIsOpen, hasValue, options),
  ":hover": {
    borderColor: getSearchControlBorder(
      isFocused,
      menuIsOpen,
      hasValue,
      options,
    ),
  },
  ":focus": {
    transition: "all 0.3s ease",
    borderColor: getSearchControlBorder(
      isFocused,
      menuIsOpen,
      hasValue,
      options,
    ),
  },
  height: "56px",
  alignItems: "flex-end",
  ...additional,
});

export const searchPickerStyles = {
  control: searchControlStyles,
  container: (
    styles,
    { isDisabled, selectProps: { menuIsOpen, isMulti } },
  ) => ({
    ...styles,
    zIndex: menuIsOpen ? 3 : 0,
    height: isDisabled && !isMulti ? "40px" : styles.height,
  }),
  valueContainer: (styles, { isDisabled, hasValue }) => ({
    ...styles,
    height: 30,
    marginLeft: "0.7rem",
    paddingLeft: isDisabled ? 0 : styles.paddingLeft,
    fontSize: isDisabled ? styles.fontSize : "15px",
    overflow: "auto",
    // fontFamily: isDisabled ? styles.fontFamily : "Futura Lt BT",
    ".css-b8ldur-Input": hasValue
      ? {
          maxWidth: "70px",
          overflow: "hidden",
          paddingTop: "12px",
        }
      : {
          maxWidth: "70px",
          overflow: "hidden",
        },
  }),
  input: (styles) => ({
    ...styles,
    paddingTop: 0,
    marginLeft: 0,
  }),
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    opacity: isDisabled ? 0 : styles.opacity,
    height: isDisabled ? 0 : styles.height,
  }),
  indicatorSeparator: (styles, { selectProps: { isClearable }, hasValue }) => ({
    ...styles,
    display: isClearable && hasValue ? styles.display : "none",
  }),
  dropdownIndicator: (styles, { isDisabled }) => ({
    ...styles,
    paddingTop: isDisabled ? styles.paddingTop : "9px",
  }),
  clearIndicator: (styles, { isDisabled }) => ({
    ...styles,
    paddingTop: isDisabled ? styles.paddingTop : "9px",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#2c3335",
    marginLeft: 0,
    marginTop: "-3px",
    // fontFamily: isDisabled ? styles.fontFamily : "Futura Lt BT",
  }),
  multiValue: (styles, { isDisabled }) => ({
    ...styles,
    top: isDisabled ? "30%" : styles.top,
    marginLeft: isDisabled ? 0 : styles.marginLeft,
  }),
  multiValueLabel: (styles, { isDisabled }) => ({
    ...styles,
    paddingRight: isDisabled ? "6px" : styles.paddingRight,
    paddingTop: isDisabled ? "4px" : styles.paddingTop,
  }),
  multiValueRemove: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? "none" : styles.display,
  }),
  placeholder: (provided, state) => {
    const activeLabel = state.hasValue || state.selectProps.inputValue;
    return {
      transition: "all 0.3s ease",
      position: "absolute",
      transform: activeLabel ? "translateY(-22px)" : "translateY(-10px)",
      fontSize: activeLabel ? 12 : 16,

      color: "#9CA6A9",
    };
  },
};
