import React, { Component } from "react";

import { Card } from "reactstrap";
import Notifications from "../../../components/fts/action-bar/action-sidebar/Notifications";

import "../../../assets/scss/pages/notifications.scss";
class NotificationsFull extends Component {
  render() {
    return (
      <>
        <h3 className="notifications-header">Notifications</h3>
        <Card>
          <Notifications />
        </Card>
      </>
    );
  }
}

export default NotificationsFull;
