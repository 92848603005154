import React, { forwardRef, useEffect, useRef } from "react";
import { formatRawDate } from "../../../configs/dateConfig";
import { ContextMenuTrigger } from "react-contextmenu";
import moment from "moment";

import capitalize from "lodash/capitalize";
import FTSBadge from "../../../components/fts/fts-badge";

import { Eye } from "react-feather";
import Icon from "../../../components/fts/Icons";
import { ADS_PROOFS_STATUSES } from "../../../constants";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";
// import Breakpoint from "../../../components/hoc/Breakpoint";

const TableDate = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
    </div>
  );
};

const TableDateTemp = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
    </div>
  );
};

const colorStatusID = (status) => {
  let backgroundColor;
  let textColor;
  let label;
  switch (status) {
    case "Paid":
      backgroundColor = "#EAF5F0";
      textColor = "#57C990";
      label = "Paid";
      break;

    case "Due":
      backgroundColor = "#FFE0E6";
      textColor = "#F36565";
      label = "Due";
      break;

    case "Voided":
      backgroundColor = "#D2DFE3";
      textColor = "#016D93";
      label = "Voided";
      break;

    case "Unsuccessful":
      backgroundColor = "#E5E8E9";
      textColor = "#647074";
      label = "Unsuccessful";
      break;

    default:
      backgroundColor = "#FFF3DF";
      textColor = "#F99930";
      label = "Pending";
      break;
  }

  return { backgroundColor, textColor, label };
};

export const defineInvoicesColumns = (
  collectDataForCopy,
  accountId,
  listingId,
  refreshTable,
  invoiceToOpen,
  togglePopOver,
) => {
  let columnsInvoices = [
    {
      headerName: "",
      field: "selected",
      width: 50,
      suppressMovable: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: false,
      checkboxSelection: (params) => {
        if (params.data.id === invoiceToOpen) params.node.setSelected(true);
        return params.data.statusForSort !== "Paid" ? true : false;
      },
    },
    {
      headerName: "Status",
      field: "statusForSort",
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        let { textColor, backgroundColor, label } = colorStatusID(
          params.data.statusForSort === "Overdue"
            ? "Due"
            : params.data.statusForSort,
        );

        return (
          <ContextMenuTrigger
            data={params.data.statusForSort}
            collect={collectDataForCopy}
            id="contextMenuInvoices"
          >
            <div className="d-flex align-items-center">
              <FTSBadge
                className="ml-1"
                style={{ backgroundColor: backgroundColor, color: textColor }}
              >
                {label}
              </FTSBadge>
              {params.data.statusForSort === "Voided" && (
                <span
                  onMouseEnter={() => {
                    params.data.statusForSort === "Voided" &&
                      togglePopOver(
                        `InvoiceStatus_${params.data.id}`,
                        params?.data["activities.activityNote"],
                      );
                  }}
                  onMouseLeave={() => togglePopOver()}
                  id={`InvoiceStatus_${params.data.id}`}
                  style={{ marginLeft: "10px", zIndex: "10000" }}
                >
                  <Icon
                    name="InfoCircle"
                    size="20"
                    style={{
                      stroke: "none",
                    }}
                  />
                </span>
              )}
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Invoice number",
      field: "invoiceNumberForSort",
      width: 205,
      suppressMovable: true,
      unSortIcon: true,
      sortable: false,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.invoiceNumber}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>{params.data.invoiceNumber}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Amount",
      field: "total",
      width: 120,
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["payments.total"] || params.data.total}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(params.data["payments.total"] || params.data.total)}
          </span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Due date",
      field: "dueAt",
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.dueAt).format("ll")}
            collect={collectDataForCopy}
            id="contextMenuInvoices"
          >
            <span>
              <TableDate date={params.data.dueAt} />
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Listing name",
      field: "listing.nameForSort",
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      width: 360,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["listing.name"]}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <div
            style={{
              width: "100%",
              whiteSpace: "break-spaces",
            }}
          >
            {params.data["listing.name"]}
          </div>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "City",
      field: "listing.cityForSort",
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      width: 125,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["listing.city"]}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>{params.data["listing.city"]}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "State",
      field: "listing.stateForSort",
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      width: 125,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["listing.state"]}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>{params.data["listing.state"]}</span>
        </ContextMenuTrigger>
      ),
    },

    {
      headerName: "Listing ID",
      field: "listing.id",
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      width: 125,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["listing.id"]}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>{params.data["listing.id"]}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Payment date",
      field: "payments.updatedAt",
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data["payments.updatedAt"]).format("ll")}
            collect={collectDataForCopy}
            id="contextMenuInvoices"
          >
            <span>
              {params.data["payments.updatedAt"] ? (
                <TableDate date={params.data["payments.updatedAt"]} />
              ) : null}
            </span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "Payment method",
      field: "payments.paidMethodForSort",
      minWidth: 230,
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["payments.paidMethod"]}
            collect={collectDataForCopy}
            id="contextMenuInvoices"
          >
            <span>
              {params.data["payments.paidMethod"] === "ach"
                ? params.data["payments.paidMethod"].toUpperCase()
                : capitalize(params.data["payments.paidMethod"])}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "owner.companyNameForSort",
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      minWidth: 250,
      maxWidth: 600,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["owner.companyName"]}
            collect={collectDataForCopy}
            id="contextMenuInvoices"
          >
            <div
              style={{
                overflow: "auto",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginTop: "5%" }}>
                {params.data["owner.companyName"]}
              </span>
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Listing name",
      field: "owner.companyNameForSort",
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      minWidth: 250,
      maxWidth: 600,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["owner.companyName"]}
            collect={collectDataForCopy}
            id="contextMenuInvoices"
          >
            <div
              style={{
                overflow: "auto",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginTop: "5%" }}>
                {params.data["owner.companyName"]}
              </span>
            </div>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "TID",
      field: "payments.sourceIdForSort",
      width: 270,
      suppressMovable: true,
      sortable: false,
      unSortIcon: true,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["payments.sourceId"]}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>{params.data["payments.sourceId"]}</span>
        </ContextMenuTrigger>
      ),
    },
  ];

  columnsInvoices = columnsInvoices.filter(
    (e) => e.field !== "owner.companyNameForSort",
  );

  return columnsInvoices;
};

export const defineInvoicesColumnsForPaymentLinkCheckBox = (callToAction) => {
  const listing = ["", "Premium", "Standard", "Basic Plus", "Basic"];
  let price = {
    498: "Premium",
    378: "Standard",
    258: "Basic Plus",
    138: "Basic",
  };
  let columnsInvoices = [
    {
      headerName: "",
      field: "selected",
      width: 50,
      suppressMovable: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: false,
      checkboxSelection: (params) => {
        if (params.data.isSelected) params.node.setSelected(true);
        return params.data.statusForSort !== "Paid";
      },
    },

    {
      headerName: "Listings",
      field: "invoiceNumberForSort",
      width: 320,
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      cellRendererFramework: (params) => (
        <div style={{ width: "290px" }}>
          <span
            style={{ lineHeight: "25px", whiteSpace: "break-spaces" }}
            className="d-block"
          >
            {params.data["listing.name"]}
          </span>
          <span style={{ lineHeight: "25px" }} className="d-block">
            {params.data["listing.city"]}, {params.data["listing.state"]}
          </span>
        </div>
      ),
    },
    {
      headerName: "Type",
      field: "listing.priceIdForSort",
      width: 140,
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      cellRendererFramework: (params) => (
        <div className="d-flex w-100 h-100 align-items-center">
          <span style={{ lineHeight: "42px" }}>
            {listing[params.data["listing.priceId"]] ||
              price[params.data["payments.total"] || params.data.total]}
          </span>
        </div>
      ),
    },
    {
      headerName: "Invoice number",
      field: "invoiceNumberForSort",
      width: 170,
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      cellRendererFramework: (params) => {
        return (
          <div>
            <span className="d-block" style={{ lineHeight: "25px" }}>
              {params.data.invoiceNumber}
            </span>
            <span
              className="d-flex align-items-center preview-invoice-button"
              style={{ lineHeight: "25px", color: "#00b5f4" }}
              onClick={() => callToAction(params.data)}
            >
              <Eye size={20} />
              <span>View invoice</span>
            </span>
          </div>
        );
      },
    },
    {
      headerName: "Due date",
      field: "dueAt",
      width: 110,
      suppressMovable: true,
      sortable: false,
      cellRendererFramework: (params) => {
        return (
          <div className="d-flex w-100 h-100 align-items-center">
            <span style={{ marginTop: 10 }}>
              <TableDateTemp date={params.data.dueAt} />
            </span>
          </div>
        );
      },
    },
    {
      headerName: "Amount",
      field: "total",
      width: 140,
      headerClass: "text-end",
      suppressMovable: true,
      sortable: false,
      cellRendererFramework: (params) => (
        <div className="d-flex w-100 h-100 align-items-center justify-content-end">
          <span style={{ lineHeight: "42px" }}>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(params.data["payments.total"] || params.data.total)}
          </span>
        </div>
      ),
    },
    // {
    //   colId: "actions",
    //   headerName: "",
    //   field: "",
    //   sortable: false,
    //   pinned: "right",
    //   unSortIcon: true,
    //   suppressMovable: true,
    //   width: 60,
    //   cellStyle: {
    //     "border-left": "1px solid #E5EBED !important",
    //     "box-shadow": "-2px 0px 5px #e5e8e9, 0px 1px 0px #e5e8e9;",
    //   },
    //   cellRendererFramework: () => {
    //     return (
    //       <div onClick={() => {}} style={{ paddingLeft: 2 }}>
    //         <Breakpoint md up>
    //           <Icon name="Eye" size={24} />
    //         </Breakpoint>
    //
    //         <Breakpoint sm down>
    //           <Icon className="arrow-icon" name="ArrowLeft" size={24} />
    //         </Breakpoint>
    //       </div>
    //     );
    //   },
    // },
  ];

  return columnsInvoices;
};

const IndeterminateCheckbox = forwardRef(
  ({ row, indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    if (row && row.original?.status !== PROOF_STATUSES_ENUM.PENDING)
      return null;
    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

export const defineInvoicesColumnsForProofs = () => {
  const colors = ["", "#98CA86", "#F36565", "#01B5F5"];
  const columns = [
    {
      id: "selection",
      minWidth: 10,
      suppressMovable: true,
      Header: ({ getToggleAllRowsSelectedProps }) => {
        return (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        );
      },
      Cell: ({ row }) => (
        <div>
          <IndeterminateCheckbox
            row={row}
            {...row.getToggleRowSelectedProps()}
          />
        </div>
      ),
    },
    {
      Header: "Id",
      canExpand: true,
      accessor: (row) => row.listing.id,
      minWidth: 50,
      suppressMovable: true,
    },
    {
      Header: "Location name",
      accessor: (row) => row.listing.name,
      minWidth: 120,
      suppressMovable: true,
    },
    {
      Header: "Address",
      accessor: (row) => row.listing.address,
      minWidth: 120,
      suppressMovable: true,
    },
    {
      Header: "City",
      accessor: (row) => row.listing.city,
      minWidth: 100,
      suppressMovable: true,
    },
    {
      Header: "State",
      accessor: (row) => row.listing.state,
      minWidth: 30,
      suppressMovable: true,
    },
    {
      Header: "Primary phone",
      accessor: (row) => row.listing.phoneNumber,
      minWidth: 120,
      suppressMovable: true,
    },
    {
      Header: "Secondary phone",
      accessor: (row) =>
        row.listing.phoneNumberSecondary || row.listing.afterHours,
      minWidth: 130,
      suppressMovable: true,
    },
    {
      Header: "Fax",
      accessor: (row) => row.listing.fax,
      minWidth: 120,
      suppressMovable: true,
    },

    {
      // Build our expander column
      id: "expander", // Make sure it has an ID
      suppressMovable: true,
      // Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      //   <span {...getToggleAllRowsExpandedProps()}>
      //     {isAllRowsExpanded ? <i className="fa-solid fa-chevron-down" /> : <i className="fa-solid fa-angle-right" />}
      //   </span>
      // ),
      Header: () => null,
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps({})}>
          {row.isExpanded ? (
            <>
              <span style={{ color: colors[row.original.status] }}>
                {ADS_PROOFS_STATUSES[row.original.status]}
              </span>
              <i className="fa fa-solid fa-chevron-down fa-lg" />
            </>
          ) : (
            <>
              <span style={{ color: colors[row.original.status] }}>
                {ADS_PROOFS_STATUSES[row.original.status]}
              </span>
              <i className="fa fa-solid fa-angle-right fa-lg" />
            </>
          )}
        </span>
      ),
    },
  ];

  return columns;
};
