import React, { Component } from "react";
import Select from "../Select";

class ProofsTypePicker extends Component {
  render() {
    const { name, isDisabled, options, value, ...rest } = this.props;
    const pickerValue = options.find((itm) => itm.value === value);

    return (
      <div className="fts-referral-type-picker">
        <Select
          isSearchable={false}
          isDisabled={isDisabled}
          options={options}
          name={name}
          value={pickerValue}
          {...rest}
        />
      </div>
    );
  }
}

export default ProofsTypePicker;
