import { call, takeLatest } from "redux-saga/effects";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import { COMPANY_DRIVER_PROFILE_UPDATE_REQUEST } from "../../reducers/v2/company-driver-profile";

function* updateCompanyDriverProfile(action) {
  const { data, onSuccess, onError } = action.payload;

  try {
    const response = yield call(FTSAxiosV2.put, `/users/update`, {
      userData: data,
    });
    onSuccess(response);
  } catch (e) {
    onError(e);
  }
}

export default function* updateCompanyDriverProfileWatcher() {
  yield takeLatest(
    COMPANY_DRIVER_PROFILE_UPDATE_REQUEST,
    updateCompanyDriverProfile,
  );
}
