/* eslint-disable indent */
const initialState = {
  "listing.id": "",
  "listing.name": "",
  userIdString: "",
  "listing.email": "",
  "listing.phoneNumber": "",
  "listing.city": "",
  "listing.state": "",
  startAt: null,
  endAt: null,
  activeTab: "today",
  accountOwner: null,
  from: null,
  to: null,
  collapsed: null,
};

const RenewalsFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_RENEWALS_FILTER":
      return {
        ...state,
        ...action.data,
        listState: action.data.listState || null,
      };

    case "CLEAR_RENEWALS_FILTER":
      return initialState;

    default:
      return state;
  }
};

export default RenewalsFilterReducer;
