import * as Yup from "yup";
import moment from "moment";

export const ownerOperatorTruckValidator = Yup.object().shape({
  truckYear: Yup.number()
    .required("Year is required")
    .min(1900, "Invalid year")
    .max(moment().year(), "Invalid year"),
  truckMake: Yup.string().required("Make is required"),
  truckModel: Yup.string().required("Model is required"),
  truckNumber: Yup.string().required("Truck # is required"),
  truckVin: Yup.string().required("VIN is required"),
  truckPlates: Yup.string(),
  truckPlatesExpiration: Yup.string().nullable(),
  truckType: Yup.string().required("Truck type is required"),
});
