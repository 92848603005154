const REPAIR_STATUSES = {
  IN_QUEUE: 1,
  STARTED: 2,
  EN_ROUTE: 3,
  ON_SITE: 4,
  IN_SHOP: 5,
  COMPLETED: 6,
  CANCELED: 7,
};

const REPAIR_TYPES = {
  NOT_SPECIFIED: 1,
  MOBILE_REPAIR: 2,
  SHOP_REPAIR: 3,
};

const REPAIR_ACTIVITIES_TYPES = {
  USER_ACTIVITY_LIGHT: 1,
  LEAVE_NEUTRAL_NOTE: 2,
  LEAVE_POSITIVE_NOTE: 3,
  LEAVE_NEGATIVE_NOTE: 4,
  REQUESTED_REPAIR: 5,
  REPAIR_TICKET_STARTED: 6,
  ADDED_ETA: 7,
  ADDED_ECT: 8,
  VENDOR_ARRIVED: 9,
  TICKED_MOVED_TO_STARTED: 10,
  TICKED_PAUSED: 11,
  TICKET_CANCELED: 12,
  TICKET_COMPLETED: 13,
  LEAVE_REPAIR_EXPERIENCE: 14,
  ADD_TOTAL_COST: 15,
  TICKET_COMPLETED_BEFORE_ETC: 16,
  TICKET_COMPLETED_AFTER_ETC: 17,
  TICKET_COMPLETED_ON_TIME: 18,
};

export { REPAIR_STATUSES, REPAIR_TYPES, REPAIR_ACTIVITIES_TYPES };
