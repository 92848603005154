import React from "react";
import { Col } from "reactstrap";
import CloseButton from "../CloseButton";
import TitleRow from "../TitleRow";

const HeaderCTA = ({ titleDescription, handleClose }) => {
  return (
    <div>
      <TitleRow title="Listing Preview" titleDescription={titleDescription}>
        <Col className="text-right close-button-wrapper">
          <CloseButton onClick={handleClose} />
        </Col>
      </TitleRow>
    </div>
  );
};

export default HeaderCTA;
