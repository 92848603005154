import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ServiceAmenitiesMobile } from "./ServiceAmenitiesMobile";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { ServiceAmenitiesDesktop } from "./ServiceAmenitiesDesktop";
import { setListingData } from "../../../../redux/actions/v2/listing";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { selectServiceAmenities } from "../../../../redux/reducers/essentialLists";
import { selectListingAdsServiceAmenities } from "../../../../redux/reducers/v2/listing";
import { MemoizedServiceAmenitiesModal } from "./ServiceAmenitiesModal";

export const ServiceAmenitiesRow = () => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState("");
  const [showModal, setShowModal] = useState(false);
  const selectedServiceAmenities = useSelector(
    selectListingAdsServiceAmenities,
  );
  const serviceAmenities = useSelector(selectServiceAmenities);
  const [filteredServiceAmenities, setFilteredServiceAmenities] =
    useState(null);
  const hasServiceAmenities = !!filteredServiceAmenities?.length;

  useEffect(() => {
    if (!searchString) return setFilteredServiceAmenities(serviceAmenities);
    const filtered = serviceAmenities.filter((e) =>
      e.name.toLowerCase().includes(searchString.toLowerCase()),
    );
    setFilteredServiceAmenities(filtered);
  }, [searchString, serviceAmenities]);

  const onChangeServiceAmenities = useCallback(
    (id, value, name) => {
      let adsServiceAmenities = [
        ...selectedServiceAmenities,
        { serviceAmenityId: id, name },
      ];
      if (!value)
        adsServiceAmenities = adsServiceAmenities.filter(
          ({ serviceAmenityId }) => serviceAmenityId !== id,
        );
      dispatch(setListingData({ data: { adsServiceAmenities } }));
    },
    [selectedServiceAmenities],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} width={"100%"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          SERVICES
        </Span>
      </FtsColumn>
      <ServiceAmenitiesMobile
        selectedServiceAmenities={selectedServiceAmenities}
        setShowModal={setShowModal}
      >
        <MemoizedServiceAmenitiesModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </ServiceAmenitiesMobile>
      <ServiceAmenitiesDesktop
        searchString={searchString}
        setSearchString={setSearchString}
        hasServiceAmenities={hasServiceAmenities}
        selectedServiceAmenities={selectedServiceAmenities}
        onChangeServiceAmenities={onChangeServiceAmenities}
        filteredServiceAmenities={filteredServiceAmenities}
      />
    </FtsRow>
  );
};
