import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";

import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { PhoneNumberInput } from "@find-truck-service/ui/src/components/react/PhoneNumberInput";
import {
  selectCreateListingAccountData,
  selectCreateListingAccountEmailUsed,
  selectCreateListingAccountError,
  selectCreateListingAccountInvalidFormFields,
  selectCreateListingAccountIsLoading,
} from "../../../redux/reducers/createListingAccount";
import { getListedAccountValidator } from "./validators";
import {
  createListingAccountRequest,
  createListingAccountSetEmailAlreadyUsed,
  createListingAccountSetListingDataInvalidFormFields,
  createListingSetUserValues,
} from "../../../redux/actions/createListingAccount";
import { isObject } from "lodash";
import { PasswordValidator } from "@find-truck-service/ui/src/components/react/PasswordValidator";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

import { extractParameter } from "../../../utility/param-extract";
import { CenteredLayout } from "../Layouts/CenteredLayout";
import { Collapsible } from "@find-truck-service/ui/src/components/react/Collapsible";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import { TermsAndConditions } from "../../../views/pages/create-account/components/TermsAndConditions";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { emailError } from "./helpers/getEmailError";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
import logger from "../../../utility/logger";

const GetListedAccount = () => {
  const selectedListingPriceId = extractParameter("selectedListingPriceId");
  const [showPassword, setShowPassword] = useState(false);
  const [showValidator, setShowValidator] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectCreateListingAccountIsLoading);
  const accountData = useSelector(selectCreateListingAccountData);
  const emailAlreadyUsed = useSelector(selectCreateListingAccountEmailUsed);
  const invalidFormFields = useSelector(
    selectCreateListingAccountInvalidFormFields,
  );
  const inlineMessage = useSelector(selectCreateListingAccountError);

  const emailErrorMessage = emailError(emailAlreadyUsed, invalidFormFields);

  const showPasswordValidator = useCallback(() => {
    setShowValidator(true);
  }, []);

  const showPasswordValidatorMsg =
    showValidator ||
    (isObject(invalidFormFields) && invalidFormFields["password"]);

  const onChange = (event) => {
    dispatch(
      createListingSetUserValues({
        [event.target.name]: event.target.value,
      }),
    );
  };

  const handleToggleMarketingOptIn = useCallback(() => {
    dispatch(
      createListingSetUserValues({
        ...accountData,
        ["primaryMarketingOptIn"]: !accountData?.primaryMarketingOptIn,
      }),
    );
  }, [accountData]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = useCallback(async () => {
    try {
      await getListedAccountValidator.validate(accountData || {});
      dispatch(
        createListingAccountSetListingDataInvalidFormFields({ data: null }),
      );
      dispatch(createListingAccountSetEmailAlreadyUsed(false));
      dispatch(
        createListingAccountRequest({
          data: { accountData, selectedListingPriceId },
        }),
      );
    } catch (e) {
      logger.error(e);
      if (e.name === "ValidationError") {
        dispatch(
          createListingAccountSetListingDataInvalidFormFields({
            data: {
              [e.path]: e.errors[0],
            },
          }),
        );
      }
    }
  }, [accountData]);

  return (
    <>
      {isLoading && <FullPageLoader />}
      <CenteredLayout>
        <FtsWrapper
          display={{ xs: "flex", sm: "none", md: "none", lg: "none" }}
          px={"xl"}
          py={"2xl"}
        >
          <Span
            variant={VariantsValues["text-md"]}
            color={ColorsValue["greyscale-800"]}
            weight={WeightSize.bold}
          >
            Account Info
          </Span>
        </FtsWrapper>
        <FtsWrapper
          bg={ColorsValue["greyscale-0"]}
          px={{ xs: "xl", sm: "4xl", md: "4xl", lg: "4xl" }}
          py={{ xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" }}
          borderRadius="2xs"
          boxShadow="sm"
        >
          {inlineMessage && (
            <FtsWrapper mb={{ xs: "lg", md: "3xl", lg: "4xl" }}>
              <Message type={"error"} title={""} subtitle={inlineMessage} />
            </FtsWrapper>
          )}
          <FtsWrapper
            display={{
              xs: "none",
              sm: "block",
              md: "block",
              lg: "block",
            }}
          >
            <FtsRow rowGap={"2xl"} mb={"2xl"}>
              <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                <Span
                  variant={VariantsValues["text-md"]}
                  color={ColorsValue["greyscale-800"]}
                  weight={WeightSize.bold}
                >
                  Account Info
                </Span>
              </FtsColumn>
            </FtsRow>
          </FtsWrapper>
          <FtsWrapper>
            <FtsWrapper mb={"2xs"}>
              <FtsRow columnGap={"lg"} rowGap={"2xl"}>
                <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
                  <Input
                    name={"firstName"}
                    required
                    value={accountData?.firstName}
                    onChange={onChange}
                    label={"First name"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["firstName"]
                    }
                  />
                </FtsColumn>

                <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
                  <Input
                    name={"lastName"}
                    value={accountData?.lastName}
                    onChange={onChange}
                    required
                    label={"Last name"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["lastName"]
                    }
                  />
                </FtsColumn>
              </FtsRow>
              <FtsRow columnGap={"lg"} rowGap={"2xl"} mt={"2xl"}>
                <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
                  <PhoneNumberInput
                    name={"phoneNumber"}
                    required
                    value={accountData?.phoneNumber}
                    onChange={onChange}
                    label={"Phone (primary)"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["phoneNumber"]
                    }
                  />
                </FtsColumn>

                <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
                  <Input
                    name={"jobTitle"}
                    value={accountData?.jobTitle}
                    onChange={onChange}
                    required
                    label={"Job title"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["jobTitle"]
                    }
                  />
                </FtsColumn>
              </FtsRow>

              <FtsRow columnGap={"lg"} rowGap={"2xl"} mt={"2xl"}>
                <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                  <Input
                    required
                    name={"email"}
                    label={"Email"}
                    onChange={onChange}
                    size={InputSizes.md}
                    value={accountData?.email}
                    variant={InputVariants.outlined}
                    errorMessage={emailErrorMessage}
                  />
                </FtsColumn>
              </FtsRow>
              <FtsRow columnGap={"lg"} rowGap={"2xl"} mt={"2xl"}>
                <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                  <Input
                    name={"password"}
                    onChange={onChange}
                    label={"Password"}
                    required
                    onFocus={showPasswordValidator}
                    variant={InputVariants.outlined}
                    value={accountData?.password}
                    size={InputSizes.md}
                    appendIcon={IconNames.EyeIcon}
                    onAppendIconClick={toggleShowPassword}
                    type={showPassword ? "text" : "password"}
                  />
                </FtsColumn>
                <FtsColumn size={{ xl: 12, md: 12, sm: 12 }} mb={"2xl"}>
                  <Collapsible show={showPasswordValidatorMsg}>
                    <PasswordValidator password={accountData?.password || ""} />
                  </Collapsible>
                </FtsColumn>
              </FtsRow>
            </FtsWrapper>

            <FtsRow columnGap={"lg"} rowGap={"2xl"}>
              <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                <Checkbox
                  size={CheckboxSizes.md}
                  onClick={handleToggleMarketingOptIn}
                  checked={accountData?.primaryMarketingOptIn}
                  label={"Receive emails regarding my account"}
                  mr={"lg"}
                />
              </FtsColumn>
            </FtsRow>
            <FtsWrapper
              display={{
                xs: "none",
                sm: "block",
                md: "block",
                lg: "block",
              }}
            >
              <FtsRow columnGap={"lg"} rowGap={"2xl"} mt={"2xl"}>
                <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                  <Button
                    width="100%"
                    size={ButtonSizes.md}
                    onClick={handleRegister}
                    color={ButtonColorsValue.accent}
                    type={ButtonTypes.contained}
                    label={"Continue"}
                  />
                </FtsColumn>
              </FtsRow>
            </FtsWrapper>
          </FtsWrapper>
        </FtsWrapper>
        <FtsWrapper px={{ xs: "xl", sm: "0", md: "0", lg: "0" }}>
          <FtsRow rowGap={"0"} columnGap={"0"}>
            <FtsColumn
              size={{ xs: 12, md: 12, lg: 12 }}
              display={{ xs: "block", sm: "none", md: "none", lg: "none" }}
              mt={"xl"}
            >
              <Button
                width="100%"
                size={ButtonSizes.md}
                onClick={handleRegister}
                color={ButtonColorsValue.accent}
                type={ButtonTypes.contained}
                label={"Continue"}
              />
            </FtsColumn>
            <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
              <TermsAndConditions />
            </FtsColumn>
          </FtsRow>
        </FtsWrapper>
      </CenteredLayout>
    </>
  );
};

export default GetListedAccount;
