import React, { useCallback, useEffect, useState } from "react";
import { SearchNoResults } from "./SearchNoResults";
import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { selectServiceAmenities } from "../../../../redux/reducers/essentialLists";
import { ModalContentComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalContentComponent";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { InputVariants } from "@find-truck-service/types/ui/input";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";

export const ServiceAmenitiesModalContentFields = ({
  selectedServiceAmenities,
  setListingData,
}) => {
  const [searchString, setSearchString] = useState("");
  const serviceAmenities = useSelector(selectServiceAmenities);
  const dispatch = useDispatch();

  const [filteredServiceAmenities, setFilteredServiceAmenities] =
    useState(null);
  const hasServiceAmenities = !!filteredServiceAmenities?.length;
  const showNoResults = !hasServiceAmenities && !!searchString;

  const onChangeServiceAmenities = useCallback(
    (id, value, name) => {
      let adsServiceAmenities = [
        ...selectedServiceAmenities,
        { serviceAmenityId: id, name },
      ];
      if (!value)
        adsServiceAmenities = adsServiceAmenities.filter(
          ({ serviceAmenityId }) => serviceAmenityId !== id,
        );
      dispatch(setListingData({ data: { adsServiceAmenities } }));
    },
    [selectedServiceAmenities],
  );

  useEffect(() => {
    if (!searchString) return setFilteredServiceAmenities(serviceAmenities);
    const filtered = serviceAmenities.filter((e) =>
      e.name.toLowerCase().includes(searchString.toLowerCase()),
    );
    setFilteredServiceAmenities(filtered);
  }, [searchString, serviceAmenities]);

  function onClearSearch() {
    setSearchString("");
  }

  function onSearchChange({ target: { value } }) {
    setSearchString(value);
  }

  return (
    <ModalContentComponent flex={1} overflow={"scroll"}>
      <FtsWrapper pt={"2xs"}>
        <Input
          label={""}
          size={"md"}
          name={"search"}
          bg={ColorsValue["greyscale-50"]}
          value={searchString}
          placeHolder={"Search"}
          onChange={onSearchChange}
          variant={InputVariants.contained}
          appendIcon={IconNames.Close}
          onAppendIconClick={onClearSearch}
          prependIcon={IconNames.Lupe}
        />
        <FtsRow rowGap={"xl"} width={"100%"} mt={"lg"}>
          {hasServiceAmenities &&
            filteredServiceAmenities.map((e) => {
              const checked = !!selectedServiceAmenities.find(
                (f) => f.serviceAmenityId === e.id,
              );
              return (
                <FtsColumn key={e.id} size={{ xs: 12 }}>
                  <Checkbox
                    label={e.name}
                    checked={checked}
                    size={CheckboxSizes.lg}
                    onClick={() =>
                      onChangeServiceAmenities(e.id, !checked, e.name)
                    }
                  />
                </FtsColumn>
              );
            })}
          {showNoResults && <SearchNoResults searchString={searchString} />}
        </FtsRow>
      </FtsWrapper>
    </ModalContentComponent>
  );
};
