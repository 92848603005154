import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import LoadingSkeleton from "react-loading-skeleton";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const InvoiceSkeletonBody = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(FtsWrapper, { height: "1px", bg: ColorsValue["greyscale-100"], mr: "md", width: "100%" }),
        React.createElement(FtsWrapper, null,
            React.createElement(LoadingSkeleton, { duration: 1, width: "100%", height: 12 }),
            React.createElement(LoadingSkeleton, { duration: 1, width: "100%", height: 12 })),
        React.createElement(FtsWrapper, null,
            React.createElement(LoadingSkeleton, { duration: 1, width: "100%", height: 12 }),
            React.createElement(LoadingSkeleton, { duration: 1, width: "100%", height: 12 }),
            React.createElement(LoadingSkeleton, { duration: 1, width: "100%", height: 12 })),
        React.createElement(FtsWrapper, { height: "1px", bg: "greyscale-100", mr: "md", width: "100%" })));
};
