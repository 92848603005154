import React from "react";
import { Row, Col } from "reactstrap";
import classnames from "classnames";

import "../../assets/scss/components/fts/title-row.scss";
import Icons from "./Icons";

const TitleRow = ({
  title,
  className,
  children,
  rightSection,
  noMarker,
  onTitleClick,
  titleDescription,
}) => {
  return (
    <Row
      className={classnames(
        `align-items-center fts-title-row ${className ?? ""}`,
        {
          "no-marker": noMarker,
        }
      )}
    >
      <Col className="title-header-wrapper">
        {rightSection ? (
          <div className="row-section-holder d-flex align-items-center">
            <div>
              <h4>{title}</h4>
            </div>
            <div>{rightSection()}</div>
          </div>
        ) : (
          <div
            onClick={onTitleClick}
            className={`d-flex fts-listing-details-header fts-modal__free-listing-preview__title-header  ${
              onTitleClick ? "go-to" : ""
            }`}
          >
            <h4 className={"mr-1"}>{title}</h4>
            {!!titleDescription && (
              <div className="fts-modal__free-listing-preview__title-description">
                {titleDescription}
              </div>
            )}

            {onTitleClick && <Icons name={"GoTo"} />}
          </div>
        )}
      </Col>
      {children}
    </Row>
  );
};

export default TitleRow;
