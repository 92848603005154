/* eslint-disable indent */
const initialState = {
  unReadCount: null,
  data: null,
  openNotificationsSidebar: false,
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NOTIFICATIONS_UNREAD_COUNTER":
      return {
        ...state,
        unReadCount: action.payload || 0,
      };
    case "SET_NOTIFICATIONS":
      return {
        ...state,
        data: action.payload,
      };

    case "SET_OPEN_NOTIFICATIONS":
      return {
        ...state,
        openNotificationsSidebar: action.payload || false,
      };

    default:
      return state;
  }
};

export default NotificationReducer;
