const logger = {
  info: (...args) => {
    if (process.env.REACT_APP_ENVIRONMENT !== "production")
      console.log(...args);
  },
  warn: (...args) => {
    if (process.env.REACT_APP_ENVIRONMENT !== "production")
      console.warn(...args);
  },
  error: (...args) => {
    if (process.env.REACT_APP_ENVIRONMENT !== "production")
      console.error(...args);
    //Optional handle errors with Sentry
  },
  table: (data) => {
    if (process.env.REACT_APP_ENVIRONMENT !== "production") console.table(data);
  },
};

export default logger;
