import {
  ALL_DAY,
  BY_APPOINTMENT,
  CLOSED,
  dontShowWorkingHoursToDropdown,
  FROM_DEFAULT,
  listingDraftSteps,
  SUNDAY,
  SUNDAY_FROM,
  TO_DEFAULT,
  weekDaysFullName,
} from "../../../components/fts-components-v2/ListingBusinessInfo/constants";
import logger from "../../../utility/logger";
import { ADS_RATES_TYPE } from "../../../constants";

import isEmpty from "lodash/isEmpty";

function formatWorkingHoursPayload(workingHours) {
  return weekDaysFullName?.reduce((acc, day) => {
    const dayShort = day.slice(0, 3);
    let dayFrom = workingHours[`${dayShort}From`]?.value;
    let dayTo = workingHours[`${dayShort}To`]?.value;

    //Setting default values - must match with the default values in the form
    if (!dayFrom && day === SUNDAY) dayFrom = SUNDAY_FROM;
    else if (!dayFrom) dayFrom = FROM_DEFAULT;
    if (!dayTo) dayTo = TO_DEFAULT;

    const hasToValue = !dontShowWorkingHoursToDropdown.includes(dayFrom);
    if (hasToValue) acc[day] = `${dayFrom} - ${dayTo}`;
    else acc[day] = `${dayFrom}`;

    return acc;
  }, {});
}

function formatWorkingHoursResponse(workingHours) {
  if (!workingHours || isEmpty(workingHours)) return;

  return weekDaysFullName?.reduce((acc, day) => {
    const dayShort = day.slice(0, 3);
    const dayHours = workingHours[day];
    const from = `${dayShort}From`;

    if (dayHours.includes(ALL_DAY))
      acc[from] = { label: ALL_DAY, value: ALL_DAY };
    else if (dayHours.includes(BY_APPOINTMENT))
      acc[from] = { label: BY_APPOINTMENT, value: BY_APPOINTMENT };
    else if (dayHours.includes(CLOSED))
      acc[from] = { label: CLOSED, value: CLOSED };
    else {
      const [from, to] = dayHours.split(" - ");
      acc[`${dayShort}From`] = { label: from, value: from };
      acc[`${dayShort}To`] = { label: to, value: to };
    }

    return acc;
  }, {});
}

function formatRatesPayload(adsRates, rates, responseRates, adId) {
  const allRates = rates?.map((rate) => {
    let responseRate = responseRates.find((r) => r.rateId === rate.id);
    responseRate = responseRate
      ? { ...responseRate, userIdAccount: responseRate.ownerId }
      : {};

    return {
      adId,
      rateId: rate.id,
      status: !!rate.status,
      type: ADS_RATES_TYPE.PUBLIC,
      ...responseRate,
      value: adsRates[rate.id] || "",
    };
  });
  return allRates.filter((rate) => !!rate.value);
}

// function formatRatesPayload(adsRates, rates, responseRates, adId) {
//   const allRates = rates?.map((rate) => {
//     let responseRate = responseRates.find((r) => r.rateId === rate.id);
//     responseRate = responseRate
//       ? { ...responseRate, userIdAccount: responseRate.ownerId }
//       : {};
//
//     const validProps = [
//       "adId",
//       "rateId",
//       "status",
//       "type",
//       "id",
//       "userId",
//       "ownerId",
//       "subOwnerId",
//       "value",
//       "userIdAccount",
//     ];
//
//     let formattedRate = {
//       adId,
//       rateId: rate.id,
//       status: rate.status,
//       type: ADS_RATES_TYPE.PUBLIC,
//       ...responseRate,
//       ...adsRates[rate.id],
//     };
//     return Object.keys(formattedRate).reduce((acc, key) => {
//       if (validProps.includes(key)) acc[key] = formattedRate[key];
//       return acc;
//     }, {});
//   });
//   return allRates.filter((rate) => !!rate.value);
// }

function formatAdsAssetsPayload(adsAssets, adId) {
  return adsAssets?.map((asset) => ({
    type: asset.type,
    path: asset.fileUrl,
    id: asset.id || null,
    size: asset.fileSize,
    adId: asset.adId || adId,
    versions: asset.versions || null,
    notifyUser: asset.notifyUser || 0,
    lastVersions: asset.lastVersions || 1,
    adminOwnerId: asset.adminOwnerId || null,
  }));
}

function formatAdsAssetsResponse(adsAssets) {
  return adsAssets?.map((asset) => ({
    ...asset,
    type: asset.type,
    fileUrl: asset.path,
    fileName: asset.path,
    fileSize: asset.size,
    notifyUser: asset.notifyUser,
  }));
}

export function formatRatesResponse(adsRates, rates) {
  return rates.reduce((acc, rate) => {
    const adsRate = adsRates.find((r) => r.rateId === rate.id);
    acc[rate.id] = !adsRate?.value ? "" : adsRate.value;
    return acc;
  }, {});
}

function formatAdsAmenitiesPayload(adsAmenities, adId) {
  return adsAmenities.map(({ amenityId, id }) => ({ amenityId, id, adId }));
}

function formatAdsServiceAmenitiesPayload(adsAmenities, adId) {
  return adsAmenities.map(({ serviceAmenityId, id }) => ({
    serviceAmenityId,
    id,
    adId,
  }));
}

function formatAdsCategoriesPayload(adsCategories, validCategoryIds, adId) {
  return Array.from(validCategoryIds).map((mainCategoryId) => ({
    mainCategoryId,
    id: adsCategories.find((cat) => cat.mainCategoryId === mainCategoryId)?.id,
    adId,
  }));
}

function formatAdsSubCategoriesPayload(adsSubCategories, adId) {
  return adsSubCategories.map(({ subCategoryId, id }) => ({
    subCategoryId,
    id,
    adId,
  }));
}

function filterAndFormatCategories(
  adsCategories,
  adsSubCategories,
  subCategories,
  id,
) {
  //Remove all categories that don't have a valid subcategory
  const validCategoryIds = new Set(
    adsSubCategories
      ?.filter((sub) =>
        subCategories.some((subCat) => subCat.id === sub.subCategoryId),
      )
      .map((sub) => {
        const category = subCategories.find(
          (subCat) => subCat.id === sub.subCategoryId,
        );
        return category ? category.categoryId : null;
      })
      .filter(Boolean),
  );

  return formatAdsCategoriesPayload(adsCategories, validCategoryIds, id);
}

export function formatListingDataPayload(listing, rates, subCategories) {
  const {
    email,
    listingCurrentTime,
    workingHours = {},
    adsRates = [],
    adsAssets = [],
    adsAmenities = [],
    responseRates = [],
    adsCategories = [],
    adsSubCategories = [],
    adsServiceAmenities = [],
    ...rest
  } = listing;
  logger.info("ID & Email", rest.id, email, listingCurrentTime);

  const formattedAdsSubCategories = formatAdsSubCategoriesPayload(
    adsSubCategories,
    rest.id,
  );
  const formattedAdsCategories = filterAndFormatCategories(
    adsCategories,
    formattedAdsSubCategories,
    subCategories,
    rest.id,
  );

  const listingData = {
    draftStep: listingDraftSteps.CHOOSE_LISTING_TYPE,
    ...rest,
    latitude: rest.latitude.toString(),
    longitude: rest.longitude.toString(),
    alwaysOpen: rest.alwaysOpen ? 1 : 0,
    towingRecoveryOpen: rest.towingRecoveryOpen ? 1 : 0,
    mobileRoadServiceOpen: rest.mobileRoadServiceOpen ? 1 : 0,
  };

  return {
    listingData,
    adsRates: formatRatesPayload(adsRates, rates, responseRates, rest.id),
    adsAssets: formatAdsAssetsPayload(adsAssets, rest.id),
    workingHours: formatWorkingHoursPayload(workingHours),
    adsAmenities: formatAdsAmenitiesPayload(adsAmenities, rest.id),
    adsCategories: formattedAdsCategories,
    adsSubCategories: formattedAdsSubCategories,
    adsServiceAmenities: formatAdsServiceAmenitiesPayload(
      adsServiceAmenities,
      rest.id,
    ),
  };
}

function formatAdsAmenitiesResponse(adsAmenities, amenities) {
  return adsAmenities.map((adsAmenity) => {
    const amenity = amenities.find((a) => a.id === adsAmenity.amenityId);
    return { ...adsAmenity, name: amenity?.name };
  });
}

function formatAdsServiceAmenitiesResponse(
  adsServiceAmenities,
  serviceAmenities,
) {
  return adsServiceAmenities.map((adsServiceAmenity) => {
    const serviceAmenity = serviceAmenities.find(
      (a) => a.id === adsServiceAmenity.serviceAmenityId,
    );
    return { ...adsServiceAmenity, name: serviceAmenity?.name };
  });
}

export function formatListingResponse(
  response,
  rates,
  amenities,
  serviceAmenities,
) {
  const {
    workingHours = {},
    adsRates = [],
    adsAssets = [],
    adsAmenities = [],
    adsCategories = [],
    adsSubCategories = [],
    adsServiceAmenities = [],
    ...rest
  } = response;

  return {
    ...rest,
    adsCategories,
    adsSubCategories,
    responseRates: adsRates,
    adsAssets: formatAdsAssetsResponse(adsAssets),
    adsRates: formatRatesResponse(adsRates, rates),
    workingHours: {
      ...workingHours,
      ...formatWorkingHoursResponse(workingHours),
    },
    adsAmenities: formatAdsAmenitiesResponse(adsAmenities, amenities),
    adsServiceAmenities: formatAdsServiceAmenitiesResponse(
      adsServiceAmenities,
      serviceAmenities,
    ),
  };
}

export function handleErrorMessage(e, defaultMsg) {
  return e.response ? e.response.data.message : defaultMsg;
}

export function hasOnSuccessFunction(action) {
  return (
    action?.payload?.onSuccess &&
    typeof action?.payload?.onSuccess === "function"
  );
}
