import {
  UPDATE_USER_DRIVERS,
  SET_SELECTED_TRUCK_DRIVER,
  RESET_USER_DRIVERS,
  UPDATE_USER_TRUCKS,
  RESET_USER_TRUCKS,
  SET_SELECTED_TRUCK,
} from "../../actions/trucks";

const initialState = {
  truckDriversCount: 0,
  truckDrivers: [],
  selectedTruckDriver: null,
  trucks: [],
  trucksCount: 0,
  selectedTruck: null,
};

const UserTrucksReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_DRIVERS:
      return {
        ...state,
        truckDriversCount: action.data.count,
        truckDrivers: [...state.truckDrivers, ...action.data.rows],
      };

    case RESET_USER_DRIVERS:
      return {
        ...state,
        truckDriversCount: 0,
        truckDrivers: [],
      };

    case SET_SELECTED_TRUCK_DRIVER:
      return { ...state, selectedTruckDriver: action.payload };

    case UPDATE_USER_TRUCKS:
      return {
        ...state,
        trucksCount: action.data.count,
        trucks: [...state.trucks, ...action.data.rows],
      };

    case RESET_USER_TRUCKS:
      return {
        ...state,
        trucksCount: 0,
        trucks: [],
      };

    case SET_SELECTED_TRUCK:
      return { ...state, selectedTruck: action.payload };

    default:
      return state;
  }
};

export default UserTrucksReducer;
