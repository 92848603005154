import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";

import FormikFastInput from "../../../../components/fts/formik/formik-fast-input";
import Divider from "../../../../components/fts/Divider";
import FormikMaskedInput from "../../../../components/fts/formik/masked-input";
import { phoneNumberMask } from "../../../../utility/masks";

import "../../../../assets/scss/components/fts/form-fields.scss";

class BusinessDetails extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      keys,
      editMode,
      // createMode,
      // updateListingFromEmail,
    } = this.props;

    return (
      <React.Fragment>
        <Divider textColor="primary" position="left">
          Contact info
        </Divider>
        <Row>
          <Col sm="12" md="6" lg="4" xl="3">
            <span
              className={`${
                keys && keys.includes("phoneNumber") && "field-changed"
              }`}
            >
              <FormikMaskedInput
                disabled={!editMode}
                name="phoneNumber"
                label="Primary phone number"
                labelId="phoneNumber"
                placeholder="Enter phone"
                required={true}
                mask={phoneNumberMask}
              />
            </span>
          </Col>
          <Col sm="12" md="6" lg="4" xl="3">
            <span
              className={`${
                keys && keys.includes("phoneNumberSecondary") && "field-changed"
              }`}
            >
              <FormikMaskedInput
                disabled={!editMode}
                name="phoneNumberSecondary"
                label="Secondary phone number"
                labelId="phoneNumberSecondary"
                placeholder="Secondary phone"
                mask={phoneNumberMask}
              />
            </span>
          </Col>
          <Col sm="12" md="6" lg="4" xl="3">
            <span
              className={`${
                keys && keys.includes("afterHours") && "field-changed"
              }`}
            >
              <FormikMaskedInput
                disabled={!editMode}
                name="afterHours"
                label="Afterhours phone"
                labelId="afterHours"
                placeholder="Enter phone"
                mask={phoneNumberMask}
              />
            </span>
          </Col>
          <Col sm="12" md="6" lg="4" xl="3">
            <span
              className={`${keys && keys.includes("fax") && "field-changed"}`}
            >
              <FormikMaskedInput
                disabled={!editMode}
                name="fax"
                label="Fax"
                labelId="fax"
                placeholder="Enter fax"
                mask={phoneNumberMask}
              />
            </span>
          </Col>
          <Col sm="12" md="6" lg="4" xl="6">
            <span
              className={`${keys && keys.includes("email") && "field-changed"}`}
            >
              <FormikFastInput
                disabled={!editMode}
                type="email"
                name="email"
                label="E-mail"
                labelId="email"
                placeholder="Enter e-mail"
              />
            </span>
          </Col>
        </Row>
        <Divider textColor="primary" position="left">
          Website links
        </Divider>
        <Row>
          <Col sm="12" md="6" lg="4" xl="4">
            <span
              className={`${
                keys && keys.includes("website") && "field-changed"
              }`}
            >
              <FormikFastInput
                disabled={!editMode}
                name="website"
                label="Website link"
                labelId="website-listing"
                placeholder="Enter website"
              />
            </span>
          </Col>
          <Col sm="12" md="6" lg="4" xl="3">
            <span
              className={`${
                keys && keys.includes("website2") && "field-changed"
              }`}
            >
              {editMode ? (
                <FormikFastInput
                  disabled={!editMode}
                  name="website2"
                  label="Website link #2"
                  labelId="website2-listing"
                  placeholder="Enter website"
                />
              ) : (
                <span className="undefined">
                  <div className="form-group">
                    <label htmlFor="website2-listing" className="disabled">
                      Website link #2
                    </label>
                    <div className="formik-input-value undefined">
                      {this.props.listingData?.website2 ? (
                        <a
                          href={`https://${this.props.listingData?.website2}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.props.listingData?.website2}
                        </a>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </span>
              )}
            </span>
          </Col>

          {/* {!createMode && !updateListingFromEmail && (
            <Col sm="12" md="6" lg="4" xl="3">
              <span
                className={`${keys && keys.includes("seoUrlIdentifier") && "field-changed"
                  }`}
              >
                <FormikFastInput
                  disabled
                  name="seoUrlIdentifier"
                  label="SEO URL"
                  labelId="seoUrlIdentifier"
                  placeholder="Enter SEO URL"
                  noMargin
                />
              </span>
            </Col>
          )} */}
        </Row>
      </React.Fragment>
    );
  }
}
export default BusinessDetails;
