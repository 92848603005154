import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { mapTypes } from "../constants";
import { MapTypeButtons } from "./MapTypeButtons";
import appConfig from "../../../../configs/appConfig";
import { useDispatch } from "react-redux";
import mapStyle from "../../../fts/fts-map/fts-map-style";
import mapPin from "@find-truck-service/ui/src/assets/mapButtons/pin.png";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";

const mapSizeStyle = {
  width: "100%",
  height: "100%",
};

export const ListingGoogleMap = ({
  isEditMode,
  listingData,
  setListingData,
}) => {
  const [selectedMapType, setSelectedMapType] = useState(mapTypes.ROADMAP);
  const dispatch = useDispatch();
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: appConfig.googleApiKey,
    LoadingContainer: EmptyComponent,
  });

  const onDragEnd = useCallback(
    ({ latLng }) => {
      dispatch(
        setListingData({
          data: {
            latitude: latLng.lat(),
            longitude: latLng.lng(),
          },
        }),
      );
    },
    [dispatch],
  );

  const center = {
    lat: parseFloat(listingData.latitude),
    lng: parseFloat(listingData.longitude),
  };

  const onLoad = useCallback((map) => {
    map.setOptions({
      zoom: 15,
      styles: mapStyle,
      clickableIcons: false,
      mapTypeControl: false,
    });
    setMap(map);
  }, []);

  useEffect(() => {
    if (map) map.setMapTypeId(selectedMapType);
  }, [map, selectedMapType]);

  if (!isLoaded) return <EmptyComponent />;
  return (
    <MapWrapper maxHeight={isEditMode ? "unset" : "0"}>
      <GoogleMap
        center={center}
        onLoad={onLoad}
        mapContainerStyle={mapSizeStyle}
      >
        <Marker
          draggable
          position={center}
          onDragEnd={onDragEnd}
          icon={{ url: mapPin }}
        />
        {isEditMode && (
          <MapTypeButtons
            selectedMapType={selectedMapType}
            setSelectedMapType={setSelectedMapType}
          />
        )}
      </GoogleMap>
    </MapWrapper>
  );
};

const MapWrapper = styled(FtsWrapper).attrs({
  width: "100%",
  borderRadius: "2xs",
  position: "relative",
  height: { xs: "504px", sm: "400px", md: "400px", lg: "560px" },
})``;
