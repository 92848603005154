import FTSAxios from "./fts.instance";

const collectEssentialData = (dispatch) => {
  const promises = [
    FTSAxios.get("search/amenities"),
    FTSAxios.get("search/service-amenities"),
    FTSAxios.get("user/categories"),
    FTSAxios.get("search/subcategories"),
    FTSAxios.get("search/todo/types"),
    FTSAxios.get("profile/states"),
    FTSAxios.get("/vendor/activity/getActivitiesTypes"),
    FTSAxios.get("/user/reputations"),
    FTSAxios.get("/experiences/all"),
    FTSAxios.get("/user/truck-types"),
    FTSAxios.get("/user-truck-types"),
    FTSAxios.get("rates"),
    FTSAxios.get("/user-types"),
    FTSAxios.get("admin/lists/ads-type"),
  ];

  return Promise.all(promises).then(
    ([
      amenities,
      serviceAmenities,
      categories,
      subCategories,
      types,
      states,
      // userStatuses,
      // leadsStatuses,
      // employees,
      // emailTypes,
      activitiesTypes,
      reputations,
      experiences,
      truckTypes,
      userTruckTypes,
      rates,
      userTypes,
      adsTypes,
    ]) => {
      dispatch({
        type: "UPDATE_AMENITIES_LIST",
        data: amenities.data.sort(({ name: nameA }, { name: nameB }) =>
          ("" + nameA).localeCompare(nameB),
        ),
      });
      dispatch({
        type: "UPDATE_SERVICE_AMENITIES_LIST",
        data: serviceAmenities.data.sort(({ name: nameA }, { name: nameB }) =>
          ("" + nameA).localeCompare(nameB),
        ),
      });
      // dispatch({
      //   type: "UPDATE_STATUSES_LIST",
      //   userStatuses: userStatuses.data,
      // });
      // dispatch({
      //   type: "UPDATE_LEADS_STATUSES_LIST",
      //   leadsStatuses: leadsStatuses.data,
      // });
      dispatch({
        type: "UPDATE_CATEGORIES_LIST",
        data: categories.data,
      });
      dispatch({
        type: "UPDATE_SUBCATEGORIES_LIST",
        data: subCategories.data,
      });
      dispatch({
        type: "UPDATE_TYPES_LIST",
        data: types.data,
      });
      dispatch({
        type: "UPDATE_STATES_LIST",
        data: states.data.map((st) => ({
          value: st.key,
          label: st.stateName,
        })),
      });
      dispatch({
        type: "UPDATE_ACTIVITIES_TYPES",
        data: activitiesTypes.data.activitiesTypes,
      });
      dispatch({
        type: "UPDATE_PERMISSIONS_LIST",
        data: activitiesTypes.data.activitiesTypes,
      });
      dispatch({
        type: "UPDATE_REPUTATIONS_LIST",
        data: reputations.data.reputations,
      });
      dispatch({
        type: "UPDATE_EXPERIENCES_LIST",
        data: experiences.data,
      });
      dispatch({
        type: "UPDATE_TRUCK_TYPES_LIST",
        data: truckTypes.data,
      });
      dispatch({
        type: "UPDATE_USER_TRUCK_TYPES_LIST",
        data: userTruckTypes.data,
      });
      dispatch({
        type: "UPDATE_RATES",
        data: rates.data,
      });
      dispatch({
        type: "UPDATE_USER_TYPES",
        data: userTypes.data,
      });
      dispatch({
        type: "GET_ADS_TYPES_DATA",
        data: adsTypes.data,
        totalPages: 1,
      });
    },
  );
};

export default collectEssentialData;
