import axios from "axios";
import { toast } from "react-toastify";
import { LISTING_STATUSES } from "../../../constants";
import { generateFileForUpload } from "../../../utility/uploadFile";
import { UPDATE_LISTING_ERROR_MSG, UPLOADING_ASSET_ERROR } from "../listing";
import { getDateFromTimestamp } from "../../../utility/getDateFromTimestamp";
import { all, call, put, select, take, takeLatest } from "redux-saga/effects";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";
import { FREE_LISTING_PRICE_ID } from "@find-truck-service/ui/src/components/react/ListingItem/constants";
import {
  selectTemporaryLinkEditListingData,
  selectTemporaryLinkListingPreviewData,
  selectTemporaryLinkListingProofId,
  selectTemporaryLinkProofListingData,
  TEMPORARY_LINK_APPROVE_PROOF_REQUEST,
  TEMPORARY_LINK_CHOOSE_LISTING_TYPE_REQUEST,
  TEMPORARY_LINK_GET_COMPETITORS_VENDORS_REQUEST,
  TEMPORARY_LINK_GET_DATA_REQUEST,
  TEMPORARY_LINK_GET_FEATURED_VENDORS_REQUEST,
  TEMPORARY_LINK_GET_LISTING_REQUEST,
  TEMPORARY_LINK_REJECT_PROOF_REQUEST,
  TEMPORARY_LINK_UPDATE_LISTING_REQUEST,
  TEMPORARY_LINK_UPLOAD_ADS_ASSETS_REQUEST,
} from "../../reducers/v2/temporaryListingLink";
import {
  getTemporaryLinkFeaturedVendorsFailed,
  getTemporaryLinkFeaturedVendorsRequest,
  getTemporaryLinkFeaturedVendorsSuccess,
  setTemporaryLinkActiveBanner,
  setTemporaryLinkAdsAssetForUpload,
  setTemporaryLinkHasActiveOrFreeListings,
  setTemporaryLinkListingData,
  setTemporaryLinkUserData,
  temporaryLinkApproveProofFailed,
  temporaryLinkApproveProofSuccess,
  temporaryLinkChooseListingTypeFailed,
  temporaryLinkChooseListingTypeSuccess,
  temporaryLinkGetCompetitorsFailed,
  temporaryLinkGetCompetitorsSuccess,
  temporaryLinkGetListingFailed,
  temporaryLinkGetListingRequest,
  temporaryLinkGetListingSuccess,
  temporaryLinkRejectProofFailed,
  temporaryLinkRejectProofSuccess,
  temporaryLinkUpdateListingFailed,
  temporaryLinkUpdateListingSuccess,
  temporaryLinkUploadAdsAssetsFailed,
  temporaryLinkUploadAdsAssetsSuccess,
} from "../../actions/v2/temporaryLinkListing";
import {
  formatListingDataPayload,
  formatListingResponse,
  handleErrorMessage,
  hasOnSuccessFunction,
} from "../listing/helpers";
import {
  selectAdsRates,
  selectAmenities,
  selectServiceAmenities,
  selectSubCategories,
} from "../../reducers/essentialLists";
import {
  bannerTitles,
  bannerTypes,
  bannerTypesIds,
} from "../../../constants/temporaryLinkBanners";
import {
  COLLECT_PUBLIC_ESSENTIALS_SUCCESS,
  collectPublicEssentialsRequest,
} from "../../actions/v2/essentials";
import logger from "../../../utility/logger";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";

const handleProofsBanner = (proofStatus, renewalTimestamp) => {
  const renewalTimestampDate = getDateFromTimestamp(renewalTimestamp);
  switch (proofStatus) {
    case PROOF_STATUSES_ENUM.APPROVED:
      return {
        type: bannerTypes[bannerTypesIds.PAID_LISTING_LIVE],
        title: `${bannerTitles[bannerTypesIds.PAID_LISTING_LIVE]}${renewalTimestampDate}.`,
      };
    case PROOF_STATUSES_ENUM.PENDING:
      return null;
    default:
      return {
        type: bannerTypes[bannerTypesIds.LISTING_LIVE_WAITING_PROOF],
        title: bannerTitles[bannerTypesIds.LISTING_LIVE_WAITING_PROOF],
      };
  }
};

const handleFreeListingBanners = (status) => {
  if (status === LISTING_STATUSES.ACTIVE) return null;
  return {
    type: bannerTypes[bannerTypesIds.FREE_LISTING_AWAITING_APPROVAL],
    title: bannerTitles[bannerTypesIds.FREE_LISTING_AWAITING_APPROVAL],
  };
};

const getActiveBanner = ({ invoices, listing, proof }) => {
  const { priceId, status, renewalTimestamp } = listing;
  const isFree = priceId === FREE_LISTING_PRICE_ID;

  if (isFree) return handleFreeListingBanners(status);

  //paid listing
  if (proof || invoices?.[0]?.payments?.length)
    return handleProofsBanner(
      proof?.status,
      renewalTimestamp,
      invoices?.[0]?.payments?.length,
    );
};

function* getListingByTemporaryTokenSaga() {
  try {
    const rates = yield select(selectAdsRates);
    const amenities = yield select(selectAmenities);
    const serviceAmenities = yield select(selectServiceAmenities);

    const FETCH_URL = "/ads/listing-details/temporary-token";
    const response = yield call(FTSAxiosTemporaryLink.get, FETCH_URL);
    const { invoices, proof, ...listingData } = response.data.data;

    const listing = formatListingResponse(
      listingData,
      rates,
      amenities,
      serviceAmenities,
    );

    let proofListingData = null;
    if (proof?.listingData) {
      listing.proofStatus = proof.status;
      proofListingData = formatListingResponse(
        JSON.parse(proof?.listingData),
        rates,
        amenities,
        serviceAmenities,
      );
    }
    const data = { listing, invoices, proof, proofListingData };

    const activeBanner = getActiveBanner(data);
    yield put(setTemporaryLinkActiveBanner({ data: activeBanner }));
    yield put(temporaryLinkGetListingSuccess({ data }));
  } catch (e) {
    logger.error(e);
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;

    yield put(
      temporaryLinkGetListingFailed({ data: { message, inlineMessage } }),
    );
  }
}

function* getTemporaryLinkDataSaga() {
  try {
    const FETCH_USER_URL = "/users/get-by-hash";

    const { data: responseUserData } = yield call(
      FTSAxiosTemporaryLink.get,
      FETCH_USER_URL,
    );

    const { data: countActiveAndFreeResponse } = yield call(
      FTSAxiosTemporaryLink.get,
      "/ads/count/active-and-free",
    );

    yield put(
      setTemporaryLinkHasActiveOrFreeListings({
        data: !!countActiveAndFreeResponse.data?.listingsCount,
      }),
    );

    yield all([
      put(getTemporaryLinkFeaturedVendorsRequest()),
      put(collectPublicEssentialsRequest()),
      take(COLLECT_PUBLIC_ESSENTIALS_SUCCESS),
    ]);

    yield put(temporaryLinkGetListingRequest());
    yield put(setTemporaryLinkUserData({ data: responseUserData.data }));
  } catch (e) {
    logger.error(e);
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;

    yield put(
      temporaryLinkGetListingFailed({ data: { message, inlineMessage } }),
    );
  }
}

const UPDATE_LISTING_SUCCESS_MSG = "Listing updated successfully";

function* updateListingByTemporaryTokenSaga(action) {
  try {
    const rates = yield select(selectAdsRates);
    const amenities = yield select(selectAmenities);
    const subcategories = yield select(selectSubCategories);
    const serviceAmenities = yield select(selectServiceAmenities);
    const listingData = yield select(selectTemporaryLinkEditListingData);

    const UPDATE_URL = "/ads/listing-details/temporary-token";
    const payload = formatListingDataPayload(listingData, rates, subcategories);

    const response = yield call(FTSAxiosTemporaryLink.put, UPDATE_URL, payload);

    const updatedListing = response.data.data;
    const data = formatListingResponse(
      updatedListing,
      rates,
      amenities,
      serviceAmenities,
    );

    yield put(temporaryLinkUpdateListingSuccess({ data: data }));
    toast.success(UPDATE_LISTING_SUCCESS_MSG);
    if (hasOnSuccessFunction(action))
      action.payload.onSuccess(updatedListing.id);
  } catch (e) {
    const message = handleErrorMessage(e, UPDATE_LISTING_ERROR_MSG);
    const inlineMessage = e?.response?.data?.inlineMessage;
    toast.error(message);
    yield put(
      temporaryLinkUpdateListingFailed({ data: { message, inlineMessage } }),
    );
  }
}

function* chooseListingTypeByTemporaryTokenSaga(action) {
  const { data: payloadData, onSuccess, onError } = action.payload;

  try {
    const listingData = yield select(selectTemporaryLinkListingPreviewData);

    const CREATE_INVOICE_URL =
      "/invoices/create-listing-invoice/temporary-link";
    const UPDATE_LISTING_URL = "/ads/listing-details/temporary-token";

    if (listingData?.priceId !== payloadData?.priceId) {
      yield call(FTSAxiosTemporaryLink.put, UPDATE_LISTING_URL, {
        listingData: { priceId: payloadData.priceId },
      });
    }

    const { data: responseData } = yield call(
      FTSAxiosTemporaryLink.post,
      CREATE_INVOICE_URL,
      payloadData,
    );

    yield put(
      temporaryLinkChooseListingTypeSuccess({ data: responseData.data }),
    );

    if (typeof onSuccess === "function") onSuccess();
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;

    yield put(
      temporaryLinkChooseListingTypeFailed({
        data: { message, inlineMessage },
      }),
    );

    if (typeof onError === "function") onError(e);
  }
}

function* temporaryLinkUploadAdsAssetRequestSaga(action) {
  try {
    const listingData = yield select(selectTemporaryLinkEditListingData);
    const filesForUpload = [];

    for (let file of action.payload.data) {
      const fileForUpload = yield generateFileForUpload(file, listingData);
      yield put(
        setTemporaryLinkAdsAssetForUpload({ data: fileForUpload.adsAsset }),
      );
      filesForUpload.push(fileForUpload);
    }

    for (let fileForUpload of filesForUpload) {
      const uploadConfig = fileForUpload.uploadConfig;
      const filePath = fileForUpload.fileName;
      const mimeType = fileForUpload.type;
      const blob = fileForUpload.blob;
      const params = { params: { filePath, mimeType } };
      const UPLOAD_URL = "/ads/images/image-upload-url/temporary-token";

      try {
        const response = yield call(
          FTSAxiosTemporaryLink.get,
          UPLOAD_URL,
          params,
        );
        const uploadUrl = response.data.data.uploadUrl;
        yield call(axios.put, uploadUrl, blob, uploadConfig);
        yield put(temporaryLinkUploadAdsAssetsSuccess({ data: filePath }));
      } catch (e) {
        handleErrorMessage(e, UPLOADING_ASSET_ERROR);
        yield put(temporaryLinkUploadAdsAssetsFailed({ data: filePath }));
      }
    }
  } catch (e) {
    handleErrorMessage(e, UPLOADING_ASSET_ERROR);
  }
}
function* temporaryLinkGetFeaturedVendorsRequestSaga() {
  try {
    const FEATURED_VENDORS_URL = "/ads/featured-vendors/list";
    const { data: responseData } = yield call(
      FTSAxiosTemporaryLink.get,
      FEATURED_VENDORS_URL,
    );

    yield put(
      getTemporaryLinkFeaturedVendorsSuccess({ data: responseData.data }),
    );
  } catch (e) {
    const message = e?.response
      ? e.response?.data?.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;

    yield put(
      getTemporaryLinkFeaturedVendorsFailed({
        data: { message, inlineMessage },
      }),
    );
  }
}

function* temporaryLinkApproveProofSaga({ payload: { onSuccess } }) {
  try {
    const rates = yield select(selectAdsRates);
    const subcategories = yield select(selectSubCategories);
    const proofId = yield select(selectTemporaryLinkListingProofId);
    const proofListingData = yield select(selectTemporaryLinkProofListingData);

    const APPROVE_PROOF_URL = `/proofs/approve`;
    const approvedListingData = formatListingDataPayload(
      proofListingData,
      rates,
      subcategories,
    );

    const payload = { id: proofId, approvedListingData };

    yield call(FTSAxiosTemporaryLink.put, APPROVE_PROOF_URL, payload);
    yield put(setTemporaryLinkListingData({ data: proofListingData }));
    yield put(temporaryLinkApproveProofSuccess());
    onSuccess();
  } catch (e) {
    const message = e?.response
      ? e.response?.data?.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;

    toast.error(message);
    yield put(
      temporaryLinkApproveProofFailed({
        data: { message, inlineMessage },
      }),
    );
  }
}

function* temporaryLinkRejectProofSaga({ payload: { data, onSuccess } }) {
  try {
    const proofId = yield select(selectTemporaryLinkListingProofId);
    const REJECT_PROOF_URL = `/proofs/reject`;
    const payload = { id: proofId, ...data };

    yield call(FTSAxiosTemporaryLink.put, REJECT_PROOF_URL, payload);
    yield put(temporaryLinkRejectProofSuccess());
    onSuccess();
  } catch (e) {
    const message = e?.response
      ? e.response?.data?.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;

    toast.error(message);
    yield put(
      temporaryLinkRejectProofFailed({
        data: { message, inlineMessage },
      }),
    );
  }
}

function* temporaryLinkGetCompetitorsSaga(action) {
  try {
    const { data } = yield call(
      FTSAxiosTemporaryLink.get,
      "/ads/temporary-token/competitors/list",
      { params: action.payload.data },
    );
    yield put(temporaryLinkGetCompetitorsSuccess({ data: data.data }));
  } catch (e) {
    const message = e?.response
      ? e.response?.data?.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(
      temporaryLinkGetCompetitorsFailed({ data: { message, inlineMessage } }),
    );
  }
}

export default function* temporaryLinkListingWatcher() {
  yield takeLatest(
    TEMPORARY_LINK_GET_LISTING_REQUEST,
    getListingByTemporaryTokenSaga,
  );
  yield takeLatest(TEMPORARY_LINK_GET_DATA_REQUEST, getTemporaryLinkDataSaga);
  yield takeLatest(
    TEMPORARY_LINK_UPDATE_LISTING_REQUEST,
    updateListingByTemporaryTokenSaga,
  );
  yield takeLatest(
    TEMPORARY_LINK_CHOOSE_LISTING_TYPE_REQUEST,
    chooseListingTypeByTemporaryTokenSaga,
  );
  yield takeLatest(
    TEMPORARY_LINK_UPLOAD_ADS_ASSETS_REQUEST,
    temporaryLinkUploadAdsAssetRequestSaga,
  );

  yield takeLatest(
    TEMPORARY_LINK_GET_FEATURED_VENDORS_REQUEST,
    temporaryLinkGetFeaturedVendorsRequestSaga,
  );

  yield takeLatest(
    TEMPORARY_LINK_APPROVE_PROOF_REQUEST,
    temporaryLinkApproveProofSaga,
  );

  yield takeLatest(
    TEMPORARY_LINK_REJECT_PROOF_REQUEST,
    temporaryLinkRejectProofSaga,
  );
  yield takeLatest(
    TEMPORARY_LINK_GET_COMPETITORS_VENDORS_REQUEST,
    temporaryLinkGetCompetitorsSaga,
  );
}
