import React, { useState } from "react";
import { validateCardNumber } from "@find-truck-service/types/validators/global";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { FtsColumn } from "../../../FtsColumn/FtsColumn";
import { FtsRow } from "../../../FtsRow/FtsRow";
import { Span } from "../../../FtsText";
import Checkbox from "../Checkbox";
import { Input } from "../Input";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { addGapsToNumber, cleanCardNumberInput, formatExpirationDate, getErrorMsg } from "./helpers";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
export const ClientCreditCardForm = (props) => {
    const { closeCardForm, cardData, onSubmit, handleOnChange, title, submitButtonTitle, invalidFormFields = [], handleCheckboxChange, inlineMessage, } = props;
    const [inputLength, setInputLength] = useState(16);
    const [cvvLength, setCvvLength] = useState(3);
    const onNameChange = (event) => handleOnChange("cardHolderName", event.target.value);
    const onCardNumberChange = (event) => {
        const cardNumber = cleanCardNumberInput(event);
        const card = validateCardNumber(cardNumber);
        if (!card) {
            event.target.value = cardNumber;
            return handleOnChange("cardNumber", event.target.value);
        }
        const formattedValue = addGapsToNumber(cardNumber, card.gaps ?? []);
        const formattedLength = card.lengths[0] + card.gaps.length;
        if (formattedLength !== inputLength)
            setInputLength(formattedLength);
        if (card.code.size !== cvvLength)
            setCvvLength(card.code.size);
        event.target.value = formattedValue;
        handleOnChange("cardNumber", event.target.value);
    };
    const onExpirationDateChange = (event) => {
        event.target.value = formatExpirationDate(event.target.value);
        handleOnChange("cardExpirationDate", event.target.value);
    };
    const onCvvChange = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, "");
        handleOnChange("cardCvc", event.target.value);
    };
    return (React.createElement(FtsColumn, { size: { xs: 12 }, borderRadius: "2xs", py: "2xl", px: "2xl", border: `${Spacing["4xs"]} solid ${ColorsValue["blue-accent-300"]}` },
        React.createElement(FtsRow, { columnGap: "lg", rowGap: "2xl" },
            React.createElement(FtsColumn, { size: { xs: 12 } },
                React.createElement(Span, { variant: VariantsValues["text-xs"], color: ColorsValue["greyscale-800"] }, title),
                React.createElement(FtsWrapper, { display: "flex", gap: "sm", mt: "md" },
                    React.createElement(FtsIcons, { iconName: IconNames.Mastercard }),
                    React.createElement(FtsIcons, { iconName: IconNames.Visa }),
                    React.createElement(FtsIcons, { iconName: IconNames.Amex }),
                    React.createElement(FtsIcons, { iconName: IconNames.Discover }))),
            inlineMessage && (React.createElement(FtsColumn, { size: { xs: 12 } },
                React.createElement(Message, { type: "error", title: inlineMessage, subtitle: "" }))),
            React.createElement(FtsColumn, { size: { xs: 12 } },
                React.createElement(Input, { maxLength: inputLength, name: "cardNumber", value: cardData.cardNumber, onChange: onCardNumberChange, label: "Card number", variant: InputVariants.outlined, size: InputSizes.sm, errorMessage: getErrorMsg(invalidFormFields, "cardNumber"), invalid: !!Object.keys(invalidFormFields).find((e) => e === "cardNumber") })),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(Input, { maxLength: 5, name: "cardExpirationDate", value: cardData.cardExpirationDate, onChange: onExpirationDateChange, label: "Expiration date", variant: InputVariants.outlined, size: InputSizes.sm, errorMessage: getErrorMsg(invalidFormFields, "cardExpirationDate"), invalid: !!Object.keys(invalidFormFields).find((e) => e === "cardExpirationDate") })),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(Input, { name: "cardCvc", value: cardData.cardCvc, onChange: onCvvChange, label: "CVV", maxLength: cvvLength, variant: InputVariants.outlined, size: InputSizes.sm, errorMessage: getErrorMsg(invalidFormFields, "cardCvc"), invalid: !!Object.keys(invalidFormFields).find((e) => e === "cardCvc") })),
            React.createElement(FtsColumn, { size: { xs: 12 } },
                React.createElement(Input, { name: "cardHolderName", value: cardData.cardHolderName, onChange: onNameChange, label: "Cardholder name", variant: InputVariants.outlined, size: InputSizes.sm, errorMessage: getErrorMsg(invalidFormFields, "cardHolderName"), invalid: !!Object.keys(invalidFormFields).find((e) => e === "cardHolderName") })),
            React.createElement(FtsColumn, { size: { xs: 12 }, display: "flex" },
                React.createElement(Checkbox, { size: CheckboxSizes.sm, checked: cardData.isDefault, label: "Set as default payment", onClick: () => handleCheckboxChange("isDefault", cardData.isDefault) })),
            React.createElement(FtsColumn, { size: { xs: 12 }, display: "flex", justify: "flex-end" },
                closeCardForm && (React.createElement(Button, { onClick: closeCardForm, size: ButtonSizes.sm, type: ButtonTypes.outlined, color: ButtonColorsValue.accent, mr: "lg", label: "Cancel" })),
                React.createElement(Button, { onClick: onSubmit, type: ButtonTypes.contained, color: ButtonColorsValue.accent, size: ButtonSizes.sm, label: submitButtonTitle })))));
};
