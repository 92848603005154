import React, { useCallback, useState } from "react";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { Collapsible } from "@find-truck-service/ui/src/components/react/Collapsible";
import { PasswordValidator } from "@find-truck-service/ui/src/components/react/PasswordValidator";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { useDispatch, useSelector } from "react-redux";

import {
  selectReactivateAccountData,
  selectReactivateAccountInvalidFormFields,
  selectReactivateAccountIsLoading,
} from "../../../redux/reducers/v2/reactivateAccount";
import {
  reactivateAccountRequest,
  setReactivateAccountData,
  setReactivateAccountInvalidFormFields,
} from "../../../redux/actions/v2/reactivateAccount";
import { isObject } from "lodash";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { newPasswordValidator } from "./validators";
import { toast } from "react-toastify";
import { history } from "../../../history";
import { selectShareProviderData } from "../../../redux/reducers/v2/shareProvider";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import Success from "@find-truck-service/ui/src/components/react/Success/Success";
import { selectUserData } from "redux/reducers/v2/auth";
import { SHARE_PROVIDER_STATUSSES } from "../../../constants/index";
import logger from "../../../utility/logger";
import { SESSION_STORAGE_KEYS } from "../../../utility/constants";

const NewPassword = () => {
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector(selectReactivateAccountIsLoading);
  const reactivateAccountData = useSelector(selectReactivateAccountData);
  const invalidFormFields = useSelector(
    selectReactivateAccountInvalidFormFields,
  );
  const shareProvider = useSelector(selectShareProviderData);
  const userData = useSelector(selectUserData);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const onChange = useCallback((event) => {
    dispatch(
      setReactivateAccountData({
        data: { [event.target.name]: event.target.value },
      }),
    );
  }, []);

  const onReactivateAccountSuccess = () => {
    toast.success("Password updated successfully!");
    history.push("/dashboard");
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.TEMPORARY_TOKEN);
  };

  const onReactivateAccountFailure = (e) => {
    const message = e.response
      ? e.response.data.message
      : "Something went wrong!";

    toast.error(message);
  };

  const handleReactivateAccount = async () => {
    try {
      await newPasswordValidator.validate(reactivateAccountData || {});

      dispatch(
        setReactivateAccountInvalidFormFields({
          data: null,
        }),
      );
      dispatch(
        reactivateAccountRequest({
          data: {
            newPassword: reactivateAccountData?.newPassword,
          },
          onSuccess: onReactivateAccountSuccess,
          onError: onReactivateAccountFailure,
        }),
      );
    } catch (e) {
      logger.error(e);
      if (e.name === "ValidationError") {
        dispatch(
          setReactivateAccountInvalidFormFields({
            data: {
              [e.path]: e.errors[0],
            },
          }),
        );
      }
    }
  };

  if (!shareProvider) {
    return <EmptyComponent />;
  }

  // TODO: Temporary Token interceptor needs to be finished & removeAllCookies removed from router in order for this to work properly
  if (
    shareProvider?.status === SHARE_PROVIDER_STATUSSES.DISABLED &&
    shareProvider?.userId === userData?.id
  ) {
    return (
      <Success
        title={"Account Already Reactivated"}
        subtitle={
          "Your account is already active and ready to use! You can now go to dashboard and pick up right where you left off."
        }
        iconName="Check"
        containedButton={{
          label: "Dashboard",
          color: ButtonColorsValue.accent,
          onClick: () => {
            history.push("/dashboard");
          },
        }}
      />
    );
  }

  if (shareProvider?.status === SHARE_PROVIDER_STATUSSES.DISABLED) {
    return (
      <Success
        title={"Account Already Reactivated"}
        subtitle={
          "Your account is already active and ready to use! You can now log in and pick up right where you left off."
        }
        iconName="Check"
        containedButton={{
          label: "Login",
          color: ButtonColorsValue.accent,
          onClick: () => {
            history.push("/login");
          },
        }}
      />
    );
  }

  return (
    <FtsContainer>
      {isLoading && <FullPageLoader />}
      <FtsRow mt={{ lg: "4xl", md: "4xl", sm: "2xl", xs: "0" }}>
        <FtsColumn
          size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
          offset={{ xs: 0, sm: 0, md: 0, lg: 3 }}
          columnGap={"2xl"}
        >
          <FtsWrapper
            p={{
              xs: "2xl",
              md: "4xl",
              lg: "4xl",
            }}
            bg={ColorsValue["greyscale-0"]}
            boxShadow="sm"
            borderRadius="2xs"
          >
            <FtsColumn
              size={{ xs: 12, md: 12, lg: 12 }}
              columnGap={"2xl"}
              pb={"sm"}
            >
              <Span
                variant={VariantsValues["text-md"]}
                color={ColorsValue["greyscale-800"]}
                weight={WeightSize.bold}
              >
                New Password
              </Span>
            </FtsColumn>
            <FtsColumn
              size={{ xs: 12, md: 12, lg: 12 }}
              columnGap={"2xl"}
              pb={{ xs: "2xl", md: "3xl", lg: "4xl" }}
            >
              <Span
                variant={VariantsValues["text-2xs"]}
                color={ColorsValue["greyscale-800"]}
                weight={WeightSize.regular}
              >
                Enter a new password to reactivate your account
              </Span>
            </FtsColumn>

            <FtsColumn size={{ xs: 12, md: 12, lg: 12 }} pb={"lg"}>
              <Input
                name={"newPassword"}
                onChange={onChange}
                label={"Password"}
                required
                variant={InputVariants.outlined}
                value={reactivateAccountData?.newPassword}
                size={InputSizes.md}
                appendIcon={IconNames.EyeIcon}
                onAppendIconClick={toggleShowPassword}
                type={showPassword ? "text" : "password"}
                errorMessage={
                  isObject(invalidFormFields) &&
                  invalidFormFields["newPassword"]
                }
              />
            </FtsColumn>

            <FtsColumn size={{ xl: 12, md: 12, sm: 12 }} mb={"2xl"}>
              <Collapsible show={true}>
                <PasswordValidator
                  password={reactivateAccountData?.newPassword || ""}
                />
              </Collapsible>
            </FtsColumn>

            <FtsWrapper
              display={{ xs: "none", sm: "grid", md: "grid", lg: "grid" }}
            >
              <FtsRow columnGap={"lg"}>
                <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                  <Button
                    size={ButtonSizes.md}
                    color={ButtonColorsValue.accent}
                    type={ButtonTypes.contained}
                    label={"Save"}
                    width={"100%"}
                    onClick={() => handleReactivateAccount()}
                  />
                </FtsColumn>
              </FtsRow>
            </FtsWrapper>
          </FtsWrapper>
          <FtsWrapper
            display={{ xs: "grid", sm: "none", md: "none", lg: "none" }}
          >
            <FtsRow columnGap={"lg"} p={"xl"}>
              <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                <Button
                  size={ButtonSizes.md}
                  color={ButtonColorsValue.accent}
                  type={ButtonTypes.contained}
                  label={"Save"}
                  width={"100%"}
                  onClick={() => handleReactivateAccount()}
                />
              </FtsColumn>
            </FtsRow>
          </FtsWrapper>
        </FtsColumn>
      </FtsRow>
    </FtsContainer>
  );
};

export default NewPassword;
