import React, { useCallback, useEffect, useState } from "react";
import { FtsContainer } from "../dist/ui/src/FtsContainer/FtsContainer";
import { FtsWrapper } from "../dist/ui/src/FtsWrapper/FtsWrapper";
import { FtsRow } from "../dist/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "../dist/ui/src/FtsColumn/FtsColumn";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { MessageInline } from "@find-truck-service/ui/src/components/react/MessageInline";
import VerifyEmailActionsXs from "./components/VerifyEmailActionsXs";
import VerifyEmailActionsLg from "./components/VerifyEmailActionsLg";
import { useDispatch, useSelector } from "react-redux";
import { history, historyRefresh } from "../../../history";
import {
  resendVerifyEmailAddressRequest,
  verifyEmailAddressFailed,
  verifyEmailAddressRequest,
} from "../../../redux/actions/v2/auth";
import {
  selectAuthError,
  selectAuthInlineMessage,
  selectAuthIsLoading,
  selectUserData,
} from "../../../redux/reducers/v2/auth";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import * as Yup from "yup";
import { extractParameter } from "../../../utility/param-extract";
import { HeaderWrapper } from "../HeaderWrapper";
import FTSAxios from "../../../axios/fts.instance";
import { toast } from "react-toastify";
import { Pages } from "../../../views/constants/pages";
import { LAST_VISITED_ROUTE, removeAllCookies } from "../../../cookies";

export const verifyEmailAddressCodeValidator = Yup.object().shape({
  code: Yup.string()
    .test("len", "Required", (val) => val.length === 3)
    .required("Required"),
});

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAuthIsLoading);
  const userData = useSelector(selectUserData);
  const inlineMessage = useSelector(selectAuthInlineMessage);
  const error = useSelector(selectAuthError);
  const [code, setCode] = useState(["", "", ""]);

  useEffect(() => {
    const token = extractParameter("token");
    if (token) {
      (async () => {
        try {
          await FTSAxios.post(`/verify-email/${token}`);

          window.location.href = "/dashboard/on-boarding";
          toast.success("Email verified successfully.");
        } catch (e) {
          toast.error("An error occurred. Please try again.");
        }
      })();
    }
    if (!token && !userData) {
      history.push("/dashboard/login");
    }
  }, []);

  const handleChange = (e, index) => {
    const value = e.target.value;
    const isCodeFull = !!code[code.length - 1] && !!value;
    if (isNaN(value) || isCodeFull) return;

    if (value.length > 1) {
      let newCode = value
        .toString()
        .split("")
        .map((f) => f)
        .splice(0, 3);

      setCode(newCode);
      const element = document.getElementById(`code-input-${code.length - 1}`);
      const input = element.querySelector("input");
      if (input) {
        input.focus();
      }
      return;
    }
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < 2) {
      const element = document.getElementById(`code-input-${index + 1}`);
      const input = element.querySelector("input");
      if (input) {
        input.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      const element = document.getElementById(`code-input-${index - 1}`);
      const input = element.querySelector("input");
      if (input) {
        input.focus();
      }
    }
  };

  const onSuccess = useCallback(() => {
    const redirectTo = extractParameter("redirectTo");
    if (redirectTo) return history.push(redirectTo);
    return history.push("/dashboard");
  }, [userData]);

  const handleVerifyEmail = useCallback(async () => {
    try {
      const codeValue = code.join("");
      await verifyEmailAddressCodeValidator.validate({ code: codeValue });
      dispatch(
        verifyEmailAddressRequest({ data: { code: codeValue }, onSuccess }),
      );
    } catch (e) {
      if (e.name === "ValidationError") {
        dispatch(
          verifyEmailAddressFailed({
            data: {
              message: "",
              inlineMessage: "Required",
            },
          }),
        );
      }
    }
  }, [code]);
  const handleResendVerifyEmail = useCallback(() => {
    dispatch(resendVerifyEmailAddressRequest());
  }, [code]);

  const onLogin = useCallback(async () => {
    sessionStorage.removeItem(LAST_VISITED_ROUTE);
    await removeAllCookies();
    historyRefresh.push(Pages.LOGIN);
  }, []);

  if (!userData) return <FullPageLoader />;
  return (
    <>
      <HeaderWrapper />
      <FtsContainer>
        {isLoading && <FullPageLoader />}
        <FtsWrapper mt={"2xl"}>
          <FtsRow>
            <FtsColumn
              size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
              offset={{ xs: 0, sm: 0, md: 0, lg: 3 }}
            >
              <FtsWrapper
                display="flex"
                direction="column"
                gap="2xl"
                bg={ColorsValue["greyscale-0"]}
                borderRadius="2xs"
                boxShadow="sm"
                py={{ xs: "xl", sm: "4xl", md: "4xl", lg: "4xl" }}
                px={{ xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" }}
              >
                {error && (
                  <Message type={"error"} title={""} subtitle={error} />
                )}

                <Span
                  variant={VariantsValues["text-md"]}
                  color={ColorsValue["greyscale-800"]}
                  weight="bold"
                >
                  Verify Your Email Address
                </Span>
                <FtsWrapper display="flex" direction="column">
                  <Span
                    variant={VariantsValues["text-2xs"]}
                    color={ColorsValue["greyscale-500"]}
                  >
                    A verification code has been sent to:
                  </Span>
                  <Span
                    variant={VariantsValues["text-2xs"]}
                    color={ColorsValue["greyscale-800"]}
                    weight="bold"
                  >
                    {userData.email}
                  </Span>
                  <hr style={{ width: "100%" }} />
                  <Span
                    variant={VariantsValues["text-2xs"]}
                    color={ColorsValue["greyscale-500"]}
                  >
                    Please check your inbox and enter the verification code
                    below to verify your email address. The code will expire in
                    24 hour.
                  </Span>
                  <Span
                    variant={VariantsValues["text-2xs"]}
                    color={ColorsValue["greyscale-800"]}
                    mt="xl"
                  >
                    Don’t see the email? Please check your spam/junk folder.
                  </Span>
                </FtsWrapper>
                <FtsWrapper
                  display="flex"
                  justify="center"
                  direction="column"
                  align="center"
                  gap="md"
                >
                  <FtsWrapper display="flex" gap="md">
                    {code.map((digit, index) => {
                      return (
                        <FtsWrapper width="48px" key={index}>
                          <Input
                            invalid={!!inlineMessage}
                            name={""}
                            id={`code-input-${index}`}
                            variant={InputVariants.outlined}
                            size={InputSizes.md}
                            value={digit}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            textAlign="center"
                            maxlength={1}
                          />
                        </FtsWrapper>
                      );
                    })}
                  </FtsWrapper>
                  {inlineMessage && (
                    <MessageInline
                      size="md"
                      message={inlineMessage}
                      type="error"
                    />
                  )}
                </FtsWrapper>
                <VerifyEmailActionsLg
                  onResendCode={handleResendVerifyEmail}
                  onVerify={handleVerifyEmail}
                  onLogin={onLogin}
                />
              </FtsWrapper>
            </FtsColumn>
          </FtsRow>
          <VerifyEmailActionsXs
            onResendCode={handleResendVerifyEmail}
            onVerify={handleVerifyEmail}
            onLogin={onLogin}
          />
        </FtsWrapper>
      </FtsContainer>
    </>
  );
};

export default VerifyEmail;
