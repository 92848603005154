import { useHistory } from "react-router-dom";

export function useScrollToTopAndNavigate() {
  const history = useHistory();

  return (pathname, search = "") => {
    window.scrollTo(0, 0);
    history.push({ pathname, search });
  };
}
