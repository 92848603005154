import { createSelector } from "reselect";

export const SET_RESET_PASSWORD_DATA = "RESET_PASSWORD/SET_RESET_PASSWORD_DATA";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD/RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_FAILED =
  "RESET_PASSWORD/RESET_PASSWORD_REQUEST_FAILED";
export const RESET_PASSWORD_REQUEST_SUCCESS =
  "RESET_PASSWORD/RESET_PASSWORD_REQUEST_SUCCESS";
export const SET_RESET_PASSWORD_INVALID_FORM_FIELDS =
  "RESET_PASSWORD/SET_RESET_PASSWORD_INVALID_FORM_FIELDS";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  inlineMessage: null,
  invalidFormFields: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_RESET_PASSWORD_DATA:
      return {
        ...state,
        invalidFormFields: null,
        data: { ...state.data, ...action.payload.data },
      };

    case SET_RESET_PASSWORD_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: action.payload.data,
      };

    case RESET_PASSWORD_REQUEST:
      return { ...state, isLoading: true };

    case RESET_PASSWORD_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data.message,
        inlineMessage: action.payload.data.inlineMessage,
      };

    case RESET_PASSWORD_REQUEST_SUCCESS:
      return { ...initialState, isLoading: false };

    default:
      return state;
  }
}

const selectResetPasswordState = (state) => state.resetPasswordState;

export const selectResetPasswordData = createSelector(
  selectResetPasswordState,
  (resetPasswordState) => resetPasswordState.data,
);

export const selectResetPasswordIsLoading = createSelector(
  selectResetPasswordState,
  (resetPasswordState) => resetPasswordState.isLoading,
);

export const selectResetPasswordError = createSelector(
  selectResetPasswordState,
  (resetPasswordState) => resetPasswordState.error,
);

export const selectResetPasswordInlineMessage = createSelector(
  selectResetPasswordState,
  (resetPasswordState) => resetPasswordState.inlineMessage,
);

export const selectResetPasswordInvalidFormFields = createSelector(
  selectResetPasswordState,
  (resetPasswordState) => resetPasswordState.invalidFormFields,
);
