import React from "react";
import { SearchNoResults } from "./SearchNoResults";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";

export const AmenitiesDesktop = (props) => {
  const {
    searchString,
    hasAmenities,
    selectedAmenities,
    onChangeAmenities,
    filteredAmenities,
  } = props;

  return (
    <FtsColumn
      size={{ xs: 12 }}
      display={{ xs: "none", sm: "block", md: "block", lg: "block" }}
    >
      <FtsRow columnGap={"lg"} rowGap={"lg"} width={"100%"}>
        {!hasAmenities && <SearchNoResults searchString={searchString} />}
        {hasAmenities &&
          filteredAmenities.map((e) => {
            const checked = !!selectedAmenities?.find(
              (f) => f.amenityId === e.id,
            );
            return (
              <FtsColumn key={e.id} size={{ xs: 12, md: 6, lg: 4 }}>
                <Checkbox
                  size={CheckboxSizes.md}
                  onClick={() => onChangeAmenities(e.id, !checked, e.name)}
                  checked={checked}
                  label={e.name}
                />
              </FtsColumn>
            );
          })}
      </FtsRow>
    </FtsColumn>
  );
};
