import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const activeBgLinkStyle = `
  background-color: ${ColorsValue["red-brand-700"]};
`;
export const activeOutlineStyle = `
  outline: none;
`;
export const bgLinkStyle = `
  background-color: transparent;
  &:focus, &:focus-visible { background-color: transparent; }
  &:hover { background-color: ${ColorsValue["red-brand-600"]}}
  &:active { ${activeBgLinkStyle} }
`;
export const outlineLinkStyle = `
  outline: none;
  &:focus-visible { outline: ${Spacing["3xs"]} solid ${ColorsValue["red-brand-200"]}; }
  &:active { ${activeOutlineStyle} }
`;
export const linkStyle = `
  border-radius: ${Spacing["2xs"]};
  align-items: center;
  display: flex;
`;
