import React from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useScrollToTopAndNavigate } from "../../../../hooks";
import { TemporaryLinkPages } from "../../../constants/pages";
import { CHOOSE_LISTING_TYPE_ERROR } from "../../../../redux/sagas/listing";
import { handleErrorMessage } from "../../../../redux/sagas/listing/helpers";
import { ButtonsFooter } from "@find-truck-service/ui/src/components/react/ButtonsFooter";
import {
  selectTemporaryLinkListingInitialPrice,
  selectTemporaryLinkListingPreviewData,
  selectTemporaryLinkListingSelectedPrice,
} from "../../../../redux/reducers/v2/temporaryListingLink";
import {
  temporaryLinkChooseListingTypeRequest,
  setTemporaryLinkEditListingData,
  setTemporaryLinkShowFreeListingModalNotice,
  setTemporaryLinkListingData,
} from "../../../../redux/actions/v2/temporaryLinkListing";
import { ADS_PRICES_ENUM } from "@find-truck-service/types/constants/adsPrices";
import { SESSION_STORAGE_KEYS } from "../../../../utility/constants";

export const DraftFooterButtons = () => {
  const dispatch = useDispatch();
  const scrollAndNavigate = useScrollToTopAndNavigate();
  const listingData = useSelector(selectTemporaryLinkListingPreviewData);
  const initialListingPrice = useSelector(
    selectTemporaryLinkListingInitialPrice,
  );
  const selectedListingPrice = useSelector(
    selectTemporaryLinkListingSelectedPrice,
  );

  const priceLabel = (selectedListingPrice || initialListingPrice)?.label || "";

  const editListingButtonProps = {
    label: "Edit Listing",
    onClick: () => {
      dispatch(setTemporaryLinkEditListingData({ data: listingData }));
      scrollAndNavigate(TemporaryLinkPages.EDIT_LISTING);
    },
  };

  const handleChooseListingTypeError = (e) => {
    const message = handleErrorMessage(e, CHOOSE_LISTING_TYPE_ERROR);
    toast.error(message);
  };

  const onSuccessChooseListingType = () => {
    const newListingData = {
      ...listingData,
      price: selectedListingPrice,
      priceId: selectedListingPrice.id,
    };
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.SELECTED_PRICE_ID);
    dispatch(setTemporaryLinkListingData({ data: newListingData }));
    scrollAndNavigate(TemporaryLinkPages.PAYMENT);
  };

  const handleChooseListingType = () => {
    const selectedListingPriceId =
      selectedListingPrice?.id || initialListingPrice?.id;

    if (selectedListingPriceId === ADS_PRICES_ENUM.FREE) {
      return dispatch(
        setTemporaryLinkShowFreeListingModalNotice({ data: true }),
      );
    }

    sessionStorage.removeItem(SESSION_STORAGE_KEYS.SELECTED_PRICE_ID);
    dispatch(
      temporaryLinkChooseListingTypeRequest({
        data: { priceId: selectedListingPriceId },
        onSuccess: onSuccessChooseListingType,
        onError: handleChooseListingTypeError,
      }),
    );
  };

  const continueButtonProps = {
    label: "Continue With " + priceLabel,
    onClick: () => handleChooseListingType(),
  };

  return (
    <ButtonsFooter
      outlinedButtonProps={editListingButtonProps}
      containedButtonProps={continueButtonProps}
    />
  );
};
