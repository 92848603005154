import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { SkeletonTheme } from "react-loading-skeleton";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { InvoiceSkeletonContent } from "./components/InvoiceSkeletonContent";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
export const InvoiceSkeletonLoader = () => {
    return (React.createElement(SkeletonTheme, { baseColor: ColorsValue["greyscale-100"], highlightColor: ColorsValue["greyscale-50"] },
        React.createElement(FtsRow, { rowGap: "lg" },
            React.createElement(InvoiceSkeletonContent, null))));
};
