import React, { useCallback } from "react";
import { Pages } from "../../../constants/pages";
import { isIOS } from "../../../../utility/isIOS";
import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { socialAuthRequest } from "../../../../redux/actions/v2/socialAuth";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { createAccountValidator } from "../validators/createAccountValidator";
import { FbButton } from "@find-truck-service/ui/src/components/react/FbButton";
import { socialAuthProviders } from "../../../../redux/sagas/socialAuth/helpers";
import TextDivider from "@find-truck-service/ui/src/components/react/TextDivider";
import { selectCreateAccountData } from "../../../../redux/reducers/v2/createAccount";
import { AppleButton } from "@find-truck-service/ui/src/components/react/AppleButton";
import { GoogleButton } from "@find-truck-service/ui/src/components/react/GoogleButton";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import {
  createAccountRequest,
  setCreateAccountEmailAlreadyUsed,
  setCreateAccountInvalidFormFields,
} from "../../../../redux/actions/v2/createAccount";

const showAppleButton = isIOS();
const navigateTo = Pages.COMPLETE_ACCOUNT_INFO;

export const CreateAccountFormButtons = ({ type }) => {
  const dispatch = useDispatch();
  const accountData = useSelector(selectCreateAccountData);

  const handleRegister = useCallback(async () => {
    try {
      await createAccountValidator.validate(accountData || {});
      dispatch(createAccountRequest({ data: { type, navigateTo } }));
      dispatch(setCreateAccountInvalidFormFields({}));
      dispatch(setCreateAccountEmailAlreadyUsed({ data: false }));
    } catch (e) {
      if (e.name !== "ValidationError") return;
      const data = { [e.path]: e.errors[0] };
      dispatch(setCreateAccountInvalidFormFields({ data }));
    }
  }, [accountData]);

  const handleApple = async () => {
    const provider = socialAuthProviders.APPLE;
    dispatch(socialAuthRequest({ data: { provider, navigateTo, type } }));
  };

  const handleGoogle = async () => {
    const provider = socialAuthProviders.GOOGLE;
    dispatch(socialAuthRequest({ data: { provider, navigateTo, type } }));
  };

  const handleFacebook = () => {
    const provider = socialAuthProviders.FACEBOOK;
    dispatch(socialAuthRequest({ data: { provider, navigateTo, type } }));
  };

  return (
    <FtsColumn size={{ xs: 12 }}>
      <FtsRow rowGap={"lg"}>
        <FtsColumn size={{ xs: 12 }}>
          <Button
            width={"100%"}
            label={"Register"}
            size={ButtonSizes.md}
            onClick={handleRegister}
            type={ButtonTypes.contained}
            color={ButtonColorsValue.accent}
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }} py={"sm"}>
          <TextDivider text={"or"} />
        </FtsColumn>
        {showAppleButton && (
          <FtsColumn size={{ xs: 12 }}>
            <AppleButton
              width={"100%"}
              label={"Register with Apple"}
              onClick={handleApple}
            />
          </FtsColumn>
        )}
        <FtsColumn size={{ xs: 12 }}>
          <GoogleButton
            width={"100%"}
            label={"Register with Google"}
            onClick={handleGoogle}
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }}>
          <FbButton
            width={"100%"}
            label={"Register with Facebook"}
            onClick={handleFacebook}
          />
        </FtsColumn>
      </FtsRow>
    </FtsColumn>
  );
};
