import React from "react";
import { descriptionPlaceholder } from "../constants";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { RichTextEditor } from "@find-truck-service/ui/src/components/react/RichTextEditor";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { VariantsValues } from "@find-truck-service/types/ui/text";

export const MobileDescriptionField = ({
  description,
  setShowModal,
  children,
}) => {
  function onEditDescription() {
    setShowModal(true);
  }

  return (
    <FtsWrapper
      gap={"lg"}
      direction={"column"}
      display={{ xs: "flex", sm: "none", md: "none", lg: "none" }}
    >
      {children}
      <RichTextEditor
        readOnly
        height={"100%"}
        value={description}
        className={"without-toolbar"}
        variant={VariantsValues["text-2xs"]}
        placeholder={descriptionPlaceholder}
      />
      <Button
        width={"100%"}
        size={ButtonSizes.md}
        onClick={onEditDescription}
        color={ButtonColorsValue.accent}
        type={ButtonTypes.outlined}
        iconName={IconNames.Edit}
        label={"Edit Description"}
      />
    </FtsWrapper>
  );
};
