import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Span } from "@find-truck-service/ui/src/FtsText";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { stepsDesktop } from "../constants";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

const StepperDesktop = ({ activeStepId }) => {
  return (
    <FtsWrapper
      justify="center"
      display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}
    >
      {stepsDesktop.map((step) => (
        <FtsWrapper
          key={step.id}
          display="flex"
          align="center"
          position="relative"
        >
          <FtsWrapper
            display="flex"
            position="relative"
            direction="column"
            align="center"
          >
            <FtsWrapper
              display="flex"
              justify="center"
              align="center"
              position="relative"
              width="16px"
              height="16px"
              borderRadius="2xl"
              bg={ColorsValue["greyscale-100"]}
              active={step.id <= activeStepId}
            >
              {step.id < activeStepId && (
                <FtsIcons iconName={IconNames.StepChecked} />
              )}
              {step.id === activeStepId && (
                <FtsIcons iconName={IconNames.StepActive} />
              )}
            </FtsWrapper>

            <Span
              position="absolute"
              pt="xl"
              mt="xl"
              variant={VariantsValues["text-3xs"]}
              weight={
                step.id === activeStepId ? WeightSize.bold : WeightSize.regular
              }
              color={
                step.id <= activeStepId ? "greyscale-800" : "greyscale-300"
              }
            >
              {step.label}
            </Span>
          </FtsWrapper>
          {step.id < stepsDesktop.length && (
            <FtsWrapper
              width="120px"
              height="2px"
              bg={
                ColorsValue[
                  step.id < activeStepId ? "blue-accent-300" : "greyscale-100"
                ]
              }
              position="relative"
            />
          )}
        </FtsWrapper>
      ))}
    </FtsWrapper>
  );
};

export default StepperDesktop;
