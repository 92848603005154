import React, { useEffect, useState } from "react";

import { Button, Container } from "reactstrap";
import financeRepairs from "../../../assets/img/image/finance-repairs.png";
import "../../../assets/scss/components/fts/client-card-section.scss";
import { SYNCHRONY_GET_CARD_LINK } from "../../../constants";

const getDevice = (currentWidth) => {
  return currentWidth < 576
    ? "small"
    : currentWidth < 768
    ? "phone"
    : currentWidth < 992
    ? "tablet"
    : "desktop";
};

const ClientCardSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const device = getDevice(windowWidth);
  const isMobile = device === "phone" || device === "small";

  return (
    <div className="client-card-section">
      <Container>
        <div className="grid">
          <div className="client-card-content">
            <h2>Want to finance your repairs? Perfect.</h2>
            <p className="text-white text-base mt-6">
              We have the Owner-Operator card you&apos;ve been looking for! Get
              promotional financing and exclusive cardholder benefits.
            </p>
            <div className="links">
              <a href={SYNCHRONY_GET_CARD_LINK} rel={"nofollow"}>
                <Button color="secondary" className="apply-now-btn">
                  Apply Now
                </Button>
              </a>
              <a
                href={`${window.location.origin}/truck-repair-financing`}
                rel={"nofollow"}
              >
                <Button color="secondary" className="learn-more-btn">
                  Learn more
                </Button>
              </a>
            </div>
          </div>
          <div className="image">
            <img
              src={financeRepairs}
              alt="Finance Repairs"
              width={!isMobile ? 546 : 300}
              height={!isMobile ? 342 : 200}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ClientCardSection;
