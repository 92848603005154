import React from "react";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsLogoLink } from "@find-truck-service/ui/src/FtsLink/FtsLogoLink";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
export const DrawerContentHeader = ({ onClose }) => {
    return (React.createElement(FtsWrapper, null,
        React.createElement(FtsWrapper, { pl: "xl", pb: "lg", pr: "4xl", pt: "4xl" },
            React.createElement(FtsLogoLink, { href: "/", height: "32px", display: "block", width: "202px" },
                React.createElement(FtsIcons, { iconName: IconNames.Logo, svgProps: { height: "100%", width: "100%" } }))),
        React.createElement(Button, { zIndex: 10, top: "32px", onClick: onClose, borderRadius: "2xl", position: "absolute", size: ButtonSizes.md, iconName: IconNames.Close, right: `-${Spacing["2xl"]}`, type: ButtonTypes.contained, color: ButtonColorsValue.onBrand })));
};
