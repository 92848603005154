import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";

import TextDivider from "@find-truck-service/ui/src/components/react/TextDivider";
import { PayPalButton } from "@find-truck-service/ui/src/components/react/PayPalButton";
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  cardHolderValidate,
  cardNumberValidate,
  cvvValidate,
  expDateValidate,
} from "@find-truck-service/types/validators/global";
import { useDispatch, useSelector } from "react-redux";
import { selectCreateListingInvoice } from "../../../../../redux/reducers/v2/invoices";
import {
  initialNewCardData,
  selectCardError,
  selectCardsData,
  selectIsLoadingCardsData,
  selectNewCardData,
  selectNewCardInvalidFormFields,
} from "../../../../../redux/reducers/v2/cards";
import {
  clearErrors,
  createUserCardByTmpTokenRequest,
  getUserCardsByTmpTokenRequest,
  setNewCardData,
  setNewCardDataInvalidFormFields,
} from "../../../../../redux/actions/v2/cards";
import logger from "../../../../../utility/logger";
import { Spinner } from "reactstrap";
import { ClientCreditCardForm } from "@find-truck-service/ui/src/components/react/ClientCreditCardForm";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { CardItem } from "../../../../../components/fts-components-v2/CardItem/CardItem";

export const TemporaryLinkPaymentOverviewFooter = (props) => {
  return (
    <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
      <FtsWrapper mt={"lg"}>
        <StripeCards
          handlePayWithExistingCard={props.handlePayWithExistingCard}
        />
      </FtsWrapper>

      <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }} mt="xl">
        <TextDivider text="or" />
      </FtsColumn>

      <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }} mt="xl">
        <PayPalButton
          onClick={props.handlePayWithPaypal}
          width="100%"
          label={"Pay with PayPal"}
        />
      </FtsColumn>
    </FtsColumn>
  );
};

const StripeCards = (props) => {
  const dispatch = useDispatch();
  const [addNewComponentOpen, setAddNewComponentOpen] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(0);

  const listingInvoice = useSelector(selectCreateListingInvoice);
  const newCardData = useSelector(selectNewCardData);
  const cardsData = useSelector(selectCardsData);
  const isLoading = useSelector(selectIsLoadingCardsData);
  const newCardInvalidFormFields = useSelector(selectNewCardInvalidFormFields);
  const error = useSelector(selectCardError);

  useEffect(() => {
    if (!cardsData) dispatch(getUserCardsByTmpTokenRequest());
    if (cardsData) {
      if (!cardsData.length) setAddNewComponentOpen(true);
      setSelectedCardId(cardsData.find((card) => card.isDefault)?.id);
    }
  }, [cardsData]);

  const handleOnChange = useCallback(
    (key, value) => {
      dispatch(
        setNewCardData({
          data: { ...newCardData, [key]: value },
        }),
      );
    },
    [newCardData],
  );

  const handleCheckboxChange = useCallback(
    (key, value) => {
      dispatch(
        setNewCardData({
          data: { ...newCardData, [key]: !value },
        }),
      );
    },
    [newCardData],
  );

  const createUserCard = useCallback(async () => {
    try {
      await accountCardValidator.validate(newCardData, { abortEarly: false });
      dispatch(
        createUserCardByTmpTokenRequest({
          data: newCardData,
          onSuccess: () => setAddNewComponentOpen(false),
        }),
      );
    } catch (e) {
      logger.error(e);
      if (e.name !== "ValidationError") return;
      let errors = {};
      e.inner.map((error) => (errors[error.path] = error.message));
      dispatch(setNewCardDataInvalidFormFields({ data: errors }));
    }
  }, [newCardData]);

  const closeCardForm = () => {
    if (cardsData?.length) {
      setAddNewComponentOpen(false);
      dispatch(clearErrors());
    }
  };

  if (isLoading) {
    return (
      <FtsWrapper display="flex" justify="center" align="center">
        <Spinner size="lg" />
      </FtsWrapper>
    );
  }

  if (error?.message) {
    return <Message type={"error"} title={""} subtitle={error.message} />;
  }

  if (addNewComponentOpen) {
    return (
      <ClientCreditCardForm
        closeCardForm={closeCardForm}
        cardData={newCardData}
        onSubmit={createUserCard}
        handleCheckboxChange={handleCheckboxChange}
        handleOnChange={handleOnChange}
        title={"Add Card"}
        submitButtonTitle={"Add Card"}
        invalidFormFields={newCardInvalidFormFields}
        inlineMessage={error?.inlineMessage}
      />
    );
  }
  return (
    <>
      <FtsWrapper display="flex" direction="column" gap={"lg"}>
        <FtsWrapper display="flex" justify="space-between" align={"center"}>
          <Span
            variant={VariantsValues["text-3xs"]}
            weight={WeightSize.medium}
            color={ColorsValue["greyscale-800"]}
          >
            PAYMENT METHODS
          </Span>
          <Button
            onClick={() => {
              dispatch(setNewCardData({ data: initialNewCardData }));
              setAddNewComponentOpen(true);
            }}
            size={ButtonSizes.md}
            type={ButtonTypes.text}
            color={ButtonColorsValue.accent}
            label={"Add new card"}
          />
        </FtsWrapper>
        {cardsData?.map((card) => (
          <CardItem
            key={card.id}
            {...card}
            isSelected={selectedCardId === card.id}
            setSelectedCardId={setSelectedCardId}
          />
        ))}
      </FtsWrapper>

      <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }} mt="xl">
        <Button
          width="100%"
          size={ButtonSizes.md}
          color={ButtonColorsValue.accent}
          type={ButtonTypes.contained}
          onClick={() => props.handlePayWithExistingCard(selectedCardId)}
          label={`Pay (${listingInvoice?.total?.toFixed(2)})`}
        />
      </FtsColumn>
    </>
  );
};

export const accountCardValidator = Yup.object().shape({
  cardNumber: cardNumberValidate,
  cardHolderName: cardHolderValidate,
  cardCvc: cvvValidate,
  cardExpirationDate: expDateValidate,
  isDefault: Yup.bool(),
});
