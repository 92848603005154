import React from "react";
import { useSelector } from "react-redux";
import { DesktopDescriptionField } from "./DesktopDescriptionField";
import { setListingData } from "../../../../redux/actions/v2/listing";
import { selectListingDataDescription } from "../../../../redux/reducers/v2/listing";

export const DesktopDescription = () => {
  const description = useSelector(selectListingDataDescription);

  return (
    <DesktopDescriptionField
      description={description}
      setListingData={setListingData}
    />
  );
};
