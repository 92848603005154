import { createSelector } from "reselect";

export const PAY_WITH_EXISTING_CARD_REQUEST =
  "PAYMENTS/PAY_WITH_EXISTING_CARD_REQUEST";
export const PAY_WITH_EXISTING_CARD_SUCCESS =
  "PAYMENTS/PAY_WITH_EXISTING_CARD_SUCCESS";
export const PAY_WITH_EXISTING_CARD_FAILED =
  "PAYMENTS/PAY_WITH_EXISTING_CARD_FAILED";

export const PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_REQUEST =
  "PAYMENTS/PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_REQUEST";
export const PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_SUCCESS =
  "PAYMENTS/PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_SUCCESS";
export const PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_FAILED =
  "PAYMENTS/PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_FAILED";

export const PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_REQUEST =
  "PAYMENTS/PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_REQUEST";
export const PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_SUCCESS =
  "PAYMENTS/PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_SUCCESS";
export const PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_FAILED =
  "PAYMENTS/PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_FAILED";

export const PAY_WITH_PAYPAL_REQUEST = "PAYMENTS/PAY_WITH_PAYPAL_REQUEST";
export const PAY_WITH_PAYPAL_SUCCESS = "PAYMENTS/PAY_WITH_PAYPAL_SUCCESS";
export const PAY_WITH_PAYPAL_FAILED = "PAYMENTS/PAY_WITH_PAYPAL_FAILED";

export const PAY_WITH_PAYPAL_BY_TMP_TOKEN_REQUEST =
  "PAYMENTS/PAY_WITH_PAYPAL_BY_TMP_TOKEN_REQUEST";
export const PAY_WITH_PAYPAL_BY_TMP_TOKEN_SUCCESS =
  "PAYMENTS/PAY_WITH_PAYPAL_BY_TMP_TOKEN_SUCCESS";
export const PAY_WITH_PAYPAL_BY_TMP_TOKEN_FAILED =
  "PAYMENTS/PAY_WITH_PAYPAL_BY_TMP_TOKEN_FAILED";

export const REGISTER_PAYPAL_PAYMENT_REQUEST =
  "PAYMENTS/REGISTER_PAYPAL_PAYMENT_REQUEST";
export const REGISTER_PAYPAL_PAYMENT_SUCCESS =
  "PAYMENTS/REGISTER_PAYPAL_PAYMENT_SUCCESS";
export const REGISTER_PAYPAL_PAYMENT_FAILED =
  "PAYMENTS/REGISTER_PAYPAL_PAYMENT_FAILED";

export const REGISTER_UPGRADE_PAYPAL_PAYMENT_REQUEST =
  "PAYMENTS/REGISTER_UPGRADE_PAYPAL_PAYMENT_REQUEST";
export const REGISTER_UPGRADE_PAYPAL_PAYMENT_SUCCESS =
  "PAYMENTS/REGISTER_UPGRADE_PAYPAL_PAYMENT_SUCCESS";
export const REGISTER_UPGRADE_PAYPAL_PAYMENT_FAILED =
  "PAYMENTS/REGISTER_UPGRADE_PAYPAL_PAYMENT_FAILED";

export const PAY_UPGRADE_WITH_PAYPAL_REQUEST =
  "PAYMENTS/PAY_UPGRADE_WITH_PAYPAL_REQUEST";
export const PAY_UPGRADE_WITH_PAYPAL_SUCCESS =
  "PAYMENTS/PAY_UPGRADE_WITH_PAYPAL_SUCCESS";
export const PAY_UPGRADE_WITH_PAYPAL_FAILED =
  "PAYMENTS/PAY_UPGRADE_WITH_PAYPAL_FAILED";

const initialState = {
  isLoading: false,
  error: null,
  inlineError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PAY_WITH_EXISTING_CARD_REQUEST:
    case PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_REQUEST:
    case PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_REQUEST:
      return {
        ...state,
        error: null,
        inlineError: null,
        isLoading: true,
      };

    case PAY_WITH_EXISTING_CARD_SUCCESS:
    case PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_SUCCESS:
    case PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_SUCCESS:
      return {
        ...state,
        error: null,
        inlineError: null,
        isLoading: false,
      };

    case PAY_WITH_EXISTING_CARD_FAILED:
    case PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_FAILED:
    case PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_FAILED:
      return {
        ...state,
        error: action.payload.data?.message,
        inlineError: action.payload.data?.inlineMessage,
        isLoading: false,
      };

    case PAY_WITH_PAYPAL_REQUEST:
    case REGISTER_PAYPAL_PAYMENT_REQUEST:
    case PAY_WITH_PAYPAL_BY_TMP_TOKEN_REQUEST:
    case REGISTER_UPGRADE_PAYPAL_PAYMENT_REQUEST:
    case PAY_UPGRADE_WITH_PAYPAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case PAY_WITH_PAYPAL_SUCCESS:
    case REGISTER_PAYPAL_PAYMENT_SUCCESS:
    case PAY_WITH_PAYPAL_BY_TMP_TOKEN_SUCCESS:
    case REGISTER_UPGRADE_PAYPAL_PAYMENT_SUCCESS:
    case PAY_UPGRADE_WITH_PAYPAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case PAY_WITH_PAYPAL_FAILED:
    case REGISTER_PAYPAL_PAYMENT_FAILED:
    case PAY_WITH_PAYPAL_BY_TMP_TOKEN_FAILED:
    case REGISTER_UPGRADE_PAYPAL_PAYMENT_FAILED:
    case PAY_UPGRADE_WITH_PAYPAL_FAILED:
      return {
        ...state,
        error: action.payload.data?.message,
        inlineError: action.payload.data?.inlineMessage,
        isLoading: false,
      };
    default:
      return state;
  }
}

const selectPaymentsState = (state) => state.paymentsState;

export const selectPaymentsError = createSelector(
  selectPaymentsState,
  (paymentsState) => {
    return paymentsState.error;
  },
);
export const selectPaymentsInlineError = createSelector(
  selectPaymentsState,
  (paymentsState) => {
    return paymentsState.inlineError;
  },
);

export const selectPaymentsIsLoading = createSelector(
  selectPaymentsState,
  (paymentsState) => {
    return paymentsState.isLoading;
  },
);
