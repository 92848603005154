import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { InputVariants } from "@find-truck-service/types/ui/input";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { selectAdsRates } from "../../../../redux/reducers/essentialLists";
import { MINIMUM_CHARGE_FIELD_ID, minimumChargeOptions } from "../constants";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import { PriceInput } from "@find-truck-service/ui/src/components/react/Input/PriceInput";

export const ListingInfoRatesFields = ({ listingAdsRates, setListingData }) => {
  const allAdsRates = useSelector(selectAdsRates);
  const dispatch = useDispatch();

  const onChange = useCallback(
    (rateId, value) => {
      listingAdsRates[rateId] = value;
      const adsRates = { ...listingAdsRates };
      dispatch(setListingData({ data: { adsRates } }));
    },
    [listingAdsRates],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} width={"100%"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          RATES
        </Span>
      </FtsColumn>

      {allAdsRates?.map(({ name, id }) => {
        const value = listingAdsRates[id] ?? "";
        if (id === MINIMUM_CHARGE_FIELD_ID)
          return (
            <FtsColumn key={id} size={{ xs: 12, md: 6, lg: 3 }}>
              <Dropdown
                name={id}
                size={DropdownSizesValues.md}
                variant={DropdownVariantsValues.outlined}
                label={name}
                placeholder={""}
                options={minimumChargeOptions}
                value={value && { label: value, value }}
                onChange={(e) => onChange(id, e?.value || "")}
              />
            </FtsColumn>
          );
        return (
          <FtsColumn key={id} size={{ xs: 12, md: 6, lg: 3 }}>
            <PriceInput
              name={id}
              size={"md"}
              label={name}
              value={value}
              variant={InputVariants.outlined}
              onChange={(e) => onChange(id, e.target.value)}
            />
          </FtsColumn>
        );
      })}
    </FtsRow>
  );
};
