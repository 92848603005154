import axios from "axios";
import appConfig from "../configs/appConfig";
import FTSCookies, { AUTH_KEY } from "../cookies";
import { sentryErrorHandler } from "./sentryErrorHandler";
import { handleUnauthorized } from "./handleUnauthorized";
import { rfc3986DecodeURIComponent } from "../utility/encode-rfc";

const instance = axios.create({ baseURL: appConfig.v2ApiURL });
const TIMEOUT_SEC = 30;

instance.interceptors.request.use(
  async (req) => {
    const xAuthCookie = FTSCookies.get(AUTH_KEY);

    if (xAuthCookie) req.headers = { Authorization: `Bearer ${xAuthCookie}` };

    req.timeout = TIMEOUT_SEC * 1000;
    return req;
  },
  async (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(handleUnauthorized, sentryErrorHandler);
instance.interceptors.response.use((response) => {
  if (response.data) {
    response.data = rfc3986DecodeURIComponent(response.data);
  }
  return handleUnauthorized(response);
}, sentryErrorHandler);

export default instance;
