import React, { lazy } from "react";
import {
  Activity,
  Search,
  Phone,
  Share2,
  User,
  Truck,
  ThumbsUp,
} from "react-feather";
import Icons from "../components/fts/Icons";
import { PERMISSIONS, USER_TYPE } from "../constants";
import { EVENT_MEASUREMENTS } from "../constants/analytics";
import NotificationsFull from "../views/pages/notifications-full";

const clientAccountInfo = lazy(
  () => import("../views/pages/account-settings/ClientAccountSettings"),
);

const SubAccounts = lazy(() => import("../views/pages/sub-accounts"));

const ownerOperatorDashboard = lazy(
  () => import("../views/pages/owner-operator-dashboard"),
);
const recentSearches = lazy(() => import("../views/pages/recent-searches"));
const privateLocation = lazy(() => import("../views/pages/private-location"));
const preferredVendors = lazy(() => import("../views/pages/preferred-vendors"));
const drivers = lazy(() => import("../views/pages/drivers"));
const trucks = lazy(() => import("../views/pages/trucks"));
const notes = lazy(() => import("../views/pages/notes"));
const callLogs = lazy(() => import("../views/pages/call-logs"));
const sharedVendors = lazy(() => import("../views/pages/shared-vendors"));
const ratings = lazy(() => import("../views/pages/recommendations"));
const myProfileOther = lazy(() => import("../views/pages/my-profile-other"));
// const financing = lazy(() => import("../views/pages/financing"));
const changeMyPassword = lazy(() => import("../views/pages/change-password"));
const downloadApp = lazy(() => import("../views/pages/download-app"));
const repairs = lazy(() => import("../views/pages/repairs"));
const repairTicket = lazy(() => import("../views/pages/repair-ticket"));

export const otherProfileRoutes = [
  {
    key: "notifications",
    path: "/dashboard/notifications",
    title: "Notifications",
    type: "item",
    notificationNavItem: true,
    isProtected: true,
    component: NotificationsFull,
    icon: <Activity size={20} />,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_NOTIFICATIONS,
    interactionType: EVENT_MEASUREMENTS.VIEW_NOTIFICATIONS,
  },
  {
    key: "fleet-company-details",
    path: "/dashboard/fleet-company-details",
    isProtected: true,
    component: clientAccountInfo,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_COMPANY_DETAILS,
    interactionType: EVENT_MEASUREMENTS.VIEW_COMPANY_DETAILS,
  },
  {
    key: "sub-accounts",
    path: "/dashboard/sub-accounts",
    isProtected: true,
    component: SubAccounts,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_SUB_USERS,
    interactionType: EVENT_MEASUREMENTS.VIEW_SUB_USERS,
  },
  {
    key: "my-profile",
    path: "/dashboard/my-profile",
    isProtected: true,
    component: myProfileOther,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_MY_PROFILE,
    interactionType: EVENT_MEASUREMENTS.VIEW_MY_PROFILE,
  },
  {
    key: "change-my-password",
    path: "/dashboard/change-my-password",
    isProtected: true,
    component: changeMyPassword,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_CHANGE_PASSWORD,
    interactionType: EVENT_MEASUREMENTS.CHANGE_PASSWORD,
  },
  {
    key: "other-dashboard",
    path: "/dashboard",
    title: "Dashboard",
    type: "item",
    isProtected: true,
    component: ownerOperatorDashboard,
    icon: <Activity size={20} />,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_DASHBOARD,
    interactionType: EVENT_MEASUREMENTS.VIEW_DASHBOARD,
  },
  {
    key: "recent-searches",
    path: "/dashboard/recent-searches",
    title: "Searches",
    type: "item",
    component: recentSearches,
    icon: <Search size={20} />,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_RECENT_SEARCHES,
    interactionType: EVENT_MEASUREMENTS.VIEW_RECENT_SEARCHES,
  },
  {
    key: "preferred-vendors",
    path: "/dashboard/preferred-vendors",
    title: "Vendors",
    type: "item",
    isProtected: true,
    redirectToMobile: "PreferredVendors",
    component: preferredVendors,
    icon: <Icons name="Vendors" size={20} />,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_PREFFERED_VENDORS,
    interactionType: EVENT_MEASUREMENTS.VIEW_PREFERRED_VENDORS,
  },
  {
    key: "drivers",
    path: "/dashboard/drivers",
    title: "Drivers",
    type: "item",
    isProtected: true,
    component: drivers,
    icon: <User size={20} />,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_DRIVERS,
    interactionType: EVENT_MEASUREMENTS.VIEW_DRIVERS,
  },
  {
    key: "trucks",
    path: "/dashboard/trucks",
    title: "Trucks",
    type: "item",
    isProtected: true,
    component: trucks,
    icon: <Truck size={20} />,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_TRUCKS,
    interactionType: EVENT_MEASUREMENTS.VIEW_TRUCKS,
  },
  {
    key: "repairs",
    path: "/dashboard/repairs",
    title: "Repairs",
    type: "item",
    isProtected: true,
    component: repairs,
    icon: <Icons name="Repairs" size={20} />,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_REPAIRS,
    interactionType: EVENT_MEASUREMENTS.VIEW_REPAIRS,
    badge: "white",
    badgeText: "BETA",
  },
  {
    key: "repair-by-id",
    path: "/dashboard/repairs/:repairId",
    noSidebar: false,
    isProtected: true,
    exact: false,
    fullLayout: false,
    component: repairTicket,
    icon: <Icons name="Repairs" size={20} />,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_REPAIRS,
  },
  {
    key: "notes",
    path: "/dashboard/notes",
    title: "Notes",
    type: "item",
    isProtected: true,
    redirectToMobile: "Notes",
    component: notes,
    icon: <Icons name="TodoNote" size={20} />,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_NOTES,
    interactionType: EVENT_MEASUREMENTS.VIEW_NOTES,
  },
  {
    key: "call-logs",
    path: "/dashboard/call-logs",
    title: "Call Logs",
    type: "item",
    isProtected: true,
    redirectToMobile: "CallsListing",
    component: callLogs,
    icon: <Phone size={20} />,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_CALL_LOGS,
    interactionType: EVENT_MEASUREMENTS.VIEW_CALL_LOGS,
  },
  {
    key: "shared-vendors",
    path: "/dashboard/shared-vendors",
    title: "Shared",
    type: "item",
    isProtected: true,
    redirectToMobile: "SharedVendorsStack",
    component: sharedVendors,
    icon: <Share2 size={20} />,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_SHARED_VENDORS,
    interactionType: EVENT_MEASUREMENTS.VIEW_SHARED_VENDORS,
  },
  {
    key: "ratings",
    path: "/dashboard/ratings",
    title: "Ratings",
    type: "item",
    isProtected: true,
    redirectToMobile: "RatingsStack",
    component: ratings,
    icon: <ThumbsUp size={20} />,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_RECOMMENDATIONS,
    interactionType: EVENT_MEASUREMENTS.VIEW_RECOMMENDATIONS,
  },
  // {
  //   key: "financing",
  //   path: "/dashboard/financing",
  //   title: "Financing",
  //   type: "item",
  //   isProtected: true,
  //   component: financing,
  //   icon: <Icons name="DollarSign" size={20} />,
  //   permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_FINANCING,
  //   interactionType: EVENT_MEASUREMENTS.VIEW_FINANCING,
  // },
  {
    key: "download-app",
    path: "/dashboard/download-app",
    isProtected: true,
    component: downloadApp,
    permission: PERMISSIONS[USER_TYPE.OTHER].OTHER_DOWNLOAD_APP,
    interactionType: EVENT_MEASUREMENTS.DOWNLOAD_APP,
  },
  {
    key: "private-location",
    path: "/dashboard/private-location",
    noSidebar: true,
    isProtected: true,
    exact: false,
    fullLayout: true,
    component: privateLocation,
    icon: "",
  },
];
