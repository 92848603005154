import { isObject } from "lodash";
import { Pages } from "../../../../views/constants/pages";

export function emailError(emailAlreadyUsed, invalidFormFields) {
  const href =
    Pages.LOGIN + "?redirectTo=/dashboard/create-listing/business-details";
  const alreadyUsedEmail = `Email already exists, <a href="${href}">login</a> to continue.`;
  return emailAlreadyUsed
    ? alreadyUsedEmail
    : isObject(invalidFormFields) && invalidFormFields["email"];
}
