export const checkSkipWorkingHours = (workingHours) => {
  if (!workingHours) return true;

  for (const value of Object.values(workingHours)) {
    if (typeof value !== "string") {
      return false;
    }
    if (!value.startsWith(" ") && !value.endsWith(" ")) {
      return false;
    }

    if (value.startsWith("24 hrs")) {
      return false;
    }

    if (value.startsWith("By Appointment -")) {
      return false;
    }
  }
  return true;
};
