import React from "react";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";

import TextDivider from "@find-truck-service/ui/src/components/react/TextDivider";
import { ListingTemporaryLinkPaymentMethods } from "../ListingTemporaryLinkPaymentMethods/ListingTemporaryLinkPaymentMethods";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { PayPalButton } from "@find-truck-service/ui/src/components/react/PayPalButton";
import { useDispatch, useSelector } from "react-redux";

import { clearAllSearchParams } from "../../../../../utility/clear-search-params";
import { history } from "../../../../../history";
import { selectTemporaryListingLinkInvoices } from "../../../../../redux/reducers/v2/temporaryListingLink";
import { extractParameter } from "../../../../../utility/param-extract";
import { temporaryLinkPayWithPaypalRequest } from "../../../../../redux/actions/v2/temporaryLinkPayments";

const ListingTemporaryLinkPaymentOverviewPaymentMethod = () => {
  const listingInvoices = useSelector(selectTemporaryListingLinkInvoices);
  const dispatch = useDispatch();
  const temporaryToken = extractParameter("temporaryToken");

  const handlePayWithPaypal = () => {
    clearAllSearchParams(history);
    const redirectUri = new URL(window.location.href);
    redirectUri.searchParams.append("temporaryToken", temporaryToken);

    const payload = {
      data: {
        paymentData: {
          redirectUri: redirectUri.toString(),
          invoicesIds: [listingInvoices[0]?.id],
        },
        temporaryToken,
      },
    };

    dispatch(temporaryLinkPayWithPaypalRequest(payload));
  };
  return (
    <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
      <FtsWrapper mt={"lg"}>
        <ListingTemporaryLinkPaymentMethods />
      </FtsWrapper>

      <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }} mt="xl">
        <TextDivider text="or" />
      </FtsColumn>

      <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }} mt="xl">
        <PayPalButton
          onClick={handlePayWithPaypal}
          width="100%"
          label={"Pay with PayPal"}
        />
      </FtsColumn>
    </FtsColumn>
  );
};

export default ListingTemporaryLinkPaymentOverviewPaymentMethod;
