import React from "react";
import { useSelector } from "react-redux";
import { ListingMapInfo } from "./ListingMapInfo";
import {
  setListingData,
  setListingInvalidFormFields,
} from "../../../../redux/actions/v2/listing";
import {
  selectListingData,
  selectListingInvalidFormFields,
  selectListingLatitude,
  selectListingLongitude,
} from "../../../../redux/reducers/v2/listing";

export const ListingMapInfoRow = () => {
  const listingData = useSelector(selectListingData);
  const latitude = useSelector(selectListingLatitude);
  const longitude = useSelector(selectListingLongitude);
  const invalidFormFields = useSelector(selectListingInvalidFormFields);

  return (
    <ListingMapInfo
      latitude={latitude}
      longitude={longitude}
      listingData={listingData}
      setListingData={setListingData}
      invalidFormFields={invalidFormFields}
      setListingInvalidFormFields={setListingInvalidFormFields}
    />
  );
};
