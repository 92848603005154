import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { descriptionPlaceholder } from "../constants";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { RichTextEditor } from "@find-truck-service/ui/src/components/react/RichTextEditor";
import { VariantsValues } from "@find-truck-service/types/ui/text";

export const DescriptionModalContentField = ({
  descriptionStore,
  setListingData,
}) => {
  const dispatch = useDispatch();
  const [description, setDescription] = React.useState("");

  useEffect(() => {
    setDescription(descriptionStore);
  }, []);

  function onChange(description) {
    setDescription(description);
    dispatch(setListingData({ data: { description } }));
  }

  return (
    <FtsWrapper px={"xl"} height={"100%"} overflow={"scroll"}>
      <RichTextEditor
        height={"100%"}
        onChange={onChange}
        value={description}
        className={"reverse"}
        variant={VariantsValues["text-2xs"]}
        placeholder={descriptionPlaceholder}
      />
    </FtsWrapper>
  );
};
