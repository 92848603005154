import GetListedStepperIndicator from "../../../components/fts-components-v2/GetListedStepperIndicator";
import React, { useCallback, useEffect, useState } from "react";
import {
  selectAdsPrices,
  selectAmenities,
  selectServiceAmenities,
} from "../../../redux/reducers/essentialLists";
import { useDispatch, useSelector } from "react-redux";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { ListingItem } from "@find-truck-service/ui/src/components/react/ListingItem";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import {
  selectIsLoadingListingData,
  selectListingData,
  selectListingDataError,
  selectListingInlineError,
} from "../../../redux/reducers/v2/listing";
import {
  createListingInvoiceRequest,
  getListingDataByIdRequest,
  setInitialListingData,
  setListingData,
  updateListingRequest,
} from "../../../redux/actions/v2/listing";
import { H3 } from "@find-truck-service/ui/src/FtsText";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { history, historyRefresh } from "../../../history";
import { PreviewListingModal } from "@find-truck-service/ui/src/components/react/PreviewListingModal";
import { FreeListingNoticeModal } from "@find-truck-service/ui/src/components/react/FreeListingNoticeModal";
import { ADS_PRICES_LABELS } from "@find-truck-service/types/constants/adsPrices";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import {
  SOMETHING_WENT_WRONG,
  UNEXPECTED_ISSUE,
} from "../../../utility/errorMessages";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { extractParameter } from "../../../utility/param-extract";
import { getListingImageSrc } from "../../../components/fts-components-v2/ListingBusinessInfo/helpers";
import { PreviewListingModalContentTypes } from "@find-truck-service/types/constants/previewListingModalContentTypes";
import { SESSION_STORAGE_KEYS } from "../../../utility/constants";
import { toast } from "react-toastify";
import { LISTING_TYPE } from "../../../constants";
import { ListingItemsTypeValues } from "@find-truck-service/types/ui/listingItemComponent";

const ChooseListingType = (props) => {
  const dispatch = useDispatch();
  const [isFreeListingNoticeModalOpen, setIsFreeListingNoticeModalOpen] =
    useState(false);
  const [previewListingForPrice, setPreviewListingForPrice] = useState(null);
  let prices = useSelector(selectAdsPrices);
  const listingData = useSelector(selectListingData);
  const amenities = useSelector(selectAmenities);
  const serviceAmenities = useSelector(selectServiceAmenities);

  const [selectedListingPrice, setSelectedListingPrice] = useState(null);
  const [initialListingPrice, setInitialListingPrice] = useState(null);

  const [biggerOptions, setBiggerOptions] = useState(null);
  const error = useSelector(selectListingDataError);
  const errorMessage = useSelector(selectListingInlineError);
  const isLoading = useSelector(selectIsLoadingListingData);

  const continueLabel = `Continue${selectedListingPrice ? ` with ${ADS_PRICES_LABELS[selectedListingPrice?.id]}` : ""}`;

  useEffect(() => {
    if (!listingData || initialListingPrice) return;

    const { priceId } = listingData;
    const selectedPriceId = sessionStorage.getItem(
      SESSION_STORAGE_KEYS.SELECTED_PRICE_ID,
    );

    const initialPriceId =
      selectedPriceId && !Number.isNaN(Number(selectedPriceId))
        ? parseInt(selectedPriceId)
        : priceId;

    let listingPrice = prices?.find((price) => price.id === initialPriceId);
    let biggerOptions = prices
      ?.filter((price) => price.id < initialPriceId)
      .sort((a, b) => b.id - a.id);

    setInitialListingPrice(listingPrice);
    setSelectedListingPrice(listingPrice);
    setBiggerOptions(biggerOptions);
  }, [prices, listingData, initialListingPrice]);

  useEffect(() => {
    if (!listingData?.id && props.match.params.adId) {
      dispatch(
        getListingDataByIdRequest({ data: { id: props.match.params.adId } }),
      );
    }
  }, [listingData.id, props.match.params]);

  const onEdit = () => {
    const convertedToVendor = extractParameter("convertedToVendor");

    const searchParams = new URLSearchParams();

    if (convertedToVendor) {
      searchParams.set("convertedToVendor", convertedToVendor);
    }

    historyRefresh.push({
      pathname: `/dashboard/edit-listing/business-details/${listingData.id}`,
      search: `?${searchParams.toString()}`,
    });
  };

  const onSelectListing = (price) => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.SELECTED_PRICE_ID, price.id);
    setSelectedListingPrice(price);
  };

  const onCreateInvoiceSuccess = () => {
    const convertedToVendor = extractParameter("convertedToVendor");
    const searchParams = new URLSearchParams();
    if (convertedToVendor) {
      searchParams.set("convertedToVendor", convertedToVendor);
    }

    sessionStorage.removeItem(SESSION_STORAGE_KEYS.SELECTED_PRICE_ID);

    historyRefresh.push({
      pathname: `/dashboard/payment-overview/${listingData.id}`,
      search: `?${searchParams.toString()}`,
    });
  };

  const onCreateInvoiceError = ({ message }) => {
    toast.error(message);
  };

  const handleGoWithFreeListing = useCallback(() => {
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.SELECTED_PRICE_ID);
    setIsFreeListingNoticeModalOpen(false);
    dispatch(
      setListingData({
        data: {
          ...listingData,
          draftStep: null,
          priceId: 5,
          status: 2,
        },
      }),
    );

    dispatch(
      updateListingRequest({
        onSuccess: () => {
          history.push("/dashboard?openModal=freeListingSubmittedModal");
          dispatch(setInitialListingData());
        },
      }),
    );
  }, [listingData]);

  const handleCreateListingInvoice = () => {
    if (selectedListingPrice.id === LISTING_TYPE.FREE) {
      return setIsFreeListingNoticeModalOpen(true);
    }
    dispatch(
      createListingInvoiceRequest({
        data: {
          priceId: selectedListingPrice.id,
        },
        onSuccess: onCreateInvoiceSuccess,
        onError: onCreateInvoiceError,
      }),
    );
  };

  const imageSrc = getListingImageSrc(listingData);

  if (!prices || !listingData || !initialListingPrice)
    return <FullPageLoader />;

  if (error)
    return (
      <FtsWrapper mt="4xl">
        <Error
          title={SOMETHING_WENT_WRONG}
          subtitle={errorMessage || UNEXPECTED_ISSUE}
          iconName="AlertTriangle"
          containedButton={{
            label: "Refresh",
            color: ButtonColorsValue.accent,
            onClick: () => {
              history.go(0);
            },
          }}
        />
      </FtsWrapper>
    );

  return (
    <>
      {isLoading && <FullPageLoader />}

      {/*Stepper for sm devices*/}
      <FtsWrapper display={{ xs: "flex", sm: "none", md: "none", lg: "none" }}>
        <GetListedStepperIndicator activeStepId={3} />
      </FtsWrapper>
      {/*Stepper for bigger devices*/}
      <FtsWrapper
        display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}
        justify={"center"}
      >
        <GetListedStepperIndicator activeStepId={2} />
      </FtsWrapper>

      <FtsContainer pb={"3xl"}>
        <FtsWrapper mb={{ lg: "3xxl", md: "3xl", sm: "3xl", xs: "2xl" }}>
          <H3
            display={{ xs: "none", sm: "block", md: "block", lg: "block" }}
            variant={VariantsValues["text-lg"]}
            weight={WeightSize.bold}
            textAlign={"center"}
            mb={"3xl"}
          >
            Preview listing
          </H3>
          <ListingItem
            type={ListingItemsTypeValues.VENDOR_ADVERTISE}
            selected={selectedListingPrice.id === initialListingPrice.id}
            listingData={{
              ...listingData,
              priceId: initialListingPrice.id,
              price: initialListingPrice,
            }}
            footerActions={{
              onSelect: () => onSelectListing(initialListingPrice),
              onClickPreview: () =>
                setPreviewListingForPrice(initialListingPrice),
            }}
          />
        </FtsWrapper>

        {biggerOptions?.length ? (
          <>
            <H3
              variant={"text-lg"}
              weight={WeightSize.bold}
              textAlign={"center"}
              mb={"3xl"}
            >
              See more listing options
            </H3>
            <FtsWrapper
              display={"flex"}
              gap={{ lg: "3xxl", md: "3xl", sm: "3xl", xs: "2xl" }}
              direction={"column"}
            >
              {biggerOptions.map((item, index) => (
                <ListingItem
                  key={index}
                  type={ListingItemsTypeValues.VENDOR_ADVERTISE}
                  selected={selectedListingPrice.id === item.id}
                  listingData={{
                    ...listingData,
                    price: item,
                    priceId: item.id,
                  }}
                  footerActions={{
                    onSelect: () => onSelectListing(item),
                    onClickPreview: () => setPreviewListingForPrice(item),
                  }}
                />
              ))}
            </FtsWrapper>
          </>
        ) : (
          <EmptyComponent />
        )}

        <FtsWrapper
          display={"flex"}
          gap={"lg"}
          my={"3xl"}
          justify={"center"}
          p={{ xs: "xl", sm: "0", md: "0", lg: "0" }}
          direction={{ xs: "column", sm: "row", md: "row", lg: "row" }}
        >
          <Button
            onClick={onEdit}
            size={ButtonSizes.md}
            color={ButtonColorsValue.accent}
            type={ButtonTypes.outlined}
            label={"Edit listing"}
            width={{ xs: "100%", sm: "auto", md: "auto", lg: "auto" }}
          />

          <Button
            size={ButtonSizes.md}
            color={ButtonColorsValue.accent}
            type={ButtonTypes.contained}
            disabled={!selectedListingPrice}
            label={continueLabel}
            width={{ xs: "100%", sm: "auto", md: "auto", lg: "auto" }}
            onClick={() => handleCreateListingInvoice()}
          />
        </FtsWrapper>
      </FtsContainer>

      <PreviewListingModal
        showBenefits
        modalFooterProps={{
          successLabel: `Select ${previewListingForPrice?.label}`,
          onSuccess: () => {
            setSelectedListingPrice(previewListingForPrice);
            setPreviewListingForPrice(null);
          },
        }}
        imageSrc={imageSrc}
        amenities={amenities}
        listingData={listingData}
        price={previewListingForPrice}
        isOpen={!!previewListingForPrice}
        serviceAmenities={serviceAmenities}
        onClose={() => setPreviewListingForPrice(null)}
        contentType={PreviewListingModalContentTypes.PREVIEW_LISTING}
        title={
          previewListingForPrice?.label
            ? `${previewListingForPrice.label} Preview`
            : "Preview listing"
        }
      />

      <FreeListingNoticeModal
        size={"sm"}
        isOpen={isFreeListingNoticeModalOpen}
        onError={handleGoWithFreeListing}
        onClose={() => {
          setIsFreeListingNoticeModalOpen(false);
        }}
        onSuccess={() => {
          setIsFreeListingNoticeModalOpen(false);
        }}
        onBackdropClick={() => {
          setIsFreeListingNoticeModalOpen(false);
        }}
      />
    </>
  );
};

export default ChooseListingType;
