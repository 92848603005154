import React, { Component } from "react";
import { connect } from "react-redux";

import classnames from "classnames";
import FTSDataTable from "../fts-data-table/FTSTable";
import { listingTypesArray } from "../../../data/listing-types-options";
import { defineRelatedListingsColumns } from "./related-listings-columns";
import {
  Card,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import "../../../assets/scss/components/fts/fts-individual-listings-performance.scss";
import {
  getRelatedListingsByListingType,
  getRelatedListingsCounters,
} from "../../../redux/actions/reports";
import capitalize from "lodash/capitalize";
import { rfc3986DecodeURIComponent } from "../../../utility/encode-rfc";
import Icon from "../Icons";
import logger from "../../../utility/logger";

class IndividualListingsPerformance extends Component {
  state = {
    activeTabListing: "premium",
    inProgress: true,
    premiumListingData: null,
    standardListingData: null,
    basicplusListingData: null,
    basicListingData: null,
    freeListingData: null,
    pagePremium: 1,
    pageStandard: 1,
    pageBasicPlus: 1,
    pageBasic: 1,
    pageFree: 1,
    pageCountPremium: 0,
    pageCountStandard: 0,
    pageCountBasic: 0,
    pageCountBasicPlus: 0,
    pageCountFree: 0,
    pageSizePremium: 10,
    pageSizeStandard: 10,
    pageSizeBasicPlus: 10,
    pageSizeBasic: 10,
    pageSizeFree: 10,
    totalRecordsPremium: 0,
    totalRecordsStandard: 0,
    totalRecordsBasicPlus: 0,
    totalRecordsBasic: 0,
    totalRecordsFree: 0,

    searchString: null,
  };

  debounce = setTimeout(() => {}, 300);
  pageSizes = [10, 25, 50, 100];

  componentDidMount() {
    this.getCounters(true);
    this.relatedListingsColumns = defineRelatedListingsColumns(
      this.props.toggleListingReportPreview,
      this.showTablesLoadingState,
      this.props.listingPreviewInProgress,
      this.props.includeFreeListingReport,
    );
  }

  componentDidUpdate = () => {
    if (this.props.listingReportPreview) {
      this.hideTablesLoadingState();
    }
  };

  getCounters = (mount) => {
    this.props
      .getRelatedListingsCounters(this.props.token, {
        searchString: this.state.searchString,
      })
      .then((res) => {
        this.setState(
          {
            countersData: res.data?.counts,
          },
          () => {
            this.formatCounters(mount);
          },
        );
      })
      .catch((e) => {
        logger.error(e);
        this.setState({ error: true });
      });
  };

  formatCounters = (mount) => {
    const totalRecordsMappings = {
      totalRecordsPremium: 0,
      totalRecordsStandard: 0,
      totalRecordsBasicPlus: 0,
      totalRecordsBasic: 0,
      totalRecordsFree: 0,
    };

    this.state.countersData &&
      this.state.countersData.forEach((row) => {
        logger.info(row);
        if (row.priceId === 1) {
          totalRecordsMappings["totalRecordsPremium"] = row.count;
        } else if (row.priceId === 2) {
          totalRecordsMappings["totalRecordsStandard"] = row.count;
        } else if (row.priceId === 3) {
          totalRecordsMappings["totalRecordsBasicPlus"] = row.count;
        } else if (row.priceId === 4) {
          totalRecordsMappings["totalRecordsBasic"] = row.count;
        } else if (row.priceId === 5) {
          totalRecordsMappings["totalRecordsFree"] = row.count;
        }
      });

    let listingPriceId = this.state.countersData
      .filter((e) => e.count)
      .sort((a, b) => a.priceId - b.priceId)[0]?.priceId;
    let listingTypeName = "premium";

    if (listingPriceId) {
      listingTypeName = this.getListingTypeName(listingPriceId)
        .toLowerCase()
        .replace(/ /g, "");
    }
    this.setState(
      {
        ...totalRecordsMappings,
        activeTabListing: listingTypeName,
      },
      () => {
        if (mount) {
          this.updateListingByType(listingTypeName);
        }
      },
    );
  };

  onGridReady = (params) => {
    if (!this.state.inProgress) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    }
  };

  resetApi = () => {
    this.gridApi = null;
  };

  getListingTypeName = (value) => {
    let key = listingTypesArray?.filter(
      (type) => type.value === parseInt(value),
    );

    return key[0].label;
  };

  getTotalRecords = ({ adType }) => {
    const listingType = this.getListingTypeName(adType).replace(/ /g, "");

    return this.state[`totalRecords${listingType}`];
  };

  updateListingByType = (type) => {
    switch (type) {
      case "premium":
        this.updatePremiumListingResults();
        break;
      case "standard":
        this.updateStandardListingResults();
        break;
      case "basicplus":
        this.updateBasicPlusListingResults();
        break;
      case "basic":
        this.updateBasicListingResults();
        break;
      case "free":
        this.updateFreeListingResults();
        break;
      default:
        break;
    }
  };

  toggleListingTab = (tab) => {
    if (this.state.activeTabListing !== tab) {
      this.setState({ activeTabListing: tab });
      this.updateListingByType(tab);
    }
  };

  resetApiPremium = () => {
    this.gridApiPremium = null;
  };

  resetApiStandard = () => {
    this.gridApiStandard = null;
  };

  resetApiBasicPlus = () => {
    this.gridApiBasicPlus = null;
  };

  resetApiBasic = () => {
    this.gridApiBasic = null;
  };

  resetApiFree = () => {
    this.gridApiFree = null;
  };

  generateTabsListing = () => {
    const tabs = [
      {
        label: "Premium",
        type: "premium",
        total: this.getTotalRecords({ adType: 1 }),
      },
      {
        label: "Standard",
        type: "standard",
        total: this.getTotalRecords({ adType: 2 }),
      },
      {
        label: "BasicPlus",
        type: "basicplus",
        total: this.getTotalRecords({ adType: 3 }),
      },
      {
        label: "Basic",
        type: "basic",
        total: this.getTotalRecords({ adType: 4 }),
      },
      {
        label: "Low priority",
        type: "free",
        total: this.getTotalRecords({ adType: 5 }),
      },
    ];

    // let activeTab = tabs.find((e) => e.total);
    // if (activeTab) this.toggleListingTab(activeTab.type);

    return tabs
      .filter((e) => e.total)
      .map((itm) => {
        return (
          <NavItem key={itm.type}>
            <NavLink
              className={classnames({
                active: this.state.activeTabListing === itm.type,
              })}
              onClick={() => {
                this.toggleListingTab(itm.type);
              }}
            >
              {itm.label} ({Number(itm.total?.toFixed(1)).toLocaleString()})
            </NavLink>
          </NavItem>
        );
      });
  };

  onGridReadyPremium = (params) => {
    this.gridApiPremium = params.api;
    this.gridColumnApiPremium = params.columnApi;

    if (localStorage.getItem("premiumColumnState") !== null) {
      this.gridColumnApiPremium.setColumnState(
        JSON.parse(localStorage.getItem("premiumColumnState")),
      );
    }
  };

  onGridReadyStandard = (params) => {
    this.gridApiStandard = params.api;
    this.gridColumnApiStandard = params.columnApi;

    if (localStorage.getItem("standardColumnState") !== null) {
      this.gridColumnApiStandard.setColumnState(
        JSON.parse(localStorage.getItem("standardColumnState")),
      );
    }
  };

  onGridReadyBasicPlus = (params) => {
    this.gridApiBasicPlus = params.api;
    this.gridColumnApiBasicPlus = params.columnApi;

    if (localStorage.getItem("basicplusColumnState") !== null) {
      this.gridColumnApiBasicPlus.setColumnState(
        JSON.parse(localStorage.getItem("basicplusColumnState")),
      );
    }
  };

  onGridReadyBasic = (params) => {
    this.gridApiBasic = params.api;
    this.gridColumnApiBasic = params.columnApi;

    if (localStorage.getItem("basicColumnState") !== null) {
      this.gridColumnApiBasic.setColumnState(
        JSON.parse(localStorage.getItem("basicColumnState")),
      );
    }
  };

  onGridReadyFree = (params) => {
    this.gridApiFree = params.api;
    this.gridColumnApiFree = params.columnApi;

    if (localStorage.getItem("freeColumnState") !== null) {
      this.gridColumnApiFree.setColumnState(
        JSON.parse(localStorage.getItem("freeColumnState")),
      );
    }
  };

  onPageChangedPremium = ({ selected }) => {
    const pageNum = selected + 1;

    this.setState({ pagePremium: pageNum }, () => {
      this.updatePremiumListingResults();
    });
  };

  onPageChangedStandard = ({ selected }) => {
    const pageNum = selected + 1;

    this.setState({ pageStandard: pageNum }, () => {
      this.updateStandardListingResults();
    });
  };

  onPageChangedBasicPlus = ({ selected }) => {
    const pageNum = selected + 1;

    this.setState({ pageBasicPlus: pageNum }, () => {
      this.updateBasicPlusListingResults();
    });
  };

  onPageChangedBasic = ({ selected }) => {
    const pageNum = selected + 1;

    this.setState({ pageBasic: pageNum }, () => {
      this.updateBasicListingResults();
    });
  };

  onPageChangedFree = ({ selected }) => {
    const pageNum = selected + 1;

    this.setState({ pageFree: pageNum }, () => {
      this.updateFreeListingResults();
    });
  };

  updatePremiumListingResults = () => {
    const { pagePremium, pageSizePremium, listingColumns, searchString } =
      this.state;

    const apiAvailableListing = !!this.gridApiPremium;

    if (apiAvailableListing) {
      this.gridApiPremium.showLoadingOverlay();
    }

    this.props
      .getRelatedListingsByListingType(this.props.token, {
        page: pagePremium,
        priceId: 1,
        resultsPerPage: pageSizePremium,
        searchString,
      })
      .then((res) => {
        this.setState({
          inProgress: false,
          premiumListingData: rfc3986DecodeURIComponent(res.data?.relatedAds),
          columnDefs: listingColumns,
          pageCountPremium: Math.ceil(
            this.getTotalRecords({ adType: 1 }) / pageSizePremium,
          ),
        });
        if (res.data?.relatedAds?.length > 0) {
          this.gridApiPremium.hideOverlay();
        } else {
          this.gridApiPremium.showNoRowsOverlay();
        }
      })
      .catch((error) => logger.error(error));
  };

  updateStandardListingResults = () => {
    const { pageStandard, pageSizeStandard, listingColumns, searchString } =
      this.state;

    const apiAvailableListing = !!this.gridApiStandard;

    if (apiAvailableListing) {
      this.gridApiStandard.showLoadingOverlay();
    }

    this.props
      .getRelatedListingsByListingType(this.props.token, {
        page: pageStandard,
        priceId: 2,
        resultsPerPage: pageSizeStandard,
        searchString,
      })
      .then((res) => {
        this.setState({
          inProgress: false,
          standardListingData: rfc3986DecodeURIComponent(res.data?.relatedAds),
          columnDefs: listingColumns,
          pageCountStandard: Math.ceil(
            this.getTotalRecords({ adType: 2 }) / pageSizeStandard,
          ),
        });
        if (apiAvailableListing && res.data?.relatedAds?.length > 0) {
          this.gridApiStandard.hideOverlay();
        } else {
          this.gridApiStandard.showNoRowsOverlay();
        }
      })
      .catch((error) => logger.error(error));
  };

  updateBasicPlusListingResults = () => {
    const { pageBasicPlus, pageSizeBasicPlus, listingColumns, searchString } =
      this.state;

    const apiAvailableListing = !!this.gridApiBasicPlus;

    if (apiAvailableListing) {
      this.gridApiBasicPlus.showLoadingOverlay();
    }

    this.props
      .getRelatedListingsByListingType(this.props.token, {
        page: pageBasicPlus,
        priceId: 3,
        resultsPerPage: pageSizeBasicPlus,
        searchString,
      })
      .then((res) => {
        this.setState({
          inProgress: false,
          basicplusListingData: rfc3986DecodeURIComponent(res.data?.relatedAds),
          columnDefs: listingColumns,
          pageCountBasicPlus: Math.ceil(
            this.getTotalRecords({ adType: 3 }) / pageSizeBasicPlus,
          ),
        });
        if (apiAvailableListing && res.data?.relatedAds?.length > 0) {
          this.gridApiBasicPlus.hideOverlay();
        } else {
          this.gridApiBasicPlus.showNoRowsOverlay();
        }
      })
      .catch((error) => logger.error(error));
  };

  updateBasicListingResults = () => {
    const { pageBasic, pageSizeBasic, listingColumns, searchString } =
      this.state;

    const apiAvailableListing = !!this.gridApiBasic;

    if (apiAvailableListing) {
      this.gridApiBasic.showLoadingOverlay();
    }

    this.props
      .getRelatedListingsByListingType(this.props.token, {
        page: pageBasic,
        priceId: 4,
        resultsPerPage: pageSizeBasic,
        searchString,
      })
      .then((res) => {
        this.setState({
          inProgress: false,
          basicListingData: rfc3986DecodeURIComponent(res.data?.relatedAds),
          columnDefs: listingColumns,
          pageCountBasic: Math.ceil(
            this.getTotalRecords({ adType: 4 }) / pageSizeBasic,
          ),
        });
        if (apiAvailableListing && res.data?.relatedAds?.length > 0) {
          this.gridApiBasic.hideOverlay();
        } else {
          this.gridApiBasic.showNoRowsOverlay();
        }
      })
      .catch((error) => logger.error(error));
  };

  updateFreeListingResults = () => {
    const { pageFree, pageSizeFree, listingColumns, searchString } = this.state;

    const apiAvailableListing = !!this.gridApiFree;

    if (apiAvailableListing) {
      this.gridApiFree.showLoadingOverlay();
    }

    this.props
      .getRelatedListingsByListingType(this.props.token, {
        page: pageFree,
        priceId: 5,
        resultsPerPage: pageSizeFree,
        searchString,
      })
      .then((res) => {
        this.setState({
          inProgress: false,
          freeListingData: rfc3986DecodeURIComponent(res.data?.relatedAds),
          columnDefs: listingColumns,
          pageCountFree: Math.ceil(
            this.getTotalRecords({ adType: 4 }) / pageSizeFree,
          ),
        });
        if (apiAvailableListing && res.data?.relatedAds?.length > 0) {
          this.gridApiFree.hideOverlay();
        } else {
          this.gridApiFree.showNoRowsOverlay();
        }
      })
      .catch((error) => logger.error(error));
  };

  filterSize = (type, val) => {
    const { activeTabListing } = this.state;
    const key = `page${capitalize(activeTabListing)}`;

    this.setState(
      {
        [type]: val,
        [key]: 1,
      },
      () => {
        switch (activeTabListing) {
          case "premium":
            this.updatePremiumListingResults();
            break;
          case "standard":
            this.updateStandardListingResults();
            break;
          case "basicplus":
            this.updateBasicPlusListingResults();
            break;
          case "basic":
            this.updateBasicListingResults();
            break;
          case "free":
            this.updateBasicListingResults();
            break;
          default:
            break;
        }
      },
    );
  };

  updateSearchQuery = (searchString) => {
    clearTimeout(this.debounce);
    this.setState({ searchString }, () => {
      this.getCounters();
      this.debounce = setTimeout(() => {
        let pageProperty, updateFunction;
        switch (this.state.activeTabListing) {
          case "premium":
            pageProperty = "pagePremium";
            updateFunction = this.updatePremiumListingResults;
            break;
          case "standard":
            pageProperty = "pageStandard";
            updateFunction = this.updateStandardListingResults;
            break;
          case "basicplus":
            pageProperty = "pageBasicPlus";
            updateFunction = this.updateBasicPlusListingResults;
            break;
          case "basic":
            pageProperty = "pageBasic";
            updateFunction = this.updateBasicListingResults;
            break;
          case "free":
            pageProperty = "pageFree";
            updateFunction = this.updateFreeListingResults;
            break;
          default:
            return;
        }

        this.setState(
          {
            [pageProperty]: 1,
          },
          () => {
            updateFunction.call(this);
          },
        );
      }, 500);
    });
  };

  showTablesLoadingState = () => {
    this.gridApiPremium.showLoadingOverlay();
    this.gridApiStandard.showLoadingOverlay();
    this.gridApiBasicPlus.showLoadingOverlay();
    this.gridApiBasic.showLoadingOverlay();
    this.gridApiFree.showLoadingOverlay();
  };

  hideTablesLoadingState = () => {
    this.gridApiPremium.hideOverlay();
    this.gridApiStandard.hideOverlay();
    this.gridApiBasicPlus.hideOverlay();
    this.gridApiBasic.hideOverlay();
    this.gridApiFree.hideOverlay();
  };

  render() {
    const { listingReportPreview, setIsAccountReport } = this.props;
    const {
      activeTabListing,
      premiumListingData,
      standardListingData,
      basicplusListingData,
      basicListingData,
      freeListingData,
      pageCountPremium,
      pageCountStandard,
      pageCountBasicPlus,
      pageCountBasic,
      pageCountFree,
      pageSizePremium,
      pageSizeStandard,
      pageSizeBasicPlus,
      pageSizeBasic,
      pageSizeFree,
      pagePremium,
      pageStandard,
      pageBasicPlus,
      pageBasic,
      pageFree,
    } = this.state;

    return (
      <div
        className={`fts-individual-listings-performance ${
          listingReportPreview && "fts-individual-listings-performance-hidden"
        }`}
      >
        <Container className={"pt-0 mt-3"}>
          <div
            id={"individual-listings-performance"}
            onClick={() => {
              setIsAccountReport(true);
            }}
            className="d-flex mb-3 cursor-pointer"
          >
            <Icon name="ChevronLeft" />
            <div className={"ml-1"}>Back to account report</div>
          </div>

          <Card>
            <div className="fts-listings-actions-search-wrapper">
              <Input
                className="fts-listings-actions-search"
                type="text"
                placeholder="Search listings..."
                onChange={(e) => this.updateSearchQuery(e.target.value)}
                defaultValue={this.state.searchString}
                style={{ width: "200px" }}
              />
            </div>
            <Nav className="fts-nav-tabs mb-1" tabs>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex">{this.generateTabsListing()}</div>
              </div>
            </Nav>
            <TabContent activeTab={activeTabListing}>
              <TabPane tabId="premium">
                <FTSDataTable
                  isSearch
                  pageSizes={this.pageSizes}
                  totalRecords={this.getTotalRecords({ adType: 1 })}
                  currentPageSize={pageSizePremium}
                  pageSizePropName={"pageSizePremium"}
                  currentPage={pagePremium}
                  pageCount={pageCountPremium}
                  onGridReadyInit={this.onGridReadyPremium}
                  saveState={this.saveStatePremium}
                  filterSize={this.filterSize}
                  rowData={premiumListingData}
                  onPageChange={this.onPageChangedPremium}
                  resetApi={this.resetApiPremium}
                  columnDefs={this.relatedListingsColumns}
                  withinCard
                />
              </TabPane>
              <TabPane tabId="standard">
                <FTSDataTable
                  isSearch
                  pageSizes={this.pageSizes}
                  currentPageSize={pageSizeStandard}
                  currentPage={pageStandard}
                  pageSizePropName={"pageSizeStandard"}
                  totalRecords={this.getTotalRecords({ adType: 2 })}
                  pageCount={pageCountStandard}
                  onGridReadyInit={this.onGridReadyStandard}
                  filterSize={this.filterSize}
                  rowData={standardListingData}
                  onPageChange={this.onPageChangedStandard}
                  resetApi={this.resetApiStandard}
                  columnDefs={this.relatedListingsColumns}
                  withinCard
                />
              </TabPane>
              <TabPane tabId="basicplus">
                <FTSDataTable
                  isSearch
                  pageSizes={this.pageSizes}
                  currentPageSize={pageSizeBasicPlus}
                  currentPage={pageBasicPlus}
                  pageSizePropName={"pageSizeBasicPlus"}
                  totalRecords={this.getTotalRecords({ adType: 3 })}
                  pageCount={pageCountBasicPlus}
                  onGridReadyInit={this.onGridReadyBasicPlus}
                  saveState={this.saveStateBasicPlus}
                  filterSize={this.filterSize}
                  rowData={basicplusListingData}
                  onPageChange={this.onPageChangedBasicPlus}
                  resetApi={this.resetApiBasicPlus}
                  columnDefs={this.relatedListingsColumns}
                  withinCard
                />
              </TabPane>
              <TabPane tabId="basic">
                <FTSDataTable
                  isSearch
                  pageSizes={this.pageSizes}
                  currentPageSize={pageSizeBasic}
                  currentPage={pageBasic}
                  pageSizePropName={"pageSizeBasic"}
                  totalRecords={this.getTotalRecords({ adType: 4 })}
                  pageCount={pageCountBasic}
                  onGridReadyInit={this.onGridReadyBasic}
                  saveState={this.saveStateBasic}
                  filterSize={this.filterSize}
                  rowData={basicListingData}
                  onPageChange={this.onPageChangedBasic}
                  resetApi={this.resetApiBasic}
                  columnDefs={this.relatedListingsColumns}
                  withinCard
                />
              </TabPane>

              <TabPane tabId="free">
                <FTSDataTable
                  isSearch
                  pageSizes={this.pageSizes}
                  currentPageSize={pageSizeFree}
                  currentPage={pageFree}
                  pageSizePropName={"pageSizeFree"}
                  totalRecords={this.getTotalRecords({ adType: 5 })}
                  pageCount={pageCountFree}
                  onGridReadyInit={this.onGridReadyFree}
                  saveState={this.saveStateFree}
                  filterSize={this.filterSize}
                  rowData={freeListingData}
                  onPageChange={this.onPageChangedFree}
                  resetApi={this.resetApiFree}
                  columnDefs={this.relatedListingsColumns}
                  withinCard
                />
              </TabPane>
            </TabContent>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getRelatedListingsByListingType,
  getRelatedListingsCounters,
};

export default connect(null, mapDispatchToProps)(IndividualListingsPerformance);
