import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { WorkingHoursColumn } from "./WorkingHoursColumn";
import { SpecialHoursColumn } from "./SpecialHoursColumn";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";

export const ListingWorkingHoursFields = (props) => {
  const { listingData, listingWorkingHours, setListingData } = props;
  const dispatch = useDispatch();

  const onChange = useCallback((event) => {
    const name = event.target.name;
    let value = event.target.value;
    dispatch(setListingData({ data: { [name]: value } }));
  }, []);

  const onChangeWorkingHours = useCallback(
    (name, value) => {
      const workingHours = { ...listingWorkingHours, [name]: value };
      dispatch(setListingData({ data: { workingHours } }));
    },
    [listingWorkingHours],
  );

  const handleToggle = useCallback(
    (name) => {
      dispatch(
        setListingData({
          data: { [name]: !listingData[name] },
        }),
      );
    },
    [listingData],
  );

  return (
    <FtsRow columnGap={"3xl"} rowGap={"lg"} width={"100%"}>
      <WorkingHoursColumn
        listingData={listingData}
        handleToggle={handleToggle}
        onChange={onChangeWorkingHours}
        workingHours={listingWorkingHours}
      />
      <SpecialHoursColumn
        onChange={onChange}
        listingData={listingData}
        handleToggle={handleToggle}
      />
    </FtsRow>
  );
};
