import React from "react";
import "./ChartHeading.scss";

const ChartHeading = ({
  heading,
  subHeading,
  showUnderline = true,
  cta,
  backCta,
  className = "",
}) => {
  return (
    <div className={`chartSubheading ${className}`}>
      {backCta && backCta()}
      <div className={"chartSubheadingFlex"}>
        <h3>{heading}</h3>
        {cta && cta()}
      </div>
      {showUnderline && <div className={"redUnderline"} />}
      <p>{subHeading}</p>
    </div>
  );
};

export default ChartHeading;
