import { apps, initializeApp, auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";
import FTSAxios, { storeCookies } from "../../../axios/fts.instance";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import FTSCookies, { REFRESH_KEY, removeAllCookies } from "../../../cookies";
import { config } from "../../../authServices/firebase/firebaseConfig";
import { getVendorDataWithEssentialLists } from "../users";
import collectEssentialData from "../../../axios/collect-data";
import { history } from "../../../history";
import { setUserData } from "../v2/auth";
import logger from "../../../utility/logger";

// Init firebase if not already initialized
if (!apps.length) {
  initializeApp(config);
}

let firebaseAuth = auth();

// const initAuth0 = new auth0.WebAuth(configAuth)

export const refreshAuthToken = () => {
  return () => {
    const refreshToken = FTSCookies.get(REFRESH_KEY);
    return FTSAxios.post("refresh_token", { refreshToken }).then((response) => {
      const { data, status } = response;
      if (status === 201) {
        removeAllCookies();
        storeCookies(data);
      }
      return response;
    });
  };
};

export const loginClientWithFB = (showSpinner) => {
  return (dispatch) => {
    let provider = new auth.FacebookAuthProvider();
    provider.setCustomParameters({
      display: "popup",
    });
    firebaseAuth
      .signInWithPopup(provider)
      .then((result) => {
        if (result.user) {
          showSpinner && showSpinner();
          let token = result.credential.accessToken;
          let user = result.user.email;

          const payload = {
            accessToken: token,
            user,
            loggedInWith: "firebase",
          };

          FTSAxios.post("/facebook-oauth", payload).then((response) => {
            dispatch({
              type: "LOGIN_WITH_FB",
              payload: {
                user,
                token,
                loggedInWith: "firebase",
              },
            });

            if (response.data.success) {
              localStorage.setItem("loggedInWith", "firebase");
              getVendorDataWithEssentialLists(dispatch, "firebase");
            }
          });
        }
      })
      .catch((error) => logger.error(error));
  };
};

export const loginClientWithGoogle = (showSpinner) => {
  return (dispatch) => {
    let provider = new auth.GoogleAuthProvider();
    provider.addScope("email");
    firebaseAuth
      .signInWithPopup(provider)
      .then((result) => {
        if (result.user) {
          showSpinner && showSpinner();
          let token = result.credential.accessToken;
          let user = result.additionalUserInfo.profile.email;
          let email = result.additionalUserInfo.profile.email;
          let name = result.user.displayName;
          let photoUrl = result.user.photoURL;
          let loggedInWith = "firebase";

          const payload = {
            accessToken: token,
            user,
            email: email,
            name,
            photoUrl,
            loggedInWith,
          };

          FTSAxios.post("/google-oauth", payload).then((response) => {
            dispatch({
              type: "LOGIN_WITH_GOOGLE",
              payload: {
                email: user,
                name: name,
                photoUrl,
                token,
                loggedInWith: "firebase",
              },
            });

            if (response.data.success) {
              localStorage.setItem("loggedInWith", "firebase");
              getVendorDataWithEssentialLists(dispatch, "firebase");
            }
          });
        }
      })
      .catch(function (error) {
        logger.error(error);
      });
  };
};

export const loginClientWithApple = (showSpinner) => {
  return (dispatch) => {
    let provider = new auth.OAuthProvider("apple.com");
    firebaseAuth
      .signInWithPopup(provider)
      .then((result) => {
        if (result.user) {
          showSpinner && showSpinner();
          let token = result.credential.accessToken;
          let user = result.user.email;

          const payload = {
            accessToken: token,
            user,
            loggedInWith: "firebase",
          };

          FTSAxios.post("/apple-oauth", payload).then((response) => {
            dispatch({
              type: "LOGIN_WITH_APPLE",
              payload: {
                user,
                token,
                loggedInWith: "firebase",
              },
            });

            if (response.data.success) {
              localStorage.setItem("loggedInWith", "firebase");
              getVendorDataWithEssentialLists(dispatch, "firebase");
            }
          });
        }
      })
      .catch((error) => logger.error(error));
  };
};
export const loginWithJWT = (user) => {
  return () =>
    FTSAxios.post("login", {
      email: user.email,
      password: user.password,
      rememberMe: user.rememberMe,
    });
};

export const resendVerifyEmail = (data) => {
  return () => FTSAxios.post("resend-verify-email", data);
};

export const logoutWithJWT = async () => {
  await removeAllCookies();
  return (dispatch) => {
    dispatch({ type: "LOGOUT_WITH_JWT", payload: null });
    dispatch(setUserData({ data: null }));
  };
};

export const logoutWithFirebase = async () => {
  await removeAllCookies();
  return (dispatch) => {
    dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} });
    dispatch(setUserData({ data: null }));
  };
};

export const changeRole = (role) => {
  return (dispatch) => dispatch({ type: "CHANGE_ROLE", userRole: role });
};

export const restartPassword = ({ email }) => {
  return () => {
    return FTSAxios.post("/restart-password", { email });
  };
};

export const updatePassword = ({ token, password, repeatPassword }) => {
  return () => {
    return FTSAxios.put(`/restart-password/${token}`, {
      password,
      repeatPassword,
    });
  };
};

export const setLoggedInUserData = (payload) => {
  localStorage.setItem("loggedInWith", "jwt");

  return (dispatch) => {
    dispatch({
      type: "LOGIN_WITH_JWT",
      payload,
    });
    dispatch(
      setUserData({
        data: payload.loggedInUser,
      }),
    );
  };
};

export const reactivateAccount = ({ token, password, repeatPassword }) => {
  return () => {
    return FTSAxios.put(`/reactivate-account/${token}`, {
      password,
      repeatPassword,
    });
  };
};

export const deleteDeactivatedAccount = ({ token }) => {
  return () => {
    return FTSAxios.get(`/delete-deactivated-account/${token}`);
  };
};

export const verifyEmail = (token) => {
  return () => {
    return FTSAxios.post(`/verify-email/${token}`);
  };
};

// TODO: REFACTOR THIS, USE NEW API
export const authWithGoogleV2 = (navigateTo) => {
  return (dispatch) => {
    let provider = new auth.GoogleAuthProvider();
    provider.addScope("email");
    firebaseAuth.signInWithPopup(provider).then((result) => {
      if (result.user) {
        let token = result.credential.accessToken;
        let user = result.additionalUserInfo.profile.email;
        let email = result.additionalUserInfo.profile.email;
        let name = result.user.displayName;
        let photoUrl = result.user.photoURL;
        let loggedInWith = "firebase";

        const payload = {
          accessToken: token,
          user,
          email: email,
          name,
          photoUrl,
          loggedInWith,
        };

        FTSAxios.post("/google-oauth", payload).then((response) => {
          dispatch({
            type: "LOGIN_WITH_GOOGLE",
            payload: {
              email: user,
              name: name,
              photoUrl,
              token,
              loggedInWith: "firebase",
            },
          });

          if (response.data.success) {
            localStorage.setItem("loggedInWith", "firebase");
            FTSAxiosV2.get("/users/me")
              .then(async (res) => {
                const loggedInUser = res.data.data;

                dispatch({
                  type: "UPDATE_USER_INFO",
                  payload: {
                    loggedInUser: { ...loggedInUser },
                    loggedInWith: "firebase",
                  },
                });

                await collectEssentialData(dispatch);

                history.push(navigateTo);
              })
              .catch(function (error) {
                logger.error(error);
              });
          }
        });
      }
    });
  };
};

// TODO: REFACTOR THIS, USE NEW API
export const authWithFBV2 = (navigateTo) => {
  return (dispatch) => {
    let provider = new auth.FacebookAuthProvider();
    provider.setCustomParameters({
      display: "popup",
    });
    firebaseAuth
      .signInWithPopup(provider)
      .then((result) => {
        if (result.user) {
          let token = result.credential.accessToken;
          let user = result.user.email;

          const payload = {
            accessToken: token,
            user,
            loggedInWith: "firebase",
          };

          FTSAxios.post("/facebook-oauth", payload).then((response) => {
            dispatch({
              type: "LOGIN_WITH_FB",
              payload: {
                user,
                token,
                loggedInWith: "firebase",
              },
            });

            if (response.data.success) {
              localStorage.setItem("loggedInWith", "firebase");
              FTSAxiosV2.get("/users/me")
                .then(async (res) => {
                  const loggedInUser = res.data.data;

                  dispatch({
                    type: "UPDATE_USER_INFO",
                    payload: {
                      loggedInUser: {
                        ...loggedInUser,
                      },
                      loggedInWith: "firebase",
                    },
                  });

                  await collectEssentialData(dispatch);

                  history.push(navigateTo);
                })
                .catch(function (error) {
                  logger.error(error);
                });
            }
          });
        }
      })
      .catch((error) => logger.error(error));
  };
};

export const authAppleV2 = (navigateTo) => {
  return (dispatch) => {
    let provider = new auth.OAuthProvider("apple.com");
    provider.setCustomParameters({ display: "popup" });
    firebaseAuth
      .signInWithPopup(provider)
      .then((result) => {
        if (!result?.user) return;
        let token = result.credential.accessToken;
        let user = result.user.email;

        const payload = {
          accessToken: token,
          user,
          loggedInWith: "firebase",
        };

        FTSAxios.post("/apple-oauth", payload).then((response) => {
          dispatch({
            type: "LOGIN_WITH_APPLE",
            payload: {
              user,
              token,
              loggedInWith: "firebase",
            },
          });

          if (response.data.success) {
            localStorage.setItem("loggedInWith", "firebase");
            FTSAxiosV2.get("/users/me")
              .then(async (res) => {
                const loggedInUser = res.data.data;

                dispatch({
                  type: "UPDATE_USER_INFO",
                  payload: {
                    loggedInUser: {
                      ...loggedInUser,
                    },
                    loggedInWith: "firebase",
                  },
                });

                await collectEssentialData(dispatch);

                history.push(navigateTo);
              })
              .catch(function (error) {
                logger.error(error);
              });
          }
        });
      })
      .catch((error) => logger.error(error));
  };
};
