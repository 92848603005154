import * as Yup from "yup";
import { zipValidate } from "@find-truck-service/types/validators/global";

export const ownerOperatorProfileValidator = Yup.object().shape({
  companyName: Yup.string()
    .trim()
    .required("Company name is required")
    .nullable(),
  address: Yup.string().trim().required("Street is required").nullable(),
  city: Yup.string().trim().required("City is required").nullable(),
  state: Yup.lazy((val) =>
    typeof val === "object" && val !== null
      ? Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required("Select state")
      : Yup.string().nullable().required("Select state"),
  ),
  zip: zipValidate,
  phoneNumber: Yup.string()
    .trim()
    .required("Phone (primary) is required")
    .test("len", "Invalid phone number", (val) => {
      if (val) {
        return val.replace(/[_() -]/g, "").length === 10;
      }
    })
    .nullable(),
  referal: Yup.string().trim().required("Referal is required").nullable(),
});
