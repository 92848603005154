import React from "react";
import { Modal } from "@find-truck-service/ui/src/components/react/Modal";
import { FreeListingSubmittedModalHeader } from "@find-truck-service/ui/src/components/react/FreeListingSubmittedModal/components/FreeListingSubmittedModalHeader";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
export const FreeListingSubmittedModal = (props) => {
    const { isOpen, onClose, onBackdropClick } = props;
    return (React.createElement(Modal, { onBackdropClick: onBackdropClick, modalWrapperProps: {
            isOpen: isOpen,
        }, size: "md", isOpen: isOpen, modalHeaderComponent: () => React.createElement(FreeListingSubmittedModalHeader, { onClose: onClose }), modalContentComponent: EmptyComponent, modalFooterComponent: EmptyComponent }));
};
