import React, { useRef } from "react";
import { UploadButton } from "./UploadButton";
import { UploadedFiles } from "./UploadedFiles";
import { ListingInfoWrapper } from "./ListingInfoWrapper";
import { ListingImagesBanner } from "./ListingImagesBanner";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ListingInfoInlineError } from "./ListingInfoInlineError";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { LISTING_FILES_LIMIT } from "../../../../data/supported-file-types";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

export const ListingImagesMobile = ({
  adsAssets,
  listingData,
  adsAssetsSize,
  setListingData,
  uploadAdsAssetsRequest,
}) => {
  const fileInputRef = useRef(null);
  const showUploadCTA = adsAssetsSize < LISTING_FILES_LIMIT;

  function onUpload() {
    if (fileInputRef.current) fileInputRef.current.click();
  }

  return (
    <ListingInfoWrapper>
      <FtsRow rowGap={"lg"} width={"100%"}>
        <ListingInfoInlineError />
        <ListingImagesBanner />
        <FtsColumn size={{ xs: 12 }}>
          <Button
            width={"100%"}
            onClick={onUpload}
            size={ButtonSizes.md}
            label={"Upload Files"}
            disabled={!showUploadCTA}
            type={ButtonTypes.outlined}
            iconName={IconNames.Upload}
            color={ButtonColorsValue.accent}
          />
        </FtsColumn>
        <UploadedFiles
          mt={"sm"}
          adsAssets={adsAssets}
          listingData={listingData}
          setListingData={setListingData}
          uploadAdsAssetsRequest={uploadAdsAssetsRequest}
        />
        <UploadButton
          adsAssets={adsAssets}
          fileInputRef={fileInputRef}
          adsAssetsSize={adsAssetsSize}
          setListingData={setListingData}
          uploadAdsAssetsRequest={uploadAdsAssetsRequest}
        />
      </FtsRow>
    </ListingInfoWrapper>
  );
};
