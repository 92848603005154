import React from "react";
import { Modal } from "@find-truck-service/ui/src/components/react/Modal";
import { PaymentSuccessfulModalHeader } from "@find-truck-service/ui/src/components/react/PaymentSuccessfulModal/components/PaymentSuccessfulModalHeader";
import { PaymentSuccessfulModalFooter } from "@find-truck-service/ui/src/components/react/PaymentSuccessfulModal/components/PaymentSuccessfulModalFooter";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
export const PaymentSuccessfulModal = (props) => {
    const { isOpen, onClose, onBackdropClick } = props;
    return (React.createElement(Modal, { onBackdropClick: onBackdropClick, modalWrapperProps: {
            isOpen: isOpen,
        }, size: "md", isOpen: isOpen, modalHeaderComponent: () => React.createElement(PaymentSuccessfulModalHeader, { onClose: onClose }), modalContentComponent: EmptyComponent, modalFooterComponent: () => React.createElement(PaymentSuccessfulModalFooter, { onError: onClose }) }));
};
