import React from "react";
import { useSelector } from "react-redux";
import { AmenitiesModalContentFields } from "../../components/AmenitiesModalContentFields";
import { setTemporaryLinkEditListingData } from "../../../../../redux/actions/v2/temporaryLinkListing";
import { selectTemporaryLinkListingAdsAmenities } from "../../../../../redux/reducers/v2/temporaryListingLink";

export const TemporaryLinkAmenitiesModalContent = () => {
  const selectedAmenities = useSelector(selectTemporaryLinkListingAdsAmenities);

  return (
    <AmenitiesModalContentFields
      selectedAmenities={selectedAmenities || []}
      setListingData={setTemporaryLinkEditListingData}
    />
  );
};
