import FTSAxios from "../../../axios/fts.instance";

export const getAccountAccessRequest = (hash) => {
  return () => {
    return FTSAxios.get(`account-access-request/${hash}`);
  };
};

export const updateAccountAccessRequest = ({ id, status, expiresAt }) => {
  return () => {
    return FTSAxios.put(`account-access-request/${id}`, { status, expiresAt });
  };
};
