import React from "react";
import { useSelector } from "react-redux";
import { ListingImagesRowField } from "../../components/ListingImagesRowField";
import {
  setTemporaryLinkInvalidFormFields,
  setTemporaryLinkEditListingData,
  temporaryLinkUploadAdsAssetsRequest,
} from "../../../../../redux/actions/v2/temporaryLinkListing";
import {
  selectTemporaryLinkListingAdsAssets,
  selectTemporaryLinkEditListingData,
} from "../../../../../redux/reducers/v2/temporaryListingLink";

export const TemporaryLinkListingImagesRow = () => {
  const listingData = useSelector(selectTemporaryLinkEditListingData);
  const adsAssets = useSelector(selectTemporaryLinkListingAdsAssets);

  return (
    <ListingImagesRowField
      adsAssets={adsAssets || []}
      listingData={listingData || {}}
      adsAssetsSize={adsAssets?.length || 0}
      setListingData={setTemporaryLinkEditListingData}
      uploadAdsAssetsRequest={temporaryLinkUploadAdsAssetsRequest}
      setListingInvalidFormFields={setTemporaryLinkInvalidFormFields}
    />
  );
};
