import React, { useCallback, useState } from "react";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { Collapsible } from "@find-truck-service/ui/src/components/react/Collapsible";
import { PasswordValidator } from "@find-truck-service/ui/src/components/react/PasswordValidator";
import { useDispatch, useSelector } from "react-redux";
import { selectUpdatePasswordData } from "../../../redux/reducers/v2/updatePassword";
import {
  selectResetPasswordInvalidFormFields,
  selectResetPasswordIsLoading,
} from "../../../redux/reducers/v2/resetPassword";
import {
  setUpdatePasswordData,
  setUpdatePasswordInvalidFormFields,
  updatePasswordRequest,
} from "../../../redux/actions/v2/updatePassword";
import { toast } from "react-toastify";
import { changePasswordValidator } from "./validators/index";
import { isObject } from "lodash";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { extractParameter } from "../../../utility/param-extract";
import { history } from "../../../history";
import { HeaderWrapper } from "../HeaderWrapper";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import logger from "../../../utility/logger";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const updatePasswordData = useSelector(selectUpdatePasswordData);
  const invalidFormFields = useSelector(selectResetPasswordInvalidFormFields);
  const isLoading = useSelector(selectResetPasswordIsLoading);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const onChange = (event) => {
    dispatch(
      setUpdatePasswordData({
        data: { [event.target.name]: event.target.value },
      }),
    );
  };

  const onUpdatePasswordSuccess = () => {
    toast.success("Password updated successfully!");
    history.push("/dashboard");
  };

  const onUpdatePasswordFailure = (e) => {
    const message = e.response
      ? e.response.data.message
      : "Something went wrong!";

    toast.error(message);
  };

  const handleUpdatePassword = async () => {
    try {
      await changePasswordValidator.validate(updatePasswordData || {});
      const hash = extractParameter("token");

      dispatch(
        setUpdatePasswordInvalidFormFields({
          data: null,
        }),
      );
      dispatch(
        updatePasswordRequest({
          data: {
            hash,
            newPassword: updatePasswordData.newPassword,
          },
          onSuccess: onUpdatePasswordSuccess,
          onError: onUpdatePasswordFailure,
        }),
      );
    } catch (e) {
      logger.error(e);
      if (e.name === "ValidationError") {
        dispatch(
          setUpdatePasswordInvalidFormFields({
            data: {
              [e.path]: e.errors[0],
            },
          }),
        );
      }
    }
  };

  return (
    <FtsContainer>
      {isLoading && <FullPageLoader />}
      <HeaderWrapper />
      <FtsRow mt={{ lg: "4xl", md: "4xl", sm: "4xl", xs: "2xl" }}>
        <FtsColumn
          size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
          offset={{ xs: 0, sm: 0, md: 0, lg: 3 }}
          columnGap={"2xl"}
        >
          <FtsWrapper
            p={{
              xs: "2xl",
              md: "4xl",
              lg: "4xl",
            }}
            bg={ColorsValue["greyscale-0"]}
            boxShadow="sm"
            borderRadius="2xs"
          >
            <FtsColumn
              size={{ xs: 12, md: 12, lg: 12 }}
              columnGap={"2xl"}
              pb={"sm"}
            >
              <Span
                variant={VariantsValues["text-md"]}
                color={ColorsValue["greyscale-800"]}
                weight={WeightSize.bold}
              >
                Change Password
              </Span>
            </FtsColumn>
            <FtsColumn
              size={{ xs: 12, md: 12, lg: 12 }}
              columnGap={"2xl"}
              pb={{ xs: "2xl", md: "3xl", lg: "4xl" }}
            >
              <Span
                variant={VariantsValues["text-2xs"]}
                color={ColorsValue["greyscale-800"]}
                weight={WeightSize.regular}
              >
                Enter a new password to continue
              </Span>
            </FtsColumn>

            <FtsColumn size={{ xs: 12, md: 12, lg: 12 }} pb={"lg"}>
              <Input
                name={"newPassword"}
                onChange={onChange}
                label={"New Password"}
                required
                variant={InputVariants.outlined}
                value={updatePasswordData?.newPassword}
                size={InputSizes.md}
                appendIcon={IconNames.EyeIcon}
                onAppendIconClick={toggleShowPassword}
                type={showPassword ? "text" : "password"}
                errorMessage={
                  isObject(invalidFormFields) &&
                  invalidFormFields["newPassword"]
                }
              />
            </FtsColumn>

            <FtsColumn size={{ xl: 12, md: 12, sm: 12 }} mb={"2xl"}>
              <Collapsible show={true}>
                <PasswordValidator
                  password={updatePasswordData?.newPassword || ""}
                />
              </Collapsible>
            </FtsColumn>

            <FtsWrapper
              display={{ xs: "none", sm: "grid", md: "grid", lg: "grid" }}
            >
              <FtsRow columnGap={"lg"}>
                <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                  <Button
                    size={ButtonSizes.md}
                    color={ButtonColorsValue.accent}
                    type={ButtonTypes.contained}
                    label={"Update Password"}
                    width={"100%"}
                    onClick={() => handleUpdatePassword()}
                  />
                </FtsColumn>
              </FtsRow>
            </FtsWrapper>
          </FtsWrapper>
          <FtsWrapper
            display={{ xs: "grid", sm: "none", md: "none", lg: "none" }}
          >
            <FtsRow columnGap={"lg"} p={"xl"}>
              <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                <Button
                  size={ButtonSizes.md}
                  color={ButtonColorsValue.accent}
                  type={ButtonTypes.contained}
                  label={"Update Password"}
                  width={"100%"}
                  onClick={() => handleUpdatePassword()}
                />
              </FtsColumn>
            </FtsRow>
          </FtsWrapper>
        </FtsColumn>
      </FtsRow>
    </FtsContainer>
  );
};

export default ChangePassword;
