import { call, takeLatest } from "redux-saga/effects";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import { FLEET_PROFILE_UPDATE_REQUEST } from "../../reducers/v2/fleet-driver-profile";

function* updateFleetProfile(action) {
  const { data, onSuccess, onError } = action.payload;

  try {
    const response = yield call(FTSAxiosV2.put, "/users/update", {
      userData: data.userData,
      otherUserData: data.otherUserData,
    });
    onSuccess(response);
  } catch (e) {
    onError(e);
  }
}

export default function* updateFleetProfileWatcher() {
  yield takeLatest(FLEET_PROFILE_UPDATE_REQUEST, updateFleetProfile);
}
