import React from "react";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { Spacing } from "@find-truck-service/types/ui/spacing";

import { ColorsValue } from "@find-truck-service/types/ui/colors";

import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { capitalizeFirstLetter } from "../../../utility/googleCalendar";

import { RadioButton } from "@find-truck-service/ui/src/components/react/RadioButton";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

export const CardItem = (props) => {
  // let a = {
  //   adId: null,
  //   brand: "visa",
  //   createdAt: "2024-03-07T21:27:33.000Z",
  //   customerId: "cus_JFsGWRHEKfJmCK",
  //   expirationDate: "01/26",
  //   holderName: "Mike Medwed",
  //   id: 10,
  //   isDefault: false,
  //   lastFourDigital: "9720",
  //   ownerId: 36239,
  //   paymentGateway: "stripe",
  //   status: true,
  // };
  let {
    id,
    brand,
    isDefault,
    isSelected,
    expirationDate,
    lastFourDigital,
    setSelectedCardId,
  } = props;
  let expirationColor = "greyscale-500";
  const iconName = capitalizeFirstLetter(brand.toLowerCase().replace(/ /g, ""));

  return (
    <FtsColumn
      size={{ xs: 12 }}
      onClick={() => setSelectedCardId(id)}
      cursor="pointer"
    >
      <FtsWrapper
        display={"flex"}
        gap={"lg"}
        borderRadius={"2xs"}
        py={"lg"}
        px={"2xl"}
        border={`${isSelected ? Spacing["3xs"] : Spacing["4xs"]} solid ${isSelected ? ColorsValue["blue-accent-300"] : ColorsValue["greyscale-100"]}`}
        justify="space-between"
        bg={isSelected ? ColorsValue["greyscale-50"] : ""}
      >
        <FtsWrapper display="flex" gap={"lg"} align="center">
          <FtsIcons iconName={iconName} svgProps={{ width: 47, height: 32 }} />
          <FtsWrapper display={"flex"} gap={"2xs"} direction={"column"}>
            <Span
              variant={VariantsValues["text-2xs"]}
              color={ColorsValue["greyscale-800"]}
              weight={WeightSize.medium}
            >
              {`${brand} (${lastFourDigital})`}{" "}
              {isDefault ? (
                <Span
                  variant={VariantsValues["text-3xs"]}
                  color={ColorsValue["blue-accent-500"]}
                >
                  Default
                </Span>
              ) : null}
            </Span>
            <Span
              align={"start"}
              variant={VariantsValues["text-3xs"]}
              color={expirationColor}
            >
              {expirationDate}
            </Span>
          </FtsWrapper>
        </FtsWrapper>
        <RadioButton size={"md"} checked={isSelected} />
      </FtsWrapper>
    </FtsColumn>
  );
};
//todo use this component
// <SelectableBox
//   id={accountType.id}
//   setSelectedAccountType={handleSelectAccountType}
//   isSelected={accountType.id === selectedAccountType}
//   key={index}
//   title={accountType.categoryName}
//   subtitle={accountType.description}
// />
