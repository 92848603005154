import React, { memo, PureComponent } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { Field, ErrorMessage } from "formik";
import classnames from "classnames";
import TextareaAutosize from "react-textarea-autosize";

import FormikInputValue from "./formik-input-value";
import { capitalizeFirstLetter } from "../../../utility/googleCalendar";

let FIELDS_FOR_CAPITALIZE_VALUE = ["city", "address"];
const extractPartsFromName = (name) => {
  const parts = name.split(".");
  const part1 = parts[0];
  const part2 = parseInt(parts[1]);
  const part3 = parts[2];
  return { part1, part2, part3 };
};

const isFieldArrayInput = (name, errors, touched) => {
  if (name.indexOf(".") > -1) {
    const { part1, part2, part3 } = extractPartsFromName(name);
    if (errors[part1] && errors[part1][part2]) {
      return errors[part1][part2][part3] && touched[part1][part2][part3];
    }
  }
  return null;
};

const returnErrorTouched = (prevProps, nextProps) => {
  if (prevProps.field.name.indexOf(".") > -1) {
    const { part1, part2, part3 } = extractPartsFromName(prevProps.field.name);
    return (
      prevProps.form.errors[part1] &&
      nextProps.form.errors[part1] &&
      prevProps.form.errors[part1][part2] &&
      nextProps.form.errors[part1][part2] &&
      nextProps.form.errors[part1][part2][part3] &&
      prevProps.form.errors[part1][part2][part3] &&
      nextProps.form.touched[part1][part2][part3] &&
      prevProps.form.touched[part1][part2][part3]
    );
  } else {
    return (
      prevProps.form.errors[prevProps.field.name] ===
        nextProps.form.errors[nextProps.field.name] &&
      prevProps.form.touched[prevProps.field.name] ===
        nextProps.form.touched[nextProps.field.name]
    );
  }
};

class FormikInput extends PureComponent {
  render() {
    return (
      <Field
        {...this.props}
        component={memo(FormInput, (prevProps, nextProps) => {
          return (
            prevProps.field.value === nextProps.field.value &&
            returnErrorTouched(prevProps, nextProps)
          );
        })}
      />
    );
  }
}

export const FormInput = ({
  className,
  inputClassName,
  labelId,
  label,
  field,
  noMargin,
  required,
  form: { errors, touched, setFieldValue },
  disabled,
  autoComplete,
  prepend,
  editMode,
  formikInputValueId,
  ...props
}) => {
  const fieldArrayInput = isFieldArrayInput(field.name, errors, touched);
  const InputField = props.type === "textarea" ? TextareaAutosize : Input;

  return (
    <FormGroup className={classnames(className, { "mb-0": !!noMargin })}>
      {!!label && (
        <Label className={disabled ? "disabled" : ""} for={labelId}>
          {required && !disabled ? `${label} *` : label}
        </Label>
      )}
      {(field.value || editMode) && prepend && (
        <Prepend prepend={prepend} disabled={disabled} />
      )}
      {disabled ? (
        <FormikInputValue
          formikInputValueId={formikInputValueId}
          prepend={field.value && prepend}
          className={`${prepend && "formik-input-field-prepend"}`}
        >
          {field.value || "-"}
        </FormikInputValue>
      ) : (
        <InputField
          {...(props.type === "textarea"
            ? { minRows: props.rows ? props.rows : 5 }
            : {})}
          className={classnames(inputClassName, {
            "invalid-formik-input":
              fieldArrayInput !== null
                ? fieldArrayInput
                : errors[field.name] && touched[field.name],
            prepend: prepend && "prepend",
          })}
          id={labelId}
          {...field}
          {...props}
          onBlur={(e) => {
            let isFieldForCapitalize = FIELDS_FOR_CAPITALIZE_VALUE.find(
              (item) => item === e.target.name
            );
            if (isFieldForCapitalize) {
              let newValue = capitalizeInfoValues(e.target.value);
              setFieldValue(e.target.name, newValue);
            }
            if (props.onBlur && typeof props.onBlur === "function") {
              return props.onBlur(e);
            }
            return field.onBlur(e);
          }}
          disabled={disabled}
          placeholder={disabled ? "-" : props.placeholder}
          autoComplete={autoComplete ? autoComplete : "new-text"}
        />
      )}
      <ErrorMessage
        name={field.name}
        render={(msg) => <div className="formik-validation-error">{msg}</div>}
      />
    </FormGroup>
  );
};

FormInput.defaultProps = {
  inputClassName: "form-control",
};

export default FormikInput;

const Prepend = ({ prepend, disabled }) => {
  return (
    <div
      className={classnames("prepend-form-control-position ", {
        disabled: disabled && "disabled",
      })}
    >
      <div className="prepend-span-nav">
        <span className={`prepend-span`}>{prepend}</span>
      </div>
    </div>
  );
};

let capitalizeInfoValues = (values) => {
  if (!values) return "";
  if (values.length === 2) return values.toUpperCase();
  let newValues = values.split(" ");
  return newValues.map((e) => capitalizeFirstLetter(e)).join(" ");
};
