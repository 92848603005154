import queryString from "query-string";

export const GET_REMINDERS_REQUEST = "reminders/GET_POST_LIST_REQUEST";
export const GET_REMINDERS_SUCCESS = "reminders/GET_REMINDERS_SUCCESS";
export const GET_REMINDERS_BY_TYPE_SUCCESS =
  "reminders/GET_REMINDERS_BY_TYPE_SUCCESS";
export const GET_REMINDERS_FAILED = "reminders/GET_REMINDERS_FAILED";
export const SET_QUERY_STRING = "reminders/SET_QUERY_STRING";
export const REMOVE_REMINDERS = "reminders/REMOVE_REMINDERS";
export const UPDATE_REMINDER = "reminders/UPDATE_REMINDER";
export const RESET_QUERY_STRING = "reminders/RESET_QUERY_STRING";

const initialState = {
  isLoading: false,
  reminders: null,
  queryString: "",
};

const remindersReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REMINDER: {
      let reminders = [...state.reminders[action.payload.todoType].todos];
      let index = reminders.findIndex((e) => e.id === action.payload.id);
      reminders[index] = action.payload;
      let newReminders = {
        ...state.reminders,
        [action.payload.todoType]: {
          todos: reminders,
          count: state.reminders[action.payload.todoType].count + 1,
        },
      };
      return {
        ...state,
        reminders: newReminders,
      };
    }
    case GET_REMINDERS_REQUEST:
      return { ...state };
    case GET_REMINDERS_SUCCESS: {
      let reminders = state.reminders
        ? { ...state.reminders, [action.payload.todoType]: action.payload.data }
        : { [action.payload.todoType]: action.payload.data };
      return {
        ...state,
        isLoading: false,
        reminders,
      };
    }
    case GET_REMINDERS_BY_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reminders: { [action.payload.todoType]: action.payload.data },
      };
    }
    case GET_REMINDERS_FAILED:
      return { ...state, isLoading: false, reminders: null };
    case SET_QUERY_STRING:
      const queryObj = queryString.parse(state.queryString);
      let newQuery = { ...queryObj, ...action.payload };

      return { ...state, queryString: queryString.stringify(newQuery) };

    case RESET_QUERY_STRING:
      return { ...state, queryString: "" };
    case REMOVE_REMINDERS:
      return initialState;

    default:
      return state;
  }
};

export default remindersReducer;
