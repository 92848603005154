import {
  SET_USER_TODOS,
  UNARCHIVE_USER_TODO,
  REMOVE_USER_TODO,
} from "../../actions/user-todos";

const initialState = {
  list: [],
};

const UserTodosReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_TODOS:
      return { ...state, list: action.data };

    case REMOVE_USER_TODO:
      return {
        ...state,
        list: state.list.filter((todo) => todo.id !== action.data.id),
      };

    case UNARCHIVE_USER_TODO: {
      return {
        ...state,
        list: [...state.list, action.data],
      };
    }

    default:
      return state;
  }
};

export default UserTodosReducer;
