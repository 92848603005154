import {
  CREATE_ACCOUNT_FAILED,
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_SUCCESS,
  SET_CREATE_ACCOUNT_DATA,
  SET_CREATE_ACCOUNT_EMAIL_ALREADY_USED,
  SET_CREATE_ACCOUNT_INVALID_FORM_FIELDS,
} from "../../../reducers/v2/createAccount";

export const setCreateAccountData = (payload) => ({
  type: SET_CREATE_ACCOUNT_DATA,
  payload,
});

export const setCreateAccountInvalidFormFields = (payload) => ({
  type: SET_CREATE_ACCOUNT_INVALID_FORM_FIELDS,
  payload,
});

export const setCreateAccountEmailAlreadyUsed = (payload) => ({
  type: SET_CREATE_ACCOUNT_EMAIL_ALREADY_USED,
  payload,
});

export const createAccountRequest = (payload) => ({
  type: CREATE_ACCOUNT_REQUEST,
  payload,
});

export const createAccountSuccess = (payload) => ({
  type: CREATE_ACCOUNT_SUCCESS,
  payload,
});

export const createAccountFailed = (payload) => ({
  type: CREATE_ACCOUNT_FAILED,
  payload,
});
