/* eslint-disable indent */
import React, { useState, useEffect } from "react";

import FTSTableHeader from "./FTSTableHeader";
import FTSTableBody from "./FTSTableBody";
import FTSTableFooter from "./FTSTableFooter";

import moment from "moment";

import "./style.css";
import "../../../assets/scss/plugins/tables/_agGridStyleOverride.scss";
import { REPAIR_STATUSES } from "../../../views/pages/repairs/constants";

const getDevice = (currentWidth) => {
  return currentWidth < 576
    ? "small"
    : currentWidth < 768
    ? "phone"
    : currentWidth < 992
    ? "tablet"
    : "desktop";
};

const FTSDataTable = (props) => {
  let {
    noPagination,
    // FTSTableHeader props
    filterSize,
    searchVal,
    updateSearchQuery,
    searchValType,
    pageSizePropName,
    pageSizes,
    onCreateNewPress,
    filterChips,
    longSearch,
    isListing,
    searchPlaceholder,
    accountId,

    // FTSTableBody props
    onSortChanged,
    getRowHeight,
    rowData,
    columnDefs,
    defaultColDef,
    onGridReadyInit,
    saveState,
    onRowClicked,
    getSelectedAccounts,
    getSelectedActiveLeads,
    overlayNoRowsTemplate = "<span>No results for this specific search.</span>",
    colResizeDefault = "shift",
    suppressPaginationPanel = true,
    animateRows = true,
    getSelectedInvoices,
    rowSelection,
    pagination = true,
    resizable = true,
    pivotPanelShow = "always",
    renewalsTab,
    upcomingTab,
    updatesTab,
    salesTab,
    promoCodesTab,
    invoicesTab,
    emailsTab,
    getTo,
    reporting,
    referralsUsed,
    referralsNotUsed,
    myListings,
    recentSearchesDashboard,
    clientReports,
    clientNotes,
    callLogs,
    sharedVendors,
    preferredVendors,
    preferredVendorsDashboard,
    repairs,
    recommendations,
    clientTrucks,
    clientDrivers,
    previousDrivers,

    // FTSTableFooter props
    pageCount,
    currentPage,
    totalRecords,
    onPageChange,

    // shared props
    currentPageSize,

    // other props
    resetApi,
    isSearch,
    tableName,
    activeAccount,
    totalAmount,
    onScroll = () => {},
    subAcc,
    rowHeight,
    domLayout,
  } = props;

  const [deviceType, setDeviceType] = useState(getDevice(window.innerWidth));

  const updateDeviceType = () => {
    setDeviceType(getDevice(window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener("resize", updateDeviceType);
    return () => {
      window.removeEventListener("resize", updateDeviceType);
      resetApi && resetApi(tableName);
    };
  }, []);

  const headerProps = {
    totalAmount,
    searchVal,
    updateSearchQuery,
    searchValType,
    onCreateNewPress,
    tableName,
    searchPlaceholder,
    longSearch,
    isListing,
    filterChips,
    accountId,
  };

  const upcomingRowClassRules = {
    "row-renewals-red": (params) => {
      return params.data.excludeRenewalEmails || !params.data.email;
    },
  };

  const renewalsRowClassRules = {};

  const updatesRowClassRules = {
    "row-update-red": (params) => {
      const now = moment();
      const days = now.diff(moment(params.data.updatedAt), "days");
      return days > getTo() && tableName === "updates";
    },
  };

  const clientReportsRowClassRules = {
    "row-inactive-red": (params) => {
      let difference;

      difference = moment(
        params.data?.accountReport?.expirationDate ||
          params.data?.listingReport?.expirationDate ||
          params.data?.listing?.expirationDate ||
          params.data?.expirationDate,
      ).diff(moment(), "days");

      return difference < 1;
    },
    "row-active-green": (params) => {
      let difference;

      difference = moment(
        params.data?.expirationDate ||
          params.data?.accountReport?.expirationDate ||
          params.data?.listing?.expirationDate ||
          params.data?.listingReport?.expirationDate,
      ).diff(moment(), "days");

      return difference > 0;
    },
  };

  // const promoCodesRowClassRules = {
  //   "row-suspended": (params) => {
  //     const now = moment();
  //     return params.data.status === 0 || now.isAfter(moment(params.data.endAt));
  //   },
  //   "row-active": (params) => {
  //     const now = moment();
  //     return (
  //       params.data.status === 1 && now.isBefore(moment(params.data.endAt))
  //     );
  //   },
  // };

  const rowClassRulesSubUser = {
    "row-active-green": (params) => {
      return params.data.status === 1;
    },
    "row-inactive-red": (params) => {
      return params.data.status === 0;
    },
  };

  const rowClassRulesMyListings = {
    "row-active-listings-green": () => {
      return true;
    },
  };

  const rowClassRulesReferralsUseed = {
    "row-active": () => {
      return true;
    },
  };

  const rowClassRulesReferralsNotUseed = {
    "row-pending": () => {
      return true;
    },
  };

  const rowClassUserNotes = {
    "row-note-border-green": (params) => {
      return params.data.rating === "positive";
    },

    "row-note-border-red": (params) => {
      return params.data.rating === "negative";
    },

    "row-note-border-gray": (params) => {
      return params.data.rating === "neutral";
    },
  };

  const rowClassRules = {
    "row-active": (params) => {
      if (params.data.invoiceNumber) return;

      return (
        params.data.statusString === "Active" ||
        params.data.status === 1 ||
        params.data.status === "Active" ||
        params.data.statusString === "Enabled"
      );
    },

    "row-disabled": (params) => {
      if (params.data.invoiceNumber) return;

      return (
        params.data.statusString === "Disabled" ||
        (params.data.status === 0 && !tableName === "promotion")
      );
    },

    "row-gray-out": (params) => {
      const startAt = moment(params.data.validFrom);
      const now = moment();
      if (params.data.invoiceNumber) return;

      return (
        (params.data.status === "Inactive" || params.data.status === 0) &&
        tableName === "promotion" &&
        startAt > now
      );
    },

    "row-warning": (params) => {
      const startAt = moment(params.data.validFrom);
      const endAt = moment(params.data.validTo);
      const now = moment();
      if (params.data.invoiceNumber) return;

      return (
        (params.data.status === "Inactive" || params.data.status === 0) &&
        startAt < now &&
        endAt > now
      );
    },

    "row-suspended": (params) => {
      if (params.data.invoiceNumber) return;
      return (
        params.data.statusString === "Suspended" ||
        params.data.status === 2 ||
        moment(params.data.validTo) < moment()
      );
    },
  };

  const rowClassUserCallLogs = {
    "row-call-logs": () => true,
  };

  const rowClassRulesPreferredVendors = {
    "row-active": () => true,
  };

  const rowClassRulesPreferredVendorsDashboard = {
    "row-border-green": () => true,
  };

  const rowClassClientTrucks = {
    "row-note-border-red": (params) => {
      return params.data.status === 1;
    },

    "row-note-border-green": (params) => {
      return params.data.status === 2;
    },
  };

  const rowClassClientDrivers = {
    "row-note-border-green": (params) => {
      return params.data.truck?.id;
    },

    "row-note-border-red": (params) => {
      return !params.data.truck?.id;
    },
  };

  const rowClassPreviousDrivers = {
    "row-gray-out": () => true,
  };

  const rowClassRecentSearchesDashboard = {
    "row-broder-red": () => true,
  };

  const rowClassRepairs = {
    "row-note-border-green": (params) => {
      return params.data.status === REPAIR_STATUSES.COMPLETED;
    },

    "row-note-border-red": (params) => {
      return params.data.status === REPAIR_STATUSES.CANCELED;
    },

    "row-note-border-gray": (params) => {
      return params.data.status === REPAIR_STATUSES.STARTED;
    },
  };

  let rowClassRulesTemp = rowClassRules;
  if (subAcc) rowClassRulesTemp = rowClassRulesSubUser;

  if (renewalsTab) {
    rowClassRulesTemp = renewalsRowClassRules;
  }

  if (upcomingTab) {
    rowClassRulesTemp = upcomingRowClassRules;
  }

  if (updatesTab) {
    rowClassRulesTemp = updatesRowClassRules;
  }

  if (promoCodesTab) {
    rowClassRulesTemp = null;
  }

  if (salesTab) {
    rowClassRulesTemp = null;
  }

  if (invoicesTab) {
    rowClassRulesTemp = null;
  }

  if (emailsTab) {
    rowClassRulesTemp = null;
  }

  if (reporting) {
    rowClassRulesTemp = null;
  }

  if (activeAccount) {
    rowClassRulesTemp = {
      "row-active": (params) => {
        return !params.data.activities.length;
      },
    };
  }

  if (referralsUsed) {
    rowClassRulesTemp = rowClassRulesReferralsUseed;
  }

  if (referralsNotUsed) {
    rowClassRulesTemp = rowClassRulesReferralsNotUseed;
  }

  if (myListings) {
    rowClassRulesTemp = rowClassRulesMyListings;
  }

  if (clientReports) {
    rowClassRulesTemp = clientReportsRowClassRules;
  }

  if (clientNotes) {
    rowClassRulesTemp = rowClassUserNotes;
  }

  if (callLogs) {
    rowClassRulesTemp = rowClassUserCallLogs;
  }

  if (sharedVendors) {
    rowClassRulesTemp = rowClassUserCallLogs;
  }

  if (preferredVendors) {
    rowClassRulesTemp = rowClassRulesPreferredVendors;
  }

  if (recommendations) {
    rowClassRulesTemp = rowClassUserCallLogs;
  }

  if (clientTrucks) {
    rowClassRulesTemp = rowClassClientTrucks;
  }

  if (clientDrivers) {
    rowClassRulesTemp = rowClassClientDrivers;
  }

  if (previousDrivers) {
    rowClassRulesTemp = rowClassPreviousDrivers;
  }

  if (recentSearchesDashboard) {
    rowClassRulesTemp = rowClassRecentSearchesDashboard;
  }

  if (preferredVendorsDashboard) {
    rowClassRulesTemp = rowClassRulesPreferredVendorsDashboard;
  }

  if (repairs) {
    rowClassRulesTemp = rowClassRepairs;
  }

  const bodyProps = {
    tableName,
    getRowHeight,
    rowData,
    columnDefs,
    rowSelection,
    getSelectedInvoices,
    defaultColDef,
    onGridReady: onGridReadyInit,
    saveState: saveState,
    onRowClicked,
    getSelectedAccounts,
    getSelectedActiveLeads,
    colResizeDefault,
    suppressPaginationPanel,
    animateRows,
    pagination,
    overlayNoRowsTemplate,
    pivotPanelShow,
    rowHeight: rowHeight || 70,
    paginationPageSize: currentPageSize,
    resizable,
    onSortChanged,
    rowClassRules: rowClassRulesTemp,
    suppressRowHoverHighlight: myListings || false,
    domLayout,
  };

  const footerProps = {
    filterSize,
    pageSizePropName,
    currentPageSize,
    pageSizes,
    pageCount,
    currentPage,
    totalRecords,
    onPageChange,
    deviceType,
    tableName,
  };
  return (
    <>
      <div
        className="ag-theme-material ag-grid-table"
        onScroll={() => {
          onScroll();
        }}
      >
        {!isSearch && <FTSTableHeader {...headerProps} />}
        <FTSTableBody {...bodyProps} />
      </div>
      {!noPagination && <FTSTableFooter {...footerProps} />}
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.currentPageSize === nextProps.currentPageSize &&
    prevProps.searchVal === nextProps.searchVal &&
    prevProps.totalRecords === nextProps.totalRecords &&
    prevProps.currentPage === nextProps.currentPage &&
    prevProps.rowData === nextProps.rowData &&
    prevProps.filterChips === nextProps.filterChips &&
    prevProps.pageCount === nextProps.pageCount &&
    prevProps.overlayNoRowsTemplate === nextProps.overlayNoRowsTemplate
  );
};

export default React.memo(FTSDataTable, areEqual);
