import React from "react";
import { connect } from "react-redux";
import LinkSuccessfullCard from "../../../components/fts/link-successfull-card";
import "../../../assets/scss/pages/link-successfull.scss";
import { acceptInvitation } from "../../../redux/actions/drivers";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import FTSCookies, {
  removeAllCookies,
  TEMPORARY_TOKEN,
} from "../../../cookies";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import logger from "../../../utility/logger";

class FleetInvitation extends React.Component {
  state = {
    inProgress: false,
    isLoggedIn: false,
    linkSuccessFullCardProps: {
      heading: "Your account has been linked",
      message: "Your account has been linked to {{fleet_name}}",
      description: "Log into your account.",
      buttonText: "Log into your account",
    },
  };

  handleRedirect = () => {
    window.location.href = "/dashboard";
  };

  componentDidMount() {
    this.setState({
      inProgress: true,
    });
    const { linkSuccessFullCardProps } = this.state;
    (async () => {
      try {
        await removeAllCookies();
        let { hash: token } = this.props.match.params;
        await FTSCookies.set(TEMPORARY_TOKEN, token, { path: "/" });

        const { data } = await this.props.acceptInvitation();

        if (this.props?.userData?.id === data.driverId) {
          linkSuccessFullCardProps.message = `Your account has been linked to company:${data.owner.companyName}`;
          linkSuccessFullCardProps.description =
            "Click on “go to dashboard” to go to your Find Truck Service account.";
          linkSuccessFullCardProps.buttonText = "Go to dashboard";
          this.setState({ isLoggedIn: true, linkSuccessFullCardProps });
          return;
        }

        linkSuccessFullCardProps.message = `Your account has been linked to ${data.owner.companyName}`;
      } catch (e) {
        logger.error(e);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } finally {
        this.setState({
          inProgress: false,
        });
      }
    })();
  }

  render() {
    const { inProgress, linkSuccessFullCardProps } = this.state;

    return (
      <>
        <HeaderWrapper />
        <div className="fts-link-successfull-wrapper">
          {!inProgress ? (
            <LinkSuccessfullCard
              {...linkSuccessFullCardProps}
              action={this.handleRedirect}
            />
          ) : (
            <Spinner size="lg" />
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.authState.user,
  };
};
export default connect(mapStateToProps, { acceptInvitation })(FleetInvitation);
