import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import "../../../assets/scss/pages/account-access-request.scss";
import FTSCookies, {
  AUTH_KEY,
  REFRESH_KEY,
  removeAllCookies,
} from "../../../cookies";
import { Spinner } from "reactstrap";
import { extractParameter } from "../../../utility/param-extract";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import { history } from "../../../history";
import { setLoggedInUserData } from "../../../redux/actions/auth/loginActions";
import collectEssentialData from "../../../axios/collect-data";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import logger from "../../../utility/logger";

const RemoteAccessLogin = ({ setLoggedInUserData }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    async function handleRemoteLogin() {
      await removeAllCookies();
      const accessToken = extractParameter("accessToken");
      const refreshToken = extractParameter("refreshToken");

      logger.info("ACCES", accessToken);
      logger.info("REFRESH", refreshToken);

      const path = "/";
      await FTSCookies.set(AUTH_KEY, accessToken, { path });
      await FTSCookies.set(REFRESH_KEY, refreshToken, { path });

      const {
        data: { data: user },
      } = await FTSAxiosV2.get("/users/me");

      await collectEssentialData(dispatch);

      setLoggedInUserData({
        loggedInUser: user,
        loggedInWith: "jwt",
      });

      localStorage.setItem("loggedInWith", "jwt");
      history.push("/dashboard");

      return;
    }

    handleRemoteLogin();
  }, [setLoggedInUserData]);

  return (
    <div
      className="d-flex flex-column position-relative w-100 bg-theme"
      style={{ height: "100vh" }}
    >
      <div style={{ flex: "1 1 0%" }}>
        <HeaderWrapper />
        <Spinner size={"lg"} />
      </div>
    </div>
  );
};
export default connect(null, { setLoggedInUserData })(RemoteAccessLogin);
