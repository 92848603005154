import { setUserData } from "../../actions/v2/auth";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import { loginFailed, loginSuccess } from "../../actions/v2/login";
import { LOGIN_REQUEST, selectLoginData } from "../../reducers/v2/login";
import { all, call, put, select, take, takeLatest } from "redux-saga/effects";
import {
  COLLECT_ESSENTIALS_SUCCESS,
  collectEssentialsRequest,
} from "../../actions/v2/essentials";
import { setLoggedUserCookie } from "../../../cookies";
import { history } from "../../../history";
import logger from "../../../utility/logger";

const LOGIN_ERROR_MSG = "Error while logging in";
const getErrorMsg = (e, defaultMessage) =>
  e.response ? e.response.data.message : defaultMessage;

function* loginSaga() {
  try {
    const loginData = yield select(selectLoginData);
    const response = yield call(FTSAxiosV2.post, `/auth/signin`, loginData);
    const { user, bearerToken } = response.data.data;
    setLoggedUserCookie(user, bearerToken);

    yield all([
      put(collectEssentialsRequest()),
      take(COLLECT_ESSENTIALS_SUCCESS),
    ]);
    yield put(setUserData({ data: user }));

    yield put(loginSuccess());
  } catch (e) {
    if (e.response?.data?.redirectUrl) {
      yield put(loginSuccess());
      return history.push(e.response?.data?.redirectUrl);
    }
    logger.error("Login error", e);
    const message = getErrorMsg(e, LOGIN_ERROR_MSG);
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(loginFailed({ data: { message, inlineMessage } }));
  }
}

export function* loginWatcher() {
  yield takeLatest(LOGIN_REQUEST, loginSaga);
}
