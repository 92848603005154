import { createSelector } from "reselect";

export const COMPANY_DRIVER_PROFILE_SET_FIELD_VALUES =
  "COMPANY_DRIVER_PROFILE/SET_FIELD_VALUES";
export const COMPANY_DRIVER_PROFILE_UPDATE_REQUEST =
  "COMPANY_DRIVER_PROFILE/UPDATE_REQUEST";
export const COMPANY_DRIVER_PROFILE_UPDATE_SUCCESS =
  "COMPANY_DRIVER_PROFILE/UPDATE_SUCCESS";
export const COMPANY_DRIVER_PROFILE_UPDATE_FAILED =
  "COMPANY_DRIVER_PROFILE/UPDATE_FAILED";
export const COMPANY_DRIVER_PROFILE_SET_INVALID_FORM_FIELDS =
  "COMPANY_DRIVER_PROFILE/SET_INVALID_FORM_FIELDS";
export const COMPANY_DRIVER_PROFILE_SET_UPDATE_LOADING =
  "COMPANY_DRIVER_PROFILE/SET_UPDATE_LOADING";
export const COMPANY_DRIVER_PROFILE_SET_FINISH_LATER_LOADING =
  "COMPANY_DRIVER_PROFILE/SET_FINISH_LATER_LOADING";

const initialState = {
  updateLoading: false,
  finishLaterLoading: false,
  error: null,
  data: null,
  invalidFormFields: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COMPANY_DRIVER_PROFILE_SET_FIELD_VALUES:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    case COMPANY_DRIVER_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        invalidFormFields: null,
      };

    case COMPANY_DRIVER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        error: null,
        invalidFormFields: null,
        updateLoading: false,
        finishLaterLoading: false,
      };
    case COMPANY_DRIVER_PROFILE_UPDATE_FAILED:
      return {
        ...state,
        error: action.payload,
        invalidFormFields: null,
        updateLoading: false,
        finishLaterLoading: false,
      };

    case COMPANY_DRIVER_PROFILE_SET_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: action.payload.data,
      };

    case COMPANY_DRIVER_PROFILE_SET_UPDATE_LOADING:
      return {
        ...state,
        updateLoading: action.payload.data,
      };
    case COMPANY_DRIVER_PROFILE_SET_FINISH_LATER_LOADING:
      return {
        ...state,
        finishLaterLoading: action.payload.data,
      };

    default:
      return state;
  }
}

const selectCompanyDriverProfileState = (state) =>
  state.companyDriverProfileState;

export const selectCompanyDriverProfileData = createSelector(
  selectCompanyDriverProfileState,
  (companyDriverProfile) => {
    return companyDriverProfile.data;
  },
);

export const selectCompanyDriverProfileInvalidFormFields = createSelector(
  selectCompanyDriverProfileState,
  (companyDriverProfile) => {
    return companyDriverProfile.invalidFormFields;
  },
);

export const selectCompanyDriverProfileUpdateLoading = createSelector(
  selectCompanyDriverProfileState,
  (companyDriverProfile) => {
    return companyDriverProfile.updateLoading;
  },
);

export const selectCompanyDriverProfileFinishLaterLoading = createSelector(
  selectCompanyDriverProfileState,
  (companyDriverProfile) => {
    return companyDriverProfile.finishLaterLoading;
  },
);
