import {
  SOCIAL_AUTH_FAILED,
  SOCIAL_AUTH_REQUEST,
  SOCIAL_AUTH_SUCCESS,
} from "../../../reducers/v2/social-auth";

export const socialAuthRequest = (payload) => ({
  type: SOCIAL_AUTH_REQUEST,
  payload,
});

export const socialAuthSuccess = (payload) => ({
  type: SOCIAL_AUTH_SUCCESS,
  payload,
});

export const socialAuthFailed = (payload) => ({
  type: SOCIAL_AUTH_FAILED,
  payload,
});
