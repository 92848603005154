const clearSearchParams = (history) => {
  const searchParams = new URLSearchParams(history.location.search);
  searchParams.forEach((value, key) => {
    searchParams.delete(key);
  });

  history.push({
    pathname: history.location.pathname,
    search: searchParams.toString(),
  });
};
export const clearAllSearchParams = (history) => {
  history.push({
    pathname: history.location.pathname,
    search: "",
  });
};

export default clearSearchParams;
