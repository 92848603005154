import React from "react";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { useSelector } from "react-redux";
import {
  selectOwnerOperatorProfileFinishLaterLoading,
  selectOwnerOperatorProfileUpdateLoading,
} from "../../../../redux/reducers/v2/owner-operator-profile";

const TruckFormButtonsXs = ({
  handleUpdateOwnerOperatorTruck,
  handleOwnerOperatorTruckFinishLater,
}) => {
  const ownerOperatorUpdateLoading = useSelector(
    selectOwnerOperatorProfileUpdateLoading,
  );
  const ownerOperatorFinishLaterLoading = useSelector(
    selectOwnerOperatorProfileFinishLaterLoading,
  );

  return (
    <FtsWrapper
      display={{ xs: "grid", sm: "none", md: "none", lg: "none" }}
      p={"2xl"}
    >
      <FtsColumn size={{ xs: 6, md: 6, lg: 6 }}>
        <Button
          onClick={handleUpdateOwnerOperatorTruck}
          size={ButtonSizes.md}
          color={ButtonColorsValue.accent}
          type={ButtonTypes.contained}
          label={"Save"}
          width={"100%"}
          loading={ownerOperatorUpdateLoading}
          disabled={
            ownerOperatorFinishLaterLoading || ownerOperatorUpdateLoading
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6, md: 6, lg: 6 }}>
        <Button
          onClick={handleOwnerOperatorTruckFinishLater}
          size={ButtonSizes.md}
          color={ButtonColorsValue.neutral}
          type={ButtonTypes.outlined}
          label={"Finish Later"}
          width={"100%"}
          mt={"2xl"}
          loading={ownerOperatorFinishLaterLoading}
          disabled={
            ownerOperatorFinishLaterLoading || ownerOperatorUpdateLoading
          }
        />
      </FtsColumn>
    </FtsWrapper>
  );
};
export default TruckFormButtonsXs;
