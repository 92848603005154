import React from "react";
import { useSelector } from "react-redux";
import { ListingInfoRatesFields } from "./ListingInfoRatesFields";
import { setListingData } from "../../../../redux/actions/v2/listing";
import { selectListingAdsRates } from "../../../../redux/reducers/v2/listing";

export const ListingInfoRatesRow = () => {
  const listingAdsRates = useSelector(selectListingAdsRates);

  return (
    <ListingInfoRatesFields
      listingAdsRates={listingAdsRates}
      setListingData={setListingData}
    />
  );
};
