import React from "react";
import LoadingSkeleton from "react-loading-skeleton";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
export const InvoiceSkeletonHeader = () => {
    return (React.createElement(FtsWrapper, { display: "flex", gap: "lg" },
        React.createElement(LoadingSkeleton, { duration: 1, width: 48, height: 48 }),
        React.createElement(FtsWrapper, { display: "flex", align: "flex-start", justify: "center", direction: "column" },
            React.createElement(LoadingSkeleton, { duration: 1, width: 200, height: 16 }),
            React.createElement(LoadingSkeleton, { duration: 1, width: 100, height: 16 }))));
};
