import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { format } from "date-fns";
import { getStoreStatus } from "./helper";
import { toast } from "react-toastify";
import { getListingCurrentHours } from "../../../redux/actions/listings";
import logger from "../../../utility/logger";

const WorkingHoursPreview = ({
  alwaysOpen,
  workingHours,
  listingCurrentTime,
  getListingCurrentHours,
  listingData,
  specialHours,
  mobileRoadServiceOpen,
  towingRecoveryOpen,
}) => {
  const [currentTime, setCurrentTime] = useState(listingCurrentTime);

  const day = format(new Date(), "EEEE").toLowerCase();
  const currentHours = !alwaysOpen && workingHours[day];

  useEffect(() => {
    if (!currentTime) {
      (async () => {
        try {
          const { data: currentHours } = await getListingCurrentHours({
            city: listingData?.city,
            state:
              typeof listingData?.state === "object"
                ? listingData?.state?.value
                : listingData?.state,
            latitude: listingData?.latitude,
            longitude: listingData?.longitude,
          });
          setCurrentTime(currentHours);
        } catch (e) {
          logger.error(e);
          toast.error("Error while getting listing current time");
        }
      })();
    }
  }, []);
  const storeStatus = getStoreStatus({
    currentHours,
    listingCurrentTime,
    specialHours,
    mobileRoadServiceOpen,
    towingRecoveryOpen,
  });

  if (alwaysOpen) {
    return (
      <span>
        <span style={{ color: "#128548" }}>Open 24 Hours</span>
      </span>
    );
  }

  return (
    <Fragment>
      <span>{storeStatus}</span>
    </Fragment>
  );
};

export default connect(null, { getListingCurrentHours })(WorkingHoursPreview);
