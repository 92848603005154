import React from "react";

import { Button } from "reactstrap";

import Icon from "../../../components/fts/Icons";
import "../../../assets/scss/pages/fts-link-expired.scss";
import FtsContactFromModalFleet from "../../../components/fts/fts-contact-from-modal-fleet";
import Breakpoint from "../../../components/hoc/Breakpoint";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";

class TemporaryLinkExpired extends React.Component {
  state = {
    contactModalOpen: false,
  };

  toggleContactModal = () => {
    this.setState({
      contactModalOpen: !this.state.contactModalOpen,
    });
  };

  render() {
    return (
      <div className="fts-link-expired">
        <HeaderWrapper />
        <div className="fts-link-expired-message">
          <div className="fts-link-expired-message-box">
            <Breakpoint md up>
              <Icon name="LinkExpired" size={114} />
            </Breakpoint>
            <Breakpoint sm down>
              <Icon name="LinkExpired" size={83} />
            </Breakpoint>
            <h3>This temporary link has expired</h3>
            <p>
              Please contact Find Truck Service to request a new one. Your
              account ID for this link is #{this.props.accountId}
            </p>
            <div className="fts-link-expired-message-box-buttons">
              <Button tag="a" href="/dashboard" color="primary" size="lg">
                Go to Homepage
              </Button>
              <Button
                color="secondary"
                size="lg"
                onClick={() => this.toggleContactModal()}
              >
                Contact Support
              </Button>
            </div>
          </div>
          {this.state.contactModalOpen && (
            <FtsContactFromModalFleet
              isOpen={true}
              closeModal={this.toggleContactModal}
            />
          )}
        </div>
      </div>
    );
  }
}
export default TemporaryLinkExpired;
