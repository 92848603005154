import React from "react";
import { DrawerContentHeader } from "./DrawerContentHeader";
import { DrawerButtonsLogged } from "./DrawerButtonsLogged";
import { DrawerLinksNonLogged } from "./DrawerLinksNonLogged";
import { DrawerButtonsNonLogged } from "./DrawerButtonsNonLogged";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
export const DrawerContent = ({ onClose, userName, activeLink }) => {
    return (React.createElement(FtsWrapper, { width: "100%", height: "100%", display: "flex", direction: "column", position: "relative", justify: "space-between" },
        React.createElement(DrawerContentHeader, { onClose: onClose }),
        React.createElement(DrawerLinksNonLogged, { activeLink: activeLink }),
        !userName && React.createElement(DrawerButtonsNonLogged, null),
        !!userName && React.createElement(DrawerButtonsLogged, { userName: userName })));
};
