export const LISTING_TYPE = {
  PREMIUM: "premium",
  STANDARD: "standard",
  BASIC_PLUS: "basicplus",
  BASIC: "basic",
  FREE: "free",
};
export const LISTING_TYPES = [
  "",
  LISTING_TYPE.PREMIUM,
  LISTING_TYPE.STANDARD,
  LISTING_TYPE.BASIC_PLUS,
  LISTING_TYPE.BASIC,
  LISTING_TYPE.FREE,
];

export const DEVICE_TAG_EXPIRES = new Date(new Date().getTime() + 10 * 60000);

export const EVENT_TAGS = {
  //DEVICE RELATED - REQUIRED
  deviceOs: "os", //Android || iOS || Windows
  deviceOsVersion: "os", //Android || iOS || Windows
  devicePlatform: "platform", // Chrome || Sarafi || Monzila
  devicePlatformVersion: "platformVersion", //113.0.0.0
  deviceCategory: "deviceCategory", //desktop mobile tablet
  deviceLatitude: "latitude",
  deviceLongitude: "longitude",
  deviceCity: "",
  deviceState: "",
  deviceCounty: "",

  //APP RELATED - REQUIRED
  appVersion: "appVersion", //1.0.17
  appSource: "website",
  //LISTING ITEM - REQUIRED

  adId: "adId",
  adLatitude: "adLatitude",
  adLongitude: "adLongitude",
  adType: "adType", //standard
  adPriceId: "adPriceId",
  adName: "adName",
  adUserId: "adUserId",
  adCity: "adCity",
  adState: "adState",
  adZipCode: "adZipCode",
  adPhoneNumberPrimary: "adPhoneNumberPrimary",
  adPhoneNumberSecondary: "adPhoneNumberSecondary",
  adAfterHours: "adAfterHours",
  adEmail: "adEmail",
  adSeoUrlIdentifier: "adSeoUrlIdentifier",
  adIsFeatureVendor: "adIsFeatureVendor",
  adAddress: "adAddress",
  adCategories: ",",
  adSubCategories: ",",
  adWebsite: ",",

  //account ITEM - REQUIRED
  accountId: "adId",
  accountLatitude: "adLatitude",
  accountLongitude: "adLongitude",
  accountType: "adType", //standard
  accountPriceId: "adPriceId",
  accountName: "adName",
  accountUserId: "adUserId",
  accountCity: "adCity",
  accountState: "adState",
  accountZipCode: "adZipCode",
  accountPhoneNumberPrimary: "adPhoneNumberPrimary",
  accountPhoneNumberSecondary: "adPhoneNumberSecondary",
  accountAfterHours: "adAfterHours",
  accountEmail: "adEmail",
  accountSeoUrlIdentifier: "adSeoUrlIdentifier",
  accountIsFeatureVendor: "adIsFeatureVendor",
  accountAddress: "adAddress",
  accountNationalNetwork: "adAddress",
  accountCategories: "accountCategories",
  accountSubCategories: "accountSubCategories",
  accountWebsite: "accountWebsite",

  //SEARCH RELATED
  searchCategory: "category",
  searchSubCategory: "subCategory",
  searchCategoryLabel: "categoryLabel",
  searchSubCategoryLabel: "subCategoryLabel",
  searchCity: "city",
  searchState: "state",
  searchLatitude: "state",
  searchLongitude: "state",

  //interactions
  interactionType: "log_call_primary",

  //USER RELATED - IF LOGGED IN
  loggedUserId: "userId",
  loggedUserSubUserId: "subUserId",
  loggedUserFirstName: "firstName",
  loggedUserLastName: "lastName",
  loggedUserType: "loggedUserType",
  loggedUserTypeNamed: "loggedUserTypeNamed",

  //
  city: "city",
};

export const EVENT_MEASUREMENTS = {
  TOP1: "top1",
  TOP3: "top3",
  TOP5: "top5",
  TOP10: "top10",
  LISTING_VIEWED: "listing_viewed",
  LISTING_VIEWED_MAP: "listing_viewed_map",
  LISTINGS_VIEWED_LIST: "listings_viewed_list",
  LISTINGS_VIEWED_MAP: "listings_viewed_map",
  LISTINGS_PREVIEW_LISTING: "listings_preview_listing",
  ACCOUNT_VIEWED: "account_viewed",
  SEARCH: "search",
  CLICK: "click",

  //
  //WHEN MODAL OPENS
  VIEW_LISTING_MAP: "view_listing_map",
  VIEW_LISTING_LIST: "view_listing_list",
  VIEW_LISTING_SERVICES: "view_listing_services",
  VIEW_LISTING_AMENITIES: "view_listing_amenities",
  VIEW_LISTING_WORKING_HOURS: "view_listing_working_hours",
  VIEW_LISTING_CONTACTS: "view_listing_contacts",
  VIEW_LISTING_PRIMARY_PHONE_NUMBER: "view_listing_primary_phone_number",
  VIEW_LISTING_SECONDARY_PHONE_NUMBER: "view_listing_secondary_phone_number",
  VIEW_LISTING_AFTER_HOURS_PHONE_NUMBER:
    "view_listing_after_hours_phone_number",
  VIEW_LISTING_NOTE: "view_listing_note",
  VIEW_LISTING_RATES: "view_listing_rates",
  VIEW_LISTING_SHARE: "view_listing_share",
  VIEW_LISTING_STATUS: "view_listing_status",
  VIEW_LISTING_REVIEW: "view_listing_review",
  VIEW_LISTING_PROMOTION: "view_listing_promotion",

  VIEW_ACCOUNT_MAP: "view_listing_map",

  //WHEN USER DO SOMETHING
  SAVE_LISTING_NOTE: "save_listing_note",
  SAVE_LISTING_RATES: "save_listing_rates",
  SAVE_LISTING_REVIEW: "save_listing_review",
  SAVE_LISTING_STATUS: "save_listing_status",

  //WHEN USER DO SOMETHING
  UPDATE_LISTING_NOTE: "update_listing_note",
  UPDATE_LISTING_RATES: "update_listing_rates",
  UPDATE_LISTING_REVIEW: "update_listing_review",
  UPDATE_LISTING_STATUS: "update_listing_status",

  //WHEN USER DO SOMETHING
  REMOVE_LISTING_NOTE: "remove_listing_note",
  REMOVE_LISTING_RATES: "remove_listing_rates",
  REMOVE_LISTING_REVIEW: "remove_listing_review",
  REMOVE_LISTING_STATUS: "remove_listing_status",

  //CALL LOGS
  LOG_CALL_LISTING_PRIMARY_PHONE_NUMBER:
    "log_call_listing_primary_phone_number",
  LOG_CALL_LISTING_SECONDARY_PHONE_NUMBER:
    "log_call_listing_secondary_phone_number",
  LOG_CALL_LISTING_AFTER_HOURS_PHONE_NUMBER:
    "log_call_listing_after_hours_phone_number",

  SHARE_VENDOR_SMS: "share_vendor_sms",
  SHARE_VENDOR_EMAIL: "share_vendor_email",

  FIND_CITY: "find_city",

  UPDATE_ACCOUNT: "update_account",
  ADD_DRIVER: "add_driver",
  ADD_SUBUSER: "add_subuser",
  REMOVE_SUBUSER: "remove_subuser",
  EDIT_SUBUSER: "edit_subuser",
  UPDATE_DRIVER: "update_driver",
  REMOVE_DRIVER: "remove_driver",
  RESEND_DRIVER_INVITATION: "resend_driver_invitation",
  OPEN_REFER_A_FRIEND_MODAL: "open_refer_a_friend_modal",
  OPEN_AUTHORIZED_BENEFITS_MODAL: "open_authorized_benefits_modal",
  PROCEED_TO_ENROLLMENT: "proceed_to_enrollment",
  REFER_A_FRIEND: "refer_a_friend",
  RESEND_REFERRAL: "resend_referral",
  RECENT_SEARCHES_LOAD_SEARCH: "recent_searches_load_search",
  DRIVERS_BULK_UPLOAD: "drivers_bulk_upload",
  UPDATE_EXPERIENCE: "update_experience",
  REMOVE_EXPERIENCE: "remove_experience",
  TRUCKS_BULK_UPLOAD: "trucks_bulk_upload",
  LINK_TRUCK_TO_DRIVER: "link_truck_to_driver",
  LINK_DRIVER_TO_TRUCK: "link_driver_to_truck",
  UNLINK_TRUCK_FROM_DRIVER: "unlink_truck_from_driver",
  UNLINK_DRIVER_FROM_TRUCK: "unlink_driver_from_truck",
  CHANGE_DRIVER_TRUCK: "change_driver_truck",
  CHANGE_TRUCK_DRIVER: "change_truck_driver",
  ADD_TRUCK: "add_truck",
  UPDATE_TRUCK: "update_truck",
  REMOVE_TRUCK: "remove_truck",
  CALCULATE_REVENUE: "calculate_revenue",
  REQUEST_ACCOUNT_TYPE_CHANGE: "request_account_type_change",
  RESHARE_VENDOR: "reshare_vendor",

  VIEW_PREFERRED_VENDORS_LIST: "view_preferred_vendors_list",
  VIEW_PREFERRED_VENDORS_MAP: "view_preferred_vendors_map",
  VIEW_COMPANY_DETAILS: "view_company_detials",
  VIEW_SUB_USERS: "view_sub_users",
  VIEW_MY_PROFILE: "view_my_profile",
  VIEW_DASHBOARD: "view_dashboard",
  VIEW_NOTIFICATIONS: "view_notifications",
  VIEW_RECENT_SEARCHES: "view_recent_searches",
  VIEW_PREFERRED_VENDORS: "view_preferred_vendors",
  VIEW_DRIVERS: "view_drivers",
  VIEW_TRUCKS: "view_trucks",
  VIEW_NOTES: "view_notes",
  VIEW_CALL_LOGS: "view_call_logs",
  VIEW_SHARED_VENDORS: "view_shared_vendors",
  VIEW_RECOMMENDATIONS: "view_recommendations",
  VIEW_FINANCING: "view_financing",
  VIEW_LINKED_COMPANY: "view_linked_company",
  VIEW_LIST_YOUR_BUSINESS: "view_list_your_business",
  VIEW_REFER_A_FRIEND: "view_refer_a_friend",
  VIEW_MY_LISTINGS: "view_my_listings",
  VIEW_RENEWALS: "view_renewals",
  VIEW_INVOICES: "view_invoices",
  VIEW_PAST_PAYMENTS: "view_past_payments",
  VIEW_RECURRING_PAYMENTS: "view_recurring_payments",
  VIEW_REPORTS: "view_reports",
  VIEW_GET_AUTHORIZED: "view_get_authorized",
  VIEW_FINISH_YOUR_BUSINESS: "view_finish_your_business",
  VIEW_UPGRADE_LISTING: "view_upgrade_listing",
  VIEW_CONTACT_FTS: "view_contact_fts",
  VIEW_FINANCE_REPAIRS: "view_finance_repairs",
  VIEW_GET_MOBILE_APP: "view_get_mobile_app",
  VIEW_LISTING_REPORT: "view_listing_report",
  VIEW_AUTHORIZED_BENEFITS: "view_authorized_benefits",
  VIEW_REPAIRS: "view_repairs",

  APPLY_FOR_FINANCING: "apply_for_financing",
  CHECK_PREQUALIFY: "check_prequalify",
  FINANCING_LEARN_MORE: "financing_learn_more",
  OPEN_ADD_SUBUSER_FORM: "open_add_subuser_form",
  OPEN_EDIT_SUBUSER_FORM: "open_edit_subuser_form",
  OPEN_LIST_YOUR_BUSINESS: "open_list_your_business",
  OPEN_UPGADE_FLOW: "open_upgrade_flow",
  OPEN_FINISH_YOUR_BUSINESS: "open_finish_your_business",

  CHANGE_PASSWORD: "change_password",
  DOWNLOAD_APP: "download_app",

  REPAIRS_COMPLETE_REPAIR_CLOSE: "repairs_complete_repairs_close",
  REPAIRS_COMPLETE_REPAIR: "repairs_complete_repair",
  REPAIRS_NEW_REPAIR: "repairs_new_repair",
  REPAIRS_ADD_UPDATE_ETC: "repairs_add_update_etc",
  REPAIRS_CANCEL_CANCEL_TICKET: "repairs_cancel_cancel_ticket",
  REPAIRS_CANCEL_TICKET: "repairs_cancel_ticket",
  REPAIRS_VENDOR_MORE_INFO: "repairs_vendor_more_info",
  REPAIRS_CHANGE_DRIVER: "repairs_change_driver",
  REPAIRS_TRUCK_DETAILS: "repairs_truck_details",
  REPAIRS_DRIVER_DETAILS: "repairs_driver_details",
  REPAIRS_ADD_NOTE: "repairs_add_note",
  REPAIRS_ADD_REPAIR_NOTE: "repairs_add_repair_note",
  REPAIRS_ADD_DRIVER: "repairs_add_driver",
  REPAIRS_VIEW_VENDOR_CONTACT: "repairs_view_vendor_contact",
};

export const EVENT_MEASUREMENTS_EXPIRES = {
  [EVENT_MEASUREMENTS.VIEW_LISTING_MAP]: new Date(
    new Date().getTime() + 15 * 60000,
  ),
  [EVENT_MEASUREMENTS.VIEW_LISTING_SERVICES]: new Date(
    new Date().getTime() + 15 * 60000,
  ),
  [EVENT_MEASUREMENTS.VIEW_LISTING_AMENITIES]: new Date(
    new Date().getTime() + 15 * 60000,
  ),
  [EVENT_MEASUREMENTS.VIEW_LISTING_WORKING_HOURS]: new Date(
    new Date().getTime() + 15 * 60000,
  ),
  // [EVENT_MEASUREMENTS.VIEW_LISTING_CONTACTS]: new Date(
  //   new Date().getTime() + 15 * 60000,
  // ),
  // [EVENT_MEASUREMENTS.VIEW_LISTING_PRIMARY_PHONE_NUMBER]: new Date(
  //   new Date().getTime() + 15 * 60000,
  // ),
  // [EVENT_MEASUREMENTS.VIEW_LISTING_SECONDARY_PHONE_NUMBER]: new Date(
  //   new Date().getTime() + 15 * 60000,
  // ),
  // [EVENT_MEASUREMENTS.VIEW_LISTING_AFTER_HOURS_PHONE_NUMBER]: new Date(
  //   new Date().getTime() + 15 * 60000,
  // ),
  [EVENT_MEASUREMENTS.VIEW_LISTING_NOTE]: new Date(
    new Date().getTime() + 15 * 60000,
  ),
  [EVENT_MEASUREMENTS.VIEW_LISTING_RATES]: new Date(
    new Date().getTime() + 15 * 60000,
  ),
  [EVENT_MEASUREMENTS.VIEW_LISTING_SHARE]: new Date(
    new Date().getTime() + 15 * 60000,
  ),
  // [EVENT_MEASUREMENTS.SAVE_LISTING_NOTE]: new Date(
  //   new Date().getTime() + 15 * 60000
  // ),
  // [EVENT_MEASUREMENTS.SAVE_LISTING_RATES]: new Date(
  //   new Date().getTime() + 15 * 60000
  // ),
  // [EVENT_MEASUREMENTS.SAVE_LISTING_REVIEW]: new Date(
  //   new Date().getTime() + 15 * 60000
  // ),
  // [EVENT_MEASUREMENTS.SAVE_LISTING_STATUS]: new Date(
  //   new Date().getTime() + 15 * 60000
  // ),
  // [EVENT_MEASUREMENTS.UPDATE_LISTING_NOTE]: new Date(
  //   new Date().getTime() + 15 * 60000
  // ),
  // [EVENT_MEASUREMENTS.UPDATE_LISTING_RATES]: new Date(
  //   new Date().getTime() + 15 * 60000
  // ),
  // [EVENT_MEASUREMENTS.UPDATE_LISTING_REVIEW]: new Date(
  //   new Date().getTime() + 15 * 60000
  // ),
  // [EVENT_MEASUREMENTS.UPDATE_LISTING_STATUS]: new Date(
  //   new Date().getTime() + 15 * 60000
  // ),

  // [EVENT_MEASUREMENTS.LOG_CALL_LISTING_PRIMARY_PHONE_NUMBER]: new Date(
  //   new Date().getTime() + 15 * 60000,
  // ),
  // [EVENT_MEASUREMENTS.LOG_CALL_LISTING_SECONDARY_PHONE_NUMBER]: new Date(
  //   new Date().getTime() + 15 * 60000,
  // ),
  // [EVENT_MEASUREMENTS.LOG_CALL_LISTING_AFTER_HOURS_PHONE_NUMBER]: new Date(
  //   new Date().getTime() + 15 * 60000,
  // ),
  [EVENT_MEASUREMENTS.LISTING_VIEWED_MAP]: new Date(
    new Date().getTime() + 5 * 60000,
  ),
};

export const ANALYTICS_SEARCH_TYPE = {
  DEFAULT: "default",
  SEMI: "semi",
  QUICK_SEARCH: "quick_search",
  EXTERNAL: "external",
};
