export var INVOICES_TYPES_ENUM;
(function (INVOICES_TYPES_ENUM) {
    INVOICES_TYPES_ENUM["RENEWAL"] = "renewal";
    INVOICES_TYPES_ENUM["NEW"] = "new";
    INVOICES_TYPES_ENUM["UPGRADE"] = "upgrade";
})(INVOICES_TYPES_ENUM || (INVOICES_TYPES_ENUM = {}));
export var INVOICES_STATUSES_ENUM;
(function (INVOICES_STATUSES_ENUM) {
    INVOICES_STATUSES_ENUM[INVOICES_STATUSES_ENUM["VOIDED"] = 0] = "VOIDED";
    INVOICES_STATUSES_ENUM[INVOICES_STATUSES_ENUM["ACTIVE"] = 1] = "ACTIVE";
})(INVOICES_STATUSES_ENUM || (INVOICES_STATUSES_ENUM = {}));
