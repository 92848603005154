import React, { useEffect, useRef } from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { FTSTextarea } from "@find-truck-service/ui/src/FtsTextarea/FtsTextarea";
import { TextareaLabel } from "@find-truck-service/ui/src/FtsTextarea/TextareaLabel";
import { InputHelperText } from "@find-truck-service/ui/src/FtsInput/InputHelperText";
import { FtsTextareaWrapper } from "@find-truck-service/ui/src/FtsTextarea/TextareaWrapper";
export const Textarea = (props) => {
    const { errorMessage, required, helperText, invalid, label, ...defaultProps } = props;
    const ref = useRef();
    const showLabel = !!label || required;
    const helperTextValue = errorMessage || helperText;
    useEffect(() => {
        if (errorMessage && ref.current)
            ref.current.scrollIntoView({ block: "center", behavior: "auto" });
    }, [errorMessage]);
    return (React.createElement(FtsTextareaWrapper, null,
        showLabel && (React.createElement(FtsWrapper, { display: "flex", align: "center", gap: "3xs" },
            !!label && (React.createElement(TextareaLabel, { size: props.size, disabled: props.disabled }, label)),
            required && (React.createElement(TextareaLabel, { size: props.size, disabled: props.disabled }, "*")))),
        React.createElement(FTSTextarea, { rows: 5, ref: ref, invalid: !!errorMessage || !!invalid, ...defaultProps }),
        !!helperTextValue && (React.createElement(InputHelperText, { size: props.size, disabled: props.disabled, errorMessage: !!errorMessage || !!invalid, dangerouslySetInnerHTML: { __html: helperTextValue } }))));
};
