import React, { Component } from "react";

import { Button, Spinner } from "reactstrap";
import FTSModal from "../fts-modal";
import CloseButton from "../CloseButton";
import Icon from "../Icons";
import { connect } from "react-redux";
import { updateProof } from "../../../redux/actions/proofs";
import { toast } from "react-toastify";
import { createActivity } from "../../../redux/actions/activities";
import { activities } from "../../../constants";
import { addUserActivity } from "../../../redux/actions/user-activities";

import "../../../assets/scss/components/fts/listing-update-confirmation-modal.scss";
import logger from "../../../utility/logger";

class PermissionToApproveModal extends Component {
  state = {
    inProgress: false,
  };

  confirmModal = () => {
    this.approveProof();
    this.props.closePreviewModal && this.props.closePreviewModal();
  };

  approveProof = () => {
    this.setState({
      inProgress: true,
    });
    const payload = {
      proofId: this.props.proofId,
      data: {
        status: 1,
        sendBy: `${this.props.userData.firstName} ${this.props.userData.lastName}`,
        lastUpdatedBy: `${this.props.userData.firstName} ${this.props.userData.lastName}`,
      },
    };

    this.props
      .updateProof(payload)
      .then(async (data) => {
        let activityPayload = {
          status: 1,
          proofId: data?.data.id,
          userId: this.props?.userId,
          adId: this.props?.adId,
          activityType: this.props.activitiesTypes.APPROVED_PROOF,
          activity: activities.approvedProof
            .replace(
              "{{admin_name}}",
              `${this.props.userData.firstName} ${this.props.userData.lastName}`,
            )
            .replace("{{userId}}", this.props?.userId)
            .replace("{{proofId}}", this.props.proofId),
          adminUserId: this.props.userData.adminAccountOwner || null,
          iconName: "Listings",
        };

        const proofActivity = await this.props.createActivity(activityPayload);
        this.props.addUserActivity(proofActivity.data);

        toast.success("Proof succesfully approved", {
          position: toast.POSITION.TOP_RIGHT,
        });

        this.props.fetchProofs && this.props.fetchProofs();
        this.props.fetchProofsCounters && this.props.fetchProofsCounters();

        this.props.closeModal();
        this.setState({
          inProgress: false,
        });
      })
      .catch((err) => {
        logger.error(err);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({
          inProgress: false,
        });
      });
  };

  render() {
    const { isOpen, title, closeModal, onClose } = this.props;
    const { inProgress } = this.state;

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        modalClasses={"fts-reassign-modal"}
        centered={true}
        style={{ maxWidth: "665px" }}
        footerCTA={() => {
          return (
            <div className="update-listing-confrimation-modal-cta">
              <div>
                <span className="mr-1">
                  <Button
                    onClick={() => {
                      closeModal();
                    }}
                    color="white"
                    disabled={inProgress}
                  >
                    Cancel
                  </Button>
                </span>
                <Button
                  onClick={() => {
                    this.confirmModal();
                  }}
                  color="red"
                  disabled={inProgress}
                >
                  {inProgress ? <Spinner size="sm" /> : "Approve"}
                </Button>
              </div>
            </div>
          );
        }}
      >
        <div className="reassign-modal-body">
          <span className="reassign-modal-body-wrapper">
            <span className="reassign-modal-title">
              <div className="d-flex update-listing-confirmation-title">
                <Icon name="ApproveProof" size={56} />
                <div>
                  <span className="d-flex align-center">
                    <h3>
                      Are you sure you have permission to approve this
                      promotion?
                    </h3>

                    {this.props.inProgress && (
                      <div className="confirmation-spinner-wrapper">
                        <Spinner size="sm" />
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <CloseButton onClick={closeModal} />
            </span>
          </span>
        </div>
      </FTSModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.authState.user,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

const mapDispatchToProps = {
  updateProof,
  createActivity,
  addUserActivity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PermissionToApproveModal);
