import { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";
export const LOGIN_WITH_FIREBASE = "firebase";
export const LOGIN_WITH_JWT = "jwt";

export const socialAuthProviders = {
  GOOGLE: "google.com",
  FACEBOOK: "facebook.com",
  APPLE: "apple.com",
};

export const providerEndpoints = {
  [socialAuthProviders.GOOGLE]: "/google-oauth-web",
  [socialAuthProviders.FACEBOOK]: "/facebook-oauth-web",
  [socialAuthProviders.APPLE]: "/apple-oauth-web",
};

export const getProvider = (provider) => {
  switch (provider) {
    case socialAuthProviders.GOOGLE:
      return new auth.GoogleAuthProvider();
    case socialAuthProviders.FACEBOOK:
      return new auth.FacebookAuthProvider();
    case socialAuthProviders.APPLE:
      return new auth.OAuthProvider("apple.com");
  }
};

export const createPayload = (result, type, provider) => ({
  accessToken: result.credential.accessToken,
  email: result.additionalUserInfo.profile.email,
  name: result.user.displayName,
  photoUrl: result.user.photoURL,
  loggedInWith: LOGIN_WITH_FIREBASE,
  userType: type,
  appleId:
    provider === socialAuthProviders.APPLE
      ? result.user.providerData[0]?.uid
      : null,
});
