import React from "react";
import styled from "styled-components";
import { getDayValues } from "../helpers";
import { weekDayLabels, weekDays } from "../constants";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { WorkingHoursToDropdown } from "./WorkingHoursToDropdown";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { WorkingHoursFromDropdown } from "./WorkingHoursFromDropdown";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ToggleSwitch } from "@find-truck-service/ui/src/components/react/Toggle";

export const WorkingHoursColumn = (props) => {
  const { listingData, handleToggle, onChange, workingHours } = props;
  const isAlwaysOpen = listingData.alwaysOpen;

  return (
    <WorkingHoursStyledColumn>
      <FtsWrapper
        rowGap={"0"}
        width={"100%"}
        display={"flex"}
        direction={"column"}
        align={"flex-start"}
      >
        <HorizontalDivider />
        <Span
          mb={"lg"}
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          WORK HOURS
        </Span>

        <ToggleSwitch
          checked={listingData.alwaysOpen}
          label={"Are you open 24 hours?"}
          onClick={() => handleToggle("alwaysOpen")}
        />
        {!isAlwaysOpen && (
          <FtsWrapper width={"100%"}>
            {weekDays.map((d, index) => {
              const { day, dayFromName, dayToName, dayFromValue, dayToValue } =
                getDayValues(d, workingHours);

              return (
                <FtsRow key={index} columnGap={"lg"} rowGap={"2xs"}>
                  <FtsColumn size={{ xs: 12 }} mt={"2xl"}>
                    <Span
                      variant={VariantsValues["text-3xs"]}
                      color={ColorsValue["greyscale-500"]}
                    >
                      {weekDayLabels[day]}
                    </Span>
                  </FtsColumn>
                  <WorkingHoursFromDropdown
                    onChange={onChange}
                    dayFromName={dayFromName}
                    dayFromValue={dayFromValue}
                  />
                  <WorkingHoursToDropdown
                    onChange={onChange}
                    dayToName={dayToName}
                    dayToValue={dayToValue}
                    dayFromValue={dayFromValue}
                  />
                </FtsRow>
              );
            })}
          </FtsWrapper>
        )}
      </FtsWrapper>
      <VerticalDivider />
    </WorkingHoursStyledColumn>
  );
};

const WorkingHoursStyledColumn = styled(FtsColumn).attrs({
  size: { md: 12, lg: 6 },
  display: "flex",
  flexDirection: "column",
  order: { xs: 2, sm: 2, md: 2, lg: 1 },
})``;

const VerticalDivider = styled(FtsWrapper).attrs({
  display: { xs: "none", sm: "none", md: "none", lg: "block" },
  bg: ColorsValue["greyscale-100"],
  height: "100%",
  width: "1px",
  ml: "3xl",
})``;

const HorizontalDivider = styled(FtsColumn).attrs({
  display: { xs: "block", sm: "block", md: "block", lg: "none" },
  bg: ColorsValue["greyscale-100"],
  size: { xs: 12 },
  height: "1px",
  width: "100%",
  mb: "2xl",
  mt: "md",
})``;
