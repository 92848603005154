import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Route,
  Router as BrowserRouter,
  Switch,
} from "react-router-dom";
import FTSAxiosV2 from "./axios/ftsv2.instance";

import FTSCookies, {
  AUTH_KEY,
  path,
  PURCHASE_LISTING_TEMPORARY_TOKEN,
  TEMPORARY_TOKEN,
} from "./cookies";
// import FTSAxios from "./axios/fts.instance";
import handleError from "./handlers/error";

import Router from "./Router";
import { history } from "./history";

import { temporaryTokenRoutes } from "./constants";

import "react-perfect-scrollbar/dist/css/styles.css";
import "@find-truck-service/ui/src/components/react/RichTextEditor/Styled/RichTextEditor.css";
import "@find-truck-service/ui/src/components/react/RichTextEditor/Styled/quill.snow.css";
// import "./components/@vuexy/rippleButton/RippleButton";
// import "prismjs/themes/prism-tomorrow.css";
import UpdateListing from "./views/update-listing";
import DownloadRecipient from "./views/recipient";
import Payment from "./views/pages/payment/payment";
import PaymentStatus from "./views/pages/payment/PaymentStatus";
import Proofs from "./views/pages/proofs/Proofs";
import UpgradeProof from "./views/pages/upgrade-proof/UpgradeProof";
import Report from "./views/pages/report";
import { getDeviceTags } from "./utility/analytic.helpers";
import ConfirmationModalWrapper from "./components/fts/modals/ConfirmationModalWrapper";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { setUserData } from "./redux/actions/v2/auth";
import collectEssentialData from "./axios/collect-data";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight && window.innerHeight < 500,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getDeviceTags();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(
        window.innerWidth > window.innerHeight && window.innerHeight < 500,
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUserData = useCallback(async () => {
    try {
      const response = await FTSAxiosV2.get("/users/me");
      const user = response.data.data;
      await collectEssentialData(dispatch);
      dispatch(setUserData({ data: user }));
      setIsAuthenticated(true);
    } catch (e) {
      setIsAuthenticated(true);
      handleError(e);
    }
  }, [dispatch]);

  const isTemporaryTokenRoute = useCallback(() => {
    const pathnameWithoutToken = window.location.pathname
      .split("/")
      .slice(0, 3)
      .join("/");

    return temporaryTokenRoutes.indexOf(pathnameWithoutToken) !== -1;
  }, []);

  useEffect(() => {
    if (isTemporaryTokenRoute()) return;
    FTSCookies.remove(PURCHASE_LISTING_TEMPORARY_TOKEN, { path });
    FTSCookies.remove(TEMPORARY_TOKEN, { path });
    const cookie = FTSCookies.get(AUTH_KEY);
    if (cookie) {
      getUserData(cookie);
    } else {
      setIsAuthenticated(true);
    }
  }, [dispatch, getUserData, isTemporaryTokenRoute]);

  // useEffect(() => {
  //   const isPurchaseTemporary = isPurchaseTemporaryTokenRoute();
  //   setIsPurchaseTemporaryToken(isPurchaseTemporary);
  // }, [history?.location?.pathname, isPurchaseTemporaryTokenRoute]);

  if (isLandscape) {
    return (
      <div className="FTS-landscape-info-modal">
        <ConfirmationModalWrapper
          title="Rotate your phone"
          toggleModal={() => {}}
          continueAction={() => {
            location.reload();
          }}
          content={[
            "Find Truck Service dashboard is currently not available in landscape mode, please rotate your phone and click button below to continue using your app.",
          ]}
          buttonTitle="Refresh"
          showCancelActions={false}
        />
      </div>
    );
  }

  if (isTemporaryTokenRoute()) {
    return (
      <BrowserRouter history={history}>
        <Switch>
          <Route path={"/dashboard/report/:token"} component={Report} />
          <Route path={"/dashboard/proofs/:token"} component={Proofs} />
          <Route
            path={"/dashboard/upgrade-proof/:token"}
            component={UpgradeProof}
          />
          <Route
            path={"/dashboard/update-listing/:token"}
            component={UpdateListing}
          />
          <Route
            path={"/dashboard/recipient/:token"}
            component={DownloadRecipient}
          />
          <Route
            path={"/dashboard/payment/:token/:status"}
            component={PaymentStatus}
          />
          <Route path={"/dashboard/payment/:token"} component={Payment} />
          <Redirect to={"/dashboard/login"} />
        </Switch>
      </BrowserRouter>
    );
  }

  if (!isAuthenticated) {
    return <FullPageLoader />;
  }

  return <Router />;
};

export default App;
