import { all } from "redux-saga/effects";
import createListingAccountWatcher from "./createListingAccount";

import { authWatcher } from "./auth/index";
import getListingInvoiceWatcher from "./invoices";
import cardsWatcher from "./cards";
import paymentsWatcher from "./payments";
import listingWatcher from "./listing";
import vendorAdvertiseListingsWatcher from "./vendorAdvertiseListings";
import updateCompanyDriverProfileWatcher from "./companyDriverProfile";
import updateFleetProfileWatcher from "./fleetProfile";
import updateOtherProfileWatcher from "./otherProfile";
import ownerOperatorProfileWatcher from "./ownerOperatorProfile";
import createAccountWatcher from "./createAccount";
import socialAuthWatcher from "./socialAuth";
import { loginWatcher } from "./login";
import { essentialsWatcher } from "./essentials";
import resetPasswordWatcher from "./resetPassword";
import updatePasswordWatcher from "./updatePassword";
import completeRegistrationWatcher from "./completeRegistration";
import temporaryLinkListingWatcher from "./temporaryListingLink";
import temporaryLinkUserCardsWatcher from "./temporaryLinkCards";
import temporaryLinkPaymentsWatcher from "./temporaryLinkPayments";
import reactivateAccountWatcher from "./reactivateAccount";
import shareProviderWatcher from "./shareProvider";
import userMfasWatcher from "./userMfas";

export default function* rootSaga() {
  yield all([
    createListingAccountWatcher(),
    getListingInvoiceWatcher(),
    cardsWatcher(),
    paymentsWatcher(),
    listingWatcher(),
    vendorAdvertiseListingsWatcher(),
    authWatcher(),
    updateCompanyDriverProfileWatcher(),
    updateFleetProfileWatcher(),
    updateOtherProfileWatcher(),
    ownerOperatorProfileWatcher(),
    createAccountWatcher(),
    socialAuthWatcher(),
    loginWatcher(),
    essentialsWatcher(),
    resetPasswordWatcher(),
    updatePasswordWatcher(),
    completeRegistrationWatcher(),
    temporaryLinkListingWatcher(),
    temporaryLinkUserCardsWatcher(),
    temporaryLinkPaymentsWatcher(),
    reactivateAccountWatcher(),
    shareProviderWatcher(),
    userMfasWatcher(),
  ]);
}
