import styled, { css, keyframes } from "styled-components";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
const slideDown = keyframes `
  from { max-height: 0; }
  to { max-height: 1000px;}
`;
export const FtsCollapsibleWrapper = styled(FtsWrapper) `
  overflow: hidden;
  max-height: ${(props) => (props.show ? "500px" : "0")};
  transition: max-height 0.3s ease-out;
  animation: ${(props) => props.show &&
    css `
      ${slideDown} 0.3s ease-out
    `};
`;
