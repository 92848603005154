/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-extra-boolean-cast */
import React from "react";
import { connect } from "react-redux";
import { Button, Spinner } from "reactstrap";
import Icon from "../../../components/fts/Icons";
import "../../../assets/scss/pages/fts-email-unsubscribe.scss";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikCheckbox from "../../../components/fts/formik/checkbox";
import ConfirmationModalWrapper from "../../../components/fts/modals/ConfirmationModalWrapper";
import FTSCookies, {
  removeAllCookies,
  TEMPORARY_TOKEN,
} from "../../../cookies";
import {
  getUserByHash,
  updateUserEmailSubscription,
} from "../../../redux/actions/users";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import logger from "../../../utility/logger";

const formSchema = Yup.object().shape({
  primaryMarketingOptIn: Yup.boolean(),
  primaryUpdatesOptIn: Yup.boolean(),
});

class EmailUnsubscribe extends React.Component {
  state = {
    unsubscribed: false,
    formValues: null,
    confirmUnsubscribeModal: false,
    inProgress: true,
    updateInProgress: false,
  };

  formRef = React.createRef();

  async componentDidMount() {
    await removeAllCookies();

    const hash = this.props.match.params.hash;
    FTSCookies.set(TEMPORARY_TOKEN, hash, { path: "/" });

    this.props
      .getUserByHash({ hash })
      .then(({ data }) => {
        this.setState({
          formValues: {
            ...data?.data,
            primaryMarketingOptIn: !data?.data.primaryMarketingOptIn,
            primaryUpdatesOptIn: !data.data?.primaryUpdatesOptIn,
          },
          inProgress: false,
        });
      })
      .catch((err) => logger.error(err));
  }

  handleSubmit = (values) => {
    if (values?.primaryUpdatesOptIn) {
      this.toggleConfirmUnsubscribeModal();
      return;
    }
    this.handleUnsubscribe();
  };

  toggleConfirmUnsubscribeModal = () => {
    this.setState({
      confirmUnsubscribeModal: !this.state.confirmUnsubscribeModal,
    });
  };

  handleUnsubscribe = () => {
    const { values } = this.formRef?.current;

    this.setState({
      updateInProgress: true,
    });

    let payload = {
      ...values,
      primaryMarketingOptIn: !values.primaryMarketingOptIn,
      primaryUpdatesOptIn: !values.primaryUpdatesOptIn,
    };

    this.props
      .updateUserEmailSubscription(payload)
      .then(() => {
        this.setState({
          unsubscribed: true,
          confirmUnsubscribeModal: false,
          updateInProgress: false,
        });
      })
      .catch((err) => logger.error(err));
  };

  validateForm = () => {
    this.formRef.current.validateForm();
  };

  render() {
    const {
      formValues,
      unsubscribed,
      confirmUnsubscribeModal,
      inProgress,
      updateInProgress,
    } = this.state;

    return (
      <div className="fts-email-unsubscribe">
        <HeaderWrapper />
        <div className="fts-email-unsubscribe-message">
          {!inProgress ? (
            <div className="fts-email-unsubscribe-message-box">
              <Icon
                name={!unsubscribed ? "Unsubscribe" : "UnsubscribeRed"}
                size={135}
              />
              <h3>
                {!unsubscribed
                  ? "Please select what you would like to unsubscribe from."
                  : `You're unsubscribed.`}
              </h3>
              {unsubscribed && <p> We’re sorry to see you go!</p>}

              <Formik
                enableReinitialize={true}
                onSubmit={this.handleSubmit}
                validationSchema={formSchema}
                initialValues={formValues}
                validateOnChange={false}
                validateOnBlur={true}
                innerRef={this.formRef}
              >
                {() => (
                  <Form>
                    {!unsubscribed && (
                      <div className="fts-email-unsubscribe-checkboxes">
                        <div className="fts-email-unsubscribe-checkbox">
                          <FormikCheckbox
                            name="primaryMarketingOptIn"
                            label="General E-mails"
                            color="primary"
                          />
                        </div>

                        <div className="fts-email-unsubscribe-checkbox">
                          <FormikCheckbox
                            name="primaryUpdatesOptIn"
                            label="Update E-mails"
                            color="primary"
                          />
                        </div>
                      </div>
                    )}

                    {!unsubscribed ? (
                      <Button
                        color="primary"
                        size="lg"
                        className="fts-email-unsubscribe-button"
                        type="submit"
                      >
                        {!updateInProgress ? (
                          "Unsubscribe Me"
                        ) : (
                          <Spinner size="sm" />
                        )}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        size="lg"
                        tag="a"
                        href="/"
                        className="fts-email-unsubscribe-button"
                      >
                        Go to Website
                      </Button>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <Spinner size="lg" />
          )}
        </div>

        {confirmUnsubscribeModal && (
          <ConfirmationModalWrapper
            title="Unsubscribe from Update Emails"
            content={[
              "Free listings have to be updated every 6 months in order to stay active. If you unsubscribe from “update” emails, your Free listing(s) might be terminated and removed from search results.",
            ]}
            buttonTitle="Unsubscribe Me"
            toggleModal={this.toggleConfirmUnsubscribeModal}
            continueAction={() => {
              this.handleUnsubscribe();
            }}
            loading={updateInProgress}
          />
        )}
      </div>
    );
  }
}
export default connect(null, { getUserByHash, updateUserEmailSubscription })(
  EmailUnsubscribe,
);
