import { createSelector } from "reselect";
import { addOrUpdateAsset, updateAssetStatus } from "./helpers";

export const TEMPORARY_LINK_GET_LISTING_REQUEST =
  "TEMPORARY_LINK/TEMPORARY_LINK_GET_LISTING_REQUEST";
export const TEMPORARY_LINK_GET_LISTING_SUCCESS =
  "TEMPORARY_LINK/TEMPORARY_LINK_GET_LISTING_SUCCESS";
export const TEMPORARY_LINK_GET_LISTING_FAILED =
  "TEMPORARY_LINK/TEMPORARY_LINK_GET_LISTING_FAILED";

export const TEMPORARY_LINK_GET_DATA_REQUEST =
  "TEMPORARY_LINK/TEMPORARY_LINK_GET_DATA_REQUEST";
export const TEMPORARY_LINK_GET_DATA_SUCCESS =
  "TEMPORARY_LINK/TEMPORARY_LINK_GET_DATA_SUCCESS";
export const TEMPORARY_LINK_GET_DATA_FAILED =
  "TEMPORARY_LINK/TEMPORARY_LINK_GET_DATA_FAILED";

export const TEMPORARY_LINK_UPDATE_LISTING_REQUEST =
  "TEMPORARY_LINK/TEMPORARY_LINK_UPDATE_LISTING_REQUEST";
export const TEMPORARY_LINK_UPDATE_LISTING_SUCCESS =
  "TEMPORARY_LINK/TEMPORARY_LINK_UPDATE_LISTING_SUCCESS";
export const TEMPORARY_LINK_UPDATE_LISTING_FAILED =
  "TEMPORARY_LINK/TEMPORARY_LINK_UPDATE_LISTING_FAILED";

export const TEMPORARY_LINK_CHOOSE_LISTING_TYPE_REQUEST =
  "TEMPORARY_LINK/TEMPORARY_LINK_CHOOSE_LISTING_TYPE_REQUEST";
export const TEMPORARY_LINK_CHOOSE_LISTING_TYPE_SUCCESS =
  "TEMPORARY_LINK/TEMPORARY_LINK_CHOOSE_LISTING_TYPE_SUCCESS";
export const TEMPORARY_LINK_CHOOSE_LISTING_TYPE_FAILED =
  "TEMPORARY_LINK/TEMPORARY_LINK_CHOOSE_LISTING_TYPE_FAILED";

export const TEMPORARY_LINK_UPLOAD_ADS_ASSETS_REQUEST =
  "TEMPORARY_LINK/TEMPORARY_LINK_UPLOAD_ADS_ASSETS_REQUEST";
export const TEMPORARY_LINK_UPLOAD_ADS_ASSETS_SUCCESS =
  "TEMPORARY_LINK/TEMPORARY_LINK_UPLOAD_ADS_ASSETS_SUCCESS";
export const TEMPORARY_LINK_UPLOAD_ADS_ASSETS_FAILED =
  "TEMPORARY_LINK/TEMPORARY_LINK_UPLOAD_ADS_ASSETS_FAILED";

export const TEMPORARY_LINK_GET_FEATURED_VENDORS_REQUEST =
  "TEMPORARY_LINK/TEMPORARY_LINK_GET_FEATURED_VENDORS_REQUEST";
export const TEMPORARY_LINK_GET_FEATURED_VENDORS_SUCCESS =
  "TEMPORARY_LINK/TEMPORARY_LINK_GET_FEATURED_VENDORS_SUCCESS";
export const TEMPORARY_LINK_GET_FEATURED_VENDORS_FAILED =
  "TEMPORARY_LINK/TEMPORARY_LINK_GET_FEATURED_VENDORS_FAILED";

export const TEMPORARY_LINK_APPROVE_PROOF_REQUEST =
  "TEMPORARY_LINK/TEMPORARY_LINK_APPROVE_PROOF_REQUEST";
export const TEMPORARY_LINK_APPROVE_PROOF_SUCCESS =
  "TEMPORARY_LINK/TEMPORARY_LINK_APPROVE_PROOF_SUCCESS";
export const TEMPORARY_LINK_APPROVE_PROOF_FAILED =
  "TEMPORARY_LINK/TEMPORARY_LINK_APPROVE_PROOF_FAILED";

export const TEMPORARY_LINK_REJECT_PROOF_REQUEST =
  "TEMPORARY_LINK/TEMPORARY_LINK_REJECT_PROOF_REQUEST";
export const TEMPORARY_LINK_REJECT_PROOF_SUCCESS =
  "TEMPORARY_LINK/TEMPORARY_LINK_REJECT_PROOF_SUCCESS";
export const TEMPORARY_LINK_REJECT_PROOF_FAILED =
  "TEMPORARY_LINK/TEMPORARY_LINK_REJECT_PROOF_FAILED";

export const TEMPORARY_LINK_GET_COMPETITORS_VENDORS_REQUEST =
  "TEMPORARY_LINK/TEMPORARY_LINK_GET_COMPETITORS_VENDORS_REQUEST";
export const TEMPORARY_LINK_GET_COMPETITORS_VENDORS_SUCCESS =
  "TEMPORARY_LINK/TEMPORARY_LINK_GET_COMPETITORS_VENDORS_SUCCESS";
export const TEMPORARY_LINK_GET_COMPETITORS_VENDORS_FAILED =
  "TEMPORARY_LINK/TEMPORARY_LINK_GET_COMPETITORS_VENDORS_FAILED";

//SETTERS

export const SET_TEMPORARY_LINK_EDIT_LISTING_DATA =
  "TEMPORARY_LINK/SET_TEMPORARY_LINK_EDIT_LISTING_DATA";
export const SET_TEMPORARY_LINK_LISTING_DATA =
  "TEMPORARY_LINK/SET_TEMPORARY_LINK_LISTING_DATA";
export const SET_TEMPORARY_LINK_INVALID_FORM_FIELDS =
  "TEMPORARY_LINK/SET_TEMPORARY_LINK_INVALID_FORM_FIELDS";
export const SET_TEMPORARY_LINK_ACTIVE_BANNER =
  "TEMPORARY_LINK/SET_TEMPORARY_LINK_ACTIVE_BANNER";

export const SET_TEMPORARY_LINK_ADS_ASSET_FOR_UPLOAD =
  "TEMPORARY_LINK/SET_TEMPORARY_LINK_ADS_ASSET_FOR_UPLOAD";

export const SET_TEMPORARY_LINK_INITIAL_LISTING_PRICE =
  "TEMPORARY_LINK/SET_TEMPORARY_LINK_INITIAL_LISTING_PRICE";

export const SET_TEMPORARY_LINK_SELECTED_LISTING_PRICE =
  "TEMPORARY_LINK/SET_TEMPORARY_LINK_SELECTED_LISTING_PRICE";

export const SET_TEMPORARY_LINK_SHOW_FREE_LISTING_MODAL_NOTICE =
  "TEMPORARY_LINK/SET_SHOW_FREE_LISTING_MODAL_NOTICE";

export const SET_TEMPORARY_LINK_HAS_ACTIVE_OR_FREE_LISTINGS =
  "TEMPORARY_LINK/SET_TEMPORARY_LINK_HAS_ACTIVE_OR_FREE_LISTINGS";

export const SET_TEMPORARY_LINK_USER_DATA =
  "TEMPORARY_LINK/SET_TEMPORARY_LINK_USER_DATA";

const initialState = {
  userData: null,
  // INITIAL PRICE SELECTION BY USER ON GET LISTED FLOW
  initialListingPrice: null,

  // CURRENT PRICE SELECTION BY USER ON TEMPORARY LINK
  selectedListingPriceId: null,
  selectedListingPrice: null,

  // STATE FOR FREE LISTING MODAL NOTICE
  showFreeListingNoticeModal: false,

  // STATE FOR SHOWING CREATE FREE LISTING
  hasFreeOrActiveListings: true,

  // FEATURED VENDORS (for free listing preview modal)
  featuredVendors: null,

  // FETCHED LISTING DATA
  listing: null,

  // LISTING INVOICES BECAUSE WE WILL HAVE TO HANDLE MULTIPLE INVOICES LATER
  listingInvoices: null,

  // SELECTED INVOICES IN DIFFERENT STATE BECAUSE USER WILL BE ABLE TO SELECT INVOICES PARTIALLY
  selectedInvoices: null,

  // ACTIVE BANNER
  activeBanner: null,

  // LATER - RECEIPT WHEN USER SELECTS PAID ROW
  receipt: null,

  // RANDOM LISTINGS BECAUSE USER WILL HAVE UPGRADE OPTIONS FOR LISTINGS BELOW PREMIUM
  randomListings: null,

  // FOR LISTING'S EDIT FORM
  editListingData: null,
  invalidFormFields: null,

  // LISTING PROOFS BECAUSE WE WILL HAVE TO HANDLE MULTIPLE PROOFS LATER
  // pendingListingProofs: null,
  listingProof: null,
  proofListingData: null,

  // COMPETITORS LIST
  competitorsListings: null,
  competitorsListingsIsLoading: null,
  competitorsListingsError: null,

  // ERROR STATES
  error: null,
  inlineError: null,

  // LOADING STATES
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TEMPORARY_LINK_CHOOSE_LISTING_TYPE_REQUEST:
    case TEMPORARY_LINK_UPDATE_LISTING_REQUEST:
    case TEMPORARY_LINK_APPROVE_PROOF_REQUEST:
    case TEMPORARY_LINK_REJECT_PROOF_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
        inlineError: null,
        invalidFormFields: null,
      };

    case SET_TEMPORARY_LINK_USER_DATA:
      return {
        ...state,
        userData: action.payload.data,
      };

    case TEMPORARY_LINK_APPROVE_PROOF_SUCCESS:
    case TEMPORARY_LINK_REJECT_PROOF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listingProof: null,
        proofListingData: null,
      };

    case TEMPORARY_LINK_GET_LISTING_REQUEST:
      return {
        ...state,
        error: null,
        listing: null,
        isLoading: true,
        inlineError: null,
        listingProof: null,
        editListingData: null,
        listingInvoices: null,
        proofListingData: null,
        invalidFormFields: null,
      };

    case TEMPORARY_LINK_GET_LISTING_SUCCESS:
      return {
        ...state,
        listing: action.payload.data.listing,
        editListingData: action.payload.data.listing,
        listingInvoices: action.payload.data.invoices,
        listingProof: action.payload.data.proof,
        proofListingData: action.payload.data.proofListingData,
        error: null,
        isLoading: false,
        inlineError: null,
      };
    case TEMPORARY_LINK_CHOOSE_LISTING_TYPE_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        inlineError: null,
        listingInvoices: [action.payload.data],
      };

    case TEMPORARY_LINK_UPDATE_LISTING_FAILED:
    case TEMPORARY_LINK_GET_LISTING_FAILED:
    case TEMPORARY_LINK_GET_DATA_FAILED:
    case TEMPORARY_LINK_APPROVE_PROOF_FAILED:
    case TEMPORARY_LINK_REJECT_PROOF_FAILED:
      return {
        ...state,
        isLoading: false,
        invalidFormFields: null,
        error: action.payload.data.message,
        inlineMessage: action.payload.data.inlineMessage,
      };

    case SET_TEMPORARY_LINK_EDIT_LISTING_DATA:
      return {
        ...state,
        editListingData: { ...state.editListingData, ...action.payload.data },
      };

    case SET_TEMPORARY_LINK_LISTING_DATA:
      return {
        ...state,
        listing: { ...state.listing, ...action.payload.data },
      };

    case SET_TEMPORARY_LINK_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: { ...action.payload.data },
      };

    case SET_TEMPORARY_LINK_ACTIVE_BANNER:
      return {
        ...state,
        activeBanner: action.payload.data,
      };

    case TEMPORARY_LINK_UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        inlineError: null,
        invalidFormFields: null,
        listing: action.payload.data,
        editListingData: action.payload.data,
      };

    case TEMPORARY_LINK_GET_FEATURED_VENDORS_SUCCESS:
      return {
        ...state,
        featuredVendors: action.payload.data,
      };

    case SET_TEMPORARY_LINK_ADS_ASSET_FOR_UPLOAD:
      return {
        ...state,
        editListingData: {
          ...state?.editListingData,
          adsAssets: addOrUpdateAsset(
            state?.editListingData?.adsAssets || [],
            action.payload.data,
          ),
        },
      };

    case TEMPORARY_LINK_UPLOAD_ADS_ASSETS_SUCCESS:
      return {
        ...state,
        editListingData: {
          ...state.editListingData,
          adsAssets: updateAssetStatus(
            state.editListingData.adsAssets,
            action.payload.data,
            {
              inProgress: false,
              error: false,
            },
          ),
        },
      };

    case TEMPORARY_LINK_UPLOAD_ADS_ASSETS_FAILED:
      return {
        ...state,
        editListingData: {
          ...state.editListingData,
          adsAssets: updateAssetStatus(
            state.editListingData.adsAssets,
            action.payload.data,
            {
              inProgress: false,
              error: true,
            },
          ),
        },
      };

    case TEMPORARY_LINK_CHOOSE_LISTING_TYPE_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case SET_TEMPORARY_LINK_INITIAL_LISTING_PRICE:
      return {
        ...state,
        initialListingPrice: action.payload,
      };

    case SET_TEMPORARY_LINK_SELECTED_LISTING_PRICE:
      return {
        ...state,
        selectedListingPrice: action.payload,
      };

    case SET_TEMPORARY_LINK_SHOW_FREE_LISTING_MODAL_NOTICE:
      return {
        ...state,
        showFreeListingNoticeModal: action.payload.data,
      };

    case SET_TEMPORARY_LINK_HAS_ACTIVE_OR_FREE_LISTINGS:
      return {
        ...state,
        hasFreeOrActiveListings: action.payload.data,
      };

    case TEMPORARY_LINK_GET_COMPETITORS_VENDORS_REQUEST:
      return {
        ...state,
        competitorsListingsIsLoading: true,
        competitorsListings: null,
      };
    case TEMPORARY_LINK_GET_COMPETITORS_VENDORS_SUCCESS:
      return {
        ...state,
        competitorsListings: action.payload.data,
        competitorsListingsIsLoading: false,
      };
    case TEMPORARY_LINK_GET_COMPETITORS_VENDORS_FAILED:
      return {
        ...state,
        competitorsListings: null,
        competitorsListingsError: action.payload.data,
      };
    default:
      return state;
  }
};

const selectTemporaryListingLinkState = (state) =>
  state.temporaryLinkListingState;

export const selectTemporaryLinkListingPreviewData = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.listing,
);

export const selectTemporaryLinkListingPreviewRenewalTimestampData =
  createSelector(
    selectTemporaryListingLinkState,
    (temporaryLinkListingState) =>
      temporaryLinkListingState.listing?.renewalTimestamp,
  );

export const selectTemporaryLinkEditListingData = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.editListingData,
);

export const selectTemporaryLinkListingAdsAssets = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.editListingData?.adsAssets,
);

export const selectTemporaryLinkContinueAssetsButtonIsDisabled = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    !!temporaryLinkListingState.editListingData?.adsAssets?.find(
      (asset) => asset.inProgress,
    ),
);

export const selectTemporaryLinkListingWorkingHours = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.editListingData?.workingHours,
);

export const selectTemporaryLinkListingDescription = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.editListingData?.description,
);

export const selectTemporaryLinkListingAdsRates = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.editListingData?.adsRates,
);

export const selectTemporaryLinkListingAdsServiceAmenities = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.editListingData?.adsServiceAmenities,
);

export const selectTemporaryLinkListingAdsAmenities = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.editListingData?.adsAmenities,
);

export const selectTemporaryLinkListingAdsCategories = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.editListingData?.adsCategories,
);

export const selectTemporaryLinkListingAdsSubCategories = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.editListingData?.adsSubCategories,
);

export const selectTemporaryListingLinkInvoices = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.listingInvoices,
);

export const selectTemporaryLinkInvalidFormFields = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.invalidFormFields,
);

export const selectTemporaryLinkInlineError = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.inlineError,
);

export const selectTemporaryLinkIsLoading = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.isLoading,
);

export const selectTemporaryLinkError = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.error,
);

export const selectTemporaryLinkListingLatitude = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.editListingData?.latitude,
);

export const selectTemporaryLinkListingLongitude = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.editListingData?.longitude,
);

export const selectTemporaryLinkListingActiveBanner = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.activeBanner,
);

export const selectTemporaryLinkListingInitialPrice = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.initialListingPrice,
);

export const selectTemporaryLinkListingSelectedPrice = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.selectedListingPrice,
);

export const selectTemporaryLinkListingFeaturedVendors = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.featuredVendors,
);

export const selectTemporaryLinkListingShowFreeListingModal = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.showFreeListingNoticeModal,
);

export const selectTemporaryLinkHasFreeOrActiveListings = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.hasFreeOrActiveListings,
);

export const selectTemporaryLinkListingProof = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.listingProof,
);

export const selectTemporaryLinkListingProofId = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.listingProof?.id,
);

export const selectTemporaryLinkListingProofStatus = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.listingProof?.status,
);

export const selectTemporaryLinkProofListingData = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.proofListingData,
);

export const selectTemporaryLinkHasProofListingData = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => !!temporaryLinkListingState.proofListingData,
);

export const selectTemporaryLinkUserData = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.userData,
);

export const selectTemporaryLinkCompetitorsData = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) => temporaryLinkListingState.competitorsListings,
);
export const selectTemporaryLinkCompetitorsIsLoading = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.competitorsListingsIsLoading,
);

export const selectTemporaryLinkCompetitorsError = createSelector(
  selectTemporaryListingLinkState,
  (temporaryLinkListingState) =>
    temporaryLinkListingState.competitorsListingsError,
);
