import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Banner } from "@find-truck-service/ui/src/components/react/Banners";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { ListingItem } from "@find-truck-service/ui/src/components/react/ListingItem";
import { ListingItemsTypeValues } from "@find-truck-service/types/ui/listingItemComponent";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { TemporaryLinkPageTitle } from "../../../components/fts-components-v2/TemporaryLink/TemporaryLinkPageTitle/TemporaryLinkPageTitle";
import { TemporaryLinkContentContainer } from "../../../components/fts-components-v2/TemporaryLink/TemporaryLinkContentContainer/TemporaryLinkContentContainer";
import {
  selectTemporaryLinkIsLoading,
  selectTemporaryLinkListingActiveBanner,
  selectTemporaryLinkListingPreviewData,
  selectTemporaryLinkListingSelectedPrice,
} from "../../../redux/reducers/v2/temporaryListingLink";
import { CreatedFooterButtons } from "./components/CreatedFooterButtons";

import { setTemporaryLinkSelectedListingPrice } from "../../../redux/actions/v2/temporaryLinkListing";
import { SelectedUpgradeListingFooterButtons } from "./components/SelectedUpgradeListingFooterButtons";

import { TemporaryLinkUpgradeListingOptions } from "../../../components/fts-components-v2/TemporaryLinkUpgradeListingOptions";
import { selectPaymentsIsLoading } from "../../../redux/reducers/v2/payments";

const TemporaryLinkPreviewListing = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectTemporaryLinkIsLoading);
  const paymentIsLoading = useSelector(selectPaymentsIsLoading);
  const listingData = useSelector(selectTemporaryLinkListingPreviewData);
  const activeBanner = useSelector(selectTemporaryLinkListingActiveBanner);

  const selectedUpgradeListingPrice = useSelector(
    selectTemporaryLinkListingSelectedPrice,
  );

  const handleChangeListingSelection = useCallback(
    (newListingPrice) => {
      if (
        selectedUpgradeListingPrice &&
        newListingPrice.id === selectedUpgradeListingPrice.id
      ) {
        dispatch(setTemporaryLinkSelectedListingPrice(null));
        return;
      }
      dispatch(setTemporaryLinkSelectedListingPrice(newListingPrice));
    },
    [selectedUpgradeListingPrice],
  );

  if (!listingData || paymentIsLoading) return <FullPageLoader />;

  return (
    <>
      {isLoading && <FullPageLoader />}
      <TemporaryLinkContentContainer>
        <Banner title={activeBanner?.title} type={activeBanner?.type} />
        <FtsWrapper flex={1} overflow={"auto"}>
          <FtsContainer mb={"2xl"}>
            <TemporaryLinkPageTitle title="Listing Preview" />
            <ListingItem
              listingData={listingData}
              type={ListingItemsTypeValues.LIVE}
            />
            <TemporaryLinkUpgradeListingOptions
              selectedUpgradeListingPrice={selectedUpgradeListingPrice}
              handleChangeListingSelection={handleChangeListingSelection}
            />
          </FtsContainer>
        </FtsWrapper>
        {selectedUpgradeListingPrice ? (
          <SelectedUpgradeListingFooterButtons />
        ) : (
          <CreatedFooterButtons />
        )}
      </TemporaryLinkContentContainer>
    </>
  );
};

export default TemporaryLinkPreviewListing;
