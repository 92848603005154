import React, { useCallback, useEffect, useState } from "react";
import { SearchNoResults } from "./SearchNoResults";
import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ModalContentComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalContentComponent";

export const AmenitiesModalContentFields = ({
  selectedAmenities,
  setListingData,
}) => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState("");
  const amenities = useSelector((state) => state.essentialLists.amenities);
  const [filteredAmenities, setFilteredAmenities] = useState(null);
  const hasAmenities = !!filteredAmenities?.length;
  const showNoResults = !hasAmenities && !!searchString;

  const onChangeAmenities = useCallback(
    (id, value, name) => {
      let adsAmenities = [...selectedAmenities, { amenityId: id, name }];
      if (!value)
        adsAmenities = adsAmenities.filter(({ amenityId }) => amenityId !== id);
      dispatch(setListingData({ data: { adsAmenities } }));
    },
    [selectedAmenities],
  );

  useEffect(() => {
    if (!searchString) return setFilteredAmenities(amenities);
    const filtered = amenities.filter((e) =>
      e.name.toLowerCase().includes(searchString.toLowerCase()),
    );
    setFilteredAmenities(filtered);
  }, [searchString, amenities]);

  function onClearSearch() {
    setSearchString("");
  }

  function onSearchChange({ target: { value } }) {
    setSearchString(value);
  }

  return (
    <ModalContentComponent flex={1} overflow={"scroll"}>
      <FtsWrapper pt={"2xs"}>
        <Input
          name={"search"}
          size={InputSizes.md}
          value={searchString}
          placeHolder={"Search"}
          onChange={onSearchChange}
          appendIcon={IconNames.Close}
          prependIcon={IconNames.Lupe}
          variant={InputVariants.contained}
          onAppendIconClick={onClearSearch}
        />
        <FtsRow rowGap={"xl"} width={"100%"} mt={"lg"}>
          {hasAmenities &&
            filteredAmenities.map((e) => {
              const checked = !!selectedAmenities.find(
                (f) => f.amenityId === e.id,
              );
              return (
                <FtsColumn key={e.id} size={{ xs: 12 }}>
                  <Checkbox
                    size={CheckboxSizes.lg}
                    label={e.name}
                    onClick={() => onChangeAmenities(e.id, !checked, e.name)}
                    checked={checked}
                  />
                </FtsColumn>
              );
            })}
          {showNoResults && <SearchNoResults searchString={searchString} />}
        </FtsRow>
      </FtsWrapper>
    </ModalContentComponent>
  );
};
