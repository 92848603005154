import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

export const ListingInfoHeader = () => {
  return (
    <FtsWrapper display={{ xs: "none", sm: "block", md: "block", lg: "block" }}>
      <Span
        variant={VariantsValues["text-md"]}
        color={ColorsValue["greyscale-800"]}
        weight={WeightSize.bold}
      >
        Business Listing
      </Span>
    </FtsWrapper>
  );
};
