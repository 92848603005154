import Cookies from "universal-cookie";

export const AUTH_KEY = "x-auth";
export const EXP_KEY = "x-exp";
export const REFRESH_KEY = "x-refresh";
export const TEMPORARY_TOKEN = "temporary-token";
export const LOGGED_USER = "logged-user";

export const PURCHASE_LISTING_TEMPORARY_TOKEN =
  "purchase-listing-temporary-token";

export const SELECTED_USER_TYPE = "selected-user-type";
export const SELECTED_PRICE_ID = "selected-price-id";
export const PURCHASE_LISTING_DATA = "purchase-listing-data";
export const LAST_VISITED_ROUTE = "lastVisitedRoute";
export const PRIVATE_LOCATION_REDIRECT = "privateLocationRedirect";
export const LISTING_DATA_ANALYTIC_API_KEY =
  "get-listing-data-analytic-api-key";

const FTSCookies = new Cookies();

export const path = "/";
export function getAuthToken() {
  const token = FTSCookies.get(AUTH_KEY);
  return `Bearer ${token}`;
}
export async function removeAllCookies() {
  await FTSCookies.remove(AUTH_KEY, { path });
  await FTSCookies.remove(TEMPORARY_TOKEN, { path });
  await FTSCookies.remove(REFRESH_KEY, { path });
  await FTSCookies.remove(PURCHASE_LISTING_TEMPORARY_TOKEN, { path });
  await FTSCookies.remove(LOGGED_USER, { path });
}

export function setLoggedUserCookie(user, bearerToken) {
  const { userType, accountLink, id, firstName, lastName, type, email } = user;

  const userData = {
    userType,
    accountLink,
    id,
    firstName,
    lastName,
    type,
    email,
  };

  FTSCookies.set(AUTH_KEY, bearerToken, { path: "/" });
  FTSCookies.set(LOGGED_USER, userData, { path: "/" });
}

export default FTSCookies;
