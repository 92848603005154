import moment from "moment";
import FTSAxios from "../axios/fts.instance";
import logger from "./logger";

const folderPathImage = `images/${moment().format("YYYY")}/${moment().format("MM")}/${moment().format("DD")}`;

export const getUploadUrl = async ({ filePath, mimeType }) => {
  try {
    let {
      data: { uploadUrl },
    } = await FTSAxios.get("/profile/upload-url", {
      params: { filePath, mimeType },
    });

    return uploadUrl;
  } catch (e) {
    logger.error(e);
  }
};

const removeExtraSpaces = (str) => str.replace(/\s{2,}/g, " ").trim();
const stripSpecialChars = (str) => str.replace(/[^a-zA-Z0-9 ]/g, "").trim();

export const generateUserFileName = (userData, fileExtension) => {
  const ID = userData.id;
  const encodedAddress = stripSpecialChars(
    `${userData.address} ${userData.city} ${userData.state} ${userData.zip}`,
  );
  const encodedCompanyName = stripSpecialChars(userData.companyName);
  const encodedFullName = stripSpecialChars(
    `${userData.firstName}-${userData.lastName}`,
  );
  const address = encodedAddress.split(" ").join("-").toLowerCase();

  let name = encodedCompanyName
    ? encodedCompanyName.split(" ").join("-").toLowerCase()
    : encodedFullName.toLowerCase();

  // name = `${name}-${address}-${ID}-${Date.now()}`;
  name = `${folderPathImage}/${name}-${address}-${ID}-${Date.now()}.${fileExtension}`;

  return name;
};

export const generateTodoFileName = (todoData) => {
  const ID = todoData.id;
  const encodedTypeName = todoData.todoType
    ? stripSpecialChars(todoData.todoType)
    : "";
  const type = encodedTypeName || "";
  const encodedTitle = stripSpecialChars(todoData.title);
  let name = encodedTitle.split(" ").join("-").toLowerCase();

  name = `${ID}_${name}_${type}_${Date.now()}`;

  return name;
};

export const generateListingFileName = (listingData, fileExtension = "png") => {
  const ID = listingData.id;
  const listingName = listingData.name;
  const addressString = `${listingData.address} ${listingData.city} ${listingData.state}`;

  const encodedAddress = removeExtraSpaces(stripSpecialChars(addressString));
  const encodedListingName = removeExtraSpaces(stripSpecialChars(listingName));

  const address = encodedAddress.split(" ").join("-").toLowerCase();
  const name = encodedListingName.split(" ").join("-").toLowerCase();

  return `${folderPathImage}/${name}-${address}-${ID ? ID + "-" : ""}${Date.now()}.${fileExtension}`;
};

export async function generateFileForUpload(file, listingData) {
  const type = file.type.split("/").pop();
  const arrayBuffer = await file.arrayBuffer();
  const imageSize = file.size / 1000000;
  const fileName = generateListingFileName(listingData, type);
  const mimeType = type === "pdf" ? `application/${type}` : `image/${type}`;
  const versions = JSON.stringify([
    { path: fileName, version: 1, createdAt: new Date() },
  ]);

  const adsAsset = {
    file,
    type,
    fileName,
    versions,
    error: false,
    inProgress: true,
    fileUrl: fileName,
    fileSize: imageSize,
  };

  return {
    type,
    adsAsset,
    mimeType,
    versions,
    fileName,
    imageSize,
    arrayBuffer,
    isLocalFile: true,
    id: Math.random() * 1000000,
    uploadConfig: { headers: { "Content-Type": mimeType } },
    blob: new Blob([new Uint8Array(arrayBuffer)], { type }),
  };
}
