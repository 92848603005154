import { createSelector } from "reselect";

// For all action that have call to api need to be defined _REQUEST, _SUCCESS and _FAILED action
export const CREATE_LISTING_REQUEST = "LISTING/CREATE_LISTING_REQUEST";
export const CREATE_LISTING_SUCCESS = "LISTING/CREATE_LISTING_SUCCESS";
export const CREATE_LISTING_FAILED = "LISTING/CREATE_LISTING_FAILED";

export const GET_LISTING_BY_ID_REQUEST = "LISTING/GET_LISTING_BY_ID_REQUEST";
export const GET_LISTING_BY_ID_SUCCESS = "LISTING/GET_LISTING_BY_ID_SUCCESS";
export const GET_LISTING_BY_ID_FAILED = "LISTING/GET_LISTING_BY_ID_FAILED";

export const UPDATE_LISTING_REQUEST = "LISTING/UPDATE_LISTING_REQUEST";
export const UPDATE_LISTING_SUCCESS = "LISTING/UPDATE_LISTING_SUCCESS";
export const UPDATE_LISTING_FAILED = "LISTING/UPDATE_LISTING_FAILED";

export const UPLOAD_ADS_ASSETS_REQUEST = "LISTING/UPLOAD_ADS_ASSETS_REQUEST";
export const UPLOAD_ADS_ASSETS_SUCCESS = "LISTING/UPLOAD_ADS_ASSETS_SUCCESS";
export const UPLOAD_ADS_ASSETS_FAILED = "LISTING/UPLOAD_ADS_ASSETS_FAILED";

export const GET_DRAFT_LISTING_REQUEST = "LISTING/GET_DRAFT_LISTING_REQUEST";
export const GET_DRAFT_LISTING_SUCCESS = "LISTING/GET_DRAFT_LISTING_SUCCESS";
export const GET_DRAFT_LISTING_FAILED = "LISTING/GET_DRAFT_LISTING_FAILED";

export const CREATE_LISTING_INVOICE_REQUEST =
  "LISTING/CREATE_LISTING_INVOICE_REQUEST";
export const CREATE_LISTING_INVOICE_REQUEST_FAILED =
  "LISTING/CREATE_LISTING_INVOICE_REQUEST_FAILED";
export const CREATE_LISTING_INVOICE_REQUEST_SUCCESS =
  "LISTING/CREATE_LISTING_INVOICE_REQUEST_SUCCESS";

// For all action where needs to update part of redux state action name needs to have SET_ prefix
export const SET_LISTING_DATA_INVALID_FORM_FIELDS =
  "LISTING/SET_LISTING_DATA_INVALID_FORM_FIELDS";
export const SET_LISTING_DATA = "LISTING/SET_LISTING_DATA";
export const SET_INITIAL_LISTING_DATA = "LISTING/SET_INITIAL_LISTING_DATA";
export const SET_ADS_ASSET_FOR_UPLOAD = "LISTING/SET_ADS_ASSET_FOR_UPLOAD";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  inlineError: null,
  draftListingData: null,
  invalidFormFields: null,
};

// action.payload = {
//   data: {},
//   onSuccess: () => {},
//   onFailed: () => {},
// };
/// Reducer
export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_LISTING_REQUEST:
    case CREATE_LISTING_REQUEST:
    case GET_LISTING_BY_ID_REQUEST:
    case CREATE_LISTING_INVOICE_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
        inlineError: null,
        invalidFormFields: null,
      };

    case GET_DRAFT_LISTING_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
        inlineError: null,
      };
    case CREATE_LISTING_INVOICE_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        inlineError: null,
        isLoading: false,
      };
    case CREATE_LISTING_SUCCESS:
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        inlineError: null,
        data: action.payload,
        draftListingData: null,
        invalidFormFields: null,
      };

    case GET_LISTING_BY_ID_SUCCESS:
      //TODO update logic on API
      return {
        ...state,
        error: action.payload.data ? null : {},
        isLoading: false,
        inlineError: null,
        invalidFormFields: null,
        data: action.payload.data,
      };

    case GET_DRAFT_LISTING_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        inlineError: null,
        draftListingData: action.payload.data,
      };

    case GET_DRAFT_LISTING_FAILED:
      return {
        ...state,
        isLoading: false,
        draftListingData: null,
        invalidFormFields: null,
        error: action.payload.data.message,
        inlineError: action.payload.data.inlineMessage,
      };

    case CREATE_LISTING_FAILED:
    case UPDATE_LISTING_FAILED:
    case CREATE_LISTING_INVOICE_REQUEST_FAILED:
    case GET_LISTING_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        invalidFormFields: null,
        error: action.payload.data.message,
        inlineError: action.payload.data.inlineMessage,
      };

    case SET_LISTING_DATA_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: { ...action.payload.data },
      };

    case SET_LISTING_DATA:
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
      };

    case SET_INITIAL_LISTING_DATA:
      return {
        ...state,
        data: initialState.data,
      };

    case SET_ADS_ASSET_FOR_UPLOAD:
      let newAdsAssets = [...(state?.data?.adsAssets || [])];
      const fileName = action.payload.file.name;
      const isDuplicate = !!newAdsAssets.find(
        ({ file }) => file?.name === fileName,
      );

      if (!isDuplicate) newAdsAssets = [...newAdsAssets, action.payload];
      else
        newAdsAssets = newAdsAssets.map((asset) => {
          if (asset.file.name !== fileName) return asset;
          return action.payload;
        });

      return { ...state, data: { ...state?.data, adsAssets: newAdsAssets } };

    case UPLOAD_ADS_ASSETS_SUCCESS:
      const adsAssetsWithSuccess = state.data.adsAssets.map((asset) => {
        if (asset.fileName !== action.payload) return asset;
        return { ...asset, inProgress: false, error: false };
      });
      return {
        ...state,
        data: { ...state.data, adsAssets: adsAssetsWithSuccess },
      };

    case UPLOAD_ADS_ASSETS_FAILED:
      const adsAssetsWithError = state.data.adsAssets.map((asset) => {
        if (asset.fileName !== action.payload) return asset;
        return { ...asset, error: true, inProgress: false };
      });
      return {
        ...state,
        data: { ...state.data, adsAssets: adsAssetsWithError },
      };

    default:
      return state;
  }
}

/// Selectors
const selectListingState = (state) => state.listingState;

export const selectListingData = createSelector(
  selectListingState,
  (listing) => {
    return listing.data || {};
  },
);

export const selectListingDataDescription = createSelector(
  selectListingState,
  (listing) => {
    return listing.data?.description || "";
  },
);

export const selectListingId = createSelector(selectListingState, (listing) => {
  return listing.data?.id;
});

export const selectHasListingData = createSelector(
  selectListingState,
  (listing) => !!listing.data,
);

export const selectListingLatitude = createSelector(
  selectListingState,
  (listing) => {
    return listing.data?.latitude;
  },
);

export const selectListingLongitude = createSelector(
  selectListingState,
  (listing) => {
    return listing.data?.longitude;
  },
);

export const selectListingWorkingHours = createSelector(
  selectListingState,
  (listing) => {
    return listing.data?.workingHours;
  },
);

export const selectListingAdsRates = createSelector(
  selectListingState,
  (listing) => {
    return listing.data?.adsRates ?? {};
  },
);

export const selectListingAdsAssetsLength = createSelector(
  selectListingState,
  (listing) => {
    return listing.data?.adsAssets?.length || 0;
  },
);

export const selectListingAdsCategories = createSelector(
  selectListingState,
  (listing) => {
    return listing.data?.adsCategories || [];
  },
);

export const selectListingAdsSubCategories = createSelector(
  selectListingState,
  (listing) => {
    return listing.data?.adsSubCategories || [];
  },
);

export const selectListingInvalidFormFields = createSelector(
  selectListingState,
  (listing) => {
    return listing.invalidFormFields || {};
  },
);

export const selectListingAdsAmenities = createSelector(
  selectListingState,
  (listing) => {
    return listing.data?.adsAmenities ?? [];
  },
);

export const selectListingAdsServiceAmenities = createSelector(
  selectListingState,
  (listing) => {
    return listing.data?.adsServiceAmenities ?? [];
  },
);

export const selectListingAdsAssets = createSelector(
  selectListingState,
  (listing) => {
    return listing.data?.adsAssets ?? [];
  },
);

export const selectListingAdsAssetsIsLoading = createSelector(
  selectListingState,
  (listing) => {
    return !!listing.data?.adsAssets?.some((asset) => asset.inProgress);
  },
);

export const selectListingDataError = createSelector(
  selectListingState,
  (listing) => {
    return listing.error;
  },
);

export const selectIsLoadingListingData = createSelector(
  selectListingState,
  (listing) => {
    return listing.isLoading;
  },
);

export const selectDraftListingData = createSelector(
  selectListingState,
  (listing) => {
    return listing.draftListingData;
  },
);

export const selectListingInlineError = createSelector(
  selectListingState,
  (listing) => {
    return listing.inlineError;
  },
);
