import { formatRawDate } from "../../../configs/dateConfig";

export const weekdays = [
  {
    dayIndex: 0,
    day: "Monday",
    from: { label: "24 hrs", value: "24 hrs" },
    to: null,
    invalid: false,
  },
  {
    dayIndex: 1,
    day: "Tuesday",
    from: { label: "8 AM", value: "8 AM" },
    to: { label: "5 PM", value: "5 PM" },
    invalid: false,
  },
  {
    dayIndex: 2,
    day: "Wednesday",
    from: { label: "8 AM", value: "8 AM" },
    to: { label: "5 PM", value: "5 PM" },
    invalid: false,
  },
  {
    dayIndex: 3,
    day: "Thursday",
    from: { label: "8 AM", value: "8 AM" },
    to: { label: "5 PM", value: "5 PM" },
    invalid: false,
  },
  {
    dayIndex: 4,
    day: "Friday",
    from: { label: "8 AM", value: "8 AM" },
    to: { label: "5 PM", value: "5 PM" },
    invalid: false,
  },
  {
    dayIndex: 5,
    day: "Saturday",
    from: { label: "8 AM", value: "8 AM" },
    to: { label: "3 AM", value: "3 AM" },
    invalid: false,
  },
  {
    dayIndex: 6,
    day: "Sunday",
    from: { label: "Closed", value: "Closed" },
    to: null,
    invalid: false,
  },
];

export const formikInitialValues = {
  // listing main info
  name: "Motiff",
  excludeRenewalEmails: false,
  excludeDowngrades: false,
  isItPaidFor: false,
  includeInAuthorizedPromotions: false,
  includeInFeaturedVendors: false,
  isSticky: false,
  createdAt: formatRawDate(new Date()),
  renewalDate: formatRawDate(new Date()),
  //1,
  accountType: {
    value: 1,
    label: "User",
  },
  //1,
  paymentStatus: {
    value: 1,
    label: "User",
  },
  //1,
  priceId: {
    value: 1,
    label: "User",
  },
  adminStatus: { value: 1, label: "Free" },
  addressInfo: {
    address: "Džidžikovac 6",
    city: "Sarajevo",
    state: { value: "AZ", label: "Arizona" },
    zip: 12315,
  },
  interstate: "",
  mileMarker: "",
  geoLocation: {
    lat: "43.86147",
    lng: "18.416576",
  },

  // business section
  phone: "1221221235",
  afterHours: "1231231235",
  fax: "1231231248",
  website: "www.findtruckservice.com",
  website2: "www.findtruckservice2.com",
  email: "test@test.com",
  specials: "special",
  IdentifierIdentifier: "illinois-towing-service-inc-chicago-il-423069",

  // description section
  description:
    "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.",

  // hours of operation section
  workingHours: {
    open24hrs: false,
    MondayFrom: { label: "5 AM", value: "5 AM" },
    MondayTo: { label: "4 PM", value: "4 PM" },
    TuesdayFrom: { label: "5 AM", value: "5 AM" },
    TuesdayTo: { label: "4 PM", value: "4 PM" },
    WednesdayFrom: { label: "5 AM", value: "5 AM" },
    WednesdayTo: { label: "4 PM", value: "4 PM" },
    ThursdayFrom: { label: "5 AM", value: "5 AM" },
    ThursdayTo: { label: "4 PM", value: "4 PM" },
    FridayFrom: { label: "5 AM", value: "5 AM" },
    FridayTo: { label: "4 PM", value: "4 PM" },
    SaturdayFrom: { label: "5 AM", value: "5 AM" },
    SaturdayTo: { label: "4 PM", value: "4 PM" },
    SundayFrom: { label: "24 hrs", value: "24 hrs" },
    SundayTo: null,
    towingRecoveryOpen: true,
    mobileRoadServiceOpen: true,
    specialHours: "24 hour Roadside Service",
  },

  // services section
  categories: {
    category_1: {
      active: true,
      subCats: {
        subCategory_1: true,
        subCategory_2: true,
      },
    },
    category_2: {
      active: true,
      subCats: {
        subCategory_8: true,
        subCategory_10: true,
      },
    },
    category_12: {
      active: true,
      subCats: {
        subCategory_70: true,
      },
    },
  },

  amenities: {
    amenity_1: true,
    amenity_17: true,
  },

  serviceAmenities: {
    serviceAmenity_1: true,
    serviceAmenity_76: true,
  },

  // note section
  adminNote: "Some note",
};

export const amenityOptions = [
  { value: "service", label: "Service" },
  { value: "trailer_washout", label: "Trailer washout" },
  { value: "chemical_wash", label: "Chemical wash" },
  { value: "trailer_washes", label: "Trailer washes" },
  { value: "bobtail_fuel_stop", label: "Bobtail fuel stop" },
  { value: "scale", label: "Scale" },
  { value: "tanker_washout", label: "Tanker washout" },
  { value: "dry_bulk_washout", label: "Dry bulk washout" },
  { value: "engine_wash", label: "Engine wash" },
  { value: "grain_hopper_washout", label: "Grain - Hopper washout" },
  { value: "wifi", label: "Wifi" },
  { value: "laundry", label: "Laundry" },
  { value: "diesel_fuel_stop", label: "Diesel fuel stop" },
  { value: "chemical_wash_hazardous", label: "Chemical wash - Hazardous" },
  { value: "bulk_def", label: "Bulk DEF" },
  { value: "tanker_washes", label: "Tanker washes" },
  { value: "parking", label: "Parking" },
  { value: "lounge", label: "Lounge" },
  { value: "livestock_trailer_wash", label: "Livestock trailer wash" },
  { value: "truck_washes", label: "Truck washes" },
  { value: "self_service_wash", label: "Self service wash" },
  { value: "restaurant", label: "Restaurant" },
  { value: "showers", label: "Showers" },
  { value: "food_grade_washout", label: "Food grade washout" },
];

export const listingBlankValues = {
  // listing main info
  name: "",
  excludeRenewalEmails: false,
  excludeDowngrades: false,
  isItPaidFor: false,
  includeInAuthorizedPromotions: false,
  includeInFeaturedVendors: false,
  isSticky: false,
  noteIsSticky: false,
  createdAt: formatRawDate(new Date()),
  renewalDate: "",
  addressInfo: {
    address: "",
    city: "",
    state: "",
    zip: "",
  },
  accountType: {
    value: 1,
    label: "User",
  },
  //1,
  paymentStatus: {
    value: 1,
    label: "User",
  },
  //1,
  priceId: 1,
  status: { value: 1, label: "Active" },
  adminStatus: { value: 1, label: "Free" },
  // accountType: 1,
  // // {
  // //   value: 1,
  // //   label: "User",
  // // },
  // paymentStatus: 1,
  // // {
  // //   value: 1,
  // //   label: "User",
  // // },
  // priceId: 1,
  // // {
  // //   value: 1,
  // //   label: "User",
  // // },
  // adminStatus: 1, // { label: "Free", value: 1 },
  interstate: "",
  mileMarker: "",
  geoLocation: {
    lat: "40.730610",
    lng: "-73.935242",
  },

  // business section
  phone: "",
  afterHours: "",
  fax: "",
  website: "",
  website2: "",
  email: "",
  specials: "",
  seoUrlIdentifier: "",

  // description section
  description: "",

  // hours of operation section
  workingHours: {
    open24hrs: false,
    MondayFrom: { label: "5 AM", value: "5 AM" },
    MondayTo: { label: "4 PM", value: "4 PM" },
    TuesdayFrom: { label: "5 AM", value: "5 AM" },
    TuesdayTo: { label: "4 PM", value: "4 PM" },
    WednesdayFrom: { label: "5 AM", value: "5 AM" },
    WednesdayTo: { label: "4 PM", value: "4 PM" },
    ThursdayFrom: { label: "5 AM", value: "5 AM" },
    ThursdayTo: { label: "4 PM", value: "4 PM" },
    FridayFrom: { label: "5 AM", value: "5 AM" },
    FridayTo: { label: "4 PM", value: "4 PM" },
    SaturdayFrom: { label: "5 AM", value: "5 AM" },
    SaturdayTo: { label: "4 PM", value: "4 PM" },
    SundayFrom: { label: "24 hrs", value: "24 hrs" },
    SundayTo: null,
    towingRecoveryOpen: false,
    mobileRoadServiceOpen: false,
    specialHours: "",
  },

  // services section
  // ...
  categories: {},

  // amenities section
  amenities: {},

  // note section
  adminNote: "",
  adminNoteType: "",
};

export const testingValues = {
  // listing main info
  name: "New listing with a very long title for testing purposes",
  excludeRenewalEmails: false,
  excludeDowngrades: false,
  isItPaidFor: false,
  includeInAuthorizedPromotions: false,
  includeInFeaturedVendors: false,
  isSticky: false,
  createdAt: formatRawDate(new Date()),
  renewalDate: "",
  addressInfo: {
    address: "",
    city: "",
    state: "",
    zip: "",
  },
  accountType: {
    value: 1,
    label: "User",
  },
  //1,
  paymentStatus: {
    value: 1,
    label: "User",
  },
  //1,
  priceId: 1,
  adminStatus: { value: 1, label: "Free" },
  // accountType: 1,
  // // {
  // //   value: 1,
  // //   label: "User",
  // // },
  // paymentStatus: 1,
  // // {
  // //   value: 1,
  // //   label: "User",
  // // },
  // priceId: 1,
  // // {
  // //   value: 1,
  // //   label: "User",
  // // },
  // adminStatus: 1, // { label: "Free", value: 1 },
  interstate: "",
  mileMarker: "",
  geoLocation: {
    lat: "40.730610",
    lng: "-73.935242",
  },

  // business section
  phone: "(800) 245-2452",
  afterHours: "",
  fax: "(800) 245-2452",
  website: "",
  website2: "",
  email: "test@test.com",
  specials: "",
  seoUrlIdentifier: "",

  // description section
  description: "desc",

  // hours of operation section
  workingHours: {
    open24hrs: false,
    MondayFrom: { label: "5 AM", value: "5 AM" },
    MondayTo: { label: "4 PM", value: "4 PM" },
    TuesdayFrom: { label: "5 AM", value: "5 AM" },
    TuesdayTo: { label: "4 PM", value: "4 PM" },
    WednesdayFrom: { label: "5 AM", value: "5 AM" },
    WednesdayTo: { label: "4 PM", value: "4 PM" },
    ThursdayFrom: { label: "5 AM", value: "5 AM" },
    ThursdayTo: { label: "4 PM", value: "4 PM" },
    FridayFrom: { label: "5 AM", value: "5 AM" },
    FridayTo: { label: "4 PM", value: "4 PM" },
    SaturdayFrom: { label: "5 AM", value: "5 AM" },
    SaturdayTo: { label: "4 PM", value: "4 PM" },
    SundayFrom: { label: "24 hrs", value: "24 hrs" },
    SundayTo: null,
    towingRecoveryOpen: false,
    mobileRoadServiceOpen: false,
    specialHours: "",
  },

  // services section
  // ...
  categories: {},

  // amenities section
  amenities: {},

  // note section
  adminNote: "",
  adminNoteType: "",
};
