import React from "react";
import { BannerButtonsSmall } from "./BannerButtonsSmall";
import { BannerButtonsLarge } from "./BannerButtonsLarge";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
export const BannerButtons = (props) => {
    return (React.createElement(React.Fragment, null,
        props.withButtons && (React.createElement(React.Fragment, null,
            React.createElement(FtsWrapper, { gap: "lg", align: "center", direction: "row", justify: "flex-end", display: { xs: "flex", sm: "flex", md: "none", lg: "none" } },
                React.createElement(BannerButtonsSmall, { ...props })),
            React.createElement(FtsWrapper, { gap: "lg", align: "center", direction: "row", justify: "flex-end", display: { xs: "none", sm: "none", md: "flex", lg: "flex" } },
                React.createElement(BannerButtonsLarge, { ...props })))),
        !!props.onClose && (React.createElement(FtsWrapper, { align: "center", display: { xs: "none", sm: "flex", md: "flex", lg: "flex" } },
            React.createElement(Button, { size: ButtonSizes.sm, onClick: props.onClose, type: ButtonTypes.text, iconName: IconNames.Close, color: ButtonColorsValue.neutral, display: { xs: "none", sm: "none", md: "flex", lg: "flex" } }),
            React.createElement(Button, { size: ButtonSizes.md, onClick: props.onClose, type: ButtonTypes.text, iconName: IconNames.Close, color: ButtonColorsValue.neutral, display: { xs: "none", sm: "flex", md: "none", lg: "none" } })))));
};
