import React, { useCallback } from "react";
import { isObject } from "lodash";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { PhoneNumberInput } from "@find-truck-service/ui/src/components/react/PhoneNumberInput";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { selectStates } from "../../../redux/reducers/essentialLists";
import {
  otherProfileSetFieldValues,
  otherProfileSetInvalidFormFields,
  otherProfileUpdateRequest,
  otherProfileSetUpdateLoading,
} from "../../../redux/actions/v2/otherProfile";

import { toast } from "react-toastify";

import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import {
  selectOtherProfileData,
  selectOtherProfileInvalidFormFields,
  selectOtherProfileUpdateLoading,
} from "../../../redux/reducers/v2/other-profile";
import { selectUserData } from "../../../redux/reducers/v2/auth";
import { otherProfileValidator } from "./validators";
import { generateYearsValues } from "../../../utility/generateYearsValues";

import { ZipCodeInput } from "@find-truck-service/ui/src/components/react/ZipCodeInput";
import { USER_TYPE } from "../../../constants";
import { useHistory } from "react-router-dom";
import { Pages } from "../../../views/constants/pages";
import logger from "../../../utility/logger";

const OtherInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const states = useSelector(selectStates);
  const userData = useSelector(selectUserData);
  const otherProfileData = useSelector(selectOtherProfileData);
  const invalidFormFields = useSelector(selectOtherProfileInvalidFormFields);
  const updateOtherProfileLoading = useSelector(
    selectOtherProfileUpdateLoading,
  );
  let years = generateYearsValues();

  const onChange = (event) => {
    dispatch(
      otherProfileSetFieldValues({
        [event.target.name]: event.target.value,
      }),
    );
  };

  const onUpdateOtherSuccess = () => {
    history.push(Pages.VERIFY_EMAIL);
  };

  const onUpdateOtherFailure = (e) => {
    const message = e.response
      ? e.response.data.message
      : "Error while updating user data";

    toast.error(message);
  };

  const handleUpdateOther = useCallback(async () => {
    try {
      await otherProfileValidator.validate(otherProfileData || {});

      dispatch(
        otherProfileSetInvalidFormFields({
          data: null,
        }),
      );
      dispatch(otherProfileSetUpdateLoading({ data: true }));
      dispatch(
        otherProfileUpdateRequest({
          data: {
            userData: {
              ...otherProfileData,
              onboardingFinished: true,
              id: userData.id,
              userType: USER_TYPE.OTHER,
              userRole: userData?.userRole,
            },
            otherUserData: {
              mc: otherProfileData?.mc,
              dot: otherProfileData?.dot,
              numberOfTrailers: otherProfileData?.numberOfTrailers,
              numberOfTrucks: otherProfileData?.numberOfTrucks,
              userData: userData.id,
            },
          },
          onSuccess: onUpdateOtherSuccess,
          onError: onUpdateOtherFailure,
        }),
      );
    } catch (e) {
      logger.error(e);
      if (e.name === "ValidationError") {
        dispatch(
          otherProfileSetInvalidFormFields({
            data: {
              [e.path]: e.errors[0],
            },
          }),
        );
      }
    }
  });

  return (
    <>
      <FtsContainer>
        <FtsRow mt={{ lg: "4xl", md: "4xl", sm: "4xl", xs: "2xl" }}>
          <FtsColumn
            size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
            offset={{ xs: 0, sm: 0, md: 0, lg: 3 }}
            rowGap={"2xl"}
            columnGap={"2xl"}
          >
            <FtsWrapper
              p={{
                xs: "2xl",
                md: "4xl",
                lg: "4xl",
              }}
              bg={ColorsValue["greyscale-0"]}
              boxShadow="sm"
              borderRadius="2xs"
              mb={"2xl"}
            >
              <FtsColumn
                size={{ xs: 12, md: 12, lg: 12 }}
                rowGap={"2xl"}
                columnGap={"2xl"}
              >
                <Span
                  variant={VariantsValues["text-md"]}
                  color={ColorsValue["greyscale-800"]}
                  weight={WeightSize.bold}
                >
                  Profile Info
                </Span>
              </FtsColumn>

              <FtsRow rowGap={"2xl"} columnGap={"lg"} mt={"4xl"}>
                <FtsColumn size={{ xs: 12, md: 12, lg: 12 }} rowGap={"2xl"}>
                  <Input
                    name={"companyName"}
                    required
                    value={otherProfileData?.companyName}
                    onChange={onChange}
                    label={"Company name"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["companyName"]
                    }
                  />
                </FtsColumn>

                <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                  <Input
                    name={"address"}
                    required
                    value={otherProfileData?.address}
                    onChange={onChange}
                    label={"Street"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["address"]
                    }
                  />
                </FtsColumn>
                <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
                  <Input
                    name={"city"}
                    required
                    value={otherProfileData?.city}
                    onChange={onChange}
                    label={"City"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) && invalidFormFields["city"]
                    }
                  />
                </FtsColumn>
                <FtsColumn size={{ xs: 6, md: 3, lg: 3 }}>
                  <Dropdown
                    required
                    size={DropdownSizesValues.md}
                    variant={DropdownVariantsValues.outlined}
                    name={"state"}
                    label={"State"}
                    options={states}
                    placeholder={""}
                    value={states.find(
                      (e) => e.label === otherProfileData?.state,
                    )}
                    errorMessage={invalidFormFields?.["state"]}
                    onChange={(val) =>
                      onChange({
                        target: { name: "state", value: val?.label || "" },
                      })
                    }
                  />
                </FtsColumn>
                <FtsColumn size={{ xs: 6, md: 3, lg: 3 }}>
                  <ZipCodeInput
                    name={"zip"}
                    required
                    value={otherProfileData?.zip}
                    onChange={onChange}
                    label={"Zip Code"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) && invalidFormFields["zip"]
                    }
                    type={"number"}
                  />
                </FtsColumn>
                <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
                  <PhoneNumberInput
                    name={"phoneNumber"}
                    required
                    value={otherProfileData?.phoneNumber}
                    onChange={onChange}
                    label={"Phone (primary)"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["phoneNumber"]
                    }
                  />
                </FtsColumn>
                <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
                  <Dropdown
                    menuPlacement={"top"}
                    required
                    size={DropdownSizesValues.md}
                    variant={DropdownVariantsValues.outlined}
                    name={"yearsBusiness"}
                    label={"In business since?"}
                    options={years}
                    placeholder={""}
                    value={years.find(
                      (e) =>
                        e.label.toString() === otherProfileData?.yearsBusiness,
                    )}
                    errorMessage={
                      invalidFormFields && invalidFormFields?.["yearsBusiness"]
                    }
                    onChange={(val) =>
                      onChange({
                        target: {
                          name: "yearsBusiness",
                          value: val?.label || "",
                        },
                      })
                    }
                  />
                </FtsColumn>

                <FtsColumn size={{ xs: 12, md: 12, lg: 12 }} rowGap={"2xl"}>
                  <Input
                    name={"referal"}
                    required
                    value={otherProfileData?.referal}
                    onChange={onChange}
                    label={"How did you hear about us?"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["referal"]
                    }
                  />
                </FtsColumn>
                <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
                  <Input
                    name={"numberOfTrucks"}
                    value={otherProfileData?.numberOfTrucks}
                    onChange={onChange}
                    label={"Number of trucks"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["numberOfTrucks"]
                    }
                    type={"number"}
                  />
                </FtsColumn>
                <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
                  <Input
                    name={"numberOfTrailers"}
                    value={otherProfileData?.numberOfTrailers}
                    onChange={onChange}
                    label={"Number of trailers"}
                    variant={InputVariants.outlined}
                    size={InputSizes.md}
                    errorMessage={
                      isObject(invalidFormFields) &&
                      invalidFormFields["numberOfTrailers"]
                    }
                    type={"number"}
                  />
                </FtsColumn>
                <FtsColumn
                  size={{ xs: 12, md: 12, lg: 12 }}
                  display={{
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  }}
                >
                  <Button
                    onClick={handleUpdateOther}
                    size={ButtonSizes.md}
                    color={ButtonColorsValue.accent}
                    type={ButtonTypes.contained}
                    label={"Save"}
                    width={"100%"}
                    mt={"2xl"}
                    loading={updateOtherProfileLoading}
                    disabled={updateOtherProfileLoading}
                  />
                </FtsColumn>
              </FtsRow>
            </FtsWrapper>

            <FtsColumn
              size={{ xs: 12, md: 12, lg: 12 }}
              display={{
                xs: "block",
                sm: "none",
                md: "none",
                lg: "none",
              }}
              p={"2xl"}
              pt={"0"}
            >
              <Button
                onClick={handleUpdateOther}
                size={ButtonSizes.md}
                color={ButtonColorsValue.accent}
                type={ButtonTypes.contained}
                label={"Save"}
                width={"100%"}
                loading={updateOtherProfileLoading}
                disabled={updateOtherProfileLoading}
              />
            </FtsColumn>
          </FtsColumn>
        </FtsRow>
      </FtsContainer>
    </>
  );
};

export default OtherInfo;
