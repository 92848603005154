import * as Yup from "yup";
import { REQUIRED_ERROR_MESSAGE } from "../../../../constants";
import { zipValidate } from "@find-truck-service/types/validators/global";

export const listingMapValidator = Yup.object().shape({
  zip: zipValidate,
  state: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  city: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  address: Yup.string().required(REQUIRED_ERROR_MESSAGE),
});
