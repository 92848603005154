import {
  OTHER_PROFILE_UPDATE_REQUEST,
  OTHER_PROFILE_SET_FIELD_VALUES,
  OTHER_PROFILE_UPDATE_FAILED,
  OTHER_PROFILE_UPDATE_SUCCESS,
  OTHER_PROFILE_SET_IS_LOADING,
  OTHER_PROFILE_SET_INVALID_FORM_FIELDS,
} from "../../../reducers/v2/other-profile";

export const otherProfileSetFieldValues = (payload) => ({
  type: OTHER_PROFILE_SET_FIELD_VALUES,
  payload,
});

export const otherProfileUpdateRequest = (payload) => ({
  type: OTHER_PROFILE_UPDATE_REQUEST,
  payload,
});

export const otherProfileUpdateSuccess = (payload) => ({
  type: OTHER_PROFILE_UPDATE_SUCCESS,
  payload,
});

export const otherProfileUpdateFailed = (payload) => ({
  type: OTHER_PROFILE_UPDATE_FAILED,
  payload,
});

export const otherProfileSetInvalidFormFields = (payload) => ({
  type: OTHER_PROFILE_SET_INVALID_FORM_FIELDS,
  payload,
});

export const otherProfileSetUpdateLoading = (payload) => ({
  type: OTHER_PROFILE_SET_IS_LOADING,
  payload,
});
