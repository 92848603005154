import * as Yup from "yup";

const schema = {
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .required("New password is required")
    .matches(
      /^(?=(?:.*[a-z]))(?=(?:.*[A-Z]))(?=(?:.*[0-9]))(?=(?:.*[!@#$%^&*()_+=\-{}|":;'?/.>,<])).{7,}$/,
      "Password must be 7 or more digits with at least 1 number and 1 special character.",
    ),
  repeatNewPassword: Yup.string().test(function (value) {
    const { newPassword } = this.parent;
    if (!value || !value.length) {
      return this.createError({
        message: "Repeat new password is required",
        path: "repeatNewPassword",
      });
    }
    if (value !== newPassword) {
      return this.createError({
        message: "Passwords must match",
        path: "repeatNewPassword",
      });
    }
    return true;
  }),
};

export const changePasswordSchema = Yup.object().shape(schema);
