import {
  SET_TEMPORARY_LINK_CLEAR_CARDS_ERRORS,
  TEMPORARY_LINK_CREATE_USER_CARD_FAILED,
  TEMPORARY_LINK_CREATE_USER_CARD_REQUEST,
  TEMPORARY_LINK_CREATE_USER_CARD_SUCCESS,
  TEMPORARY_LINK_GET_USER_CARDS_FAILED,
  TEMPORARY_LINK_GET_USER_CARDS_REQUEST,
  TEMPORARY_LINK_GET_USER_CARDS_SUCCESS,
  SET_TEMPORARY_LINK_NEW_CARD_DATA,
  SET_TEMPORARY_LINK_NEW_CARD_DATA_INVALID_FORM_FIELDS,
} from "../../../reducers/v2/temporaryLinkUserCards";

export const temporaryLinkGetUserCardsRequest = (payload) => ({
  type: TEMPORARY_LINK_GET_USER_CARDS_REQUEST,
  payload,
});

export const temporaryLinkGetUserCardsSuccess = (payload) => ({
  type: TEMPORARY_LINK_GET_USER_CARDS_SUCCESS,
  payload,
});

export const temporaryLinkGetUserCardsFailed = (payload) => ({
  type: TEMPORARY_LINK_GET_USER_CARDS_FAILED,
  payload,
});

export const temporaryLinkCreateUserCardRequest = (payload) => ({
  type: TEMPORARY_LINK_CREATE_USER_CARD_REQUEST,
  payload,
});

export const temporaryLinkCreateUserCardSuccess = (payload) => ({
  type: TEMPORARY_LINK_CREATE_USER_CARD_SUCCESS,
  payload,
});

export const temporaryLinkCreateUserCardFailed = (payload) => ({
  type: TEMPORARY_LINK_CREATE_USER_CARD_FAILED,
  payload,
});

//SETTERS

export const setTemporaryLinkNewCardData = (payload) => ({
  type: SET_TEMPORARY_LINK_NEW_CARD_DATA,
  payload,
});

export const setTemporaryLinkNewCardDataInvalidFormFields = (payload) => ({
  type: SET_TEMPORARY_LINK_NEW_CARD_DATA_INVALID_FORM_FIELDS,
  payload,
});

export const setTemporaryLinkClearCardsErrors = (payload) => ({
  type: SET_TEMPORARY_LINK_CLEAR_CARDS_ERRORS,
  payload,
});
