import React from "react";
import "../../../assets/scss/pages/authentication.scss";
import "../../../assets/scss/components/fts/footer.scss";
import Breakpoint from "../../hoc/Breakpoint";
import logo from "../../../assets/img/logo/fts-logo.svg";
import logoMobile from "../../../assets/img/logo/fts-new-logo.svg";
import { NAV_ITEMS, TERMS_NAV_ITEMS } from "../../../utility/constants";
import EventWrapper from "../EventWrapper";

class Footer extends React.Component {
  render() {
    const currentYear = new Date().getFullYear();

    return (
      <div className="fts-footer w-100">
        <div className="container">
          <div className="fts-footer-wrapper">
            <div className="fts-footer-left">
              <Breakpoint md up>
                <img src={logo} alt={"logo"} />
              </Breakpoint>
              <Breakpoint sm down>
                <img src={logoMobile} alt={"logo-mobile"} />
              </Breakpoint>

              <Breakpoint md down>
                <div className="options options-right">
                  <div className="column">
                    <ul>
                      {NAV_ITEMS[0]?.items?.map((item) => (
                        <li className="mb-1 text-white" key={item.title}>
                          <EventWrapper interactionType={item?.id}>
                            <div
                              key={item.title}
                              className="d-flex text-white text-nowrap cursor-pointer"
                              onClick={() => {
                                window.location.href = `${window.location.origin}/${item?.path}`;
                              }}
                            >
                              {item?.title}
                            </div>
                          </EventWrapper>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="column">
                    <ul>
                      {NAV_ITEMS[1]?.items?.map((item) => (
                        <li className="mb-1 text-white" key={item.title}>
                          <EventWrapper interactionType={item?.id}>
                            <div
                              key={item.title}
                              className="d-flex text-white text-nowrap cursor-pointer"
                              onClick={() => {
                                window.location.href = `${window.location.origin}/${item?.path}`;
                              }}
                            >
                              {item?.title}
                            </div>
                          </EventWrapper>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="column">
                    <ul>
                      {NAV_ITEMS[2]?.items?.map((item) => (
                        <li className="mb-1 text-white" key={item.title}>
                          <div
                            key={item.title}
                            className="d-flex text-white text-nowrap cursor-pointer"
                            onClick={() => {
                              window.location.href = `${window.location.origin}/${item?.path}`;
                            }}
                          >
                            {item?.title}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Breakpoint>

              <div className="fts-footer-info">
                <p className="text-white">2020 Calamos Ct</p>
                <p className="text-white">Suite 200</p>
                <p className="text-white">Naperville, IL 60563</p>
                <p className="text-white">(847) 586-9110</p>
              </div>
              <div className="text-white text-nowrap">
                © 2007-{currentYear} Find Truck Service® Inc.
              </div>
              <div className={"terms"}>
                {TERMS_NAV_ITEMS.map((item, index) => (
                  <div key={index} className="d-flex">
                    <EventWrapper interactionType={item?.id}>
                      <div
                        key={item.title}
                        className="d-flex text-white text-nowrap cursor-pointer"
                        onClick={() => {
                          window.location.href = `${window.location.origin}${item?.path}`;
                        }}
                      >
                        {item?.title}
                      </div>
                    </EventWrapper>
                    {index !== TERMS_NAV_ITEMS?.length - 1 && (
                      <p className="d-inline cursor-default">|</p>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <Breakpoint lg up>
              <div className="options options-right">
                <div className="column">
                  <ul>
                    {NAV_ITEMS[0]?.items?.map((item) => (
                      <li className="mb-1 text-white" key={item.title}>
                        <EventWrapper interactionType={item?.id}>
                          <div
                            key={item.title}
                            className="d-flex text-white text-nowrap cursor-pointer"
                            onClick={() => {
                              window.location.href = `${window.location.origin}${item?.path}`;
                            }}
                          >
                            {item?.title}
                          </div>
                        </EventWrapper>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="column">
                  <ul>
                    {NAV_ITEMS[1]?.items?.map((item) => (
                      <li className="mb-1 text-white" key={item.title}>
                        <EventWrapper interactionType={item?.id}>
                          <div
                            key={item.title}
                            className="d-flex text-white text-nowrap cursor-pointer"
                            onClick={() => {
                              window.location.href = `${window.location.origin}${item?.path}`;
                            }}
                          >
                            {item?.title}
                          </div>
                        </EventWrapper>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="column">
                  <ul>
                    {NAV_ITEMS[2]?.items?.map((item) => (
                      <li className="mb-1 text-white" key={item.title}>
                        <EventWrapper interactionType={item?.id}>
                          <div
                            key={item.title}
                            className="d-flex text-white text-nowrap cursor-pointer"
                            onClick={() => {
                              window.location.href = `${window.location.origin}${item?.path}`;
                            }}
                          >
                            {item?.title}
                          </div>
                        </EventWrapper>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Breakpoint>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
