import React, { useState } from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { useDispatch } from "react-redux";
import { sendReactivateAccountEmailRequest } from "../../../redux/actions/v2/auth";
import { toast } from "react-toastify";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";

const UserDeactivated = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onSendReactivateEmailSuccess = () => {
    setIsLoading(false);
    toast.success("Reactivate account email sent successfully.");
  };

  const onSendEmailReactivateEmailFailure = (e) => {
    const message = e.response
      ? e.response.data.message
      : "Error while sending reactivate email";

    setIsLoading(false);
    toast.error(message);
  };

  return (
    <FtsWrapper mt="4xl">
      {isLoading && <FullPageLoader />}
      <Error
        title={"Account Deactivated"}
        subtitle={
          "Your account has been deactivated. Click below to reactivate and continue using Find Truck Service."
        }
        iconName="AlertOctagon"
        containedButton={{
          label: "Reactivate Account",
          color: ButtonColorsValue.accent,
          onClick: () => {
            setIsLoading(true);
            dispatch(
              sendReactivateAccountEmailRequest({
                onSuccess: onSendReactivateEmailSuccess,
                onFailure: onSendEmailReactivateEmailFailure,
              }),
            );
          },
        }}
      />
    </FtsWrapper>
  );
};
export default UserDeactivated;
