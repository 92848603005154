import React from "react";
import { ListingInfoHeader } from "../../components/ListingInfoHeader";
import { TemporaryLinkAmenitiesRow } from "./TemporaryLinkAmenitiesRow";
import { ListingInfoWrapper } from "../../components/ListingInfoWrapper";
import { TemporaryLinkListingImagesRow } from "./TemporaryLinkListingImagesRow";
import { TemporaryLinkListingMapInfoRow } from "./TemporaryLinkListingMapInfoRow";
import { TemporaryLinkListingDetailsRow } from "./TemporaryLinkListingDetailsRow";
import { TemporaryLinkListingContactRow } from "./TemporaryLinkListingContactRow";
import { TemporaryLinkServiceAmenitiesRow } from "./TemporaryLinkServiceAmenitiesRow";
import { TemporaryLinkCategoriesPickerRow } from "./TemporaryLinkCategoriesPickerRow";
import { TemporaryLinkListingInfoRatesRow } from "./TemporaryLinkListingInfoRatesRow";
import { TemporaryLinkListingDescriptionRow } from "./TemporaryLinkListingDescriptionRow";
import { TemporaryLinkListingWorkingHoursRow } from "./TemporaryLinkListingWorkingHoursRow";
import { TemporaryLinkListingInfoInlineError } from "./TemporaryLinkListingInfoInlineError";
import { TemporaryLinkEditListingInfoMdFooter } from "./TemporaryLinkEditListingInfoMdFooter";

const TemporaryLinkEditListingInfoContent = () => {
  return (
    <ListingInfoWrapper mt={{ xs: "0", sm: "4xl", md: "4xl", lg: "4xl" }}>
      <TemporaryLinkListingInfoInlineError />
      <ListingInfoHeader />
      <TemporaryLinkListingDetailsRow />
      <TemporaryLinkListingMapInfoRow />
      <TemporaryLinkListingContactRow />
      <TemporaryLinkListingDescriptionRow />
      <TemporaryLinkListingWorkingHoursRow />
      <TemporaryLinkListingInfoRatesRow />
      <TemporaryLinkCategoriesPickerRow />
      <TemporaryLinkServiceAmenitiesRow />
      <TemporaryLinkAmenitiesRow />
      <TemporaryLinkListingImagesRow />
      <TemporaryLinkEditListingInfoMdFooter />
    </ListingInfoWrapper>
  );
};

export default TemporaryLinkEditListingInfoContent;
