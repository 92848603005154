import {
  OWNER_OPERATOR_PROFILE_SET_FIELD_VALUES,
  OWNER_OPERATOR_PROFILE_UPDATE_REQUEST,
  OWNER_OPERATOR_PROFILE_UPDATE_FAILED,
  OWNER_OPERATOR_PROFILE_UPDATE_SUCCESS,
  OWNER_OPERATOR_PROFILE_SET_INVALID_FORM_FIELDS,
  OWNER_OPERATOR_PROFILE_SET_UPDATE_LOADING,
  OWNER_OPERATOR_PROFILE_SET_FINISH_LATER_LOADING,
  OWNER_OPERATOR_CREATE_TRUCK_REQUEST,
  OWNER_OPERATOR_CREATE_TRUCK_SUCCESS,
  OWNER_OPERATOR_CREATE_TRUCK_FAILED,
} from "../../../reducers/v2/owner-operator-profile";

export const ownerOperatorProfileSetFieldValues = (payload) => ({
  type: OWNER_OPERATOR_PROFILE_SET_FIELD_VALUES,
  payload,
});

export const ownerOperatorProfileUpdateRequest = (payload) => ({
  type: OWNER_OPERATOR_PROFILE_UPDATE_REQUEST,
  payload,
});

export const ownerOperatorProfileUpdateSuccess = (payload) => ({
  type: OWNER_OPERATOR_PROFILE_UPDATE_SUCCESS,
  payload,
});

export const ownerOperatorProfileUpdateFailed = (payload) => ({
  type: OWNER_OPERATOR_PROFILE_UPDATE_FAILED,
  payload,
});

export const ownerOperatorProfileSetInvalidFormFields = (payload) => ({
  type: OWNER_OPERATOR_PROFILE_SET_INVALID_FORM_FIELDS,
  payload,
});

export const ownerOperatorProfileSetUpdateLoading = (payload) => ({
  type: OWNER_OPERATOR_PROFILE_SET_UPDATE_LOADING,
  payload,
});

export const ownerOperatorProfileSetFinishLaterLoading = (payload) => ({
  type: OWNER_OPERATOR_PROFILE_SET_FINISH_LATER_LOADING,
  payload,
});

export const ownerOperatorCreateTruckRequest = (payload) => ({
  type: OWNER_OPERATOR_CREATE_TRUCK_REQUEST,
  payload,
});

export const ownerOperatorCreateTruckSuccess = (payload) => ({
  type: OWNER_OPERATOR_CREATE_TRUCK_SUCCESS,
  payload,
});

export const ownerOperatorCreateTruckFailed = (payload) => ({
  type: OWNER_OPERATOR_CREATE_TRUCK_FAILED,
  payload,
});
