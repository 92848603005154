import { createSelector } from "reselect";

export const TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_REQUEST =
  "TEMPORARY_LINK_PAYMENTS/TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_REQUEST";
export const TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_SUCCESS =
  "TEMPORARY_LINK_PAYMENTS/TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_SUCCESS";
export const TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_FAILED =
  "TEMPORARY_LINK_PAYMENTS/TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_FAILED";

export const TEMPORARY_LINK_PAY_WITH_PAYPAL_REQUEST =
  "TEMPORARY_LINK_PAYMENTS/TEMPORARY_LINK_PAY_WITH_PAYPAL_REQUEST";
export const TEMPORARY_LINK_PAY_WITH_PAYPAL_SUCCESS =
  "TEMPORARY_LINK_PAYMENTS/TEMPORARY_LINK_PAY_WITH_PAYPAL_SUCCESS";
export const TEMPORARY_LINK_PAY_WITH_PAYPAL_FAILED =
  "TEMPORARY_LINK_PAYMENTS/TEMPORARY_LINK_PAY_WITH_PAYPAL_FAILED";

export const TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_REQUEST =
  "TEMPORARY_LINK_PAYMENTS/TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_REQUEST";
export const TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_SUCCESS =
  "TEMPORARY_LINK_PAYMENTS/TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_SUCCESS";
export const TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_FAILED =
  "TEMPORARY_LINK_PAYMENTS/TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_FAILED";

const initialState = {
  isLoading: false,
  error: null,
  inlineError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_REQUEST:
      return {
        ...state,
        error: null,
        inlineError: null,
        isLoading: true,
      };

    case TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_SUCCESS:
      return {
        ...state,
        error: null,
        inlineError: null,
        isLoading: false,
      };

    case TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_FAILED:
      return {
        ...state,
        error: action.payload.data?.message,
        inlineError: action.payload.data?.inlineMessage,
        isLoading: false,
      };
    case TEMPORARY_LINK_PAY_WITH_PAYPAL_REQUEST:
    case TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TEMPORARY_LINK_PAY_WITH_PAYPAL_FAILED:
    case TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_FAILED:
      return {
        ...state,
        error: action.payload.data?.message,
        inlineError: action.payload.data?.inlineMessage,
        isLoading: false,
      };
    case TEMPORARY_LINK_PAY_WITH_PAYPAL_SUCCESS:
    case TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

const selectTemporaryLinkPaymentsState = (state) =>
  state.temporaryLinkPaymentsState;

export const selectTemporaryLinkPaymentsError = createSelector(
  selectTemporaryLinkPaymentsState,
  (temporaryLinkPaymentsState) => {
    return temporaryLinkPaymentsState.error;
  },
);
export const selectTemporaryLinkPaymentsInlineError = createSelector(
  selectTemporaryLinkPaymentsState,
  (temporaryLinkPaymentsState) => {
    return temporaryLinkPaymentsState.inlineError;
  },
);

export const selectTemporaryLinkPaymentsIsLoading = createSelector(
  selectTemporaryLinkPaymentsState,
  (temporaryLinkPaymentsState) => {
    return temporaryLinkPaymentsState.isLoading;
  },
);
