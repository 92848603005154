import React from "react";
import { useSelector } from "react-redux";
import { DescriptionModalContentField } from "../../components/DescriptionModalContentField";
import { setTemporaryLinkEditListingData } from "../../../../../redux/actions/v2/temporaryLinkListing";
import { selectTemporaryLinkListingDescription } from "../../../../../redux/reducers/v2/temporaryListingLink";

export const TemporaryLinkDescriptionModalContent = () => {
  const descriptionStore = useSelector(selectTemporaryLinkListingDescription);

  return (
    <DescriptionModalContentField
      descriptionStore={descriptionStore}
      setListingData={setTemporaryLinkEditListingData}
    />
  );
};
