import React from "react";
import { SearchNoResults } from "./SearchNoResults";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { InputVariants } from "@find-truck-service/types/ui/input";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";

export const ServiceAmenitiesDesktop = (props) => {
  const {
    searchString,
    setSearchString,
    hasServiceAmenities,
    selectedServiceAmenities,
    onChangeServiceAmenities,
    filteredServiceAmenities,
  } = props;

  function handleSearch(event) {
    setSearchString(event.target.value);
  }

  return (
    <FtsColumn
      size={{ xs: 12 }}
      display={{ xs: "none", sm: "block", md: "block", lg: "block" }}
    >
      <Input
        label={""}
        size={"md"}
        icon={"Search"}
        bg={ColorsValue["greyscale-50"]}
        value={searchString}
        placeHolder={"Search"}
        onChange={handleSearch}
        variant={InputVariants.contained}
        name={"serviceAmenitiesSearch"}
        prependIcon={IconNames.Lupe}
      />
      <FtsRow columnGap={"lg"} rowGap={"lg"} mt={"lg"} width={"100%"}>
        {!hasServiceAmenities && (
          <SearchNoResults searchString={searchString} />
        )}
        {hasServiceAmenities &&
          filteredServiceAmenities.map((e) => {
            const checked = !!selectedServiceAmenities.find(
              (f) => f.serviceAmenityId === e.id,
            );
            return (
              <FtsColumn key={e.id} size={{ xs: 12, md: 6, lg: 4 }}>
                <Checkbox
                  onClick={() =>
                    onChangeServiceAmenities(e.id, !checked, e.name)
                  }
                  size={CheckboxSizes.md}
                  checked={checked}
                  label={e.name}
                />
              </FtsColumn>
            );
          })}
      </FtsRow>
    </FtsColumn>
  );
};
