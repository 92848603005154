import React from "react";
import { mapTypes } from "../constants";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import map from "@find-truck-service/ui/src/assets/mapButtons/map.png";
import { FtsImage } from "@find-truck-service/ui/src/FtsImage/FtsImage";
import satellite from "@find-truck-service/ui/src/assets/mapButtons/satellite.png";

export const MapTypeButtons = ({ selectedMapType, setSelectedMapType }) => {
  function toggleMapType() {
    setSelectedMapType((prev) =>
      prev === mapTypes.HYBRID ? mapTypes.ROADMAP : mapTypes.HYBRID,
    );
  }

  return (
    <>
      {selectedMapType === mapTypes.HYBRID && (
        <FtsImage
          zIndex={2}
          left={Spacing.lg}
          bottom={Spacing.lg}
          position={"absolute"}
          src={map?.toString()}
          width={Spacing["2xxl"]}
          onClick={toggleMapType}
          height={Spacing["2xxl"]}
          alt={"Map type buttons"}
        />
      )}
      {selectedMapType === mapTypes.ROADMAP && (
        <FtsImage
          zIndex={2}
          left={Spacing.lg}
          cursor={"pointer"}
          bottom={Spacing.lg}
          position={"absolute"}
          width={Spacing["2xxl"]}
          onClick={toggleMapType}
          height={Spacing["2xxl"]}
          alt={"Map type buttons"}
          src={satellite?.toString()}
        />
      )}
    </>
  );
};
