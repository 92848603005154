import React, { useEffect } from "react";
import { useTable, useExpanded, useRowSelect } from "react-table";

import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import { Button } from "reactstrap";
// import Icon from "../../../components/fts/Icons";

const ReactTable = ({
  columns,
  data,
  renderRowSubComponent,
  expandAll,
  setSelectedProofs,
  allSelected,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { selectedRowIds },
    toggleAllRowsExpanded,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded, // Use the useExpanded plugin hook
    useRowSelect,
  );

  useEffect(() => {
    toggleAllRowsExpanded(expandAll);
  }, [expandAll, toggleAllRowsExpanded]);

  useEffect(() => {
    toggleAllRowsSelected(allSelected);
  }, [allSelected, toggleAllRowsSelected]);

  useEffect(() => {
    let selectedRows = [];
    for (let rowIndex of Object.keys(selectedRowIds)) {
      if (rows[rowIndex]?.original?.status === 3)
        selectedRows.push(rows[rowIndex].original);
    }
    setSelectedProofs(selectedRows);
  }, [rows, selectedRowIds, setSelectedProofs]);

  return (
    <>
      {/*<CssBaseline />*/}
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, i) => (
            <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <TableCell
                  key={i}
                  style={{ minWidth: column.minWidth }}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              // Use a React.Fragment here so the table markup is still valid
              <React.Fragment key={rowProps.key}>
                <TableRow {...rowProps} className="position-relative">
                  {row.cells.map((cell, i) => {
                    return (
                      <TableCell
                        key={i}
                        className={
                          cell.column.id === "expander" ? "expander" : ""
                        }
                        style={{
                          backgroundColor:
                            cell.row.original.status === 1
                              ? "#EEFFE8"
                              : cell.row.original.status === 2
                              ? "#FFEDED"
                              : "#F1FBFF",
                        }}
                        height={64}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {/* We could pass anything into this */}
                {renderRowSubComponent({ row, rowProps, visibleColumns })}
              </React.Fragment>
            );
          })}
        </TableBody>
      </MaUTable>
    </>
  );
};

export default ReactTable;
