import React from "react";
import { ModalHeaderComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalHeaderComponent";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
export const PaymentSuccessfulModalHeader = (props) => {
    const { onClose } = props;
    return (React.createElement(ModalHeaderComponent, { title: "Payment successfully processed!", onClose: onClose, prependIconProps: {
            iconName: IconNames.Check,
            iconWrapperProps: {
                p: "md",
                bg: ColorsValue["green-success-50"],
                borderRadius: "4xxl",
            },
            svgProps: {
                width: SvgIconSizeValues.xl,
                height: SvgIconSizeValues.xl,
            },
            pathProps: { stroke: ColorsValue["green-success-500"] },
        } }));
};
