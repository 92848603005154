import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTemporaryLinkListingPreviewData,
  selectTemporaryLinkListingSelectedPrice,
} from "../../../redux/reducers/v2/temporaryListingLink";

import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";

import { getUpgradeListingInvoiceActionRequest } from "../../../redux/actions/v2/invoices";
import { useHistory } from "react-router-dom";
// import { TemporaryLinkPages } from "../../constants/pages";

import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import {
  SOMETHING_WENT_WRONG,
  UNEXPECTED_ISSUE,
} from "../../../utility/errorMessages";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";

import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";

import { ColorsValue } from "@find-truck-service/types/ui/colors";
// import PaymentOverviewHeader from "../../../components/fts-components-v2/PaymentOverview/components/PaymentOverviewHeader/PaymentOverviewHeader";
import PaymentOverviewMainContent from "../../../components/fts-components-v2/PaymentOverview/components/PaymentOverviewMainContent/PaymentOverviewMainContent";
import {
  selectCreateListingInvoice,
  selectCreateListingInvoiceError,
  selectCreateListingIsLoading,
} from "../../../redux/reducers/v2/invoices";
import { selectPaymentsIsLoading } from "../../../redux/reducers/v2/payments";
import { TemporaryLinkPaymentOverviewFooter } from "./components/TemporaryLinkPaymentOverviewFooter/TemporaryLinkPaymentOverviewFooter";
// import { clearAllSearchParams } from "../../../utility/clear-search-params";
import {
  payUpgradeWithExistingCardByTmpTokenRequest,
  payUpgradeWithPaypalRequest,
} from "../../../redux/actions/v2/payments";
import PaymentOverviewHeader from "../../../components/fts-components-v2/TemporaryListingLinkPaymentOverview/components/PaymentOverviewHeader/PaymentOverviewHeader";
import {
  setTemporaryLinkActiveBanner,
  setTemporaryLinkSelectedListingPrice,
  // temporaryLinkGetListingRequest,
  temporaryLinkGetListingSuccess,
} from "../../../redux/actions/v2/temporaryLinkListing";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { CenteredLayout } from "../../../components/fts-components-v2/Layouts/CenteredLayout";
import { clearAllSearchParams } from "../../../utility/clear-search-params";
import { extractParameter } from "../../../utility/param-extract";
import { TemporaryLinkPages } from "../../constants/pages";
import {
  bannerTitles,
  bannerTypes,
  bannerTypesIds,
} from "../../../constants/temporaryLinkBanners";

const TemporaryListingLinkUpgradePaymentOverviewPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const listingData = useSelector(selectTemporaryLinkListingPreviewData);
  const invoiceData = useSelector(selectCreateListingInvoice);
  const isLoading = useSelector(selectCreateListingIsLoading);
  const isPaymentLoading = useSelector(selectPaymentsIsLoading);
  const error = useSelector(selectCreateListingInvoiceError);
  const selectedPrice = useSelector(selectTemporaryLinkListingSelectedPrice);

  useEffect(() => {
    const hash = extractParameter("hash");
    const gateway = extractParameter("gateway");

    if (!selectedPrice?.id && !hash && !gateway) {
      history.push(TemporaryLinkPages.LISTING_PREVIEW);
    }

    if (listingData && selectedPrice?.id) {
      dispatch(
        getUpgradeListingInvoiceActionRequest({
          data: {
            adId: listingData.id,
            listingRenewalTimestamp: listingData.renewalTimestamp,
            userId: listingData.userId,
            newPriceId: selectedPrice.id,
            oldPriceId: listingData.priceId,
            listing: {
              id: listingData.id,
              name: listingData.name,
              city: listingData.city,
              state: listingData.state,
              address: listingData.address,
              priceId: listingData.priceId,
              phoneNumber: listingData.phoneNumber,
              createdAt: listingData.createdAt,
              renewalTimestamp: listingData.renewalTimestamp,
              email: listingData.email,
              price: listingData.price,
              userId: listingData.userId,
            },
          },
        }),
      );
    }
  }, [listingData?.id, selectedPrice?.id]);

  const handlePayWithPaypal = () => {
    clearAllSearchParams(history);
    const redirectUri = new URL(window.location.href);
    // redirectUri.searchParams.append("temporaryToken", temporaryToken);

    const payload = {
      data: {
        paymentData: {
          redirectUri: redirectUri.toString(),
          invoice: invoiceData,

          newPriceId: selectedPrice.id,
          oldPriceId: listingData.priceId,
        },
      },
    };

    dispatch(payUpgradeWithPaypalRequest(payload));
  };

  const handlePayWithExistingCard = useCallback(
    async (selectedCardId) => {
      dispatch(
        payUpgradeWithExistingCardByTmpTokenRequest({
          data: {
            adId: invoiceData.adId,
            userId: invoiceData.userId,
            dueAt: invoiceData.dueAt,
            invoiceAmount: invoiceData.invoiceAmount,
            total: invoiceData.total,
            status: invoiceData.status,
            type: invoiceData.type,
            creditUsed: invoiceData.creditUsed,
            cardId: selectedCardId,
            newPriceId: selectedPrice.id,
          },
          onSuccess: () => {
            dispatch(setTemporaryLinkSelectedListingPrice());
            dispatch(
              setTemporaryLinkActiveBanner({
                data: {
                  type: bannerTypes[bannerTypesIds.LISTING_LIVE_WAITING_PROOF],
                  title:
                    bannerTitles[bannerTypesIds.LISTING_LIVE_WAITING_PROOF],
                },
              }),
            );
            dispatch(
              temporaryLinkGetListingSuccess({
                data: {
                  listing: {
                    ...listingData,
                    priceId: selectedPrice.id,
                    priceData: selectedPrice,
                    price: selectedPrice,
                  },
                },
              }),
            );
            history.push(TemporaryLinkPages.LISTING_PREVIEW);
          },
        }),
      );
    },
    [invoiceData],
  );

  if (!listingData) return <FullPageLoader />;

  if (isLoading || isPaymentLoading) return <FullPageLoader />;
  if (error) {
    return (
      <FtsWrapper mt="4xl">
        <Error
          title={SOMETHING_WENT_WRONG}
          subtitle={error?.inlineMessage || UNEXPECTED_ISSUE}
          iconName="AlertTriangle"
          containedButton={{
            label: "Refresh",
            color: ButtonColorsValue.accent,
            onClick: () => {
              history.go(0);
            },
          }}
        />
      </FtsWrapper>
    );
  }

  return (
    <FtsContainer>
      <CenteredLayout>
        <FtsRow
          px={{ xs: "xl", sm: "4xl", md: "4xl", lg: "4xl" }}
          py={{ xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" }}
          bg={ColorsValue["greyscale-0"]}
          borderRadius="2xs"
          boxShadow="sm"
          size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
        >
          <PaymentOverviewHeader onBackClick={() => history.goBack()} />
          <PaymentOverviewMainContent invoice={invoiceData} />
          <TemporaryLinkPaymentOverviewFooter
            handlePayWithPaypal={handlePayWithPaypal}
            handlePayWithExistingCard={handlePayWithExistingCard}
          />
        </FtsRow>
      </CenteredLayout>
    </FtsContainer>
  );
};

export default TemporaryListingLinkUpgradePaymentOverviewPage;
