import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { formatFileSize } from "../helpers";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

const FileItem = ({
  fileData,
  listingData,
  uploadAdsAssetsRequest,
  setListingData,
}) => {
  const { fileName, fileSize, errorMessage, error, inProgress, file } =
    fileData;
  const borderColor = error ? "red-brand-200" : "greyscale-100";
  const dispatch = useDispatch();
  const showLoader = inProgress && !error;
  const showError = !!errorMessage && error;
  const size = formatFileSize(fileSize);
  const showSize = !!size && !error;

  function onRemoveImage() {
    const filteredAssets = listingData.adsAssets.filter(
      (asset) => asset.fileName !== fileName,
    );
    dispatch(setListingData({ data: { adsAssets: filteredAssets } }));
  }

  function onRetry() {
    dispatch(uploadAdsAssetsRequest({ data: [file] }));
  }

  return (
    <FileItemWrapper borderColor={borderColor}>
      <FtsWrapper>
        {error && (
          <FtsIcons
            iconName={IconNames.AlertTriangle}
            svgProps={{ width: 40, height: 40 }}
            pathProps={{ stroke: ColorsValue["red-brand-500"] }}
          />
        )}
        {!error && (
          <FtsIcons
            iconName={IconNames.PhotoIcon}
            svgProps={{ width: 40, height: 40 }}
          />
        )}
      </FtsWrapper>
      <FtsWrapper display={"flex"} direction={"column"} gap={"2xs"} flex={1}>
        {!!fileName && (
          <Span
            overflowWrap={"anywhere"}
            weight={WeightSize.medium}
            color={ColorsValue["greyscale-800"]}
            variant={VariantsValues["text-2xs"]}
            align={"left"}
          >
            {fileName}
          </Span>
        )}
        {showError && (
          <Span
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["red-brand-500"]}
            align={"left"}
          >
            {errorMessage}
          </Span>
        )}
        {showSize && (
          <Span
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["greyscale-500"]}
            align={"left"}
          >
            {size}
          </Span>
        )}
      </FtsWrapper>
      <FtsWrapper display={"flex"}>
        {error && (
          <Button
            onClick={onRetry}
            color={ButtonColorsValue.neutral}
            size={ButtonSizes.sm}
            type={ButtonTypes.text}
            iconName={IconNames.Refresh}
          />
        )}
        {showLoader && (
          <Button
            loading
            size={ButtonSizes.sm}
            type={ButtonTypes.text}
            iconName={IconNames.Loader}
            color={ButtonColorsValue.neutral}
          />
        )}
        <Button
          size={ButtonSizes.sm}
          onClick={onRemoveImage}
          type={ButtonTypes.text}
          iconName={IconNames.Close}
          color={ButtonColorsValue.neutral}
        />
      </FtsWrapper>
    </FileItemWrapper>
  );
};

export default FileItem;

const FileItemWrapper = styled(FtsWrapper).attrs({
  display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
  borderRadius: "2xs",
  align: "center",
  gap: "lg",
  px: "2xl",
  py: "lg",
})`
  ${({ borderColor }) =>
    `border: ${Spacing["4xs"]} solid ${ColorsValue[borderColor]};`}
`;
