import { Button, Card } from "reactstrap";
import React from "react";
import Icon from "../Icons";
import "../../../assets/scss/components/link-successfull-card.scss";

const LinkSuccessfullCard = ({ heading, message, description, buttonText, action }) => {
  return (
    <div className="fts-link-successfull">
      <Card className="fts-link-successfull-container">
        <span className="fts-link-successful-svg-wrapper">
          <Icon name="LinkSuccessfull" size="88" />
        </span>
        <span className="fts-link-successfull-heading">{heading}</span>
        <span className="fts-link-successfull-info">{message}</span>
        <span className="fts-link-successfull-info">{description}</span>
        <Button className="fts-link-successfull-button" onClick={action} color="primary">
          {buttonText}
        </Button>
      </Card>
    </div>
  );
};

export default LinkSuccessfullCard;
