import React from "react";
import styled from "styled-components";
import { Span } from "@find-truck-service/ui/src/FtsText";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { VariantsValues } from "@find-truck-service/types/ui/text";

export const SearchNoResults = ({ searchString }) => {
  return (
    <FtsColumn size={{ xs: 12 }} display={"flex"} align={"center"}>
      <ErrorInfoIconWrapper>
        <FtsIcons iconName={IconNames.AlertOctagon} />
      </ErrorInfoIconWrapper>
      <Span
        variant={VariantsValues["text-2xs"]}
        color={ColorsValue["red-error-500"]}
      >
        {`No results for “${searchString?.trim()}”`}
      </Span>
    </FtsColumn>
  );
};

export const ErrorInfoIconWrapper = styled(FtsWrapper).attrs({
  bg: ColorsValue["red-error-500"],
  borderRadius: "2xl",
  display: "flex",
  align: "center",
  mr: "sm",
  p: "3xs",
})``;
