export const Pages = {
  DASHBOARD: "/dashboard",
  LOGIN: "/dashboard/login",
  REGISTER: "/dashboard/register",
  FORGOT_PASSWORD: "/dashboard/forgot-password",
  COMPLETE_ACCOUNT_INFO: "/dashboard/complete-account-info",
  CHOOSE_ACCOUNT_TYPE: "/dashboard/choose-account-type",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  RESET_PASSWORD: "/dashboard/reset-password",
  REACTIVATE_ACCOUNT: "/dashboard/reactivate-account",
  FINISH_REGISTER: "/dashboard/finish-register",
  USER_DISABLED: "/dashboard/user-disabled",
  USER_DEACTIVATED: "/dashboard/user-deactivated",
  VERIFY_EMAIL: "/dashboard/verify-email",
  ON_BOARDING: "/dashboard/on-boarding",
};
//
export const TemporaryLinkPages = {
  LISTING_PREVIEW: "/dashboard/listing-temporary/preview",
  DRAFT_LISTING_PREVIEW: "/dashboard/listing-temporary/draft-preview",
  FREE_LISTING_PREVIEW: "/dashboard/listing-temporary/preview-free",
  PAYMENT: "/dashboard/listing-temporary/payment",
  EDIT_LISTING: "/dashboard/listing-temporary/edit-listing",
  EDIT_LISTING_IMAGES: "/dashboard/listing-temporary/edit-listing-images",
  LISTING_DISABLED: "/dashboard/listing-disabled",
  LISTING_NOT_FOUND: "/dashboard/listing-not-found",
  VERIFICATION_REQUIRED: "/dashboard/verification-required",
  PROOF_PREVIEW: "/dashboard/proof-preview",
  LISTING_RENEWAL: "/dashboard/listing-temporary/renewal",
  DRAFT_PROOF_PREVIEW: "/dashboard/listing-temporary/draft-proof-preview",
  UPGRADE_LISTING_PAYMENT:
    "/dashboard/listing-temporary/upgrade-listing-payment",
  LISTING_RELIST: "/dashboard/listing-temporary/relist",
};

// TODO: REMOVE selectedPriceId WHEN LOGIC IS IMPROVED (CURRENTLY IT'S OPIONAL -> ? )
export const TemporaryLinkProxies = {
  TEMPORARY_LINKS_PROXY_URL:
    "/dashboard/redirect/:temporaryToken/:selectedListingPriceId?",
};
