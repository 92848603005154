/* eslint-disable indent */
const initialState = {
  promotionsCount: 0,
  notesCount: 0,
  filesCount: 0,
  todosCount: 0,
  selectedListings: [],
  updateListingTypePayload: null,
};

const ListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_SELECTED_LISTINGS":
      return {
        ...state,
        selectedListings: action.data,
      };
    case "SET_UPDATE_LISTING_TYPE_PAYLOAD":
      return {
        ...state,
        updateListingTypePayload: action.data,
      };

    case "GET_LISTING_COUNTS": {
      let counter = 0;
      let todoCounts = Object.values(action.data?.listing?.todoCounts || {});
      if (todoCounts.length) {
        counter = todoCounts.reduce((a, b) => a + b);
      }

      return {
        ...state,
        promotionsCount: action.data.listing.promotionsCount,
        notesCount: action.data.listing.notesCount,
        filesCount: action.data.listing.filesCount,
        todosCount: counter,
      };
    }

    default:
      return state;
  }
};

export default ListingReducer;
