import React from "react";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
const HeaderButtonsNonLoggedUser = ({ openSideOut }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { label: "Login", size: ButtonSizes.md, type: ButtonTypes.outlined, color: ButtonColorsValue.onBrand, display: { xs: "none", sm: "none", md: "flex", lg: "flex" }, onClick: () => (window.location.href = "/dashboard/login") }),
        React.createElement(Button, { label: "Get Listed", size: ButtonSizes.md, type: ButtonTypes.contained, color: ButtonColorsValue.onBrand, display: { xs: "none", sm: "none", md: "flex", lg: "flex" }, onClick: () => (window.location.href = "/vendor?scrollTo=fts-create-listing") }),
        React.createElement(Button, { onClick: openSideOut, size: ButtonSizes.md, type: ButtonTypes.text, iconName: IconNames.Menu, color: ButtonColorsValue.onBrand, display: { xs: "flex", sm: "flex", md: "flex", lg: "none" } })));
};
export default HeaderButtonsNonLoggedUser;
