import React, { useCallback, useState, useRef, useMemo } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import RevenueChart from "../revenue-chart/RevenueChart";
import InfoCard from "../info-card";
import Icon from "../Icons";
import classNames from "classnames";
import "./revenue.scss";
import SkeletonEmptyState from "../skeleton-empty-state";
import EventWrapper from "../EventWrapper";
import { EVENT_MEASUREMENTS } from "../../../constants/analytics";

const RevenueCalculator = ({
  calls,
  isTemporaryLink,
  categories = [],
  data,
  showChart = true,
  buttonAction,
  reportFrom,
  reportTo,
}) => {
  const refChart = useRef(null);
  const [infoCards, setInfoCards] = useState([
    {
      label: "Towing and Recovery",
      borderColor: "#E7C5A9",
      showCard: true,
    },
    {
      label: "Mobile Repair",
      borderColor: "#EB9797",
      showCard: true,
    },
    {
      label: "Repair Shops",
      borderColor: "#BDA3DF",
      showCard: true,
    },
  ]);

  const revenue = useMemo(() => {
    return {
      "Mobile Repair": categories[0]?.ticket || 0,
      "Towing and Recovery": categories[1]?.ticket || 0,
      "Repair Shops": categories[2]?.ticket || 0,
    };
  }, [categories]);

  const [value, setValue] = useState("");
  const [estimatedRevenue, setEstimatedRevenue] = useState(0);

  const generateTooltipText = () => {
    return `Revenue is calculated based on the ${calls} calls you received through FTS and the industry ticket average.`;
  };
  const getSuffixMoney = useCallback((number) => {
    const suffixes = {
      1e12: "T",
      1e9: "B",
      1e6: "M",
      1e3: "K",
    };
    let money = `$${number}`;
    if (number >= 1e3) {
      const suffix = Object.keys(suffixes)
        .sort((a, b) => b - a)
        .find((e) => number >= e);
      let result = (number / suffix).toFixed(1).replace(/\.0/g, "");
      money = `$${result}${suffixes[suffix]}+`;
    }
    return money;
  }, []);
  const getAmount = useCallback(
    (type) => {
      let n = revenue[type] * calls;
      return getSuffixMoney(n);
    },
    [calls, revenue, getSuffixMoney],
  );

  const formatMoney = (number, decimals = 2) => {
    return number
      .toFixed(decimals)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleCalculate = () => {
    setEstimatedRevenue(value * calls);

    if (refChart.current && infoCards.length < 4) {
      let newInfoCards = [...infoCards];
      newInfoCards.unshift({
        label: "My Estimated Revenue",
        borderColor: "#00B5F4",
        showCard: false,
      });
      setInfoCards(newInfoCards);
      refChart.current.show(0);
    }
  };

  return (
    <div className="revenue-calculator">
      <div className={"wrapper"}>
        <div className={"heading"}>
          <h2 className="reports-title-row">
            See your advertising ROI and compare to industry averages.
          </h2>
        </div>

        <div className={"calculatorWrapper "}>
          <div
            className={"calculator"}
            style={{
              borderBottomLeftRadius: !estimatedRevenue && 4,
              borderBottomRightRadius: !estimatedRevenue && 4,
            }}
          >
            <div className={"headings"}>
              <h3>Enter your average repair ticket</h3>
              <p>
                The revenue estimate is calculated based on your number of
                calls.
              </p>
            </div>
            <div className={"form"}>
              <div className="dollar-wrapper">
                {value && <div className="dollar">$</div>}
                <input
                  className={"revenueInputWrapper"}
                  placeholder={"Enter your average ticket (e.g. $750)"}
                  type="number"
                  pattern="[0-9]*"
                  // onFocus={handleOnFocus}
                  value={value}
                  onKeyDown={(e) => {
                    const charCode = e.which ? e.which : e.keyCode;
                    if (
                      charCode === 69 ||
                      charCode === 45 ||
                      charCode === 189 ||
                      charCode === 43 ||
                      charCode === 187 ||
                      charCode === 46 ||
                      charCode === 190
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  // onKeyDown={onKeyDown}
                  style={{ paddingLeft: value && 27 }}
                />
              </div>

              <EventWrapper
                interactionType={EVENT_MEASUREMENTS.CALCULATE_REVENUE}
              >
                <Button
                  onClick={() => handleCalculate()}
                  // color={"danger"}
                >
                  Calculate
                </Button>
              </EventWrapper>
            </div>

            {estimatedRevenue ? <span className={"arrow"}> </span> : null}
          </div>

          {estimatedRevenue ? (
            <div className={"estimatedRevenue "}>
              <>
                <Icon
                  name="ReportInfo"
                  size={24}
                  id="estimatedRevenueTooltip"
                  className="mr-1"
                />
                <UncontrolledTooltip target="estimatedRevenueTooltip">
                  {generateTooltipText(null)}
                </UncontrolledTooltip>
              </>
              <h2>
                Your estimated revenue is{" "}
                <span>${formatMoney(estimatedRevenue, 0)}</span>
              </h2>
            </div>
          ) : null}
        </div>
        <div className={"industryAverage"}>
          <div className="revenue-calculator-wrapper">
            <div
              className={classNames({
                "fts-report-preview-page-chart-title": isTemporaryLink,
              })}
            >
              <h2 className="reports-title-row">
                Annual industry average revenue per service
              </h2>
            </div>

            <div className={"revenueChartContent"}>
              <div className={"infoCards"}>
                {infoCards.map((infoCard, i) => {
                  return (
                    infoCard.showCard && (
                      <InfoCard
                        key={i}
                        label={infoCard?.label}
                        amount={getAmount(infoCard?.label)}
                        borderColor={infoCard?.borderColor}
                        tooltipText={generateTooltipText(infoCard?.label)}
                        fullWidth
                        showBorder
                      />
                    )
                  );
                })}
              </div>

              <div className="w-100">
                {data && showChart ? (
                  <RevenueChart
                    data={data}
                    revenue={revenue}
                    myTicket={estimatedRevenue && value}
                    infoCards={infoCards}
                    reportFrom={reportFrom}
                    reportTo={reportTo}
                    refChart={refChart}
                  />
                ) : (
                  <SkeletonEmptyState
                    title="To see the results in a graph, click below."
                    iconSize={40}
                    iconName="Diagram"
                    buttonTitle="Show Graph"
                    buttonColor="primary"
                    inProgress={showChart && !data}
                    buttonAction={buttonAction}
                    additionalClasses="revenue-chart-calculator"
                  />
                )}
              </div>

              {/* {isMobile && (
                <div>
                  <span className="font-futura-light flex justify-center pt-8">Scroll to view all data</span>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueCalculator;
