import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "../../../assets/scss/pages/account-access-request.scss";
import Icon from "../../../components/fts/Icons";
import RequestedAccessEmptyState from "../../../components/fts/requested-access-empty-state";
import FTSCookies, {
  removeAllCookies,
  TEMPORARY_TOKEN,
} from "../../../cookies";
import {
  getAccountAccessRequest,
  updateAccountAccessRequest,
} from "../../../redux/actions/accountAccessRequests";
import { accountAccessRequestsStatuses } from "../../../utility/constants";
import { Spinner } from "reactstrap";
import moment from "moment";
import { activities } from "../../../constants";

import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import collectEssentialData from "../../../axios/collect-data";
import { createActivityVendor } from "../../../redux/actions/activities";
import { calculateTimeDifference } from "../../../utility/calculateTimeDifference";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import logger from "../../../utility/logger";

const AccountAccessRequest = ({
  match,
  getAccountAccessRequest,
  updateAccountAccessRequest,
  createActivityVendor,
  userData,
  activitiesTypes,
}) => {
  const dispatch = useDispatch();

  const [accountAccess, setAccountAccess] = useState(null);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const hash = match.params.hash;

    const getUserData = async () => {
      await removeAllCookies();
      await FTSCookies.set(TEMPORARY_TOKEN, hash, { path: "/" });

      const { data } = await FTSAxiosV2.get("/users/me");

      let { data: loggedInUser } = data;
      await collectEssentialData(dispatch);

      dispatch({
        type: "LOGIN_WITH_JWT",
        payload: {
          loggedInUser: {
            ...loggedInUser,
          },
          loggedInWith: "jwt",
        },
      });
    };

    async function getAccessRequest() {
      await removeAllCookies();
      await FTSCookies.set(TEMPORARY_TOKEN, hash, { path: "/" });

      getAccountAccessRequest(hash)
        .then(({ data }) => {
          setAccountAccess(data?.accessEmail?.accountAccessRequest);
          setLoading(false);

          logger.info(
            moment() >
              moment(data?.accessEmail?.accountAccessRequest.expiresAt),
          );
        })
        .catch((err) => logger.error(err));
    }

    setLoading(true);
    getUserData();
    getAccessRequest();
  }, [dispatch, getAccountAccessRequest, match.params.hash]);

  const handleAcceptAccessRequest = () => {
    if (acceptLoading || rejectLoading) return;

    setAcceptLoading(true);

    updateAccountAccessRequest({
      id: accountAccess?.id,
      status: accountAccessRequestsStatuses.granted,
      expiresAt: accountAccess?.expiresAt,
    })
      .then(() => {
        setAccountAccess({
          ...accountAccess,
          status: accountAccessRequestsStatuses.granted,
        });
        handleAccessApprovedActivity();
      })
      .catch((err) => logger.error(err));
  };

  const handleRejectAccessRequest = () => {
    if (acceptLoading || rejectLoading) return;

    setRejectLoading(true);

    updateAccountAccessRequest({
      id: accountAccess?.id,
      status: accountAccessRequestsStatuses.rejected,
    })
      .then(() => {
        setAccountAccess({
          ...accountAccess,
          status: accountAccessRequestsStatuses.rejected,
        });
        handleAccessRejectedActivity();
      })
      .catch((err) => logger.error(err));
  };

  const handleAccessApprovedActivity = async () => {
    let activityPayload = {
      status: 1,
      userId: userData?.id,
      activityType: activitiesTypes.APPROVED_REMOTE_ACCOUNT_ACCESS_REQUEST,
      activity: activities.approveAccountAccess
        .replace(
          "{{user_name}}",
          `${userData?.firstName} ${userData?.lastName}`,
        )
        .replace("{{user_id}}", userData.id),
      iconName: "AccountsActivity",
    };

    return await createActivityVendor(activityPayload);
  };

  const handleAccessRejectedActivity = async () => {
    let activityPayload = {
      status: 1,
      userId: userData?.id,
      activityType: activitiesTypes.REJECTED_REMOTE_ACCOUNT_ACCESS_REQUEST,
      activity: activities.rejectedAccountAccess
        .replace(
          "{{user_name}}",
          `${userData?.firstName} ${userData?.lastName}`,
        )
        .replace("{{user_id}}", userData?.id),
      iconName: "AccountsActivity",
    };

    return await createActivityVendor(activityPayload);
  };

  const handleEmptyState = () => {
    if (loading) {
      return (
        <div className="FTS-Account-Requested-Empty-State-Wrapper">
          <Spinner size={"md"} />
        </div>
      );
    }
    if (accountAccess?.status === accountAccessRequestsStatuses.rejected) {
      return (
        <div className="FTS-Account-Requested-Empty-State-Wrapper">
          <RequestedAccessEmptyState
            iconName={"AccessRejected"}
            iconSize={88}
            header="Access request rejected"
            message="Find Truck Service will be notified that you rejected their request to access your account."
          />
        </div>
      );
    }

    if (accountAccess?.status === accountAccessRequestsStatuses.granted) {
      return (
        <div className="FTS-Account-Requested-Empty-State-Wrapper">
          <RequestedAccessEmptyState
            iconName={"AccessGranted"}
            iconSize={88}
            header="Access granted"
            message="You can disable remote access at any time by logging into your dashboard. "
          />
        </div>
      );
    }

    if (moment() > moment(accountAccess?.expiresAt)) {
      return (
        <div className="FTS-Account-Requested-Empty-State-Wrapper">
          <RequestedAccessEmptyState
            iconName={"AccessRequestExpired"}
            iconSize={88}
            header="Remote account access request has expired."
            message="Request has expired. Ask Find Truck Service to send a new request."
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className="d-flex flex-column position-relative w-100 bg-theme"
      style={{ height: "100vh" }}
    >
      <div style={{ flex: "1 1 0%" }}>
        <HeaderWrapper />
        {accountAccess?.status === accountAccessRequestsStatuses.pending &&
          moment() < moment(accountAccess?.expiresAt) && (
            <div className="FTS-Account-Requested-Wrapper">
              <h3>Remote Account Access Requested</h3>
              <div className="FTS-Account-Requested">
                <div className="FTS-Account-Requested-left">
                  <span className="FTS-Account-Requested-Icon-wrapper">
                    <Icon name="AccessRequested" size={40} />
                  </span>

                  <div className="FTS-Account-Requested-message">
                    <span className="FTS-Account-Requested-message-heading">
                      Find Truck Service
                    </span>
                    <span className="FTS-Account-Requested-message-info">
                      {`${accountAccess?.adminUser?.firstName} ${accountAccess?.adminUser?.lastName}`}{" "}
                      from Find Truck Service Requested access to your account
                      for{" "}
                      {calculateTimeDifference({
                        nextDate: accountAccess?.expiresAt,
                      })}
                      .
                    </span>
                  </div>
                </div>

                <div className="FTS-Account-Requested-message-heading-mobile">
                  {`${accountAccess?.adminUser?.firstName} ${accountAccess?.adminUser?.lastName}`}{" "}
                  from Find Truck Service Requested access to your account for{" "}
                  {calculateTimeDifference({
                    nextDate: accountAccess?.expiresAt,
                  })}
                  .
                </div>

                <div className="FTS-Account-Requested-right">
                  <span
                    className="FTS-Account-Requested-right-action FTS-Account-Requested-right-action-approve"
                    onClick={() => {
                      handleAcceptAccessRequest();
                    }}
                  >
                    {!acceptLoading ? (
                      <Icon name="Checked" size={17} />
                    ) : (
                      <Spinner size={"sm"} />
                    )}
                    <span className="FTS-Account-Requested-right-action-text">
                      Approve
                    </span>
                  </span>
                  <span
                    className="FTS-Account-Requested-right-action"
                    onClick={() => {
                      handleRejectAccessRequest();
                    }}
                  >
                    {!rejectLoading ? (
                      <Icon name="Close" size={24} color={"#fff"} />
                    ) : (
                      <Spinner size={"sm"} />
                    )}
                    <span className="FTS-Account-Requested-right-action-text">
                      Reject
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}

        {handleEmptyState()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.authState.user,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

export default connect(mapStateToProps, {
  getAccountAccessRequest,
  updateAccountAccessRequest,
  createActivityVendor,
})(AccountAccessRequest);
