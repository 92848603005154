import React from "react";
import { Col, Row, TabContent } from "reactstrap";
import "../../../../assets/scss/pages/authentication.scss";
import FinishRegisterForm from "./FinishRegisterForm";
import FTSCookies, {
  removeAllCookies,
  TEMPORARY_TOKEN,
} from "../../../../cookies";
import { getSubUser } from "../../../../redux/actions/listings";
import { connect } from "react-redux";
import Icon from "../../../../components/fts/Icons";
import { HeaderWrapper } from "../../../../components/fts-components-v2/HeaderWrapper";
import logger from "../../../../utility/logger";

class FinishRegister extends React.Component {
  state = {
    activeTab: "1",
    inProgress: false,
    subUserData: null,
    isDriverInvitation: false,
    invitedUserEmail: null,
  };

  componentDidMount() {
    if (
      window.location.pathname.startsWith("/dashboard/finish-driver-register/")
    ) {
      this.setState({
        isDriverInvitation: true,
      });
    }

    (async () => {
      try {
        await removeAllCookies();
        const hash = this.props.match.params.hash;
        await FTSCookies.set(TEMPORARY_TOKEN, hash, { path: "/" });

        this.setState({
          inProgress: true,
        });

        const { data } = await this.props.getSubUser();

        this.setState({
          subUserData: data.user,
          inProgress: false,
          invitedUserEmail: data.email,
        });
      } catch (err) {
        logger.error(err);
      } finally {
        this.setState({
          inProgress: false,
        });
      }
    })();
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  render() {
    const { inProgress, isDriverInvitation, invitedUserEmail } = this.state;

    return (
      <div className="h-100">
        <HeaderWrapper />
        <div className={"content-footer-wrapper"}>
          <Row className="m-0 justify-content-center">
            <Col
              sm="8"
              xl="6"
              lg="6"
              md="8"
              className="d-flex justify-content-center"
            >
              <Row className="m-0">
                <div
                  className="rounded-0 mb-0 px-4 mb-4 mt-2 register-tabs-container"
                  style={{ maxWidth: "500px" }}
                >
                  <h4 className="mb-1">Complete Registration</h4>

                  <p className="auth-title">
                    Create your own password to complete your registration.
                  </p>
                  <TabContent activeTab={this.state.activeTab}>
                    {invitedUserEmail && (
                      <div className="fts-registered-email">
                        <span>This is the email you are registered with:</span>
                        <div className="fts-registered-email-box">
                          <Icon name="Email" size={26} />
                          <span>{invitedUserEmail}</span>
                        </div>
                      </div>
                    )}
                    {!inProgress ? (
                      <FinishRegisterForm
                        subUserData={this.state.subUserData}
                        isDriverInvitation={isDriverInvitation}
                      />
                    ) : (
                      "Loading..."
                    )}
                  </TabContent>
                </div>
              </Row>
            </Col>
          </Row>
          <div className={"footer-grey"}></div>
        </div>
      </div>
    );
  }
}
export default connect(null, { getSubUser })(FinishRegister);
