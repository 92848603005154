import React, { useEffect, useRef } from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ReactComponent as ListingPreviewDemo } from "../../../images/svg/listing-preview-demo.svg";
import PaymentOverviewItem from "../PaymentOverviewItem/PaymentOverviewItem";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import { useSelector } from "react-redux";
import { selectPaymentsInlineError } from "../../../../../redux/reducers/v2/payments";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";

const PaymentOverviewMainContent = ({ invoice }) => {
  const inlineMessage = useSelector(selectPaymentsInlineError);
  const messageElementRef = useRef(null);

  useEffect(() => {
    if (messageElementRef.current) {
      messageElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [inlineMessage, messageElementRef]);
  return (
    <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
      {inlineMessage && (
        <FtsWrapper
          display={{ xs: "block", sm: "none", md: "none", lg: "none" }}
          mb={"lg"}
          ref={messageElementRef}
        >
          <Message type={"error"} title={""} subtitle={inlineMessage} />
        </FtsWrapper>
      )}
      <FtsWrapper
        border={`1px solid ${ColorsValue["greyscale-100"]}`}
        borderRadius={"2xs"}
      >
        <FtsWrapper display="flex" gap={"lg"} px={"2xl"} pt={"xl"} pb={"0"}>
          <ListingPreviewDemo />

          <FtsWrapper display="flex" direction="column">
            <Span
              variant={VariantsValues["text-xs"]}
              color={ColorsValue["greyscale-800"]}
            >
              {invoice?.listing?.name}
            </Span>
            <Span
              variant={VariantsValues["text-2xs"]}
              color={ColorsValue["greyscale-500"]}
            >
              Account #{invoice?.listing?.userId}
            </Span>
          </FtsWrapper>
        </FtsWrapper>

        <FtsWrapper py={"lg"} px={"3xl"}>
          <hr />
          <FtsWrapper display="flex" direction="column" gap="md">
            <PaymentOverviewItem
              label={"Listing type"}
              value={invoice?.listing?.price.label}
            />
            <PaymentOverviewItem
              label={"Location"}
              value={`${invoice?.listing?.city}, ${invoice?.listing?.state}`}
            />
          </FtsWrapper>
        </FtsWrapper>

        <FtsWrapper bg={ColorsValue["greyscale-50"]} py={"lg"} px={"3xl"}>
          <FtsWrapper display="flex" direction="column" gap="md">
            <PaymentOverviewItem
              label={"Subtotal"}
              value={`$${invoice?.invoiceAmount?.toFixed(2)}`}
            />

            {invoice?.creditUsed ? (
              <PaymentOverviewItem
                label={"Listing credit"}
                value={`- $${invoice?.creditUsed?.toFixed(2)}`}
              />
            ) : (
              <EmptyComponent />
            )}
            {/* <PaymentOverviewItem label={"Corporate (10%)"} value="-$49.80" />
            <PaymentOverviewItem label={"Account credit"} value="-$150.00" /> */}
          </FtsWrapper>
          <hr />

          <FtsWrapper display="flex" width="100%" justify="space-between">
            <Span
              variant={VariantsValues["text-xs"]}
              color={ColorsValue["greyscale-800"]}
            >
              Total
            </Span>
            <Span
              variant={VariantsValues["text-xs"]}
              color={ColorsValue["greyscale-800"]}
              weight={WeightSize.medium}
            >
              ${invoice?.total?.toFixed(2)}
            </Span>
          </FtsWrapper>
        </FtsWrapper>
      </FtsWrapper>
    </FtsColumn>
  );
};

export default PaymentOverviewMainContent;
