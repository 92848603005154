import {
  CLEAR_ERRORS,
  CREATE_USER_CARD_BY_TMP_TOKEN_FAILED,
  CREATE_USER_CARD_BY_TMP_TOKEN_REQUEST,
  CREATE_USER_CARD_BY_TMP_TOKEN_SUCCESS,
  CREATE_USER_CARD_FAILED,
  CREATE_USER_CARD_REQUEST,
  CREATE_USER_CARD_SUCCESS,
  GET_USER_CARDS_BY_TMP_TOKEN_FAILED,
  GET_USER_CARDS_BY_TMP_TOKEN_REQUEST,
  GET_USER_CARDS_BY_TMP_TOKEN_SUCCESS,
  GET_USER_CARDS_FAILED,
  GET_USER_CARDS_REQUEST,
  GET_USER_CARDS_SUCCESS,
  SET_NEW_CARD_DATA,
  SET_NEW_CARD_DATA_INVALID_FORM_FIELDS,
} from "../../../reducers/v2/cards";

export const getUserCardsRequest = (payload) => ({
  type: GET_USER_CARDS_REQUEST,
  payload,
});

export const getUserCardsSuccess = (payload) => ({
  type: GET_USER_CARDS_SUCCESS,
  payload,
});

export const getUserCardsFailed = (payload) => ({
  type: GET_USER_CARDS_FAILED,
  payload,
});

export const createUserCardRequest = (payload) => ({
  type: CREATE_USER_CARD_REQUEST,
  payload,
});

export const createUserCardSuccess = (payload) => ({
  type: CREATE_USER_CARD_SUCCESS,
  payload,
});

export const createUserCardFailed = (payload) => ({
  type: CREATE_USER_CARD_FAILED,
  payload,
});

export const getUserCardsByTmpTokenRequest = (payload) => ({
  type: GET_USER_CARDS_BY_TMP_TOKEN_REQUEST,
  payload,
});

export const getUserCardsByTmpTokenSuccess = (payload) => ({
  type: GET_USER_CARDS_BY_TMP_TOKEN_SUCCESS,
  payload,
});

export const getUserCardsByTmpTokenFailed = (payload) => ({
  type: GET_USER_CARDS_BY_TMP_TOKEN_FAILED,
  payload,
});

export const createUserCardByTmpTokenRequest = (payload) => ({
  type: CREATE_USER_CARD_BY_TMP_TOKEN_REQUEST,
  payload,
});

export const createUserCardByTmpTokenSuccess = (payload) => ({
  type: CREATE_USER_CARD_BY_TMP_TOKEN_SUCCESS,
  payload,
});

export const createUserCardByTmpTokenFailed = (payload) => ({
  type: CREATE_USER_CARD_BY_TMP_TOKEN_FAILED,
  payload,
});

export const setNewCardData = (payload) => ({
  type: SET_NEW_CARD_DATA,
  payload,
});

export const setNewCardDataInvalidFormFields = (payload) => ({
  type: SET_NEW_CARD_DATA_INVALID_FORM_FIELDS,
  payload,
});

export const clearErrors = (payload) => ({
  type: CLEAR_ERRORS,
  payload,
});
