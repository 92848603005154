import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import FTSModal from "../fts-modal";
import ListingItem from "../listing-item";
import "../../../assets/scss/components/free-listing-preview-modal.scss";
import TitleRow from "../TitleRow";
import { Col, Spinner } from "reactstrap";
import CloseButton from "../CloseButton";
import {
  getListingTags,
  getLoggedUserTags,
  storeAnalytic,
} from "../../../utility/analytic.helpers";
import { EVENT_MEASUREMENTS } from "../../../constants/analytics";
import { getListingData } from "../../../redux/actions/listings";
import { toast } from "react-toastify";
import logger from "../../../utility/logger";

const ListingPreviewModal = ({
  handleClose,
  listingData,
  userData,
  listingId,
  getListingData,
}) => {
  const [listing, setListing] = useState(listingData);
  const [getListingDataInProgress, setGetListingDataInProgress] =
    useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!listing) {
          setGetListingDataInProgress(true);
          const { data } = await getListingData(listingId);
          setListing(data.listing);
          setGetListingDataInProgress(false);
        }
      } catch (e) {
        logger.error(e);
        handleClose();
        toast.error("Oops! Something went wrong. Please try again.");
      }

      if (listing) {
        const listingTags = getListingTags(listing);
        const loggedInUserTags = getLoggedUserTags(userData);

        storeAnalytic(EVENT_MEASUREMENTS.LISTINGS_PREVIEW_LISTING, {
          ...listingTags,
          ...loggedInUserTags,
        });
      }
    })();
  }, [userData, listing, handleClose, getListingData, listingId]);

  return (
    <div>
      <FTSModal
        isOpen
        centered
        title="Listing Preview:"
        titleDescription="free"
        modalClasses="fts-modal fts-modal__free-listing-preview"
        headerClasses="modal-header"
        closeModal={handleClose}
        headerCTA={() => {
          return (
            <TitleRow title="Listing Preview">
              <Col className="text-right">
                <CloseButton onClick={handleClose} />
              </Col>
            </TitleRow>
          );
        }}
      >
        {getListingDataInProgress ? (
          <div
            className={"w-100 d-flex justify-content-center align-items-center"}
          >
            <Spinner size={"lg"} />
          </div>
        ) : listing ? (
          <ListingItem data={listing} />
        ) : null}
      </FTSModal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.authState.user,
  };
};

export default connect(mapStateToProps, { getListingData })(
  ListingPreviewModal,
);
