/* eslint-disable indent */
import * as Yup from "yup";
import { urlValidate } from "../utility/url-validator";
import moment from "moment/moment";

const fleetCarrierSchema = {
  companyName: Yup.string()
    .trim()
    .required("Company name is required")
    .nullable(),
  address: Yup.string().trim().required("Street is required").nullable(),
  city: Yup.string().trim().required("City is required").nullable(),
  state: Yup.lazy((val) =>
    typeof val === "object" && val !== null
      ? Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required("Select state")
      : Yup.string().nullable().required("Select state"),
  ),
  zip: Yup.number()
    .required("Zip code is required")
    .typeError("Invalid zip code")
    .nullable(),
  phoneNumber: Yup.string()
    .trim()
    .required("Phone (primary) is required")
    .test("len", "Invalid phone number", (val) => {
      if (val) {
        return val.replace(/[_() -]/g, "").length === 10;
      }
    })
    .nullable(),
  website: urlValidate.nullable(),
  referal: Yup.string().trim().required("Referal is required").nullable(),
  numberOfTrucks: Yup.number()
    .nullable()
    .required("Number of trucks is required"),
  numberOfTrailers: Yup.number()
    .nullable()
    .required("Number of trailers is required"),
  dotNumber: Yup.number().nullable(),
  mcNumber: Yup.number().nullable(),
  yearsBusiness: Yup.number()
    .required("In business since is required")
    .min(1950, "Invalid year")
    .max(moment().year(), "Invalid year"),
};

const fleetCarrierSubAccountSchema = {
  companyName: Yup.string()
    .trim()
    .required("Company name is required")
    .nullable(),
  address: Yup.string().trim().required("Address is required").nullable(),
  city: Yup.string().trim().required("City is required").nullable(),
  state: Yup.lazy((val) =>
    typeof val === "object" && val !== null
      ? Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required("Select state")
      : Yup.string().nullable().required("Select state"),
  ),
  zip: Yup.number()
    .required("Zip code is required")
    .typeError("Invalid zip code")
    .nullable(),
  phoneNumber: Yup.string().required("Phone number is required").nullable(),
  website: urlValidate.nullable(),
  referal: Yup.string().trim().nullable(),
};

const ownerOperatorSchema = {
  companyName: Yup.string()
    .trim()
    .required("Company name is required")
    .nullable(),
  firstName: Yup.string().trim().required("First name is required"),
  lastName: Yup.string().trim().required("Last name is required"),
  password: Yup.string().trim(),
  address: Yup.string().trim().required("Street is required").nullable(),
  city: Yup.string().trim().required("City is required").nullable(),
  state: Yup.lazy((val) =>
    typeof val === "object" && val !== null
      ? Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required("Select state")
      : Yup.string().nullable().required("Select state"),
  ),
  zip: Yup.number()
    .required("Zip code is required")
    .typeError("Invalid zip code")
    .nullable(),
  phoneNumber: Yup.string()
    .trim()
    .required("Phone (primary) is required")
    .test("len", "Invalid phone number", (val) => {
      if (val) {
        return val.replace(/[_() -]/g, "").length === 10;
      }
    })
    .nullable(),
  website: urlValidate.nullable(),
  referal: Yup.string().trim().nullable(),
  numberOfTrucks: Yup.number().nullable(),
  numberOfTrailers: Yup.number().nullable(),
  dotNumber: Yup.number().nullable(),
  mcNumber: Yup.number().nullable(),

  truckYear: Yup.number()
    .required("Year is required")
    .min(1900, "Invalid year")
    .max(moment().year(), "Invalid year"),
  truckMake: Yup.string().required("Make is required"),
  truckModel: Yup.string().required("Model is required"),
  truckNumber: Yup.string().required("Truck # is required"),
  truckVin: Yup.string().required("VIN is required"),
  truckPlates: Yup.string(),
  truckPlatesExpiration: Yup.string().nullable(),
  truckType: Yup.string().required("Truck type is required"),
  driverId: Yup.number(),
  status: Yup.number().required("Truck status is required"),
};

const companyDriverSchema = {
  firstName: Yup.string().trim().required("First name is required"),
  lastName: Yup.string().trim().required("Last name is required"),
  password: Yup.string().trim(),
  address: Yup.string().trim().required("Street is required").nullable(),
  city: Yup.string().trim().required("City is required").nullable(),
  state: Yup.lazy((val) =>
    typeof val === "object" && val !== null
      ? Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required("Select state")
      : Yup.string().nullable().required("Select state"),
  ),
  zip: Yup.number()
    .required("Zip code is required")
    .typeError("Invalid zip code")
    .nullable(),
  phoneNumber: Yup.string()
    .trim()
    .required("Phone (primary) is required")
    .test("len", "Invalid phone number", (val) => {
      if (val) {
        return val.replace(/[_() -]/g, "").length === 10;
      }
    })
    .nullable(),
  website: urlValidate.nullable(),
  referal: Yup.string().trim().required("Referal is required").nullable(),
};

const otherProfileSchema = {
  companyName: Yup.string()
    .trim()
    .required("Company name is required")
    .nullable(),
  address: Yup.string().trim().required("Street is required").nullable(),
  city: Yup.string().trim().required("City is required").nullable(),
  state: Yup.lazy((val) =>
    typeof val === "object" && val !== null
      ? Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required("Select state")
      : Yup.string().nullable().required("Select state"),
  ),
  zip: Yup.number()
    .required("Zip code is required")
    .typeError("Invalid zip code")
    .nullable(),
  phoneNumber: Yup.string()
    .trim()
    .required("Phone (primary) is required")
    .test("len", "Invalid phone number", (val) => {
      if (val) {
        return val.replace(/[_() -]/g, "").length === 10;
      }
    })
    .nullable(),
  website: urlValidate.nullable(),
  referal: Yup.string().trim().required("Referal is required").nullable(),
  numberOfTrucks: Yup.number().nullable(),
  numberOfTrailers: Yup.number().nullable(),
  dotNumber: Yup.number().nullable(),
  mcNumber: Yup.number().nullable(),
  yearsBusiness: Yup.number()
    .required("In business since is required")
    .min(1950, "Invalid year")
    .max(moment().year(), "Invalid year"),
};

const serviceVendorSchema = {
  companyName: Yup.string().trim().required("This field is required"),
  firstName: Yup.string().trim(),
  lastName: Yup.string().trim(),
  password: Yup.string().trim(),
  address: Yup.string().trim().required("This field is required"),
  city: Yup.string().trim().required("This field is required"),
  //   state: Yup.lazy((val) =>
  //     typeof val === "object" && val !== null
  //       ? Yup.object()
  //           .shape({
  //             label: Yup.string(),
  //             value: Yup.string(),
  //           })
  //           .nullable()
  //           .required("Select state")
  //       : Yup.string().nullable().required("Select state")
  //   ),
  zip: Yup.number()
    .required("This field is required")
    .typeError("Invalid zip code"),
  website: urlValidate,
  jobTitle: Yup.string().trim().required("This field is required"),
  bussinessPhone: Yup.string().required("This field is required"),
  referal: Yup.string().trim().nullable(),
};

export const createFleetCarrierSchema = Yup.object().shape(fleetCarrierSchema);
export const createFleetCarrierSubAccountSchema = Yup.object().shape(
  fleetCarrierSubAccountSchema,
);

export const createOwnerOperatorSchema =
  Yup.object().shape(ownerOperatorSchema);

export const createCompanyDriverSchema =
  Yup.object().shape(companyDriverSchema);

export const createOtherProfileSchema = Yup.object().shape(otherProfileSchema);

export const createServiceVendorSchema =
  Yup.object().shape(serviceVendorSchema);
