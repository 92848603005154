import * as Sentry from "@sentry/react";

function setSentryContextProperties(scope, context) {
  if (context.tags) {
    scope.setTags(context.tags);
  }

  if (context.extras) {
    scope.setExtras(context.extras);
  }

  if (context.level) {
    scope.setLevel(context.level);
  }
}
export function captureRequest(message, data) {
  Sentry.captureMessage(message, {
    extra: {
      data,
    },
  });
}

export const captureExceptionWithSentry = (err, context = {}) =>
  Sentry.withScope((scope) => {
    context.extras = {
      ...(err?.config?.params ?? {}),
      ...(context?.extras ?? {}),
    };

    setSentryContextProperties(scope, context);

    if (err?.response) {
      captureRequest("Error making API request", {
        data: err.response?.config?.data,
        headers: err.response?.config?.headers,
        url: err.response?.config?.url,
      });

      captureRequest("Failed API response", {
        status: err.response?.status,
        url: err.response?.config?.url,
        method: err.response?.config?.method,
      });
    }
    Sentry.captureException(err);
  });

export const captureDebugMessage = (message, context = {}) => {
  Sentry.withScope((scope) => {
    context.level = "debug";

    setSentryContextProperties(scope, context);

    Sentry.captureMessage(message);
  });
};

export const catchHandle = (err) => {
  if (err?.response) {
    const { data, config, status, statusText } = err.response;

    const tags = {
      url: config?.url,
      status,
      statusText: statusText || `${status}`,
      method: config?.method,
    };

    let extras = {
      stacktrace: data?.stacktrace,
    };

    captureExceptionWithSentry(err, {
      tags,
      extras,
    });
  }
};
