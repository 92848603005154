import React from "react";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { InvoiceSkeletonHeader } from "./InvoiceSkeletonHeader";
import { InvoiceSkeletonBody } from "./InvoiceSkeletonBody";
import LoadingSkeleton from "react-loading-skeleton";
export const InvoiceSkeletonContent = () => {
    return (React.createElement(FtsColumn, { size: { xs: 12, sm: 12, md: 12, lg: 12 } },
        React.createElement(FtsWrapper, { py: "lg", px: "2xl", gap: "lg", align: "center", display: "flex", borderRadius: "2xs", justify: "space-between", border: `${Spacing["4xs"]} solid ${ColorsValue["greyscale-50"]}`, width: "100%" },
            React.createElement(FtsWrapper, { display: "flex", direction: "column", width: "100%", gap: "lg" },
                React.createElement(InvoiceSkeletonHeader, null),
                React.createElement(InvoiceSkeletonBody, null),
                React.createElement(LoadingSkeleton, { duration: 1, width: "100%", height: 16 })))));
};
