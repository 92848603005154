import React from "react";

import "../../assets/scss/components/fts/checkbox.scss";
import Breakpoint from "../hoc/Breakpoint";
import Icon from "./Icons";

class FTSCheckbox extends React.Component {
  render() {
    let { checked, defaultChecked } = this.props;
    checked = checked ?? defaultChecked;

    return (
      <div
        className={`fts-checkbox-con ${
          this.props.className ? this.props.className : ""
        } fts-checkbox-${this.props.color}`}
        title={this.props.label}
      >
        <input
          id={this.props.id}
          name={this.props.name}
          type="checkbox"
          checked={checked}
          value={this.props.value}
          disabled={this.props.disabled}
          onClick={this.props.onClick ? this.props.onClick : null}
          onChange={this.props.onChange ? this.props.onChange : () => {}}
        />

        <Breakpoint md up>
          <span
            className={`fts-checkbox fts-checkbox-${
              this.props.size ? this.props.size : "md"
            }`}
          >
            <span className="fts-checkbox--check">
              {this.props.icon ?? (
                <Icon className="fts-icon" name="CheckActive" size={24} />
              )}
            </span>
          </span>
        </Breakpoint>

        <Breakpoint sm down>
          <span className={`fts-checkbox fts-checkbox-sm`}>
            <span className="fts-checkbox--check">
              {this.props.icon ?? (
                <Icon className="fts-icon" name="ResponsiveCheck" size={30} />
              )}
            </span>
          </span>
        </Breakpoint>
        <div className="fts-checkbox-label">{this.props.label}</div>
      </div>
    );
  }
}

export default FTSCheckbox;
