import React, { useCallback } from "react";
import { Pages } from "../../../constants/pages";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { ButtonsFooter } from "@find-truck-service/ui/src/components/react/ButtonsFooter";
import { useHistory } from "react-router-dom";

export const RenewalsListingRenewedFooterButtons = () => {
  const history = useHistory();

  const onClickSearch = useCallback(() => {
    window.location.href = "/";
  }, []);

  const onClickDashboard = useCallback(() => {
    history.push(Pages.DASHBOARD);
  }, []);

  return (
    <>
      <ButtonsFooter
        outlinedButtonProps={{
          label: "Search",
          color: ButtonColorsValue.accent,
          onClick: onClickSearch,
        }}
        containedButtonProps={{
          label: "Dashboard",
          color: ButtonColorsValue.accent,
          onClick: onClickDashboard,
        }}
      />
    </>
  );
};
