import React, { PureComponent } from "react";
import { Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import CloseButton from "../CloseButton";
import TitleRow from "../TitleRow";

class FTSModal extends PureComponent {
  render() {
    const {
      isOpen,
      children,
      closeModal,
      footerCTA,
      title,
      onClose,
      headerCTA,
      centered = false,
      size,
      style,
      headerClasses = "modal-header",
      modalClasses = "fts-modal",
    } = this.props;

    return (
      <Modal
        centered={centered}
        className={modalClasses}
        isOpen={isOpen}
        toggle={closeModal}
        onClosed={onClose}
        style={style}
        size={size}
      >
        {!!title && (
          <ModalHeader toggle={closeModal} className={headerClasses}>
            {headerCTA ? (
              headerCTA()
            ) : (
              <TitleRow title={title}>
                <Col className="text-right">
                  <CloseButton onClick={closeModal} />
                </Col>
              </TitleRow>
            )}
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
        {!!footerCTA && <ModalFooter>{footerCTA()}</ModalFooter>}
      </Modal>
    );
  }
}

export default FTSModal;
