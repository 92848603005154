import { call, takeLatest, put, all, take } from "redux-saga/effects";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";
import { setLoggedUserCookie } from "../../../cookies";
import { setUserData } from "../../actions/v2/auth";
import {
  COLLECT_ESSENTIALS_SUCCESS,
  collectEssentialsRequest,
} from "../../actions/v2/essentials";
import {
  updatePasswordFailed,
  updatePasswordSuccess,
} from "../../actions/v2/updatePassword";
import {
  COMPLETE_REGISTRATION_GET_INVITATION_REQUEST,
  COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST,
} from "../../reducers/v2/completeRegistration";
import {
  completeRegistrationGetInvitationRequestSuccess,
  completeRegistrationGetInvitationRequestFailed,
} from "../../actions/v2/completeRegistration";
import logger from "../../../utility/logger";

function* getInvitationSaga() {
  try {
    const { data: responseData } = yield call(
      FTSAxiosTemporaryLink.get,
      "/users/subuser-invitation",
    );

    yield put(
      completeRegistrationGetInvitationRequestSuccess({
        data: responseData?.data?.email,
      }),
    );
  } catch (e) {
    logger.error(e);
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(
      completeRegistrationGetInvitationRequestFailed({
        data: { message, inlineMessage },
      }),
    );
  }
}

function* completeRegistrationSaga(action) {
  try {
    const { data, onSuccess } = action.payload;
    const response = yield call(
      FTSAxiosTemporaryLink.put,
      "/auth/complete-registration",
      {
        password: data.password,
        recaptcha: data.recaptcha,
        onboardingFinished: true,
      },
    );
    const { user, bearerToken } = response.data.data;
    setLoggedUserCookie(user, bearerToken);

    yield all([
      put(setUserData({ data: user })),
      put(collectEssentialsRequest()),
      take(COLLECT_ESSENTIALS_SUCCESS),
      put(updatePasswordSuccess()),
    ]);

    onSuccess();
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(updatePasswordFailed({ data: { message, inlineMessage } }));
    action.payload.onError(e);
  }
}

function* completeRegistrationWatcher() {
  yield takeLatest(
    COMPLETE_REGISTRATION_GET_INVITATION_REQUEST,
    getInvitationSaga,
  );
  yield takeLatest(
    COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST,
    completeRegistrationSaga,
  );
}

export default completeRegistrationWatcher;
