import {
  SET_LISTING_DATA,
  UPDATE_LISTING_REQUEST,
  CREATE_LISTING_REQUEST,
  SET_ADS_ASSET_FOR_UPLOAD,
  UPLOAD_ADS_ASSETS_FAILED,
  UPLOAD_ADS_ASSETS_REQUEST,
  UPLOAD_ADS_ASSETS_SUCCESS,
  UPDATE_LISTING_FAILED,
  CREATE_LISTING_FAILED,
  UPDATE_LISTING_SUCCESS,
  CREATE_LISTING_SUCCESS,
  SET_LISTING_DATA_INVALID_FORM_FIELDS,
  GET_LISTING_BY_ID_REQUEST,
  GET_LISTING_BY_ID_SUCCESS,
  GET_LISTING_BY_ID_FAILED,
  GET_DRAFT_LISTING_REQUEST,
  GET_DRAFT_LISTING_SUCCESS,
  GET_DRAFT_LISTING_FAILED,
  CREATE_LISTING_INVOICE_REQUEST,
  CREATE_LISTING_INVOICE_REQUEST_FAILED,
  CREATE_LISTING_INVOICE_REQUEST_SUCCESS,
  SET_INITIAL_LISTING_DATA,
} from "../../../reducers/v2/listing";

export const setListingInvalidFormFields = (payload) => ({
  type: SET_LISTING_DATA_INVALID_FORM_FIELDS,
  payload,
});

export const setListingData = (payload) => ({
  type: SET_LISTING_DATA,
  payload,
});

export const setInitialListingData = () => ({
  type: SET_INITIAL_LISTING_DATA,
});

export const createListingRequest = (payload) => ({
  type: CREATE_LISTING_REQUEST,
  payload,
});

export const createListingRequestSuccess = (payload) => ({
  type: CREATE_LISTING_SUCCESS,
  payload,
});

export const createListingRequestFailed = (payload) => ({
  type: CREATE_LISTING_FAILED,
  payload,
});

export const getDraftListingRequest = (payload) => ({
  type: GET_DRAFT_LISTING_REQUEST,
  payload,
});

export const getDraftListingSuccess = (payload) => ({
  type: GET_DRAFT_LISTING_SUCCESS,
  payload,
});

export const getDraftListingFailed = (payload) => ({
  type: GET_DRAFT_LISTING_FAILED,
  payload,
});

export const updateListingRequest = (payload) => ({
  type: UPDATE_LISTING_REQUEST,
  payload,
});

export const updateListingRequestSuccess = (payload) => ({
  type: UPDATE_LISTING_SUCCESS,
  payload,
});

export const updateListingRequestFailed = (payload) => ({
  type: UPDATE_LISTING_FAILED,
  payload,
});

export const uploadAdsAssetsRequest = (payload) => ({
  type: UPLOAD_ADS_ASSETS_REQUEST,
  payload,
});

export const uploadAdsAssetsRequestSuccess = (payload) => ({
  type: UPLOAD_ADS_ASSETS_SUCCESS,
  payload,
});

export const uploadAdsAssetsRequestFailed = (payload) => ({
  type: UPLOAD_ADS_ASSETS_FAILED,
  payload,
});

export const setAdsAssetForUpload = (payload) => ({
  type: SET_ADS_ASSET_FOR_UPLOAD,
  payload,
});

export const getListingDataByIdRequest = (payload) => ({
  type: GET_LISTING_BY_ID_REQUEST,
  payload,
});

export const getListingDataByIdSuccess = (payload) => ({
  type: GET_LISTING_BY_ID_SUCCESS,
  payload,
});

export const getListingDataByIdFailed = (payload) => ({
  type: GET_LISTING_BY_ID_FAILED,
  payload,
});

export const createListingInvoiceRequest = (payload) => ({
  type: CREATE_LISTING_INVOICE_REQUEST,
  payload,
});

export const createListingInvoiceRequestFailed = (payload) => ({
  type: CREATE_LISTING_INVOICE_REQUEST_FAILED,
  payload,
});

export const createListingInvoiceRequestSuccess = (payload) => ({
  type: CREATE_LISTING_INVOICE_REQUEST_SUCCESS,
  payload,
});
