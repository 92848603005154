import React from "react";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";

export const CenteredLayout = ({ children }) => {
  return (
    <>
      <FtsContainer>
        <FtsRow
          mt={{ xs: "0", sm: "2xl", md: "4xl", lg: "4xl" }}
          pb={{ xs: "lg", sm: "2xl", md: "4xl", lg: "4xl" }}
        >
          <FtsColumn size={{ md: 12, lg: 6 }} offset={{ md: 0, lg: 3 }}>
            {children}
          </FtsColumn>
        </FtsRow>
      </FtsContainer>
    </>
  );
};
