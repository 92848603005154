import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ErrorComponent } from "./Components/ErrorComponent";
import { HeaderWrapper } from "../components/fts-components-v2/HeaderWrapper";
import { selectTemporaryLinkError } from "../redux/reducers/v2/temporaryListingLink";
import { temporaryLinkGetDataRequest } from "../redux/actions/v2/temporaryLinkListing";
import { useTemporaryLinkRedirectHandler } from "./hooks/useTemporaryLinkRedirectHandler";
import { ListingTemporaryLinkRouterWrapper } from "./Components/ListingTemporaryLinkRouterWrapper";
import { useTemporaryLinkPaymentSuccessfulRedirect } from "./hooks/useTemporaryLinkPaymentSuccessfulRedirect";

const ListingTemporaryLinkRouter = (props) => {
  const dispatch = useDispatch();
  const error = useSelector(selectTemporaryLinkError);

  //hooks
  useTemporaryLinkRedirectHandler();
  useTemporaryLinkPaymentSuccessfulRedirect();

  useEffect(() => {
    (() => dispatch(temporaryLinkGetDataRequest()))();
  }, [dispatch]);

  return (
    <ListingTemporaryLinkRouterWrapper>
      <HeaderWrapper />
      {!!error && <ErrorComponent errorMessage={error} />}
      {!error && <Route {...props} />}
    </ListingTemporaryLinkRouterWrapper>
  );
};

export default ListingTemporaryLinkRouter;
