import React from "react";
import { connect } from "react-redux";

import "../../../../assets/scss/pages/authentication.scss";
import { history } from "../../../../history";
import ChooseAccount from "./ChooseAccount";
import CompleteProfile from "./CompleteProfile";
import { toast } from "react-toastify";
import { extractParameter } from "../../../../utility/param-extract";
import { USER_TYPE } from "../../../../constants";

import { reassignListing } from "../../../../redux/actions/users";

class AccountSelection extends React.Component {
  state = {
    chosenAccount: null,
    step: 1,
    subAccount: null,
  };

  componentDidMount = async () => {
    if (this.props.userData.onboardingFinished) {
      history.push("/dashboard");
    }

    if (this.props?.userData?.disableUserTypeChange) {
      this.handleAutomaticAccountSelection();
      return;
    }

    if (this.props.userData?.accountLink) {
      const chosenAccount = this.props.userTypes.find(
        (e) => e.id === this.props.userData.userType,
      )?.id;
      if (!chosenAccount) {
        toast.error("Something went wrong. Please try again.");
        history.push("/dashboard/login");
      }

      this.setState({
        step: 2,
        chosenAccount,
      });
    }
  };

  handleChooseAccount = (chosenAccount) => {
    this.setState({
      chosenAccount,
    });
  };

  handleAutomaticAccountSelection = () => {
    const chosenAccount = this.props?.userTypes.find(
      (e) => e.id === this.props?.userData?.userType,
    )?.id;

    this.setState({
      chosenAccount,
      step: 2,
    });

    return;
  };

  handleStepChange = (step) => {
    this.setState({
      step: step,
    });
  };

  redirectToWebsite = () => {
    const redirect = extractParameter("redirect");
    const openModal = extractParameter("openModal");
    const listingId = extractParameter("listingId");
    const search = window.location.search;

    if (redirect) {
      if (redirect.startsWith("/search")) {
        let searchIndex = search.indexOf("/search");
        let openmodalIndex = search.indexOf("&openModal");
        let substring = search.substring(searchIndex, openmodalIndex);
        return () => {
          history.push({
            pathname: `${substring}&openModal=${openModal}&listingId=${listingId}`,
          });
        };
      } else {
        return () => {
          history.push({
            pathname: redirect,
            search: `openModal=${openModal}&listingId=${listingId}`,
          });
        };
      }
    }
    return;
  };

  reorderArray = (array) => {
    const order = [
      USER_TYPE.FLEET_CARRIER,
      USER_TYPE.SERVICE_VENDOR,
      USER_TYPE.OWNER_OPERATOR,
      USER_TYPE.COMPANY_DRIVER,
      USER_TYPE.OTHER,
    ];
    return array.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
  };

  render() {
    const { chosenAccount, step } = this.state;
    return (
      <>
        {step === 1 && (
          <div className="account-selection-wrapper">
            <ChooseAccount
              accountOptions={this.reorderArray(this.props.userTypes)}
              chosenAccount={chosenAccount}
              handleChooseAccount={this.handleChooseAccount}
              handleStepChange={this.handleStepChange}
            />
          </div>
        )}
        {step === 2 && (
          <CompleteProfile
            chosenAccount={chosenAccount}
            handleStepChange={this.handleStepChange}
            redirectToWebsite={this.redirectToWebsite}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.authState.user,
    userTypes: state.essentialLists.userTypes,
  };
};

export default connect(mapStateToProps, { reassignListing })(AccountSelection);
