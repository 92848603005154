import React, { useCallback, useEffect, useState } from "react";
import { ClientCreditCardForm } from "@find-truck-service/ui/src/components/react/ClientCreditCardForm";
import { useDispatch, useSelector } from "react-redux";
import { historyRefresh } from "../../../../../history";
import {
  setTemporaryLinkClearCardsErrors,
  setTemporaryLinkNewCardData,
  setTemporaryLinkNewCardDataInvalidFormFields,
  temporaryLinkCreateUserCardRequest,
  temporaryLinkGetUserCardsRequest,
} from "../../../../../redux/actions/v2/temporaryLinkCards";

import { CardItem } from "../../../CardItem/CardItem";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import {
  cardHolderValidate,
  cardNumberValidate,
  cvvValidate,
  expDateValidate,
} from "@find-truck-service/types/validators/global";
import * as Yup from "yup";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

import { Spinner } from "reactstrap";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import { selectTemporaryListingLinkInvoices } from "../../../../../redux/reducers/v2/temporaryListingLink";
import {
  initialNewCardData,
  selectIsLoadingTemporaryLinkCardsData,
  selectTemporaryLinkCardError,
  selectTemporaryLinkCardsData,
  selectTemporaryLinkNewCardData,
  selectTemporaryLinkNewCardInvalidFormFields,
} from "../../../../../redux/reducers/v2/temporaryLinkUserCards";
import { extractParameter } from "../../../../../utility/param-extract";
import { temporaryLinkPayWithExistingCardRequest } from "../../../../../redux/actions/v2/temporaryLinkPayments";
import { TemporaryLinkPages } from "../../../../../views/constants/pages";
import logger from "../../../../../utility/logger";

export const accountCardValidator = Yup.object().shape({
  cardNumber: cardNumberValidate,
  cardHolderName: cardHolderValidate,
  cardCvc: cvvValidate,
  cardExpirationDate: expDateValidate,
  isDefault: Yup.bool(),
});

export const ListingTemporaryLinkPaymentMethods = () => {
  const dispatch = useDispatch();
  const listingInvoices = useSelector(selectTemporaryListingLinkInvoices);
  const newCardData = useSelector(selectTemporaryLinkNewCardData);
  const cardsData = useSelector(selectTemporaryLinkCardsData);
  const isLoading = useSelector(selectIsLoadingTemporaryLinkCardsData);
  const newCardInvalidFormFields = useSelector(
    selectTemporaryLinkNewCardInvalidFormFields,
  );
  const error = useSelector(selectTemporaryLinkCardError);
  const token = extractParameter("token");

  const [addNewComponentOpen, setAddNewComponentOpen] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(0);

  useEffect(() => {
    if (!cardsData)
      dispatch(temporaryLinkGetUserCardsRequest({ data: { token } }));
    if (cardsData) {
      if (!cardsData.length) setAddNewComponentOpen(true);
      setSelectedCardId(cardsData.find((card) => card.isDefault)?.id);
    }
  }, [cardsData]);

  const handleOnChange = useCallback(
    (key, value) => {
      dispatch(
        setTemporaryLinkNewCardData({
          data: { ...newCardData, [key]: value },
        }),
      );
    },
    [newCardData],
  );

  const handleCheckboxChange = useCallback(
    (key, value) => {
      dispatch(
        setTemporaryLinkNewCardData({
          data: { ...newCardData, [key]: !value },
        }),
      );
    },
    [newCardData],
  );

  const createUserCard = useCallback(async () => {
    try {
      await accountCardValidator.validate(newCardData, { abortEarly: false });
      dispatch(
        temporaryLinkCreateUserCardRequest({
          data: { cardData: newCardData, token },
          onSuccess: () => setAddNewComponentOpen(false),
        }),
      );
    } catch (e) {
      logger.error(e);
      if (e.name !== "ValidationError") return;
      let errors = {};
      e.inner.map((error) => (errors[error.path] = error.message));
      dispatch(setTemporaryLinkNewCardDataInvalidFormFields({ data: errors }));
    }
  }, [newCardData]);

  const handleOnSuccess = () =>
    historyRefresh.push(TemporaryLinkPages.LISTING_PREVIEW);

  const handlePayWithExistingCard = useCallback(async () => {
    dispatch(
      temporaryLinkPayWithExistingCardRequest({
        data: {
          paymentData: {
            invoicesIds: [listingInvoices?.[0].id],
            cardId: selectedCardId,
          },
          token,
        },
        onSuccess: handleOnSuccess,
      }),
    );
  }, [listingInvoices, selectedCardId]);

  if (isLoading) {
    return (
      <FtsWrapper display="flex" justify="center" align="center">
        <Spinner size="lg" />
      </FtsWrapper>
    );
  }

  const closeCardForm = () => {
    if (cardsData?.length) {
      setAddNewComponentOpen(false);
      dispatch(setTemporaryLinkClearCardsErrors());
    }
  };
  if (addNewComponentOpen) {
    return (
      <ClientCreditCardForm
        closeCardForm={closeCardForm}
        cardData={newCardData}
        onSubmit={createUserCard}
        handleCheckboxChange={handleCheckboxChange}
        handleOnChange={handleOnChange}
        title={"Add Card"}
        submitButtonTitle={"Add Card"}
        invalidFormFields={newCardInvalidFormFields}
        inlineMessage={error?.inlineMessage}
      />
    );
  }
  if (error?.message) {
    return <Message type={"error"} title={""} subtitle={error.message} />;
  }

  return (
    <>
      <FtsWrapper display="flex" direction="column" gap={"lg"}>
        <FtsWrapper display="flex" justify="space-between" align={"center"}>
          <Span
            variant={VariantsValues["text-3xs"]}
            weight={WeightSize.medium}
            color={ColorsValue["greyscale-800"]}
          >
            PAYMENT METHODS
          </Span>
          <Button
            onClick={() => {
              dispatch(
                setTemporaryLinkNewCardData({ data: initialNewCardData }),
              );
              setAddNewComponentOpen(true);
            }}
            size={ButtonSizes.md}
            type={ButtonTypes.text}
            color={ButtonColorsValue.accent}
            label={"Add new card"}
          />
        </FtsWrapper>
        {cardsData?.map((card) => (
          <CardItem
            key={card.id}
            {...card}
            isSelected={selectedCardId === card.id}
            setSelectedCardId={setSelectedCardId}
          />
        ))}
      </FtsWrapper>

      <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }} mt="xl">
        <Button
          width="100%"
          size={ButtonSizes.md}
          color={ButtonColorsValue.accent}
          type={ButtonTypes.contained}
          onClick={handlePayWithExistingCard}
          label={`Pay (${listingInvoices?.[0]?.total?.toFixed(2)})`}
        />
      </FtsColumn>
    </>
  );
};
