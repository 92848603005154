import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { formatFileSize } from "../helpers";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

export const FileItemSm = ({
  fileData,
  listingData,
  setListingData,
  uploadAdsAssetsRequest,
}) => {
  const { errorMessage, inProgress, fileName, fileSize, isFirst, error, file } =
    fileData;
  const dispatch = useDispatch();
  const showLoader = inProgress && !error;
  const showError = !!errorMessage && error;
  const size = formatFileSize(fileSize);
  const showSize = !!size && !error;

  function onRemoveImage() {
    const filteredAssets = listingData.adsAssets.filter(
      (asset) => asset.fileName !== fileName,
    );
    dispatch(setListingData({ data: { adsAssets: filteredAssets } }));
  }

  function onRetry() {
    dispatch(uploadAdsAssetsRequest({ data: [file] }));
  }

  return (
    <>
      {!isFirst && <HorizontalDivider />}
      <FileItemWrapper>
        <FtsWrapper>
          {error && (
            <FtsIcons
              iconName={IconNames.AlertTriangle}
              svgProps={{ width: 40, height: 40 }}
              pathProps={{ stroke: ColorsValue["red-brand-500"] }}
            />
          )}
          {!error && (
            <FtsIcons
              iconName={IconNames.PhotoIcon}
              svgProps={{ width: 40, height: 40 }}
            />
          )}
        </FtsWrapper>
        <FtsWrapper display={"flex"} direction={"column"} gap={"2xs"} flex={1}>
          {!!fileName && (
            <SpanStyled
              color={ColorsValue["greyscale-800"]}
              variant={VariantsValues["text-2xs"]}
              withIcons={showLoader || showError}
              weight={WeightSize.medium}
              align={"left"}
            >
              {fileName}
            </SpanStyled>
          )}
          {showError && (
            <Span
              variant={VariantsValues["text-3xs"]}
              color={ColorsValue["red-brand-500"]}
              align={"left"}
            >
              {errorMessage}
            </Span>
          )}
          {showSize && (
            <Span
              variant={VariantsValues["text-3xs"]}
              color={ColorsValue["greyscale-500"]}
              align={"left"}
            >
              {size}
            </Span>
          )}
        </FtsWrapper>
        <FtsWrapper display={"flex"}>
          {error && (
            <Button
              onClick={onRetry}
              size={ButtonSizes.sm}
              type={ButtonTypes.text}
              color={ButtonColorsValue.neutral}
              iconName={IconNames.Refresh}
            />
          )}
          {showLoader && (
            <Button
              loading
              size={ButtonSizes.sm}
              type={ButtonTypes.text}
              iconName={IconNames.Loader}
              color={ButtonColorsValue.neutral}
            />
          )}
          <Button
            size={ButtonSizes.sm}
            onClick={onRemoveImage}
            type={ButtonTypes.text}
            iconName={IconNames.Close}
            color={ButtonColorsValue.neutral}
          />
        </FtsWrapper>
      </FileItemWrapper>
    </>
  );
};

const FileItemWrapper = styled(FtsWrapper).attrs({
  display: { xs: "flex", sm: "none", md: "none", lg: "none" },
  align: "center",
  gap: "lg",
})``;

const HorizontalDivider = styled(FtsColumn).attrs({
  display: { xs: "block", sm: "none", md: "none", lg: "none" },
  bg: ColorsValue["greyscale-100"],
  size: { xs: 12 },
  height: "1px",
  width: "100%",
  mb: "lg",
})``;

const SpanStyled = styled(Span)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ withIcons }) =>
    `max-width: ${withIcons ? "calc(100vw - 200px)" : "calc(100vw - 164px)"};`}
`;
