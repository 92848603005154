import React, { useCallback } from "react";
import { TemporaryLinkPages } from "../../../constants/pages";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { ButtonsFooter } from "@find-truck-service/ui/src/components/react/ButtonsFooter";
import { useHistory } from "react-router-dom";
import { SESSION_STORAGE_KEYS } from "../../../../utility/constants";

export const RenewalsFooterButtons = () => {
  const history = useHistory();

  const onRenewNow = useCallback(() => {
    history.push(TemporaryLinkPages.PAYMENT);
  }, []);

  const onViewReport = useCallback(() => {
    const hash = sessionStorage.getItem(SESSION_STORAGE_KEYS.TEMPORARY_TOKEN);
    const href = `/dashboard/report/${hash}`;

    window.open(href, "_blank");
  }, []);

  return (
    <>
      <ButtonsFooter
        outlinedButtonProps={{
          label: "View Report",
          color: ButtonColorsValue.accent,
          onClick: onViewReport,
        }}
        containedButtonProps={{
          label: "Renew Now",
          color: ButtonColorsValue.accent,
          onClick: onRenewNow,
        }}
      />
    </>
  );
};
