import React, { createRef, useCallback, useEffect, useState } from "react";
import { isObject } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import {
  setCreateAccountData,
  setCreateAccountEmailAlreadyUsed,
  setCreateAccountInvalidFormFields,
} from "../../../../redux/actions/v2/createAccount";
import { PasswordValidator } from "@find-truck-service/ui/src/components/react/PasswordValidator";
import {
  selectCreateAccountData,
  selectCreateAccountEmailAlreadyUsed,
  selectCreateAccountInvalidFormFields,
} from "../../../../redux/reducers/v2/createAccount";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { Collapsible } from "@find-truck-service/ui/src/components/react/Collapsible";
import { emailError } from "../helpers/getEmailError";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";

export const CreateAccountFormInputFields = () => {
  const dispatch = useDispatch();
  const recaptchaRef = createRef();
  const [showPassword, setShowPassword] = useState(false);
  const [showValidator, setShowValidator] = useState(false);

  const accountData = useSelector(selectCreateAccountData);
  const emailAlreadyUsed = useSelector(selectCreateAccountEmailAlreadyUsed);
  const invalidFormFields = useSelector(selectCreateAccountInvalidFormFields);

  useEffect(() => {
    if (!emailAlreadyUsed) return;
    recaptchaRef.current.reset();
    setStoreData({ recaptcha: "" });
  }, [emailAlreadyUsed]);

  const showPasswordValidator = useCallback(() => {
    setShowValidator(true);
  }, []);

  const showPasswordValidatorMsg =
    showValidator ||
    (isObject(invalidFormFields) && invalidFormFields["password"]);

  const emailErrorMessage = emailError(emailAlreadyUsed, invalidFormFields);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const setStoreData = useCallback(
    (data) => dispatch(setCreateAccountData({ data })),
    [dispatch],
  );

  const onChangeRecaptcha = useCallback((value) => {
    setStoreData({ recaptcha: value });
  }, []);

  const onChange = useCallback(
    (event) => {
      setStoreData({ [event.target.name]: event.target.value });
      if (emailAlreadyUsed)
        dispatch(setCreateAccountEmailAlreadyUsed({ data: false }));
      if (invalidFormFields)
        dispatch(setCreateAccountInvalidFormFields({ data: null }));
    },
    [emailAlreadyUsed, invalidFormFields],
  );

  const handleToggleMarketingOptIn = useCallback((newValue) => {
    setStoreData({ primaryMarketingOptIn: newValue });
  }, []);

  return (
    <FtsColumn size={{ xs: 12 }}>
      <FtsRow columnGap={"lg"} rowGap={"2xl"}>
        <FtsColumn size={{ xs: 12, sm: 6 }}>
          <Input
            required
            name={"firstName"}
            onChange={onChange}
            label={"First name"}
            size={InputSizes.md}
            value={accountData?.firstName}
            variant={InputVariants.outlined}
            errorMessage={
              isObject(invalidFormFields) && invalidFormFields["firstName"]
            }
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12, sm: 6 }}>
          <Input
            required
            name={"lastName"}
            onChange={onChange}
            label={"Last name"}
            size={InputSizes.md}
            value={accountData?.lastName}
            variant={InputVariants.outlined}
            errorMessage={
              isObject(invalidFormFields) && invalidFormFields["lastName"]
            }
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }}>
          <Input
            required
            name={"email"}
            label={"Email"}
            onChange={onChange}
            size={InputSizes.md}
            value={accountData?.email}
            variant={InputVariants.outlined}
            errorMessage={emailErrorMessage}
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }}>
          <FtsWrapper display={"flex"} gap={"lg"} direction={"column"}>
            <Input
              required
              name={"password"}
              label={"Password"}
              onChange={onChange}
              size={InputSizes.md}
              value={accountData?.password}
              onFocus={showPasswordValidator}
              variant={InputVariants.outlined}
              appendIcon={IconNames.EyeIcon}
              onAppendIconClick={toggleShowPassword}
              type={showPassword ? "text" : "password"}
            />
            <Collapsible show={showPasswordValidatorMsg}>
              <PasswordValidator password={accountData?.password || ""} />
            </Collapsible>
          </FtsWrapper>
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }}>
          <Checkbox
            size={CheckboxSizes.md}
            checked={accountData?.primaryMarketingOptIn}
            label={"Receive emails regarding my account"}
            onClick={() =>
              handleToggleMarketingOptIn(!accountData?.primaryMarketingOptIn)
            }
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }} style={{ minHeight: "78px" }}>
          <ReCAPTCHA
            ref={recaptchaRef}
            onChange={onChangeRecaptcha}
            sitekey={process.env.REACT_APP_SITE_KEY}
          />
          {isObject(invalidFormFields) && invalidFormFields["recaptcha"] && (
            <Span variant={"text-3xs"} color={ColorsValue["red-brand-500"]}>
              {invalidFormFields["recaptcha"]}
            </Span>
          )}
        </FtsColumn>
      </FtsRow>
    </FtsColumn>
  );
};
