import React, { useEffect, useRef, useState } from "react";

import { Card, Spinner } from "reactstrap";

import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";
import { connect, useDispatch } from "react-redux";
import {
  getPaymentsByEmailToken,
  setAppliedCredit,
  setAppliedCreditAmount,
  setDiscount,
  setDiscountForSelectedInvoices,
  setIsCreditApplied,
  setSelectedInvoices,
} from "../../../redux/actions/invoices";
import "../../../assets/scss/components/fts/payments-client-page.scss";

import {
  payWithPaypalClient,
  payWithStripeClient,
} from "../../../redux/actions/payments";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import { defineInvoicesColumnsForPaymentLinkCheckBox } from "../invoices-page/invoices-columns";
import { toast } from "react-toastify";
import {
  checkPromoCode,
  setPromoCode,
} from "../../../redux/actions/promocodes";
import { listingTypesArray } from "../../../data/listing-types-options";

import TitleRow from "../../../components/fts/TitleRow";
import FTSCookies, {
  removeAllCookies,
  TEMPORARY_TOKEN,
} from "../../../cookies";
import ClientPaymentMethodModal from "../../../components/fts/client-payment-modal";
import StickyPaymentBox from "../../../components/fts/fts-sticky-payment-box";
import InvoicesTempEmptyState from "../../../components/fts/invoices-temp-empty-state";
import queryString from "query-string";
import appConfig from "../../../configs/appConfig";
import axios from "axios";
import ListingPreviewModal from "../../../components/fts/fts-listing-preview-modal";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import PaymentProccessedModal from "../invoices-page/payment-modals/payment-proccessed";
import moment from "moment";
import { clearAllSearchParams } from "../../../utility/clear-search-params";
import { history } from "../../../history";
import collectEssentialData from "../../../axios/collect-data";
import logger from "../../../utility/logger";

const Payment = (props) => {
  const {
    setSelectedInvoices,
    match,
    // payWithPaypalClient,
    checkPromoCode,
    setPromoCode,
    setDiscountForSelectedInvoices,
    setAppliedCreditAmount,
    setIsCreditApplied,
    discountAmount,
    subTotal,
    promoCode,
    appliedCreditAmount,
    isCreditApplied,
    totalAmount,
    getPaymentsByEmailToken,
    payWithStripeClient,
  } = props;
  const dispatch = useDispatch();
  const gridApi = useRef(null);
  const gridColumnApi = useRef(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const [downgradedListings, setDowngradedListings] = useState(false);
  const [inactiveListings, setInactiveListings] = useState(false);
  const [listingForPreview, setListingForPreview] = useState(false);
  const [isOpenListingPreviewModal, setIsOpenListingPreviewModal] =
    useState(false);

  const [columnDefCheckBox, setColumnDefCheckbox] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [editing] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedInvoices, setSelectedInvoicesState] = useState([]);
  const [selectedInvoicesCount, setSelectedInvoicesCount] = useState(0);
  const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState("");
  const [isOpenPaymentSuccessfullyModal, setIsOpenPaymentSuccessfullyModal] =
    useState(false);
  const [sourceId, setSourceId] = useState("");

  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [state, setState] = useState({
    isOpen: false,
    sidebar: false,
    updating: false,
  });

  const callToAction = (item) => {
    setState({
      action: "invoice_details",
      actionItem: item,
      sidebar: true,
    });
  };

  useEffect(() => {
    if (match.params.token) {
      (async () => {
        try {
          await removeAllCookies();
          setIsLoading(true);
          await FTSCookies.set(TEMPORARY_TOKEN, match.params.token, {
            path: "/",
          });
          await collectEssentialData(dispatch);

          let newData = [];

          const { paymentId, status, hash, gateway } = queryString.parse(
            props.location.search,
          );
          if (status && paymentId && hash) {
            if (status === "success") {
              const url = `${appConfig.apiURL}payment/${gateway}/success`;
              const payload = {
                token: hash,
                paymentId,
              };
              const { data } = await axios.post(url, payload);
              newData = data.invoices;
              props.setDiscount(data.discountAmount || 0);
              props.setAppliedCredit(data.credit || 0);

              const selectedInvoices = data.invoices.map((e) => ({
                ...e,
                isSelected: true,
              }));
              props.setSelectedInvoices({
                selectedInvoices: selectedInvoices,
              });
              setSourceId(paymentId);

              setIsOpenPaymentSuccessfullyModal(true);
            }
            if (status === "error") {
              throw new Error("Oops! Something went wrong. Please try again.");
            }
          } else {
            const { data } = await getPaymentsByEmailToken();
            newData = data.results;

            setDowngradedListings(newData.filter((e) => e.deletedAt));
            setInactiveListings(
              newData.filter((e) => e["listing.status"] === 2),
            );
          }

          let newTotal = 0;

          let selectedInvoices = newData.map((e) => {
            newTotal += e.total;
            return {
              ...e,
              isSelected: true,
            };
          });

          setInvoices(newData);
          setSelectedInvoices({
            selectedInvoices,
          });
          setTotal(newTotal);
          setSelectedInvoicesState(selectedInvoices);
          setSelectedInvoicesCount(selectedInvoices?.length);

          setColumnDefCheckbox(
            defineInvoicesColumnsForPaymentLinkCheckBox(callToAction),
          );
          setIsLoading(false);
        } catch (e) {
          logger.error(e);
          if (e.response.status === 400) {
            setIsLoading(false);
            return setInvoices([]);
          }
          setError("Oops! Something went wrong. Please try again.");
        } finally {
          clearAllSearchParams(history);
        }
      })();
    }
  }, [
    match.params.token,
    setSelectedInvoices,
    getPaymentsByEmailToken,
    props.location.search,
  ]);

  const formatMoney = (number) => {
    return number
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  let invoiceTotal = 0;
  invoices.map(({ total }) => (invoiceTotal += total));

  const handleSidebar = (bool, update, closeWithoutPrompt) => {
    if (bool === false && !closeWithoutPrompt) {
      return setState({ isOpen: true });
    } else {
      return setState({ sidebar: bool, updating: !!update });
    }
  };

  // const payWithPaypalClient = (payload) => {
  //   rest
  //     .payWithPaypalClient(payload)
  //     .then(({ data }) => {
  //       let { href } = data.links.find((e) => e.method === "REDIRECT");
  //       window.open(href, "_self");
  //     })
  //     .catch(() => {
  //       toast.error("Oops! Something went wrong. Please try again.");
  //     });
  // };
  const payWithPaypalClient = () => {
    const {
      selectedInvoices,
      appliedCreditAmount = 0,
      promoCode,
      restOfListingPrice,
      discountAmount = 0,
    } = props;
    const redirectUri = window.location.href;

    let amount = 0;
    selectedInvoices.map((invoice) => (amount += invoice.invoiceAmount));
    amount = amount - discountAmount - appliedCreditAmount;

    const payload = {
      redirectUri,
      // subscription: activeTabType === "recurring",
      companyName: selectedInvoices[0]["owner.companyName"],
      ownerFirstName: selectedInvoices[0]["owner.firstName"],
      ownerLastName: selectedInvoices[0]["owner.lastName"],
      city: selectedInvoices[0]["owner.city"],
      state: selectedInvoices[0]["owner.state"],
      newAccount: selectedInvoices[0]["owner.newAccount"],
      source: "card",
      amount,
      discountAmount,
      credit: appliedCreditAmount,
      invoices: selectedInvoices.map((e) => ({
        ...e,
        promoCode: promoCode && { id: promoCode.id },
      })),
      promoCodeId: promoCode?.id ? promoCode.id : null,
      previousListingAmount: restOfListingPrice,
    };

    // let total = 0;
    // this.props.selectedInvoices.map((e) => (total += e.total));
    props
      .payWithPaypalClient(payload)
      .then(({ data }) => {
        let { href } = data.links.find((e) => e.method === "REDIRECT");
        window.open(href, "_self");
      })
      .catch((e) => {
        logger.error(e);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };
  const payWithStripe = () => {
    const {
      selectedInvoices,

      appliedCreditAmount = 0,
      promoCode,
      restOfListingPrice,
      discountAmount = 0,
    } = props;
    const redirectUri = window.location.href;

    let amount = 0;
    selectedInvoices.map((invoice) => (amount += invoice.invoiceAmount));
    amount = amount - discountAmount - appliedCreditAmount;

    const payload = {
      redirectUri,
      // subscription: activeTabType === "recurring",
      companyName: selectedInvoices[0]["owner.companyName"],
      ownerFirstName: selectedInvoices[0]["owner.firstName"],
      ownerLastName: selectedInvoices[0]["owner.lastName"],
      city: selectedInvoices[0]["owner.city"],
      state: selectedInvoices[0]["owner.state"],
      newAccount: selectedInvoices[0]["owner.newAccount"],
      source: "card",
      amount,
      discountAmount,
      credit: appliedCreditAmount,
      invoices: selectedInvoices.map((e) => ({
        ...e,
        promoCode: promoCode && { id: promoCode.id },
      })),
      promoCodeId: promoCode?.id ? promoCode.id : null,
      previousListingAmount: restOfListingPrice,
    };

    // let total = 0;
    // this.props.selectedInvoices.map((e) => (total += e.total));
    payWithStripeClient(payload)
      .then(({ data }) => {
        let { url } = data;
        window.open(url, "_self");
      })
      .catch((e) => {
        logger.error(e);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };
  //All listings are paid!

  // getSelectedInvoices = () => {
  //   let selectedInvoices = this.gridApi?.getSelectedNodes().map((gridNode) => {
  //     return { ...gridNode.data, isSelected: true };
  //   });
  //
  //   this.props.setSelectedInvoices({ selectedInvoices });
  // };
  const getSelectedInvoices = (promoCode) => {
    if (!gridApi.current) return;
    gridApi.current.showLoadingOverlay();

    let newTotal = 0;
    let selectedInvoices = gridApi.current
      ?.getSelectedNodes()
      .map((gridNode) => {
        newTotal += gridNode.data.total;
        return { ...gridNode.data, isSelected: true };
      });

    setTotal(newTotal);
    setSelectedInvoices({ selectedInvoices });
    gridApi &&
      setSelectedInvoicesCount(gridApi?.current?.getSelectedRows()?.length);
    gridApi.current.hideOverlay();
    promoCode && setDiscountForSelectedInvoices(promoCode);
  };

  const pageSizes = [10, 25, 50, 100];

  let a = setInterval(() => {
    let ag = document.getElementsByClassName("ag-root")[0];
    if (ag) {
      ag.style.marginLeft = editing ? "-50px" : "0px";
    }

    if (ag) clearInterval(a);
  }, 10);

  const onGridReady = (params) => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
  };

  const isValidPromocode = (promoCode) => {
    if (!promoCode) return true;

    if (promoCode.numberOfUses >= promoCode.limit && promoCode.limit) {
      return false;
    }

    let arr = [];
    const firstTime = promoCode.rules.includes("First time customer only");
    selectedInvoices.forEach((invoice) => {
      let listingTypeLabel = listingTypesArray.filter(
        (listingType) => invoice["listing.priceId"] === listingType.value,
      )[0].label;

      if (
        promoCode.rules.toLowerCase().includes(listingTypeLabel.toLowerCase())
      ) {
        arr.push(listingTypeLabel);
      }

      if (invoice["owner.newAccount"] === 1 && firstTime) {
        arr.push("First time customer only");
      }
    });

    if (arr.length > 0) {
      setPromoCodeErrorMessage(null);
      return true;
    } else {
      return false;
    }
  };

  const onApplyPromoCode = (promoCodeText) => {
    if (!promoCodeText?.length) return;

    checkPromoCode({ promoCode: promoCodeText })
      .then((res) => {
        setPromoCodeErrorMessage(null);
        if (!res.data) {
          setPromoCode(null);
          setPromoCodeErrorMessage(null);
        }
        if (isValidPromocode(res.data)) {
          setPromoCode(res.data);
          setDiscountForSelectedInvoices(res.data);
          setTimeout(() => {
            if (discountAmount > subTotal) {
              setIsCreditApplied(false);
              setAppliedCreditAmount(0);
            }
          }, 300);
          setPromoCodeApplied(true);
        } else {
          setPromoCode(null);
          setDiscountForSelectedInvoices(null);
          setPromoCodeErrorMessage("This promo code is not valid");
        }
      })
      .catch((err) => {
        logger.error(err);
        setPromoCode(null);
        setDiscountForSelectedInvoices(null);
        setPromoCodeErrorMessage("This promo code is not valid");
      });
  };

  const onClientPaymentSuccessfull = () => {
    setIsOpenPaymentSuccessfullyModal(true);
  };

  const showTableLoadingOverlay = () => {
    if (gridApi.current) {
      gridApi.current.showLoadingOverlay();
    }
  };

  const hideTableLoadingOverlay = () => {
    if (gridApi.current) {
      gridApi.current.hideOverlay();
    }
  };

  const toggleListingPreviewModal = () => {
    if (isOpenListingPreviewModal) {
      document.body.style.overflow = "unset";
    } else {
      document.body.style.overflow = "hidden";
    }
    setIsOpenListingPreviewModal(!isOpenListingPreviewModal);
  };

  if (error) {
    return (
      <div
        style={{ height: "100vh", width: "100vw" }}
        className={
          "d-flex flex-column justify-content-center align-items-center"
        }
      >
        <h2>{error}</h2>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{ height: "100vh", width: "100vw" }}
        className={
          "d-flex flex-column justify-content-center align-items-center"
        }
      >
        <Spinner size={"lg"} color="primary" />
      </div>
    );
  }

  if (downgradedListings.length) {
    return (
      <>
        <HeaderWrapper />
        <div
          style={{ height: "90vh", width: "100vw" }}
          className={
            "d-flex flex-column justify-content-center align-items-center"
          }
        >
          <InvoicesTempEmptyState
            title={"Your Advertisement EXPIRED"}
            subtitle={
              "Due to non-payment your listings are not easily discoverable and visible to drivers and fleets searching for services near you."
            }
            buttonLabel={"Relist now"}
            onButtonClick={() => {
              setInactiveListings([]);
              setDowngradedListings([]);
            }}
          />
        </div>
      </>
    );
  }

  if (inactiveListings.length) {
    return (
      <>
        <HeaderWrapper />
        <div
          style={{ height: "90vh", width: "100vw" }}
          className={
            "d-flex flex-column justify-content-center align-items-center"
          }
        >
          <InvoicesTempEmptyState
            title={
              inactiveListings.length > 1
                ? "Your business listings EXPIRED"
                : "Your business listing EXPIRED"
            }
            subtitle={
              inactiveListings.length > 1
                ? "Due to non-payment your listings are not visible to drivers and fleets searching for services near you"
                : "Due to non-payment your listing is not visible to drivers and fleets searching for services near you"
            }
            buttonLabel={"Relist now"}
            onButtonClick={() => {
              setInactiveListings([]);
              setDowngradedListings([]);
            }}
          />
        </div>
      </>
    );
  }

  if (!invoices.length) {
    return (
      <>
        <HeaderWrapper />
        <div
          style={{ height: "90vh", width: "100vw" }}
          className={
            "d-flex flex-column justify-content-center align-items-center"
          }
        >
          <InvoicesTempEmptyState
            onButtonClick={() => {
              window.location.href = "/dashboard/login";
            }}
          />
        </div>
      </>
    );
  }
  return (
    <div className="payment-link">
      <HeaderWrapper />
      <h1 className={"text-center mt-3 payment-title"}>
        Pay your business listings.
      </h1>
      <div className="payment-temporary-link-container">
        <Card
          className={
            "p-25 mt-3 payment-overview overflow-auto payment-overview-temp"
          }
        >
          <div className="invoices-date-filter mb-3 d-flex justify-content-between">
            <TitleRow title="Payment overview" />
          </div>

          <FTSDataTable
            noPagination={true}
            isSearch={true}
            getSelectedInvoices={getSelectedInvoices}
            pageSizes={pageSizes}
            rowSelection="multiple"
            rowData={selectedInvoices}
            onGridReadyInit={onGridReady}
            onRowClicked={(e = {}) => {
              logger.error(e);
              if (e) {
                if (
                  e.api?.focusedCellController?.focusedCellPosition?.column
                    .colId === "actions" &&
                  window.innerWidth >= 768
                ) {
                  setListingForPreview(e.data);
                  toggleListingPreviewModal();
                }
              }
            }}
            columnDefs={columnDefCheckBox}
            resetApi={() => {}}
            tableName="invoices2"
            domLayout="autoHeight"
            rowHeight={108}
            setPromoCode={setPromoCode}
            setDiscountForSelectedInvoices={setDiscountForSelectedInvoices}
          />
        </Card>

        <StickyPaymentBox
          promoCodeApplied={promoCodeApplied}
          onApplyPromoCode={onApplyPromoCode}
          setPromoCodeErrorMessage={setPromoCodeErrorMessage}
          promoCodeErrorMessage={promoCodeErrorMessage}
          promoCode={promoCode}
          discountAmount={discountAmount}
          subTotal={subTotal}
          appliedCreditAmount={appliedCreditAmount}
          total={total}
          totalAmount={totalAmount}
          isCreditApplied={isCreditApplied}
          setIsOpenPaymentModal={setIsOpenPaymentModal}
          formatMoney={formatMoney}
          invoicesCount={invoices?.length}
          selectedInvoicesCount={selectedInvoicesCount}
          setPromoCodeApplied={setPromoCodeApplied}
          setPromoCode={setPromoCode}
          setDiscountForSelectedInvoices={setDiscountForSelectedInvoices}
        />
      </div>

      <ActionSidebar
        temporaryLink={true}
        handleSidebar={handleSidebar}
        actionItem={state.actionItem}
        getSelectedInvoices={getSelectedInvoices}
        scrollToSelectedInvoice={() => {}}
        // accountData={invoices[0].owner}
        show={state.sidebar}
        action={state.action}
        downloadInvoiceOnLoad
        showTableLoadingOverlay={showTableLoadingOverlay}
        hideTableLoadingOverlay={hideTableLoadingOverlay}
      />
      <ClientPaymentMethodModal
        hash={match.params.token}
        total={total}
        payWithPaypalClient={payWithPaypalClient}
        payWithStripe={payWithStripe}
        selectedInvoices={selectedInvoices}
        invoiceTotal={formatMoney(invoiceTotal)}
        isOpen={isOpenPaymentModal}
        closeModal={() => setIsOpenPaymentModal(false)}
        onClientPaymentSuccessfull={onClientPaymentSuccessfull}
      />

      {isOpenPaymentSuccessfullyModal && (
        <PaymentProccessedModal
          closeModal={() => {
            window.location.href = "/dashboard";
          }}
          sourceId={sourceId}
          paymentDate={moment().unix()}
          lastPaymentModalTitle={"Payment successfully processed"}
          isOpen={isOpenPaymentSuccessfullyModal}
        />
      )}

      {isOpenListingPreviewModal && (
        <ListingPreviewModal
          isOpen={true}
          handleClose={toggleListingPreviewModal}
          listingId={listingForPreview.adId}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedInvoices: state.invoices.selectedInvoices,
    promoCode: state.promoCodes.promoCode,
    ads: state.adsList.data,
    totalAmount: state.invoices.totalAmount,
    subTotal: state.invoices.subTotal,
    discountAmount: state.invoices.discountAmount,
    appliedCreditAmount: state.invoices.appliedCreditAmount,
    isCreditApplied: state.invoices.isCreditApplied,
    totalAccountCredit: state.invoices.totalAccountCredit,
    updateListingTypePayload: state.listingCounts.updateListingTypePayload,

    invoicesData: state.invoices.invoicesData,
    totalRecordsInvoices: state.invoices.totalRecordsInvoices,
    invoicesCount: state.invoices.allInvoicesCount,
    activitiesTypes: state.essentialLists.activitiesTypes,
    restOfListingPrice: state.invoices.restOfListingPrice,
  };
};

const mapDispatchToProps = {
  setSelectedInvoices,
  payWithPaypalClient,
  checkPromoCode,
  setPromoCode,
  setDiscountForSelectedInvoices,
  setAppliedCreditAmount,
  setIsCreditApplied,
  getPaymentsByEmailToken,
  payWithStripeClient,
  setDiscount,
  setAppliedCredit,
};
export default connect(mapStateToProps, mapDispatchToProps)(Payment);
