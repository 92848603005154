import React from "react";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { Badge } from "@find-truck-service/ui/src/components/react/Badge";

export const AmenitiesMobile = ({
  selectedAmenities,
  setShowModal,
  children,
}) => {
  const hasSelected = selectedAmenities?.length > 0;

  function onEditAmenities() {
    setShowModal(true);
  }

  return (
    <FtsColumn
      size={{ xs: 12 }}
      display={{ xs: "block", sm: "none", md: "none", lg: "none" }}
    >
      {children}
      {hasSelected && (
        <FtsWrapper display="flex" wrap={"wrap"} gap={"sm"} mb={"lg"}>
          {selectedAmenities?.map((e) => (
            <Badge
              key={e.amenityId}
              type={"accent"}
              size={"md"}
              title={e.name}
              badgeRadiusTypes={"pill"}
              variant={"shaded"}
            />
          ))}
        </FtsWrapper>
      )}

      <Button
        width={"100%"}
        size={ButtonSizes.md}
        onClick={onEditAmenities}
        color={ButtonColorsValue.accent}
        type={ButtonTypes.outlined}
        iconName={IconNames.Edit}
        label={"Edit Amenities"}
      />
    </FtsColumn>
  );
};
