import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserName } from "../../../redux/reducers/v2/auth";
import { Header } from "@find-truck-service/ui/src/components/react/Header";
import Sidebar from "../../../layouts/components/menu/vertical-menu/Sidebar";

export const HeaderWithSidebar = ({
  sidebarProps,
  handleSidebarVisibility,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const userName = useSelector(selectUserName);

  const openSideOut = useCallback(() => {
    setIsSidebarOpen(true);
    handleSidebarVisibility && handleSidebarVisibility();
  }, []);

  return (
    <>
      <Header userName={userName} dataLoaded openSideOut={openSideOut} />
      {isSidebarOpen && <Sidebar {...sidebarProps} isMobile />}
    </>
  );
};
