/* eslint-disable indent */
import React from "react";
import { ChevronLeft, ChevronRight, ChevronUp } from "react-feather";
import ReactPaginate from "react-paginate";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import Breakpoint from "../../hoc/Breakpoint";

const getMarginPages = (deviceType) => (deviceType === "desktop" ? 2 : 1);

const getPageRange = (deviceType) =>
  deviceType === "desktop" ? 5 : deviceType === "tablet" ? 3 : 1;

// const getInfoText = (currentPage, currentPageSize, totalRecords, deviceType) =>
//   deviceType === "small"
//     ? "" /* `${currentPageSize * currentPage - (currentPageSize - 1)} - ${
//         totalRecords <= currentPageSize
//           ? totalRecords
//           : currentPageSize * currentPage
//       } of ${totalRecords}` */
//     : `Showing ${currentPageSize * currentPage - (currentPageSize - 1)} to ${
//         totalRecords <= currentPageSize
//           ? totalRecords
//           : currentPageSize * currentPage
//       } of ${totalRecords} entries`;

const FTSTableFooter = ({
  pageCount,
  currentPageSize,
  currentPage,
  onPageChange,
  deviceType,
  filterSize,
  pageSizes,
  pageSizePropName,
}) => {
  if (!pageCount || pageCount <= 0 || !pageSizes) return null;
  const dropdownItems = pageSizes.map((pageSize, index) => (
    <DropdownItem
      tag="div"
      onClick={() => filterSize(pageSizePropName, pageSize)}
      key={index}
    >
      {pageSize}
    </DropdownItem>
  ));

  return (
    <div className="fts-pagination ag-grid-table-alternate">
      <Breakpoint md up>
        <div className="sort-dropdown mr-1">
          <UncontrolledDropdown className="ag-dropdown no-chevron p-1">
            <DropdownToggle tag="div">
              Show entries: {currentPageSize}
              <ChevronUp className="ml-50" size={15} />
            </DropdownToggle>
            <DropdownMenu right>{dropdownItems}</DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Breakpoint>
      {/* <div className="results-info">
        {getInfoText(currentPage, currentPageSize, totalRecords, deviceType)}
      </div> */}
      <ReactPaginate
        previousLabel={
          deviceType === "small" || deviceType === "phone" ? (
            <ChevronLeft size="24" />
          ) : (
            <ChevronLeft size="16" />
          )
        }
        nextLabel={
          deviceType === "small" || deviceType === "phone" ? (
            <ChevronRight size="24" />
          ) : (
            <ChevronRight size="16" />
          )
        }
        breakLabel={".."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        forcePage={currentPage - 1}
        marginPagesDisplayed={getMarginPages(deviceType)}
        pageRangeDisplayed={getPageRange(deviceType)}
        onPageChange={onPageChange}
        containerClassName={"pagination ml-auto"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default React.memo(FTSTableFooter);
