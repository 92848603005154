import {
  TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_FAILED,
  TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_REQUEST,
  TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_SUCCESS,
  TEMPORARY_LINK_PAY_WITH_PAYPAL_FAILED,
  TEMPORARY_LINK_PAY_WITH_PAYPAL_REQUEST,
  TEMPORARY_LINK_PAY_WITH_PAYPAL_SUCCESS,
  TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_FAILED,
  TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_REQUEST,
  TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_SUCCESS,
} from "../../../reducers/v2/temporaryLinkPayments";

export const temporaryLinkPayWithExistingCardRequest = (payload) => ({
  type: TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_REQUEST,
  payload,
});

export const temporaryLinkPayWithExistingCardSuccess = (payload) => ({
  type: TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_SUCCESS,
  payload,
});

export const temporaryLinkPayWithExistingCardFailed = (payload) => ({
  type: TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_FAILED,
  payload,
});

export const temporaryLinkPayWithPaypalRequest = (payload) => ({
  type: TEMPORARY_LINK_PAY_WITH_PAYPAL_REQUEST,
  payload,
});

export const temporaryLinkPayWithPaypalSuccess = (payload) => ({
  type: TEMPORARY_LINK_PAY_WITH_PAYPAL_SUCCESS,
  payload,
});

export const temporaryLinkPayWithPaypalFailed = (payload) => ({
  type: TEMPORARY_LINK_PAY_WITH_PAYPAL_FAILED,
  payload,
});

export const temporaryLinkRegisterPaypalPaymentRequest = (payload) => ({
  type: TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_REQUEST,
  payload,
});

export const temporaryLinkRegisterPaypalPaymentSuccess = (payload) => ({
  type: TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_SUCCESS,
  payload,
});

export const temporaryLinkRegisterPaypalPaymentFailed = (payload) => ({
  type: TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_FAILED,
  payload,
});
