import { call, takeLatest, put, all, take } from "redux-saga/effects";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";
import { REACTIVATE_ACCOUNT_REQUEST } from "../../reducers/v2/reactivateAccount";
import {
  reactivateAccountFailed,
  reactivateAccountSuccess,
} from "../../actions/v2/reactivateAccount";
import { setLoggedUserCookie } from "../../../cookies";
import { setUserData } from "../../actions/v2/auth";
import {
  COLLECT_ESSENTIALS_SUCCESS,
  collectEssentialsRequest,
} from "../../actions/v2/essentials";

function* reactivateAccount(action) {
  const { data, onSuccess, onError } = action.payload;

  try {
    const response = yield call(
      FTSAxiosTemporaryLink.put,
      "/auth/reactivate/account",
      {
        newPassword: data.newPassword,
      },
    );

    const { user, bearerToken } = response.data.data;
    setLoggedUserCookie(user, bearerToken);

    yield all([
      put(setUserData({ data: user })),
      put(collectEssentialsRequest()),
      take(COLLECT_ESSENTIALS_SUCCESS),
    ]);

    onSuccess();
    yield put(reactivateAccountSuccess());
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(reactivateAccountFailed({ data: { message, inlineMessage } }));
    onError(e);
  }
}

export default function* reactivateAccountWatcher() {
  yield takeLatest(REACTIVATE_ACCOUNT_REQUEST, reactivateAccount);
}
