import React from "react";
import { AmenitiesRow } from "../../components/AmenitiesRow";
import { EditListingInfoMdFooter } from "./EditListingInfoMdFooter";
import { ListingImagesRow } from "../../components/ListingImagesRow";
import { ListingInfoHeader } from "../../components/ListingInfoHeader";
import { ListingDetailsRow } from "../../components/ListingDetailsRow";
import { ListingMapInfoRow } from "../../components/ListingMapInfoRow";
import { ListingContactRow } from "../../components/ListingContactRow";
import { ListingInfoWrapper } from "../../components/ListingInfoWrapper";
import { ListingInfoRatesRow } from "../../components/ListingInfoRatesRow";
import { CategoriesPickerRow } from "../../components/CategoriesPickerRow";
import { ServiceAmenitiesRow } from "../../components/ServiceAmenitiesRow";
import { ListingDescriptionRow } from "../../components/ListingDescriptionRow";
import { ListingWorkingHoursRow } from "../../components/ListingWorkingHoursRow";
import { ListingInfoInlineError } from "../../components/ListingInfoInlineError";

const EditListingInfoContent = () => {
  return (
    <ListingInfoWrapper>
      <ListingInfoInlineError />
      <ListingInfoHeader />
      <ListingDetailsRow />
      <ListingMapInfoRow />
      <ListingContactRow />
      <ListingDescriptionRow />
      <ListingWorkingHoursRow />
      <ListingInfoRatesRow />
      <CategoriesPickerRow />
      <ServiceAmenitiesRow />
      <AmenitiesRow />
      <ListingImagesRow />
      <EditListingInfoMdFooter />
    </ListingInfoWrapper>
  );
};

export default EditListingInfoContent;
