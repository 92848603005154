const subjects = [
  { label: "Questions", value: "Questions" },
  { label: "Advertising", value: "Advertising" },
  { label: "Website Errors", value: "Website Errors" },
  { label: "Listing Errors", value: "Listing Errors" },
  { label: "Feedback", value: "Feedback" },
  { label: "Other", value: "Other" },
];

export default subjects;
