import React, { useCallback } from "react";
import { mapRelatedFields } from "../constants";
import { ListingDetails } from "./ListingDetails";
import { capitalizeFirstLetter } from "../helpers";
import { useDispatch, useSelector } from "react-redux";
import { selectStates } from "../../../../redux/reducers/essentialLists";
import {
  selectListingData,
  selectListingInvalidFormFields,
} from "../../../../redux/reducers/v2/listing";
import {
  setListingData,
  setListingInvalidFormFields,
} from "../../../../redux/actions/v2/listing";

export const ListingDetailsRow = () => {
  const dispatch = useDispatch();
  const states = useSelector(selectStates);
  const listingData = useSelector(selectListingData);
  const invalidFormFields = useSelector(selectListingInvalidFormFields);

  const onChange = useCallback(
    (event) => {
      const name = event.target.name;
      const isMapRelated = mapRelatedFields.includes(name);
      let location = {};
      if (isMapRelated)
        location = {
          latitude: "",
          longitude: "",
        };
      let value = event.target.value;
      if (name === "city") value = capitalizeFirstLetter(value);
      const data = { ...location, [name]: value };
      dispatch(setListingData({ data }));
      const invalidFields = { ...invalidFormFields, [name]: "" };
      dispatch(setListingInvalidFormFields({ data: invalidFields }));
    },
    [invalidFormFields],
  );

  return (
    <ListingDetails
      states={states}
      onChange={onChange}
      listingData={listingData}
      invalidFormFields={invalidFormFields}
    />
  );
};
