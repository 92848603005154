import React from "react";
import { Badge } from "@find-truck-service/ui/src/components/react/Badge";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

export const ServiceAmenitiesMobile = ({
  selectedServiceAmenities,
  setShowModal,
  children,
}) => {
  const hasSelected = selectedServiceAmenities?.length > 0;

  function onEditServiceAmenities() {
    setShowModal(true);
  }

  return (
    <FtsColumn
      size={{ xs: 12 }}
      display={{ xs: "block", sm: "none", md: "none", lg: "none" }}
    >
      {children}
      {hasSelected && (
        <FtsWrapper display="flex" wrap={"wrap"} gap={"sm"} mb={"lg"}>
          {selectedServiceAmenities?.map((e) => (
            <Badge
              key={e.serviceAmenityId}
              type={"accent"}
              size={"md"}
              title={e.name}
              badgeRadiusTypes={"pill"}
              variant={"shaded"}
            />
          ))}
        </FtsWrapper>
      )}

      <Button
        width={"100%"}
        size={ButtonSizes.md}
        color={ButtonColorsValue.accent}
        onClick={onEditServiceAmenities}
        type={ButtonTypes.outlined}
        iconName={IconNames.Edit}
        label={"Edit Services"}
      />
    </FtsColumn>
  );
};
