import React from "react";
import { FtsRadioButtonWrapper } from "../../../FtsRadioButton/FtsRadioButtonWrapper";
import { FtsRadioButton } from "../../../FtsRadioButton/FtsRadioButton";
import { FtsRadioButtonLabel } from "../../../FtsRadioButton/FtsRadioButtonLabel";
import { InputLabel } from "../InputLabel";
export const RadioButton = (props) => {
    return (React.createElement(FtsRadioButtonWrapper, null,
        React.createElement(FtsRadioButtonLabel, null,
            React.createElement(FtsRadioButton, { type: "radio", checked: props.checked, size: props.size }),
            React.createElement(InputLabel, { ml: "2xs", label: props.label, required: props.required }))));
};
