import { createSelector } from "reselect";

export const GET_VENDOR_ADVERTISE_LISTINGS_REQUEST =
  "ADVERTISE_LISTINGS/GET_VENDOR_ADVERTISE_LISTINGS_REQUEST";
export const GET_VENDOR_ADVERTISE_LISTINGS_SUCCESS =
  "ADVERTISE_LISTINGS/GET_VENDOR_ADVERTISE_LISTINGS_SUCCESS";
export const GET_VENDOR_ADVERTISE_LISTINGS_FAILED =
  "ADVERTISE_LISTINGS/GET_VENDOR_ADVERTISE_LISTINGS_FAILED";
export const GET_ACTIVE_AND_FREE_LISTINGS_COUNT_REQUEST =
  "ADVERTISE_LISTINGS/GET_ACTIVE_AND_FREE_LISTINGS_COUNT_REQUEST";
export const GET_ACTIVE_AND_FREE_LISTINGS_COUNT_SUCCESS =
  "ADVERTISE_LISTINGS/GET_ACTIVE_AND_FREE_LISTINGS_COUNT_SUCCESS";
export const GET_ACTIVE_AND_FREE_LISTINGS_COUNT_FAILED =
  "ADVERTISE_LISTINGS/GET_ACTIVE_AND_FREE_LISTINGS_COUNT_FAILED";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  inlineError: null,
  showCreateFreeListingButton: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VENDOR_ADVERTISE_LISTINGS_REQUEST:
      return { ...state, error: null, inlineError: null, isLoading: true };

    case GET_VENDOR_ADVERTISE_LISTINGS_SUCCESS:
      return {
        ...state,
        error: null,
        inlineError: null,
        isLoading: false,
        data: action.payload.data,
      };

    case GET_ACTIVE_AND_FREE_LISTINGS_COUNT_FAILED:
    case GET_VENDOR_ADVERTISE_LISTINGS_FAILED:
      return {
        ...state,
        error: action.payload.data?.message,
        inlineError: action.payload.data?.inlineMessage,
        isLoading: false,
      };

    case GET_ACTIVE_AND_FREE_LISTINGS_COUNT_SUCCESS:
      return {
        ...state,
        showCreateFreeListingButton: action.payload.data,
      };

    default:
      return state;
  }
}

const selectVendorAdvertiseListingsState = (state) =>
  state.vendorAdvertiseListingsState;

export const selectVendorAdvertiseListingsError = createSelector(
  selectVendorAdvertiseListingsState,
  (vendorAdvertiseListingsState) => {
    return vendorAdvertiseListingsState.error;
  },
);

export const selectVendorAdvertiseListingsInlineError = createSelector(
  selectVendorAdvertiseListingsState,
  (vendorAdvertiseListingsState) => {
    return vendorAdvertiseListingsState.inlineError;
  },
);

export const selectVendorAdvertiseListingsData = createSelector(
  selectVendorAdvertiseListingsState,
  (vendorAdvertiseListingsState) => {
    return vendorAdvertiseListingsState.data;
  },
);

export const selectVendorAdvertiseListingsIsLoading = createSelector(
  selectVendorAdvertiseListingsState,
  (vendorAdvertiseListingsState) => {
    return vendorAdvertiseListingsState.isLoading;
  },
);

export const selectVendorAdvertiseListingsShowCreateFreeListingButton =
  createSelector(
    selectVendorAdvertiseListingsState,
    (vendorAdvertiseListingsState) => {
      return vendorAdvertiseListingsState.showCreateFreeListingButton;
    },
  );
