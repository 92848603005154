import React from "react";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import Warning from "@find-truck-service/ui/src/components/react/Warning";
import { history } from "../../../history";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
export const FreeListingExist = () => {
  return (
    <FtsWrapper mt={{ xs: "0", sm: "0", md: "3xl", lg: "4xl" }}>
      <Warning
        iconName="AlertTriangle"
        title="Free Listing Limit Reached"
        message="Free listings are limited to one per vendor and are only available to new vendors. They have lower visibility and appear below paid listings. Since you already have a paid listing, you can't create another free listing."
        containedButton={{
          label: "Create Paying Listing",
          color: ButtonColorsValue.accent,
          onClick: () => history.push("/dashboard/vendor-advertise-listings"),
        }}
        outlinedButton={{
          label: "Go to Dashboard",
          color: ButtonColorsValue.accent,
          onClick: () => history.push("/dashboard"),
        }}
      />
    </FtsWrapper>
  );
};
