import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

export const TemporaryLinkEditListingHeaderXs = ({ title }) => {
  return (
    <FtsWrapper
      px={"xl"}
      py={"2xl"}
      display={{ xs: "block", sm: "none", md: "none", lg: "none" }}
    >
      <Span
        weight={WeightSize.bold}
        variant={VariantsValues["text-md"]}
        color={ColorsValue["greyscale-800"]}
      >
        {title}
      </Span>
    </FtsWrapper>
  );
};
