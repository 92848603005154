import React from "react";
import {
  // * breakpoint-specific
  useSmallPhoneMediaQuery,
  useRegularPhoneMediaQuery,
  useTabletMediaQuery,
  useDesktopMediaQuery,
  useLargeDesktopMediaQuery,
  useExtraLargeDesktopMediaQuery,

  // * breakpoint and above
  usePhoneAndAboveMediaQuery,
  useTabletAndAboveMediaQuery,
  useDesktopAndAboveMediaQuery,
  useLargeDesktopAndAboveMediaQuery,

  // * breakpoint and below
  usePhoneAndBelowMediaQuery,
  useTabletAndBelowMediaQuery,
  useDesktopAndBelowMediaQuery,
  useLargeDesktopAndBelowMediaQuery,
} from "../../hooks";

const Breakpoint = ({ children, xs, sm, md, lg, xl, xxl, only, down, up }) => {
  // * breakpoint-specific
  if (xs && only) return <SmallPhoneOnly>{children}</SmallPhoneOnly>;
  if (sm && only) return <PhoneOnly>{children}</PhoneOnly>;
  if (md && only) return <TabletOnly>{children}</TabletOnly>;
  if (lg && only) return <DesktopOnly>{children}</DesktopOnly>;
  if (xl && only) return <LargeDesktopOnly>{children}</LargeDesktopOnly>;
  if (xxl && only)
    return <ExtraLargeDesktopOnly>{children}</ExtraLargeDesktopOnly>;

  // * breakpoint and above
  if (xs && up) return children;
  if (sm && up) return <PhoneAndAbove>{children}</PhoneAndAbove>;
  if (md && up) return <TabletAndAbove>{children}</TabletAndAbove>;
  if (lg && up) return <DesktopAndAbove>{children}</DesktopAndAbove>;
  if (xl && up) return <LargeDesktopAndAbove>{children}</LargeDesktopAndAbove>;
  if (xxl && up)
    return <ExtraLargeDesktopOnly>{children}</ExtraLargeDesktopOnly>;

  // * breakpoint and below
  if (xs && down) return <SmallPhoneOnly>{children}</SmallPhoneOnly>;
  if (sm && down) return <PhoneAndBelow>{children}</PhoneAndBelow>;
  if (md && down) return <TabletAndBelow>{children}</TabletAndBelow>;
  if (lg && down) return <DesktopAndBelow>{children}</DesktopAndBelow>;
  if (xl && down)
    return <LargeDesktopAndBelow>{children}</LargeDesktopAndBelow>;
  if (xxl && down) return children;

  return children;
};

// * Breakpoint and below
const PhoneAndBelow = ({ children }) => {
  const isPhoneAndBelow = usePhoneAndBelowMediaQuery();
  return isPhoneAndBelow ? children : null;
};
const TabletAndBelow = ({ children }) => {
  const isTabletAndBelow = useTabletAndBelowMediaQuery();
  return isTabletAndBelow ? children : null;
};
const DesktopAndBelow = ({ children }) => {
  const isDesktopAndBelow = useDesktopAndBelowMediaQuery();
  return isDesktopAndBelow ? children : null;
};
const LargeDesktopAndBelow = ({ children }) => {
  const isLargeDesktopAndBelow = useLargeDesktopAndBelowMediaQuery();
  return isLargeDesktopAndBelow ? children : null;
};

// * Breakpoint and above

const PhoneAndAbove = ({ children }) => {
  const isPhoneAndAbove = usePhoneAndAboveMediaQuery();
  return isPhoneAndAbove ? children : null;
};
const TabletAndAbove = ({ children }) => {
  const isTabletAndAbove = useTabletAndAboveMediaQuery();
  return isTabletAndAbove ? children : null;
};
const DesktopAndAbove = ({ children }) => {
  const isDesktopAndAbove = useDesktopAndAboveMediaQuery();
  return isDesktopAndAbove ? children : null;
};
const LargeDesktopAndAbove = ({ children }) => {
  const isLargeDesktopAndAbove = useLargeDesktopAndAboveMediaQuery();
  return isLargeDesktopAndAbove ? children : null;
};

// * Breakpoint-specific containers
const SmallPhoneOnly = ({ children }) => {
  const isSmallPhoneOnly = useSmallPhoneMediaQuery();
  return isSmallPhoneOnly ? children : null;
};
const PhoneOnly = ({ children }) => {
  const isPhoneOnly = useRegularPhoneMediaQuery();
  return isPhoneOnly ? children : null;
};
const TabletOnly = ({ children }) => {
  const isTabletOnly = useTabletMediaQuery();
  return isTabletOnly ? children : null;
};
const DesktopOnly = ({ children }) => {
  const isDesktopOnly = useDesktopMediaQuery();
  return isDesktopOnly ? children : null;
};
const LargeDesktopOnly = ({ children }) => {
  const isLargeDesktopOnly = useLargeDesktopMediaQuery();
  return isLargeDesktopOnly ? children : null;
};
const ExtraLargeDesktopOnly = ({ children }) => {
  const isExtraLargeDesktopOnly = useExtraLargeDesktopMediaQuery();
  return isExtraLargeDesktopOnly ? children : null;
};

export default Breakpoint;
