import React, { useCallback } from "react";

import "../../../../assets/scss/components/fts/complete-profile.scss";

import { connect } from "react-redux";
import FleetCarrierSubAccountForm from "../../../../components/fts/fleet-carrier-sub-account-form";
import { history } from "../../../../history";
import CompanyDriverInfo from "../../../../components/fts-components-v2/CompanyDriverInfo";
import FleetInfo from "../../../../components/fts-components-v2/FleetInfo";
import OtherInfo from "../../../../components/fts-components-v2/OtherInfo";
import OwnerOperatorProfile from "../../../../components/fts-components-v2/OwnerOperatorProfile";
import { HeaderWrapper } from "../../../../components/fts-components-v2/HeaderWrapper";
import { CompleteAccountInfo } from "../../../../components/fts-components-v2/CompleteAccountInfo";

const CompleteProfile = (props) => {
  const { chosenAccount, userData, userTypes, redirectToWebsite } = props;

  const handleStepBack = () => {
    history.push("/dashboard/choose-account-type");
  };

  const isSubAccount = useCallback(() => {
    return !!userData?.accountLink;
  }, [userData]);

  const getCategoryNameByID = (categoryName) => {
    return userTypes?.find((type) => type?.categoryName === categoryName)?.id;
  };

  return (
    <div className="complete-profile-layout">
      <HeaderWrapper />
      <div>
        {!isSubAccount() &&
          chosenAccount === getCategoryNameByID("Fleet/Carrier") && (
            <FleetInfo />
          )}
        {isSubAccount() &&
          chosenAccount === getCategoryNameByID("Fleet/Carrier") && (
            <FleetCarrierSubAccountForm
              handleCancel={handleStepBack}
              handleStepBack={handleStepBack}
              isSubAccount={isSubAccount}
              redirectToWebsite={redirectToWebsite}
              userData={userData}
            />
          )}
        {chosenAccount === getCategoryNameByID("Owner Operator") && (
          <OwnerOperatorProfile />
        )}
        {chosenAccount === getCategoryNameByID("Company Driver") && (
          <CompanyDriverInfo />
        )}
        {chosenAccount === getCategoryNameByID("Other") && <OtherInfo />}
        {chosenAccount === getCategoryNameByID("Service Vendor") && (
          <CompleteAccountInfo selectedAccountType={chosenAccount} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userTypes: state.essentialLists.userTypes,
    userData: state.authState.user,
  };
};

export default connect(mapStateToProps)(CompleteProfile);
