import {
  COMPANY_DRIVER_PROFILE_SET_FIELD_VALUES,
  COMPANY_DRIVER_PROFILE_UPDATE_REQUEST,
  COMPANY_DRIVER_PROFILE_UPDATE_FAILED,
  COMPANY_DRIVER_PROFILE_UPDATE_SUCCESS,
  COMPANY_DRIVER_PROFILE_SET_INVALID_FORM_FIELDS,
  COMPANY_DRIVER_PROFILE_SET_UPDATE_LOADING,
  COMPANY_DRIVER_PROFILE_SET_FINISH_LATER_LOADING,
} from "../../../reducers/v2/company-driver-profile";

export const companyDriverProfileSetFieldValues = (payload) => ({
  type: COMPANY_DRIVER_PROFILE_SET_FIELD_VALUES,
  payload,
});

export const companyDriverProfileUpdateRequest = (payload) => ({
  type: COMPANY_DRIVER_PROFILE_UPDATE_REQUEST,
  payload,
});

export const companyDriverProfileUpdateSuccess = (payload) => ({
  type: COMPANY_DRIVER_PROFILE_UPDATE_SUCCESS,
  payload,
});

export const companyDriverProfileUpdateFailed = (payload) => ({
  type: COMPANY_DRIVER_PROFILE_UPDATE_FAILED,
  payload,
});

export const companyDriverProfileSetInvalidFormFields = (payload) => ({
  type: COMPANY_DRIVER_PROFILE_SET_INVALID_FORM_FIELDS,
  payload,
});

export const companyDriverProfileSetUpdateLoading = (payload) => ({
  type: COMPANY_DRIVER_PROFILE_SET_UPDATE_LOADING,
  payload,
});

export const companyDriverProfileSetFinishLaterLoading = (payload) => ({
  type: COMPANY_DRIVER_PROFILE_SET_FINISH_LATER_LOADING,
  payload,
});
