import React, { useCallback, useEffect } from "react";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { InvoiceSkeletonLoader } from "@find-truck-service/ui/src/components/react/InvoiceSkeletonLoader";

import { useDispatch, useSelector } from "react-redux";
import PaymentOverviewPaymentMethod from "./components/PaymentOverviewPaymentMethod/PaymentOverviewPaymentMethod";
import PaymentOverviewMainContent from "./components/PaymentOverviewMainContent/PaymentOverviewMainContent";
import PaymentOverviewHeader from "./components/PaymentOverviewHeader/PaymentOverviewHeader";

import { getSingleInvoiceRequest } from "../../../redux/actions/v2/invoices";
import {
  selectCreateListingInvoice,
  selectCreateListingInvoiceError,
  selectCreateListingIsLoading,
} from "../../../redux/reducers/v2/invoices";
import GetListedStepperIndicator from "../GetListedStepperIndicator";
import { selectPaymentsIsLoading } from "../../../redux/reducers/v2/payments";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { SOMETHING_WENT_WRONG, UNEXPECTED_ISSUE } from "utility/errorMessages";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";

import { history } from "../../../history";
import { extractParameter } from "../../../utility/param-extract";

// import { registerPaypalPaymentRequest } from "../../../redux/actions/v2/payments";

const PaymentOverview = ({ match }) => {
  const dispatch = useDispatch();
  const listingInvoice = useSelector(selectCreateListingInvoice);
  const isLoading = useSelector(selectCreateListingIsLoading);
  const isPaymentLoading = useSelector(selectPaymentsIsLoading);
  const error = useSelector(selectCreateListingInvoiceError);

  useEffect(() => {
    if (match) {
      const { adId } = match.params;
      if (adId) {
        dispatch(getSingleInvoiceRequest(adId));
      }
    }
  }, [match]);

  const onBackClick = useCallback(() => {
    const convertedToVendor = extractParameter("convertedToVendor");

    if (convertedToVendor) {
      history.push(
        `/dashboard/get-listed/preview-listing/${listingInvoice.listing.id}?selectedListingPriceId=${listingInvoice.listing.priceId}&convertedToVendor=true`,
      );
    } else {
      history.push(
        `/dashboard/get-listed/preview-listing/${listingInvoice.listing.id}?selectedListingPriceId=${listingInvoice.listing.priceId}`,
      );
    }
  }, []);
  return (
    <>
      {error && !isLoading ? (
        <FtsWrapper mt="4xl">
          <Error
            title={SOMETHING_WENT_WRONG}
            subtitle={error?.inlineMessage || UNEXPECTED_ISSUE}
            iconName="AlertTriangle"
            containedButton={{
              label: "Refresh",
              color: ButtonColorsValue.accent,
              onClick: () => {
                history.go(0);
              },
            }}
          />
        </FtsWrapper>
      ) : (
        <FtsContainer>
          {isPaymentLoading && <FullPageLoader />}

          {/*Stepper for sm devices*/}
          <FtsWrapper
            display={{ xs: "flex", sm: "none", md: "none", lg: "none" }}
          >
            <GetListedStepperIndicator activeStepId={4} />
          </FtsWrapper>
          {/*Stepper for bigger devices*/}
          <FtsWrapper
            display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}
            justify={"center"}
          >
            <GetListedStepperIndicator activeStepId={3} />
          </FtsWrapper>

          <FtsRow>
            <FtsColumn
              size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
              offset={{ xs: 0, sm: 0, md: 0, lg: 3 }}
            >
              <FtsRow
                px={{ xs: "xl", sm: "4xl", md: "4xl", lg: "4xl" }}
                py={{ xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" }}
                bg={ColorsValue["greyscale-0"]}
                borderRadius="2xs"
                boxShadow="sm"
                size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
              >
                <PaymentOverviewHeader onBackClick={onBackClick} />

                {isLoading ? (
                  <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                    <InvoiceSkeletonLoader />
                  </FtsColumn>
                ) : (
                  <PaymentOverviewMainContent invoice={listingInvoice} />
                )}
                <PaymentOverviewPaymentMethod />
              </FtsRow>
            </FtsColumn>
          </FtsRow>
        </FtsContainer>
      )}
    </>
  );
};

export default PaymentOverview;
