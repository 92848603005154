import React, { lazy } from "react";
import { Activity, Search } from "react-feather";

import Icons from "../components/fts/Icons";
import { PERMISSIONS, USER_TYPE } from "../constants";
import { EVENT_MEASUREMENTS } from "../constants/analytics";
import NotificationsFull from "../views/pages/notifications-full";

const clientDashboard = lazy(() => import("../views/pages/client-dashboard"));
const recentSearches = lazy(() => import("../views/pages/recent-searches"));

// const accountInfo = lazy(() =>
//   import("../views/pages/account-settings/AccountSettings"),
// );

const SubAccounts = lazy(() => import("../views/pages/sub-accounts"));
const referAFriend = lazy(
  () => import("../components/fts/fts-refer-a-friend-page"),
);
const listings = lazy(() => import("../views/pages/listings"));
const renewals = lazy(() => import("../views/pages/renewals"));
const invoiceView = lazy(() => import("../views/pages/invoices-page"));
const paymentView = lazy(() => import("../views/pages/payments/paymentsList"));
const reports = lazy(() => import("../views/pages/reports-page/reports"));
const authorizedBenefits = lazy(
  () => import("../views/pages/authorized-benefits"),
);
const listingInfo = lazy(
  () => import("../views/pages/listing-settings/ListingSettings"),
);
const myProfileVendor = lazy(() => import("../views/pages/my-profile-vendor"));
const changeMyPassword = lazy(() => import("../views/pages/change-password"));
const downloadApp = lazy(() => import("../views/pages/download-app"));
// const ListingBusinessDetails = lazy(
//   () => import("../views/pages/listing-business-details"),
// );
// const EditListingBusinessDetails = lazy(
//   () => import("../views/pages/edit-listing-business-details"),
// );
// const paymentOverview = lazy(
//   () =>
//     import("../components/fts-components-v2/PaymentOverview/PaymentOverview"),
// );
//
// const ListingImages = lazy(() => import("../views/pages/listing-images"));
// const EditListingImages = lazy(
//   () => import("../views/pages/edit-listing-images"),
// );
// const ChooseListingType = lazy(
//   () => import("../views/pages/choose-listing-type"),
// );

const VendorAdvertiseListings = lazy(
  () =>
    import(
      "../components/fts-components-v2/VendorAdvertiseListings/VendorAdvertiseListings"
    ),
);

// import VerifyEmail from "../components/fts-components-v2/VerifyEmail/VerifyEmail";

export const serviceVendorRoutes = [
  {
    key: "notifications",
    path: "/dashboard/notifications",
    title: "Notifications",
    type: "item",
    notificationNavItem: true,
    isProtected: true,
    component: NotificationsFull,
    icon: <Activity size={20} />,
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_NOTIFICATIONS,
    interactionType: EVENT_MEASUREMENTS.VIEW_NOTIFICATIONS,
  },
  // {
  //   key: "list-your-business",
  //   path: "/dashboard/vendor-advertise-listings",
  //   noSidebar: true,
  //   isProtected: true,
  //   exact: false,
  //   fullLayout: true,
  //   component: listYourBusiness,
  //   sidebarComponent: null,
  //   icon: "",
  //   permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_LIST_YOUR_BUSINESS,
  //   interactionType: EVENT_MEASUREMENTS.VIEW_LIST_YOUR_BUSINESS,
  // },
  {
    key: "listings-by-id",
    path: "/dashboard/listings/:listingId",
    noSidebar: false,
    isProtected: true,
    exact: false,
    fullLayout: false,
    component: listingInfo,
    icon: "",
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_LISTING_INFO,
  },
  {
    key: "vendor-dashboard",
    path: "/dashboard",
    title: "Dashboard",
    type: "item",
    isProtected: true,
    component: clientDashboard,
    icon: <Activity size={20} />,
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_DASHBOARD,
    interactionType: EVENT_MEASUREMENTS.VIEW_DASHBOARD,
  },
  {
    key: "recent-searches",
    path: "/dashboard/recent-searches",
    title: "Searches",
    type: "item",
    component: recentSearches,
    icon: <Search size={20} />,
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_RECENT_SEARCHES,
    interactionType: EVENT_MEASUREMENTS.VIEW_RECENT_SEARCHES,
  },
  {
    key: "account",
    id: "account",
    title: "Account",
    type: "collapse",
    icon: <Icons name="Accounts" size={20} />,
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_COMPANY_DETAILS,
    children: [
      // {
      //   key: "companyDetails",
      //   id: "companyDetails",
      //   title: "Company details",
      //   type: "item",
      //   permission:
      //     PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_COMPANY_DETAILS,
      //   path: "/dashboard/company-details",
      //   interactionType: EVENT_MEASUREMENTS.VIEW_COMPANY_DETAILS,
      // },

      {
        key: "myProfile",
        id: "myProfile",
        title: "My profile",
        type: "item",
        permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_MY_PROFILE,
        path: "/dashboard/my-profile",
        interactionType: EVENT_MEASUREMENTS.VIEW_MY_PROFILE,
      },

      {
        key: "subAccounts",
        id: "subAccounts",
        title: "Sub Accounts",
        type: "item",
        permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_SUB_USERS,
        path: "/dashboard/sub-accounts",
        interactionType: EVENT_MEASUREMENTS.VIEW_SUB_USERS,
      },
      {
        key: "referAFriend",
        id: "referAFriend",
        title: "Refer a Friend",
        type: "item",
        permission:
          PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_REFERR_A_FRIEND,
        path: "/dashboard/refer-a-friend",
        interactionType: EVENT_MEASUREMENTS.VIEW_REFER_A_FRIEND,
      },
    ],
  },
  {
    key: "listings",
    path: "/dashboard/listings",
    title: "My listings",
    type: "item",
    component: listings,
    icon: <Icons name="Listings" size={20} />,
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_LISTINGS,
    interactionType: EVENT_MEASUREMENTS.VIEW_MY_LISTINGS,
  },
  {
    key: "renewals",
    path: "/dashboard/renewals",
    type: "item",
    title: "Renewals",
    noSidebar: false,
    isProtected: true,
    redirectToMobile: "RenewalsStack",
    exact: false,
    fullLayout: false,
    component: renewals,
    icon: <Icons name="Renewals" size={20} />,
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_RENEWALS,
    interactionType: EVENT_MEASUREMENTS.VIEW_RENEWALS,
  },
  {
    key: "invoice",
    path: "/dashboard/invoice",
    invoicesNavItem: true,
    title: "Reports",
    noSidebar: false,
    isProtected: true,
    exact: false,
    fullLayout: false,
    component: invoiceView,
    icon: "",
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_INVOICES,
    interactionType: EVENT_MEASUREMENTS.VIEW_INVOICES,
  },
  {
    key: "payments",
    id: "payments",
    title: "Payments",
    type: "collapse",
    icon: <Icons name="Payment" size={20} />,
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_PAYMENTS,
    children: [
      {
        key: "pastPayments",
        id: "pastPayments",
        title: "Past payments",
        type: "item",
        path: "/dashboard/past-payments",
        permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_PAST_PAYMENTS,
        interactionType: EVENT_MEASUREMENTS.VIEW_PAST_PAYMENTS,
      },
    ],
  },
  {
    key: "reports",
    path: "/dashboard/reports",
    type: "item",
    title: "Reports",
    noSidebar: false,
    isProtected: true,
    exact: false,
    fullLayout: false,
    component: reports,
    icon: <Icons name="Report" size={20} />,
    badge: "white",
    badgeText: "BETA",
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_REPORTS,
    interactionType: EVENT_MEASUREMENTS.VIEW_REPORTS,
  },
  {
    key: "authorized-benefits",
    path: "/dashboard/authorized-benefits",
    noSidebar: false,
    isProtected: true,
    exact: false,
    fullLayout: false,
    component: authorizedBenefits,
    icon: "",
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_GET_AUTHORIZED,
    interactionType: EVENT_MEASUREMENTS.VIEW_GET_AUTHORIZED,
  },
  // {
  //   key: "finish-your-business",
  //   path: "/dashboard/finish-your-business/:listingId",
  //   noSidebar: true,
  //   isProtected: true,
  //   exact: false,
  //   fullLayout: true,
  //   component: finishYourBusiness,
  //   icon: "",
  //   permission:
  //     PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_FINISH_YOR_BUSINESS,
  //   interactionType: EVENT_MEASUREMENTS.VIEW_FINISH_YOUR_BUSINESS,
  // },
  // {
  //   key: "upgrade-listing",
  //   path: "/dashboard/upgrade-listing",
  //   noSidebar: true,
  //   isProtected: true,
  //   exact: false,
  //   fullLayout: false,
  //   component: listYourBusiness,
  //   icon: "",
  //   permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_UPGRADE_LISTING,
  //   interactionType: EVENT_MEASUREMENTS.VIEW_UPGRADE_LISTING,
  // },
  {
    key: "refer-a-friend",
    path: "/dashboard/refer-a-friend",
    noSidebar: false,
    isProtected: true,
    exact: false,
    fullLayout: false,
    component: referAFriend,
    sidebarComponent: null,
    icon: "",
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_REFERR_A_FRIEND,
    interactionType: EVENT_MEASUREMENTS.VIEW_REFER_A_FRIEND,
  },
  {
    key: "past-payments",
    path: "/dashboard/past-payments",
    noSidebar: false,
    isProtected: true,
    exact: false,
    fullLayout: false,
    component: paymentView,
    icon: "",
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_PAST_PAYMENTS,
    interactionType: EVENT_MEASUREMENTS.VIEW_PAST_PAYMENTS,
  },
  // {
  //   key: "company-details",
  //   path: "/dashboard/company-details",
  //   noSidebar: false,
  //   isProtected: true,
  //   exact: false,
  //   fullLayout: false,
  //   component: accountInfo,
  //   sidebarComponent: null,
  //   icon: "",
  //   permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_COMPANY_DETAILS,
  //   interactionType: EVENT_MEASUREMENTS.VIEW_COMPANY_DETAILS,
  // },
  {
    key: "sub-accounts",
    path: "/dashboard/sub-accounts",
    noSidebar: false,
    isProtected: true,
    exact: false,
    fullLayout: false,
    component: SubAccounts,
    sidebarComponent: null,
    icon: "",
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_SUB_USERS,
    interactionType: EVENT_MEASUREMENTS.VIEW_SUB_USERS,
  },

  {
    key: "my-profile",
    path: "/dashboard/my-profile",
    noSidebar: false,
    isProtected: true,
    exact: false,
    fullLayout: false,
    component: myProfileVendor,
    sidebarComponent: null,
    icon: "",
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_MY_PROFILE,
    interactionType: EVENT_MEASUREMENTS.VIEW_MY_PROFILE,
  },
  {
    key: "change-my-password",
    path: "/dashboard/change-my-password",
    isProtected: true,
    component: changeMyPassword,
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_CHANGE_PASSWORD,
    interactionType: EVENT_MEASUREMENTS.CHANGE_PASSWORD,
  },
  {
    key: "download-app",
    path: "/dashboard/download-app",
    isProtected: true,
    component: downloadApp,
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_DOWNLOAD_APP,
    interactionType: EVENT_MEASUREMENTS.DOWNLOAD_APP,
  },

  {
    key: "get-listed",
    path: "/dashboard/vendor-advertise-listings",
    noSidebar: true,
    isProtected: true,
    exact: false,
    fullLayout: true,
    component: VendorAdvertiseListings,
    sidebarComponent: null,
    icon: "",
    permission: PERMISSIONS[USER_TYPE.SERVICE_VENDOR].VENDOR_LIST_YOUR_BUSINESS,
    interactionType: EVENT_MEASUREMENTS.VIEW_LIST_YOUR_BUSINESS,
  },
];
