import { Col, Modal, Row } from "reactstrap";
import Icon from "../Icons";
import React from "react";
import "../../../assets/scss/components/fts/modals/confirmation-modal-wrapper.scss";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

const ConfirmationModalWrapper = ({
  toggleModal,
  continueAction,
  title,
  buttonTitle,
  content = [],
  Content,
  formId,
  loading,
  showCancelActions = true,
}) => {
  return (
    <Modal
      centered={true}
      className={"confirmation-wrapper"}
      isOpen={true}
      toggle={toggleModal}
      onClosed={toggleModal}
    >
      <div className={"confirmation-wrapper-modal"}>
        <div className={"confirmation-wrapper-modal-header"}>
          <span>{title}</span>
          {showCancelActions && (
            <Icon name={"Exit"} size={32} onClick={toggleModal} />
          )}
        </div>
        <div className={"confirmation-wrapper-modal-content"}>
          {content.map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
          {!!Content && <Content />}
        </div>
        <div className={"confirmation-wrapper-modal-footer"}>
          <Row>
            {showCancelActions && (
              <Col sm={6}>
                <Button
                  width={"100%"}
                  size={ButtonSizes.md}
                  disabled={loading}
                  onClick={toggleModal}
                  color={ButtonColorsValue.neutral}
                  type={ButtonTypes.outlined}
                  label={"Cancel"}
                />
              </Col>
            )}
            <Col sm={showCancelActions ? 6 : 12}>
              <Button
                width={"100%"}
                size={ButtonSizes.md}
                form={formId}
                disabled={loading}
                loading={loading}
                onClick={continueAction}
                color={ButtonColorsValue.accent}
                type={ButtonTypes.contained}
                label={buttonTitle}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModalWrapper;
