import React from "react";
import { history } from "../../../../../history";
import { useDispatch, useSelector } from "react-redux";
import { extractParameter } from "../../../../../utility/param-extract";
import { listingDetailsRowValidator } from "../../validators/listingDetailsRowValidator";
import { listingContactRowValidator } from "../../validators/listingContactRowValidator";
import { ListingInfoMdFooterComponent } from "../../components/ListingInfoMdFooterComponent";
import {
  selectListingAdsAssetsIsLoading,
  selectListingData,
} from "../../../../../redux/reducers/v2/listing";
import {
  setListingInvalidFormFields,
  updateListingRequest,
} from "../../../../../redux/actions/v2/listing";
import { listingSearchCategoriesRowValidator } from "../../validators/listingSearchCategoriesRowValidator";

export const EditListingInfoMdFooter = () => {
  const dispatch = useDispatch();
  const listingData = useSelector(selectListingData);
  const isDisabled = useSelector(selectListingAdsAssetsIsLoading);

  function onSuccessfulUpdate(id) {
    const priceId = extractParameter("selectedListingPriceId");
    const convertedToVendor = extractParameter("convertedToVendor");

    const searchParams = new URLSearchParams();

    if (priceId) {
      searchParams.set("selectedListingPriceId", priceId);
    }

    if (convertedToVendor) {
      searchParams.set("convertedToVendor", convertedToVendor);
    }

    history.push({
      pathname: `/dashboard/get-listed/preview-listing/${id}`,
      search: `?${searchParams.toString()}`,
    });
  }

  async function onUpdate() {
    try {
      dispatch(setListingInvalidFormFields({ data: {} }));
      await listingDetailsRowValidator.validate(listingData);
      await listingContactRowValidator.validate(listingData);
      await listingSearchCategoriesRowValidator.validate(listingData);
      dispatch(updateListingRequest({ onSuccess: onSuccessfulUpdate }));
    } catch (e) {
      if (e.name !== "ValidationError") return;
      const data = { [e.path]: e.errors[0] };
      dispatch(setListingInvalidFormFields({ data }));
    }
  }

  const primaryButtonProps = {
    label: "Continue",
    onClick: onUpdate,
    disabled: isDisabled,
  };

  return (
    <ListingInfoMdFooterComponent primaryButtonProps={primaryButtonProps} />
  );
};
