import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
const HeaderButtonsLoggedUser = ({ userName, openSideOut }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Span, { variant: "text-2xs", weight: WeightSize.semibold, color: ColorsValue["greyscale-0"], display: { xs: "none", sm: "none", md: "block", lg: "block" }, style: { maxWidth: "90px", textWrap: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } }, userName),
        React.createElement(Button, { label: "Dashboard", size: ButtonSizes.md, type: ButtonTypes.contained, color: ButtonColorsValue.onBrand, onClick: () => (window.location.href = "/dashboard"), display: { xs: "none", sm: "none", md: "flex", lg: "flex" } }),
        React.createElement(Button, { onClick: openSideOut, size: ButtonSizes.md, type: ButtonTypes.text, iconName: IconNames.Menu, color: ButtonColorsValue.onBrand, display: { xs: "flex", sm: "flex", md: "flex", lg: "none" } })));
};
export default HeaderButtonsLoggedUser;
