import {
  isChrome,
  isEdge,
  isFirefox,
  isIE,
  isSafari,
} from "react-device-detect";

export const formatImageLink = (fileUrl) => {
  if (fileUrl === "") return null;
  if (fileUrl.startsWith("http")) return fileUrl;
  if (!fileUrl.startsWith("/")) fileUrl = "/" + fileUrl;
  fileUrl = process.env.REACT_APP_IMAGE_URL + fileUrl.replace("../", "");

  return fileUrl;
};

export const hasAllUrlParams = (urlParams) => {
  const paramsToCheck = [
    "city",
    "state",
    "longitude",
    "latitude",
    "mainCategory",
    "subCategory",
    "subCategoryLabel",
    "mainCategoryLabel",
  ];
  let isValid = true;

  // if (Object.keys(urlParams).length !== paramsToCheck.length) return false;

  for (const [key, val] of Object.entries(urlParams)) {
    if (!val) {
      isValid = false;
      return;
    }
    const isValidKey = paramsToCheck.find((e) => e === key);
    if (!isValidKey) {
      isValid = false;
      return;
    }
  }

  return isValid;
};

export function launchUri(
  uri,
  successCallback,
  noHandlerCallback,
  unknownCallback,
) {
  let parent,
    popup,
    iframe,
    timer,
    timeout,
    blurHandler,
    timeoutHandler,
    browser;

  function callback(cb) {
    if (typeof cb === "function") cb();
  }

  function createHiddenIframe(parent) {
    let iframe;
    if (!parent) parent = document.body;
    iframe = document.createElement("iframe");
    iframe.style.display = "none";
    parent.appendChild(iframe);
    return iframe;
  }

  function removeHiddenIframe(parent) {
    if (!iframe) return;
    if (!parent) parent = document.body;
    parent.removeChild(iframe);
    iframe = null;
  }

  browser = {
    isChrome,
    isIE,
    isFirefox,
    isEdge,
    isSafari,
  };

  // Proprietary msLaunchUri method (IE 10+ on Windows 8+)
  if (navigator.msLaunchUri) {
    navigator.msLaunchUri(uri, successCallback, noHandlerCallback);
    return;
  }

  // Blur hack (Chrome)
  if (browser.isChrome) {
    blurHandler = function () {
      window.clearTimeout(timeout);
      window.removeEventListener("visibilitychange", blurHandler);
      callback(successCallback);
    };
    timeoutHandler = function () {
      window.removeEventListener("visibilitychange", blurHandler);
      callback(noHandlerCallback);
    };
    window.addEventListener("visibilitychange", blurHandler);
    timeout = window.setTimeout(timeoutHandler, 1000);
    window.location.href = uri;
    return;
  }
  if (browser.isSafari) {
    blurHandler = function () {
      window.clearTimeout(timeout);
      window.removeEventListener("blur", blurHandler);
      callback(successCallback);
    };
    timeoutHandler = function () {
      window.removeEventListener("blur", blurHandler);
      callback(noHandlerCallback);
    };
    window.addEventListener("blur", blurHandler);
    timeout = window.setTimeout(timeoutHandler, 500);
    window.location.href = uri;
    return;
  }
  // Catch NS_ERROR_UNKNOWN_PROTOCOL exception (Firefox)
  if (browser.isFirefox) {
    iframe = createHiddenIframe();
    try {
      // if we're still allowed to change the iframe's location, the protocol is registered
      iframe.contentWindow.location.href = uri;
      callback(successCallback);
    } catch (e) {
      if (e.name === "NS_ERROR_UNKNOWN_PROTOCOL") {
        callback(noHandlerCallback);
      } else {
        callback(unknownCallback);
      }
    } finally {
      removeHiddenIframe();
    }
    return;
  }
  // Open popup, change location, check wether we can access the location after the change (IE on Windows < 8)
  if (browser.isIE) {
    popup = window.open("", "launcher", "width=0,height=0");
    popup.location.href = uri;
    try {
      // Try to change the popup's location - if it fails, the protocol isn't registered
      // and we'll end up in the `catch` block.
      popup.location.href = "about:blank";
      callback(successCallback);
      // The user will be shown a modal dialog to allow the external application. While
      // this dialog is open, we cannot close the popup, so we try again and again until
      // we succeed.
      timer = window.setInterval(function () {
        popup.close();
        if (popup.closed) window.clearInterval(timer);
      }, 500);
    } catch (e) {
      // Regain access to the popup in order to close it.
      popup = window.open("about:blank", "launcher");
      popup.close();
      callback(noHandlerCallback);
    }
    return;
  }
  // No hack we can use, just open the URL in an hidden iframe and invoke `unknownCallback`

  iframe = createHiddenIframe();
  iframe.contentWindow.location.href = uri;
  window.setTimeout(function () {
    removeHiddenIframe(parent);
    callback(unknownCallback);
  }, 500);
}
