import React from "react";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { useDispatch } from "react-redux";
import { ownerOperatorProfileSetFieldValues } from "../../../redux/actions/v2/ownerOperatorProfile";
import OwnerOperatorTruckForm from "./components/OwnerOperatorTruckForm";
import OwnerOperatorProfileForm from "./components/OwnerOperatorProfileForm";
import { useLocation } from "react-router-dom";
import { history } from "../../../history";
import { OWNER_OPERATOR_PROFILE_STEPS } from "./utils/constants";

const OwnerOperatorInfo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const step = new URLSearchParams(location.search).get("step") || "1";
  const onChange = (event) => {
    dispatch(
      ownerOperatorProfileSetFieldValues({
        [event.target.name]: event.target.value,
      }),
    );
  };

  const handleActiveStep = ({
    step = OWNER_OPERATOR_PROFILE_STEPS.PROFILE_STEP,
  }) => {
    history.push(`?step=${step}`);
  };

  return (
    <>
      <FtsContainer>
        <FtsRow mt={{ lg: "4xl", md: "4xl", sm: "4xl", xs: "2xl" }}>
          {step === OWNER_OPERATOR_PROFILE_STEPS.PROFILE_STEP ? (
            <OwnerOperatorProfileForm
              onChange={onChange}
              handleActiveStep={handleActiveStep}
            />
          ) : (
            <OwnerOperatorTruckForm
              onChange={onChange}
              handleActiveStep={handleActiveStep}
            />
          )}
        </FtsRow>
      </FtsContainer>
    </>
  );
};

export default OwnerOperatorInfo;
