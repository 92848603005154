export const COLLECT_ESSENTIALS_REQUEST =
  "ESSENTIALS/COLLECT_ESSENTIALS_REQUEST";
export const COLLECT_ESSENTIALS_SUCCESS =
  "ESSENTIALS/COLLECT_ESSENTIALS_SUCCESS";
export const COLLECT_ESSENTIALS_FAILED = "ESSENTIALS/COLLECT_ESSENTIALS_FAILED";
export const COLLECT_PUBLIC_ESSENTIALS_REQUEST =
  "ESSENTIALS/COLLECT_PUBLIC_ESSENTIALS_REQUEST";
export const COLLECT_PUBLIC_ESSENTIALS_SUCCESS =
  "ESSENTIALS/COLLECT_PUBLIC_ESSENTIALS_SUCCESS";
export const COLLECT_PUBLIC_ESSENTIALS_FAILED =
  "ESSENTIALS/COLLECT_PUBLIC_ESSENTIALS_FAILED";

export const collectEssentialsRequest = () => ({
  type: COLLECT_ESSENTIALS_REQUEST,
});

export const collectEssentialsSuccess = () => ({
  type: COLLECT_ESSENTIALS_SUCCESS,
});

export const collectEssentialsFailed = () => ({
  type: COLLECT_ESSENTIALS_FAILED,
});

export const collectPublicEssentialsRequest = () => ({
  type: COLLECT_PUBLIC_ESSENTIALS_REQUEST,
});

export const collectPublicEssentialsSuccess = () => ({
  type: COLLECT_PUBLIC_ESSENTIALS_SUCCESS,
});

export const collectPublicEssentialsFailed = () => ({
  type: COLLECT_PUBLIC_ESSENTIALS_FAILED,
});
