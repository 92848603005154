import { createSelector } from "reselect";

export const TEMPORARY_LINK_GET_USER_CARDS_REQUEST =
  "TEMPORARY_LINK_CARDS/TEMPORARY_LINK_GET_USER_CARDS_REQUEST";
export const TEMPORARY_LINK_GET_USER_CARDS_SUCCESS =
  "TEMPORARY_LINK_CARDS/TEMPORARY_LINK_GET_USER_CARDS_SUCCESS";
export const TEMPORARY_LINK_GET_USER_CARDS_FAILED =
  "TEMPORARY_LINK_CARDS/TEMPORARY_LINK_GET_USER_CARDS_FAILED";

export const TEMPORARY_LINK_CREATE_USER_CARD_REQUEST =
  "TEMPORARY_LINK_CARDS/TEMPORARY_LINK_CREATE_USER_CARD_REQUEST";
export const TEMPORARY_LINK_CREATE_USER_CARD_SUCCESS =
  "TEMPORARY_LINK_CARDS/TEMPORARY_LINK_CREATE_USER_CARD_SUCCESS";
export const TEMPORARY_LINK_CREATE_USER_CARD_FAILED =
  "TEMPORARY_LINK_CARDS/TEMPORARY_LINK_CREATE_USER_CARD_FAILED";

//SETTERS

export const SET_TEMPORARY_LINK_NEW_CARD_DATA =
  "TEMPORARY_LINK/SET_TEMPORARY_LINK_NEW_CARD_DATA";
export const SET_TEMPORARY_LINK_NEW_CARD_DATA_INVALID_FORM_FIELDS =
  "TEMPORARY_LINK/SET_TEMPORARY_LINK_NEW_CARD_DATA_INVALID_FORM_FIELDS";
export const SET_TEMPORARY_LINK_CLEAR_CARDS_ERRORS =
  "TEMPORARY_LINK/SET_TEMPORARY_LINK_CLEAR_CARDS_ERRORS";

export const initialNewCardData = {
  cardNumber: "",
  cardZipCode: "",
  cardHolderName: "",
  cardCvc: "",
  cardExpirationDate: "",
  isDefault: true,
};
const initialState = {
  isLoading: false,
  error: null,
  cardsData: null,
  newCardData: initialNewCardData,
  newCardInvalidFormFields: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TEMPORARY_LINK_GET_USER_CARDS_REQUEST:
    case TEMPORARY_LINK_CREATE_USER_CARD_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SET_TEMPORARY_LINK_CLEAR_CARDS_ERRORS:
      return {
        ...state,
        error: null,
      };

    case TEMPORARY_LINK_GET_USER_CARDS_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        cardsData: action.payload.data,
      };
    case TEMPORARY_LINK_CREATE_USER_CARD_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case TEMPORARY_LINK_GET_USER_CARDS_FAILED:
    case TEMPORARY_LINK_CREATE_USER_CARD_FAILED:
      return {
        ...state,
        error: action.payload.data,
        isLoading: false,
      };

    case SET_TEMPORARY_LINK_NEW_CARD_DATA:
      return {
        ...state,
        newCardData: action.payload.data,
      };
    case SET_TEMPORARY_LINK_NEW_CARD_DATA_INVALID_FORM_FIELDS:
      return {
        ...state,
        newCardInvalidFormFields: action.payload.data,
      };

    default:
      return state;
  }
}

const selectTemporaryLinkCardsState = (state) => state.temporaryLinkCardsState;

export const selectTemporaryLinkCardsData = createSelector(
  selectTemporaryLinkCardsState,
  (temporaryLinkCardsState) => {
    return temporaryLinkCardsState.cardsData;
  },
);
export const selectIsLoadingTemporaryLinkCardsData = createSelector(
  selectTemporaryLinkCardsState,
  (temporaryLinkCardsState) => {
    return temporaryLinkCardsState.isLoading;
  },
);

export const selectTemporaryLinkNewCardData = createSelector(
  selectTemporaryLinkCardsState,
  (temporaryLinkCardsState) => {
    return temporaryLinkCardsState.newCardData;
  },
);

export const selectTemporaryLinkNewCardInvalidFormFields = createSelector(
  selectTemporaryLinkCardsState,
  (temporaryLinkCardsState) => {
    return temporaryLinkCardsState.newCardInvalidFormFields;
  },
);

export const selectTemporaryLinkCardError = createSelector(
  selectTemporaryLinkCardsState,
  (temporaryLinkCardsState) => {
    return temporaryLinkCardsState.error;
  },
);
