import { call, takeLatest, put, all } from "redux-saga/effects";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";
import { GET_SHARE_PROVIDER_REQUEST } from "../../reducers/v2/shareProvider";
import {
  getShareProviderFailed,
  getShareProviderSuccess,
} from "../../actions/v2/shareProvider";
import { SESSION_STORAGE_KEYS } from "../../../utility/constants";

function* getShareProviderSaga(action) {
  const { onSuccess } = action.payload;
  try {
    const [{ data: shareProviderResponse }, { data: userResponse }] = yield all(
      [
        call(FTSAxiosTemporaryLink.get, "/share-providers"),
        call(FTSAxiosTemporaryLink.get, "/users/me"),
      ],
    );

    yield put(
      getShareProviderSuccess({
        data: {
          shareProviderData: shareProviderResponse.data,
          shareProviderUserData: userResponse.data,
        },
      }),
    );

    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.TEMPORARY_TOKEN_USER_EMAIL,
      userResponse?.data?.email,
    );

    if (typeof onSuccess === "function") {
      onSuccess({
        shareProviderData: shareProviderResponse?.data,
        userData: userResponse?.data,
      });
    }
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(getShareProviderFailed({ data: { message, inlineMessage } }));
  }
}

export default function* shareProviderWatcher() {
  yield takeLatest(GET_SHARE_PROVIDER_REQUEST, getShareProviderSaga);
}
