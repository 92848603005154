import {
  GET_SINGLE_INVOICE_REQUEST,
  GET_SINGLE_INVOICE_FAILED,
  GET_SINGLE_INVOICE_SUCCESS,
  GET_UPGRADE_LISTING_INVOICE_REQUEST,
  GET_UPGRADE_LISTING_INVOICE_SUCCESS,
  GET_UPGRADE_LISTING_INVOICE_FAILED,
} from "../../../reducers/v2/invoices";

export const getSingleInvoiceRequest = (payload) => ({
  type: GET_SINGLE_INVOICE_REQUEST,
  payload,
});

export const getSingleInvoiceSuccess = (payload) => ({
  type: GET_SINGLE_INVOICE_SUCCESS,
  payload,
});

export const getSingleInvoiceFailed = (payload) => ({
  type: GET_SINGLE_INVOICE_FAILED,
  payload,
});

export const getUpgradeListingInvoiceActionRequest = (payload) => ({
  type: GET_UPGRADE_LISTING_INVOICE_REQUEST,
  payload,
});

export const getUpgradeListingInvoiceActionSuccess = (payload) => ({
  type: GET_UPGRADE_LISTING_INVOICE_SUCCESS,
  payload,
});

export const getUpgradeListingInvoiceActionFailed = (payload) => ({
  type: GET_UPGRADE_LISTING_INVOICE_FAILED,
  payload,
});
