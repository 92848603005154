import React from "react";

import { Button } from "reactstrap";

import Icon from "../../../components/fts/Icons";
import "../../../assets/scss/pages/fts-not-authorized.scss";
import FTSCookies, { AUTH_KEY } from "../../../cookies";
import FtsContactFromModalFleet from "../../../components/fts/fts-contact-from-modal-fleet";
import Breakpoint from "../../../components/hoc/Breakpoint";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import logger from "../../../utility/logger";
// import { initGapi } from "../../../utility/googleCalendar";
// import { removeAllCookies } from "../../../cookies";

class NewNotAuthorized extends React.Component {
  state = {
    isLoading: true,
    contactModalOpen: false,
  };

  toggleContactModal = () => {
    this.setState({
      contactModalOpen: !this.state.contactModalOpen,
    });
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    (async () => {
      try {
        let cookie = await FTSCookies.get(AUTH_KEY);
        if (cookie) return this.setState({ isLoading: false });

        window.location.href = "/dashboard/login";
      } catch (e) {
        logger.error(e);
      }
    })();
    // todo this is quick fix check permission gate
    //   removeAllCookies();
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading)
      return (
        <div className="d-flex align-items-center justify-content-center mt-2">
          <FullPageLoader />
        </div>
      );
    return (
      <div className="fts-not-authorized">
        <HeaderWrapper />
        <div className="fts-not-authorized-message">
          <div className="fts-not-authorized-message-box">
            <Breakpoint md up>
              <Icon name="NotAuthorized" size={114} />
            </Breakpoint>
            <Breakpoint sm down>
              <Icon name="NotAuthorized" size={83} />
            </Breakpoint>
            <h3>
              You do not have valid authentication credentials to access the
              requested page.
            </h3>
            <p>Go to your dashboard or contact support.</p>
            <div className="fts-not-authorized-message-box-buttons">
              <Button tag="a" href="/dashboard" color="primary" size="lg">
                Go to Dashboard
              </Button>
              <Button
                color="secondary"
                size="lg"
                onClick={() => this.toggleContactModal()}
              >
                Contact Support
              </Button>
            </div>
          </div>
          {this.state.contactModalOpen && (
            <FtsContactFromModalFleet
              isOpen={true}
              closeModal={this.toggleContactModal}
            />
          )}
        </div>
      </div>
    );
  }
}
export default NewNotAuthorized;
