import { createSelector } from "reselect";

export const CONVERT_TO_VENDOR_REQUEST =
  "AUTHENTICATION/CONVERT_TO_VENDOR_REQUEST";
export const CONVERT_TO_VENDOR_SUCCESS =
  "AUTHENTICATION/CONVERT_TO_VENDOR_SUCCESS";
export const CONVERT_TO_VENDOR_FAILED =
  "AUTHENTICATION/CONVERT_TO_VENDOR_FAILED";
export const COUNT_SUB_USERS_REQUEST = "AUTHENTICATION/COUNT_SUB_USERS_REQUEST";
export const COUNT_SUB_USERS_SUCCESS = "AUTHENTICATION/COUNT_SUB_USERS_SUCCESS";
export const COUNT_SUB_USERS_FAILED = "AUTHENTICATION/COUNT_SUB_USERS_FAILED";

export const VERIFY_EMAIL_ADDRESS_REQUEST =
  "AUTHENTICATION/VERIFY_EMAIL_ADDRESS_REQUEST";
export const VERIFY_EMAIL_ADDRESS_SUCCESS =
  "AUTHENTICATION/VERIFY_EMAIL_ADDRESS_SUCCESS";
export const VERIFY_EMAIL_ADDRESS_FAILED =
  "AUTHENTICATION/VERIFY_EMAIL_ADDRESS_FAILED";

export const RESEND_VERIFY_EMAIL_ADDRESS_REQUEST =
  "AUTHENTICATION/RESEND_VERIFY_EMAIL_ADDRESS_REQUEST";
export const RESEND_VERIFY_EMAIL_ADDRESS_SUCCESS =
  "AUTHENTICATION/RESEND_VERIFY_EMAIL_ADDRESS_SUCCESS";
export const RESEND_VERIFY_EMAIL_ADDRESS_FAILED =
  "AUTHENTICATION/RESEND_VERIFY_EMAIL_ADDRESS_FAILED";

export const UPDATE_USER_REQUEST = "AUTHENTICATION/UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "AUTHENTICATION/UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "AUTHENTICATION/UPDATE_USER_FAILED";

export const SET_USER_DATA = "AUTHENTICATION/SET_USER_DATA";
export const SET_INVALID_FORM_FIELDS = "AUTHENTICATION/SET_INVALID_FORM_FIELDS";

export const GET_USER_TYPES_REQUEST = "AUTHENTICATION/GET_USER_TYPES_REQUEST";
export const GET_USER_TYPES_SUCCESS = "AUTHENTICATION/GET_USER_TYPES_SUCCESS";
export const GET_USER_TYPES_FAILED = "AUTHENTICATION/GET_USER_TYPES_FAILED";

export const INSERT_USER_PERMISSIONS_REQUEST =
  "AUTHENTICATION/INSERT_USER_PERMISSIONS_REQUEST";

export const SEND_REACTIVATE_ACCOUNT_EMAIL_REQUEST =
  "AUTHENTICATION/SEND_REACTIVATE_ACCOUNT_EMAIL_REQUEST";

const initialState = {
  isLoading: false,
  error: null,
  inlineMessage: null,
  loginData: null,
  user: null,
  invalidFormFields: null,
  accountTypes: null,
};

// SET_USER_DATA

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        user: action.payload.data,
      };

    case VERIFY_EMAIL_ADDRESS_REQUEST:
    case COUNT_SUB_USERS_REQUEST:
    case CONVERT_TO_VENDOR_REQUEST:
    case RESEND_VERIFY_EMAIL_ADDRESS_REQUEST:
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        inlineMessage: null,
        invalidFormFields: null,
      };
    case VERIFY_EMAIL_ADDRESS_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
        isLoading: false,
      };
    case COUNT_SUB_USERS_SUCCESS:
    case CONVERT_TO_VENDOR_SUCCESS:
    case RESEND_VERIFY_EMAIL_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload.data,
      };
    case COUNT_SUB_USERS_FAILED:
    case CONVERT_TO_VENDOR_FAILED:
    case VERIFY_EMAIL_ADDRESS_FAILED:
    case UPDATE_USER_FAILED:
    case RESEND_VERIFY_EMAIL_ADDRESS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data.message,
        inlineMessage: action.payload.data.inlineMessage,
      };

    case SET_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: action.payload.data,
      };
    case GET_USER_TYPES_FAILED:
    case GET_USER_TYPES_SUCCESS:
      return {
        ...state,
        accountTypes: action.payload.data,
      };

    default:
      return state;
  }
}

const selectAuthState = (state) => state.authState;

export const selectAuthIsLoading = createSelector(
  selectAuthState,
  (authState) => {
    return authState.isLoading;
  },
);

export const selectAuthError = createSelector(selectAuthState, (authState) => {
  return authState.error;
});

export const selectAuthInlineMessage = createSelector(
  selectAuthState,
  (authState) => {
    return authState.inlineMessage;
  },
);

export const selectUserData = createSelector(selectAuthState, (authState) => {
  return authState.user;
});

export const selectHasUserData = createSelector(
  selectAuthState,
  (authState) => {
    return !!authState.user;
  },
);

export const selectUserName = createSelector(selectAuthState, (authState) => {
  return authState.user?.firstName && authState.user.lastName
    ? `${authState.user.firstName} ${authState.user.lastName[0]}`
    : null;
});

export const selectUserPermissions = createSelector(
  selectAuthState,
  (authState) => {
    return authState.user?.userPermissions;
  },
);
export const selectUserSubPermissions = createSelector(
  selectAuthState,
  (authState) => {
    return authState.user?.userSubPermissions;
  },
);

export const selectInvalidFormFields = createSelector(
  selectAuthState,
  (authState) => {
    return authState.invalidFormFields;
  },
);

export const selectAccountTypes = createSelector(
  selectAuthState,
  (authState) => {
    return authState.accountTypes;
  },
);
