import { useCallback } from "react";
import { history } from "../../history";
import { useSelector } from "react-redux";
import { LISTING_STATUSES } from "../../constants";
import {
  selectTemporaryLinkListingPreviewData,
  selectTemporaryLinkListingProofStatus,
} from "../../redux/reducers/v2/temporaryListingLink";
import { TemporaryLinkPages } from "../../views/constants/pages";
import { FREE_LISTING_PRICE_ID } from "@find-truck-service/ui/src/components/react/ListingItem/constants";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";

export const useTemporaryLinkRedirectToPreviewHandler = () => {
  const proofStatus = useSelector(selectTemporaryLinkListingProofStatus);
  const listingData = useSelector(selectTemporaryLinkListingPreviewData);

  const isFreeNotDraft = listingData?.status !== LISTING_STATUSES.DRAFT;
  const isFreeListing = listingData?.priceId === FREE_LISTING_PRICE_ID;
  const isFreeCompletedListing = isFreeListing && isFreeNotDraft;
  const isProofActive = proofStatus === PROOF_STATUSES_ENUM.PENDING;

  return useCallback(() => {
    if (isFreeCompletedListing)
      return history.replace(TemporaryLinkPages.FREE_LISTING_PREVIEW);

    if (isProofActive) return history.replace(TemporaryLinkPages.PROOF_PREVIEW);

    return history.replace(TemporaryLinkPages.LISTING_PREVIEW);
  }, [isProofActive, isFreeCompletedListing]);
};
