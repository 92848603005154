import React from "react";
import styled from "styled-components";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { InputVariants } from "@find-truck-service/types/ui/input";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";

export const SpecialHoursColumn = (props) => {
  const { listingData, onChange, handleToggle } = props;

  return (
    <SpecialHoursStyledColumn>
      <FtsColumn size={{ xs: 6 }} mb={"lg"}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          SPECIAL HOURS
        </Span>
      </FtsColumn>
      <FtsRow columnGap={"lg"} rowGap={"lg"}>
        <FtsColumn size={{ xs: 12 }}>
          <Checkbox
            size={CheckboxSizes.md}
            label={"24 hour Towing & Recovery"}
            checked={listingData.towingRecoveryOpen}
            onClick={() => handleToggle("towingRecoveryOpen")}
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }}>
          <Checkbox
            size={CheckboxSizes.md}
            label={"24 hour Mobile/Road Service"}
            checked={listingData.mobileRoadServiceOpen}
            onClick={() => handleToggle("mobileRoadServiceOpen")}
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }}>
          <Input
            size={"md"}
            onChange={onChange}
            name={"specialHours"}
            label={"Special hours"}
            variant={InputVariants.outlined}
            value={listingData.specialHours}
          />
        </FtsColumn>
      </FtsRow>
    </SpecialHoursStyledColumn>
  );
};

const SpecialHoursStyledColumn = styled(FtsColumn).attrs({
  size: { md: 12, lg: 6 },
  order: { xs: 1, sm: 1, md: 1, lg: 2 },
})``;
