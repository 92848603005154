import React, { PureComponent } from "react";
import { urlRegex } from "../../../utility/url-validator";
import linkifyHtml from "linkifyjs/html";

class FormikInputValue extends PureComponent {
  createMarkup = () => {
    const text = this.props.children || "";
    const __html = text;

    const isUrl = urlRegex.test(__html);

    if (isUrl) {
      return {
        __html: linkifyHtml(__html, {
          defaultProtocol: "https",
          target: "_blank",
        }),
      };
    }

    return { __html };
  };

  render() {
    const { className } = this.props;
    return (
      <div
        id={this.props.formikInputValueId || ""}
        className={"formik-input-value " + className}
        dangerouslySetInnerHTML={this.createMarkup()}
      ></div>
    );
  }
}

export default FormikInputValue;
