export const stepsDesktop = [
  { label: "Listing", id: 1 },
  { label: "Preview", id: 2 },
  { label: "Payment", id: 3 },
];
export const stepsMobile = [
  { label: "Business Listing", id: 1 },
  { label: "Listing Image (optional)", id: 2 },
  { label: "Preview Listing", id: 3 },
  { label: "Payment Overview", id: 4 },
];
