export var USER_STATUS_ENUM;
(function (USER_STATUS_ENUM) {
    USER_STATUS_ENUM[USER_STATUS_ENUM["DISABLED"] = 0] = "DISABLED";
    USER_STATUS_ENUM[USER_STATUS_ENUM["ACTIVE"] = 1] = "ACTIVE";
    USER_STATUS_ENUM[USER_STATUS_ENUM["DEACTIVATED"] = 3] = "DEACTIVATED";
})(USER_STATUS_ENUM || (USER_STATUS_ENUM = {}));
export var USER_TYPES_ENUM;
(function (USER_TYPES_ENUM) {
    USER_TYPES_ENUM[USER_TYPES_ENUM["FLEET_CARRIER"] = 1] = "FLEET_CARRIER";
    USER_TYPES_ENUM[USER_TYPES_ENUM["OWNER_OPERATOR"] = 2] = "OWNER_OPERATOR";
    USER_TYPES_ENUM[USER_TYPES_ENUM["SERVICE_VENDOR"] = 3] = "SERVICE_VENDOR";
    USER_TYPES_ENUM[USER_TYPES_ENUM["COMPANY_DRIVER"] = 4] = "COMPANY_DRIVER";
    USER_TYPES_ENUM[USER_TYPES_ENUM["OTHER"] = 5] = "OTHER";
})(USER_TYPES_ENUM || (USER_TYPES_ENUM = {}));
export var USER_TYPE_NAMED_ENUM;
(function (USER_TYPE_NAMED_ENUM) {
    USER_TYPE_NAMED_ENUM["VENDOR"] = "vendor";
    USER_TYPE_NAMED_ENUM["USER"] = "user";
})(USER_TYPE_NAMED_ENUM || (USER_TYPE_NAMED_ENUM = {}));
export var USER_ROLES_ENUM;
(function (USER_ROLES_ENUM) {
    //main account roles
    USER_ROLES_ENUM[USER_ROLES_ENUM["SUPER_ADMIN"] = 1] = "SUPER_ADMIN";
    // sub account roles
    USER_ROLES_ENUM[USER_ROLES_ENUM["ADMIN"] = 2] = "ADMIN";
    USER_ROLES_ENUM[USER_ROLES_ENUM["USER"] = 3] = "USER";
})(USER_ROLES_ENUM || (USER_ROLES_ENUM = {}));
export var EMAIL_STATUS_ENUM;
(function (EMAIL_STATUS_ENUM) {
    EMAIL_STATUS_ENUM[EMAIL_STATUS_ENUM["ACTIVE"] = 1] = "ACTIVE";
    EMAIL_STATUS_ENUM[EMAIL_STATUS_ENUM["DISABLED"] = 2] = "DISABLED";
})(EMAIL_STATUS_ENUM || (EMAIL_STATUS_ENUM = {}));
