import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
export const FtsDrawerLinksContainer = styled(FtsWrapper) `
  flex: 1;
  display: flex;
  overflow: scroll;
  gap: ${Spacing["3xs"]};
  padding: ${Spacing.sm};
  flex-direction: column;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
