import React, { Fragment } from "react";
import { format, isBefore, differenceInMinutes } from "date-fns";
import formatShortHours from "../../../utility/formatShortHours";

export const getStoreStatus = ({
  currentHours,
  listingCurrentTime,
  specialHours,
  mobileRoadServiceOpen,
  towingRecoveryOpen,
}) => {
  if (!currentHours) return null;

  const hasSpecialHours =
    specialHours || mobileRoadServiceOpen || towingRecoveryOpen;

  const isClosed =
    currentHours.trim() === "-" ||
    currentHours.toLowerCase().trim() === "closed -" ||
    currentHours.toLowerCase() === "closed";

  const is24Hours = currentHours.toLowerCase().trim().startsWith("24 hrs");
  const isByAppointment =
    currentHours.toLowerCase().trim() === "by appointment -";

  let currentDate = format(new Date(), "P");
  let currentDateEnd = format(new Date(), "P");

  const [openHours, closeHours] = currentHours.split("-");
  const formatOpenHours = formatShortHours(openHours);
  const formatCloseHours = formatShortHours(closeHours);
  const isAM = (str) => str.indexOf("AM") !== -1;

  const isCloseBeforeStart = isBefore(
    new Date(`${currentDate} ${formatCloseHours}`),
    new Date(`${currentDate} ${formatOpenHours}`),
  );

  if (isAM(formatCloseHours) && isCloseBeforeStart) {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    currentDateEnd = format(date, "P");
  }

  const isTimeAfterClosedHours = isBefore(
    new Date(`${currentDate} ${formatCloseHours}`),
    new Date(listingCurrentTime),
  );

  const isTimeAfterOpenHours = isBefore(
    new Date(`${currentDate} ${formatOpenHours}`),
    new Date(listingCurrentTime),
  );

  const isTimeBeforeClosedHours = isBefore(
    new Date(listingCurrentTime),
    new Date(`${currentDateEnd} ${formatCloseHours}`),
  );

  const hoursClosed = isBefore(
    new Date(listingCurrentTime),
    new Date(`${currentDate} ${formatOpenHours}`),
  );

  const isDayAdded = isBefore(
    new Date(`${currentDate}`),
    new Date(`${currentDateEnd}`),
  );

  if (isTimeAfterOpenHours && isDayAdded) {
    return (
      <Fragment>
        <span>
          {`Hours (`}
          <span style={{ color: "#128548" }}>Open</span>
          {`)`}
        </span>
      </Fragment>
    );
  }

  if (isTimeAfterOpenHours && isTimeBeforeClosedHours) {
    const hoursDiffrence = differenceInMinutes(
      new Date(`${currentDateEnd} ${formatCloseHours}`),
      new Date(listingCurrentTime),
    );

    const isClosingSoon = hoursDiffrence <= 60;

    if (isClosingSoon && !hasSpecialHours) {
      return (
        <Fragment>
          {`Hours (`}
          <span style={{ color: "#F5B053" }}>Closing Soon</span>
          {`)`}
        </Fragment>
      );
    }

    if (
      isClosingSoon &&
      specialHours &&
      !towingRecoveryOpen &&
      !mobileRoadServiceOpen
    ) {
      return (
        <Fragment>
          <span>
            {`Hours (`}
            <span style={{ color: "#128548" }}>Special hours</span>
            {`)`}
          </span>
        </Fragment>
      );
    }
  }

  if (isTimeAfterOpenHours) {
    const hoursDiffrence = differenceInMinutes(
      new Date(`${currentDateEnd} ${formatCloseHours}`),
      new Date(listingCurrentTime),
    );

    const closed = hoursDiffrence <= 0;

    if (closed && !hasSpecialHours) {
      return (
        <Fragment>
          {`Hours (`}
          <span style={{ color: "#CE1B3B" }}>Closed</span>
          {`)`}
        </Fragment>
      );
    }
  }

  if (isClosed && !hasSpecialHours) {
    return (
      <Fragment>
        {`Hours (`}
        <span style={{ color: "#CE1B3B" }}>Closed</span>
        {`)`}
      </Fragment>
    );
  }

  if (is24Hours) {
    return (
      <Fragment>
        <span>
          {`Hours (`}
          <span style={{ color: "#128548" }}>Open</span>
          {`)`}
        </span>
      </Fragment>
    );
  }

  if (specialHours && (isClosed || isTimeAfterClosedHours || hoursClosed)) {
    return (
      <Fragment>
        <span>
          {`Hours (`}
          <span style={{ color: "#128548" }}>Special hours</span>
          {`)`}
        </span>
      </Fragment>
    );
  }

  if (isByAppointment) {
    return (
      <Fragment>
        <span>
          {`Hours (`}
          <span style={{ color: "#128548" }}>Special hours</span>
          {`)`}
        </span>
      </Fragment>
    );
  }

  if (mobileRoadServiceOpen || towingRecoveryOpen) {
    return (
      <Fragment>
        <span>
          {`Hours (`}
          <span style={{ color: "#128548" }}>Open</span>
          {`)`}
        </span>
      </Fragment>
    );
  }

  if (isTimeAfterClosedHours) {
    return (
      <Fragment>
        {`Hours (`}
        <span style={{ color: "#CE1B3B" }}>Closed</span>
        {`)`}
      </Fragment>
    );
  }

  if (hoursClosed) {
    return (
      <Fragment>
        {`Hours (`}
        <span style={{ color: "#CE1B3B" }}>Closed</span>
        {`)`}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <span>
        {`Hours (`}
        <span style={{ color: "#128548" }}>Open</span>
        {`)`}
      </span>
    </Fragment>
  );
};
