import React from "react";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { Span } from "../../../FtsText";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import styled from "styled-components";
const MessageIcon = {
    info: "Info",
    success: "Check",
    warning: "AlertTriangle",
    error: "AlertOctagon",
};
const MessageInlineColor = {
    info: ColorsValue["blue-accent-500"],
    success: ColorsValue["green-success-500"],
    warning: ColorsValue["yellow-warning-800"],
    error: ColorsValue["red-error-500"],
};
const MessageInlineTextVariants = {
    sm: VariantsValues["text-3xs"],
    md: VariantsValues["text-2xs"],
};
const IconWrapper = styled(FtsWrapper) `
  background-color: ${(props) => MessageInlineColor[props.type]};
  border-radius: ${Spacing["4xxl"]};
  padding: ${Spacing["3xs"]};
  display: inline-flex;
  width: fit-content;
  height: fit-content;
  svg {
    width: ${(props) => SvgIconSizeValues[props.size]};
    height: ${(props) => SvgIconSizeValues[props.size]};
  }
`;
export const MessageInline = (props) => {
    return (React.createElement(FtsWrapper, { display: "flex", gap: "sm", align: "center" },
        React.createElement(IconWrapper, { ...props },
            React.createElement(FtsIcons, { iconName: MessageIcon[props.type] })),
        React.createElement(Span, { variant: MessageInlineTextVariants[props.size], color: MessageInlineColor[props.type] }, props.message)));
};
