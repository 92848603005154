import {
  GENERATE_USER_LOGIN_MFA_REQUEST,
  TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_REQUEST,
  TEMPORARY_TOKEN_VERIFY_USER_MFA_REQUEST,
} from "../../reducers/v2/userMfas";
import { call, takeLatest, put, all, take } from "redux-saga/effects";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";
import {
  generateUserLoginMfaSuccess,
  temporaryTokenResendVerifuUserMfaFailed,
  temporaryTokenResendVerifuUserMfaSuccess,
  temporaryTokenVerifyUserMfaFailed,
  temporaryTokenVerifyUserMfaSuccess,
} from "../../actions/v2/userMfas";
import { setLoggedUserCookie } from "../../../cookies";
import { setUserData } from "../../actions/v2/auth";
import {
  COLLECT_ESSENTIALS_SUCCESS,
  collectEssentialsRequest,
} from "../../actions/v2/essentials";
import { setShareProviderData } from "../../actions/v2/shareProvider";
import { UNEXPECTED_ISSUE } from "../../../utility/errorMessages";

function* generateUserLoginMfaRequestSaga(action) {
  const { onError } = action.payload;
  try {
    const GENERATE_AND_SEND_MFA_CODE_URL =
      "/users-mfas/generate-and-send-temporary-token-login-mfa";
    yield call(FTSAxiosTemporaryLink.get, GENERATE_AND_SEND_MFA_CODE_URL);

    yield put(generateUserLoginMfaSuccess());
  } catch (e) {
    const inlineMessage = e?.response?.data?.inlineMessage || UNEXPECTED_ISSUE;
    if (typeof onError === "function") onError({ inlineMessage });
  }
}

function* temporaryTokenVerifyUserMfaRequestSaga(action) {
  const { data: payloadData, onSuccess, onError } = action.payload;
  try {
    const TEMP_LINK_USER_MFA_VERIFICATION_URL =
      "/users-mfas/temporary-token-verification";

    const response = yield call(
      FTSAxiosTemporaryLink.post,
      TEMP_LINK_USER_MFA_VERIFICATION_URL,
      { code: payloadData.code },
    );

    const { user, bearerToken } = response.data.data;
    setLoggedUserCookie(user, bearerToken);

    yield all([
      put(setShareProviderData({ data: null })),
      put(collectEssentialsRequest()),
      put(temporaryTokenVerifyUserMfaSuccess()),
      take(COLLECT_ESSENTIALS_SUCCESS),
    ]);
    yield put(setUserData({ data: user }));

    if (typeof onSuccess === "function") onSuccess();
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(
      temporaryTokenVerifyUserMfaFailed({
        data: { message, inlineMessage },
      }),
    );
    if (typeof onError === "function") onError({ message, inlineMessage });
  }
}

function* temporaryTokenResendVerificationCodeRequestSaga() {
  try {
    const RESEND_VERIFY_CODE_URL =
      "/users-mfas/resend-temporary-token-verification";
    yield call(FTSAxiosTemporaryLink.get, RESEND_VERIFY_CODE_URL);

    yield put(temporaryTokenResendVerifuUserMfaSuccess());
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(
      temporaryTokenResendVerifuUserMfaFailed({
        data: { message, inlineMessage },
      }),
    );
  }
}

export default function* userMfasWatcher() {
  yield takeLatest(
    GENERATE_USER_LOGIN_MFA_REQUEST,
    generateUserLoginMfaRequestSaga,
  );
  yield takeLatest(
    TEMPORARY_TOKEN_VERIFY_USER_MFA_REQUEST,
    temporaryTokenVerifyUserMfaRequestSaga,
  );

  yield takeLatest(
    TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_REQUEST,
    temporaryTokenResendVerificationCodeRequestSaga,
  );
}
