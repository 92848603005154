import React from "react";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import {
  Message,
  messageTypes,
} from "@find-truck-service/ui/src/components/react/Message";

export const ListingImagesBanner = () => {
  return (
    <FtsColumn size={{ xs: 12 }} mt={"sm"}>
      <Message
        type={messageTypes.warning}
        subtitle="Max 3 files, 15MB per file (PNG, JPG, PSD, GIF, PDF, AI)"
        title="Using your uploaded files, we’ll design your listing image and email
            you the proof. If you don’t provide any files, we’ll contact you
            after your listing is posted to get the details to finish your free
            image design."
      />
    </FtsColumn>
  );
};
