import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { isObject } from "lodash";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import { PhoneNumberInput } from "@find-truck-service/ui/src/components/react/PhoneNumberInput";
import ProfileButtonsMd from "./ProfileButtonsMd";
import ProfileButtonsXs from "./ProfileButtonsXs";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectStates } from "../../../../redux/reducers/essentialLists";
import { selectUserData } from "../../../../redux/reducers/v2/auth";
import {
  selectOwnerOperatorProfileData,
  selectOwnerOperatorProfileInvalidFormFields,
} from "../../../../redux/reducers/v2/owner-operator-profile";
import { toast } from "react-toastify";
import { ownerOperatorProfileValidator } from "../validators/ownerOperatorProfileValidator";
import {
  ownerOperatorProfileSetFinishLaterLoading,
  ownerOperatorProfileSetInvalidFormFields,
  ownerOperatorProfileSetUpdateLoading,
  ownerOperatorProfileUpdateRequest,
} from "../../../../redux/actions/v2/ownerOperatorProfile";
import { USER_TYPE } from "../../../../constants";
import { OWNER_OPERATOR_PROFILE_STEPS } from "../utils/constants";
import { ZipCodeInput } from "@find-truck-service/ui/src/components/react/ZipCodeInput";
import logger from "../../../../utility/logger";

const OwnerOperatorProfileForm = (props) => {
  const dispatch = useDispatch();
  const states = useSelector(selectStates);
  const userData = useSelector(selectUserData);
  const ownerOperatorProfileData = useSelector(selectOwnerOperatorProfileData);
  const invalidFormFields = useSelector(
    selectOwnerOperatorProfileInvalidFormFields,
  );

  const onUpdateOwnerOperatorSuccess = () => {
    props.handleActiveStep({ step: OWNER_OPERATOR_PROFILE_STEPS.TRUCK_STEP });
  };

  const onUpdateOwnerOperatorFailure = (e) => {
    const message = e.response
      ? e.response.data.message
      : "Error while updating user data";

    toast.error(message);
  };

  const handleUpdateOwnerOperator = useCallback(async () => {
    try {
      await ownerOperatorProfileValidator.validate(
        ownerOperatorProfileData || {},
      );

      dispatch(
        ownerOperatorProfileSetInvalidFormFields({
          data: null,
        }),
      );
      dispatch(ownerOperatorProfileSetUpdateLoading({ data: true }));
      dispatch(
        ownerOperatorProfileUpdateRequest({
          data: {
            ...ownerOperatorProfileData,
            onboardingFinished: true,
            id: userData.id,
            userType: USER_TYPE.OWNER_OPERATOR,
            userRole: userData.userRole,
          },
          onSuccess: onUpdateOwnerOperatorSuccess,
          onError: onUpdateOwnerOperatorFailure,
        }),
      );
    } catch (e) {
      logger.error(e);
      if (e.name === "ValidationError") {
        dispatch(
          ownerOperatorProfileSetInvalidFormFields({
            data: {
              [e.path]: e.errors[0],
            },
          }),
        );
      }
    }
  });
  const handleOwnerOperatorFinishLater = useCallback(async () => {
    try {
      dispatch(
        ownerOperatorProfileSetInvalidFormFields({
          data: null,
        }),
      );

      dispatch(ownerOperatorProfileSetFinishLaterLoading({ data: true }));

      dispatch(
        ownerOperatorProfileUpdateRequest({
          data: {
            ...ownerOperatorProfileData,
            onboardingFinished: true,
            id: userData.id,
            userType: USER_TYPE.OWNER_OPERATOR,
            userRole: userData.userRole,
            type: USER_TYPE.COMPANY_DRIVER,
          },
          onSuccess: onUpdateOwnerOperatorSuccess,
          onError: onUpdateOwnerOperatorFailure,
        }),
      );
    } catch (e) {
      const message = e.response
        ? e.response.data.message
        : "Error while updating user data";

      toast.error(message);
    }
  });

  return (
    <FtsColumn
      size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
      offset={{ xs: 0, sm: 0, md: 0, lg: 3 }}
      rowGap={"2xl"}
      columnGap={"2xl"}
    >
      <FtsWrapper
        p={{
          xs: "2xl",
          md: "4xl",
          lg: "4xl",
        }}
        bg={ColorsValue["greyscale-0"]}
        boxShadow="sm"
        borderRadius="2xs"
      >
        <FtsColumn
          size={{ xs: 12, md: 12, lg: 12 }}
          rowGap={"2xl"}
          columnGap={"2xl"}
        >
          <Span
            variant={VariantsValues["text-md"]}
            color={ColorsValue["greyscale-800"]}
            weight={WeightSize.bold}
          >
            Business Info
          </Span>
        </FtsColumn>

        <FtsRow rowGap={"2xl"} columnGap={"lg"} mt={"4xl"}>
          <FtsColumn size={{ xs: 12, md: 12, lg: 12 }} rowGap={"2xl"}>
            <Input
              name={"companyName"}
              required
              value={ownerOperatorProfileData?.companyName}
              onChange={props.onChange}
              label={"Company / DBA name"}
              variant={InputVariants.outlined}
              size={InputSizes.md}
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["companyName"]
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12, md: 12, lg: 12 }} rowGap={"2xl"}>
            <Input
              name={"address"}
              required
              value={ownerOperatorProfileData?.address}
              onChange={props.onChange}
              label={"Street"}
              variant={InputVariants.outlined}
              size={InputSizes.md}
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["address"]
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
            <Input
              name={"city"}
              required
              value={ownerOperatorProfileData?.city}
              onChange={props.onChange}
              label={"City"}
              variant={InputVariants.outlined}
              size={InputSizes.md}
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["city"]
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 6, md: 3, lg: 3 }}>
            <Dropdown
              required
              size={DropdownSizesValues.md}
              variant={DropdownVariantsValues.outlined}
              name={"state"}
              label={"State"}
              options={states}
              placeholder={""}
              value={states.find(
                (e) => e.label === ownerOperatorProfileData?.state,
              )}
              errorMessage={invalidFormFields?.["state"]}
              onChange={(val) =>
                props.onChange({
                  target: { name: "state", value: val?.label || "" },
                })
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 6, md: 3, lg: 3 }}>
            <ZipCodeInput
              name={"zip"}
              required
              value={ownerOperatorProfileData?.zip}
              onChange={props.onChange}
              label={"Zip Code"}
              variant={InputVariants.outlined}
              size={InputSizes.md}
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["zip"]
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
            <PhoneNumberInput
              name={"phoneNumber"}
              required
              value={ownerOperatorProfileData?.phoneNumber}
              onChange={props.onChange}
              label={"Phone (primary)"}
              variant={InputVariants.outlined}
              size={InputSizes.md}
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["phoneNumber"]
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
            <Input
              name={"referal"}
              required
              value={ownerOperatorProfileData?.referal}
              onChange={props.onChange}
              label={"How did you hear about us?"}
              variant={InputVariants.outlined}
              size={InputSizes.md}
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["referal"]
              }
            />
          </FtsColumn>
        </FtsRow>
        <ProfileButtonsMd
          handleUpdateOwnerOperator={handleUpdateOwnerOperator}
          handleOwnerOperatorFinishLater={handleOwnerOperatorFinishLater}
        />
      </FtsWrapper>
      <ProfileButtonsXs
        handleUpdateOwnerOperator={handleUpdateOwnerOperator}
        handleOwnerOperatorFinishLater={handleOwnerOperatorFinishLater}
      />
    </FtsColumn>
  );
};

export default OwnerOperatorProfileForm;
