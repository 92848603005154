export const promotionsTypes = {
  NATIONAL: "national",
  LISTING: "listing",
  ACCOUNT: "account",
  GENERAL: "general",
};

export const NOTIFICATION = {
  title: "Love's truck care",
  icon: "Notification",
  description:
    "100 FYX (TRAC Interstar) listings are due to renewal on June 1,2020 - Email",
  linkTitle: "View todo",
  link: "/listings/439909?toDoId=123",
  notification: "You were added as a participant on this Todo:",
};

export const activities = {
  changeTodoType: `<span>{{admin_name}} changed todo type from <span class="fts-badge-inline">{{from_type}}</span> to <span class="fts-badge-inline">{{to_type}}</span></span>`,
  callLog: `<p class="mb-0">{{admin_name}} CALLED <span class="contacted-person">{{contacted_persons}}</span></p>`,
  voidInvoice: `<p class="mb-0">{{admin_name}} VOIDED invoice #<span class="voided-invoice">{{invoiceId}}</span></p>`,
  emailLog: `<p class="mb-0">{{admin_name}} SENT AN E-MAIL TO <span class="contacted-person">{{emails}}</span></p>`,
  convertAccount: `<p class="mb-0">{{admin_name}} #{{user_id}} LINKED this account, to account <a href="{{href}}" class="account-link">{{toAccount}}</a></p>`,
  convertListing: `<p class="mb-0">{{admin_name}} REASSIGNED this listing from account <a href="{{fromHref}}" class="account-link">{{fromAccount}}</a> to account <a href="{{toHref}}" class="account-link">{{toAccount}}</a></p>`,
  removeListing: `<p class="mb-0">{{admin_name}} #{{user_id}} DELETED listing ID #{{listingId}}</p>`,
  removeAccount: `<p class="mb-0">{{admin_name}} DELETE this account </p>`,
  enableAccount: `<p class="mb-0">{{admin_name}} ENABLE this account </p>`,
  enableListing: `<p class="mb-0">{{admin_name}} ENABLE this listing </p>`,
  assignedAccountUser: `<p class="mb-0">{{admin_name}} ASSIGNED this account to {{assigned_admin}}</p>`,
  unassignedAccountUser: `<p class="mb-0">{{admin_name}} UN-ASSIGNED this account from {{assigned_admin}}</p>`,
  ////////////////////////
  upgradeToBasic: `<p class="mb-0">{{admin_name}} UPGRADE listing #{{listing_id}} from {{oldType}} to Basic</p>`,
  upgradeToBasicPlus: `<p class="mb-0">{{admin_name}} UPGRADE listing #{{listing_id}} from {{oldType}} to Basic Plus</p>`,
  upgradeToStandard: `<p class="mb-0">{{admin_name}} UPGRADE listing #{{listing_id}} from {{oldType}} to Standard</p>`,
  upgradeToPremium: `<p class="mb-0">{{admin_name}} UPGRADE listing #{{listing_id}} from {{oldType}} to Premium</p>`,
  upgradeToPayingListing: `<p class="mb-0">{{admin_name}} UPGRADED #{{listingId}} from free to paying listing</p>`,
  ////////////////////////
  revertListingUpdate: `<p class="mb-0">{{admin_name}} REVERTED listing update to previous state </p>`,
  revertAccountUpdate: `<p class="mb-0">{{admin_name}} REVERTED account update to previous state </p>`,
  ////////////////////////
  downgradeToFree: `<p class="mb-0">{{admin_name}} DOWNGRADE listing #{{listing_id}} from {{oldType}} to Free</p>`,
  downgradeToBasic: `<p class="mb-0">{{admin_name}} DOWNGRADE listing #{{listing_id}} from {{oldType}} to Basic</p>`,
  downgradeToBasicPlus: `<p class="mb-0">{{admin_name}} DOWNGRADE listing #{{listing_id}} from {{oldType}} to Basic Plus</p>`,
  downgradeToStandard: `<p class="mb-0">{{admin_name}} DOWNGRADE listing #{{listing_id}} from {{oldType}} to Standard</p>`,
  createPayingListing: `<p class="mb-0">{{admin_name}} #{{user_id}} added a new paid listing #{{listingId}} for {{companyName}} in {{city}}, {{state}}.</p>`,
  createFreeListing: `<p class="mb-0">{{admin_name}} #{{user_id}} ({{user_type/sub-user}}) added a new free listing #{{listingId}} for {{companyName}} in {{city}}, {{state}}.</p>`,
  ////////////////////////
  createAccount: `<p class="mb-0">{{vendor_name}} #{{user_id}} created account for {{email}} from website</p>`,
  setAccountStatus: `<p class="mb-0">Account #{{accountId}} status set to {{status}}</p>`,
  signedUpGoogle: `<p class="mb-0">{{vendor_name}} signed up with Google</p>`,
  updateAccount: `<p class="mb-0">{{vendor_name}} #{{user_id}} ({{user_type/sub-user}}) updated the account.</p>`,
  addedSubAccount: `<p class="mb-0">{{vendor_name}} ({{user_type/sub-user}}) added a sub-account.</p>`,
  savedListingToDraft: `<p class="mb-0">{{vendor_name}} #{{user_id}} ({{user_type/sub-user}}) saved listing to draft.</p>`,
  referredAFriend: `<p class="mb-0">{{vendor_name}} #{{user_id}} ({{user_type/sub-user}}) referred a friend {{email}}.</p>`,
  createAccountTodo: `<p class="mb-0">{{admin_name}} created an <span class=" small-note-label badge badge-secondary rounded-pill" style="background-color: rgb(178, 178, 179);" >{{todoType}}</span> account todo</p>`,
  createAccountNote: `<p class="mb-0">{{admin_name}} posted a <span class=" small-note-label badge badge-secondary rounded-pill" style="background-color: rgb(178, 178, 179);" >{{noteType}}</span> account note</p>`,
  createStickyNote: `<p class="mb-0">{{admin_name}} posted a <span class=" small-note-label badge badge-secondary rounded-pill" style="background-color: rgb(178, 178, 179);" >{{noteType}}</span> sticky note</p>`,
  postAccountNoteReply: `<p class="mb-0">{{admin_name}} posted a reply to an account note.</p>`,
  pinTodoNoteToAccount: `<p class="mb-0">{{admin_name}} pinned a todo note to the account.</p>`,
  createAccountPromotion: `<p class="mb-0">{{admin_name}} created an account promotion: {{promotionName}}.</p>`,
  uploadAccountFile: `<p class="mb-0">{{admin_name}} uploaded an account file.</p>`,
  createAltContact: `<p class="mb-0">{{admin_name}} added {{firstName}} {{lastName}} as an alt contact.</p>`,
  removeAltContact: `<p class="mb-0">{{admin_name}} removed an old alt contact for {{firstName}} {{lastName}}.</p>`,
  setAccountMerchant: `<p class="mb-0">{{admin_name}} set this account as a merhcant.</p>`,
  unsetAccountMerchant: `<p class="mb-0">{{admin_name}} removed this account from merhcant.</p>`,
  setAccountCorporate: `<p class="mb-0">{{admin_name}} set this account as a corporate.</p>`,
  convertToSubAccount: `<p class="mb-0">{{admin_name}} set this account as a sub account of {{companyName}} in {{city}}, {{state}} account#{{accountId}}.</p>`,
  deleteAccount: `<p class="mb-0">{{admin_name}} DELETED account ID #{{accountId}}.</p>`,
  /////////////////////////
  createListing: `<p class="mb-0">{{admin_name}} created listing {{listingId}} for {{companyName}} in {{city}}, {{state}}.</p>`,
  updateListing: `<p class="mb-0">{{vendor_name}} #{{user_id}} ({{user_type/sub-user}}) updated listing {{listingId}} for {{companyName}} in {{city}}, {{state}}.</p>`,
  approvedRequest: `<p class="mb-0">{{admin_name}} approved an update request for listing ID {{listingId}}, for {{companyName}} in {{city}}, {{state}}.</p>`,
  rejectedRequest: `<p class="mb-0">{{admin_name}} rejected an update request for listing ID {{listingId}}, for {{companyName}} in {{city}}, {{state}}..</p>`,
  // Change activity log to "Faruk Avdic" approved update request.

  createListingTodo: `<p class="mb-0">{{admin_name}} created a new <span class=" small-note-label badge badge-secondary rounded-pill" style="background-color: rgb(178, 178, 179);" >{{todoType}}</span> todo for {{listingId}} in {{city}}, {{state}}.</p>`,
  postedListingNote: `<p class="mb-0">{{admin_name}} posted a new <span class=" small-note-label badge badge-secondary rounded-pill" style="background-color: rgb(178, 178, 179);" >{{noteType}}</span> note for listing #{{listingId}} in {{city}}, {{state}}.</p>`,
  createListingPromotion: `<p class="mb-0">{{admin_name}} created a new promotion "{{promotionName}}" for listing #{{listingId}} in {{city}}, {{state}}.</p>`,
  emailListingProof: `<p class="mb-0">{{admin_name}} emailed a proof for listing #{{listingId}} in {{city}}, {{state}} to {{emailAddress}}.</p>`,
  uploadListingFile: `<p class="mb-0">{{admin_name}} uploaded a new file for listing #{{listingId}} in {{city}}, {{state}}.</p>`,
  collectPayment: `<p style="word-break: break-word;" class="mb-0">{{admin_name}} collected a new payment with source #{{sourceId}} in amount of \${{amount}}.</p>`,
  setListingAuthorized: `<p class="mb-0">{{admin_name}} set listing #{{listingId}} in {{city}}, {{state}} as an authorized location.</p>`,
  setListingFeatured: `<p class="mb-0">{{admin_name}} added listing #{{listingId}} in {{city}}, {{state}} to featured authorized location.</p>`,
  unListingAuthorized: `<p class="mb-0">{{admin_name}} removed listing #{{listingId}} in {{city}}, {{state}} from a featured authorized location.</p>`,
  recievePayment: `<p class="mb-0">\${{paymentAmount}} payment recieved for listing #{{listingId}} in {{city}}, {{state}}.</p>`,
  duplicateOnce: `<p class="mb-0">{{admin_name}} duplicated listing #{{listingId}} in {{city}}, {{state}} once.</p>`,
  duplicateMultiple: `<p class="mb-0">{{admin_name}} duplicated listing #{{listingId}} and created listings ID {{listingsIds}}</p>`,
  reassignListing: `<p class="mb-0">{{admin_name}} reassigned listing #{{listingId}} from account <a href="{{href}}" class="account-link">{{fromAccount}}</a> to this account.</p>`,
  bulkListingUpdate: `<p class="mb-0">{{admin_name}} updated listing {{listingId}} and applied changes to listing IDs: {{listingIDs}}.</p>`,
  bulkListingUpdateIndividual: `<p class="mb-0">{{admin_name}} updated listing {{listingId}} as part of bulk update from listing {{mainListingId}}.</p>`,
  registeredAuthorizedVendor: `<p class="mb-0">{{vendor_name}} #{{user_id}} registered to become an authorized vendor.</p>`,
  //////////////////////////
  associateTodo: `<p class="mb-0">{{admin_name}} associated todo with listing {{listingName}}.</p>`,
  updateTodoField: `<p class="mb-0">{{admin_name}} updated {{todoField}} from {{oldFieldValue}} to {{newFieldValue}}.</p>`,
  uploadTodoFIle: `<p class="mb-0">{{admin_name}} uploaded a new file {{fileName}} on a todo.</p>`,
  archiveTodo: `<p class="mb-0">{{admin_name}} archived todo #{{todoId}}</p>`,
  addTodoPerticipant: `<p class="mb-0">{{admin_name}} added a {{participantName}} {{participantLastname}} as a participant on a todo #{{todoId}}</p>`,
  addTodoPerticipants: `<p class="mb-0">{{admin_name}} added a {{participantsNames}} as participants on a todo #{{todoId}}</p>`,
  unassignUserFromTodo: `<p class="mb-0">{{admin_name}} unassigned {{userName}} {{userLastname}}from a todo #{{todoId}}.</p>`,
  unassignUsersFromTodo: `<p class="mb-0">{{admin_name}} unassigned {{userNames}} from a todo #{{todoId}}.</p>`,
  reassignedTodo: `<p class="mb-0">{{admin_name}} reassigned todo #{{todoId}} to {{firstName}} {{lastName}}.</p>`,
  addTodoCalendar: `<p class="mb-0">{{admin_name}} added {{todoName}} to a calendar.</p>`,
  unarchiveTodo: `<p class="mb-0">{{admin_name}} unarchived {{todoName}}.</p>`,
  updatedTodo: `<p class="mb-0">{{admin_name}} updated todo #{{todoId}}.</p>`,
  //////////////////////////
  updatePromotion: `<p class="mb-0">{{admin_name}} updated {{oldPromotionFields}} on a promotion to {{newPromotionFields}}.</p>`,
  duplicatePromotion: `<p class="mb-0">{{admin_name}} duplicated the #{{promotionId}} and created promotion id {{promotionIds}}.</p>`,
  promotionExpired: `<p class="mb-0">{{promotionName}} promotion expired.</p>`,
  applyGeneralPromotion: `<p class="mb-0">{{admin_name}} applied a general promotion {{promotionName}} to {{generalPromotionType}} #{{generalPromotionTypeId}}.</p>`,
  //////////////////////////
  updateRequest: `<p class="mb-0">{{userName}} {{userLastname}} sent an update request for a listing #{{listingId}}.</p>`,
  createInvoice: `<p class="mb-0">An invoice #{{invoiceId}} was created for a listing #{{listingId}}.</p>`,

  changeInvoiceStatus: `<p class="mb-0">Invoice status changed for invoice  #{{invoiceId}} , listing #{{listingId}}.Changed from {{oldInvoiceStatus}} to {{newInvoiceStatus}}.</p>`,
  sendInvoice: `<p class="mb-0">Invoice #{{invoiceId}} was sent to {{recipientEmail}}.</p>`,
  ///////////////////////////
  processPayment: `<p class="mb-0">Payment #{{paymentId}} was processed in the amount of \${{amount}}.</p>`,
  paymentUnsuccesful: `<p class="mb-0">Payment for invoice #{{invoiceId}} in the amount of \${{amount}} was unsuccessful.</p>`,
  emailSent: `<p class="mb-0">{{emailType}} was sent to {{recipientEmail}}.</p>`,
  requestParticipation: `<p class="mb-0">{{admin_name}} requested participation for todo #{{todoName}}.</p>`,
  handleParticipationRequest: `<p class="mb-0">{{admin_name}} {{status}} participation for todo #{{todoId}}.</p>`,

  generatedAccountReport: `<p class="mb-0">{{admin_name}} generated an account report.</p>`,
  generatedListingReport: `<p class="mb-0">{{admin_name}} generated an listing report.</p>`,
  accountReportByEmail: `<p class="mb-0">{{admin_name}} emailed an account report to: {{emails}}</p>`,
  accountReportBySMS: `<p class="mb-0">{{admin_name}} sent a account report by SMS to: {{phoneNumbers}}</p>`,
  listingReportByEmail: `<p class="mb-0">{{admin_name}} emailed an listing report to: {{emails}}</p>`,
  listingReportBySMS: `<p class="mb-0">{{admin_name}} sent a listing report by SMS to: {{phoneNumbers}}</p>`,
  visibleToVendor: `<p class="mb-0">{{admin_name}} made report visible to vendor.</p>`,
  deletedReport: `<p class="mb-0">{{admin_name}} deleted report {{reportName}}.</p>`,
  extendedValidity: `<p class="mb-0">{{admin_name}} extended the validity of report {{reportName}}. New validity date: {{newDate}}</p>`,

  sendReport: `<p class="mb-0">{{admin_name}} shared a report.</p>`,
  sentProof: `<p class="mb-0">{{admin_name}} sent a listing proof to {{proofRecievers}}.</p>`,
  sentMultipleProofs: `<p class="mb-0">{{admin_name}} sent multiple listing proofs to {{proofsRecievers}}. Listing proofs sent for listing ID's: {{listingIds}} .</p>`,
  approvedProof: `<p class="mb-0">Listing #{{adId}} was approved by vendor #{{userId}}.</p>`,
  proofRevised: `<p class="mb-0">{{admin_name}} changed proof status from rejected to pending for proof #{{proofId}}.</p>`,
  canceledListingTypeChange: `<p class="mb-0">{{admin_name}} canceled {{changeType}} and voided an invoice with ID: #{{invoiceId}}.Reason: {{voidReason}}.</p>`,
  becomeAuthorizedVendor: `<p class="mb-0">Registered to become an authorized vendor.</p>`,
  vendorPayingListing: `<p class="mb-0">{{vendor_name}} #{{user_id}} (vendor) created a paid listing #{{listingId}} for {{companyName}} in {{city}}, {{state}}.</p>`,
  vendorPayingListingListingActivity: `<p class="mb-0">{{vendor_name}} #{{user_id}} (vendor) created this listing.</p>`,
  vendorUpgradedListingActivityAccount: `<p class="mb-0">{{vendor_name}} #{{user_id}} (vendor) upgraded listing #{{listingId}} for listing {{companyName}} in {{city}}, {{state}}, from {{oldType}} to {{newType}}.</p>`,
  vendorUpgradedListingActivityListing: `<p class="mb-0">{{vendor_name}} #{{user_id}} (vendor) upgraded this listing from {{oldType}} to {{newType}}.</p>`,

  sendPaymentLink: `<p class="mb-0">{{admin_name}} #{{user_id}} shared payment link for invoice(s): {{invoiceId}} with {{numbers}}.</p>`,
  sendReceiptLink: `<p class="mb-0">{{admin_name}} shared receipt link for invoice: #{{invoiceId}} with {{numbers}}.</p>`,

  rejectedProof: `<p class="mb-0">Listing proof #{{proofId}} for listing #{{adId}} was rejected by vendor #{{userId}}.</p>`,
  approvedMultipleProofs: `<p class="mb-0">Multiple listings ({{adIds}}) were approved by vendor #{{userId}}.</p>`,
  rejectedMultipleProofs: `<p class="mb-0">Multiple listings ({{adIds}}) were rejected by vendor #{{userId}}.</p>`,
  requestChangeAccountType: `<p class="mb-1">{{user_name}} #{{user_id}} requested to change their Account Type to {{selectedType}}</p> <br/> <p class="mb-0" >Message: {{message}}.</p>`,
  approveAccountAccess: `<p class="mb-0">{{user_name}} #{{user_id}} approved remote account access request.</p>`,
  rejectedAccountAccess: `<p class="mb-0">{{user_name}} #{{user_id}} rejected remote account access request.</p>`,
  disabledAccountAccess: `<p class="mb-0">{{user_name}} #{{user_id}} disabled remote account access.</p>`,
  createdListingWithoutVendorAccount: `<p class="mb-0">User with {{account_type_named}} account type #{{user_id}} created a new paid listing #{{listing_id}} from the Website.</p>`,
  deactivatedAccount: `<p class="mb-0">{{user_name}} deactivated their account #{{accountId}}.</p>`,
  vendorRelistedListingActivity: `<p class="mb-0">{{vendor_name}} #{{user_id}} (vendor) relisted this listing.</p>`,
};

export const LISTING_TYPE = {
  PREMIUM: 1,
  STANDARD: 2,
  BASIC_PLUS: 3,
  BASIC: 4,
  FREE: 5,
};

export const LISTING_IMAGE_SIZES = {
  1: { width: 295, height: 245 },
  2: { width: 295, height: 130 },
  3: { width: 295, height: 65 },
};

export const CLIENT_FILE_SIZE_LIMIT_BYTES = 15 * 1024 * 1024; // 15MB

export const NAVIGATION = [
  {
    title: "Search",
    path: "/",
  },
  {
    title: "Vendor",
    path: "/vendor",
  },
  {
    title: "Fleet",
    path: "/fleet",
  },
  {
    title: "Driver",
    path: "/driver",
  },
  {
    title: "Financing",
    path: "/financing",
  },
];

export const ADS_PROOFS_STATUSES = ["", "Approved", "Rejected", "Pending"];

export const DRIVERS_STATUSES = {
  ACTIVE: 1,
  PENDING: 2,
  INACTIVE: 3,
};

export const USER_TYPE = {
  FLEET_CARRIER: 1,
  OWNER_OPERATOR: 2,
  SERVICE_VENDOR: 3,
  COMPANY_DRIVER: 4,
  OTHER: 5,
};

export const USER_TYPES_NAMED = {
  1: "Fleet",
  2: "Owner operator",
  3: "Service vendor",
  4: "Driver",
  5: "Other",
};

export const userStatuses = {
  ACTIVE: 1,
  DEACTIVATED: 3,
};

export const PERMISSION_GATE_PRINCIPLE = {
  ALL: "all",
};

export const LISTING_STATUSES = {
  DISABLED: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  DRAFT: 3,
};

export const SHARE_PROVIDER_STATUSSES = {
  ACTIVE: 1,
  DISABLED: 2,
};

export const PERMISSION_GATE_DEFAULT_FALLBACK = () => null;

export const PERMISSIONS = {
  [USER_TYPE.SERVICE_VENDOR]: {
    VENDOR_DASHBOARD: 16,
    VENDOR_COMPANY_DETAILS: 17,
    VENDOR_SUB_USERS: 20,
    VENDOR_GET_AUTHORIZED: 21,
    VENDOR_REFERR_A_FRIEND: 22,
    VENDOR_REPORTS: 23,
    VENDOR_PAYMENTS: 24,
    VENDOR_PAST_PAYMENTS: 25,
    VENDOR_INVOICES: 26,
    VENDOR_LIST_YOUR_BUSINESS: 28,
    VENDOR_HAVE_A_QUESTIONS: 29,
    VENDOR_LISTING_INFO: 30,
    VENDOR_LISTINGS: 31,
    VENDOR_RENEWALS: 32,
    VENDOR_FINISH_YOR_BUSINESS: 33,
    VENDOR_UPGRADE_LISTING: 34,
    VENDOR_MY_PROFILE: 58,
    VENDOR_CORPORATE_DETAILS: 71,
    VENDOR_CREATE_LISTING: 73,
    VENDOR_ADD_PAYMENT_METHOD: 87,
    VENDOR_DELETE_PAYMENT_METHOD: 88,
    VENDOR_NOTIFICATIONS: 90,
    VENDOR_RECENT_SEARCHES: 95,
    VENDOR_RECURRING_PAYMENTS: 96,
    VENDOR_CHANGE_PASSWORD: 97,
    VENDOR_DOWNLOAD_APP: 102,
    VENDOR_EDIT_LISTING: 117,
  },
  [USER_TYPE.FLEET_CARRIER]: {
    FLEET_MY_PROFILE: 5,
    FLEET_RECENT_SEARCHES: 6,
    FLEET_PREFFERED_VENDORS: 8,
    FLEET_DRIVERS: 9,
    FLEET_TRUCKS: 10,
    FLEET_NOTES: 11,
    FLEET_CALL_LOGS: 12,
    FLEET_SHARED_VENDORS: 13,
    FLEET_RECOMMENDATIONS: 14,
    FLEET_DASHBOARD: 15,
    FLEET_COMPANY_DETAILS: 18,
    FLEET_SUB_USERS: 27,
    FLEET_FINANCING: 57,
    FLEET_CORPORATE_DETAILS: 72,
    FLEET_CREATE_AND_EDIT_NOTE: 75,
    FLEET_CREATE_AND_EDIT_PREFERRED_VENDORS: 76,
    FLEET_CREATE_AND_EDIT_DRIVERS: 77,
    FLEET_CREATE_AND_EDIT_TRUCKS: 78,
    FLEET_CREATE_AND_EDIT_RECOMMENDATIONS: 78,
    FLEET_SHARE_VENDOR: 80,
    FLEET_NOTIFICATIONS: 91,
    FLEET_CHANGE_PASSWORD: 98,
    FLEET_DOWNLOAD_APP: 103,
    FLEET_REPAIRS: 107,
    FLEET_PRIVATE_LOCATIONS: 110,
    FLEET_CREATE_AND_EDIT_REPAIRS: 118,
  },
  [USER_TYPE.OWNER_OPERATOR]: {
    RECENT_SEARCHES: 45,
    PREFFERED_VENDORS: 46,
    NOTES: 47,
    CALL_LOGS: 48,
    SHARED_VENDORS: 49,
    RECOMMENDATIONS: 50,
    DASHBOARD: 51,
    MY_PROFILE: 52,
    LINKED_COMPANY: 54,
    OWNER_OPERATOR_FINANCING: 55,
    OWNER_OPERATOR_NOTIFICATIONS: 92,
    OWNER_OPERATOR_CHANGE_PASSWORD: 99,
    OWNER_OPERATOR_DOWNLOAD_APP: 104,
    OWNER_OPERATOR_REPAIRS: 108,
    OWNER_OPERATOR_CREATE_AND_EDIT_REPAIRS: 119,
  },
  [USER_TYPE.COMPANY_DRIVER]: {
    DRIVER_RECENT_SEARCHES: 36,
    DRIVER_PREFFERED_VENDORS: 37,
    DRIVER_NOTES: 38,
    DRIVER_CALL_LOGS: 39,
    DRIVER_SHARED_VENDORS: 40,
    DRIVER_RECOMMENDATIONS: 41,
    DRIVER_DASHBOARD: 42,
    DRIVER_MY_PROFILE: 43,
    DRIVER_LINKED_COMPANY: 44,
    DRIVER_FINANCING: 56,
    DRIVER_NOTIFICATIONS: 93,
    DRIVER_CHANGE_PASSWORD: 100,
    DRIVER_DOWNLOAD_APP: 105,
  },
  [USER_TYPE.OTHER]: {
    OTHER_RECENT_SEARCHES: 59,
    OTHER_PREFFERED_VENDORS: 60,
    OTHER_NOTES: 61,
    OTHER_CALL_LOGS: 62,
    OTHER_SHARED_VENDORS: 63,
    OTHER_RECOMMENDATIONS: 64,
    OTHER_DASHBOARD: 65,
    OTHER_MY_PROFILE: 66,
    OTHER_LINKED_COMPANY: 67,
    OTHER_FINANCING: 68,
    OTHER_DRIVERS: 69,
    OTHER_TRUCKS: 70,
    OTHER_CREATE_AND_EDIT_NOTE: 81,
    OTHER_CREATE_AND_EDIT_PREFERRED_VENDORS: 82,
    OTHER_CREATE_AND_EDIT_DRIVERS: 83,
    OTHER_CREATE_AND_EDIT_TRUCKS: 84,
    OTHER_CREATE_AND_EDIT_RECOMMENDATIONS: 85,
    OTHER_SHARE_VENDOR: 86,
    OTHER_SUB_USERS: 89,
    OTHER_NOTIFICATIONS: 94,
    OTHER_CHANGE_PASSWORD: 101,
    OTHER_DOWNLOAD_APP: 106,
    OTHER_REPAIRS: 120,
    OTHER_CREATE_EDIT_REPAIRS: 121,
  },
};

export const SUB_PERMISSIONS = {
  [USER_TYPE.SERVICE_VENDOR]: {
    VENDOR_EDIT_COMPANY_DETAILS: 61,
  },
  [USER_TYPE.FLEET_CARRIER]: {
    FLEET_EDIT_SUB_OWNER_NOTE: 58,
    FLEET_EDIT_SUB_REPUTATION: 59,
    FLEET_FILTER_SUB_USER: 60,
    FLEET_EDIT_COMPANY_DETAILS: 62,
    FILTER_SUB_USERS_CALL_LOGS: 65,
    FILTER_SUB_USERS_NOTES: 66,
    FILTER_SUB_USERS_RECOMMENDATIONS: 67,
    FILTER_SUB_USERS_SHARED_VENDORS: 68,
  },
  [USER_TYPE.OWNER_OPERATOR]: {
    UNLINK_FROM_FLEET: 64,
  },
  [USER_TYPE.COMPANY_DRIVER]: {
    UNLINK_FROM_FLEET: 63,
  },
  [USER_TYPE.OTHER]: {
    UNLINK_FROM_FLEET: 70,
  },
};

export const temporaryTokenRoutes = [
  "/dashboard/update-listing",
  "/dashboard/recipient",
  "/dashboard/proofs",
  "/dashboard/upgrade-proof",
  "/dashboard/payment",
  "/dashboard/report",
];

export const SYNCHRONY_GET_CARD_LINK =
  "https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=D2895D9FF3CADB4EBEA8FAC12CA0FE68A81AB131CDCCBCE8&preQual=Y";

export const SYNCHRONY_CREDIT_PROGRAM_ENROLLMENT =
  "https://synchronycreditportal.secure.force.com/PSDE";

export const SYNCHRONY_ORDER_MATERIALS =
  "https://businesscenter.synchronybusiness.com/portal/beginOrderSupplies";

export const REVENUE_CATEGORIES_IDS = [1, 2, 5];

export const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=org.fts.findtruckservice&pli=1";

export const APP_STORE_LINK =
  "https://apps.apple.com/us/app/find-truck-service-stops/id1027671788";

export const listingTypeBenefits = {
  1: [
    {
      icon: "BenefitsCrown",
      benefit: "Biggest and most visible listing",
    },
    {
      icon: "BenefitsPlacement",
      benefit: "TOP placement/rotation in city",
    },
    {
      icon: "BenefitsCategories",
      benefit: "List under all provided categories",
    },
    {
      icon: "BenefitsImage",
      benefit: "Large image (design included)",
    },
    {
      icon: "BenefitsEdit",
      benefit: "Large description section",
    },
    {
      icon: "BenefitsMap",
      benefit: "Large icon on map view",
    },
    {
      icon: "BenefitsWebsite",
      benefit: "Website link from  listing",
    },
    {
      icon: "BenefitsPromotion",
      benefit: "12 promotions included",
    },
  ],
  2: [
    {
      icon: "BenefitsLowerPlacement",
      benefit: "Placement below Premium listing",
    },
    {
      icon: "BenefitsImage",
      benefit: "Medium image (design included)",
    },

    {
      icon: "BenefitsEdit",
      benefit: "Medium description section",
    },
    {
      icon: "BenefitsMap",
      benefit: "Medium icon on map view",
    },
    {
      icon: "BenefitsWebsite",
      benefit: "Website link from business listing",
    },

    {
      icon: "BenefitsPromotion",
      benefit: "6 promotions included",
    },
  ],
  3: [
    {
      icon: "BenefitsLowerPlacement",
      benefit: "Placement below Standard listing",
    },
    {
      icon: "BenefitsImage",
      benefit: "Small image (design included)",
    },

    {
      icon: "BenefitsEdit",
      benefit: "Small description section",
    },

    {
      icon: "BenefitsMap",
      benefit: "Small icon on map view",
    },

    {
      icon: "BenefitsPromotion",
      benefit: "3 promotions included",
    },
  ],
  4: [
    {
      icon: "BenefitsLowerPlacement",
      benefit: "Placement below bigger listing",
    },
    {
      icon: "BenefitsMap",
      benefit: "Small icon on map view",
    },
    {
      icon: "BenefitsPromotion",
      benefit: "1 promotion included",
    },
  ],
};

export const REQUIRED_ERROR_MESSAGE = "Required";

export const ADS_RATES_TYPE = {
  PRIVATE: 1,
  PUBLIC: 2,
};

export const GOOGLE_MAPS_GEOCODE_API_URL =
  "https://maps.googleapis.com/maps/api/geocode/json?";
