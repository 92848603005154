import React, { memo } from "react";
import { ServiceAmenitiesModalContent } from "./ServiceAmenitiesModalContent";
import { FullSizeModal } from "@find-truck-service/ui/src/components/react/Modal/FullSizeModal";
import { ModalHeaderComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalHeaderComponent";
import { ModalFooterComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalFooterComponent";

const ServiceAmenitiesModal = ({ setShowModal, showModal }) => {
  function onSave() {
    setShowModal(false);
  }

  function onClose() {
    setShowModal(false);
  }

  return (
    <FullSizeModal
      isOpen={showModal}
      modalHeaderComponent={() => (
        <ModalHeaderComponent onClose={onClose} title={"Add Services"} />
      )}
      modalContentComponent={() => <ServiceAmenitiesModalContent />}
      modalFooterComponent={() => (
        <ModalFooterComponent successLabel={"Save"} onSuccess={onSave} />
      )}
    />
  );
};

export const MemoizedServiceAmenitiesModal = memo(ServiceAmenitiesModal);
