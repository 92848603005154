import moment from "moment";
export let gapi = window.gapi;

export let CLIENT_ID =
  "840038328947-21s2nk057ot0f6vvh5nm4oupojkcdlkj.apps.googleusercontent.com";
export let API_KEY = "AIzaSyB6Z4PiHofMzIVLDZQwb4emqdUGmcxUrtA";
let DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
let SCOPES =
  "https://www.googleapis.com/auth/calendar.events " +
  "https://www.googleapis.com/auth/gmail.addons.execute " +
  "https://www.googleapis.com/auth/script.external_request " +
  "https://www.googleapis.com/auth/gmail.addons.current.message.action " +
  "https://www.googleapis.com/auth/gmail.addons.current.action.compose " +
  "https://www.googleapis.com/auth/gmail.compose " +
  "https://www.googleapis.com/auth/gmail.addons.current.message.metadata " +
  "https://www.googleapis.com/auth/gmail.readonly " +
  "https://www.googleapis.com/auth/gmail.modify " +
  "https://mail.google.com/ " +
  "https://www.googleapis.com/auth/gmail.metadata ";

export const init = async () => {
  await gapi.client.init({
    apiKey: API_KEY,
    clientId: CLIENT_ID,
    immediate: false,
    discoveryDocs: DISCOVERY_DOCS,
    scope: SCOPES,
  });
};
export const initGapi = async () => {
  await init();
  return await gapi.client.load("calendar", "v3");
};

export const getEvent = (todo) => {
  const {
    reminderDate,
    reminderTime,
    adId,
    ownerId,
    id: todoId,
    owner,
    title,
  } = todo;
  let location = "FTS ";

  let newDate = reminderDate.split("T");

  newDate[1] = reminderTime + ".000Z";

  newDate = newDate.join("T");

  const dateTime = newDate.replace(".000Z", "-05:00");

  let description = `Timestamp: ${moment(newDate).format(
    "MMMM Do YYYY"
  )} at ${reminderTime}`;

  if (adId) {
    location = location + "listing  " + adId;
    description = `<br/> Customer: <a href='${generateLink(
      todoId,
      "",
      adId
    )}' target="_blank" >${owner.company_name}</a>`;
  }

  if (ownerId && !adId) {
    location = location + "account " + ownerId;
    description =
      description +
      `<br/> Account: <a href='${generateLink(
        todoId,
        ownerId
      )}' target="_blank" >${owner.company_name}</a>`;
  }

  description = description + `<br/> Note: ${title}`;

  const summary = capitalizeFirstLetter(todo.todoType) + " Reminder";

  return {
    summary,
    description,
    location,
    start: {
      dateTime,
      timeZone: "America/Chicago",
    },
    end: {
      dateTime,
      timeZone: "America/Chicago",
    },
    reminders: {
      useDefault: false,
      recurrence: ["RRULE:FREQ=DAILY;COUNT=2"],
      overrides: [
        { method: "email", minutes: 10 },
        { method: "popup", minutes: 10 },
      ],
    },
  };
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateLink = (todoId, userId, adId) => {
  let url = window.location.origin;
  let pathName = "";

  if (userId) {
    pathName = "/accounts/" + userId + "?toDoId=" + todoId;
  }

  if (adId) {
    pathName = "/listings/" + adId + "?toDoId=" + todoId;
  }

  url += pathName;

  return url;
};
