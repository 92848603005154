import React, { useCallback, useEffect, useRef } from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { history } from "../../../../../history";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import { useSelector } from "react-redux";
import { selectPaymentsInlineError } from "../../../../../redux/reducers/v2/payments";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";

import { selectCreateListingInvoice } from "../../../../../redux/reducers/v2/invoices";
import { extractParameter } from "../../../../../utility/param-extract";

const PaymentOverviewHeader = () => {
  const inlineMessage = useSelector(selectPaymentsInlineError);
  const listingInvoice = useSelector(selectCreateListingInvoice);

  const messageElementRef = useRef(null);

  useEffect(() => {
    if (messageElementRef.current) {
      messageElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [inlineMessage, messageElementRef]);
  const handleOnBackClick = useCallback(() => {
    const convertedToVendor = extractParameter("convertedToVendor");

    if (convertedToVendor) {
      history.push(
        `/dashboard/get-listed/preview-listing/${listingInvoice.listing.id}?selectedListingPriceId=${listingInvoice.listing.priceId}&convertedToVendor=true`,
      );
    } else {
      history.push(
        `/dashboard/get-listed/preview-listing/${listingInvoice.listing.id}?selectedListingPriceId=${listingInvoice.listing.priceId}`,
      );
    }
  }, [listingInvoice]);

  return (
    <FtsColumn
      display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}
      direction="column"
      size={{ xs: 12, sm: 12, md: 12, lg: 12 }}
    >
      <FtsWrapper
        display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}
        align="center"
        mb={Spacing.sm}
        cursor="pointer"
        onClick={handleOnBackClick}
      >
        <FtsIcons iconName={IconNames.ChevronLeft} />
        <Span
          variant={VariantsValues["text-2xs"]}
          color={ColorsValue["greyscale-500"]}
        >
          Back
        </Span>
      </FtsWrapper>

      {inlineMessage && (
        <FtsWrapper my={"lg"} ref={messageElementRef}>
          <Message type={"error"} title={""} subtitle={inlineMessage} />
        </FtsWrapper>
      )}
      <Span
        variant={VariantsValues["text-md"]}
        weight={WeightSize.bold}
        color={ColorsValue["greyscale-800"]}
      >
        Payment Overview
      </Span>
    </FtsColumn>
  );
};

export default PaymentOverviewHeader;
