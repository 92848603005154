import React from "react";
import { useSelector } from "react-redux";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { selectTemporaryLinkIsLoading } from "../../../redux/reducers/v2/temporaryListingLink";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { TemporaryLinkEditListingHeaderXs } from "../../../components/fts-components-v2/ListingBusinessInfo/TemporaryLinkEditListing/components/TemporaryLinkEditListingHeaderXs";
import TemporaryLinkEditListingInfoContent from "../../../components/fts-components-v2/ListingBusinessInfo/TemporaryLinkEditListing/components/TemporaryLinkEditListingInfoContent";
import { TemporaryLinkEditListingInfoSmFooter } from "../../../components/fts-components-v2/ListingBusinessInfo/TemporaryLinkEditListing/components/TemporaryLinkEditListingInfoSmFooter";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

const TemporaryLinkEditListing = () => {
  const isLoading = useSelector(selectTemporaryLinkIsLoading);

  return (
    <FtsWrapper bg={ColorsValue["greyscale-50"]}>
      <FtsContainer>
        {isLoading && <FullPageLoader />}
        <TemporaryLinkEditListingHeaderXs title="Business Listing" />
        <TemporaryLinkEditListingInfoContent />
        <TemporaryLinkEditListingInfoSmFooter />
      </FtsContainer>
    </FtsWrapper>
  );
};

export default TemporaryLinkEditListing;
