import React, { useState } from "react";

import moment from "moment";
import Icon from "../Icons";
import { Button, Container } from "reactstrap";
import "../../../assets/scss/components/fts/fts-renew-banner.scss";

const formatMoney = (number) => {
  return number
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const RenewBanner = ({ renewal, totalAmount, token }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  if (totalAmount === 0) {
    return null;
  }

  return (
    <div className="fts-renew-banner">
      <Container>
        <div className="fts-renew-banner-desktop">
          <div className="left">
            <div className="renewal-date">
              <span className="light">Renewal Date:</span>
              <span className="bold">
                {moment.unix(renewal).format("MMMM DD, YYYY")}
              </span>
            </div>
            <div className="renewal-amount">
              <span className="light">Amount Due:</span>
              <span className="bold">${formatMoney(totalAmount)}</span>
            </div>
          </div>
          <div className="right">
            <a href={`/dashboard/payment/${token}`}>
              <Button color="secondary" className="renew-now-btn">
                Renew Now
              </Button>
            </a>
          </div>
        </div>
        <div className="fts-renew-banner-mobile">
          <div className="fts-renew-banner-mobile-title">
            <h3>Renewal overview</h3>
            <div
              onClick={() => {
                toggle();
              }}
              className="fts-renew-banner-mobile-title-chevron"
              style={{
                transform: collapsed ? "rotate(0deg)" : "rotate(-180deg)",
              }}
            >
              <Icon name="ChevronUp" size={24} />
            </div>
          </div>
          {collapsed && (
            <div className="renewal-date-amount">
              <div className="renewal-date">
                <span className="light">Renewal Date:</span>
                <span className="bold">
                  {moment.unix(renewal).format("MMMM DD, YYYY")}
                </span>
              </div>

              <div className="renewal-amount">
                <span className="light">Amount Due:</span>
                <span className="bold">${formatMoney(totalAmount)}</span>
              </div>

              <a href={`/dashboard/payment/${token}`}>
                <Button color="secondary" className="renew-now-btn">
                  Renew Now
                </Button>
              </a>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default RenewBanner;
