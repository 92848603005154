import React from "react";
import { EVENT_MEASUREMENTS_EXPIRES } from "../../../constants/analytics";
import moment from "moment/moment";
import {
  getListingTags,
  getLoggedUserTags,
  storeAnalytic,
} from "../../../utility/analytic.helpers";
import FTSCookies from "../../../cookies";
import { connect } from "react-redux";
import logger from "../../../utility/logger";

const EventWrapper = (props) => {
  const { interactionType, children, listingData, userData } = props;

  const handleClick = async () => {
    if (children.props && typeof children.props.onClick === "function") {
      children.props.onClick();
    }

    let tags = {
      ...getLoggedUserTags(userData),
    };

    if (listingData) {
      const expires = EVENT_MEASUREMENTS_EXPIRES[interactionType];

      tags = {
        ...tags,
        ...getListingTags(listingData),
      };

      const keyName = `${interactionType}_${listingData.id}`;
      const savedAction = await FTSCookies.get(keyName);

      if (savedAction) return;
      if (expires)
        await FTSCookies.set(keyName, moment().toISOString(), { expires });
    }

    try {
      if (!interactionType) return;

      storeAnalytic(interactionType, tags);
    } catch (e) {
      logger.error("CANNOT STORE ANALYTIC: ", e);
    }
  };

  if (!React.isValidElement(props.children)) return null;

  return React.cloneElement(props.children, { onClick: handleClick });
};

const mapStateToProps = (state) => {
  return {
    userData: state.authState.user,
  };
};
export default connect(mapStateToProps, null)(EventWrapper);
