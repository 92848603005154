import React from "react";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import { hoursListFrom } from "../../../fts/formik/hour-input/options";

export const WorkingHoursFromDropdown = (props) => {
  const { dayFromName, dayFromValue, onChange } = props;
  return (
    <FtsColumn size={{ xs: 6 }}>
      <Dropdown
        size={DropdownSizesValues.md}
        variant={DropdownVariantsValues.outlined}
        placeholder={""}
        name={dayFromName}
        value={dayFromValue}
        options={hoursListFrom}
        onChange={(val) => onChange(dayFromName, val)}
      />
    </FtsColumn>
  );
};
