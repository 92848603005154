import React from "react";

import { ReactComponent as ActivityWhite } from "../../assets/img/svg/ActivityWhite.svg";
import { ReactComponent as Account } from "../../assets/img/svg/Icon/Account.svg";
import { ReactComponent as Profile } from "../../assets/img/svg/Icon/ProfileAvatar.svg";
import { ReactComponent as Info } from "../../assets/img/svg/Icon/Info.svg";
import { ReactComponent as Unpin } from "../../assets/img/svg/Icon/Quick action icons/Unpin.svg";

import { ReactComponent as CheckWhite } from "../../assets/img/svg/Icon/Listing_Icons/Checkmark/CheckWhite.svg";
import { ReactComponent as Filter } from "../../assets/img/svg/Icon/filter.svg";
import { ReactComponent as CheckGreen } from "../../assets/img/svg/CheckGreen.svg";
import { ReactComponent as CheckActive } from "../../assets/img/svg/Icon/Listing_Icons/Checkmark/Active.svg";
import { ReactComponent as Payment } from "../../assets/img/svg/Icon/Listing_Icons/Payment.svg";
import { ReactComponent as Attach } from "../../assets/img/svg/Icon/Listing_Icons/Attach.svg";
import { ReactComponent as Eye } from "../../assets/img/svg/Icon/Listing_Icons/Eye.svg";
import { ReactComponent as Files } from "../../assets/img/svg/Icon/Listing_Icons/Files.svg";
import { ReactComponent as Listings } from "../../assets/img/svg/Icon/Listing_Icons/Listing.svg";
import { ReactComponent as Accounts } from "../../assets/img/svg/Icon/Listing_Icons/Accounts.svg";
import { ReactComponent as AddNote } from "../../assets/img/svg/Icon/Listing_Icons/Add note.svg";
import { ReactComponent as Bookmark } from "../../assets/img/svg/Icon/Listing_Icons/Bookmark.svg";
import { ReactComponent as Calendar } from "../../assets/img/svg/Icon/Listing_Icons/Calendar.svg";
import { ReactComponent as Notifications } from "../../assets/img/svg/Icon/Notification.svg";
import { ReactComponent as Search } from "../../assets/img/svg/Icon/Listing_Icons/Search.svg";
import { ReactComponent as Phone } from "../../assets/img/svg/Icon/Listing_Icons/Phone.svg";
import { ReactComponent as PhoneBlue } from "../../assets/img/svg/Icon/Listing_Icons/PhoneBlue.svg";
import { ReactComponent as ListingActivity } from "../../assets/img/svg/Icon/Listing_Icons/Activity.svg";
import { ReactComponent as Add } from "../../assets/img/svg/Icon/Add.svg";
import { ReactComponent as Flag } from "../../assets/img/svg/Icon/Flag.svg";
import { ReactComponent as ClearCalendar } from "../../assets/img/svg/Icon/ClearCalendar.svg";
import { ReactComponent as LinkSuccessfull } from "../../assets/img/svg/LinkSuccessfull.svg";

import { ReactComponent as Delete } from "../../assets/img/svg/Icon/Delete.svg";
import { ReactComponent as Edit } from "../../assets/img/svg/Edit.svg";
import { ReactComponent as EditDriver } from "../../assets/img/svg/EditDriver.svg";
import { ReactComponent as Label } from "../../assets/img/svg/Label.svg";
import { ReactComponent as Diagram } from "../../assets/img/svg/Diagram.svg";
import { ReactComponent as LinkedCompany } from "../../assets/img/svg/LinkedCompany.svg";
import { ReactComponent as Megaphone } from "../../assets/img/svg/Megaphone.svg";
import { ReactComponent as PaperBlank } from "../../assets/img/svg/PaperBlank.svg";
import { ReactComponent as ListingSuccess } from "../../assets/img/svg/listing-success.svg";
import { ReactComponent as Vendors } from "../../assets/img/svg/vendors.svg";
import { ReactComponent as AccessRequested } from "../../assets/img/svg/access-requested.svg";
import { ReactComponent as AccessGranted } from "../../assets/img/svg/access-granted.svg";
import { ReactComponent as AccessRejected } from "../../assets/img/svg/access-rejected.svg";
import { ReactComponent as AccessRequestExpired } from "../../assets/img/svg/access-request-expired.svg";

import { ReactComponent as Logo } from "../../assets/img/svg/Logo.svg";
import { ReactComponent as LoginKey } from "../../assets/img/svg/Login Key.svg";
import { ReactComponent as Towing } from "../../assets/img/svg/Towing.svg";
import { ReactComponent as TowingNew } from "../../assets/img/svg/Towing_new.svg";
import { ReactComponent as Email } from "../../assets/img/svg/Email.svg";
import { ReactComponent as Password } from "../../assets/img/svg/Password.svg";
import { ReactComponent as HelpCircle } from "../../assets/img/svg/helpCircle.svg";
import { ReactComponent as ReferralSuccessfull } from "../../assets/img/svg/Referral_Successfull.svg";

import { ReactComponent as Tires } from "../../assets/img/svg/Tires.svg";
import { ReactComponent as Repair } from "../../assets/img/svg/Repair.svg";
import { ReactComponent as Repairs } from "../../assets/img/svg/Repairs.svg";
import { ReactComponent as Dealers } from "../../assets/img/svg/Dealers.svg";
import { ReactComponent as TruckRepair } from "../../assets/img/svg/truck-repair.svg";
import { ReactComponent as Parts } from "../../assets/img/svg/Parts.svg";
import { ReactComponent as MobileRepair } from "../../assets/img/svg/Mobile repair.svg";
import { ReactComponent as BgElement } from "../../assets/img/svg/Bg element.svg";

import { ReactComponent as PaperScroll } from "../../assets/img/svg/PaperScroll.svg";
import { ReactComponent as PaperText } from "../../assets/img/svg/PaperText.svg";
import { ReactComponent as Todo } from "../../assets/img/svg/Todo.svg";
import { ReactComponent as SalesDashboard } from "../../assets/img/svg/Icon/SalesDashboard.svg";
import { ReactComponent as LeadsPool } from "../../assets/img/svg/Icon/LeadsPool.svg";
import { ReactComponent as Customers } from "../../assets/img/svg/Icon/Customers.svg";
import { ReactComponent as ActiveLeads } from "../../assets/img/svg/Icon/ActiveLeads.svg";

import { ReactComponent as Todos } from "../../assets/img/svg/Icon/Quick action icons/Todos.svg";
import { ReactComponent as Promotion } from "../../assets/img/svg/Icon/Quick action icons/Promotion.svg";
import { ReactComponent as Report } from "../../assets/img/svg/Icon/Quick action icons/Report.svg";
import { ReactComponent as Marketing } from "../../assets/img/svg/Icon/Quick action icons/Marketing.svg";
import { ReactComponent as Note } from "../../assets/img/svg/Icon/Quick action icons/Add note.svg";
import { ReactComponent as Listing } from "../../assets/img/svg/Icon/Quick action icons/Listing.svg";

import { ReactComponent as BackToList } from "../../assets/img/svg/Icon/Arrow/Back To list.svg";
import { ReactComponent as Back } from "../../assets/img/svg/Icon/Back.svg";
import { ReactComponent as ArrowLeft } from "../../assets/img/svg/Icon/Listing_Icons/ArrowLeft.svg";
import { ReactComponent as ArrowRightLG } from "../../assets/img/svg/arrow-right.svg";
import { ReactComponent as CollapseLeft } from "../../assets/img/svg/Icon/CollapseLeft.svg";
import { ReactComponent as CollapseArrow } from "../../assets/img/svg/Icon/CollapseArrow.svg";
import { ReactComponent as ArrowDown } from "../../assets/img/svg/Icon/Arrow/Down.svg";
import { ReactComponent as Time } from "../../assets/img/svg/Icon/time.svg";
import { ReactComponent as Reply } from "../../assets/img/svg/reply on comment.svg";
import { ReactComponent as TodoNote } from "../../assets/img/svg/Icon/Note.svg";

import { ReactComponent as Owner } from "../../assets/img/svg/Icon/Owner.svg";
import { ReactComponent as Drag } from "../../assets/img/svg/Icon/Icon-Drag.svg";
import { ReactComponent as Placeholder } from "../../assets/img/svg/Icon/Icon-Placeholder.svg";
import { ReactComponent as EmptySearch } from "../../assets/img/svg/Icon/EmptySearch.svg";
import { ReactComponent as Updates } from "../../assets/img/svg/Icon/Updates.svg";
import { ReactComponent as NoteBottom } from "../../assets/img/svg/Icon/NoteBottom.svg";
import { ReactComponent as DropdownEdit } from "../../assets/img/svg/Icon/DropdownEdit.svg";
import { ReactComponent as ArrowRight } from "../../assets/img/svg/Icon/ArrowRight.svg";
import { ReactComponent as MobileSearch } from "../../assets/img/svg/Icon/MobileSearch.svg";
import { ReactComponent as MobileSearchGray } from "../../assets/img/svg/Icon/MobileSearchGray.svg";
import { ReactComponent as Move } from "../../assets/img/svg/Icon/Move.svg";
import { ReactComponent as User } from "../../assets/img/svg/Icon/User.svg";
import { ReactComponent as DropdownArchive } from "../../assets/img/svg/Icon/DropdownArchive.svg";
import { ReactComponent as Meta } from "../../assets/img/svg/Icon/Meta.svg";
import { ReactComponent as Renewals } from "../../assets/img/svg/Icon/Renewals.svg";
import { ReactComponent as RenewalsBanner } from "../../assets/img/svg/renewals-banner.svg";
import { ReactComponent as Sales } from "../../assets/img/svg/Icon/Sales.svg";
import { ReactComponent as MoreVertical } from "../../assets/img/svg/Icon/MoreVertical.svg";
import { ReactComponent as MoreHorizontal } from "../../assets/img/svg/Icon/MoreHorizontal.svg";
import { ReactComponent as Checked } from "../../assets/img/svg/Icon/Checked.svg";
import { ReactComponent as CheckedRed } from "../../assets/img/svg/Icon/CheckedRed.svg";
import { ReactComponent as RoundedCheckmark } from "../../assets/img/svg/Icon/RoundedCheckmark.svg";
import { ReactComponent as RoundedStop } from "../../assets/img/svg/Icon/RoundedStop.svg";
import { ReactComponent as AfterETC } from "../../assets/img/svg/Icon/afterETC.svg";
import { ReactComponent as BeforeETC } from "../../assets/img/svg/Icon/beforeETC.svg";
import { ReactComponent as JustOnTimeETC } from "../../assets/img/svg/Icon/justOnTimeETC.svg";
import { ReactComponent as CheckCircle } from "../../assets/img/svg/Icon/CheckCircle.svg";
import { ReactComponent as CheckCircle2 } from "../../assets/img/svg/Icon/CheckCircle2.svg";
import { ReactComponent as EditAction } from "../../assets/img/svg/Icon/EditAction.svg";
import { ReactComponent as FileText } from "../../assets/img/svg/Icon/FileText.svg";
import { ReactComponent as Tag } from "../../assets/img/svg/Icon/Tag.svg";
import { ReactComponent as PieChart } from "../../assets/img/svg/Icon/PieChart.svg";
import { ReactComponent as BarChart } from "../../assets/img/svg/Icon/BarChart.svg";
import { ReactComponent as CreditCard } from "../../assets/img/svg/Icon/CreditCard.svg";
import { ReactComponent as GoTo } from "../../assets/img/svg/Icon/GoTo.svg";
import { ReactComponent as Crown } from "../../assets/img/svg/Icon/Crown.svg";
import { ReactComponent as AttachBottom } from "../../assets/img/svg/Icon/AttachBottom.svg";
import { ReactComponent as TodoArchived } from "../../assets/img/svg/Icon/TodoArchived.svg";
import { ReactComponent as ChevronDown } from "../../assets/img/svg/Icon/Chevron-down.svg";
import { ReactComponent as ChevronUp } from "../../assets/img/svg/Icon/Chevron-up.svg";
import { ReactComponent as DotActive } from "../../assets/img/svg/Icon/DotActive.svg";
import { ReactComponent as DotChecked } from "../../assets/img/svg/Icon/DotChecked.svg";
import { ReactComponent as DotCheckedGreen } from "../../assets/img/svg/Icon/DotCheckedGreen.svg";

import { ReactComponent as BenefitsCategories } from "../../assets/img/svg/Icon/benefits-categories.svg";
import { ReactComponent as BenefitsDollar } from "../../assets/img/svg/Icon/benefits-dollar.svg";
import { ReactComponent as BenefitsEdit } from "../../assets/img/svg/Icon/benefits-edit.svg";
import { ReactComponent as BenefitsPlacement } from "../../assets/img/svg/Icon/benefits-placement.svg";
import { ReactComponent as BenefitsLowerPlacement } from "../../assets/img/svg/Icon/benefits-lower-placement.svg";
import { ReactComponent as BenefitsPromotion } from "../../assets/img/svg/Icon/benefits-promotion.svg";
import { ReactComponent as BenefitsSuggest } from "../../assets/img/svg/Icon/benefits-suggest.svg";
import { ReactComponent as BenefitsWebsite } from "../../assets/img/svg/Icon/benefits-website.svg";
import { ReactComponent as BenefitsImage } from "../../assets/img/svg/Icon/benefits-image.svg";
import { ReactComponent as BenefitsCrown } from "../../assets/img/svg/Icon/benefits-crown.svg";
import { ReactComponent as BenefitsMap } from "../../assets/img/svg/Icon/benefits-map.svg";

import { ReactComponent as Share } from "../../assets/img/svg/Icon/Icon-Share.svg";
import { ReactComponent as Exit } from "../../assets/img/svg/Icon/Icon-Exit.svg";
import { ReactComponent as CopyLink } from "../../assets/img/svg/Icon/Copy-link.svg";
import { ReactComponent as Archive } from "../../assets/img/svg/Icon/Icon-Archive.svg";
import { ReactComponent as AddToCalendar } from "../../assets/img/svg/Icon/Icon-Add-to-calendar.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/img/svg/duplicate.svg";
import { ReactComponent as Location } from "../../assets/img/svg/Icon/Location.svg";
import { ReactComponent as LogACall } from "../../assets/img/svg/Icon/LogACall.svg";
import { ReactComponent as LogAnEmail } from "../../assets/img/svg/Icon/LogAnEmail.svg";
import { ReactComponent as SaveWithoutLogging } from "../../assets/img/svg/Icon/SaveWithoutLogging.svg";
import { ReactComponent as ReassignAccountConfirmation } from "../../assets/img/svg/Icon/ReassignAccountConfirmation.svg";
import { ReactComponent as Reassign } from "../../assets/img/svg/Icon/Reassign.svg";
import { ReactComponent as Preview } from "../../assets/img/svg/Icon/Preview.svg";
import { ReactComponent as Update } from "../../assets/img/svg/Icon/Update.svg";
import { ReactComponent as EditAccount } from "../../assets/img/svg/Icon/EditAccount.svg";
import { ReactComponent as Invoices } from "../../assets/img/svg/Icon/Invoices.svg";
import { ReactComponent as File } from "../../assets/img/svg/Icon/File.svg";
import { ReactComponent as Card } from "../../assets/img/svg/Icon/Card.svg";
import { ReactComponent as Link } from "../../assets/img/svg/Icon/Link.svg";
import { ReactComponent as CheckIcon } from "../../assets/img/svg/Icon/Check.svg";
import { ReactComponent as InfoCircle } from "../../assets/img/svg/Icon/InfoCircle.svg";
import { ReactComponent as ResponsiveCheck } from "../../assets/img/svg/ResponsiveCheck.svg";

import { ReactComponent as MasterCard } from "../../assets/img/svg/Icon/MasterCard.svg";

import { ReactComponent as AmeEx } from "../../assets/img/svg/Icon/AmeEx.svg";
import { ReactComponent as Download } from "../../assets/img/svg/Icon/Download.svg";
import { ReactComponent as Plus } from "../../assets/img/svg/Icon/Plus.svg";
import { ReactComponent as CardRemove } from "../../assets/img/svg/Icon/CardRemove.svg";
import { ReactComponent as Open } from "../../assets/img/svg/Icon/Open.svg";
import { ReactComponent as Send } from "../../assets/img/svg/Icon/Send.svg";
import { ReactComponent as SendProof } from "../../assets/img/svg/Icon/SendProof.svg";
import { ReactComponent as SendAction } from "../../assets/img/svg/Icon/SendAction.svg";
import { ReactComponent as SendActivity } from "../../assets/img/svg/Icon/SendActivity.svg";
import { ReactComponent as OpenAction } from "../../assets/img/svg/Icon/OpenAction.svg";
import { ReactComponent as PreviewAction } from "../../assets/img/svg/Icon/PreviewAction.svg";
import { ReactComponent as UpdateAction } from "../../assets/img/svg/Icon/UpdateAction.svg";

import { ReactComponent as Trash } from "../../assets/img/svg/Icon/Trash.svg";
import { ReactComponent as UserAccount } from "../../assets/img/svg/Icon/UserAccount.svg";
import { ReactComponent as ACH } from "../../assets/img/svg/Icon/ACH.svg";
import { ReactComponent as Plaid } from "../../assets/img/svg/Icon/Plaid.svg";
import { ReactComponent as Paypal } from "../../assets/img/svg/Icon/Paypal.svg";

import { ReactComponent as LeadsDashboard } from "../../assets/img/svg/Icon/LeadsDashboard.svg";
import { ReactComponent as TrialsDashboard } from "../../assets/img/svg/Icon/TrialsDashboard.svg";
import { ReactComponent as RenewalsDashboard } from "../../assets/img/svg/Icon/RenewalsDashboard.svg";
import { ReactComponent as RenewalsSection } from "../../assets/img/svg/Icon/RenewalsSection.svg";
import { ReactComponent as CallsDashboard } from "../../assets/img/svg/Icon/CallsDashboard.svg";
import { ReactComponent as EmailsDashboard } from "../../assets/img/svg/Icon/EmailsDashboard.svg";
import { ReactComponent as UpdatesDashboard } from "../../assets/img/svg/Icon/UpdatesDashboard.svg";
import { ReactComponent as NewtrialsDashboard } from "../../assets/img/svg/Icon/NewtrialsDashboard.svg";
import { ReactComponent as DealsDashboard } from "../../assets/img/svg/Icon/DealsDashboard.svg";
import { ReactComponent as NewListings } from "../../assets/img/svg/Icon/NewListings.svg";
import { ReactComponent as Upgrades } from "../../assets/img/svg/Icon/Upgrades.svg";
import { ReactComponent as Downgrades } from "../../assets/img/svg/Icon/Downgrades.svg";
import { ReactComponent as EmptyTodos } from "../../assets/img/svg/Icon/EmptyTodos.svg";
import { ReactComponent as ArrowLink } from "../../assets/img/svg/Icon/ArrowLink.svg";
import { ReactComponent as ArrowNext } from "../../assets/img/svg/Icon/ArrowNext.svg";
import { ReactComponent as Congratulations } from "../../assets/img/svg/Icon/Congratulations.svg";
import { ReactComponent as UpdateActivity } from "../../assets/img/svg/Icon/UpdateActivity.svg";
import { ReactComponent as UnderReview } from "../../assets/img/svg/Icon/UnderReview.svg";
import { ReactComponent as NoCallLogs } from "../../assets/img/svg/Icon/NoCallLogs.svg";

import { ReactComponent as Projection } from "../../assets/img/svg/Projection.svg";
import { ReactComponent as Reinstate } from "../../assets/img/svg/Reinstate.svg";

import { ReactComponent as Notification } from "../../assets/img/notification/notification.svg";
import { ReactComponent as Close } from "../../assets/img/svg/Icon/close.svg";
import { ReactComponent as ApproveTodo } from "../../assets/img/svg/Icon/ApproveTodo.svg";
import { ReactComponent as TodoActivity } from "../../assets/img/svg/Icon/TodoActivity.svg";
import { ReactComponent as NoteActivity } from "../../assets/img/svg/Icon/NoteActivity.svg";
import { ReactComponent as PromotionActivity } from "../../assets/img/svg/Icon/PromotionActivity.svg";
import { ReactComponent as UpdatesMenuItem } from "../../assets/img/svg/Icon/UpdatesMenuItem.svg";
import { ReactComponent as ListingUpdate } from "../../assets/img/svg/Icon/ListingUpdate.svg";
import { ReactComponent as ListingSuccessful } from "../../assets/img/svg/Icon/ListingSuccessful.svg";
import { ReactComponent as BackButton } from "../../assets/img/svg/Icon/BackButton.svg";
import { ReactComponent as ClientLogo } from "../../assets/img/logo/ClientLogo.svg";
import { ReactComponent as GoogleSvg } from "../../assets/img/pages/login/google.svg";
import { ReactComponent as FacebookSvg } from "../../assets/img/pages/login/facebook.svg";
import { ReactComponent as AppleSvg } from "../../assets/img/pages/login/AppleSvg.svg";
import { ReactComponent as Star } from "../../assets/img/svg/Icon/Star.svg";
import { ReactComponent as Notes } from "../../assets/img/svg/Icon/Notes.svg";
import { ReactComponent as DollarCircle } from "../../assets/img/svg/Icon/DollarCircle.svg";
import { ReactComponent as DollarSign } from "../../assets/img/svg/Icon/Dollar-sign.svg";
import { ReactComponent as Setting } from "../../assets/img/svg/Icon/Setting.svg";
import { ReactComponent as Review } from "../../assets/img/svg/Icon/Review.svg";
import { ReactComponent as PromotionTag } from "../../assets/img/svg/Icon/PromotionTag.svg";
import { ReactComponent as NoProofs } from "../../assets/img/svg/NoProofs.svg";
import { ReactComponent as Unsubscribe } from "../../assets/img/svg/Unsubscribe.svg";
import { ReactComponent as UnsubscribeRed } from "../../assets/img/svg/UnsubscribeRed.svg";
import { ReactComponent as CreditCardRed } from "../../assets/img/svg/Icon/CreditCardRed.svg";
import { ReactComponent as Miles } from "../../assets/img/svg/Icon/Miles.svg";
import { ReactComponent as IconInfo } from "../../assets/img/svg/Icon/IconInfo.svg";
import { ReactComponent as MapPin } from "../../assets/img/svg/Icon/MapPin.svg";
import { ReactComponent as Clock } from "../../assets/img/svg/Icon/Clock.svg";
import { ReactComponent as Fuel } from "../../assets/img/svg/Icon/Fuel.svg";
import { ReactComponent as CheckCircleInfo } from "../../assets/img/svg/Icon/CheckCircleInfo.svg";
import { ReactComponent as GoStandardIcon } from "../../assets/img/svg/Icon/GoStandardIcon.svg";
import { ReactComponent as GoStandardScale } from "../../assets/img/svg/Icon/GoStandardScale.svg";
import { ReactComponent as GoBasicPlusIcon } from "../../assets/img/svg/Icon/GoBasicPlusIcon.svg";
import { ReactComponent as GoBasicIcon } from "../../assets/img/svg/Icon/GoBasicIcon.svg";
import { ReactComponent as GoBasicPlusScale } from "../../assets/img/svg/Icon/GoBasicPlusScale.svg";
import { ReactComponent as GoBasicScale } from "../../assets/img/svg/Icon/GoBasicScale.svg";
import { ReactComponent as GoPremiumIcon } from "../../assets/img/svg/Icon/GoPremiumIcon.svg";
import { ReactComponent as GoPremiumScale } from "../../assets/img/svg/Icon/GoPremiumScale.svg";
import { ReactComponent as ImageIcon } from "../../assets/img/svg/Icon/ImageIcon.svg";
import { ReactComponent as UserPlus } from "../../assets/img/svg/Icon/UserPlus.svg";

import { ReactComponent as Downgrading } from "../../assets/img/svg/Icon/Downgrading.svg";
import { ReactComponent as CreditCardWhite } from "../../assets/img/svg/Icon/CreditCardWhite.svg";
import { ReactComponent as EnrollNow } from "../../assets/img/svg/Icon/EnrollNow.svg";
import { ReactComponent as ExternalLink } from "../../assets/img/svg/Icon/ExternalLink.svg";
import { ReactComponent as Copy } from "../../assets/img/svg/Icon/Copy.svg";
import { ReactComponent as IncreaseSales } from "../../assets/img/svg/Icon/IncreaseSales.svg";
import { ReactComponent as AttractCustomers } from "../../assets/img/svg/Icon/AttractCustomers.svg";
import { ReactComponent as BuildLoyalty } from "../../assets/img/svg/Icon/BuildLoyalty.svg";
import { ReactComponent as FiveMinuteApplication } from "../../assets/img/svg/Icon/FiveMinuteApplication.svg";
import { ReactComponent as HourFunding } from "../../assets/img/svg/Icon/HourFunding.svg";
import { ReactComponent as HoursClosed } from "../../assets/img/svg/Icon/hoursClosed.svg";
import { ReactComponent as HoursOpen } from "../../assets/img/svg/Icon/hoursOpen.svg";
import { ReactComponent as HoursClosingSoon } from "../../assets/img/svg/Icon/hoursClosingSoon.svg";
import { ReactComponent as NoRecourse } from "../../assets/img/svg/Icon/NoRecourse.svg";
import { ReactComponent as NoReports } from "../../assets/img/svg/Icon/NoReports.svg";
import { ReactComponent as PaypalMobile } from "../../assets/img/svg/Icon/paypal-mobile.svg";
import { ReactComponent as NoSubAccounts } from "../../assets/img/svg/Icon/NoSubAccounts.svg";
import { ReactComponent as DiscountsAndRebates } from "../../assets/img/svg/Icon/DiscountsAndRebates.svg";
import { ReactComponent as FreePOPMaterials } from "../../assets/img/svg/Icon/FreePOPMaterials.svg";
import { ReactComponent as NoHiddenCosts } from "../../assets/img/svg/Icon/NoHiddenCosts.svg";
import { ReactComponent as Smartphone } from "../../assets/img/svg/Icon/NoHiddenCosts.svg";
import { ReactComponent as Desktop } from "../../assets/img/svg/Icon/NoHiddenCosts.svg";

import { ReactComponent as AppStoreBadge } from "../../assets/img/svg/AppStoreBadge.svg";
import { ReactComponent as GooglePlayBadge } from "../../assets/img/svg/GooglePlayBadge.svg";

import { ReactComponent as GiftBox } from "../../assets/img/svg/Icon/GiftBox.svg";
import { ReactComponent as GoogleInfoArrow } from "../../assets/img/svg/Icon/GoogleInfoArrow.svg";

import { ReactComponent as UserProfile } from "../../assets/img/svg/Icon/UserProfile.svg";

import { ReactComponent as ListView } from "../../assets/img/svg/Icon/ListView.svg";
import { ReactComponent as MapView } from "../../assets/img/svg/Icon/MapView.svg";
import { ReactComponent as SendIcon } from "../../assets/img/svg/Icon/SendIcon.svg";
import { ReactComponent as MyListingsPreview } from "../../assets/img/svg/Icon/MyListingsPreview.svg";
import { ReactComponent as ListingsDashboard } from "../../assets/img/svg/Icon/ListingsDashboard.svg";
import { ReactComponent as SearchExposureDashboard } from "../../assets/img/svg/Icon/SearchExposureDashboard.svg";
import { ReactComponent as InstantFinancingDashboard } from "../../assets/img/svg/Icon/InstantFinancingDashboard.svg";
import { ReactComponent as ReferAFriendDashboard } from "../../assets/img/svg/Icon/ReferAFriendDashboard.svg";
import { ReactComponent as ReportInfo } from "../../assets/img/svg/report-info.svg";
import { ReactComponent as ReportIcon } from "../../assets/img/svg/Icon/ReportIcon.svg";
import { ReactComponent as EmailIcon } from "../../assets/img/svg/Icon/EmailIcon.svg";
import { ReactComponent as ReportImage } from "../../assets/img/svg/ReportImage.svg";
import { ReactComponent as Truck } from "../../assets/img/svg/Icon/Truck.svg";
import { ReactComponent as Power } from "../../assets/img/svg/Icon/Power.svg";
import { ReactComponent as Brush } from "../../assets/img/svg/Icon/Brush.svg";
import { ReactComponent as Home } from "../../assets/img/svg/Icon/Home.svg";
import { ReactComponent as AccountUsers } from "../../assets/img/svg/Icon/AccountUsers.svg";
import { ReactComponent as MyProfile } from "../../assets/img/svg/Icon/MyProfile.svg";
import { ReactComponent as NeedHelp } from "../../assets/img/svg/Icon/NeedHelp.svg";
import { ReactComponent as LogOut } from "../../assets/img/svg/Icon/LogOut.svg";
import { ReactComponent as GeneralUsers } from "../../assets/img/svg/Icon/GeneralUsers.svg";
import { ReactComponent as AdminUsers } from "../../assets/img/svg/Icon/AdminUsers.svg";
import { ReactComponent as AccountSubUsers } from "../../assets/img/svg/Icon/AccountSubUsers.svg";
import { ReactComponent as EmptyNotes } from "../../assets/img/svg/Icon/EmptyNotes.svg";
import { ReactComponent as Lock } from "../../assets/img/svg/Icon/Lock.svg";
import { ReactComponent as MobileCall } from "../../assets/img/svg/Icon/MobileCall.svg";
import { ReactComponent as DesktopCall } from "../../assets/img/svg/Icon/DesktopCall.svg";
import { ReactComponent as ShareVendorEmpty } from "../../assets/img/svg/Icon/ShareVendorEmpty.svg";
import { ReactComponent as Share2 } from "../../assets/img/svg/Icon/Share2.svg";
import { ReactComponent as ShareArrow } from "../../assets/img/svg/Icon/ShareArrow.svg";
import { ReactComponent as SmartphoneSMS } from "../../assets/img/svg/Icon/Smartphone.svg";
import { ReactComponent as ShareEmail } from "../../assets/img/svg/Icon/ShareEmail.svg";
import { ReactComponent as Facebook } from "../../assets/img/svg/Icon/Facebook.svg";
import { ReactComponent as Twitter } from "../../assets/img/svg/Icon/Twitter.svg";
import { ReactComponent as XIcon } from "../../assets/img/svg/Icon/XIcon.svg";
import { ReactComponent as Linkedin } from "../../assets/img/svg/Icon/Linkedin.svg";
import { ReactComponent as NoRecommendations } from "../../assets/img/svg/Icon/NoRecommendations.svg";
import { ReactComponent as CheckCircleInner } from "../../assets/img/svg/Icon/CheckCircleInner.svg";
import { ReactComponent as NoPreferredVendors } from "../../assets/img/svg/Icon/NoPreferredVendors.svg";
import { ReactComponent as Slash } from "../../assets/img/svg/Icon/Slash.svg";
import { ReactComponent as NotAuthorized } from "../../assets/img/svg/Icon/NotAuthorized.svg";
import { ReactComponent as LinkExpired } from "../../assets/img/svg/Icon/LinkExpired.svg";
import { ReactComponent as NoTrucks } from "../../assets/img/svg/Icon/NoTrucks.svg";
import { ReactComponent as IconUser } from "../../assets/img/svg/Icon/Icon-User.svg";
import { ReactComponent as UserX } from "../../assets/img/svg/Icon/User-x.svg";
import { ReactComponent as AlertTriangle } from "../../assets/img/svg/Icon/AlertTriangle.svg";
import { ReactComponent as MyRepair } from "../../assets/img/svg/Icon/MyRepair.svg";
import { ReactComponent as UserAvilable } from "../../assets/img/svg/Icon/UserAvilable.svg";
import { ReactComponent as UserUnavilable } from "../../assets/img/svg/Icon/UserUnavilable.svg";
import { ReactComponent as Vendor } from "../../assets/img/svg/Icon/Vendor.svg";
import { ReactComponent as DocumentOne } from "../../assets/img/svg/Icon/DocumentOne.svg";
import { ReactComponent as DocumentTwo } from "../../assets/img/svg/Icon/DocumentTwo.svg";
import { ReactComponent as NoDriver } from "../../assets/img/svg/Icon/NoDriver.svg";
import { ReactComponent as Upload } from "../../assets/img/svg/Icon/Upload.svg";
import { ReactComponent as TruckDisabled } from "../../assets/img/svg/Icon/TruckDisabled.svg";
import { ReactComponent as TruckEnabled } from "../../assets/img/svg/Icon/TruckEnabled.svg";
import { ReactComponent as UploadCloud } from "../../assets/img/svg/Icon/UploadCloud.svg";
import { ReactComponent as TruckAvilable } from "../../assets/img/svg/Icon/TruckAvilable.svg";
import { ReactComponent as TruckUnavilable } from "../../assets/img/svg/Icon/TruckUnavilable.svg";
import { ReactComponent as Driver } from "../../assets/img/svg/Icon/Driver.svg";
import { ReactComponent as ShareListing } from "../../assets/img/svg/Icon/ShareListing.svg";
import { ReactComponent as CardBlack } from "../../assets/img/svg/Icon/CardBlack.svg";
import { ReactComponent as GetMobileApp } from "../../assets/img/svg/Icon/GetMobileApp.svg";
import { ReactComponent as NoRecentSearches } from "../../assets/img/svg/Icon/NoRecentSearches.svg";
import { ReactComponent as NoSharedVendors } from "../../assets/img/svg/Icon/NoSharedVendors.svg";
import { ReactComponent as NoMyVendors } from "../../assets/img/svg/Icon/NoMyVendors.svg";
import { ReactComponent as NoMyNotes } from "../../assets/img/svg/Icon/NoMyNotes.svg";
import { ReactComponent as NoMyCalls } from "../../assets/img/svg/Icon/NoMyCalls.svg";
import { ReactComponent as ArrowRight2 } from "../../assets/img/svg/Icon/ArrowRight2.svg";
import { ReactComponent as NoRecommend } from "../../assets/img/svg/Icon/NoRecommend.svg";
import { ReactComponent as GreenCheckmark } from "../../assets/img/svg/Icon/GreenCheckmark.svg";
import { ReactComponent as InfoCircleIcon } from "../../assets/img/svg/Icon/InfoCircleIcon.svg";
import { ReactComponent as FleetTruck } from "../../assets/img/svg/fleet-truck.svg";
import { ReactComponent as FTSCardNew } from "../../assets/img/svg/Icon/FTSCardNew.svg";
import { ReactComponent as GreenCheckBig } from "../../assets/img/svg/Icon/GreenCheckBig.svg";
import { ReactComponent as NoRenewals } from "../../assets/img/svg/Icon/NoRenewals.svg";
import { ReactComponent as ListingPaid } from "../../assets/img/svg/Icon/ListingPaid.svg";
import { ReactComponent as CardMethod } from "../../assets/img/svg/Icon/CardMethod.svg";
import { ReactComponent as Checkmark } from "../../assets/img/svg/Icon/Checkmark.svg";
import { ReactComponent as LinkRight } from "../../assets/img/svg/Icon/LinkRight.svg";
import { ReactComponent as ChevronLeft } from "../../assets/img/svg/Icon/ChevronLeft.svg";
import { ReactComponent as LockFrame } from "../../assets/img/svg/Icon/LockFrame.svg";
import { ReactComponent as ExitWhite } from "../../assets/img/svg/Icon/ExitWhite.svg";
import { ReactComponent as LockDisabled } from "../../assets/img/svg/Icon/LockDisabled.svg";
import { ReactComponent as LockChecked } from "../../assets/img/svg/Icon/LockChecked.svg";
import { ReactComponent as FaRegCopy } from "../../assets/img/svg/faRegCopy.svg";
import { ReactComponent as CompleteDraft } from "../../assets/img/svg/Icon/CompleteDraft.svg";

import { ReactComponent as Alipay } from "../../assets/img/svg/cards/alipay.svg";
import { ReactComponent as Amex } from "../../assets/img/svg/cards/amex.svg";
import { ReactComponent as Diners } from "../../assets/img/svg/cards/diners.svg";
import { ReactComponent as Discover } from "../../assets/img/svg/cards/discover.svg";
import { ReactComponent as Elo } from "../../assets/img/svg/cards/elo.svg";
import { ReactComponent as Generic } from "../../assets/img/svg/cards/generic.svg";
import { ReactComponent as Hiper } from "../../assets/img/svg/cards/hiper.svg";
import { ReactComponent as Hipercard } from "../../assets/img/svg/cards/hipercard.svg";
import { ReactComponent as Jcb } from "../../assets/img/svg/cards/jcb.svg";
import { ReactComponent as Maestro } from "../../assets/img/svg/cards/maestro.svg";
import { ReactComponent as Mastercard } from "../../assets/img/svg/cards/mastercard.svg";
import { ReactComponent as Mir } from "../../assets/img/svg/cards/mir.svg";
import { ReactComponent as Unionpay } from "../../assets/img/svg/cards/unionpay.svg";
import { ReactComponent as Visa } from "../../assets/img/svg/cards/visa.svg";
import { ReactComponent as EyeIcon } from "../../assets/img/svg/Icon/EyeIcon.svg";

import { ReactComponent as Menu } from "../../assets/img/svg/Icon/Menu.svg";
import { ReactComponent as X } from "../../assets/img/svg/Icon/X.svg";
import { ReactComponent as ArrowRight3 } from "../../assets/img/svg/Icon/arrow-right-3.svg";
import { ReactComponent as ListingWithPlus } from "../../assets/img/svg/Icon/listing-with-plus.svg";
import { ReactComponent as Dashboard } from "../../assets/img/svg/Icon/Dashboard.svg";
import { ReactComponent as Questions } from "../../assets/img/svg/Icon/Questions.svg";
import { ReactComponent as SearchIcon } from "../../assets/img/svg/Icon/SearchIcon.svg";
import { ReactComponent as Dollar } from "../../assets/img/svg/Icon/Dollar.svg";
import { ReactComponent as TruckAndTrailer } from "../../assets/img/svg/Icon/TruckAndTrailer.svg";
import { ReactComponent as DownloadApp } from "../../assets/img/svg/DownloadApp.svg";
import { ReactComponent as DownloadAppTablet } from "../../assets/img/svg/DownloadAppTablet.svg";
import { ReactComponent as PhoneMobileApp } from "../../assets/img/svg/Icon/Phone.svg";
import { ReactComponent as Reviews } from "../../assets/img/svg/reviews.svg";
import { ReactComponent as EmailIllustration } from "../../assets/img/svg/EmailIllustration.svg";
import { ReactComponent as EmailIllustrationMobile } from "../../assets/img/svg/EmailIllustrationMobile.svg";
import { ReactComponent as EmailVerified } from "../../assets/img/svg/email-verified.svg";
import { ReactComponent as UserWarning } from "../../assets/img/svg/Icon/UserWarning.svg";

import "../../assets/scss/components/fts/icon.scss";

export const Icon = ({ name, color, size, ...rest }) => {
  size = size ?? 20;

  const props = {
    ...rest,
    width: size,
    height: size,
    className: rest.className ? rest.className : "fts-icon",
  };

  if (color) {
    props.className = "";
    props.fill = color;
    props.path = color;
  }

  const icons = {
    Account: <Account {...props} />,
    CheckActive: <CheckActive {...props} />,
    ResponsiveCheck: <ResponsiveCheck {...props} />,
    Payment: <Payment {...props} />,
    Delete: <Delete {...props} />,
    Edit: <Edit {...props} />,
    Eye: <Eye {...props} />,
    Files: <Files {...props} />,
    Label: <Label {...props} />,
    Megaphone: <Megaphone {...props} />,
    PaperBlank: <PaperBlank {...props} />,
    Towing: <Towing {...props} />,
    TowingNew: <TowingNew {...props} />,
    Password: <Password {...props} />,
    Email: <Email {...props} />,
    Logo: <Logo {...props} />,
    LoginKey: <LoginKey {...props} />,
    Tires: <Tires {...props} />,
    Dealers: <Dealers {...props} />,
    Repair: <Repair {...props} />,
    MobileRepair: <MobileRepair {...props} />,
    TruckRepair: <TruckRepair {...props} />,
    Parts: <Parts {...props} />,
    BgElement: <BgElement {...props} />,
    PaperScroll: <PaperScroll {...props} />,
    PaperText: <PaperText {...props} />,
    Todo: <Todo {...props} />,
    Todos: <Todos {...props} />,
    Note: <Note {...props} />,
    Listing: <Listing {...props} />,
    Listings: <Listings {...props} />,
    Promotion: <Promotion {...props} />,
    Report: <Report {...props} />,
    Marketing: <Marketing {...props} />,
    BackToList: <BackToList {...props} />,
    Back: <Back {...props} />,
    CollapseLeft: <CollapseLeft {...props} />,
    Time: <Time {...props} />,
    Attach: <Attach {...props} />,
    Reply: <Reply {...props} />,
    ArrowDown: <ArrowDown {...props} />,
    TodoNote: <TodoNote {...props} />,
    Accounts: <Accounts {...props} />,
    AddNote: <AddNote {...props} />,
    Bookmark: <Bookmark {...props} />,
    Calendar: <Calendar {...props} />,
    Notifications: <Notifications {...props} />,
    ArrowRightLG: <ArrowRightLG {...props} />,
    Search: <Search {...props} />,
    Owner: <Owner {...props} />,
    Share: <Share {...props} />,
    Archive: <Archive {...props} />,
    AddToCalendar: <AddToCalendar {...props} />,
    Drag: <Drag {...props} />,
    Placeholder: <Placeholder {...props} />,
    EmptySearch: <EmptySearch {...props} />,
    Updates: <Updates {...props} />,
    NoteBottom: <NoteBottom {...props} />,
    Downgrading: <Downgrading {...props} />,
    DropdownEdit: <DropdownEdit {...props} />,
    ArrowRight: <ArrowRight {...props} />,
    Move: <Move {...props} />,
    User: <User {...props} />,
    DropdownArchive: <DropdownArchive {...props} />,
    Meta: <Meta {...props} />,
    Renewals: <Renewals {...props} />,
    Sales: <Sales {...props} />,
    MoreVertical: <MoreVertical {...props} />,
    MoreHorizontal: <MoreHorizontal {...props} />,
    Checked: <Checked {...props} />,
    CheckedRed: <CheckedRed {...props} />,
    Phone: <Phone {...props} />,
    ListingActivity: <ListingActivity {...props} />,
    Profile: <Profile {...props} />,
    CheckCircle: <CheckCircle {...props} />,
    CheckCircle2: <CheckCircle2 {...props} />,
    EditAction: <EditAction {...props} />,
    FileText: <FileText {...props} />,
    Tag: <Tag {...props} />,
    PieChart: <PieChart {...props} />,
    BarChart: <BarChart {...props} />,
    CreditCard: <CreditCard {...props} />,
    GoTo: <GoTo {...props} />,
    Crown: <Crown {...props} />,
    AttachBottom: <AttachBottom {...props} />,
    TodoArchived: <TodoArchived {...props} />,
    ArrowLeft: <ArrowLeft {...props} />,
    Duplicate: <DuplicateIcon {...props} />,
    Info: <Info {...props} />,
    ChevronDown: <ChevronDown {...props} />,
    ChevronUp: <ChevronUp {...props} />,
    CopyLink: <CopyLink {...props} />,
    Unpin: <Unpin {...props} />,
    Flag: <Flag {...props} />,
    ClearCalendar: <ClearCalendar {...props} />,
    Location: <Location {...props} />,
    PhoneBlue: <PhoneBlue {...props} />,
    Trash: <Trash {...props} />,
    UserAccount: <UserAccount {...props} />,
    SalesDashboard: <SalesDashboard {...props} />,
    LeadsPool: <LeadsPool {...props} />,
    Customers: <Customers {...props} />,
    ActiveLeads: <ActiveLeads {...props} />,
    LogACall: <LogACall {...props} />,
    LogAnEmail: <LogAnEmail {...props} />,
    SaveWithoutLogging: <SaveWithoutLogging {...props} />,
    ReassignAccountConfirmation: <ReassignAccountConfirmation {...props} />,
    Reassign: <Reassign {...props} />,
    Preview: <Preview {...props} />,
    Update: <Update {...props} />,
    EditAccount: <EditAccount {...props} />,
    Invoices: <Invoices {...props} />,
    File: <File {...props} />,
    Card: <Card {...props} />,
    Link: <Link {...props} />,
    CheckIcon: <CheckIcon {...props} />,
    InfoCircle: <InfoCircle {...props} />,
    MasterCard: <MasterCard {...props} />,

    AmeEx: <AmeEx {...props} />,
    Download: <Download {...props} />,
    Plus: <Plus {...props} />,
    Exit: <Exit {...props} />,
    Open: <Open {...props} />,
    Send: <Send {...props} />,
    SendAction: <SendAction {...props} />,
    SendActivity: <SendActivity {...props} />,
    OpenAction: <OpenAction {...props} />,
    UpdateAction: <UpdateAction {...props} />,
    PreviewAction: <PreviewAction {...props} />,
    CardRemove: <CardRemove {...props} />,
    ACH: <ACH {...props} />,
    Plaid: <Plaid {...props} />,
    Paypal: <Paypal {...props} />,
    LeadsDashboard: <LeadsDashboard {...props} />,
    TrialsDashboard: <TrialsDashboard {...props} />,
    RenewalsDashboard: <RenewalsDashboard {...props} />,
    RenewalsSection: <RenewalsSection {...props} />,
    CallsDashboard: <CallsDashboard {...props} />,
    EmailsDashboard: <EmailsDashboard {...props} />,
    UpdatesDashboard: <UpdatesDashboard {...props} />,
    NewtrialsDashboard: <NewtrialsDashboard {...props} />,
    NewListings: <NewListings {...props} />,
    DealsDashboard: <DealsDashboard {...props} />,
    Upgrades: <Upgrades {...props} />,
    Downgrades: <Downgrades {...props} />,
    EmptyTodos: <EmptyTodos {...props} />,
    ArrowLink: <ArrowLink {...props} />,
    ArrowNext: <ArrowNext {...props} />,
    Congratulations: <Congratulations {...props} />,
    CheckGreen: <CheckGreen {...props} />,
    UpdateActivity: <UpdateActivity {...props} />,
    Projection: <Projection {...props} />,
    ActivityWhite: <ActivityWhite {...props} />,
    Notification: <Notification {...props} />,
    Close: <Close {...props} />,
    ApproveTodo: <ApproveTodo {...props} />,
    Filter: <Filter {...props} />,
    TodoActivity: <TodoActivity {...props} />,
    NoteActivity: <NoteActivity {...props} />,
    PromotionActivity: <PromotionActivity {...props} />,
    UpdatesMenuItem: <UpdatesMenuItem {...props} />,
    ListingUpdate: <ListingUpdate {...props} />,
    ListingSuccessful: <ListingSuccessful {...props} />,
    BackButton: <BackButton {...props} />,
    ClientLogo: <ClientLogo {...props} />,
    GoogleSvg: <GoogleSvg {...props} />,
    FacebookSvg: <FacebookSvg {...props} />,
    AppleSvg: <AppleSvg {...props} />,
    CheckWhite: <CheckWhite {...props} />,
    Star: <Star {...props} />,
    Notes: <Notes {...props} />,
    DollarCircle: <DollarCircle {...props} />,
    Setting: <Setting {...props} />,
    Review: <Review {...props} />,
    PromotionTag: <PromotionTag {...props} />,
    NoProofs: <NoProofs {...props} />,
    CreditCardRed: <CreditCardRed {...props} />,
    Miles: <Miles {...props} />,
    IconInfo: <IconInfo {...props} />,
    MapPin: <MapPin {...props} />,
    Clock: <Clock {...props} />,
    Fuel: <Fuel {...props} />,
    CheckCircleInfo: <CheckCircleInfo {...props} />,
    GoStandardIcon: <GoStandardIcon {...props} />,
    GoStandardScale: <GoStandardScale {...props} />,
    GoBasicPlusIcon: <GoBasicPlusIcon {...props} />,
    GoBasicIcon: <GoBasicIcon {...props} />,
    GoBasicPlusScale: <GoBasicPlusScale {...props} />,
    GoBasicScale: <GoBasicScale {...props} />,
    GoPremiumIcon: <GoPremiumIcon {...props} />,
    GoPremiumScale: <GoPremiumScale {...props} />,
    ImageIcon: <ImageIcon {...props} />,
    UserPlus: <UserPlus {...props} />,
    CreditCardWhite: <CreditCardWhite {...props} />,
    UnderReview: <UnderReview {...props} />,
    DotChecked: <DotChecked {...props} />,
    DotCheckedGreen: <DotCheckedGreen {...props} />,
    DotActive: <DotActive {...props} />,
    AppStoreBadge: <AppStoreBadge {...props} />,
    GooglePlayBadge: <GooglePlayBadge {...props} />,
    EnrollNow: <EnrollNow {...props} />,
    Copy: <Copy {...props} />,
    IncreaseSales: <IncreaseSales {...props} />,
    AttractCustomers: <AttractCustomers {...props} />,
    BuildLoyalty: <BuildLoyalty {...props} />,
    FiveMinuteApplication: <FiveMinuteApplication {...props} />,
    HourFunding: <HourFunding {...props} />,
    NoRecourse: <NoRecourse {...props} />,
    DiscountsAndRebates: <DiscountsAndRebates {...props} />,
    FreePOPMaterials: <FreePOPMaterials {...props} />,
    NoHiddenCosts: <NoHiddenCosts {...props} />,
    GiftBox: <GiftBox {...props} />,
    UserProfile: <UserProfile {...props} />,
    GoogleInfoArrow: <GoogleInfoArrow {...props} />,
    ListView: <ListView {...props} />,
    MapView: <MapView {...props} />,
    SendIcon: <SendIcon {...props} />,
    MyListingsPreview: <MyListingsPreview {...props} />,
    CollapseArrow: <CollapseArrow {...props} />,
    ListingsDashboard: <ListingsDashboard {...props} />,
    SearchExposureDashboard: <SearchExposureDashboard {...props} />,
    InstantFinancingDashboard: <InstantFinancingDashboard {...props} />,
    ReferAFriendDashboard: <ReferAFriendDashboard {...props} />,
    ReportIcon: <ReportIcon {...props} />,
    EmailIcon: <EmailIcon {...props} />,
    ReportInfo: <ReportInfo {...props} />,
    ReportImage: <ReportImage {...props} />,
    HelpCircle: <HelpCircle {...props} />,
    Smartphone: <Smartphone {...props} />,
    Desktop: <Desktop {...props} />,
    ReferralSuccessfull: <ReferralSuccessfull {...props} />,
    Truck: <Truck {...props} />,
    Power: <Power {...props} />,
    Brush: <Brush {...props} />,
    Home: <Home {...props} />,
    AccountUsers: <AccountUsers {...props} />,
    MyProfile: <MyProfile {...props} />,
    NeedHelp: <NeedHelp {...props} />,
    LogOut: <LogOut {...props} />,
    ExternalLink: <ExternalLink {...props} />,
    GeneralUsers: <GeneralUsers {...props} />,
    AdminUsers: <AdminUsers {...props} />,
    AccountSubUsers: <AccountSubUsers {...props} />,
    SendProof: <SendProof {...props} />,
    EmptyNotes: <EmptyNotes {...props} />,
    Lock: <Lock {...props} />,
    MobileCall: <MobileCall {...props} />,
    DesktopCall: <DesktopCall {...props} />,
    ShareVendorEmpty: <ShareVendorEmpty {...props} />,
    NoCallLogs: <NoCallLogs {...props} />,
    Share2: <Share2 {...props} />,
    ShareArrow: <ShareArrow {...props} />,
    SmartphoneSMS: <SmartphoneSMS {...props} />,
    ShareEmail: <ShareEmail {...props} />,
    Facebook: <Facebook {...props} />,
    Twitter: <Twitter {...props} />,
    XIcon: <XIcon {...props} />,
    Linkedin: <Linkedin {...props} />,
    NoReports: <NoReports {...props} />,
    NoSubAccounts: <NoSubAccounts {...props} />,
    NoRecommendations: <NoRecommendations {...props} />,
    CheckCircleInner: <CheckCircleInner {...props} />,
    NoPreferredVendors: <NoPreferredVendors {...props} />,
    Slash: <Slash {...props} />,
    NotAuthorized: <NotAuthorized {...props} />,
    LinkExpired: <LinkExpired {...props} />,
    NoTrucks: <NoTrucks {...props} />,
    IconUser: <IconUser {...props} />,
    UserX: <UserX {...props} />,
    AlertTriangle: <AlertTriangle {...props} />,
    MyRepair: <MyRepair {...props} />,
    UserAvilable: <UserAvilable {...props} />,
    UserUnavilable: <UserUnavilable {...props} />,
    NoDriver: <NoDriver {...props} />,
    Upload: <Upload {...props} />,
    TruckDisabled: <TruckDisabled {...props} />,
    TruckEnabled: <TruckEnabled {...props} />,
    UploadCloud: <UploadCloud {...props} />,
    TruckAvilable: <TruckAvilable {...props} />,
    TruckUnavilable: <TruckUnavilable {...props} />,
    LinkSuccessfull: <LinkSuccessfull {...props} />,
    Reinstate: <Reinstate {...props} />,
    Driver: <Driver {...props} />,
    LinkedCompany: <LinkedCompany {...props} />,
    ShareListing: <ShareListing {...props} />,
    DollarSign: <DollarSign {...props} />,
    CardBlack: <CardBlack {...props} />,
    GetMobileApp: <GetMobileApp {...props} />,
    NoRecentSearches: <NoRecentSearches {...props} />,
    NoSharedVendors: <NoSharedVendors {...props} />,
    NoMyVendors: <NoMyVendors {...props} />,
    NoMyNotes: <NoMyNotes {...props} />,
    NoMyCalls: <NoMyCalls {...props} />,
    ArrowRight2: <ArrowRight2 {...props} />,
    NoRecommend: <NoRecommend {...props} />,
    GreenCheckmark: <GreenCheckmark {...props} />,
    InfoCircleIcon: <InfoCircleIcon {...props} />,
    FleetTruck: <FleetTruck {...props} />,
    FTSCardNew: <FTSCardNew {...props} />,
    Unsubscribe: <Unsubscribe {...props} />,
    UnsubscribeRed: <UnsubscribeRed {...props} />,
    GreenCheckBig: <GreenCheckBig {...props} />,
    NoRenewals: <NoRenewals {...props} />,
    ListingPaid: <ListingPaid {...props} />,
    CardMethod: <CardMethod {...props} />,
    Checkmark: <Checkmark {...props} />,
    Diagram: <Diagram {...props} />,
    LinkRight: <LinkRight {...props} />,
    ChevronLeft: <ChevronLeft {...props} />,
    AccessRequested: <AccessRequested {...props} />,
    LockFrame: <LockFrame {...props} />,
    ExitWhite: <ExitWhite {...props} />,
    AccessGranted: <AccessGranted {...props} />,
    AccessRejected: <AccessRejected {...props} />,
    AccessRequestExpired: <AccessRequestExpired {...props} />,
    LockDisabled: <LockDisabled {...props} />,
    LockChecked: <LockChecked {...props} />,
    FaRegCopy: <FaRegCopy {...props} />,

    Visa: <Visa {...props} />,
    Mastercard: <Mastercard {...props} />,
    "American Express": <Amex {...props} />,
    "Diners Club": <Diners {...props} />,
    Discover: <Discover {...props} />,
    JCB: <Jcb {...props} />,
    UnionPay: <Unionpay {...props} />,
    Maestro: <Maestro {...props} />,
    Mir: <Mir {...props} />,
    Elo: <Elo {...props} />,
    Hiper: <Hiper {...props} />,
    Hipercard: <Hipercard {...props} />,
    Alipay: <Alipay {...props} />,
    Generic: <Generic {...props} />,
    CompleteDraft: <CompleteDraft {...props} />,

    BenefitsCategories: <BenefitsCategories {...props} />,
    BenefitsDollar: <BenefitsDollar {...props} />,
    BenefitsEdit: <BenefitsEdit {...props} />,
    BenefitsImage: <BenefitsImage {...props} />,
    BenefitsPlacement: <BenefitsPlacement {...props} />,
    BenefitsLowerPlacement: <BenefitsLowerPlacement {...props} />,
    BenefitsPromotion: <BenefitsPromotion {...props} />,
    BenefitsSuggest: <BenefitsSuggest {...props} />,
    BenefitsWebsite: <BenefitsWebsite {...props} />,
    BenefitsCrown: <BenefitsCrown {...props} />,
    BenefitsMap: <BenefitsMap {...props} />,
    Menu: <Menu {...props} />,
    X: <X {...props} />,
    PaypalMobile: <PaypalMobile {...props} />,
    MobileSearch: <MobileSearch {...props} />,
    MobileSearchGray: <MobileSearchGray {...props} />,
    ArrowRight3: <ArrowRight3 {...props} />,
    ListingWithPlus: <ListingWithPlus {...props} />,
    Dashboard: <Dashboard {...props} />,
    Questions: <Questions {...props} />,
    SearchIcon: <SearchIcon {...props} />,
    Dollar: <Dollar {...props} />,
    TruckAndTrailer: <TruckAndTrailer {...props} />,
    DownloadApp: <DownloadApp {...props} />,
    DownloadAppTablet: <DownloadAppTablet {...props} />,
    PhoneMobileApp: <PhoneMobileApp {...props} />,
    RenewalsBanner: <RenewalsBanner {...props} />,
    ListingSuccess: <ListingSuccess {...props} />,
    Vendors: <Vendors {...props} />,
    Repairs: <Repairs {...props} />,
    Vendor: <Vendor {...props} />,
    DocumentOne: <DocumentOne {...props} />,
    DocumentTwo: <DocumentTwo {...props} />,
    Reviews: <Reviews {...props} />,
    EyeIcon: <EyeIcon {...props} />,
    EmailIllustration: <EmailIllustration {...props} />,
    EmailIllustrationMobile: <EmailIllustrationMobile {...props} />,
    EmailVerified: <EmailVerified {...props} />,
    UserWarning: <UserWarning {...props} />,
    HoursOpen: <HoursOpen {...props} />,
    HoursClosingSoon: <HoursClosingSoon {...props} />,
    HoursClosed: <HoursClosed {...props} />,
    Add: <Add {...props} />,
    RoundedCheckmark: <RoundedCheckmark {...props} />,
    RoundedStop: <RoundedStop {...props} />,
    AfterETC: <AfterETC {...props} />,
    BeforeETC: <BeforeETC {...props} />,
    JustOnTimeETC: <JustOnTimeETC {...props} />,
    EditDriver: <EditDriver {...props} />,
  };
  const Component = icons[name] || null;
  return Component;
};

export default Icon;
