import React, { useCallback, useState } from "react";
import { isObject } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { setLoginData } from "../../../../redux/actions/v2/login";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import {
  selectLoginData,
  selectLoginInlineMessage,
  selectLoginInvalidFormFields,
} from "../../../../redux/reducers/v2/login";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";

export const LoginFormInputFields = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const accountData = useSelector(selectLoginData);
  const inlineMessage = useSelector(selectLoginInlineMessage);
  const invalidFormFields = useSelector(selectLoginInvalidFormFields);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const setStoreData = useCallback(
    (data) => dispatch(setLoginData({ data })),
    [dispatch],
  );

  const onChange = useCallback((event) => {
    setStoreData({ [event.target.name]: event.target.value });
  }, []);

  return (
    <FtsColumn size={{ xs: 12 }}>
      <FtsRow columnGap={"lg"} rowGap={"2xl"}>
        <FtsColumn size={{ xs: 12 }}>
          <Input
            required
            name={"email"}
            label={"Email"}
            onChange={onChange}
            size={InputSizes.md}
            invalid={inlineMessage}
            value={accountData?.email}
            variant={InputVariants.outlined}
            errorMessage={
              isObject(invalidFormFields) && invalidFormFields["email"]
            }
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }}>
          <Input
            required
            name={"password"}
            label={"Password"}
            onChange={onChange}
            size={InputSizes.md}
            invalid={inlineMessage}
            value={accountData?.password}
            variant={InputVariants.outlined}
            appendIcon={IconNames.EyeIcon}
            onAppendIconClick={toggleShowPassword}
            type={showPassword ? "text" : "password"}
            errorMessage={
              isObject(invalidFormFields) && invalidFormFields["password"]
            }
          />
        </FtsColumn>
      </FtsRow>
    </FtsColumn>
  );
};
