import React, { useCallback, useEffect, useState } from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { ListingItem } from "@find-truck-service/ui/src/components/react/ListingItem";
import { ListingItemsTypeValues } from "@find-truck-service/types/ui/listingItemComponent";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { TemporaryLinkPageTitle } from "../../../components/fts-components-v2/TemporaryLink/TemporaryLinkPageTitle/TemporaryLinkPageTitle";
import { TemporaryLinkContentContainer } from "../../../components/fts-components-v2/TemporaryLink/TemporaryLinkContentContainer/TemporaryLinkContentContainer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectTemporaryLinkIsLoading,
  selectTemporaryLinkListingActiveBanner,
  selectTemporaryLinkListingPreviewData,
  selectTemporaryLinkListingSelectedPrice,
  selectTemporaryListingLinkInvoices,
} from "../../../redux/reducers/v2/temporaryListingLink";
import { RenewalsFooterButtons } from "./components/RenewalsFooterButtons";
import { Banner } from "@find-truck-service/ui/src/components/react/Banners";
import { INVOICES_TYPES_ENUM } from "@find-truck-service/types/constants/invoices";
import {
  setTemporaryLinkActiveBanner,
  setTemporaryLinkSelectedListingPrice,
} from "../../../redux/actions/v2/temporaryLinkListing";
import {
  bannerTitles,
  bannerTypes,
  bannerTypesIds,
} from "../../../constants/temporaryLinkBanners";
import { LISTING_STATUSES } from "../../../constants";
import { RenewalsListingRenewedFooterButtons } from "./components/RenewalsListingRenewedFooterButtons";
import { RenewalsListingExpiredFooterButtons } from "./components/RenewalsListingExpiredFooterButtons";
import { TemporaryLinkUpgradeListingOptions } from "../../../components/fts-components-v2/TemporaryLinkUpgradeListingOptions";
import { SelectedUpgradeListingFooterButtons } from "../temporary-link-preview-listing/components/SelectedUpgradeListingFooterButtons";

import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";

const renewalsTempLinkFooters = {
  NOT_RENEWED: "not_renewed",
  RENEWED: "renewed",
  EXPIRED: "expired",
};

export const TemporaryLinkRenewal = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectTemporaryLinkIsLoading);
  const listingData = useSelector(selectTemporaryLinkListingPreviewData);
  const activeBanner = useSelector(selectTemporaryLinkListingActiveBanner);
  const invoices = useSelector(selectTemporaryListingLinkInvoices);

  const selectedUpgradeListingPrice = useSelector(
    selectTemporaryLinkListingSelectedPrice,
  );

  const [activeFooter, setActiveFooter] = useState(
    renewalsTempLinkFooters.NOT_RENEWED,
  );

  useEffect(() => {
    if (
      !invoices?.length ||
      invoices[0].type !== INVOICES_TYPES_ENUM.RENEWAL ||
      !listingData
    )
      return;

    handleActiveBanner(invoices, listingData);
  }, [invoices, listingData]);

  const handleActiveBanner = (invoices, listingData) => {
    let bannerType;
    let bannerTitle;

    const currentDate = moment();
    const renewalDate = moment(invoices[0].dueAt);
    const thirtyDaysFromNow = moment().add(30, "days");

    const isListingActive = listingData?.status === LISTING_STATUSES.ACTIVE;
    const isPastDue = renewalDate.isBefore(currentDate);
    const isRenewalSoon = renewalDate.isAfter(currentDate);
    const isExpiringToday = renewalDate.isBetween(
      moment().startOf("day"),
      moment().endOf("day"),
    );
    const isExpired = renewalDate.isBefore(
      moment().subtract(1, "days").startOf("day"),
    );
    const isRenewed = moment
      .unix(listingData?.renewalTimestamp)
      .isAfter(thirtyDaysFromNow);

    if (isPastDue) {
      bannerType = bannerTypes[bannerTypesIds.RENEWAL_PAST_DUE];
      bannerTitle = bannerTitles[bannerTypesIds.RENEWAL_PAST_DUE];
    }

    if (isExpiringToday) {
      bannerType = bannerTypes[bannerTypesIds.RENEWAL_DUE_TODAY];
      bannerTitle = bannerTitles[bannerTypesIds.RENEWAL_DUE_TODAY];
    }

    if (isRenewalSoon && !isExpiringToday) {
      bannerType = bannerTypes[bannerTypesIds.RENEWAL_SOON];
      bannerTitle =
        bannerTitles[bannerTypesIds.RENEWAL_SOON] +
        renewalDate.format("MMM D, YYYY");
    }

    if (isExpired) {
      setActiveFooter(renewalsTempLinkFooters.EXPIRED);
      bannerType = bannerTypes[bannerTypesIds.LISTING_EXPIRED];
      bannerTitle = bannerTitles[bannerTypesIds.LISTING_EXPIRED];
    }

    if (isRenewed && isListingActive) {
      setActiveFooter(renewalsTempLinkFooters.RENEWED);
      bannerType = bannerTypes[bannerTypesIds.LISTING_RENEWED];
      bannerTitle =
        bannerTitles[bannerTypesIds.LISTING_RENEWED] +
        moment.unix(listingData?.renewalTimestamp).format("MMM D, YYYY");
    }

    dispatch(
      setTemporaryLinkActiveBanner({
        data: {
          type: bannerType,
          title: bannerTitle,
        },
      }),
    );
  };

  const handleChangeListingSelection = useCallback(
    (newListingPrice) => {
      if (
        selectedUpgradeListingPrice &&
        newListingPrice.id === selectedUpgradeListingPrice.id
      ) {
        dispatch(setTemporaryLinkSelectedListingPrice(null));
        return;
      }
      dispatch(setTemporaryLinkSelectedListingPrice(newListingPrice));
    },
    [selectedUpgradeListingPrice],
  );

  if (!listingData) return <FullPageLoader />;

  return (
    <>
      {isLoading && <FullPageLoader />}
      <TemporaryLinkContentContainer>
        <Banner title={activeBanner?.title} type={activeBanner?.type} />
        <FtsWrapper flex={1} overflow={"auto"}>
          <FtsContainer mb={"2xl"}>
            <TemporaryLinkPageTitle title="Your listing" />
            <ListingItem
              listingData={listingData}
              type={ListingItemsTypeValues.LIVE}
            />
            <TemporaryLinkUpgradeListingOptions
              selectedUpgradeListingPrice={selectedUpgradeListingPrice}
              handleChangeListingSelection={handleChangeListingSelection}
            />
          </FtsContainer>
        </FtsWrapper>
        {selectedUpgradeListingPrice ? (
          <SelectedUpgradeListingFooterButtons />
        ) : activeFooter === renewalsTempLinkFooters.NOT_RENEWED ? (
          <RenewalsFooterButtons />
        ) : activeFooter === renewalsTempLinkFooters.RENEWED ? (
          <RenewalsListingRenewedFooterButtons />
        ) : activeFooter === renewalsTempLinkFooters.EXPIRED ? (
          <RenewalsListingExpiredFooterButtons />
        ) : (
          <EmptyComponent />
        )}
      </TemporaryLinkContentContainer>
    </>
  );
};
