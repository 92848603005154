import {
  SET_USER_LIST,
  SET_USER_STICKY_LIST,
  CLEAR_USER_LIST,
  CLEAR_USER_STICKY_LIST,
  REMOVE_USER_NOTE,
  RESET_STATE,
} from "../../actions/user-notes";

const initialState = {
  list: [],
  stickyList: [],
};

const UserNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LIST:
      return { ...state, list: action.data };

    case SET_USER_STICKY_LIST:
      return { ...state, stickyList: action.data };

    case CLEAR_USER_LIST:
      return { ...state, list: [] };

    case CLEAR_USER_STICKY_LIST:
      return { ...state, stickyList: [] };

    case REMOVE_USER_NOTE:
      return { ...state, list: [] };

    case RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default UserNotesReducer;
