import axios from "axios";
import { toast } from "react-toastify";
import { history } from "../../../history";
import FTSV2Axios from "../../../axios/ftsv2.instance";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { extractParameter } from "../../../utility/param-extract";
import { generateFileForUpload } from "../../../utility/uploadFile";
import {
  selectAdsRates,
  selectAmenities,
  selectServiceAmenities,
  selectSubCategories,
} from "../../reducers/essentialLists";
import {
  formatListingDataPayload,
  formatListingResponse,
  handleErrorMessage,
  hasOnSuccessFunction,
} from "./helpers";
import {
  CREATE_LISTING_INVOICE_REQUEST,
  CREATE_LISTING_REQUEST,
  GET_DRAFT_LISTING_REQUEST,
  GET_LISTING_BY_ID_REQUEST,
  selectListingData,
  UPDATE_LISTING_REQUEST,
  UPLOAD_ADS_ASSETS_REQUEST,
} from "../../reducers/v2/listing";
import {
  createListingInvoiceRequestFailed,
  createListingInvoiceRequestSuccess,
  createListingRequestFailed,
  createListingRequestSuccess,
  getDraftListingFailed,
  getDraftListingSuccess,
  getListingDataByIdFailed,
  getListingDataByIdSuccess,
  setAdsAssetForUpload,
  updateListingRequestFailed,
  updateListingRequestSuccess,
  uploadAdsAssetsRequestFailed,
  uploadAdsAssetsRequestSuccess,
} from "../../actions/v2/listing";
import { UNEXPECTED_ISSUE } from "../../../utility/errorMessages";
import logger from "../../../utility/logger";

export const UPLOADING_ASSET_ERROR = "Error while uploading asset";
const CREATE_LISTING_ERROR = "Error while creating listing";
const CREATE_LISTING_SUCCESS = "Listing created successfully";
const FETCH_LISTING_ERROR = "Error while fetching listing";
const FETCH_DRAFT_LISTING_ERROR = "Error while fetching draft listing";
export const CHOOSE_LISTING_TYPE_ERROR = "Error while choosing listing type";
export const UPDATE_LISTING_ERROR_MSG = "Error while updating listing";
export const UPDATE_LISTING_SUCCESS_MSG = "Listing has been updated";

function* createListingRequestSaga() {
  try {
    const rates = yield select(selectAdsRates);
    const amenities = yield select(selectAmenities);
    const listingData = yield select(selectListingData);
    const subcategories = yield select(selectSubCategories);
    const serviceAmenities = yield select(selectServiceAmenities);
    const priceId = extractParameter("selectedListingPriceId");
    const convertedToVendor = extractParameter("convertedToVendor");
    const payload = formatListingDataPayload(
      { ...listingData, priceId: parseInt(priceId) },
      rates,
      subcategories,
    );

    const response = yield call(FTSV2Axios.post, `ads/create`, payload);
    const createdListing = response.data.data;
    const data = formatListingResponse(
      createdListing,
      rates,
      amenities,
      serviceAmenities,
    );

    yield put(createListingRequestSuccess(data));
    toast.success(CREATE_LISTING_SUCCESS);

    const searchParams = new URLSearchParams();

    if (priceId) {
      searchParams.set("selectedListingPriceId", priceId);
    }

    if (convertedToVendor) {
      searchParams.set("convertedToVendor", convertedToVendor);
    }

    history.push({
      pathname: `/dashboard/get-listed/preview-listing/${createdListing.id}`,
      search: `?${searchParams.toString()}`,
    });
  } catch (e) {
    const message = handleErrorMessage(e, CREATE_LISTING_ERROR);
    const inlineMessage = e?.response?.data?.inlineMessage;
    toast.error(message);
    yield put(createListingRequestFailed({ data: { message, inlineMessage } }));
  }
}

function* updateListingRequestSaga(action) {
  try {
    const rates = yield select(selectAdsRates);
    const amenities = yield select(selectAmenities);
    const listingData = yield select(selectListingData);
    const subcategories = yield select(selectSubCategories);
    const serviceAmenities = yield select(selectServiceAmenities);

    const UPDATE_URL = `ads/${listingData.id}`;
    const payload = formatListingDataPayload(listingData, rates, subcategories);

    const response = yield call(FTSV2Axios.put, UPDATE_URL, payload);
    const updatedListing = response.data.data;
    const data = formatListingResponse(
      updatedListing,
      rates,
      amenities,
      serviceAmenities,
    );

    yield put(updateListingRequestSuccess(data));
    toast.success(UPDATE_LISTING_SUCCESS_MSG);
    if (hasOnSuccessFunction(action))
      action.payload.onSuccess(updatedListing.id);
  } catch (e) {
    const message = handleErrorMessage(e, UPDATE_LISTING_ERROR_MSG);
    const inlineMessage = e?.response?.data?.inlineMessage;
    toast.error(message);
    yield put(updateListingRequestFailed({ data: { message, inlineMessage } }));
  }
}

function* uploadAdsAssetRequestSaga(action) {
  try {
    const listingData = yield select(selectListingData);
    const filesForUpload = [];

    for (let file of action.payload.data) {
      const fileForUpload = yield generateFileForUpload(file, listingData);
      yield put(setAdsAssetForUpload(fileForUpload.adsAsset));
      filesForUpload.push(fileForUpload);
    }

    for (let fileForUpload of filesForUpload) {
      const uploadConfig = fileForUpload.uploadConfig;
      const filePath = fileForUpload.fileName;
      const mimeType = fileForUpload.type;
      const blob = fileForUpload.blob;
      const params = { filePath, mimeType };
      const UPLOAD_URL = "/ads/images/image-upload-url";

      try {
        const response = yield call(FTSV2Axios.get, UPLOAD_URL, { params });
        const uploadUrl = response.data.data.uploadUrl;
        yield call(axios.put, uploadUrl, blob, uploadConfig);
        yield put(uploadAdsAssetsRequestSuccess(filePath));
      } catch (e) {
        handleErrorMessage(e, UPLOADING_ASSET_ERROR);
        yield put(uploadAdsAssetsRequestFailed(filePath));
      }
    }
  } catch (e) {
    handleErrorMessage(e, UPLOADING_ASSET_ERROR);
  }
}

function* getListingDataRequestSaga(action) {
  try {
    const rates = yield select(selectAdsRates);
    const amenities = yield select(selectAmenities);
    const serviceAmenities = yield select(selectServiceAmenities);

    const FETCH_URL = `ads/${action.payload.data.id}`;
    const response = yield call(FTSV2Axios.get, FETCH_URL);
    const listingData = response.data.data;

    const data = formatListingResponse(
      listingData,
      rates,
      amenities,
      serviceAmenities,
    );
    yield put(getListingDataByIdSuccess({ data }));
  } catch (e) {
    const message = handleErrorMessage(e, FETCH_LISTING_ERROR);
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(getListingDataByIdFailed({ data: { message, inlineMessage } }));
  }
}

function* getDraftListingRequestSaga(action) {
  try {
    const FETCH_URL = "ads/drafts";
    const response = yield call(FTSV2Axios.get, FETCH_URL);
    const data = response.data.data;
    yield put(getDraftListingSuccess({ data }));
    if (typeof action.payload?.onSuccess === "function") {
      action.payload.onSuccess(data);
    }
  } catch (e) {
    logger.error(e);
    const message = handleErrorMessage(e, FETCH_DRAFT_LISTING_ERROR);
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(getDraftListingFailed({ data: { message, inlineMessage } }));
  }
}

function* createListingInvoice(action) {
  const { data, onSuccess, onError } = action.payload;
  try {
    const listingData = yield select(selectListingData);
    const { priceId: selectedPriceId } = data;
    const { priceId: listingPriceId, id } = listingData;

    const UPDATE_LISTING_ENDPOINT_URL = `ads/${id}`;
    const CREATE_INVOICE_ENDPOINT_URL = "invoices/create-listing-invoice";

    // IF USER SELECTED DIFFERENT priceId, UPDATE LISTING BEFORE GENERATING INVOICE
    if (listingPriceId !== selectedPriceId) {
      yield call(FTSV2Axios.put, UPDATE_LISTING_ENDPOINT_URL, {
        listingData: { priceId: selectedPriceId },
      });
    }

    yield call(FTSV2Axios.post, CREATE_INVOICE_ENDPOINT_URL, { adId: id });
    yield put(createListingInvoiceRequestSuccess());
    if (typeof onSuccess === "function") onSuccess();
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while choosing listing type!";
    const inlineMessage = e?.response?.data?.inlineMessage || UNEXPECTED_ISSUE;
    yield put(
      createListingInvoiceRequestFailed({ data: { message, inlineMessage } }),
    );
    if (typeof onError === "function") onError({ message });
  }
}

export default function* listingWatcher() {
  yield takeLatest(UPLOAD_ADS_ASSETS_REQUEST, uploadAdsAssetRequestSaga);
  yield takeLatest(CREATE_LISTING_REQUEST, createListingRequestSaga);
  yield takeLatest(UPDATE_LISTING_REQUEST, updateListingRequestSaga);
  yield takeLatest(GET_LISTING_BY_ID_REQUEST, getListingDataRequestSaga);
  yield takeLatest(GET_DRAFT_LISTING_REQUEST, getDraftListingRequestSaga);
  yield takeLatest(CREATE_LISTING_INVOICE_REQUEST, createListingInvoice);
}
