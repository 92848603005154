import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { TemporaryLinkPages } from "../../../constants/pages";
import { useScrollToTopAndNavigate } from "../../../../hooks";
import { LISTING_STATUSES, LISTING_TYPE } from "../../../../constants";
import { CHOOSE_LISTING_TYPE_ERROR } from "../../../../redux/sagas/listing";
import { handleErrorMessage } from "../../../../redux/sagas/listing/helpers";
import { PreviewListingModal } from "@find-truck-service/ui/src/components/react/PreviewListingModal";
import {
  selectTemporaryLinkListingFeaturedVendors,
  selectTemporaryLinkListingPreviewData,
  selectTemporaryLinkListingSelectedPrice,
} from "../../../../redux/reducers/v2/temporaryListingLink";
import { PreviewListingModalContentTypes } from "@find-truck-service/types/constants/previewListingModalContentTypes";
import { MemoizedTemporaryLinkAmenitiesModal } from "../../../../components/fts-components-v2/ListingBusinessInfo/TemporaryLinkEditListing/components/TemporaryLInkAmenitiesModal";
import { MemoizedTemporaryLinkDescriptionModal } from "../../../../components/fts-components-v2/ListingBusinessInfo/TemporaryLinkEditListing/components/TemporaryLinkDescriptionModal";
import { MemoizedTemporaryLinkServiceAmenitiesModal } from "../../../../components/fts-components-v2/ListingBusinessInfo/TemporaryLinkEditListing/components/TemporaryLinkServiceAmenitiesModal";
import {
  setTemporaryLinkEditListingData,
  setTemporaryLinkListingData,
  setTemporaryLinkSelectedListingPrice,
  setTemporaryLinkShowFreeListingModalNotice,
  temporaryLinkChooseListingTypeRequest,
  temporaryLinkUpdateListingRequest,
} from "../../../../redux/actions/v2/temporaryLinkListing";
import {
  selectAmenities,
  selectServiceAmenities,
} from "../../../../redux/reducers/essentialLists";
import { getListingImageSrc } from "../../../../components/fts-components-v2/ListingBusinessInfo/helpers";

export const TemporaryLinkPreviewDraftListingModals = (props) => {
  const {
    showServicesModal,
    showAmenitiesModal,
    setShowServicesModal,
    showDescriptionModal,
    openDescriptionModal,
    setShowAmenitiesModal,
    previewListingForPrice,
    setShowDescriptionModal,
    closePreviewListingModal,
    setPreviewListingForPrice,
    handleChangeListingSelection,
  } = props;

  const dispatch = useDispatch();
  const scrollAndNavigate = useScrollToTopAndNavigate();

  const listingData = useSelector(selectTemporaryLinkListingPreviewData);
  const serviceAmenities = useSelector(selectServiceAmenities);
  const amenities = useSelector(selectAmenities);
  const featuredVendors = useSelector(
    selectTemporaryLinkListingFeaturedVendors,
  );
  const selectedListingPrice = useSelector(
    selectTemporaryLinkListingSelectedPrice,
  );

  const previewListingModalContentType =
    listingData?.status === LISTING_STATUSES.ACTIVE
      ? PreviewListingModalContentTypes.PREVIEW_LISTING
      : PreviewListingModalContentTypes.PREVIEW_WITH_EDIT_BUTTONS;

  const previewModalTitle = previewListingForPrice?.label
    ? `${previewListingForPrice.label} Preview`
    : "Preview listing";

  const imageSrc = getListingImageSrc(listingData);

  const previewModalFooterProps = {
    successLabel: `Continue With ${previewListingForPrice?.label || ""}`,
    onSuccess: () => {
      const isListingTypeConfirmed =
        selectedListingPrice?.id === previewListingForPrice?.id;

      const isFreeListingSelected =
        selectedListingPrice?.id === LISTING_TYPE.FREE;

      if (isListingTypeConfirmed && isFreeListingSelected) {
        closePreviewListingModal();
        return dispatch(
          setTemporaryLinkShowFreeListingModalNotice({ data: true }),
        );
      }

      if (isListingTypeConfirmed) {
        handleChooseListingType();
      } else {
        handleChangeListingSelection(previewListingForPrice);
      }

      setPreviewListingForPrice(null);
    },
  };

  const handleChooseListingTypeError = (e) => {
    const message = handleErrorMessage(e, CHOOSE_LISTING_TYPE_ERROR);
    toast.error(message);
  };

  const onSuccessChooseListingType = () => {
    const newListingData = {
      ...listingData,
      price: selectedListingPrice,
      priceId: selectedListingPrice.id,
    };
    dispatch(setTemporaryLinkListingData({ data: newListingData }));
    scrollAndNavigate(TemporaryLinkPages.PAYMENT);
  };

  const handleChooseListingType = () => {
    dispatch(
      temporaryLinkChooseListingTypeRequest({
        data: { priceId: selectedListingPrice.id },
        onSuccess: onSuccessChooseListingType,
        onError: handleChooseListingTypeError,
      }),
    );
  };

  const onSaveServices = useCallback(() => {
    setShowServicesModal(false);
    dispatch(temporaryLinkUpdateListingRequest());
  }, []);

  const onSaveAmenities = useCallback(() => {
    setShowAmenitiesModal(false);
    dispatch(temporaryLinkUpdateListingRequest());
  }, []);

  const openAmenitiesModal = () => {
    closePreviewListingModal();
    dispatch(setTemporaryLinkEditListingData({ data: listingData }));
    setShowAmenitiesModal(true);
  };

  const onSaveDescription = useCallback(() => {
    setShowDescriptionModal(false);
    dispatch(temporaryLinkUpdateListingRequest());
  }, []);

  const openServicesModal = () => {
    closePreviewListingModal();
    dispatch(setTemporaryLinkEditListingData({ data: listingData }));
    setShowServicesModal(true);
  };

  return (
    <>
      <PreviewListingModal
        isOpen={!!previewListingForPrice}
        onSelectListing={(price) => {
          dispatch(setTemporaryLinkSelectedListingPrice(price));
          setPreviewListingForPrice(null);
        }}
        imageSrc={imageSrc}
        amenities={amenities}
        listingData={listingData}
        title={previewModalTitle}
        price={previewListingForPrice}
        onAddServices={openServicesModal}
        onClose={closePreviewListingModal}
        onAddAmenities={openAmenitiesModal}
        serviceAmenities={serviceAmenities}
        onAddDescription={openDescriptionModal}
        modalFooterProps={previewModalFooterProps}
        contentType={previewListingModalContentType}
        showBenefits={listingData?.status !== LISTING_STATUSES.ACTIVE}
        showFeaturedVendors={previewListingForPrice?.id === LISTING_TYPE.FREE}
        featuredVendors={featuredVendors}
      />
      <MemoizedTemporaryLinkAmenitiesModal
        showModal={showAmenitiesModal}
        onSaveAmenities={onSaveAmenities}
        setShowModal={setShowAmenitiesModal}
      />
      <MemoizedTemporaryLinkServiceAmenitiesModal
        showModal={showServicesModal}
        onSaveServices={onSaveServices}
        setShowModal={setShowServicesModal}
      />
      <MemoizedTemporaryLinkDescriptionModal
        showModal={showDescriptionModal}
        onSaveDescription={onSaveDescription}
        setShowModal={setShowDescriptionModal}
      />
    </>
  );
};
