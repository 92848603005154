import FTSAxios from "../../../axios/fts.instance";
import { serialize } from "../../../utility/serialize";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";

export const filterInvoicesTypes = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_INVOICES_DATA", value });
};

export const getInvoicesData = (params) => {
  const page = params.page || 1;
  const queryParams = { ...params, page };
  const stringifiedParams = serialize(queryParams);

  return (dispatch) => {
    return FTSAxios.get(`admin/billing/all?${stringifiedParams}`).then(
      (response) => {
        dispatch({
          type: "GET_INVOICES_DATA",
          data: response.data.data,
          totalPages: response.data.numberOfPages,
          params: queryParams,
        });
      },
    );
  };
};

export const getInvoicesByUser = (userId) => {
  return () => FTSAxios.get(`admin/billing/user/${userId}`);
};

export const sendLinkMessage = (payload) => {
  return () => FTSAxios.post("invoices/payment-link", payload);
};

export const getInvoicesByListing = (listingId) => {
  return () => FTSAxios.get(`admin/billing/listing/${listingId}`);
};

export const downloadInvoice = (data) => {
  return () => FTSAxios.post(`admin/download/invoice`, data);
};

export const searchInvoices = ({
  page,
  resultsPerPage,
  sort,
  searchString,
  filter = [],
  statusFilter = [],
  searchFields,
}) => {
  return () => {
    const queryParams = {
      searchString: "",
      page: page || 1,
      pageSize: resultsPerPage,
    };

    let multi_match = [...statusFilter];

    // "status"
    // "owner.assignedAdmins.firstName"
    // "owner.assignedAdmins.lastName"
    // "invoiceNumber"
    // "total"
    // "dueAtString"
    // "paidAtString"
    // "payments.paidMethod"
    // "payments.paidSource"
    // "owner.companyName"
    // "owner.id"
    // "owner.city"
    // "owner.state"
    // "listing.id"
    if (searchString) {
      multi_match.push({
        query_string: {
          query: rfc3986EncodeURIComponent(searchString || "")
            .replace(/%40/g, "@")
            .replace(/\+/g, " AND "),

          type: "phrase_prefix",
          default_operator: "AND",
          fields: searchFields,
        },
      });
    }

    return FTSAxios.post("/invoices/search", {
      ...queryParams,
      multi_match,
      filter,
      sort,
    });
  };
};

export const searchPayments = ({
  page,
  resultsPerPage,
  sort,
  searchString,
  filter = [],
  statusFilter = [],
  searchFields,
}) => {
  return () => {
    const queryParams = {
      searchString: "",
      page: page || 1,
      pageSize: resultsPerPage,
    };

    let multi_match = [...statusFilter];

    // "status"
    // "owner.assignedAdmins.firstName"
    // "owner.assignedAdmins.lastName"
    // "invoiceNumber"
    // "total"
    // "dueAtString"
    // "paidAtString"
    // "payments.paidMethod"
    // "payments.paidSource"
    // "owner.companyName"
    // "owner.id"
    // "owner.city"
    // "owner.state"
    // "listing.id"
    if (searchString) {
      multi_match.push({
        query_string: {
          query: rfc3986EncodeURIComponent(searchString || "")
            .replace(/%40/g, "@")
            .replace(/\+/g, " AND "),

          type: "phrase_prefix",
          default_operator: "AND",
          fields: searchFields,
        },
      });
    }

    return FTSAxios.post("payment/search", {
      ...queryParams,
      multi_match,
      filter,
      sort,
    });
  };
};

export const resetDiscount = () => {
  return (dispatch) => dispatch({ type: "RESET_DISCOUNT" });
};

export const setDiscount = (payload) => {
  return (dispatch) => dispatch({ type: "SET_DISCOUNT", payload });
};
export const setAppliedCredit = (payload) => {
  return (dispatch) => dispatch({ type: "SET_APPLIED_CREDIT", payload });
};

export const setInvoicesListTest = (payload) => {
  return (dispatch) => dispatch({ type: "SET_INVOICES_LIST", payload });
};

export const setPaymentsListTest = (payload) => {
  return (dispatch) => dispatch({ type: "SET_PAYMENTS_LIST", payload });
};

export const setAllInvoicesCount = (payload) => {
  return (dispatch) => dispatch({ type: "SET_ALL_INVOICES_COUNT", payload });
};

export const setSelectedInvoices = (payload) => {
  return (dispatch) => dispatch({ type: "SET_SELECTED_INVOICES", payload });
};

export const selectInvoice = (payload) => {
  return (dispatch) => dispatch({ type: "SELECT_INVOICE", payload });
};

export const setDiscountForSelectedInvoices = (payload) => {
  return (dispatch) =>
    dispatch({ type: "SET_DISCOUNT_FOR_SELECTED_INVOICES", payload });
};

export const removeInvoiceDiscount = (payload) => {
  return (dispatch) => dispatch({ type: "REMOVE_INVOICE_DISCOUNT", payload });
};

export const getInvoiceDetails = (payload) => {
  return () => FTSAxios.post("invoices/details", payload);
};

export const getReceiptDetails = (payload) => {
  return () => FTSAxios.post("payment/recipient", payload);
};

export const setCreditCardData = (payload) => {
  return (dispatch) => dispatch({ type: "SET_CREDIT_CARD_DATA", payload });
};

export const updateInvoice = (payload) => {
  return () => FTSAxios.put("admin/invoices/update", payload);
};

export const addNewInvoice = (payload) => {
  return (dispatch) => dispatch({ type: "ADD_NEW_INVOICE", payload });
};

export const setAppliedCreditAmount = (payload) => {
  return (dispatch) => dispatch({ type: "SET_APPLIED_CREDIT_AMOUNT", payload });
};

export const setIsCreditApplied = (payload) => {
  return (dispatch) => dispatch({ type: "SET_IS_CREDIT_APPLIED", payload });
};

export const setTotalCreditAmount = (payload) => {
  return (dispatch) => dispatch({ type: "SET_TOTAL_ACCOUNT_CREDIT", payload });
};

export const setRestOfListingPrice = (payload) => {
  return (dispatch) => dispatch({ type: "SET_REST_OF_LISTING_PRICE", payload });
};

export const deleteInvoice = ({ adId }) => {
  return () => FTSAxios.delete(`invoices/delete/${adId}`);
};

export const upgradeListing = (data) => {
  return () => FTSAxios.post("listing/upgrade", data);
};

export const getDueInvoicesCount = () => {
  return () => {
    return FTSAxios.get("/invoices/count/Overdue");
  };
};

export const setDueInvoicesCount = (payload) => {
  return (dispatch) => {
    return dispatch({ type: "SET_DUE_INVOICES_COUNTER", payload });
  };
};

export const getPaymentsByEmailToken = () => {
  return () => {
    return FTSAxios.get("payment-by-link-hash");
  };
};
