/* eslint-disable indent */
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Route, Router, Switch, useLocation } from "react-router-dom";
import { history } from "./history";
import { connect, useSelector } from "react-redux";
import { ContextLayout } from "./utility/context/Layout";
import { toast } from "react-toastify";
import socketHandler from "./utility/socket";

import { activities, PERMISSION_GATE_PRINCIPLE } from "./constants";
import {
  getNotificationsCount,
  setNotificationUnreadCount,
  setOpenNotificationsSidebar,
} from "./redux/actions/notifications";
import { requestParticipation } from "./redux/actions/todo";
import {
  createActivity,
  fetchActivitiesAccount,
  fetchActivitiesListing,
} from "./redux/actions/activities";
import { setUserActivities } from "./redux/actions/user-activities";
import ClientNotification from "./components/fts/fts-client-notification";
import PermissionGate from "./components/fts/permission-gate/PermissionGate";
import getRoutesByUserType from "./utility/userRoutes";
import { css } from "glamor";

import FinishRegister from "./views/pages/authentication/client-registration/FinishRegister";
import accountSelection from "./views/pages/authentication/client-registration/AccountSelection";
// import forgotPassword from "./views/pages/authentication/ForgotPassword";
import ResetPassword from "./components/fts-components-v2/ResetPassword";
import ChangePassword from "./components/fts-components-v2/ChangePassword";
import NewPassword from "./components/fts-components-v2/NewPassword";
import CompleteRegistration from "./components/fts-components-v2/CompleteRegistration";
// import ChangePasswordNew from "./views/pages/authentication/ChangePasswordNew";
import fleetInvitation from "./views/pages/fleet-invitation";

import changePasswordInApp from "./views/pages/change-password";

import notAuthorized from "./views/pages/misc/NewNotAuthorized.js";
import ThankYouPage from "./views/pages/misc/ThankYouPage.js";
import CheckYourEmail from "./views/pages/misc/CheckYourEmail.js";

import error404 from "./views/pages/misc/error/404";

import accountDeactivated from "./views/pages/misc/AccountDeactivated";

// import reactivateAccount from "./views/pages/authentication/ReactivateAccount";
import deleteAccountConfirmation from "./views/pages/misc/DeleteAccountConfirmation";

import EmailUnsubscribe from "./views/pages/email-unsubscribe";

import AccountAccessRequest from "./views/pages/account-access-request";

import RemoteAccessLogin from "./views/pages/remote-access-login";
import PasswordExpired from "./views/pages/password-expired";
import UpdateExpiredPassword from "./views/pages/authentication/UpdateExpiredPassword";
import { LAST_VISITED_ROUTE } from "./cookies";

import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import GetListedRouter from "./Routers/GetListedRouter";
import ListingBusinessDetails from "./views/pages/listing-business-details";
import DraftListingExist from "./views/pages/draft-listing-exist";
import ChooseListingType from "./views/pages/choose-listing-type";
import EditListingImages from "./views/pages/edit-listing-images";
import ListingImages from "./views/pages/listing-images";
import PaymentOverview from "./components/fts-components-v2/PaymentOverview/PaymentOverview";
import EditListingBusinessDetails from "./views/pages/edit-listing-business-details";
import GetListedAccount from "./components/fts-components-v2/GetListedAccount";
import WrongAccount from "./components/fts-components-v2/WrongAccount/WrongAccount";
import VerifyEmail from "./components/fts-components-v2/VerifyEmail/VerifyEmail";
import { selectUserData } from "./redux/reducers/v2/auth";
import "./assets/scss/components/fts/fts-auth.scss";
import { ChooseAccountType } from "./components/fts-components-v2/ChooseAccountType";
import CreateAccount from "./views/pages/create-account";
import Login from "./views/pages/login";
import { FreeListingExist } from "./views/pages/free-listing-exist/FreeListingExist";
import { extractParameter } from "./utility/param-extract";
import { PaymentSuccessfulModal } from "@find-truck-service/ui/src/components/react/PaymentSuccessfulModal";
import clearSearchParams from "./utility/clear-search-params";
import { FreeListingSubmittedModal } from "@find-truck-service/ui/src/components/react/FreeListingSubmittedModal";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { CompleteAccountInfoPage } from "./views/pages/complete-account-info";
import ListingTemporaryLinkRouter from "./Routers/ListingTemporaryLinkRouter";
import VerificationRequired from "./components/fts-components-v2/VerificationRequired/VerificationRequired";
import {
  Pages,
  TemporaryLinkPages,
  TemporaryLinkProxies,
} from "./views/constants/pages";
import TemporaryListingLinkPaymentOverviewPage from "./views/pages/temporary-listing-link-payment-overview-page";
import TemporaryLinkEditListing from "./views/pages/temporary-link-edit-listing";
import TemporaryLinkEditListingImages from "./views/pages/temporary-link-edit-listing-images";
import RouteWithHeader from "./Routers/RouteWithHeader";
import TemporaryLinkProxy from "./proxies/TemporaryLinkProxy";
import UserDisabled from "./views/pages/user-disabled";
import UserDeactivated from "./views/pages/user-deactivated";
import ListingDisabled from "./views/pages/listing-disabled";
import ListingNotFound from "./views/pages/listing-not-found";
import { TemporaryLinkProofPreview } from "./views/pages/temporary-link-proof-preview";
import TemporaryLinkPreviewListing from "./views/pages/temporary-link-preview-listing";
import TemporaryLinkPreviewFreeCompletedListing from "./views/pages/temporary-link-preview-free-completed-listing";
import TemporaryLinkPreviewDraftListing from "./views/pages/temporary-link-preview-draft-listing";
import logger from "./utility/logger";
import { TemporaryLinkRenewal } from "./views/pages/temporary-link-renewal";
import { TemporaryLinkRelist } from "./views/pages/temporary-link-relist";
import { SESSION_STORAGE_KEYS } from "./utility/constants";
import { TemporaryLinkDraftProofPreview } from "./views/pages/temporary-link-draft-proof-preview";
import TemporaryListingLinkUpgradePaymentOverviewPage from "./views/pages/temporary-listing-link-upgrade-payment-overview-page";

const modalComponents = {
  paymentSuccessfulModal: "paymentSuccessfulModal",
  freeListingSubmittedModal: "freeListingSubmittedModal",
};
// Set Layout and Component Using App Route
const RouteConfig = ({
  component: Component,
  fullLayout,
  isProtected,
  isLogged,
  setNotificationUnreadCount,
  // unReadCount,
  requestParticipation,
  employees,
  // fetchActivitiesAccount,
  fetchActivitiesListing,
  createActivity,
  noSidebar,
  getNotificationsCount,
  setOpenNotificationsSidebar,
  ...rest
}) => {
  const userData = useSelector(selectUserData);
  const location = useLocation();

  const [paymentSuccessfulModalOpen, setPaymentSuccessfulModalOpen] =
    useState(false);

  const [freeListingSubmittedModalOpen, setFreeListingSubmittedModalOpen] =
    useState(false);

  useEffect(() => {
    const openModalFromUrl = extractParameter("openModal");
    if (openModalFromUrl === modalComponents.paymentSuccessfulModal) {
      setPaymentSuccessfulModalOpen(true);
    }
    if (openModalFromUrl === modalComponents.freeListingSubmittedModal) {
      setFreeListingSubmittedModalOpen(true);
    }
  }, []);

  useEffect(() => {
    logger.info("isProtected: ", isProtected);
    logger.info("isLogged: ", isLogged);
    const isVerifyRoute = window.location.pathname === Pages.VERIFY_EMAIL;
    const isOnboardingRoue = window.location.pathname === Pages.ON_BOARDING;

    if (isProtected && isLogged && userData?.id) {
      let { onboardingFinished, verified } = userData;

      if (!onboardingFinished && !isOnboardingRoue) {
        return history.push(`/dashboard/on-boarding`);
      }

      if (!verified && !isVerifyRoute && !isOnboardingRoue) {
        return history.push(
          `/dashboard/verify-email?redirectTo=${window.location.pathname}`,
        );
      }

      if (
        onboardingFinished &&
        verified &&
        (window.location.pathname === "/dashboard/complete-account-info" ||
          window.location.pathname === "/dashboard/choose-account-type")
      )
        return history.push("/dashboard");
    }
  }, [isProtected, isLogged, userData]);

  useEffect(() => {
    if (
      userData &&
      userData?.id &&
      !socketHandler.subscribedEvents.includes("saveConnection")
    ) {
      socketHandler.subscribeEvent("saveConnection");
      socketHandler.events.saveConnection.onOpen = () => {
        socketHandler.send("saveConnection", { userId: userData?.id });
      };

      if (socketHandler.socket.readyState === WebSocket.OPEN) {
        socketHandler.send("saveConnection", { userId: userData?.id });
      }
    }
  }, [userData]);

  const handleRequestStatus = useCallback(
    (status, selectedNotification) => {
      const {
        link,
        sendBy,
        id: notificationId,
        description: todoTitle,
        title: companyName,
      } = selectedNotification;

      let todoId = link.split("?")[1].split("=")[1];
      let ownerId = link.split("/")[2].split("?")[0];

      const params = {
        todoId,
        sendBy,
        status,
        notificationId,
        todoTitle,
        companyName,
        ownerId,
      };

      requestParticipation(params);

      let activityPayload = {
        status: 1,
        toDoId: todoId,
        userId: ownerId,
        notificationId,
        activityType: this.props.activitiesTypes.HANDLED_PARTICIPATION_REQUEST,
        activity: activities.handleParticipationRequest
          .replace(
            "{{admin_name}}",
            `${this.props.userData.firstName} ${this.props.userData.lastName}`,
          )
          .replace("{{status}}", status)
          .replace("{{todoId}}", `${todoId}`),
        adminUserId: this.props.userData.adminAccountOwner || null,
        iconName: "Todos",
      };

      createActivity(activityPayload).then(async () => {
        let path = window.location.pathname.split("/");
        let isListing = path.find((e) => e === "listings");
        let isAccount = path.find((e) => e === "accounts");

        if (isListing) {
          let { data } = await fetchActivitiesListing(path[2]);
          await setUserActivities(data);
        }
        if (isAccount) {
          let { data } = await this.props.fetchActivitiesAccount(path[2]);
          await setUserActivities(data);
        }
      });
    },
    [requestParticipation, fetchActivitiesListing, createActivity],
  );

  const getAdminName = useCallback(
    (id) => {
      return employees.find((employee) => employee.value === id);
    },
    [employees],
  );

  useEffect(() => {
    socketHandler.events.saveConnection.onMessage = (data) => {
      let notification = data;
      getNotificationsCount().then((res) =>
        setNotificationUnreadCount(res.data?.result),
      );
      toast.info(
        <div
          onClick={() => {
            setOpenNotificationsSidebar(true);
          }}
        >
          <ClientNotification
            getAdminName={getAdminName}
            notification={notification}
            handleRequestStatus={handleRequestStatus}
          />
        </div>,

        {
          autoClose: false,

          className: css({
            background: "transparent !important",
            borderRadius: "4px !important",
            border: " 1px solid rgb(245, 176, 83) !important",
          }),
        },
      );
    };
  }, [
    handleRequestStatus,
    setNotificationUnreadCount,
    getAdminName,
    getNotificationsCount,
    setOpenNotificationsSidebar,
  ]);

  useEffect(() => {
    if (
      location.pathname !== "/dashboard/not-authorized" &&
      location.pathname !== "/"
    ) {
      sessionStorage.setItem(LAST_VISITED_ROUTE, location.pathname);
    }
  }, [location]);

  return (
    <Route
      {...rest}
      render={(props) => {
        const hasTemporaryToken = !!sessionStorage.getItem(
          SESSION_STORAGE_KEYS.TEMPORARY_TOKEN,
        );

        if (isProtected && !isLogged && hasTemporaryToken) {
          return history.push(
            `/dashboard/verification-required?redirectTo=${window.location.pathname}`,
          );
        }

        if (isProtected && !isLogged) {
          return history.push(
            `/dashboard/login?redirectTo=${window.location.pathname}`,
          );
        }

        if (noSidebar) {
          return <Component {...props} />;
        }
        return (
          <ContextLayout.Consumer>
            {(context) => {
              let LayoutTag =
                fullLayout === true
                  ? context.fullLayout
                  : context.state.activeLayout === "horizontal"
                    ? context.horizontalLayout
                    : context.VerticalLayout;
              return (
                <LayoutTag {...props} permission={props.user}>
                  <Suspense fallback={<FullPageLoader />}>
                    <PermissionGate
                      fallbackComponent={notAuthorized}
                      permissions={[rest.permission]}
                      principle={PERMISSION_GATE_PRINCIPLE.ALL}
                    >
                      <Component {...props} />
                    </PermissionGate>
                    <PaymentSuccessfulModal
                      isOpen={paymentSuccessfulModalOpen}
                      onClose={() => {
                        setPaymentSuccessfulModalOpen(false);
                        clearSearchParams(history);
                      }}
                      onBackdropClick={() => {
                        setPaymentSuccessfulModalOpen(false);
                        clearSearchParams(history);
                      }}
                    />
                    <FreeListingSubmittedModal
                      isOpen={freeListingSubmittedModalOpen}
                      onClose={() => {
                        setFreeListingSubmittedModalOpen(false);
                        clearSearchParams(history);
                      }}
                      onBackdropClick={() => {
                        setFreeListingSubmittedModalOpen(false);
                        clearSearchParams(history);
                      }}
                    />
                  </Suspense>
                </LayoutTag>
              );
            }}
          </ContextLayout.Consumer>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.authState.user,
    currentUser: state.authState.user?.permissions,
    isLogged: !!state.authState.user,
    unReadCount: state.notifications?.unReadCount,
    employees: state.essentialLists.employees,
  };
};

const AppRoute = connect(mapStateToProps, {
  setNotificationUnreadCount,
  requestParticipation,
  createActivity,
  fetchActivitiesAccount,
  fetchActivitiesListing,
  setUserActivities,
  getNotificationsCount,
  setOpenNotificationsSidebar,
})(RouteConfig);

const AppRouter = () => {
  const userData = useSelector(selectUserData);
  const [routes, setRoutes] = useState([]);

  const generateRoutes = useCallback(() => {
    let routes = getRoutesByUserType(userData?.userType);
    const routeComponents = routes
      ?.map((route) => {
        if (!route.path || !route.component || route.type === "collapse") {
          return null;
        }
        return <AppRoute exact {...route} key={null} />;
      })
      .filter(Boolean);
    setRoutes(routeComponents);
  }, [userData]);

  useEffect(() => {
    generateRoutes();
  }, [JSON.stringify(userData)]);

  return (
    <Router history={history}>
      <Switch>
        {routes}
        <Route exact path="/dashboard/login" component={Login} />
        <RouteWithHeader
          path="/dashboard/verification-required"
          component={VerificationRequired}
        />
        <Route
          exact
          path="/dashboard/account-access-request/:hash"
          component={AccountAccessRequest}
        />

        <AppRoute
          isProtected
          isLogged
          exact
          path="/dashboard"
          component={EmptyComponent}
        />

        <Route
          exact
          path={"/dashboard/remote-access"}
          component={RemoteAccessLogin}
        />

        <Route path="/dashboard/register" component={CreateAccount} />
        <RouteWithHeader
          path="/dashboard/choose-account-type"
          component={ChooseAccountType}
        />
        <Route path={Pages.FINISH_REGISTER} component={CompleteRegistration} />
        <Route
          path="/dashboard/finish-driver-register/:hash"
          component={FinishRegister}
        />
        <Route
          path="/dashboard/forgot-password"
          component={ResetPassword}
          fullLayout
        />
        <Route
          path={Pages.RESET_PASSWORD}
          component={ChangePassword}
          fullLayout
        />
        <Route
          path="/dashboard/update-expired-password"
          component={UpdateExpiredPassword}
          fullLayout
        />
        <Route
          path="/dashboard/fleet-invitation/:hash"
          component={fleetInvitation}
          fullLayout
        />
        <Route
          path="/dashboard/verify-email"
          component={VerifyEmail}
          fullLayout
        />
        <Route
          path="/dashboard/account-deactivated"
          component={accountDeactivated}
          fullLayout
        />

        <RouteWithHeader
          path={Pages.REACTIVATE_ACCOUNT}
          component={NewPassword}
          fullLayout
        />

        <Route
          path="/dashboard/thank-you"
          component={ThankYouPage}
          fullLayout
        />

        <Route
          path="/dashboard/check-your-email"
          component={CheckYourEmail}
          fullLayout
        />

        <Route
          path="/dashboard/verify-your-email"
          component={VerifyEmail}
          fullLayout
        />

        <Route
          path="/dashboard/delete-account-confirmation"
          component={deleteAccountConfirmation}
          fullLayout
        />

        <Route
          path="/dashboard/email-unsubscribe/:hash"
          component={EmailUnsubscribe}
          fullLayout
        />

        <AppRoute
          noSidebar
          isProtected
          path="/dashboard/on-boarding"
          component={accountSelection}
          fullLayout
        />
        <AppRoute
          isProtected
          path="/dashboard/not-authorized"
          component={notAuthorized}
          fullLayout
        />
        <AppRoute
          isProtected
          isLogged
          path="/dashboard/change-my-password"
          component={changePasswordInApp}
          fullLayout
        />

        {/*<AppRoute*/}
        {/*  isProtected*/}
        {/*  noSidebar*/}
        {/*  isLogged*/}
        {/*  path="/dashboard/choose-account-type"*/}
        {/*  component={ChooseAccountType}*/}
        {/*  exact*/}
        {/*/>*/}

        <AppRoute
          isProtected
          noSidebar
          path="/dashboard/complete-account-info"
          component={CompleteAccountInfoPage}
          exact
        />

        <Route
          path="/password-expired"
          component={PasswordExpired}
          fullLayout
        />

        <RouteWithHeader
          path="/dashboard/get-listed/account"
          component={GetListedAccount}
        />

        <GetListedRouter
          path={"/dashboard/get-listed/wrong-account"}
          component={WrongAccount}
          exact
        />

        <GetListedRouter
          path={"/dashboard/create-listing/business-details"}
          component={ListingBusinessDetails}
          exact
        />

        <GetListedRouter
          path={"/dashboard/create-listing/draft-listing-exist"}
          component={DraftListingExist}
          exact
        />
        <GetListedRouter
          path={"/dashboard/create-listing/free-listing-exist"}
          component={FreeListingExist}
          exact
        />
        <GetListedRouter
          path={"/dashboard/get-listed/preview-listing/:adId"}
          component={ChooseListingType}
          exact
        />
        <GetListedRouter
          path={"/dashboard/edit-listing/edit-listing-images/:adId"}
          component={EditListingImages}
          exact
        />
        <GetListedRouter
          path={"/dashboard/create-listing/listing-images"}
          component={ListingImages}
          exact
        />
        <GetListedRouter
          path={"/dashboard/edit-listing/business-details/:adId"}
          component={EditListingBusinessDetails}
        />
        <GetListedRouter
          path={"/dashboard/payment-overview/:adId"}
          component={PaymentOverview}
          exact
        />
        <ListingTemporaryLinkRouter
          path={TemporaryLinkPages.LISTING_RENEWAL}
          component={TemporaryLinkRenewal}
          exact
        />

        <ListingTemporaryLinkRouter
          path={TemporaryLinkPages.LISTING_RELIST}
          component={TemporaryLinkRelist}
          exact
        />
        <ListingTemporaryLinkRouter
          path={TemporaryLinkPages.PROOF_PREVIEW}
          component={TemporaryLinkProofPreview}
          exact
        />
        <ListingTemporaryLinkRouter
          path={TemporaryLinkPages.LISTING_PREVIEW}
          component={TemporaryLinkPreviewListing}
          exact
        />
        <ListingTemporaryLinkRouter
          path={TemporaryLinkPages.DRAFT_PROOF_PREVIEW}
          component={TemporaryLinkDraftProofPreview}
          exact
        />
        <ListingTemporaryLinkRouter
          path={TemporaryLinkPages.DRAFT_LISTING_PREVIEW}
          component={TemporaryLinkPreviewDraftListing}
          exact
        />
        <ListingTemporaryLinkRouter
          path={TemporaryLinkPages.FREE_LISTING_PREVIEW}
          component={TemporaryLinkPreviewFreeCompletedListing}
          exact
        />
        <ListingTemporaryLinkRouter
          path={TemporaryLinkPages.PAYMENT}
          component={TemporaryListingLinkPaymentOverviewPage}
          exact
        />
        <ListingTemporaryLinkRouter
          path={TemporaryLinkPages.EDIT_LISTING}
          component={TemporaryLinkEditListing}
          exact
        />
        <ListingTemporaryLinkRouter
          path={TemporaryLinkPages.EDIT_LISTING_IMAGES}
          component={TemporaryLinkEditListingImages}
          exact
        />
        <ListingTemporaryLinkRouter
          path={TemporaryLinkPages.UPGRADE_LISTING_PAYMENT}
          component={TemporaryListingLinkUpgradePaymentOverviewPage}
          exact
        />

        <RouteWithHeader
          path={`${TemporaryLinkPages.LISTING_DISABLED}/:id`}
          component={ListingDisabled}
          exact
        />

        <RouteWithHeader
          path={TemporaryLinkPages.LISTING_NOT_FOUND}
          component={ListingNotFound}
          exact
        />

        <Route
          path={TemporaryLinkProxies.TEMPORARY_LINKS_PROXY_URL}
          component={TemporaryLinkProxy}
          fullLayout
        />

        <RouteWithHeader
          path={Pages.USER_DISABLED}
          component={UserDisabled}
          fullLayout
        />

        <RouteWithHeader
          path={Pages.USER_DEACTIVATED}
          component={UserDeactivated}
          fullLayout
        />

        <AppRoute component={error404} fullLayout />
      </Switch>
    </Router>
  );
};

export default connect(mapStateToProps)(AppRouter);
