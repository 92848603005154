import React from "react";
import { useSelector } from "react-redux";
import { H3 } from "@find-truck-service/ui/src/FtsText";
import { WeightSize } from "@find-truck-service/types/ui/text";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ListingItem } from "@find-truck-service/ui/src/components/react/ListingItem";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import {
  selectTemporaryLinkListingPreviewData,
  selectTemporaryLinkListingSelectedPrice,
} from "../../../redux/reducers/v2/temporaryListingLink";

const TemporaryLinkListingOptions = ({
  type,
  label,
  listingOptions,
  setPreviewListingForPrice,
  handleChangeListingSelection,
}) => {
  const listingData = useSelector(selectTemporaryLinkListingPreviewData);
  const selectedListingPrice = useSelector(
    selectTemporaryLinkListingSelectedPrice,
  );

  if (!listingOptions?.length) return <EmptyComponent />;

  return (
    <FtsWrapper
      pt={{ xs: "2xl", sm: "2xl", md: "3xl", lg: "4xl" }}
      pb={{ xs: "2xl", sm: "2xl", md: "3xl", lg: "3xl" }}
    >
      <H3
        variant={"text-lg"}
        weight={WeightSize.bold}
        textAlign={{ xs: "left", sm: "left", md: "center", lg: "center" }}
        px={"xl"}
        mb={"3xl"}
      >
        {label}
      </H3>
      <FtsWrapper
        display={"flex"}
        gap={{ lg: "3xxl", md: "3xl", sm: "3xl", xs: "2xl" }}
        direction={"column"}
      >
        {listingOptions.map((item, index) => {
          const listing = { ...listingData, price: item, priceId: item.id };
          return (
            <ListingItem
              key={index}
              type={type}
              listingData={listing}
              selected={selectedListingPrice?.id === item.id}
              footerActions={{
                onSelect: () => handleChangeListingSelection(item),
                onClickPreview: () => setPreviewListingForPrice(item),
              }}
            />
          );
        })}
      </FtsWrapper>
    </FtsWrapper>
  );
};

export default TemporaryLinkListingOptions;
