import { BannerTypes } from "@find-truck-service/types/ui/banner";

export const bannerTypesIds = {
  LISTING_LIVE_WAITING_PROOF: 1,
  FREE_LISTING_AWAITING_APPROVAL: 2,
  PAID_LISTING_LIVE: 3,
  RENEWAL_SOON: 4,
  RENEWAL_DUE_TODAY: 5,
  RENEWAL_PAST_DUE: 6,
  LISTING_RENEWED: 7,
  LISTING_EXPIRED: 8,
};

export const bannerTypes = {
  [bannerTypesIds.LISTING_LIVE_WAITING_PROOF]: BannerTypes.info,
  [bannerTypesIds.FREE_LISTING_AWAITING_APPROVAL]: BannerTypes.info,
  [bannerTypesIds.PAID_LISTING_LIVE]: BannerTypes.success,
  [bannerTypesIds.RENEWAL_SOON]: BannerTypes.info,
  [bannerTypesIds.RENEWAL_DUE_TODAY]: BannerTypes.warning,
  [bannerTypesIds.RENEWAL_PAST_DUE]: BannerTypes.error,
  [bannerTypesIds.LISTING_RENEWED]: BannerTypes.success,
  [bannerTypesIds.LISTING_EXPIRED]: BannerTypes.error,
};

export const bannerTitles = {
  [bannerTypesIds.LISTING_LIVE_WAITING_PROOF]:
    "Your listing is under review, and we’ll email you the proof when it's ready.",
  [bannerTypesIds.FREE_LISTING_AWAITING_APPROVAL]:
    "Awaiting for approval! We’ll verify it soon, free listings may take up to 30 days.",
  [bannerTypesIds.PAID_LISTING_LIVE]: "Your business listing expires on ",
  [bannerTypesIds.RENEWAL_SOON]: "Your business listing expires on ",
  [bannerTypesIds.RENEWAL_DUE_TODAY]: "Your business listing expires today!",
  [bannerTypesIds.RENEWAL_PAST_DUE]: "Your business listing is past due!",
  [bannerTypesIds.LISTING_RENEWED]: "Your business listing expires on ",
  [bannerTypesIds.LISTING_EXPIRED]:
    "Due to non-payment, your business listing has expired!",
};
