import React from "react";
import { useSelector } from "react-redux";
import {
  selectTemporaryLinkInvalidFormFields,
  selectTemporaryLinkListingLatitude,
  selectTemporaryLinkListingLongitude,
  selectTemporaryLinkEditListingData,
} from "../../../../../redux/reducers/v2/temporaryListingLink";
import { ListingMapInfo } from "../../components/ListingMapInfo";
import {
  setTemporaryLinkInvalidFormFields,
  setTemporaryLinkEditListingData,
} from "../../../../../redux/actions/v2/temporaryLinkListing";

export const TemporaryLinkListingMapInfoRow = () => {
  const latitude = useSelector(selectTemporaryLinkListingLatitude);
  const longitude = useSelector(selectTemporaryLinkListingLongitude);
  const listingData = useSelector(selectTemporaryLinkEditListingData);
  const invalidFormFields = useSelector(selectTemporaryLinkInvalidFormFields);

  return (
    <ListingMapInfo
      latitude={latitude}
      longitude={longitude}
      listingData={listingData || {}}
      invalidFormFields={invalidFormFields || {}}
      setListingData={setTemporaryLinkEditListingData}
      setListingInvalidFormFields={setTemporaryLinkInvalidFormFields}
    />
  );
};
