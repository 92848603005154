import {
  GET_SHARE_PROVIDER_REQUEST,
  GET_SHARE_PROVIDER_REQUEST_SUCCESS,
  GET_SHARE_PROVIDER_REQUEST_FAILED,
  SET_SHARE_PROVIDER_DATA,
} from "../../../reducers/v2/shareProvider";

export const getShareProviderRequest = (payload) => ({
  type: GET_SHARE_PROVIDER_REQUEST,
  payload,
});

export const getShareProviderSuccess = (payload) => ({
  type: GET_SHARE_PROVIDER_REQUEST_SUCCESS,
  payload,
});

export const getShareProviderFailed = (payload) => ({
  type: GET_SHARE_PROVIDER_REQUEST_FAILED,
  payload,
});

export const setShareProviderData = (payload) => ({
  type: SET_SHARE_PROVIDER_DATA,
  payload,
});
