import { createSelector } from "reselect";

export const GENERATE_USER_LOGIN_MFA_REQUEST =
  "USER_MFAS/GENERATE_USER_LOGIN_MFA_REQUEST";
export const GENERATE_USER_LOGIN_MFA_SUCCESS =
  "USER_MFAS/GENERATE_USER_LOGIN_MFA_SUCCESS";
export const GENERATE_USER_LOGIN_MFA_FAILED =
  "USER_MFAS/GENERATE_USER_LOGIN_MFA_FAILED";

export const TEMPORARY_TOKEN_VERIFY_USER_MFA_REQUEST =
  "USER_MFAS/TEMPORARY_TOKEN_VERIFY_USER_MFA_REQUEST";
export const TEMPORARY_TOKEN_VERIFY_USER_MFA_SUCCESS =
  "USER_MFAS/TEMPORARY_TOKEN_VERIFY_USER_MFA_SUCCESS";
export const TEMPORARY_TOKEN_VERIFY_USER_MFA_FAILED =
  "USER_MFAS/TEMPORARY_TOKEN_VERIFY_USER_MFA_FAILED";

export const TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_REQUEST =
  "USER_MFAS/TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_REQUEST";
export const TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_SUCCESS =
  "USER_MFAS/TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_SUCCESS";
export const TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_FAILED =
  "USER_MFAS/TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_FAILED";

export const SET_USER_MFA_CODE_VALIDATION_MESSAGE =
  "USER_MFAS/SET_USER_MFA_CODE_VALIDATION_MESSAGE";

const initialState = {
  isGenerateLoading: false,
  isVerifyLoading: false,
  error: null,
  inlineMessage: null,
  userMfaCodeValidationMessage: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GENERATE_USER_LOGIN_MFA_REQUEST:
      return {
        ...state,
        isGenerateLoading: true,
      };

    case GENERATE_USER_LOGIN_MFA_SUCCESS:
      return {
        ...state,
        isGenerateLoading: false,
        error: null,
        inlineMessage: null,
      };

    case GENERATE_USER_LOGIN_MFA_FAILED:
      return {
        ...state,
        isGenerateLoading: false,
        error: action.payload.data.message,
        inlineMessage: action.payload.data.inlineMessage,
      };

    case TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_REQUEST:
    case TEMPORARY_TOKEN_VERIFY_USER_MFA_REQUEST:
      return {
        ...state,
        isVerifyLoading: true,
      };

    case TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_SUCCESS:
    case TEMPORARY_TOKEN_VERIFY_USER_MFA_SUCCESS:
      return {
        ...state,
        isVerifyLoading: false,
        error: null,
        inlineMessage: null,
      };

    case TEMPORARY_TOKEN_RESEND_VERIFY_USER_MFA_FAILED:
    case TEMPORARY_TOKEN_VERIFY_USER_MFA_FAILED:
      return {
        ...state,
        isVerifyLoading: false,
        error: action.payload.data.message,
        inlineMessage: action.payload.data.inlineMessage,
      };

    case SET_USER_MFA_CODE_VALIDATION_MESSAGE:
      return { ...state, userMfaCodeValidationMessage: action.payload.data };
    default:
      return state;
  }
}

const selectUserMfasState = (state) => state.userMfasState;

export const selectUserMfasIsGenerateLoading = createSelector(
  selectUserMfasState,
  (userMfasState) => {
    return userMfasState.isGenerateLoading;
  },
);

export const selectUserMfasValidationMessage = createSelector(
  selectUserMfasState,
  (userMfasState) => {
    return userMfasState.userMfaCodeValidationMessage;
  },
);

export const selectUserMfasIsVerifyLoading = createSelector(
  selectUserMfasState,
  (userMfasState) => {
    return userMfasState.isVerifyLoading;
  },
);

export const selectUserMfasErrorMessage = createSelector(
  selectUserMfasState,
  (userMfasState) => {
    return userMfasState.inlineMessage;
  },
);

export const selectUserMfasError = createSelector(
  selectUserMfasState,
  (userMfasState) => {
    return userMfasState.error;
  },
);
