import React from "react";
import { HeaderLinks } from "./components/HeaderLinks";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { FtsTopBar } from "@find-truck-service/ui/src/FtsTopBar/FtsTopBar";
import { FtsLogoLink } from "@find-truck-service/ui/src/FtsLink/FtsLogoLink";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import HeaderButtonsLoggedUser from "@find-truck-service/ui/src/components/react/Header/components/HeaderButtonsLoggedUser";
import HeaderButtonsNonLoggedUser from "@find-truck-service/ui/src/components/react/Header/components/HeaderButtonsNonLoggedUser";
export const Header = ({ userName, activeLink, dataLoaded, openSideOut }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(FtsWrapper, { minHeight: { xs: "56px", sm: "88px", md: "88px", lg: "96px" }, bg: ColorsValue["transparent"] }),
        React.createElement(FtsTopBar, null,
            React.createElement(FtsWrapper, { display: "flex", align: "center", justify: "space-between", py: { lg: "2xl", md: "xl", sm: "xl", xs: "2xs" }, pl: { lg: "3xl", md: "3xl", sm: "2xl", xs: "xl" }, pr: { lg: "3xl", md: "3xl", sm: "2xl", xs: "sm" } },
                React.createElement(FtsLogoLink, { href: "/", height: "48px", display: "block", py: { lg: "0", md: "0", sm: "0", xs: "sm" }, maxWidth: { lg: "303px", md: "303px", sm: "303px", xs: "202px" } },
                    React.createElement(FtsIcons, { iconName: IconNames.Logo, svgProps: { height: "100%", width: "100%" } })),
                React.createElement(HeaderLinks, { activeLink: activeLink }),
                React.createElement(FtsWrapper, { gap: "lg", align: "center", display: "flex", justify: "flex-end", width: { xs: "60px", sm: "60px", md: "302px", lg: "302px" } },
                    dataLoaded && !userName && React.createElement(HeaderButtonsNonLoggedUser, { openSideOut: openSideOut }),
                    dataLoaded && !!userName && React.createElement(HeaderButtonsLoggedUser, { openSideOut: openSideOut, userName: userName }))))));
};
