import {
  PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_FAILED,
  PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_REQUEST,
  PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_SUCCESS,
  PAY_UPGRADE_WITH_PAYPAL_FAILED,
  PAY_UPGRADE_WITH_PAYPAL_REQUEST,
  PAY_UPGRADE_WITH_PAYPAL_SUCCESS,
  PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_FAILED,
  PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_REQUEST,
  PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_SUCCESS,
  PAY_WITH_EXISTING_CARD_FAILED,
  PAY_WITH_EXISTING_CARD_REQUEST,
  PAY_WITH_EXISTING_CARD_SUCCESS,
  PAY_WITH_PAYPAL_FAILED,
  PAY_WITH_PAYPAL_REQUEST,
  PAY_WITH_PAYPAL_SUCCESS,
  REGISTER_PAYPAL_PAYMENT_FAILED,
  REGISTER_PAYPAL_PAYMENT_REQUEST,
  REGISTER_PAYPAL_PAYMENT_SUCCESS,
  REGISTER_UPGRADE_PAYPAL_PAYMENT_FAILED,
  REGISTER_UPGRADE_PAYPAL_PAYMENT_REQUEST,
  REGISTER_UPGRADE_PAYPAL_PAYMENT_SUCCESS,
} from "../../../reducers/v2/payments";

export const payWithExistingCardRequest = (payload) => ({
  type: PAY_WITH_EXISTING_CARD_REQUEST,
  payload,
});

export const payWithExistingCardSuccess = (payload) => ({
  type: PAY_WITH_EXISTING_CARD_SUCCESS,
  payload,
});

export const payWithExistingCardFailed = (payload) => ({
  type: PAY_WITH_EXISTING_CARD_FAILED,
  payload,
});

export const payWithExistingCardByTmpTokenRequest = (payload) => ({
  type: PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_REQUEST,
  payload,
});

export const payWithExistingCardByTmpTokenSuccess = (payload) => ({
  type: PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_SUCCESS,
  payload,
});

export const payWithExistingCardByTmpTokenFailed = (payload) => ({
  type: PAY_WITH_EXISTING_CARD_BY_TMP_TOKEN_FAILED,
  payload,
});

export const payUpgradeWithExistingCardByTmpTokenRequest = (payload) => ({
  type: PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_REQUEST,
  payload,
});

export const payUpgradeWithExistingCardByTmpTokenSuccess = (payload) => ({
  type: PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_SUCCESS,
  payload,
});

export const payUpgradeWithExistingCardByTmpTokenFailed = (payload) => {
  return {
    type: PAY_UPGRADE_WITH_EXISTING_CARD_BY_TMP_TOKEN_FAILED,
    payload,
  };
};

export const payWithPaypalRequest = (payload) => ({
  type: PAY_WITH_PAYPAL_REQUEST,
  payload,
});

export const payWithPaypalSuccess = (payload) => ({
  type: PAY_WITH_PAYPAL_SUCCESS,
  payload,
});

export const payWithPaypalFailed = (payload) => ({
  type: PAY_WITH_PAYPAL_FAILED,
  payload,
});

export const payWithPaypalByTmpTokenRequest = (payload) => ({
  type: PAY_WITH_PAYPAL_REQUEST,
  payload,
});

export const payWithPaypalByTmpTokenSuccess = (payload) => ({
  type: PAY_WITH_PAYPAL_SUCCESS,
  payload,
});

export const payWithPaypalByTmpTokenFailed = (payload) => ({
  type: PAY_WITH_PAYPAL_FAILED,
  payload,
});

export const registerPaypalPaymentRequest = (payload) => ({
  type: REGISTER_PAYPAL_PAYMENT_REQUEST,
  payload,
});

export const registerPaypalPaymentSuccess = (payload) => ({
  type: REGISTER_PAYPAL_PAYMENT_SUCCESS,
  payload,
});

export const registerPaypalPaymentFailed = (payload) => ({
  type: REGISTER_PAYPAL_PAYMENT_FAILED,
  payload,
});

export const registerUpgradePaypalPaymentRequest = (payload) => ({
  type: REGISTER_UPGRADE_PAYPAL_PAYMENT_REQUEST,
  payload,
});

export const registerUpgradePaypalPaymentSuccess = (payload) => ({
  type: REGISTER_UPGRADE_PAYPAL_PAYMENT_SUCCESS,
  payload,
});

export const registerUpgradePaypalPaymentFailed = (payload) => ({
  type: REGISTER_UPGRADE_PAYPAL_PAYMENT_FAILED,
  payload,
});

export const payUpgradeWithPaypalRequest = (payload) => ({
  type: PAY_UPGRADE_WITH_PAYPAL_REQUEST,
  payload,
});

export const payUpgradeWithPaypalSuccess = (payload) => ({
  type: PAY_UPGRADE_WITH_PAYPAL_SUCCESS,
  payload,
});

export const payUpgradeWithPaypalFailed = (payload) => ({
  type: PAY_UPGRADE_WITH_PAYPAL_FAILED,
  payload,
});
