import axios from "axios";
import appConfig from "../configs/appConfig";
import { rfc3986DecodeURIComponent } from "../utility/encode-rfc";

const instance = axios.create({
  baseURL: appConfig.analyticsApiURL,
});

const TIMEOUT_SEC = 30;
instance.interceptors.request.use(
  async (req) => {
    req.timeout = TIMEOUT_SEC * 1000;

    return req;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    if (response.data) {
      response.data = rfc3986DecodeURIComponent(response.data);
    }

    return response;
  },
  async (error) => {
    const originalRequest = error?.config;
    if (originalRequest.data && typeof originalRequest.data === "string") {
      originalRequest.data = JSON.parse(originalRequest.data);
    }

    return Promise.reject(error);
  }
);

export default instance;
