import React from "react";
import "../../../../assets/scss/pages/choose-account.scss";
import { toast } from "react-toastify";
import classnames from "classnames";

import Breakpoint from "../../../../components/hoc/Breakpoint";
import { Button } from "reactstrap";
import { HeaderWrapper } from "../../../../components/fts-components-v2/HeaderWrapper";

export default function ChooseAccount(props) {
  const {
    handleChooseAccount,
    chosenAccount,
    handleStepChange,
    accountOptions,
  } = props;

  return (
    <div className="FTS-choose-account-page">
      <HeaderWrapper />
      <div className="FTS-choose-account-wrapper fts-auth">
        <div className="FTS-choose-account-container">
          <div>
            <h2>Account Type</h2>
            <h4 className="p-0 m-0">
              Select what best describes your operation
            </h4>
          </div>
          <div className="FTS-account-options">
            {accountOptions.map((accountOption, index) => {
              return (
                <div
                  className="d-flex w-100"
                  key={index}
                  onClick={() => {
                    handleChooseAccount(accountOption.id);
                  }}
                >
                  <div
                    className={classnames(
                      {
                        ["FTS-account-single-option-active"]:
                          chosenAccount === accountOption.id,
                      },
                      "FTS-account-single-option",
                    )}
                  >
                    <div className="d-flex flex-column">
                      <div className="title">{accountOption.categoryName}</div>
                      <div className="description">
                        {accountOption.description}
                      </div>
                    </div>
                    <div
                      className={classnames(
                        {
                          ["radio-button-active"]:
                            chosenAccount === accountOption.id,
                        },
                        "radio-button",
                      )}
                    >
                      {chosenAccount === accountOption.id && (
                        <div className="radio-button-active-dot" />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Breakpoint md up>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                if (chosenAccount) {
                  handleStepChange(2);
                } else {
                  toast.error("Please choose your account type");
                }
              }}
            >
              Continue
            </Button>
          </Breakpoint>
        </div>
      </div>
      <Breakpoint sm down>
        <div className="FTS-choose-account-footer">
          <Button
            className="w-100"
            color="primary"
            type="button"
            onClick={() => {
              if (chosenAccount) {
                handleStepChange(2);
              } else {
                toast.error("Please choose your account type");
              }
            }}
          >
            Continue
          </Button>
        </div>
      </Breakpoint>
    </div>
  );
}
