import styled from "styled-components";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const FtsSelectableBox = styled(FtsWrapper) `
  ${(props) => {
    if (!props.isSelected) {
        return `cursor: pointer;`;
    }
    if (props.isSelected) {
        return `outline: ${Spacing["3xs"]} solid ${ColorsValue["blue-accent-300"]} !important; background-color: ${ColorsValue["greyscale-50"]};  `;
    }
}}

  &:hover {
    outline: ${Spacing["4xs"]} solid ${ColorsValue["blue-accent-300"]};
  }
`;
