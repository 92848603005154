import React from "react";
import { useSelector } from "react-redux";
import { CategoriesPickers } from "./CategoriesPickers";
import {
  setListingData,
  setListingInvalidFormFields,
} from "../../../../redux/actions/v2/listing";
import {
  selectListingAdsCategories,
  selectListingAdsSubCategories,
  selectListingInvalidFormFields,
} from "../../../../redux/reducers/v2/listing";

export const CategoriesPickerRow = () => {
  const selectedCategories = useSelector(selectListingAdsCategories);
  const invalidFormFields = useSelector(selectListingInvalidFormFields);
  const selectedSubCategories = useSelector(selectListingAdsSubCategories);

  return (
    <CategoriesPickers
      setListingData={setListingData}
      invalidFields={invalidFormFields}
      selectedCategories={selectedCategories}
      selectedSubCategories={selectedSubCategories}
      setInvalidFields={setListingInvalidFormFields}
      showErrorMsg={invalidFormFields && invalidFormFields.adsSubCategories}
    />
  );
};
