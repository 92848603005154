import React from "react";
import { useSelector } from "react-redux";
import { ListingImagesRowField } from "./ListingImagesRowField";
import {
  setListingData,
  setListingInvalidFormFields,
  uploadAdsAssetsRequest,
} from "../../../../redux/actions/v2/listing";
import {
  selectListingAdsAssets,
  selectListingAdsAssetsLength,
  selectListingData,
} from "../../../../redux/reducers/v2/listing";

export const ListingImagesRow = () => {
  const listingData = useSelector(selectListingData);
  const adsAssets = useSelector(selectListingAdsAssets);
  const adsAssetsSize = useSelector(selectListingAdsAssetsLength);

  return (
    <ListingImagesRowField
      adsAssets={adsAssets}
      listingData={listingData}
      adsAssetsSize={adsAssetsSize}
      setListingData={setListingData}
      uploadAdsAssetsRequest={uploadAdsAssetsRequest}
      setListingInvalidFormFields={setListingInvalidFormFields}
    />
  );
};
