import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";
import appConfig from "../../../configs/appConfig";
import { Button, Card, Col, Container, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { setSelectedInvoices } from "../../../redux/actions/invoices";
import { payWithPaypal } from "../../../redux/actions/payments";
import "../../../assets/scss/pages/upgrade-proofs.scss";
import { activities } from "../../../constants";
import { toast } from "react-toastify";

import RejectProofModal from "../../../components/fts/reject-proof-modal";
import Icon from "../../../components/fts/Icons";
import Footer from "../../../components/fts/footer";
import { removeAllCookies } from "../../../cookies";
import ListingItem from "../../../components/fts/listing-item";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import logger from "../../../utility/logger";

const UpgradeProof = ({ match }) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showRejectReason, setShowRejectReason] = useState(false);

  const [proofId, setProofId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [requestedUpdate, setRequestedUpdate] = useState(null);

  useEffect(() => {
    removeAllCookies();
  }, []);

  const getProofs = useCallback(() => {
    axios
      .get(appConfig.apiURL + "user/proof/upgrade-proof", {
        headers: { "temporary-token": match.params.token },
      })
      .then(({ data }) => {
        if (data?.id) {
          setRequestedUpdate(
            JSON.parse(data?.listing?.update?.requestedUpdate),
          );
          setProofId(data?.id);
        }

        setIsLoading(false);
      })
      .catch((e) => {
        logger.error(e);
        setError("Oops! Something went wrong. Please try again.");
      });
  }, [match.params.token]);

  const getUserData = useCallback(() => {
    FTSAxiosV2.get("/user/me", {
      headers: { "temporary-token": match.params.token },
    })
      .then(({ data }) => {
        setUserData(data?.user);
      })
      .catch((err) => {
        logger.error(err);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [match.params.token]);

  useEffect(() => {
    setIsLoading(true);
    if (match?.params?.token) {
      getProofs();
      getUserData();
    }
  }, [match.params.token, getProofs, getUserData]);

  const handleAcceptActivity = useCallback(async () => {
    let activityPayload = {
      status: 1,
      userId: requestedUpdate?.userId,
      adId: requestedUpdate?.adId,
      proofId,
      activityType: "approved_proof",
      activity: activities.approvedProof
        .replace("{{adId}}", requestedUpdate?.adId)
        .replace("{{userId}}", requestedUpdate?.userId),
      iconName: "Listings",
    };

    axios
      .post(
        appConfig.apiURL + "user/activity",
        { ...activityPayload },
        { headers: { "temporary-token": match.params.token } },
      )
      .then((data) => logger.info(data, "SUCCESS"))
      .catch((err) => logger.error(err));
  }, [match.params.token, requestedUpdate]);

  const handleRejectActivity = useCallback(async () => {
    let activityPayload = {
      status: 1,
      userId: requestedUpdate?.userId,
      adId: requestedUpdate?.adId,
      proofId,
      activityType: "rejected_proof",
      activity: activities.rejectedProof
        .replace("{{proofId}}", proofId)
        .replace("{{adId}}", requestedUpdate?.adId)
        .replace("{{userId}}", requestedUpdate?.userId),
      iconName: "Listings",
    };

    axios
      .post(
        appConfig.apiURL + "user/activity",
        { ...activityPayload },
        { headers: { "temporary-token": match.params.token } },
      )
      .then((data) => logger.info(data, "SUCCESS"))
      .catch((err) => logger.error(err));
  }, [match.params.token, requestedUpdate]);

  const handleProofsNotification = useCallback(
    async ({ action = "approved" }) => {
      const payload = {
        title: `Listing proof(s) ${action}!`,
        description: `Vendor, ${userData?.companyName} ${action} listing proof(s) for listing(s):${requestedUpdate?.adId} `,
        icon: "SendProof",
        sendTo: userData?.adminAccountOwner,
        status: 1,
        linkTitle: "Go to sent proofs",
        link: `/accounts/${userData?.id}?activeTab=7`,
      };

      return await axios.post(
        appConfig.apiURL + "profile/notifications/admin",
        payload,
        {
          headers: { "temporary-token": match.params.token },
        },
      );
    },
    [match.params.token, userData, requestedUpdate],
  );

  const handleButtonClick = useCallback(
    (type) => {
      if (type === "approve") {
        axios
          .put(
            appConfig.apiURL + "user/proofs",
            { proofIds: [proofId], status: 1, lastUpdatedBy: "Vendor" },
            { headers: { "temporary-token": match.params.token } },
          )
          .then(() => {
            handleAcceptActivity();
            setProofId(null);
            handleProofsNotification({ action: "approved" });

            toast.success("Successfully approved");
            setShowRejectReason(false);
          });
      }
      if (type === "reject") {
        setShowRejectReason(true);
        handleProofsNotification({ action: "rejected" });
      }

      if (type === "view_vendor_page") {
        window.open(
          `${window.location.origin}/page/${requestedUpdate?.seoUrlIdentifier}`,
          "_blank",
        );
      }
    },
    [
      match.params.token,
      proofId,
      handleAcceptActivity,
      handleProofsNotification,
    ],
  );

  const onReject = useCallback(
    (rejectReason) => {
      axios
        .put(
          appConfig.apiURL + "user/proofs",
          {
            proofIds: [proofId],
            status: 2,
            rejectedReason: rejectReason,
            lastUpdatedBy: "Vendor",
          },
          { headers: { "temporary-token": match.params.token } },
        )
        .then(() => {
          handleRejectActivity();
          setProofId(null);
          toast.success("Successfully rejected");
          setShowRejectReason(false);
        });
    },
    [match.params.token, proofId, setShowRejectReason, handleRejectActivity],
  );

  if (error) {
    return (
      <>
        <HeaderWrapper />
        <div
          style={{ height: "50vh", width: "100vw" }}
          className={
            "d-flex flex-column justify-content-center align-items-center"
          }
        >
          <h2>{error}</h2>
        </div>
        <Footer />
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <HeaderWrapper />
        <div
          style={{ height: "50vh", width: "100vw" }}
          className={
            "d-flex flex-column justify-content-center align-items-center"
          }
        >
          <Spinner size="lg" />
        </div>
        <Footer />
      </>
    );
  }

  if (!proofId && !isLoading) {
    return (
      <>
        <HeaderWrapper />
        <Container>
          <Col lg={{ size: 8, offset: 2 }}>
            <Card
              style={{ padding: "80px" }}
              className="d-flex justify-content-center align-items-center rounded-lg m-5"
            >
              <Icon name={"NoProofs"} size={100} color="#fafafa" />
              <h2 className={"mt-3 mb-1"}>
                There are no listing proofs to review.
              </h2>
              <p className="text-center">
                If you need to make changes to any of your listings, please
                contact us on (847) 586-9110.
              </p>
            </Card>
          </Col>
        </Container>
        <Footer />
      </>
    );
  }

  return (
    <>
      <HeaderWrapper />
      <div className="custom-proofs-container">
        <h2 className={"mt-2 mb-2"}>Your Upgrade Listing proof</h2>
      </div>

      {requestedUpdate && (
        <div className="upgrade-proof-wrapper">
          <ListingItem data={requestedUpdate} hideActions />
        </div>
      )}
      <div
        className="position-sticky bg-white p-2 custom-proofs-container-footer"
        style={{
          width: "100%",
          bottom: 0,
          left: 200,
          boxShadow: " 0px -2px 16px #EAEAEA",
        }}
      >
        <Container className="position-relative d-flex justify-content-center align-items-center">
          <div className="d-flex custom-upgrade-proofs-container-footer-content">
            <Button
              onClick={() => handleButtonClick("reject")}
              color={"warning"}
              className={"btn-reject d-flex align-items-center mr-1"}
            >
              <Icon size={30} color={"#fff"} name={"Close"} />
              <span className={"ml-1"}>Reject</span>
            </Button>
            <Button
              onClick={() => handleButtonClick("approve")}
              color={"warning"}
              className={"btn-approve d-flex align-items-center"}
            >
              <Icon size={30} name={"CheckWhite"} />
              <span className={"ml-1"}>Approve</span>
            </Button>
          </div>
        </Container>
      </div>
      {showRejectReason && (
        <RejectProofModal
          isOpen={showRejectReason}
          toggleRejectedReasonModal={() =>
            setShowRejectReason(!showRejectReason)
          }
          onReject={onReject}
        />
      )}
      <Footer />
    </>
  );
};

const mapDispatchToProps = {
  setSelectedInvoices,
  payWithPaypal,
};

export default connect(null, mapDispatchToProps)(UpgradeProof);
