import React from "react";
import { history } from "../../../../../history";
import { useDispatch, useSelector } from "react-redux";
import { extractParameter } from "../../../../../utility/param-extract";
import { selectListingData } from "../../../../../redux/reducers/v2/listing";
import { setListingInvalidFormFields } from "../../../../../redux/actions/v2/listing";
import { listingDetailsRowValidator } from "../../validators/listingDetailsRowValidator";
import { listingContactRowValidator } from "../../validators/listingContactRowValidator";
import { ListingInfoSmFooterComponent } from "../../components/ListingInfoSmFooterComponent";
import { listingSearchCategoriesRowValidator } from "../../validators/listingSearchCategoriesRowValidator";

export const ListingInfoSmFooter = () => {
  const listingData = useSelector(selectListingData);
  const dispatch = useDispatch();

  async function onContinue() {
    try {
      dispatch(setListingInvalidFormFields({ data: {} }));
      await listingDetailsRowValidator.validate(listingData);
      await listingContactRowValidator.validate(listingData);
      await listingSearchCategoriesRowValidator.validate(listingData);
      const priceId = extractParameter("selectedListingPriceId");
      const convertedToVendor = extractParameter("convertedToVendor");

      const searchParams = new URLSearchParams();

      if (priceId) {
        searchParams.set("selectedListingPriceId", priceId);
      }

      if (convertedToVendor) {
        searchParams.set("convertedToVendor", convertedToVendor);
      }

      history.push({
        pathname: "/dashboard/create-listing/listing-images",
        search: `?${searchParams.toString()}`,
      });
    } catch (e) {
      if (e.name !== "ValidationError") return;
      const data = { [e.path]: e.errors[0] };
      dispatch(setListingInvalidFormFields({ data }));
    }
  }

  const primaryButtonProps = {
    label: "Continue",
    onClick: onContinue,
  };

  return (
    <ListingInfoSmFooterComponent primaryButtonProps={primaryButtonProps} />
  );
};
