import React, { useEffect, useState } from "react";
import { TextareaSizes } from "@find-truck-service/types/ui/textarea";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { Textarea } from "@find-truck-service/ui/src/components/react/Textarea";
import { requiredPhoneNumberValidate } from "@find-truck-service/types/validators/global";
import { PhoneNumberInput } from "@find-truck-service/ui/src/components/react/PhoneNumberInput";
import { ModalFooterComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalFooterComponent";
import { ModalContentComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalContentComponent";
const initialReason = {
    rejectedReason: "",
    preferACall: false,
    rejectReasonPhoneNumber: "",
};
export const ModalContent = ({ onSubmit, onClose }) => {
    const [reason, setReason] = useState(initialReason);
    const [submitButtonIsEnabled, setSubmitButtonIsEnabled] = useState(false);
    const onChange = (value, key) => setReason((prev) => ({ ...prev, [key]: value }));
    const togglePrefer = () => setReason((prev) => ({ ...prev, preferACall: !prev.preferACall }));
    const onSuccess = () => {
        const submitData = { ...reason };
        if (!reason.preferACall)
            delete submitData.rejectReasonPhoneNumber;
        delete submitData.preferACall;
        onSubmit(submitData);
    };
    useEffect(() => {
        const validatePhoneNumber = async () => {
            try {
                await requiredPhoneNumberValidate.validate(reason.rejectReasonPhoneNumber);
                return true;
            }
            catch (error) {
                return false;
            }
        };
        const checkValidation = async () => {
            if (!reason.rejectedReason?.trim())
                return setSubmitButtonIsEnabled(false);
            if (!reason.preferACall)
                return setSubmitButtonIsEnabled(true);
            const isPhoneNumberValid = await validatePhoneNumber();
            if (!isPhoneNumberValid)
                return setSubmitButtonIsEnabled(false);
            setSubmitButtonIsEnabled(true);
        };
        checkValidation();
    }, [reason]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalContentComponent, { gap: "2xl", display: "flex", direction: "column" },
            React.createElement(Textarea, { required: true, size: TextareaSizes.md, label: "Provide details", value: reason.rejectedReason, placeholder: "e.g. incorrect phone number, address..", onChange: (e) => onChange(e.target.value, "rejectedReason") }),
            React.createElement(Checkbox, { onClick: togglePrefer, size: CheckboxSizes.md, checked: !!reason.preferACall, label: "Check if you prefer a call" }),
            reason.preferACall && (React.createElement(PhoneNumberInput, { required: true, size: InputSizes.md, variant: InputVariants.outlined, name: "rejectReasonPhoneNumber", value: reason.rejectReasonPhoneNumber, label: "Preferred phone number for call", onChange: (e) => onChange(e.target.value, "rejectReasonPhoneNumber") }))),
        React.createElement(ModalFooterComponent, { onError: onClose, errorLabel: "Cancel", onSuccess: onSuccess, successLabel: "Submit", successDisabled: !submitButtonIsEnabled })));
};
