import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { listingDetailsRowValidator } from "../../validators/listingDetailsRowValidator";
import { listingContactRowValidator } from "../../validators/listingContactRowValidator";
import { ListingInfoMdFooterComponent } from "../../components/ListingInfoMdFooterComponent";
import {
  selectListingAdsAssetsIsLoading,
  selectListingData,
} from "../../../../../redux/reducers/v2/listing";
import {
  createListingRequest,
  setListingInvalidFormFields,
} from "../../../../../redux/actions/v2/listing";
import { listingSearchCategoriesRowValidator } from "../../validators/listingSearchCategoriesRowValidator";

export const ListingInfoMdFooter = () => {
  const dispatch = useDispatch();
  const listingData = useSelector(selectListingData);
  const isDisabled = useSelector(selectListingAdsAssetsIsLoading);

  async function createListing() {
    try {
      dispatch(setListingInvalidFormFields({ data: {} }));
      await listingDetailsRowValidator.validate(listingData);
      await listingContactRowValidator.validate(listingData);
      await listingSearchCategoriesRowValidator.validate(listingData);
      dispatch(createListingRequest());
    } catch (e) {
      if (e.name !== "ValidationError") return;
      const data = { [e.path]: e.errors[0] };
      dispatch(setListingInvalidFormFields({ data }));
    }
  }

  const primaryButtonProps = {
    label: "Continue",
    disabled: isDisabled,
    onClick: createListing,
  };

  return (
    <ListingInfoMdFooterComponent primaryButtonProps={primaryButtonProps} />
  );
};
