// for files filter and default
import { CLIENT_FILE_SIZE_LIMIT_BYTES } from "../constants";

const supportedTypes = [
  { label: "All", value: "" },
  { label: "Png", value: "png" },
  { label: "Jpg", value: "jpg" },
  { label: "Jpeg", value: "jpeg" },
  { label: "Psd", value: "psd" },
  { label: "Gif", value: "gif" },
  { label: "Pdf", value: "pdf" },
  { label: "Ai", value: "ai" },
];
export default supportedTypes;

// for listing and promotion
export const listingAssetsFileTypes = [
  { label: "Png", value: "png" },
  { label: "Jpg", value: "jpg" },
  { label: "Jpeg", value: "jpeg" },
  { label: "Psd", value: "psd" },
  { label: "Gif", value: "gif" },
  { label: "Pdf", value: "pdf" },
  { label: "AI", value: "ai" },
];

export const accountFileTypes = [
  { label: "Jpeg", value: "jpeg" },
  { label: "Jpg", value: "jpg" },
  { label: "Png", value: "png" },
  { label: "Pdf", value: "pdf" },
  { label: "Psd", value: "psd" },
  { label: "Gif", value: "gif" },
];

// for account, todo and assets
export const otherFileTypes = [
  { label: "Jpeg", value: "jpeg" },
  { label: "Jpg", value: "jpg" },
  { label: "Gif", value: "gif" },
  { label: "Png", value: "png" },
  { label: "Pdf", value: "pdf" },
  { label: "Psd", value: "psd" },
];

// for drivers/trucks bulk upload
export const allowedBulkUploadFileTypes = [
  { label: "Pdf", value: "pdf" },
  { label: "Xlsx", value: "xlsx" },
  { label: "Xls", value: "xls" },
  { label: "Csv", value: "csv" },
];

export const LISTING_FILES_LIMIT = 3;

export const ALLOWED_FILE_TYPES = ".png,.jpg,.jpeg,.psd,.gif,.pdf,.ai";

export const MAXIMUM_FILE_SIZE_MSG = `File is too big! Max file size allowed is ${parseInt(
  CLIENT_FILE_SIZE_LIMIT_BYTES / 1024 / 1024,
)}MB`;

export const FILES_LIMIT_MSG = `You can only upload a maximum of ${LISTING_FILES_LIMIT} files`;
