import styled from "styled-components";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ZIndexValues } from "@find-truck-service/types/constants/zIndexValues";
//TODO Add drawerBg background-image: url("/dist/backgrounds/DrawerBg.svg");
export const FtsSideOutDrawerContainer = styled(FtsWrapper).withConfig({
    shouldForwardProp: (prop) => !["isOpen"].includes(prop),
}) `
  height: 100%;
  position: fixed;
  animation: ${(props) => (props.isOpen ? "slideInDrawer" : "slideOutDrawer")} 0.3s ease-out;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${ColorsValue["red-brand-500"]};
  z-index: ${ZIndexValues.DRAWER};
  bottom: 0;
  left: 0;
  transition:
    visibility,
    width 0.2s ease-out;
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  @keyframes slideInDrawer {
    from {
      left: -100%;
    }
    to {
      left: 0;
    }
  }

  @keyframes slideOutDrawer {
    from {
      left: 0;
    }
    to {
      left: -100%;
    }
  }
`;
