import * as Yup from "yup";
import { passwordValidate } from "@find-truck-service/types/validators/global";

export const createAccountValidator = Yup.object().shape({
  recaptcha: Yup.string().required("Please confirm that you're not a robot"),
  password: passwordValidate,
  email: Yup.string().email("Invalid email").required("Required"),
  lastName: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
});
