/* eslint-disable indent */
/* eslint-disable react/display-name */
import React from "react";
import Icon from "../Icons";
import "./related-listings-table.scss";

export const defineRelatedListingsColumns = (
  toggleListingReportPreview,
  showTablesLoadingState,
  listingPreviewInProgress,
  includeFreeListingReport,
) => {
  let columns = [
    {
      headerName: "Listing ID",
      field: "id",
      sortable: false,
      unSortIcon: false,
      suppressMovable: true,
      width: 100,
      cellRendererFramework: (params) => <div>#{params.data?.id}</div>,
    },
    {
      headerName: "Listing name",
      field: "name",
      sortable: false,
      unSortIcon: false,
      suppressMovable: true,
      width: 270,
      cellRendererFramework: (params) => (
        <div
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "270px",
          }}
        >
          {params?.data?.name}
        </div>
      ),
    },
    {
      headerName: "Address",
      field: "address",
      sortable: false,
      unSortIcon: false,
      suppressMovable: true,
      width: 230,
      cellRendererFramework: (params) => (
        <div
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "230px",
          }}
        >
          {params?.data?.address}
        </div>
      ),
    },
    {
      headerName: "City",
      field: "city",
      sortable: false,
      unSortIcon: false,
      suppressMovable: true,
      width: 120,
      cellRendererFramework: (params) => (
        <div
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "120px",
          }}
        >
          {params?.data?.city}
        </div>
      ),
    },
    {
      headerName: "State",
      field: "state",
      sortable: false,
      unSortIcon: false,
      suppressMovable: true,
      width: 70,
      cellRendererFramework: (params) => <div>{params?.data?.state}</div>,
    },
    {
      headerName: "Primary number",
      field: "phoneNumber",
      sortable: false,
      unSortIcon: false,
      suppressMovable: true,
      width: 150,
      cellRendererFramework: (params) => <div>{params?.data?.phoneNumber}</div>,
    },
    {
      headerName: "",
      field: "phoneNumberPrimary",
      sortable: false,
      unSortIcon: false,
      suppressMovable: true,
      width: 110,
      cellRendererFramework: (params) => (
        <div
          className={`see-report-btn cursor-pointer ${
            params?.data?.priceId === 5 &&
            !includeFreeListingReport &&
            "see-report-btn-disabled"
          }`}
          onClick={() => {
            if (params?.data?.priceId === 5) {
              if (includeFreeListingReport) {
                showTablesLoadingState();
                toggleListingReportPreview(params?.data.id);
              }
            } else {
              showTablesLoadingState();
              toggleListingReportPreview(params?.data.id);
            }
          }}
        >
          <div>See Report</div>
          <Icon name="LinkRight" size={24} />
        </div>
      ),
    },
  ];

  return columns;
};
