import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

export const CompleteRegistrationFormTitle = () => {
  return (
    <FtsColumn size={{ xs: 12 }}>
      <FtsColumn size={{ xs: 12 }} mb={"xs"}>
        <Span
          weight={WeightSize.bold}
          variant={VariantsValues["text-md"]}
          color={ColorsValue["greyscale-800"]}
        >
          Account Password
        </Span>
      </FtsColumn>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.regular}
          variant={VariantsValues["text-2xs"]}
          color={ColorsValue["greyscale-800"]}
        >
          Create your own password to complete registration
        </Span>
      </FtsColumn>
    </FtsColumn>
  );
};
