import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";

export const ListingInfoWrapper = ({ children, ...rest }) => {
  return (
    <FtsRow {...rest}>
      <FtsColumn
        size={{ xs: 12, sm: 12, md: 12, lg: 10 }}
        offset={{ xs: 0, sm: 0, md: 0, lg: 1 }}
      >
        <FtsWrapper
          m={"auto"}
          gap={"4xl"}
          display={"flex"}
          boxShadow={"sm"}
          bg={ColorsValue["greyscale-0"]}
          align={"flex-start"}
          direction={"column"}
          borderRadius={"2xs"}
          px={{ xs: "xl", sm: "4xl", md: "4xl", lg: "4xl" }}
          py={{ xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" }}
        >
          {children}
        </FtsWrapper>
      </FtsColumn>
    </FtsRow>
  );
};
