import React from "react";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Button } from "@find-truck-service/ui/src/components/react/Button";

const VerificationRequiredActionsXs = ({ onVerify, onResendCode }) => {
  return (
    <FtsWrapper
      display={{ xs: "flex", sm: "none", md: "none", lg: "none" }}
      direction="column"
      gap="xl"
      m="xl"
    >
      <Button
        width="100%"
        size={ButtonSizes.md}
        onClick={onVerify}
        color={ButtonColorsValue.accent}
        type={ButtonTypes.contained}
        label={"Verify"}
      />

      <Button
        width="100%"
        size={ButtonSizes.md}
        onClick={onResendCode}
        color={ButtonColorsValue.accent}
        type={ButtonTypes.outlined}
        label={"Resend Code"}
      />
    </FtsWrapper>
  );
};

export default VerificationRequiredActionsXs;
