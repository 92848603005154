import React, { useEffect } from "react";
import { Message } from "@find-truck-service/ui/src/components/react/Message";

export const ListingInlineError = ({ inlineError }) => {
  useEffect(() => {
    if (inlineError) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [inlineError]);

  if (!inlineError) return null;
  return <Message type={"error"} title={""} subtitle={inlineError} />;
};
