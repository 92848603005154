import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonColorsValue } from "../dist/types/ui/button";
import {
  convertToVendorRequest,
  countSubUsersRequest,
} from "../../../redux/actions/v2/auth";
import { history } from "../../../history";
import { toast } from "react-toastify";
import { getUserTypeName } from "../../../utility/getUserTypeName";
import { ConfirmationDialog } from "../dist/ui/src/components/react/ConfirmationDialog";
import Warning from "@find-truck-service/ui/src/components/react/Warning";
import { setLoggedInUserData } from "redux/actions/auth/loginActions";
import {
  selectAuthIsLoading,
  selectUserData,
} from "../../../redux/reducers/v2/auth";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { extractParameter } from "../../../utility/param-extract";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";

const WrongAccount = () => {
  const dispatch = useDispatch();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const userData = useSelector(selectUserData);
  const isLoading = useSelector(selectAuthIsLoading);
  const selectedListingPriceId = extractParameter("selectedListingPriceId");
  const countSubUsersCountSuccessHandler = ({ data }) => {
    if (data.count) {
      setShowWarningModal(true);
    } else {
      convertToVendorHandler();
    }
  };

  const countSubUsersCountErrorHandler = (e) => {
    const message = e.response
      ? e.response.data.message
      : "Error while getting sub users count";
    toast.error(message);
  };

  const countSubUsersHandler = useCallback(() => {
    dispatch(
      countSubUsersRequest({
        onSuccess: countSubUsersCountSuccessHandler,
        onError: countSubUsersCountErrorHandler,
      }),
    );
  }, []);

  const convertToVendorRequestSuccessHandler = ({ data }) => {
    dispatch(
      setLoggedInUserData({
        loggedInUser: { ...data.data },
        loggedInWith: "jwt",
      }),
    );

    history.push(
      `/dashboard/create-listing/business-details?selectedListingPriceId=${selectedListingPriceId}&convertedToVendor=true`,
    );
  };

  const convertToVendorRequestErrorHandler = (e) => {
    const message = e.response
      ? e.response.data.message
      : "Error while converting to vendor";
    toast.error(message);
  };

  const convertToVendorHandler = () => {
    dispatch(
      convertToVendorRequest({
        data: null,
        onSuccess: convertToVendorRequestSuccessHandler,
        onError: convertToVendorRequestErrorHandler,
      }),
    );
  };

  const toggleShowWarningModal = () => {
    setShowWarningModal(!showWarningModal);
  };

  return (
    <>
      {isLoading && <FullPageLoader />}
      <ConfirmationDialog
        isOpen={showWarningModal}
        size="xs"
        title="Switch to Vendor Account?"
        iconName="Danger"
        subtitle={`By switching to a Vendor account, you will lose all data related to your current ${getUserTypeName(userData.userType)} account, including saved vendors, notes, call logs, shared vendors, and ratings. This change is permanent and cannot be undone.\n\n Are you sure?`}
        containedButton={{
          label: "Change to Vendor Account",
          color: ButtonColorsValue.error,
          onClick: convertToVendorHandler,
        }}
        outlinedButton={{
          label: "Cancel",
          color: ButtonColorsValue.accent,
          onClick: toggleShowWarningModal,
        }}
      />

      <FtsWrapper mt={{ xs: "0", sm: "0", md: "3xl", lg: "4xl" }}>
        <Warning
          iconName="AlertTriangle"
          title={`You have a ${getUserTypeName(userData.userType)} account`}
          message={`In order to create a listing you have to change your account to a Vendor account or create a new account. Please note that if you change your current account you will lose the ${getUserTypeName(userData.userType)} features, including your saved vendors, notes, call logs, shared vendors, and ratings.`}
          containedButton={{
            label: "Change to Vendor Account",
            color: ButtonColorsValue.accent,
            onClick: countSubUsersHandler,
          }}
          outlinedButton={{
            label: "Create New Account",
            color: ButtonColorsValue.accent,
            onClick: () => {
              history.push({
                pathname: `/dashboard/get-listed/account`,
              });
            },
          }}
        />
      </FtsWrapper>
    </>
  );
};

export default WrongAccount;
