import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MobileDescriptionField } from "../../components/MobileDescriptionField";
import { MemoizedTemporaryLinkDescriptionModal } from "./TemporaryLinkDescriptionModal";
import { selectTemporaryLinkListingDescription } from "../../../../../redux/reducers/v2/temporaryListingLink";

export const TemporaryLinkMobileDescription = () => {
  const [showModal, setShowModal] = useState(false);
  const description = useSelector(selectTemporaryLinkListingDescription);

  return (
    <MobileDescriptionField
      description={description}
      setShowModal={setShowModal}
    >
      <MemoizedTemporaryLinkDescriptionModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </MobileDescriptionField>
  );
};
