import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
export const BannerTitleHandler = styled(FtsWrapper).withConfig({
    shouldForwardProp: (prop) => prop !== "withButtons",
}) `
  flex: 1;
  display: flex;
  gap: ${Spacing.lg};
  align-items: center;
  justify-content: ${(props) => (props.withButtons ? "flex-start" : "center")};
`;
