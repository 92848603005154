import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ShadowsValue } from "@find-truck-service/types/ui/shadows";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const FtsButtonsFooter = styled(FtsWrapper).attrs({
    direction: { xs: "column-reverse", sm: "row", md: "row", lg: "row" },
    px: { xs: "xl", sm: "2xl", md: "4xl", lg: "4xl" },
    py: { xs: "xl", sm: "lg", md: "lg", lg: "lg" },
}) `
  display: flex;
  gap: ${Spacing.lg};
  align-items: center;
  justify-content: center;
  box-shadow: ${ShadowsValue.md};
  background-color: ${ColorsValue["greyscale-0"]};
`;
