import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { stepsMobile } from "../constants";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

const StepperMobile = ({ activeStepId }) => {
  const label = stepsMobile.find((step) => step.id === activeStepId).label;

  return (
    <FtsWrapper
      display={{ xs: "flex", sm: "none", md: "none", lg: "none" }}
      direction="column"
      width="100%"
    >
      <FtsWrapper
        display="flex"
        align="center"
        width="100%"
        gap={"2xs"}
        borderRadius="sm"
      >
        {stepsMobile.map((step) => {
          const isActiveOrDone = step.id <= activeStepId;
          const bg = isActiveOrDone
            ? ColorsValue["blue-accent-300"]
            : ColorsValue["greyscale-50"];
          return <FtsWrapper key={step.id} height={"3px"} bg={bg} flex={1} />;
        })}
      </FtsWrapper>
      <Span
        pt="xl"
        color={ColorsValue["greyscale-800"]}
        variant={VariantsValues["text-md"]}
        weight={WeightSize.bold}
      >
        {label}
      </Span>
    </FtsWrapper>
  );
};

export default StepperMobile;
