const formatShortHours = (hours) => {
  if (!hours) return "";
  let [time, suffix] = hours.trim().split(" ");
  time = time.split(":");

  if (time.length > 1) {
    time = time.join(":");
  } else {
    time = `${time[0]}:00`;
  }

  return `${time} ${suffix}`;
};

export default formatShortHours;
