import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { H3 } from "@find-truck-service/ui/src/FtsText";
import React from "react";

export const TemporaryLinkPageTitle = ({ title }) => {
  return (
    <H3
      px={"xl"}
      textAlign={{
        xs: "left",
        sm: "center",
        md: "center",
        lg: "center",
      }}
      weight={WeightSize.bold}
      variant={VariantsValues["text-lg"]}
      pt={{ xs: "2xl", sm: "2xl", md: "3xl", lg: "4xl" }}
      pb={{ xs: "2xl", sm: "2xl", md: "3xl", lg: "3xl" }}
    >
      {title}
    </H3>
  );
};
