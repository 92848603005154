import React, { Component } from "react";

import { Button, Input, Row } from "reactstrap";

import FTSModal from "../fts-modal";
import CloseButton from "../CloseButton";
import "../../../assets/scss/components/fts/reject-proof-modal.scss";

class RejectProofModal extends Component {
  state = {
    rejectReason: "",
  };
  render() {
    const {
      isOpen,
      title,
      closeModal,
      onClose,
      toggleRejectedReasonModal,
      onReject,
    } = this.props;

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        modalClasses={"fts-rejected-read-reason-modal"}
        centered={true}
        style={{ maxWidth: "716px" }}
        footerCTA={() => {
          return (
            <div className="confirm-reject-reason-modal-footer">
              <div>
                <span className="mr-1">
                  <Button onClick={toggleRejectedReasonModal} color="secondary">
                    Cancel
                  </Button>
                </span>
                <Button
                  disabled={!this.state.rejectReason}
                  color="warning"
                  className={"btn-reject-modal"}
                  onClick={() => onReject(this.state.rejectReason)}
                >
                  Reject proof
                </Button>
              </div>
            </div>
          );
        }}
      >
        <div className="fts-rejected-reason-list-modal-body">
          <span className="fts-rejected-reason-list-modal-body-wrapper">
            <span className="fts-rejected-reason-list-modal-title">
              <span className="d-flex align-items-center">
                <h3>Reject reason</h3>
              </span>
              <CloseButton onClick={toggleRejectedReasonModal} />
            </span>
            <p>
              Please provide the reason for rejecting so we can make the
              necessary changes.
            </p>
            <Row className="fts-rejected-reason-list-modal-input">
              <Input
                value={this.state.rejectReason}
                onChange={(e) =>
                  this.setState({ rejectReason: e.target.value })
                }
                type="textarea"
                rows="3"
                placeholder=" "
                id="add-comment"
              />
            </Row>
          </span>
        </div>
      </FTSModal>
    );
  }
}

export default RejectProofModal;
