import * as Yup from "yup";
import {
  phoneNumberValidate,
  requiredPhoneNumberValidate,
  zipValidate,
} from "@find-truck-service/types/validators/global";

export const completeAccountInfoValidator = Yup.object().shape({
  companyName: Yup.string().required("Company name is required").nullable(),
  address: Yup.string().required("Mailing address is required").nullable(),
  city: Yup.string().required("City is required").nullable(),
  state: Yup.string().required("Select state").nullable(),
  zip: zipValidate,
  phoneNumber: requiredPhoneNumberValidate,
  jobTitle: Yup.string().required("Required"),
  phoneNumberSecondary: phoneNumberValidate,
  mobilePhone: phoneNumberValidate,
  yearsBusiness: Yup.string().required("Required"),
  referal: Yup.string().required("Required").nullable(),
});
