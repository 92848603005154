import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  CREATE_USER_CARD_BY_TMP_TOKEN_REQUEST,
  CREATE_USER_CARD_REQUEST,
  GET_USER_CARDS_BY_TMP_TOKEN_REQUEST,
  GET_USER_CARDS_REQUEST,
  selectCardsData,
} from "../../reducers/v2/cards";
import handleError from "../../../handlers/error";
import FTSV2Axios from "../../../axios/ftsv2.instance";
import {
  createUserCardFailed,
  createUserCardSuccess,
  getUserCardsFailed,
  getUserCardsSuccess,
} from "../../actions/v2/cards";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";

function* getUserCardsSaga() {
  try {
    const { data } = yield call(FTSV2Axios.get, "/cards/list");

    yield put(getUserCardsSuccess({ data: data.data }));
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while getting cards";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    yield put(getUserCardsFailed({ data: { message, inlineMessage } }));
  }
}

function* createUserCardSaga(action) {
  try {
    let cards = yield select(selectCardsData);
    const { data } = yield call(FTSV2Axios.post, "/cards/create", {
      cardData: action.payload.data,
      paymentGateway: "stripe",
    });

    yield put(createUserCardSuccess({ data: data.data }));
    let newCardsData = [...cards, data.data];
    if (data.data.isDefault) {
      newCardsData = [data.data, ...cards];
      cards = cards.map((card) => ({ ...card, isDefault: false }));
    }

    if (typeof action.payload.onSuccess === "function") {
      action.payload.onSuccess(data.data);
    }

    yield put(getUserCardsSuccess({ data: newCardsData }));
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while creating card";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    yield put(createUserCardFailed({ data: { message, inlineMessage } }));
  }
}

function* getUserCardsByTmpTokenSaga() {
  try {
    const { data } = yield call(
      FTSAxiosTemporaryLink.get,
      "/cards/temporary-token/list",
    );

    yield put(getUserCardsSuccess({ data: data.data }));
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while getting cards";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    yield put(getUserCardsFailed({ data: { message, inlineMessage } }));
  }
}

function* createUserCardByTmpTokenSaga(action) {
  try {
    let cards = yield select(selectCardsData);
    const { data } = yield call(
      FTSAxiosTemporaryLink.post,
      "/cards/temporary-token/create",
      {
        cardData: action.payload.data,
        paymentGateway: "stripe",
      },
    );

    yield put(createUserCardSuccess({ data: data.data }));
    let newCardsData = [...cards, data.data];
    if (data.data.isDefault) {
      newCardsData = [data.data, ...cards];
      cards = cards.map((card) => ({ ...card, isDefault: false }));
    }

    if (typeof action.payload.onSuccess === "function") {
      action.payload.onSuccess(data.data);
    }

    yield put(getUserCardsSuccess({ data: newCardsData }));
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while creating card";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    yield put(createUserCardFailed({ data: { message, inlineMessage } }));
  }
}

export default function* cardsWatcher() {
  yield takeLatest(GET_USER_CARDS_REQUEST, getUserCardsSaga);
  yield takeLatest(
    GET_USER_CARDS_BY_TMP_TOKEN_REQUEST,
    getUserCardsByTmpTokenSaga,
  );
  yield takeLatest(CREATE_USER_CARD_REQUEST, createUserCardSaga);
  yield takeLatest(
    CREATE_USER_CARD_BY_TMP_TOKEN_REQUEST,
    createUserCardByTmpTokenSaga,
  );
}
