import React, { useCallback } from "react";
import { mapRelatedFields } from "../../constants";
import { capitalizeFirstLetter } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { ListingDetails } from "../../components/ListingDetails";
import { selectStates } from "../../../../../redux/reducers/essentialLists";
import {
  selectTemporaryLinkInvalidFormFields,
  selectTemporaryLinkEditListingData,
} from "../../../../../redux/reducers/v2/temporaryListingLink";
import {
  setTemporaryLinkEditListingData,
  setTemporaryLinkInvalidFormFields,
} from "../../../../../redux/actions/v2/temporaryLinkListing";

export const TemporaryLinkListingDetailsRow = () => {
  const dispatch = useDispatch();
  const states = useSelector(selectStates);
  const listingData = useSelector(selectTemporaryLinkEditListingData);
  const invalidFormFields = useSelector(selectTemporaryLinkInvalidFormFields);

  const onChange = useCallback(
    (event) => {
      const name = event.target.name;
      let value = event.target.value;

      const isMapRelated = mapRelatedFields.includes(name);
      let location = {};
      if (isMapRelated)
        location = {
          latitude: "",
          longitude: "",
        };

      if (name === "city") value = capitalizeFirstLetter(value);
      const data = { ...location, [name]: value };
      dispatch(setTemporaryLinkEditListingData({ data }));
      const invalidFields = { ...invalidFormFields, [name]: "" };
      dispatch(setTemporaryLinkInvalidFormFields({ data: invalidFields }));
    },
    [invalidFormFields],
  );

  return (
    <ListingDetails
      onChange={onChange}
      states={states || []}
      listingData={listingData || {}}
      invalidFormFields={invalidFormFields || {}}
    />
  );
};
