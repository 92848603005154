import React, { Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import { BrowserTracing, Replay } from "@sentry/react";

import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import App from "./App";
import Layout from "./utility/context/Layout";

import packageJson from "../package.json";

import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/plugins/extensions/toastr.scss";
import "./index.scss";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { ErrorBoundary } from "./components/fts-components-v2/ErrorBoundary";
import logger from "./utility/logger";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const IS_PROD = process.env.REACT_APP_ENVIRONMENT === "production";
const IS_SANDBOX = process.env.REACT_APP_ENVIRONMENT === "sandbox";
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

Sentry.init({
  release: `${packageJson.version}`,
  dsn: IS_PROD || IS_SANDBOX ? SENTRY_DSN : "",
  tracesSampleRate: 0.7,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Replay(), new BrowserTracing()],
  environment: ENVIRONMENT,
});

logger.info("VERSION: ", packageJson.version);
render(
  <Provider store={store}>
    <ErrorBoundary>
      <Suspense fallback={<FullPageLoader />}>
        <Layout>
          <App />
        </Layout>
        <ToastContainer />
      </Suspense>
    </ErrorBoundary>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
