import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { history } from "../../../../history";
import { useDispatch, useSelector } from "react-redux";
import { TemporaryLinkPages } from "../../../constants/pages";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ButtonsFooter } from "@find-truck-service/ui/src/components/react/ButtonsFooter";
import { RejectListingProofModal } from "@find-truck-service/ui/src/components/react/Modals/RejectListingProofModal";
import { selectTemporaryLinkListingPreviewRenewalTimestampData } from "../../../../redux/reducers/v2/temporaryListingLink";
import {
  setTemporaryLinkActiveBanner,
  temporaryLinkApproveProofRequest,
  temporaryLinkRejectProofRequest,
} from "../../../../redux/actions/v2/temporaryLinkListing";
import {
  bannerTitles,
  bannerTypes,
  bannerTypesIds,
} from "../../../../constants/temporaryLinkBanners";
import { getDateFromTimestamp } from "../../../../utility/getDateFromTimestamp";

export const ProofFooterButtons = () => {
  const dispatch = useDispatch();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const renewalTimestamp = useSelector(
    selectTemporaryLinkListingPreviewRenewalTimestampData,
  );

  const renewalTimestampDate = getDateFromTimestamp(renewalTimestamp);

  const onClose = useCallback(() => setShowRejectModal(false), []);

  const onReject = useCallback(() => setShowRejectModal(true), []);

  const onSubmit = useCallback((data) => {
    setShowRejectModal(false);
    const onSuccess = () => {
      toast.success("Listing proof rejected!");
      dispatch(
        setTemporaryLinkActiveBanner({
          data: {
            type: bannerTypes[bannerTypesIds.LISTING_LIVE_WAITING_PROOF],
            title: bannerTitles[bannerTypesIds.LISTING_LIVE_WAITING_PROOF],
          },
        }),
      );
      history.replace(TemporaryLinkPages.LISTING_PREVIEW);
    };
    dispatch(temporaryLinkRejectProofRequest({ data, onSuccess }));
  }, []);

  const onApprove = useCallback(() => {
    const onSuccess = () => {
      toast.success("Listing proof approved!");
      dispatch(
        setTemporaryLinkActiveBanner({
          data: {
            type: bannerTypes[bannerTypesIds.PAID_LISTING_LIVE],
            title: `${bannerTitles[bannerTypesIds.PAID_LISTING_LIVE]}${renewalTimestampDate}.`,
          },
        }),
      );
      history.replace(TemporaryLinkPages.LISTING_PREVIEW);
    };
    dispatch(temporaryLinkApproveProofRequest({ onSuccess }));
  }, []);

  return (
    <>
      <ButtonsFooter
        outlinedButtonProps={{
          label: "Reject",
          iconName: IconNames.Close,
          color: ButtonColorsValue.error,
          onClick: onReject,
        }}
        containedButtonProps={{
          label: "Approve",
          iconName: IconNames.Check,
          onClick: onApprove,
        }}
      />
      <RejectListingProofModal
        onClose={onClose}
        onSubmit={onSubmit}
        isOpen={showRejectModal}
      />
    </>
  );
};
