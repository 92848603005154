import React from "react";
import { history } from "../../../../../history";
import { useDispatch, useSelector } from "react-redux";
import { listingDetailsRowValidator } from "../../validators/listingDetailsRowValidator";
import { listingContactRowValidator } from "../../validators/listingContactRowValidator";
import { ListingInfoMdFooterComponent } from "../../components/ListingInfoMdFooterComponent";
import { listingSearchCategoriesRowValidator } from "../../validators/listingSearchCategoriesRowValidator";
import {
  selectTemporaryLinkContinueAssetsButtonIsDisabled,
  selectTemporaryLinkEditListingData,
} from "../../../../../redux/reducers/v2/temporaryListingLink";
import {
  setTemporaryLinkInvalidFormFields,
  temporaryLinkUpdateListingRequest,
} from "../../../../../redux/actions/v2/temporaryLinkListing";

export const TemporaryLinkEditListingInfoMdFooter = () => {
  const dispatch = useDispatch();
  const listingData = useSelector(selectTemporaryLinkEditListingData);
  const continueIsDisabled = useSelector(
    selectTemporaryLinkContinueAssetsButtonIsDisabled,
  );

  function goBack() {
    history.goBack();
  }

  async function onSaveChanges() {
    try {
      dispatch(setTemporaryLinkInvalidFormFields({ data: {} }));
      await listingDetailsRowValidator.validate(listingData);
      await listingContactRowValidator.validate(listingData);
      await listingSearchCategoriesRowValidator.validate(listingData);
      dispatch(temporaryLinkUpdateListingRequest({ onSuccess: goBack }));
    } catch (e) {
      if (e.name !== "ValidationError") return;
      const data = { [e.path]: e.errors[0] };
      dispatch(setTemporaryLinkInvalidFormFields({ data }));
    }
  }

  const primaryButtonProps = {
    label: "Save Changes",
    disabled: continueIsDisabled,
    onClick: onSaveChanges,
  };

  return (
    <ListingInfoMdFooterComponent
      primaryButtonProps={primaryButtonProps}
      onCancel={goBack}
    />
  );
};
