import React from "react";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPasswordRequest,
  setResetPasswordData,
} from "../../../redux/actions/v2/resetPassword";
import { isObject } from "lodash";
import {
  selectResetPasswordData,
  selectResetPasswordInvalidFormFields,
  selectResetPasswordIsLoading,
} from "../../../redux/reducers/v2/resetPassword";
import { requestPasswordResetValidator } from "./validators/index";
import { setResetPasswordInvalidFormFields } from "../../../redux/actions/v2/resetPassword";
import { toast } from "react-toastify";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { history } from "../../../history";
import { HeaderWrapper } from "../HeaderWrapper";
import logger from "../../../utility/logger";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const resetPasswordData = useSelector(selectResetPasswordData);
  const invalidFormFields = useSelector(selectResetPasswordInvalidFormFields);
  const isLoading = useSelector(selectResetPasswordIsLoading);
  const onChange = (event) => {
    logger.info([event.target.name], event.target.value);
    dispatch(
      setResetPasswordData({
        data: { [event.target.name]: event.target.value },
      }),
    );
  };

  const onResetPasswordSuccess = () => {
    toast.success(
      `Email with reset password instructions sent to ${resetPasswordData.email}`,
    );
  };

  const onResetPasswordFailure = (e) => {
    const message = e.response
      ? e.response.data.message
      : "Something went wrong!";

    toast.error(message);
  };

  const handleSendResetPassword = async () => {
    try {
      await requestPasswordResetValidator.validate(resetPasswordData || {});

      dispatch(
        setResetPasswordInvalidFormFields({
          data: null,
        }),
      );
      dispatch(
        resetPasswordRequest({
          data: {
            email: resetPasswordData.email,
          },
          onSuccess: onResetPasswordSuccess,
          onError: onResetPasswordFailure,
        }),
      );
    } catch (e) {
      logger.error(e);
      if (e.name === "ValidationError") {
        dispatch(
          setResetPasswordInvalidFormFields({
            data: {
              [e.path]: e.errors[0],
            },
          }),
        );
      }
    }
  };

  return (
    <FtsContainer>
      {isLoading && <FullPageLoader />}
      <HeaderWrapper />
      <FtsRow mt={{ lg: "4xl", md: "4xl", sm: "4xl", xs: "2xl" }}>
        <FtsColumn
          size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
          offset={{ xs: 0, sm: 0, md: 0, lg: 3 }}
          columnGap={"2xl"}
        >
          <FtsWrapper
            p={{
              xs: "2xl",
              md: "4xl",
              lg: "4xl",
            }}
            bg={ColorsValue["greyscale-0"]}
            boxShadow="sm"
            borderRadius="2xs"
          >
            <FtsWrapper
              display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}
              align="center"
              mb={"sm"}
              p={"xs"}
              cursor={"pointer"}
            >
              <FtsIcons iconName={IconNames.ChevronLeft} />
              <Span
                variant={VariantsValues["text-3xs"]}
                color={ColorsValue["greyscale-700"]}
                weight={WeightSize.regular}
                pl={"2xs"}
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                Back
              </Span>
            </FtsWrapper>
            <FtsColumn
              size={{ xs: 12, md: 12, lg: 12 }}
              columnGap={"2xl"}
              pb={"sm"}
            >
              <Span
                variant={VariantsValues["text-md"]}
                color={ColorsValue["greyscale-800"]}
                weight={WeightSize.bold}
              >
                Forgot password
              </Span>
            </FtsColumn>
            <FtsColumn
              size={{ xs: 12, md: 12, lg: 12 }}
              columnGap={"2xl"}
              pb={{ xs: "2xl", md: "3xl", lg: "4xl" }}
            >
              <Span
                variant={VariantsValues["text-2xs"]}
                color={ColorsValue["greyscale-800"]}
                weight={WeightSize.regular}
              >
                Please enter the email you registered to receive password reset
                instructions.
              </Span>
            </FtsColumn>

            <FtsRow columnGap={"lg"} mb={{ xs: "2xl", md: "3xl", lg: "4xl" }}>
              <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                <Input
                  name={"email"}
                  required
                  value={resetPasswordData?.email}
                  onChange={onChange}
                  label={"Email"}
                  variant={InputVariants.outlined}
                  size={InputSizes.md}
                  errorMessage={
                    isObject(invalidFormFields) && invalidFormFields["email"]
                  }
                />
              </FtsColumn>
            </FtsRow>

            <FtsWrapper
              display={{ xs: "none", sm: "grid", md: "grid", lg: "grid" }}
            >
              <FtsRow columnGap={"lg"}>
                <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                  <Button
                    onClick={() => handleSendResetPassword()}
                    size={ButtonSizes.md}
                    color={ButtonColorsValue.accent}
                    type={ButtonTypes.contained}
                    label={"Reset Password"}
                    width={"100%"}
                  />
                </FtsColumn>
              </FtsRow>
            </FtsWrapper>
          </FtsWrapper>
          <FtsWrapper
            display={{ xs: "grid", sm: "none", md: "none", lg: "none" }}
          >
            <FtsRow columnGap={"lg"} p={"xl"}>
              <FtsColumn size={{ xs: 12, md: 12, lg: 12 }}>
                <Button
                  onClick={() => handleSendResetPassword()}
                  size={ButtonSizes.md}
                  color={ButtonColorsValue.accent}
                  type={ButtonTypes.contained}
                  label={"Reset Password"}
                  width={"100%"}
                />
              </FtsColumn>
            </FtsRow>
          </FtsWrapper>
        </FtsColumn>
      </FtsRow>
    </FtsContainer>
  );
};

export default ResetPassword;
