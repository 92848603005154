import styled from "styled-components";
import { TextStyle } from "@find-truck-service/ui/src/FtsText";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { StrokesValues } from "@find-truck-service/types/ui/stroke";
import { defaultProps, defaultStyleProps } from "@find-truck-service/ui/src/default";
import { TextareaSizes, TextareaStatuses } from "@find-truck-service/types/ui/textarea";
import { LineHeightVariantsValues, VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
const textareaProps = ["invalid", "size"];
const textareaAndDefaultProps = [...textareaProps, ...defaultProps];
const spacingStyle = ({ size }) => `padding: ${Spacing[size]}; `;
const outlineColors = {
    [TextareaStatuses.default]: ColorsValue["greyscale-100"],
    [TextareaStatuses.hover]: ColorsValue["blue-accent-400"],
    [TextareaStatuses.focus]: ColorsValue["blue-accent-300"],
};
const outlineInvalidColors = {
    [TextareaStatuses.default]: ColorsValue["red-error-300"],
    [TextareaStatuses.hover]: ColorsValue["red-error-700"],
    [TextareaStatuses.focus]: ColorsValue["red-error-300"],
};
const outlineStyle = () => `
    outline: ${StrokesValues.xs} solid ${outlineColors[TextareaStatuses.default]};
    &:hover { outline: ${StrokesValues.xs} solid ${outlineColors[TextareaStatuses.hover]}; }
    &:focus, &:focus-visible { outline: ${StrokesValues.sm} solid ${outlineColors[TextareaStatuses.focus]}; }
  `;
const invalidOutlineStyle = () => `
    outline: ${StrokesValues.xs} solid ${outlineInvalidColors[TextareaStatuses.default]};
    &:hover { outline: ${StrokesValues.xs} solid ${outlineInvalidColors[TextareaStatuses.hover]}; }
    &:focus, &:focus-visible { outline: ${StrokesValues.sm} solid ${outlineInvalidColors[TextareaStatuses.focus]}; }
  `;
const textVariantBySize = {
    [TextareaSizes.sm]: VariantsValues["text-3xs"],
    [TextareaSizes.md]: VariantsValues["text-2xs"],
};
const disabledStyle = () => `
  outline: ${Spacing["4xs"]} solid ${ColorsValue["greyscale-50"]};
  color: ${ColorsValue["greyscale-300"]};
  pointer-events: none;
  cursor: default;
`;
const initialTextareaStyles = `
    flex: 1;
    border: none;
    cursor: text;
    resize: none;
    border-radius: ${Spacing["2xs"]};
    background-color: ${ColorsValue["greyscale-0"]} !important;
`;
const textareaOutlineStyle = ({ invalid }) => {
    return invalid ? invalidOutlineStyle() : outlineStyle();
};
const textStyles = ({ size, disabled }) => `
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected,
    &:-webkit-autofill:active {
      ${TextStyle({
    weight: WeightSize.regular,
    variant: textVariantBySize[size],
    lineHeightVariant: LineHeightVariantsValues.relaxed,
})};
      color: ${ColorsValue["greyscale-800"]} !important;
      transition: background-color 5000s ease-in-out 0s;
    }

    ${TextStyle({
    weight: WeightSize.regular,
    variant: textVariantBySize[size],
    color: ColorsValue["greyscale-800"],
    lineHeightVariant: LineHeightVariantsValues.relaxed,
})};

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder,
    &:-ms-input-placeholder {
      color: ${ColorsValue[disabled ? "greyscale-300" : "greyscale-500"]};
      opacity: 1;
    }
`;
export const FTSTextarea = styled.textarea.withConfig({
    shouldForwardProp: (prop) => !textareaAndDefaultProps.includes(prop),
}) `
  ${defaultStyleProps}
  ${initialTextareaStyles}
  ${textStyles}
  ${spacingStyle}
  ${textareaOutlineStyle}
  ${({ disabled }) => (disabled ? disabledStyle : "")}
`;
