import { createSelector } from "reselect";

export const GET_SINGLE_INVOICE_REQUEST = "INVOICES/GET_SINGLE_INVOICE_REQUEST";
export const GET_SINGLE_INVOICE_SUCCESS = "INVOICES/GET_SINGLE_INVOICE_SUCCESS";
export const GET_SINGLE_INVOICE_FAILED = "INVOICES/GET_SINGLE_INVOICE_FAILED";

export const GET_UPGRADE_LISTING_INVOICE_REQUEST =
  "INVOICES/GET_UPGRADE_LISTING_INVOICE_REQUEST";
export const GET_UPGRADE_LISTING_INVOICE_SUCCESS =
  "INVOICES/GET_UPGRADE_LISTING_INVOICE_SUCCESS";
export const GET_UPGRADE_LISTING_INVOICE_FAILED =
  "INVOICES/GET_UPGRADE_LISTING_INVOICE_FAILED";

const initialState = {
  isLoading: false,
  error: null,
  invoiceData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SINGLE_INVOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_SINGLE_INVOICE_SUCCESS:
    case GET_UPGRADE_LISTING_INVOICE_SUCCESS:
      return {
        ...state,
        error: null,
        invoiceData: action.payload.data,
        isLoading: false,
      };
    case GET_SINGLE_INVOICE_FAILED:
    case GET_UPGRADE_LISTING_INVOICE_FAILED:
      return {
        ...state,
        error: action.payload.data,
        invoiceData: null,
        isLoading: false,
      };

    default:
      return state;
  }
}

const selectInvoicesState = (state) => state.invoicesState;

export const selectCreateListingInvoice = createSelector(
  selectInvoicesState,
  (invoicesState) => {
    return invoicesState.invoiceData;
  },
);
export const selectCreateListingIsLoading = createSelector(
  selectInvoicesState,
  (invoicesState) => {
    return invoicesState.isLoading;
  },
);
export const selectCreateListingInvoiceError = createSelector(
  selectInvoicesState,
  (invoicesState) => {
    return invoicesState.error;
  },
);
