import { call, takeLatest, put } from "redux-saga/effects";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";
import { RESET_PASSWORD_REQUEST } from "../../reducers/v2/resetPassword";
import {
  resetPasswordFailed,
  resetPasswordSuccess,
} from "../../actions/v2/resetPassword";

function* resetPassword(action) {
  const { data, onSuccess, onError } = action.payload;

  try {
    const response = yield call(
      FTSAxiosTemporaryLink.post,
      "/auth/send-reset-password-email",
      data,
    );
    onSuccess(response);
    yield put(resetPasswordSuccess());
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    yield put(resetPasswordFailed({ data: { message, inlineMessage } }));
    onError(e);
  }
}

export default function* resetPasswordWatcher() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
}
