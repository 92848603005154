import React from "react";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { Span } from "../../../FtsText";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
export const PasswordValidator = (props) => {
    const validationRules = [
        { isValid: props.password.length >= 7, message: "Minimum 7 digits" },
        { isValid: /\d/.test(props.password), message: "One number" },
        { isValid: /[!@#$%^&*()_+=\-{}|":;'?/.>,<]/.test(props.password), message: "One special (!@#$%&?)" },
        { isValid: /(?=.*[a-z])(?=.*[A-Z])/.test(props.password), message: "One upper/lower case letter" },
    ];
    return (React.createElement(FtsWrapper, { display: "flex", gap: "lg", direction: "column" },
        React.createElement(Span, { variant: VariantsValues["text-2xs"], weight: WeightSize.medium, color: ColorsValue["greyscale-800"] }, "Password requirements:"),
        React.createElement(FtsWrapper, { display: "flex", direction: "column", gap: "sm" }, validationRules.map((validationRule) => (React.createElement(FtsWrapper, { display: "flex", gap: "sm", align: "center" },
            React.createElement(FtsIcons, { svgProps: { width: "20px", height: "20px" }, iconName: validationRule.isValid ? IconNames.PasswordCheckPassed : IconNames.PasswordCheckError }),
            React.createElement(Span, { textDecoration: validationRule.isValid ? "line-through" : "none", color: ColorsValue[validationRule.isValid ? "greyscale-300" : "red-error-500"], variant: VariantsValues["text-3xs"] }, validationRule.message)))))));
};
