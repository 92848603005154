import React, { Component } from "react";
import { Label } from "reactstrap";
import { connect } from "react-redux";
import { ErrorMessage } from "formik";
import { components } from "react-select";

import FormikInputValue from "../formik/formik-input-value";

import {
  pickerStyles,
  controlStyles,
  searchPickerStyles,
} from "../picker-style";
import Select from "../Select";

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== components.Placeholder ? child : null,
      )}
    </components.ValueContainer>
  );
};

class StatesPicker extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.isDisabled !== this.props.isDisabled ||
      (!!this.props.meta &&
        nextProps.meta.touched !== this.props.meta.touched) ||
      (!!this.props.meta && nextProps.meta.error !== this.props.meta.error) ||
      nextProps.value !== this.props.value ||
      nextProps.states.length !== this.props.states.length
    );
  }

  render() {
    const {
      onStateChange,
      label,
      isDisabled,
      placeholder,
      value,
      meta,
      onBlur,
      states,
      name,
      isSearch,
      menuPlacement = "auto",
      customBorderColor,
    } = this.props;

    let stateOptions = states;

    if (isSearch) {
      const hasAll = stateOptions.find((itm) => itm.label === "All");
      stateOptions = hasAll
        ? stateOptions
        : [{ label: "All", value: "" }, ...stateOptions];
    }

    const searchPickerStyle = {
      ...searchPickerStyles,
    };

    const pickerStyle = {
      ...pickerStyles,
      control: (styles, state) =>
        controlStyles(styles, state, {}, { meta }, customBorderColor),
    };

    let isLoading = false;

    if (!stateOptions?.length) {
      isLoading = true;
    }

    const defaultValue =
      meta && meta.initialValue && stateOptions.length
        ? stateOptions.find((itm) => itm.value === meta.initialValue)
        : null;

    let valueInfo =
      typeof value !== "object"
        ? typeof value === "string" &&
          typeof defaultValue === "object" &&
          value !== defaultValue?.value
          ? stateOptions.find((itm) => itm.value === value)
          : defaultValue ?? stateOptions.find((itm) => itm.value === value)
        : value;

    const components = isSearch
      ? { ValueContainer: CustomValueContainer }
      : null;

    return (
      <>
        {!!label && (
          <Label className={isDisabled ? "disabled" : ""} for="cat-status">
            {this.props.required && !isDisabled ? `${label} *` : label}
          </Label>
        )}

        {isDisabled ? (
          <FormikInputValue>{defaultValue?.label || "-"}</FormikInputValue>
        ) : (
          <Select
            isClearable
            options={stateOptions}
            onChange={onStateChange}
            styles={isSearch ? searchPickerStyle : pickerStyle}
            value={valueInfo}
            menuPlacement={menuPlacement}
            {...{
              isDisabled,
              placeholder,
              defaultValue,
              onBlur,
              isLoading,
              name,
              label,
              components,
            }}
          />
        )}
        {!!meta && (
          <ErrorMessage
            name={name}
            render={(msg) => (
              <div className="formik-validation-error">
                {typeof msg !== "object" ? msg : ""}
              </div>
            )}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: state.essentialLists.states,
  };
};

export default connect(mapStateToProps)(StatesPicker);
