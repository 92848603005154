import React from "react";
import { useSelector } from "react-redux";
import {
  selectListingData,
  selectListingWorkingHours,
} from "../../../../redux/reducers/v2/listing";
import { setListingData } from "../../../../redux/actions/v2/listing";
import { ListingWorkingHoursFields } from "./ListingWorkingHoursFields";

export const ListingWorkingHoursRow = () => {
  const listingData = useSelector(selectListingData);
  const listingWorkingHours = useSelector(selectListingWorkingHours);

  return (
    <ListingWorkingHoursFields
      listingData={listingData}
      setListingData={setListingData}
      listingWorkingHours={listingWorkingHours}
    />
  );
};
