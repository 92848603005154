import React from "react";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";

import TextDivider from "@find-truck-service/ui/src/components/react/TextDivider";
import { PaymentMethods } from "../PaymentMethods/PaymentMethods";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { PayPalButton } from "@find-truck-service/ui/src/components/react/PayPalButton";
import { useDispatch, useSelector } from "react-redux";
import { selectCreateListingInvoice } from "../../../../../redux/reducers/v2/invoices";

import { payWithPaypalRequest } from "../../../../../redux/actions/v2/payments";
import { clearAllSearchParams } from "../../../../../utility/clear-search-params";
import { history } from "../../../../../history";

const PaymentOverviewPaymentMethod = () => {
  const listingInvoice = useSelector(selectCreateListingInvoice);
  const dispatch = useDispatch();

  const handlePayWithPaypal = () => {
    clearAllSearchParams(history);
    const redirectUri = window.location.href;

    const payload = {
      data: {
        redirectUri,
        invoicesIds: [listingInvoice.id],
      },
    };

    dispatch(payWithPaypalRequest(payload));
  };
  return (
    <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
      <FtsWrapper mt={"lg"}>
        <PaymentMethods />
      </FtsWrapper>

      <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }} mt="xl">
        <TextDivider text="or" />
      </FtsColumn>

      <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }} mt="xl">
        <PayPalButton
          onClick={handlePayWithPaypal}
          width="100%"
          label={"Pay with PayPal"}
        />
      </FtsColumn>
    </FtsColumn>
  );
};

export default PaymentOverviewPaymentMethod;
