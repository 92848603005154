export const HeaderLinksNonLogged = [
    {
        title: "Search",
        href: "/",
    },
    {
        title: "Vendor",
        href: "/vendor",
    },
    {
        title: "Fleet",
        href: "/fleet",
    },
    {
        title: "Driver",
        href: "/driver",
    },
    {
        title: "Financing",
        href: "/financing",
    },
];
