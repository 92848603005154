import React from "react";

import { Button } from "reactstrap";
import { removeAllCookies } from "../../../cookies";
import Icon from "../../../components/fts/Icons";
import "../../../assets/scss/pages/account-deactivated.scss";
import { history } from "../../../history";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";

class AccountDeactivated extends React.Component {
  handleRedirectToWebsite = () => {
    removeAllCookies();
    history.push("/");
  };

  componentWillUnmount = () => {
    this.handleRedirectToWebsite();
  };

  render() {
    return (
      <div className="fts-account-deactivated">
        <HeaderWrapper />
        <div className="fts-account-deactivated-message">
          <div className="fts-account-deactivated-message-box">
            <div className="fts-account-deactivated-icon-wrapper">
              <Icon name="AlertTriangle" size={40} />
            </div>
            <h3>This email has been used on Find Truck Service before!</h3>
            <p>For further instructions, please check your email.</p>
            <Button
              tag="a"
              onClick={() => this.handleRedirectToWebsite()}
              color="primary"
              size="lg"
              className="mt-2"
            >
              Back to Website
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default AccountDeactivated;
