import React, { useCallback, useEffect, useState } from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { ListingItem } from "@find-truck-service/ui/src/components/react/ListingItem";
import { ListingItemsTypeValues } from "@find-truck-service/types/ui/listingItemComponent";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { TemporaryLinkPageTitle } from "../../../components/fts-components-v2/TemporaryLink/TemporaryLinkPageTitle/TemporaryLinkPageTitle";
import { TemporaryLinkContentContainer } from "../../../components/fts-components-v2/TemporaryLink/TemporaryLinkContentContainer/TemporaryLinkContentContainer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectTemporaryLinkCompetitorsData,
  selectTemporaryLinkIsLoading,
  selectTemporaryLinkListingActiveBanner,
  selectTemporaryLinkListingPreviewData,
  selectTemporaryLinkListingSelectedPrice,
  selectTemporaryListingLinkInvoices,
} from "../../../redux/reducers/v2/temporaryListingLink";
import { Banner } from "@find-truck-service/ui/src/components/react/Banners";
import { INVOICES_TYPES_ENUM } from "@find-truck-service/types/constants/invoices";
import {
  setTemporaryLinkActiveBanner,
  setTemporaryLinkSelectedListingPrice,
} from "../../../redux/actions/v2/temporaryLinkListing";
import {
  bannerTitles,
  bannerTypes,
  bannerTypesIds,
} from "../../../constants/temporaryLinkBanners";
import { LISTING_STATUSES } from "../../../constants";

import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { RelistListingRelistedFooter } from "./components/RelistListingRelistedFooter";
import { RelistListingExpiredFooter } from "./components/RelistListingExpiredFooter";
import { SelectedUpgradeListingFooterButtons } from "../temporary-link-preview-listing/components/SelectedUpgradeListingFooterButtons";
import { TemporaryLinkUpgradeListingOptions } from "../../../components/fts-components-v2/TemporaryLinkUpgradeListingOptions";
import { ADS_PRICES_ENUM } from "@find-truck-service/types/constants/adsPrices";

const relistListingStates = {
  RELISTED: "relisted",
  EXPIRED: "expired",
};

export const TemporaryLinkRelist = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectTemporaryLinkIsLoading);
  const listingData = useSelector(selectTemporaryLinkListingPreviewData);
  const activeBanner = useSelector(selectTemporaryLinkListingActiveBanner);
  const invoices = useSelector(selectTemporaryListingLinkInvoices);
  const [activeListingState, setActiveListingState] = useState(
    relistListingStates.EXPIRED,
  );
  const upgradeListingOptions = useSelector(selectTemporaryLinkCompetitorsData);

  const selectedUpgradeListingPrice = useSelector(
    selectTemporaryLinkListingSelectedPrice,
  );

  useEffect(() => {
    if (
      !invoices?.length ||
      invoices[0].type !== INVOICES_TYPES_ENUM.RENEWAL ||
      !listingData
    )
      return;

    handleActiveBanner(invoices, listingData);
  }, [invoices, listingData]);

  useEffect(() => {
    if (!upgradeListingOptions?.length || !listingData) return;

    if (listingData.priceId === ADS_PRICES_ENUM.FREE) {
      dispatch(
        setTemporaryLinkSelectedListingPrice(listingData?.previousPrice),
      );
    }
  }, [upgradeListingOptions, listingData]);

  const handleActiveBanner = (invoices, listingData) => {
    let bannerType;
    let bannerTitle;

    const renewalDate = moment(invoices[0].dueAt);
    const thirtyDaysFromNow = moment().add(30, "days");

    const isListingActive = listingData?.status === LISTING_STATUSES.ACTIVE;

    const isExpired = renewalDate.isBefore(
      moment().subtract(1, "days").startOf("day"),
    );
    const isRenewed = moment
      .unix(listingData?.renewalTimestamp)
      .isAfter(thirtyDaysFromNow);

    if (isExpired) {
      setActiveListingState(relistListingStates.EXPIRED);
      bannerType = bannerTypes[bannerTypesIds.LISTING_EXPIRED];
      bannerTitle = bannerTitles[bannerTypesIds.LISTING_EXPIRED];
    }

    if (isRenewed && isListingActive) {
      setActiveListingState(relistListingStates.RELISTED);
      bannerType = bannerTypes[bannerTypesIds.LISTING_RENEWED];
      bannerTitle =
        bannerTitles[bannerTypesIds.LISTING_RENEWED] +
        moment.unix(listingData?.renewalTimestamp).format("MMM D, YYYY");
    }

    dispatch(
      setTemporaryLinkActiveBanner({
        data: {
          type: bannerType,
          title: bannerTitle,
        },
      }),
    );
  };

  const handleChangeListingSelection = useCallback(
    (newListingPrice) => {
      if (
        selectedUpgradeListingPrice &&
        newListingPrice.id === selectedUpgradeListingPrice.id
      ) {
        dispatch(setTemporaryLinkSelectedListingPrice(null));
        return;
      }
      dispatch(setTemporaryLinkSelectedListingPrice(newListingPrice));
    },
    [selectedUpgradeListingPrice],
  );

  if (!listingData) return <FullPageLoader />;

  return (
    <>
      {isLoading && <FullPageLoader />}
      <TemporaryLinkContentContainer>
        <Banner title={activeBanner?.title} type={activeBanner?.type} />
        <FtsWrapper flex={1} overflow={"auto"}>
          <FtsContainer mb={"2xl"}>
            <TemporaryLinkPageTitle title="Your listing" />
            <ListingItem
              listingData={{
                ...listingData,
                price:
                  activeListingState === relistListingStates.RELISTED
                    ? listingData.price
                    : listingData.previousPrice,
              }}
              type={ListingItemsTypeValues.LIVE}
            />
            <TemporaryLinkUpgradeListingOptions
              selectedUpgradeListingPrice={selectedUpgradeListingPrice}
              handleChangeListingSelection={handleChangeListingSelection}
              autoOpen
            />
          </FtsContainer>
        </FtsWrapper>
        {selectedUpgradeListingPrice &&
        selectedUpgradeListingPrice !== listingData?.previousPrice ? (
          <SelectedUpgradeListingFooterButtons />
        ) : activeListingState === relistListingStates.RELISTED ? (
          <RelistListingRelistedFooter />
        ) : activeListingState === relistListingStates.EXPIRED ? (
          <RelistListingExpiredFooter />
        ) : (
          <EmptyComponent />
        )}
      </TemporaryLinkContentContainer>
    </>
  );
};
