import React, { memo } from "react";
import { TemporaryLinkDescriptionModalContent } from "./TemporaryLinkDescriptionModalContent";
import { FullSizeModal } from "@find-truck-service/ui/src/components/react/Modal/FullSizeModal";
import { ModalHeaderComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalHeaderComponent";
import { ModalFooterComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalFooterComponent";

export const TemporaryLinkDescriptionModal = ({
  showModal,
  setShowModal,
  onSaveDescription,
}) => {
  function onSave() {
    onSaveDescription ? onSaveDescription() : setShowModal(false);
  }

  function onClose() {
    setShowModal(false);
  }

  return (
    <FullSizeModal
      isOpen={showModal}
      modalHeaderComponent={() => (
        <ModalHeaderComponent onClose={onClose} title={"Add Description"} />
      )}
      modalContentComponent={() => <TemporaryLinkDescriptionModalContent />}
      modalFooterComponent={() => (
        <ModalFooterComponent successLabel={"Save"} onSuccess={onSave} />
      )}
    />
  );
};

export const MemoizedTemporaryLinkDescriptionModal = memo(
  TemporaryLinkDescriptionModal,
);
