import styled from "styled-components";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { device } from "@find-truck-service/types/ui/breakpoints";
import { Spacing } from "@find-truck-service/types/ui/spacing";

export const StepperWrapper = styled(FtsWrapper)`
  width: 100%;
  min-height: 136px;
  padding-top: ${Spacing["4xl"]};

  @media ${device.xs} {
    min-height: unset;
    padding: ${Spacing["2xl"]} ${Spacing["xl"]};
  }
`;
