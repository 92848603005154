import { put, call, takeLatest } from "redux-saga/effects";
import handleError from "../../../handlers/error";
import FTSV2Axios from "../../../axios/ftsv2.instance";
import {
  GET_ACTIVE_AND_FREE_LISTINGS_COUNT_REQUEST,
  GET_VENDOR_ADVERTISE_LISTINGS_REQUEST,
} from "redux/reducers/v2/vendor-advertise-listings";
import {
  getActiveAndFreeListingsCountFailed,
  getActiveAndFreeListingsCountSuccess,
  getVendorAdvertiseListingsFailed,
  getVendorAdvertiseListingsSuccess,
} from "redux/actions/v2/vendorAdvertiseListings";

function* getVendorAdvertiseListingsSaga() {
  try {
    const { data } = yield call(
      FTSV2Axios.get,
      "/ads/list/vendor-advertise-listings",
    );
    yield put(getVendorAdvertiseListingsSuccess({ data: data?.data }));
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while fetching listings";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    yield put(
      getVendorAdvertiseListingsFailed({ data: { message, inlineMessage } }),
    );
  }
}

function* getActiveAndFreeListingsCountSaga(action) {
  try {
    const { data } = yield call(FTSV2Axios.get, "/ads/count/active-and-free");
    const hasActiveOrFreeListings = !!data?.data?.listingsCount;
    yield put(
      getActiveAndFreeListingsCountSuccess({
        data: !hasActiveOrFreeListings,
      }),
    );

    if (typeof action.payload?.onSuccess === "function") {
      action.payload.onSuccess(hasActiveOrFreeListings);
    }
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "Error while fetching listings count";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    yield put(
      getActiveAndFreeListingsCountFailed({ data: { message, inlineMessage } }),
    );
  }
}

export default function* vendorAdvertiseListingsWatcher() {
  yield takeLatest(
    GET_VENDOR_ADVERTISE_LISTINGS_REQUEST,
    getVendorAdvertiseListingsSaga,
  );
  yield takeLatest(
    GET_ACTIVE_AND_FREE_LISTINGS_COUNT_REQUEST,
    getActiveAndFreeListingsCountSaga,
  );
}
