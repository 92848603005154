import { Pages, TemporaryLinkPages } from "../views/constants/pages";

const listingStatuses = {
  0: "Disabled",
  1: "Active",
  2: "Suspended",
  3: "Draft",
};

const subUsersRoles = [
  { label: "Admin", value: 2 },
  { label: "User", value: 3, forUserType: 3 },
  { label: "General", value: 3, forUserType: 1 },
];

const accountAccessRequestsStatuses = {
  pending: 1,
  granted: 2,
  rejected: 3,
};

const temporaryAccountId = 1;

const userStatuses = {
  ACTIVE: 1,
  DEACTIVATED: 3,
};

const repairRecommendations = {
  POSITIVE: 1,
  NEGATIVE: 2,
};

const invoiceTypes = {
  RENEWAL: "renewal",
  NEW: "new",
  UPGRADE: "upgrade",
};

const userRoles = {
  //main account roles
  SUPER_ADMIN: 1,
  // sub account roles
  ADMIN: 2,
  USER: 3,
};

export {
  listingStatuses,
  subUsersRoles,
  accountAccessRequestsStatuses,
  temporaryAccountId,
  userStatuses,
  repairRecommendations,
  invoiceTypes,
  userRoles,
};

export const TERMS_NAV_ITEMS = [
  {
    id: "client_footer_nav_privacy",
    title: "Privacy Policy",
    path: "/privacy-policy",
  },
  {
    id: "client_footer_nav_terms",
    title: "Terms of use",
    path: "/terms-and-conditions",
  },
  {
    id: "client_footer_nav_advertiser_terms",
    title: "Advertiser Terms",
    path: "/advertiser-terms",
  },
];

export const SHARE_PROVIDER_TYPES = {
  VERIFY_USER_EMAIL: "verify-user-email",
  FREE_LISTING_CREATED_EMAIL: "free-listing-created-email",
  BASIC_LISTING_CREATED_EMAIL: "basic-listing-created-email",
  BASIC_PLUS_LISTING_CREATED_EMAIL: "basic-plus-listing-created-email",
  STANDARD_LISTING_CREATED_EMAIL: "standard-listing-created-email",
  PREMIUM_LISTING_CREATED_EMAIL: "premium-listing-created-email",
  PAYMENT_SUCCESSFULL_EMAIL: "payment-successfull-email",
  LISTING_UNDER_REVIEW: "listing-under-review",
  ACCOUNT_CHANGED_TO_VENDOR: "account-changed-to-vendor",
  RESET_PASSWORD: "reset-password",
  ADMIN_SUB_USER_INVITATION: "admin-sub-user-invitation",
  GENERAL_SUB_USER_INVITATION: "general-sub-user-invitation",
  FLEET_ACCOUNT_CREATED: "fleet-account-created",
  COMPANY_DRIVER_ACCOUNT_CREATED: "company-driver-account-created",
  OWNER_OPERATOR_ACCOUNT_CREATED: "owner-operator-account-created",
  OTHER_ACCOUNT_CREATED: "other-account-created",
  VENDOR_ACCOUNT_CREATED: "vendor-account-created",
  PASSWORD_EXPIRED: "password-expired",
  ACCOUNT_DEACTIVATED: "account-deactivated",
  PROOF_LISTING: "proof-listing",
  DUE_FOR_RENEWAL: "due-for-renewal",
  LISTING_RENEWED: "listing-renewed",
  RENEWAL_SEVEN_DAYS: "renewal-seven-days",
  RENEWAL_DUE_TODAY: "renewal-due-today",
  RENEWAL_PAST_DUE: "renewal-past-due",
  LISTING_EXPIRED: "listing-expired",
  DRAFT_PROOF_LISTING: "draft-proof-listing",
  SEND_LISTING_DETAILS: "send-listing-details",
  PROOF_REMINDER: "proof-reminder",
  PROOF_AUTO_APPROVED: "proof-auto-approved",
  UPGRADE_PAYMENT: "upgrade-payment-successfull-email",
  //sms
  SHARE_LISTING_PROOF: "share_listing_proof",
};

export const SHARE_PROVIDER_REDIRECT_ROUTES = {
  [SHARE_PROVIDER_TYPES.FREE_LISTING_CREATED_EMAIL]:
    TemporaryLinkPages.DRAFT_LISTING_PREVIEW,
  [SHARE_PROVIDER_TYPES.BASIC_LISTING_CREATED_EMAIL]:
    TemporaryLinkPages.DRAFT_LISTING_PREVIEW,
  [SHARE_PROVIDER_TYPES.BASIC_PLUS_LISTING_CREATED_EMAIL]:
    TemporaryLinkPages.DRAFT_LISTING_PREVIEW,
  [SHARE_PROVIDER_TYPES.STANDARD_LISTING_CREATED_EMAIL]:
    TemporaryLinkPages.DRAFT_LISTING_PREVIEW,
  [SHARE_PROVIDER_TYPES.PREMIUM_LISTING_CREATED_EMAIL]:
    TemporaryLinkPages.DRAFT_LISTING_PREVIEW,
  [SHARE_PROVIDER_TYPES.RESET_PASSWORD]: Pages.RESET_PASSWORD,
  [SHARE_PROVIDER_TYPES.ACCOUNT_DEACTIVATED]: Pages.REACTIVATE_ACCOUNT,
  [SHARE_PROVIDER_TYPES.PAYMENT_SUCCESSFULL_EMAIL]:
    TemporaryLinkPages.LISTING_PREVIEW,
  [SHARE_PROVIDER_TYPES.ADMIN_SUB_USER_INVITATION]: Pages.FINISH_REGISTER,
  [SHARE_PROVIDER_TYPES.GENERAL_SUB_USER_INVITATION]: Pages.FINISH_REGISTER,
  [SHARE_PROVIDER_TYPES.LISTING_UNDER_REVIEW]:
    TemporaryLinkPages.FREE_LISTING_PREVIEW,
  [SHARE_PROVIDER_TYPES.PASSWORD_EXPIRED]: Pages.RESET_PASSWORD,
  [SHARE_PROVIDER_TYPES.PROOF_LISTING]: TemporaryLinkPages.PROOF_PREVIEW,
  [SHARE_PROVIDER_TYPES.DUE_FOR_RENEWAL]: TemporaryLinkPages.LISTING_RENEWAL,
  [SHARE_PROVIDER_TYPES.LISTING_RENEWED]: TemporaryLinkPages.LISTING_PREVIEW,
  [SHARE_PROVIDER_TYPES.LISTING_EXPIRED]: TemporaryLinkPages.LISTING_RENEWAL,
  [SHARE_PROVIDER_TYPES.RENEWAL_SEVEN_DAYS]: TemporaryLinkPages.LISTING_RENEWAL,
  [SHARE_PROVIDER_TYPES.RENEWAL_DUE_TODAY]: TemporaryLinkPages.LISTING_RENEWAL,
  [SHARE_PROVIDER_TYPES.RENEWAL_PAST_DUE]: TemporaryLinkPages.LISTING_RENEWAL,
  [SHARE_PROVIDER_TYPES.PROOF_LISTING]: TemporaryLinkPages.PROOF_PREVIEW,
  [SHARE_PROVIDER_TYPES.SHARE_LISTING_PROOF]: TemporaryLinkPages.PROOF_PREVIEW,
  [SHARE_PROVIDER_TYPES.DRAFT_PROOF_LISTING]:
    TemporaryLinkPages.DRAFT_PROOF_PREVIEW,
  [SHARE_PROVIDER_TYPES.SEND_LISTING_DETAILS]:
    TemporaryLinkPages.LISTING_PREVIEW,
  [SHARE_PROVIDER_TYPES.PROOF_REMINDER]: TemporaryLinkPages.PROOF_PREVIEW,
  [SHARE_PROVIDER_TYPES.PROOF_AUTO_APPROVED]: TemporaryLinkPages.PROOF_PREVIEW,
  [SHARE_PROVIDER_TYPES.UPGRADE_PAYMENT]: TemporaryLinkPages.LISTING_PREVIEW,
};

export const USER_STATUSES = {
  DISABLED: 0,
  ACTIVE: 1,
  DEACTIVATED: 3,
};

export const SESSION_STORAGE_KEYS = {
  SELECTED_PRICE_ID: "selected_price_id",
  TEMPORARY_TOKEN_USER_EMAIL: "temporary_token_user_email",
  TEMPORARY_TOKEN: "temporaryToken",
};

export const NAV_ITEMS = [
  {
    id: 1,
    title: "Company",
    items: [
      {
        id: "client_footer_nav_about",
        title: "About",
        path: "/about",
      },
      {
        id: "client_footer_nav_contact",
        title: "Contact",
        path: "/contact",
      },
      {
        id: "client_footer_nav_register",
        title: "Register",
        path: "/register",
      },
      {
        id: "client_footer_nav_financing",
        title: "Financing",
        path: "/financing",
      },
      {
        id: "client_footer_nav_login",
        title: "Login",
        path: "/dashboard/login",
      },
    ],
  },
  {
    id: 2,
    title: "More info",
    items: [
      {
        id: "client_footer_nav_fleet",
        title: "Fleet",
        path: "/fleet",
      },
      {
        id: "client_footer_nav_driver",
        title: "Driver",
        path: "/driver",
      },
      {
        id: "client_footer_nav_vendor",
        title: "Vendor",
        path: "/vendor",
      },
      {
        id: "client_footer_nav_widgets",
        title: "Tools",
        path: "/tools",
      },
    ],
  },
  {
    id: 3,
    title: "More info",
    items: [
      {
        id: "client_footer_nav_driver_financing",
        title: "Driver Financing",
        path: "/truck-repair-financing",
      },
      {
        id: "client_footer_nav_vendor_financing",
        title: "Vendor Financing",
        path: "/authorized-vendor",
      },
      {
        id: "client_footer_nav_search_widget",
        title: "Search Widget",
        path: "/tools_widget",
      },
      {
        id: "client_footer_nav_trucker_app",
        title: "Trucker App",
        path: "/trucker-app",
      },
      // {
      //   id: "client_footer_nav_api",
      //   title: "Api",
      //   path: "/api",
      // },
    ],
  },
];
