// import axios from "axios";
// import { toast } from "react-toastify";
import React from "react";
import FTSModal from "../fts-modal";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { Row } from "reactstrap";
import CloseButton from "../CloseButton";
import "../../../assets/scss/components/fts/modals/contact-us-confirmation-modal.scss";
import Icon from "../Icons";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

const ContactUsConfirmationModal = ({ isOpen, confirmModal }) => {
  const ContactFormFooter = () => {
    return (
      <Row>
        <Button
          label="OK"
          width="100%"
          size={ButtonSizes.md}
          onClick={confirmModal}
          type={ButtonTypes.contained}
          color={ButtonColorsValue.accent}
        />
      </Row>
    );
  };

  return (
    <FTSModal
      headerCTA={() => {
        return (
          <CloseButton
            onClick={confirmModal}
            className="fts-contact-us-confirmation-modal-header"
          />
        );
      }}
      title={"fts-contact-us-confirmation-modal"}
      isOpen={isOpen}
      modalClasses={"fts-contact-us-confirmation-modal"}
      centered={true}
      footerCTA={ContactFormFooter}
      style={{ maxWidth: 550 }}
    >
      <div className="invoice"></div>
      <div className="fts-contact-us-confirmation-modal-content">
        <div className="fts-contact-us-confirmation-modal-content-icon">
          <Icon name="CheckGreen" color="#57A14A" />
        </div>
        <div className="d-flex flex-column">
          <h2 className="mb-1">Thank you</h2>
          <span>
            Your form has been submitted and one of our colleagues will be in
            touch shortly.
          </span>
        </div>
      </div>
    </FTSModal>
  );
};
export default ContactUsConfirmationModal;
