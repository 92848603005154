import {
  GET_VENDOR_ADVERTISE_LISTINGS_REQUEST,
  GET_VENDOR_ADVERTISE_LISTINGS_SUCCESS,
  GET_VENDOR_ADVERTISE_LISTINGS_FAILED,
  GET_ACTIVE_AND_FREE_LISTINGS_COUNT_REQUEST,
  GET_ACTIVE_AND_FREE_LISTINGS_COUNT_SUCCESS,
  GET_ACTIVE_AND_FREE_LISTINGS_COUNT_FAILED,
} from "redux/reducers/v2/vendor-advertise-listings";

export const getVendorAdvertiseListingsRequest = (payload) => ({
  type: GET_VENDOR_ADVERTISE_LISTINGS_REQUEST,
  payload,
});

export const getVendorAdvertiseListingsSuccess = (payload) => ({
  type: GET_VENDOR_ADVERTISE_LISTINGS_SUCCESS,
  payload,
});

export const getVendorAdvertiseListingsFailed = (payload) => ({
  type: GET_VENDOR_ADVERTISE_LISTINGS_FAILED,
  payload,
});

export const getActiveAndFreeListingsCountRequest = (payload) => ({
  type: GET_ACTIVE_AND_FREE_LISTINGS_COUNT_REQUEST,
  payload,
});

export const getActiveAndFreeListingsCountSuccess = (payload) => ({
  type: GET_ACTIVE_AND_FREE_LISTINGS_COUNT_SUCCESS,
  payload,
});

export const getActiveAndFreeListingsCountFailed = (payload) => ({
  type: GET_ACTIVE_AND_FREE_LISTINGS_COUNT_FAILED,
  payload,
});
