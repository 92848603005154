import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createListingRequest } from "../../../../../redux/actions/v2/listing";
import { ListingInfoSmFooterComponent } from "../../components/ListingInfoSmFooterComponent";
import {
  selectIsLoadingListingData,
  selectListingAdsAssetsIsLoading,
} from "../../../../../redux/reducers/v2/listing";

export const ListingImagesMobileFooter = () => {
  const dispatch = useDispatch();
  const inProgress = useSelector(selectIsLoadingListingData);
  const isDisabled = useSelector(selectListingAdsAssetsIsLoading);

  function createListing() {
    dispatch(createListingRequest());
  }

  const primaryButtonProps = {
    label: "Continue",
    onClick: createListing,
    disabled: isDisabled || inProgress,
  };

  return (
    <ListingInfoSmFooterComponent primaryButtonProps={primaryButtonProps} />
  );
};
