import { createSelector } from "reselect";

export const SET_REACTIVATE_ACCOUNT_DATA =
  "REACTIVATE_ACCOUNT/SET_REACTIVATE_ACCOUNT_DATA";
export const REACTIVATE_ACCOUNT_REQUEST =
  "REACTIVATE_ACCOUNT/REACTIVATE_ACCOUNT_REQUEST";
export const REACTIVATE_ACCOUNT_REQUEST_FAILED =
  "REACTIVATE_ACCOUNT/REACTIVATE_ACCOUNT_REQUEST_FAILED";
export const REACTIVATE_ACCOUNT_REQUEST_SUCCESS =
  "REACTIVATE_ACCOUNT/REACTIVATE_ACCOUNT_REQUEST_SUCCESS";
export const SET_REACTIVATE_ACCOUNT_INVALID_FORM_FIELDS =
  "REACTIVATE_ACCOUNT/SET_REACTIVATE_ACCOUNT_INVALID_FORM_FIELDS";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  inlineMessage: null,
  invalidFormFields: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_REACTIVATE_ACCOUNT_DATA:
      return {
        ...state,
        invalidFormFields: null,
        data: { ...state.data, ...action.payload.data },
      };

    case SET_REACTIVATE_ACCOUNT_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: action.payload.data,
      };

    case REACTIVATE_ACCOUNT_REQUEST:
      return { ...state, isLoading: true };

    case REACTIVATE_ACCOUNT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data.message,
        inlineMessage: action.payload.data.inlineMessage,
      };

    case REACTIVATE_ACCOUNT_REQUEST_SUCCESS:
      return { ...initialState, isLoading: false };

    default:
      return state;
  }
}

const selectReactivateAccountState = (state) => state.reactivateAccountState;

export const selectReactivateAccountData = createSelector(
  selectReactivateAccountState,
  (reactivateAccountState) => reactivateAccountState.data,
);

export const selectReactivateAccountIsLoading = createSelector(
  selectReactivateAccountState,
  (reactivateAccountState) => reactivateAccountState.isLoading,
);

export const selectReactivateAccountError = createSelector(
  selectReactivateAccountState,
  (reactivateAccountState) => reactivateAccountState.error,
);

export const selectReactivateAccountInlineMessage = createSelector(
  selectReactivateAccountState,
  (reactivateAccountState) => reactivateAccountState.inlineMessage,
);

export const selectReactivateAccountInvalidFormFields = createSelector(
  selectReactivateAccountState,
  (reactivateAccountState) => reactivateAccountState.invalidFormFields,
);
