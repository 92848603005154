import React from "react";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { FtsDrawerLink } from "@find-truck-service/ui/src/FtsLink/FtsDrawerLink";
import { FtsDrawerLinksContainer } from "@find-truck-service/ui/src/FtsSideOut/FtsDrawerLinksContainer";
import { drawerLinksNonLogged } from "@find-truck-service/ui/src/components/react/Drawer/constants/drawerLinksNonLogged";
export const DrawerLinksNonLogged = ({ activeLink }) => {
    return (React.createElement(FtsDrawerLinksContainer, null, drawerLinksNonLogged.map(({ title, iconName, href }, index) => (React.createElement(FtsDrawerLink, { href: href, index: title, key: index, active: activeLink === href },
        React.createElement(FtsIcons, { iconName: iconName }),
        title)))));
};
