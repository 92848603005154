import React from "react";
import { FtsWrapper } from "../../../../FtsWrapper/FtsWrapper";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
const WarningActions = (props) => {
    return (React.createElement(FtsWrapper, { display: "flex", gap: "lg", direction: "column", width: "100%" },
        !!props.containedButton && props.containedButton.color && (React.createElement(Button, { onClick: props.containedButton?.onClick, size: ButtonSizes.md, type: ButtonTypes.contained, color: ButtonColorsValue[props.containedButton.color], label: props.containedButton?.label, width: {
                xs: "100%",
                sm: "auto",
                md: "auto",
                lg: "auto",
            } })),
        !!props.outlinedButton && props.outlinedButton.color && (React.createElement(Button, { onClick: props.outlinedButton?.onClick, size: ButtonSizes.md, type: ButtonTypes.outlined, color: ButtonColorsValue[props.outlinedButton.color], label: props.outlinedButton?.label, width: {
                xs: "100%",
                sm: "auto",
                md: "auto",
                lg: "auto",
            } })),
        !!props.textButton && props.textButton.color && (React.createElement(Button, { onClick: props.textButton?.onClick, size: ButtonSizes.md, type: ButtonTypes.text, label: props.textButton?.label, color: ButtonColorsValue[props.textButton.color], width: {
                xs: "100%",
                sm: "auto",
                md: "auto",
                lg: "auto",
            } }))));
};
export default WarningActions;
