import React from "react";
import { FtsContainer } from "../../../FtsContainer/FtsContainer";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { FtsRow } from "../../../FtsRow/FtsRow";
import { FtsColumn } from "../../../FtsColumn/FtsColumn";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span } from "../../../FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { IconWrapper } from "./styled/IconWrapper";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import WarningActions from "./components/WarningActions";
const Warning = (props) => {
    return (React.createElement(FtsContainer, { mt: { lg: "4xl", md: "4xl", sm: "4xl", xs: "0" } },
        React.createElement(FtsWrapper, null,
            React.createElement(FtsRow, null,
                React.createElement(FtsColumn, { size: { xs: 12, sm: 12, md: 12, lg: 6 }, offset: { xs: 0, sm: 0, md: 0, lg: 3 } },
                    React.createElement(FtsWrapper, { display: "flex", direction: "column", gap: "2xl", bg: ColorsValue["greyscale-0"], borderRadius: "2xs", boxShadow: "sm", py: { xs: "xl", sm: "4xl", md: "4xl", lg: "4xl" }, px: { xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" } },
                        React.createElement(FtsWrapper, { display: "flex", align: "center", gap: "2xl" },
                            React.createElement(IconWrapper, null,
                                React.createElement(FtsIcons, { iconName: props.iconName, pathProps: { stroke: ColorsValue["yellow-warning-800"] } })),
                            React.createElement(Span, { weight: WeightSize.bold, variant: VariantsValues["text-md"], color: ColorsValue["greyscale-800"], textAlign: "left" }, props.title)),
                        React.createElement(FtsWrapper, { bg: ColorsValue["greyscale-50"], py: "xl", px: "2xl", borderRadius: "2xs" },
                            React.createElement(Span, { variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-800"] }, props.message)),
                        React.createElement(WarningActions, { containedButton: props.containedButton, outlinedButton: props.outlinedButton, textButton: props.textButton })))))));
};
export default Warning;
