import React from "react";
import { Pages } from "../../../views/constants/pages";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { LinkSizes } from "@find-truck-service/types/ui/link";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Link } from "@find-truck-service/ui/src/components/react/Link";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

const TermsAndConditions = () => {
  return (
    <FtsWrapper
      py={"2xl"}
      px={"4xl"}
      gap={"2xs"}
      wrap={"wrap"}
      align={"center"}
      display={"flex"}
      justify={"center"}
    >
      <Span
        weight={WeightSize.regular}
        variant={VariantsValues["text-2xs"]}
        color={ColorsValue["greyscale-800"]}
      >
        By proceeding you agree with our
      </Span>
      <Link
        href={Pages.TERMS_AND_CONDITIONS}
        label={"Terms and Conditions"}
        size={LinkSizes.md}
      />
    </FtsWrapper>
  );
};

export default TermsAndConditions;
