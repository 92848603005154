import React from "react";
import { useSelector } from "react-redux";

import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { ListingItem } from "@find-truck-service/ui/src/components/react/ListingItem";
import { ListingItemsTypeValues } from "@find-truck-service/types/ui/listingItemComponent";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { TemporaryLinkPageTitle } from "../../../components/fts-components-v2/TemporaryLink/TemporaryLinkPageTitle/TemporaryLinkPageTitle";
import { TemporaryLinkContentContainer } from "../../../components/fts-components-v2/TemporaryLink/TemporaryLinkContentContainer/TemporaryLinkContentContainer";
import {
  selectTemporaryLinkIsLoading,
  selectTemporaryLinkProofListingData,
} from "../../../redux/reducers/v2/temporaryListingLink";
import { DraftFooterButtons } from "./components/DraftFooterButtons";

export const TemporaryLinkDraftProofPreview = () => {
  const isLoading = useSelector(selectTemporaryLinkIsLoading);
  const listingData = useSelector(selectTemporaryLinkProofListingData);

  if (!listingData) return <FullPageLoader />;

  return (
    <>
      {isLoading && <FullPageLoader />}
      <TemporaryLinkContentContainer>
        <FtsWrapper flex={1} overflow={"auto"}>
          <FtsContainer mb={"2xl"}>
            <TemporaryLinkPageTitle title="Review Your Listing Proof" />
            <ListingItem
              listingData={listingData}
              type={ListingItemsTypeValues.LIVE}
            />
          </FtsContainer>
        </FtsWrapper>
        <DraftFooterButtons />
      </TemporaryLinkContentContainer>
    </>
  );
};
