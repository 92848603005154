import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Span } from "@find-truck-service/ui/src/FtsText";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { isObject } from "lodash";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import TruckFormButtonsMd from "./TuckFormButtonsMd";
import TruckFormButtonsXs from "./TruckFormButtonsXs";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOwnerOperatorProfileData,
  selectOwnerOperatorProfileInvalidFormFields,
} from "../../../../redux/reducers/v2/owner-operator-profile";

import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { OWNER_OPERATOR_PROFILE_STEPS } from "../utils/constants";
import { selectTruckTypes } from "../../../../redux/reducers/essentialLists";
import {
  ownerOperatorCreateTruckRequest,
  ownerOperatorProfileSetInvalidFormFields,
} from "../../../../redux/actions/v2/ownerOperatorProfile";
import { toast } from "react-toastify";

import { ownerOperatorTruckValidator } from "../validators/ownerOperatorTruck";
import { generateYearsValues } from "../../../../utility/generateYearsValues";
import { useHistory } from "react-router-dom";
import { Pages } from "../../../../views/constants/pages";
import logger from "../../../../utility/logger";

const OwnerOperatorTruckForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ownerOperatorProfileData = useSelector(selectOwnerOperatorProfileData);
  const invalidFormFields = useSelector(
    selectOwnerOperatorProfileInvalidFormFields,
  );
  const truckTypes = useSelector(selectTruckTypes);
  let years = generateYearsValues();

  const onUpdateOwnerOperatorSuccess = () => {
    history.push(Pages.VERIFY_EMAIL);
  };

  const onUpdateOwnerOperatorFailure = (e) => {
    const message = e.response
      ? e.response.data.message
      : "Error while adding user truck";

    toast.error(message);
  };

  const handleCreateTruck = async () => {
    try {
      await ownerOperatorTruckValidator.validate(
        ownerOperatorProfileData || {},
      );

      dispatch(
        ownerOperatorProfileSetInvalidFormFields({
          data: null,
        }),
      );

      const {
        truckMake,
        truckModel,
        truckNumber,
        truckVin,
        truckType,
        truckYear,
      } = ownerOperatorProfileData;

      dispatch(
        ownerOperatorCreateTruckRequest({
          data: {
            truckMake,
            truckModel,
            truckNumber,
            truckType,
            truckVin,
            truckYear,
          },
          onSuccess: onUpdateOwnerOperatorSuccess,
          onError: onUpdateOwnerOperatorFailure,
        }),
      );
    } catch (e) {
      logger.error(e);
      if (e.name === "ValidationError") {
        dispatch(
          ownerOperatorProfileSetInvalidFormFields({
            data: {
              [e.path]: e.errors[0],
            },
          }),
        );
      }
    }
  };

  return (
    <FtsColumn
      size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
      offset={{ xs: 0, sm: 0, md: 0, lg: 3 }}
      rowGap={"2xl"}
      columnGap={"2xl"}
    >
      <FtsWrapper
        p={{
          xs: "2xl",
          md: "4xl",
          lg: "4xl",
        }}
        bg={ColorsValue["greyscale-0"]}
        boxShadow="sm"
        borderRadius="2xs"
      >
        <FtsWrapper
          display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}
          align="center"
          pb={"sm"}
          cursor={"pointer"}
          onClick={() =>
            props.handleActiveStep(OWNER_OPERATOR_PROFILE_STEPS.PROFILE_STEP)
          }
        >
          <FtsIcons iconName={IconNames.ChevronLeft} />
          <Span
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["greyscale-700"]}
            weight={WeightSize.regular}
            pl={"2xs"}
          >
            Back
          </Span>
        </FtsWrapper>
        <FtsColumn
          size={{ xs: 12, md: 12, lg: 12 }}
          rowGap={"2xl"}
          columnGap={"2xl"}
        >
          <Span
            variant={VariantsValues["text-md"]}
            color={ColorsValue["greyscale-800"]}
            weight={WeightSize.bold}
          >
            Truck Info
          </Span>
        </FtsColumn>

        <FtsRow rowGap={"2xl"} columnGap={"lg"} mt={"4xl"}>
          <FtsColumn size={{ xs: 12, md: 6, lg: 6 }} rowGap={"2xl"}>
            <Input
              name={"truckNumber"}
              required
              value={ownerOperatorProfileData?.truckNumber}
              onChange={props.onChange}
              label={"Truck #"}
              variant={InputVariants.outlined}
              size={InputSizes.md}
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["truckNumber"]
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12, md: 6, lg: 6 }} rowGap={"2xl"}>
            <Dropdown
              menuPlacement={"bottom"}
              required
              size={DropdownSizesValues.md}
              variant={DropdownVariantsValues.outlined}
              name={"truckYear"}
              label={"Year"}
              options={years}
              placeholder={""}
              value={years.find(
                (e) =>
                  e.label.toString() === ownerOperatorProfileData?.truckYear,
              )}
              errorMessage={
                invalidFormFields && invalidFormFields?.["truckYear"]
              }
              onChange={(val) =>
                props.onChange({
                  target: {
                    name: "truckYear",
                    value: val?.label || "",
                  },
                })
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
            <Input
              name={"truckMake"}
              required
              value={ownerOperatorProfileData?.truckMake}
              onChange={props.onChange}
              label={"Make"}
              variant={InputVariants.outlined}
              size={InputSizes.md}
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["truckMake"]
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
            <Input
              name={"truckModel"}
              required
              value={ownerOperatorProfileData?.truckModel}
              onChange={props.onChange}
              label={"Model"}
              variant={InputVariants.outlined}
              size={InputSizes.md}
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["truckModel"]
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
            <Dropdown
              menuPlacement={"top"}
              required
              size={DropdownSizesValues.md}
              variant={DropdownVariantsValues.outlined}
              name={"truckType"}
              label={"Truck Type"}
              options={truckTypes.map((truckType) => ({
                label: truckType.label,
                value: truckType.label,
              }))}
              placeholder={""}
              value={years.find(
                (e) => e.label === ownerOperatorProfileData?.truckType,
              )}
              errorMessage={
                invalidFormFields && invalidFormFields?.["truckType"]
              }
              onChange={(val) =>
                props.onChange({
                  target: {
                    name: "truckType",
                    value: val?.label || "",
                  },
                })
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12, md: 6, lg: 6 }}>
            <Input
              name={"truckVin"}
              required
              value={ownerOperatorProfileData?.truckVin}
              onChange={props.onChange}
              label={"Vin"}
              variant={InputVariants.outlined}
              size={InputSizes.md}
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["truckVin"]
              }
            />
          </FtsColumn>
        </FtsRow>
        <TruckFormButtonsMd
          handleUpdateOwnerOperatorTruck={handleCreateTruck}
          handleOwnerOperatorTruckFinishLater={onUpdateOwnerOperatorSuccess}
        />
      </FtsWrapper>
      <TruckFormButtonsXs
        handleUpdateOwnerOperatorTruck={handleCreateTruck}
        handleOwnerOperatorTruckFinishLater={onUpdateOwnerOperatorSuccess}
      />
    </FtsColumn>
  );
};

export default OwnerOperatorTruckForm;
