import React, { PureComponent, createRef } from "react";
import { Field } from "formik";

import ReactQuill from "react-quill";

import FormikFastInput from "../../../../components/fts/formik/formik-fast-input";

import "react-quill/dist/quill.snow.css";
import "../../../../assets/scss/components/fts/form-fields.scss";
import "../../../../assets/scss/components/fts/listing-description.scss";
import DescritpionLength from "../../../../components/fts/fts-description-length";
import Breakpoint from "../../../../components/hoc/Breakpoint";
import InfoMessageSmall from "../../../../components/fts/info-message-small";
import classNames from "classnames";

class Description extends PureComponent {
  inputRef = createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = (e) => {
    this.props.setFieldValue("description", e);
  };

  render() {
    const { editMode } = this.props;

    if (this.inputRef.current) {
      this.inputRef.current.editor.clipboard.addMatcher(
        Node.ELEMENT_NODE,
        (node, delta) => {
          delta.ops = delta.ops.map((op) => {
            return {
              insert: op.insert,
            };
          });
          return delta;
        }
      );
    }
    return (
      <React.Fragment>
        <div className="description-wrapper">
          <div className="description-box">
            {editMode ? (
              <div className="d-flex flex-column">
                <div className="description-label">Description</div>

                <Breakpoint md up>
                  <Field name="description">
                    {({ field }) => {
                      return (
                        <ReactQuill
                          ref={this.inputRef}
                          modules={{
                            toolbar: [
                              ["bold", "italic", "underline"],

                              [{ list: "ordered" }, { list: "bullet" }],

                              ["clean"],
                            ],
                          }}
                          theme="snow"
                          initialValue={field.value || ""}
                          value={field.value || ""}
                          onChange={this.handleChange}
                          placeholder="Tell your customers about your company and services provided.&#x0a;Example:&#x0a;Whether you are moving freight across the country or making deliveries across town - Cummins knows that every Every Minute Counts. Because of this, Cummins provides the service support you need, when you need it. Whether in the shop or in the field, every Cummins location has the technical expertise, factory support, and experience to deliver fast high quality repairs through our global QuickServe process.&#x0a;QuickServe is a universal approach that provides you with:&#x0a;Personalized Service&#x0a;Fast Response & Diagnosis&#x0a;"
                          style={{
                            width: "647.23px",
                          }}
                        />
                      );
                    }}
                  </Field>
                </Breakpoint>

                <Breakpoint sm down>
                  <InfoMessageSmall
                    message="Please use desktop version at any time for updating &
                    finalizing listing description."
                  />
                  <div className="description-scrollable">
                    <Field name="description">
                      {({ field }) => {
                        return (
                          <ReactQuill
                            ref={this.inputRef}
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline"],

                                [{ list: "ordered" }, { list: "bullet" }],

                                ["clean"],
                              ],
                            }}
                            theme="snow"
                            initialValue={field.value || ""}
                            value={field.value || ""}
                            onChange={this.handleChange}
                            placeholder="Tell your customers about your company and services provided.&#x0a;Example:&#x0a;Whether you are moving freight across the country or making deliveries across town - Cummins knows that every Every Minute Counts. Because of this, Cummins provides the service support you need, when you need it. Whether in the shop or in the field, every Cummins location has the technical expertise, factory support, and experience to deliver fast high quality repairs through our global QuickServe process.&#x0a;QuickServe is a universal approach that provides you with:&#x0a;Personalized Service&#x0a;Fast Response & Diagnosis&#x0a;"
                            style={{
                              width: "647.23px",
                            }}
                          />
                        );
                      }}
                    </Field>
                  </div>
                </Breakpoint>
              </div>
            ) : (
              <>
                {this.props.values?.description === "" ||
                this.props.values?.description === "<p><br></p>" ? (
                  "No description"
                ) : (
                  <span
                    className={`${
                      this.props.keys &&
                      this.props.keys.includes("description") &&
                      "field-changed"
                    }`}
                  >
                    <FormikFastInput
                      formikInputValueId={"listing_item_descriptions"}
                      disabled={!editMode}
                      className={classNames("description", {
                        "description-mobile-preview": window.innerWidth < 768,
                      })}
                      type="textarea"
                      name="description"
                      label="Description"
                      labelId="description"
                      placeholder="Description"
                      rows={3}
                    />
                  </span>
                )}
              </>
            )}
            {editMode && <DescritpionLength />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Description;
