/* eslint-disable indent */
export const login = (state = { permissions: [] }, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state };
    }
    case "LOGIN_WITH_FB": {
      return { ...state };
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state };
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state };
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state };
    }
    case "LOGIN_WITH_JWT": {
      return {
        ...state,

        // permissions: action.payload.loggedInUser.permissions,
      };
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload, permissions: [] };
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload };
    }
    case "CHANGE_ROLE": {
      return { ...state, permissions: action.permissions };
    }
    case "UPDATE_USER_INFO": {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};
