import {
  SET_TEMPORARY_LINK_ACTIVE_BANNER,
  SET_TEMPORARY_LINK_ADS_ASSET_FOR_UPLOAD,
  SET_TEMPORARY_LINK_EDIT_LISTING_DATA,
  SET_TEMPORARY_LINK_HAS_ACTIVE_OR_FREE_LISTINGS,
  SET_TEMPORARY_LINK_INITIAL_LISTING_PRICE,
  SET_TEMPORARY_LINK_INVALID_FORM_FIELDS,
  SET_TEMPORARY_LINK_LISTING_DATA,
  SET_TEMPORARY_LINK_SELECTED_LISTING_PRICE,
  SET_TEMPORARY_LINK_SHOW_FREE_LISTING_MODAL_NOTICE,
  SET_TEMPORARY_LINK_USER_DATA,
  TEMPORARY_LINK_APPROVE_PROOF_FAILED,
  TEMPORARY_LINK_APPROVE_PROOF_REQUEST,
  TEMPORARY_LINK_APPROVE_PROOF_SUCCESS,
  TEMPORARY_LINK_CHOOSE_LISTING_TYPE_FAILED,
  TEMPORARY_LINK_CHOOSE_LISTING_TYPE_REQUEST,
  TEMPORARY_LINK_CHOOSE_LISTING_TYPE_SUCCESS,
  TEMPORARY_LINK_GET_COMPETITORS_VENDORS_FAILED,
  TEMPORARY_LINK_GET_COMPETITORS_VENDORS_REQUEST,
  TEMPORARY_LINK_GET_COMPETITORS_VENDORS_SUCCESS,
  TEMPORARY_LINK_GET_DATA_FAILED,
  TEMPORARY_LINK_GET_DATA_REQUEST,
  TEMPORARY_LINK_GET_DATA_SUCCESS,
  TEMPORARY_LINK_GET_FEATURED_VENDORS_FAILED,
  TEMPORARY_LINK_GET_FEATURED_VENDORS_REQUEST,
  TEMPORARY_LINK_GET_FEATURED_VENDORS_SUCCESS,
  TEMPORARY_LINK_GET_LISTING_FAILED,
  TEMPORARY_LINK_GET_LISTING_REQUEST,
  TEMPORARY_LINK_GET_LISTING_SUCCESS,
  TEMPORARY_LINK_REJECT_PROOF_FAILED,
  TEMPORARY_LINK_REJECT_PROOF_REQUEST,
  TEMPORARY_LINK_REJECT_PROOF_SUCCESS,
  TEMPORARY_LINK_UPDATE_LISTING_FAILED,
  TEMPORARY_LINK_UPDATE_LISTING_REQUEST,
  TEMPORARY_LINK_UPDATE_LISTING_SUCCESS,
  TEMPORARY_LINK_UPLOAD_ADS_ASSETS_FAILED,
  TEMPORARY_LINK_UPLOAD_ADS_ASSETS_REQUEST,
  TEMPORARY_LINK_UPLOAD_ADS_ASSETS_SUCCESS,
} from "../../../reducers/v2/temporaryListingLink";

export const temporaryLinkGetListingRequest = (payload) => ({
  type: TEMPORARY_LINK_GET_LISTING_REQUEST,
  payload,
});

export const temporaryLinkGetListingSuccess = (payload) => ({
  type: TEMPORARY_LINK_GET_LISTING_SUCCESS,
  payload,
});

export const temporaryLinkGetListingFailed = (payload) => ({
  type: TEMPORARY_LINK_GET_LISTING_FAILED,
  payload,
});

export const temporaryLinkGetDataRequest = (payload) => ({
  type: TEMPORARY_LINK_GET_DATA_REQUEST,
  payload,
});

export const temporaryLinkGetDataSuccess = (payload) => ({
  type: TEMPORARY_LINK_GET_DATA_SUCCESS,
  payload,
});

export const temporaryLinkGetDataFailed = (payload) => ({
  type: TEMPORARY_LINK_GET_DATA_FAILED,
  payload,
});

export const temporaryLinkUpdateListingRequest = (payload) => ({
  type: TEMPORARY_LINK_UPDATE_LISTING_REQUEST,
  payload,
});

export const temporaryLinkUpdateListingSuccess = (payload) => ({
  type: TEMPORARY_LINK_UPDATE_LISTING_SUCCESS,
  payload,
});

export const temporaryLinkUpdateListingFailed = (payload) => ({
  type: TEMPORARY_LINK_UPDATE_LISTING_FAILED,
  payload,
});

export const temporaryLinkApproveProofRequest = (payload) => ({
  type: TEMPORARY_LINK_APPROVE_PROOF_REQUEST,
  payload,
});

export const temporaryLinkApproveProofSuccess = (payload) => ({
  type: TEMPORARY_LINK_APPROVE_PROOF_SUCCESS,
  payload,
});

export const temporaryLinkApproveProofFailed = (payload) => ({
  type: TEMPORARY_LINK_APPROVE_PROOF_FAILED,
  payload,
});

export const temporaryLinkRejectProofRequest = (payload) => ({
  type: TEMPORARY_LINK_REJECT_PROOF_REQUEST,
  payload,
});

export const temporaryLinkRejectProofSuccess = (payload) => ({
  type: TEMPORARY_LINK_REJECT_PROOF_SUCCESS,
  payload,
});

export const temporaryLinkRejectProofFailed = (payload) => ({
  type: TEMPORARY_LINK_REJECT_PROOF_FAILED,
  payload,
});

export const temporaryLinkChooseListingTypeRequest = (payload) => ({
  type: TEMPORARY_LINK_CHOOSE_LISTING_TYPE_REQUEST,
  payload,
});

export const temporaryLinkChooseListingTypeSuccess = (payload) => ({
  type: TEMPORARY_LINK_CHOOSE_LISTING_TYPE_SUCCESS,
  payload,
});

export const temporaryLinkChooseListingTypeFailed = (payload) => ({
  type: TEMPORARY_LINK_CHOOSE_LISTING_TYPE_FAILED,
  payload,
});

export const temporaryLinkUploadAdsAssetsRequest = (payload) => ({
  type: TEMPORARY_LINK_UPLOAD_ADS_ASSETS_REQUEST,
  payload,
});

export const temporaryLinkUploadAdsAssetsSuccess = (payload) => ({
  type: TEMPORARY_LINK_UPLOAD_ADS_ASSETS_SUCCESS,
  payload,
});

export const temporaryLinkUploadAdsAssetsFailed = (payload) => ({
  type: TEMPORARY_LINK_UPLOAD_ADS_ASSETS_FAILED,
  payload,
});

export const setTemporaryLinkAdsAssetForUpload = (payload) => ({
  type: SET_TEMPORARY_LINK_ADS_ASSET_FOR_UPLOAD,
  payload,
});

//SETTERS

export const setTemporaryLinkInvalidFormFields = (payload) => ({
  type: SET_TEMPORARY_LINK_INVALID_FORM_FIELDS,
  payload,
});

export const setTemporaryLinkEditListingData = (payload) => ({
  type: SET_TEMPORARY_LINK_EDIT_LISTING_DATA,
  payload,
});

export const setTemporaryLinkListingData = (payload) => ({
  type: SET_TEMPORARY_LINK_LISTING_DATA,
  payload,
});

export const setTemporaryLinkActiveBanner = (payload) => ({
  type: SET_TEMPORARY_LINK_ACTIVE_BANNER,
  payload,
});

export const setTemporaryLinkInitialListingPrice = (payload) => ({
  type: SET_TEMPORARY_LINK_INITIAL_LISTING_PRICE,
  payload,
});

export const setTemporaryLinkSelectedListingPrice = (payload) => ({
  type: SET_TEMPORARY_LINK_SELECTED_LISTING_PRICE,
  payload,
});

export const getTemporaryLinkFeaturedVendorsRequest = (payload) => ({
  type: TEMPORARY_LINK_GET_FEATURED_VENDORS_REQUEST,
  payload,
});

export const getTemporaryLinkFeaturedVendorsSuccess = (payload) => ({
  type: TEMPORARY_LINK_GET_FEATURED_VENDORS_SUCCESS,
  payload,
});

export const getTemporaryLinkFeaturedVendorsFailed = (payload) => ({
  type: TEMPORARY_LINK_GET_FEATURED_VENDORS_FAILED,
  payload,
});

export const setTemporaryLinkShowFreeListingModalNotice = (payload) => ({
  type: SET_TEMPORARY_LINK_SHOW_FREE_LISTING_MODAL_NOTICE,
  payload,
});

export const setTemporaryLinkHasActiveOrFreeListings = (payload) => ({
  type: SET_TEMPORARY_LINK_HAS_ACTIVE_OR_FREE_LISTINGS,
  payload,
});

export const setTemporaryLinkUserData = (payload) => ({
  type: SET_TEMPORARY_LINK_USER_DATA,
  payload,
});

export const temporaryLinkGetCompetitorsRequest = (payload) => ({
  type: TEMPORARY_LINK_GET_COMPETITORS_VENDORS_REQUEST,
  payload,
});
export const temporaryLinkGetCompetitorsSuccess = (payload) => ({
  type: TEMPORARY_LINK_GET_COMPETITORS_VENDORS_SUCCESS,
  payload,
});
export const temporaryLinkGetCompetitorsFailed = (payload) => ({
  type: TEMPORARY_LINK_GET_COMPETITORS_VENDORS_FAILED,
  payload,
});
