function capitalize(string, firstLetterOnly = true) {
  const words = string.split(" ");
  const capitalizedWords = words.map(
    (word) => `${word.charAt(0).toUpperCase() + (firstLetterOnly ? word.slice(1).toLowerCase() : word.slice(1))}`
  );

  return capitalizedWords.join(" ");
}

export const capitalizeOnlyFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isUpperCase = (string) => {
  return string === string.toUpperCase();
};

export default capitalize;
