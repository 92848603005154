import React from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { history } from "../../../history";
import "../../../assets/scss/pages/fts-update-expired-password.scss";

import { updatePassword } from "../../../redux/actions/auth/loginActions";
import { extractParameter } from "../../../utility/param-extract";
import Icon from "../../../components/fts/Icons";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";

class UpdateExpiredPassword extends React.Component {
  state = {
    inProgress: false,
    error: null,
  };

  componentDidMount() {
    const token = extractParameter("token");
    if (!token) history.push("/");
  }

  formRef = React.createRef();

  initialData = {
    password: "",
    repeatPassword: "",
  };

  validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=(?:.*[a-z]))(?=(?:.*[A-Z]))(?=(?:.*[0-9]))(?=(?:.*[!@#$%^&*()_+=\-{}|":;'?/.>,<])).{7,}$/,
        "Minimum 7 digits with letters, numbers and special (!@#$%&?).",
      )
      .required("Password is required!"),
    repeatPassword: Yup.string()
      .required("Confirm password is required!")
      .oneOf([Yup.ref("password"), null], "Passwords do not match"),
  });

  handleSubmit = (values) => {
    if (this.state.inProgress) {
      return;
    }

    const token = extractParameter("token");
    this.setState({ inProgress: true });

    this.props
      .updatePassword({
        token,
        password: values.password,
        repeatPassword: values.repeatPassword,
      })
      .then(() => {
        history.push("/dashboard/login", {
          infoMessage: {
            message: "Your password has been updated",
          },
        });
      })
      .catch((err) => {
        const { response } = err;

        this.setState({
          error: response?.data?.errors,
        });
      })
      .finally(() => {
        this.setState({ inProgress: false });
      });
  };

  toggleShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { error } = this.state;

    return (
      <div className="fts-update-expired-password">
        <HeaderWrapper />
        <div className="fts-update-expired-password-content">
          <div className="fts-update-expired-password-content-box">
            <div className="change-password-new-heading">
              <h3>Update Password</h3>
              <p>Please enter a new password to continue.</p>
            </div>

            {!!error && (
              <Alert color="danger" className="error-message">
                <Icon name="AlertTriangle" size={24} />
                <span>{error?.message}</span>
              </Alert>
            )}

            <Formik
              id="FTS-update-expired-password"
              innerRef={this.formRef}
              initialValues={this.initialData}
              validationSchema={this.validationSchema}
              onSubmit={this.handleSubmit}
            >
              {({
                touched,
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <>
                  <Form
                    onSubmit={handleSubmit}
                    className="FTS-reset-password-form"
                  >
                    <div>
                      <FormGroup className="register-form-label-group position-relative">
                        <Label>New Password *</Label>
                        <div className="eye-input-field">
                          <Input
                            id="password"
                            variant="standard"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            type={this.state.showPassword ? "text" : "password"}
                          />
                          <Button
                            type="button"
                            onClick={this.toggleShowPassword}
                            className="eye-button"
                          >
                            <Icon name="EyeIcon" size={24} />
                          </Button>
                        </div>
                        {errors.password && touched.password ? (
                          <div className="FTS-Registration-error">
                            {errors.password}
                          </div>
                        ) : null}
                      </FormGroup>

                      <FormGroup className="register-form-label-group position-relative mb-0">
                        <Label>Confirm New Password *</Label>
                        <Input
                          variant="standard"
                          type="password"
                          id="repeatPassword"
                          value={values?.repeatPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                        {errors.repeatPassword && touched.repeatPassword ? (
                          <div className="FTS-Registration-error">
                            {errors.repeatPassword}{" "}
                          </div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Form>
                </>
              )}
            </Formik>

            <Button
              color="primary"
              type="button"
              className="w-100 reset-password-button"
              form="FTS-update-expired-password"
              onClick={() => {
                if (this.formRef?.errors !== {}) {
                  this.formRef.current.handleSubmit();
                } else {
                  this.handleSubmit();
                }
              }}
            >
              {this.state.inProgress ? <Spinner size="sm" /> : "Reset Password"}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(null, { updatePassword })(UpdateExpiredPassword);
