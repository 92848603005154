import moment from "moment";
import FTSAxios from "../../../axios/fts.instance";
import axios from "axios";

export const getListingsReports = (params) => {
  const { id, page, resultsPerPage } = params;
  return () => {
    return FTSAxios.get(`reports/listings/${id}/${page}/${resultsPerPage}`);
  };
};

export const getAccountReports = (params) => {
  const { id, page, resultsPerPage } = params;
  return () => {
    return FTSAxios.get(`reports/account/${id}/${page}/${resultsPerPage}`);
  };
};

export const generateListingReport = (data) => {
  return () => {
    return FTSAxios.post("reports/generateListingReport", data);
  };
};

export const generateAccountReport = (data) => {
  return () => {
    return FTSAxios.post("reports/generateAccountReport", data);
  };
};

export const removeListingReport = (params) => {
  return () => {
    return FTSAxios.delete("reports/listing", { params });
  };
};

export const removeUserReport = (params) => {
  return () => {
    return FTSAxios.delete("reports/account", { params });
  };
};

// export const updateListingReport = (params) => {
//   const { reportId, data } = params;
//   return () => {
//     return FTSAxios.put(`/reports/listing/${reportId}`, data);
//   };
// };

export const updateUserReport = (params) => {
  const { reportId, data } = params;
  return () => {
    return FTSAxios.put(`reports/account/${reportId}`, data);
  };
};

export const sendReport = (data) => {
  return () => {
    return FTSAxios.put("reports/send", data);
  };
};

export const getListingReport = (listingId) => {
  return () => {
    return FTSAxios.get("reports/listing", {
      params: { listingId },
    });
  };
};

export const getAccountReport = (accountId) => {
  return () => {
    return FTSAxios.get("reports/account", {
      params: { accountId },
    });
  };
};
export const getAccountReportCounters = (accountId) => {
  return () => {
    return FTSAxios.get("reports/account/counts", {
      params: { accountId },
    });
  };
};

export const getListingReportCounters = (listingId) => {
  return () => {
    return FTSAxios.get("reports/listing/counts", {
      params: { listingId },
    });
  };
};

export const getGeneratedAccountReport = ({ reportId }) => {
  return () => {
    return FTSAxios.get(`reports/getGeneratedAccountReport/${reportId}`);
  };
};

export const getGeneratedListingReport = ({ reportId }) => {
  return () => {
    return FTSAxios.get(`reports/getGeneratedListingReport/${reportId}`);
  };
};

export const generateMultipleListingsReport = (data) => {
  return () => {
    return FTSAxios.post("reports/multipleListingsReport", data);
  };
};

export const getReportsCount = () => {
  return () => {
    return FTSAxios.get("reports/count");
  };
};

export const getReceivedAccountReports = (params) => {
  return () => {
    return FTSAxios.get("reports/received/accounts", { params });
  };
};

export const getReceivedListingReports = (params) => {
  return () => {
    return FTSAxios.get("reports/received/listings", { params });
  };
};

export const getSharedAccountReports = (params) => {
  return () => {
    return FTSAxios.get("reports/shared/accounts", { params });
  };
};

export const getSharedListingReports = (params) => {
  return () => {
    return FTSAxios.get("reports/shared/listings", { params });
  };
};

export const updateAccountReport = (payload) => {
  return () => {
    return FTSAxios.put("reports/account", payload);
  };
};

export const updateListingReport = (payload) => {
  return () => {
    return FTSAxios.put("reports/listing", payload);
  };
};

export const getReportByHash = (hash) => {
  return () => {
    return axios.get(`${process.env.REACT_APP_API_URL}reports/token/${hash}`, {
      headers: { "temporary-token": hash },
    });
  };
};

export const getRelatedListingsCounters = (hash, data) => {
  return () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}reports/account/related-listings/count`,
      {
        headers: { "temporary-token": hash },
        params: {
          searchString: data.searchString,
        },
      }
    );
  };
};

export const getRelatedListingsByListingType = (hash, data) => {
  return () => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}reports/account/related-listings`,
      data,
      {
        headers: { "temporary-token": hash },
      }
    );
  };
};

export const getListingReportPreview = (hash, data) => {
  return () => {
    return axios.get(`${process.env.REACT_APP_API_URL}reports/listing`, {
      headers: { "temporary-token": hash },
      params: {
        listingId: data?.listingId,
        endAt: moment(data?.endAt).toISOString(),
        startAt: moment(data?.startAt).toISOString(),
      },
    });
  };
};

export const getReportsListings = ({
  page,
  resultsPerPage,
  priceId,
  status,
  searchString,
  sort = [],
}) => {
  return () => {
    return FTSAxios.get("reports/listings", {
      params: {
        page,
        resultsPerPage,
        priceId,
        status,
        searchString,
        sort: sort.length ? sort[0] : [],
      },
    });
  };
};

export const getCategories = () => {
  return (dispatch) => {
    return FTSAxios.get("search/categories").then(({ data }) => {
      dispatch({
        type: "UPDATE_CATEGORIES_LIST",
        data,
      });
    });
  };
};
