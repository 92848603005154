import {
  LOGIN_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SET_LOGIN_DATA,
  SET_LOGIN_INVALID_FORM_FIELDS,
} from "../../../reducers/v2/login";

export const setLoginData = (payload) => ({
  type: SET_LOGIN_DATA,
  payload,
});

export const setLoginInvalidFormFields = (payload) => ({
  type: SET_LOGIN_INVALID_FORM_FIELDS,
  payload,
});

export const loginRequest = (payload) => ({
  type: LOGIN_REQUEST,
  payload,
});

export const loginFailed = (payload) => ({
  type: LOGIN_FAILED,
  payload,
});

export const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});
