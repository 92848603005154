import React, { Component } from "react";

import moment from "moment";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import { Collapse } from "reactstrap";

import Icon from "../../../components/fts/Icons";
import "../../../assets/scss/components/fts/payment-modals.scss";
import { connect } from "react-redux";
import {
  payWithStripe,
  payWithStripeClient,
  payWithStripeTokenClient,
} from "../../../redux/actions/payments";
import { toast } from "react-toastify";

import { setPromoCode } from "../../../redux/actions/promocodes";
import {
  resetDiscount,
  setCreditCardData,
  setDueInvoicesCount,
  setTotalCreditAmount,
} from "../../../redux/actions/invoices";

import { getUserDetails } from "../../../redux/actions/users";

import ConfirmationModal from "../../../components/fts/confirmation-modal";
import logger from "../../../utility/logger";

class CreditCard extends Component {
  state = {
    activeTab: "oneTime",
    today: moment(),
    todayFormatted: moment().format("MMM DD, YYYY"),
    startRecurring: moment().add(1, "year").format("MMM DD, YYYY"),
    disablePayment: false,
    addNewPaymentMethod: false,
    initialFormData: {
      cardHolder: "",
      cardNumber: "",
      expirationDate: "",
      cvv: "",
      zipCode: "",
      startDate: moment().toISOString(),
    },
  };

  formSchema = Yup.object().shape(
    {
      cardHolder: Yup.string().trim().required("Cardholder name is required"),
      cardNumber: Yup.string()
        .test(
          "cardNumber",
          "Card number must be exactly 16 characters",
          (cardNumber) => {
            return (
              cardNumber?.replace(/_/g, "").replace(/-/g, "").length === 15 ||
              cardNumber?.replace(/_/g, "").replace(/-/g, "").length === 16
            );
          },
        )
        .required("Card number is required"),
      expirationDate: Yup.string()
        .required("Expiration date is required")
        .test(
          "len",
          "Invalid expiration date",
          (val) => val?.replace("_", "").replace("/", "").length >= 4,
        )
        .test("len2", "Invalid expiration date", (val) => {
          const year = new Date().getFullYear() - 2000;
          const newVal = val?.split("/")[1];
          if (parseInt(newVal) < year) {
            return false;
          }
          return true;
        })
        .test("len3", "Invalid expiration date", (val) => {
          const year = new Date().getFullYear() - 2000;
          const month = new Date().getMonth();
          const valMonth = val?.split("/")[0];
          const valYear = val?.split("/")[1];
          if (parseInt(valYear) === year && parseInt(valMonth) < month) {
            return false;
          }
          return true;
        }),
      cvv: Yup.string()
        .required("CVV is required")
        .test(
          "len",
          "CVV must be 3 or 4 digits",
          (val) =>
            val?.toString().replace(/_/g, "").length <= 4 &&
            val?.toString().replace(/_/g, "").length >= 3,
        ),
      zipCode: Yup.number()
        .required("Zip code is required")
        .typeError("Invalid zip code"),

      startDate: Yup.lazy((val) =>
        typeof val === "string"
          ? Yup.string().nullable().required("Start date is required")
          : Yup.array()
              .of(Yup.date())
              .nullable()
              .required("Start date is required"),
      ),
    },
    [["cardNumber"]],
  );

  now = new Date();

  updateActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  formatMoney = (number) => {
    return number
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  handleSubmit = (values) => {
    if (this.state.inProgress) return;
    this.props.setPaymentInProgress(true);
    this.setState({
      inProgress: true,
    });
    const card = this.state.creditCardForPayment;
    let {
      selectedInvoices,
      appliedCreditAmount,
      restOfListingPrice,
      totalAmount,
    } = this.props;

    selectedInvoices = selectedInvoices.filter((invoice) => invoice.isSelected);

    let payload = {
      subscription: this.state.activeTab === "recurring",
      number: values?.cardNumber || 0,
      exp_month: values?.expirationDate.split("/")[0] || 0,
      exp_year: values?.expirationDate.split("/")[1] || 0,
      cvc: values?.cvv.replace("_", "") || 0,
      companyName: selectedInvoices[0]["owner.companyName"],
      ownerFirstName: selectedInvoices[0]["owner.firstName"],
      ownerLastName: selectedInvoices[0]["owner.lastName"],
      city: selectedInvoices[0]["owner.city"],
      state: selectedInvoices[0]["owner.state"],
      newAccount: selectedInvoices[0]["owner.newAccount"],

      source: "card",
      amount: totalAmount,
      invoices: selectedInvoices.map(
        ({
          id,
          amount,
          promoCode,
          adId,
          userId,
          invoiceAmount,
          type,
          invoiceNumber,
          creditUsed,
        }) => ({
          invoiceNumber,
          id,
          type,
          amount: amount || invoiceAmount,
          promoCode: promoCode && { id: promoCode.id },
          adId,
          userId,
          invoiceAmount,
          creditUsed,
        }),
      ),
      promoCodeId: this.props.promoCode?.id ? this.props.promoCode.id : null,
      cardHolder: values?.cardHolder,
      zipCode: values?.zipCode,
      credit: appliedCreditAmount < 0 ? 0 : appliedCreditAmount,
      previousListingAmount: restOfListingPrice,
      card,
      paymentToken: card?.token,
    };
    if (card) {
      this.props
        .payWithStripeTokenClient(payload)
        .then(async ({ data }) => {
          let newCredit = this.props.totalAccountCredit - payload.credit;
          let newDueInvoicesCount =
            this.props.dueInvoicesCount - selectedInvoices.length;

          this.props.setTotalCreditAmount(newCredit);
          this.props.setDueInvoicesCount(Math.max(newDueInvoicesCount, 0));
          await this.props.getUserDetails();

          this.props.onClientPaymentSuccessfull &&
            this.props.onClientPaymentSuccessfull(data);
          toast.success("Payment successfully processed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((e) => {
          logger.error(e.response);
          toast.error(e.response?.data?.message || e.response?.data?.error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {
          this.props.setPaymentInProgress(false);
          this.setState({
            inProgress: false,
          });
        });
      return;
    }

    this.props
      .payWithStripeClient(payload)
      .then(async ({ data }) => {
        let newCredit = this.props.totalAccountCredit - payload.credit;
        let newDueInvoicesCount =
          this.props.dueInvoicesCount - selectedInvoices.length;

        this.props.setTotalCreditAmount(newCredit);
        this.props.setDueInvoicesCount(newDueInvoicesCount);
        await this.props.getUserDetails();

        if (values) {
          this.props.onClientPaymentSuccessfull &&
            this.props.onClientPaymentSuccessfull(data);
          toast.success("Payment successfully processed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        logger.error(e.response);
        toast.error(e.response?.data?.message || e.response?.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        this.props.setPaymentInProgress(false);
        this.setState({
          inProgress: false,
        });
      });
  };

  addNewPaymentMethod = () => {
    this.setState({ addNewPaymentMethod: !this.state.addNewPaymentMethod });
  };
  componentDidMount = () => {
    let creditCardData = this.props.creditCardData;
    if (creditCardData) {
      this.setState({
        disablePayment: true,
        activeTab: creditCardData.activeTab,
        initialFormData: {
          cardHolder: creditCardData.cardHolder,
          cardNumber: creditCardData.number,
          expirationDate: `${creditCardData.exp_month}/${creditCardData.exp_year}`,
          cvv: creditCardData.cvc,
          zipCode: creditCardData.zipCode,
          startDate: creditCardData.startDate,
        },
      });
    }
  };

  handleCeditCardConfirmationModal = (card) => {
    this.setState({
      creditCardConfirmationModal: true,
      creditCardForPayment: card,
    });
  };
  render() {
    const { activeTab, todayFormatted, startRecurring } = this.state;
    let tabs = [
      {
        label: "Recurring",
        type: "recurring",
      },
      {
        label: "One - time",
        type: "oneTime",
      },
    ];

    return (
      <>
        <Formik
          initialValues={this.state.initialFormData}
          validationSchema={this.formSchema}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={this.handleSubmit}
        >
          {() => {
            return (
              <Form id="credit-card-form">
                <div className="payment-modal-body pt-0">
                  <span className="payment-modal-body-wrapper">
                    <div className="amount-box">
                      <span className="amount">
                        ${this.formatMoney(this.props.totalAmount)}
                      </span>
                      <span className="amount-box-date">
                        Due on {todayFormatted}
                      </span>
                    </div>

                    <div className="nav">
                      {tabs.map((itm) => {
                        return (
                          <button
                            key={itm.type}
                            type="button"
                            className={`tab ${
                              activeTab === itm.type ? "selected" : "unselected"
                            }`}
                            onClick={() => {
                              this.updateActiveTab(itm.type);
                            }}
                          >
                            {itm.label}
                          </button>
                        );
                      })}
                    </div>
                    <Collapse isOpen={activeTab === "recurring"}>
                      <div className="payment-start">
                        <div>
                          Start{" "}
                          <span className="start-date">
                            <Icon name="CheckIcon" size="12" />
                            Immediately - {todayFormatted}
                          </span>
                        </div>
                        <hr />
                        <div className="payment-info">
                          <span>
                            <Icon name="InfoCircle" />
                          </span>
                          <span className="info-message">
                            Annually recurring payment will start on{" "}
                            {startRecurring}. This amount will automatically be
                            charged on this day every year. You can always
                            switch between One-time and Recurring payment type.
                          </span>
                        </div>
                      </div>
                    </Collapse>

                    <div className="accepted-credit-cards">
                      <span className="label">Accepted credit cards</span>
                      <div className="credit-cards">
                        <Icon name="Visa" size="33" />
                        <Icon name="MasterCard" size="34" />
                        <Icon name="AmeEx" size="40" />
                        <Icon name="Discover" size="40" />
                      </div>
                    </div>
                  </span>
                </div>
              </Form>
            );
          }}
        </Formik>

        <ConfirmationModal
          title="Confirmation"
          message={"Are you sure you want to pay with this card?"}
          closeModal={() =>
            this.setState({
              creditCardConfirmationModal: false,
              creditCardForPayment: null,
            })
          }
          confirmModal={() => {
            this.setState(
              {
                creditCardConfirmationModal: false,
              },
              () => {
                this.handleSubmit();
              },
            );
          }}
          isOpen={this.state.creditCardConfirmationModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedInvoices: state.invoices.selectedInvoices,
    totalAmount: state.invoices.totalAmount,
    discountAmount: state.invoices.discountAmount,
    promoCode: state.promoCodes.promoCode,
    creditCardData: state.invoices.creditCardData,
    appliedCreditAmount: state.invoices.appliedCreditAmount,
    restOfListingPrice: state.invoices.restOfListingPrice,
    totalAccountCredit: state.invoices.totalAccountCredit,
    userData: state.authState.user,
    userCards: state.auth.login?.values?.loggedInUser?.userCards,
    dueInvoicesCount: state.invoices.dueInvoicesCount,
  };
};

const mapDispatchToProps = {
  payWithStripe,
  setPromoCode,
  resetDiscount,
  setCreditCardData,
  setTotalCreditAmount,
  payWithStripeTokenClient,
  payWithStripeClient,
  getUserDetails,
  setDueInvoicesCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard);
