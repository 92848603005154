import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

const PaymentOverviewItem = ({ label, value }) => {
  return (
    <FtsWrapper display="flex" justify="space-between" width="100%">
      <Span
        variant={VariantsValues["text-2xs"]}
        color={ColorsValue["greyscale-800"]}
      >
        {label}
      </Span>
      <Span
        variant={VariantsValues["text-2xs"]}
        color={ColorsValue["greyscale-800"]}
        weight={WeightSize.medium}
      >
        {value}
      </Span>
    </FtsWrapper>
  );
};

export default PaymentOverviewItem;
