import React from "react";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";
import { CompleteAccountInfo } from "../../../components/fts-components-v2/CompleteAccountInfo";

export const CompleteAccountInfoPage = () => {
  return (
    <>
      <HeaderWrapper />
      <CompleteAccountInfo />
    </>
  );
};
