import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

export const LoginFormTitle = () => {
  return (
    <FtsColumn size={{ xs: 12 }}>
      <FtsWrapper display={"flex"} direction={"column"} gap={"sm"}>
        <Span
          weight={WeightSize.bold}
          variant={VariantsValues["text-md"]}
          color={ColorsValue["greyscale-800"]}
        >
          Login
        </Span>
        <Span
          weight={WeightSize.regular}
          variant={VariantsValues["text-2xs"]}
          color={ColorsValue["greyscale-800"]}
        >
          Enter your details to login
        </Span>
      </FtsWrapper>
    </FtsColumn>
  );
};
