import React from "react";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { FtsContainer } from "../../../FtsContainer/FtsContainer";
import { FtsRow } from "../../../FtsRow/FtsRow";
import { FtsColumn } from "../../../FtsColumn/FtsColumn";
import { Span } from "../../../FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { SuccessActions } from "./components/SuccessActions";
import { IconWrapper } from "./styled/IconWrapper";
import FtsIcons from "../../../FtsIcons/FtsIcons";
const Success = (props) => {
    return (React.createElement(FtsContainer, null,
        React.createElement(FtsWrapper, { pt: { xs: "0", sm: "0", md: "4xl", lg: "4xl" } },
            React.createElement(FtsRow, null,
                React.createElement(FtsColumn, { size: { xs: 12, sm: 12, md: 12, lg: 6 }, offset: { xs: 0, sm: 0, md: 0, lg: 3 } },
                    React.createElement(FtsWrapper, { display: "flex", direction: "column", bg: ColorsValue["greyscale-0"], borderRadius: "2xs", boxShadow: "sm", py: { xs: "4xl", sm: "4xl", md: "4xl", lg: "4xl" }, px: { xs: "xl", sm: "4xl", md: "4xl", lg: "4xl" } },
                        React.createElement(FtsWrapper, { display: "flex", align: "center", direction: "column", gap: { xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" } },
                            React.createElement(IconWrapper, null,
                                React.createElement(FtsIcons, { iconName: props.iconName, pathProps: { stroke: ColorsValue["green-success-500"] } })),
                            React.createElement(FtsWrapper, { display: "flex", direction: "column", textAlign: "center" },
                                React.createElement(Span, { weight: WeightSize.bold, variant: VariantsValues["text-md"], color: ColorsValue["greyscale-800"] }, props.title),
                                React.createElement(Span, { weight: WeightSize.regular, variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-500"], mt: "sm" }, props.subtitle)),
                            React.createElement(SuccessActions, { containedButton: props.containedButton, outlinedButton: props.outlinedButton, textButton: props.textButton }))))))));
};
export default Success;
