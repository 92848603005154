import React, { Component } from "react";
import { Label } from "reactstrap";
import { ErrorMessage } from "formik";

import limitTypes from "../../../data/subject-options";
import { pickerStyles, controlStyles } from "../picker-style";
import Select from "../Select";

class SubjectPicker extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.isDisabled !== this.props.isDisabled ||
      (nextProps.meta && nextProps.meta.error !== this.props.meta.error) ||
      (!!this.props.meta &&
        nextProps.meta.touched !== this.props.meta.touched) ||
      nextProps.value !== this.props.value
    );
  }

  render() {
    const { label, meta, name, isDisabled, ...rest } = this.props;

    const pickerStyle = {
      ...pickerStyles,
      control: (styles, state) => controlStyles(styles, state, {}, { meta }),
    };

    const defaultValue = meta && meta.initialValue.value;

    let isLoading = false;

    return (
      <>
        {!!label && (
          <Label
            className={isDisabled ? "disabled" : ""}
            for="activation-status"
          >
            {this.props.required && !isDisabled ? `${label} *` : label}
          </Label>
        )}
        <Select
          {...{ isDisabled, isLoading, label }}
          {...{ defaultValue, label }}
          isSearchable={false}
          isDisabled={this.props.isDisabled}
          options={limitTypes}
          styles={pickerStyle}
          name={name}
          {...rest}
        />

        <ErrorMessage
          name={name}
          render={(msg) => <div className="formik-validation-error">{msg}</div>}
        />
      </>
    );
  }
}

export default SubjectPicker;
