import React, { useCallback } from "react";
import { descriptionPlaceholder } from "../constants";
import { useDispatch } from "react-redux";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { RichTextEditor } from "@find-truck-service/ui/src/components/react/RichTextEditor";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import {
  DESCRIPTION_CONTAINER_HEIGHT,
  DESCRIPTION_CONTAINER_WIDTH,
} from "@find-truck-service/ui/src/components/react/ListingItem/constants";

export const DesktopDescriptionField = ({ description, setListingData }) => {
  const dispatch = useDispatch();

  const onChange = useCallback((description) => {
    dispatch(setListingData({ data: { description } }));
  }, []);

  return (
    <FtsWrapper
      height={DESCRIPTION_CONTAINER_HEIGHT}
      width={`min(100%, ${DESCRIPTION_CONTAINER_WIDTH})`}
      display={{ xs: "none", sm: "block", md: "block", lg: "block" }}
    >
      <RichTextEditor
        height={"100%"}
        value={description}
        onChange={onChange}
        variant={VariantsValues["text-3xs"]}
        placeholder={descriptionPlaceholder}
      />
    </FtsWrapper>
  );
};
