import { combineReducers } from "redux";
import calenderReducer from "./calendar/";
import promotionsReducer from "./promotions/";
import invoicesReducer from "./invoices/";
import marketingReducer from "./marketing/";
import promoCodesReducer from "./promocodes/";
import customizer from "./customizer/";
import auth from "./auth/";
import categoriesList from "./categories";
import userTypesList from "./user-types";
import todoTypesList from "./todo-types";
import permissionsList from "./permissions";
import adsList from "./ads";
import approvedIPs from "./approvedIPs";
import adminUsersList from "./adminUsers";
import serviceAmenitiesList from "./serviceAmenities";
import amenitiesList from "./amenities";
import subcategoriesList from "./subcategories";
import essentialLists from "./essentialLists";
import searchFilter from "./searchFilter";
import accountNotes from "./user-notes";
import accountTodos from "./user-todos";
import accountActivities from "./user-activities";
import ListingReducer from "./listings";
import AccountReducer from "./accounts";
import remindersReducer from "./task-managment";
import SalesReducer from "./sales";
import ActivitiesRecuder from "./activities";
import NotificationReducer from "./notifications";
import RenewalsFilterReducer from "./renewals";
import UserTrucksReducer from "./trucks";
import createListingAccountReducer from "./createListingAccount";
import createListingInvoiceReducer from "./createListingInvoice";
import invoicesState from "./v2/invoices";
import authState from "./v2/auth";
import cardsState from "./v2/cards";
import paymentsState from "./v2/payments";
import listingState from "./v2/listing";
import vendorAdvertiseListingsState from "./v2/vendor-advertise-listings";
import companyDriverProfileState from "./v2/company-driver-profile";
import fleetProfileState from "./v2/fleet-driver-profile";
import otherProfileState from "./v2/other-profile";
import ownerOperatorProfileState from "./v2/owner-operator-profile";
import createAccountState from "./v2/createAccount";
import socialAuthState from "./v2/social-auth";
import completeRegistrationState from "./v2/completeRegistration";
import loginState from "./v2/login";
import resetPasswordState from "./v2/resetPassword";
import updatePasswordState from "./v2/updatePassword";
import temporaryLinkListingState from "./v2/temporaryListingLink";
import temporaryLinkCardsState from "./v2/temporaryLinkUserCards";
import temporaryLinkPaymentsState from "./v2/temporaryLinkPayments";
import reactivateAccountState from "./v2/reactivateAccount";
import shareProviderState from "./v2/shareProvider";
import userMfasState from "./v2/userMfas";

const rootReducer = combineReducers({
  calendar: calenderReducer,
  promotions: promotionsReducer,
  invoices: invoicesReducer,
  promoCodes: promoCodesReducer,
  customizer,
  marketingReducer,
  adsList,
  adminUsersList,
  approvedIPs,
  serviceAmenitiesList,
  amenitiesList,
  permissionsList,
  userTypesList,
  todoTypesList,
  categoriesList,
  subcategoriesList,
  auth,
  essentialLists,
  searchFilter,
  accountNotes,
  accountTodos,
  accountActivities,
  listingCounts: ListingReducer,
  accountCounts: AccountReducer,
  remindersReducer,
  sales: SalesReducer,
  activities: ActivitiesRecuder,
  notifications: NotificationReducer,
  renewalsFilter: RenewalsFilterReducer,
  trucks: UserTrucksReducer,
  createListingAccount: createListingAccountReducer,
  createListingInvoice: createListingInvoiceReducer,
  invoicesState,
  authState,
  cardsState,
  paymentsState,
  listingState,
  vendorAdvertiseListingsState,
  companyDriverProfileState,
  fleetProfileState,
  otherProfileState,
  ownerOperatorProfileState,
  createAccountState,
  socialAuthState,
  completeRegistrationState,
  loginState,
  resetPasswordState,
  updatePasswordState,
  temporaryLinkListingState,
  temporaryLinkCardsState,
  temporaryLinkPaymentsState,
  reactivateAccountState,
  shareProviderState,
  userMfasState,
});

export default rootReducer;
