import React, { useState } from "react";
import "../../../assets/scss/components/fts/chart-listing-info.scss";
import Icon from "../Icons";
import { MapPin, Phone } from "react-feather";
import ListingPreviewModal from "../listing-preview-modal";

const getListingTypeName = (priceId) => {
  if (priceId === 1) {
    return "PREMIUM";
  } else if (priceId === 2) {
    return "STANDARD";
  } else if (priceId === 3) {
    return "BASIC PLUS";
  } else if (priceId === 4) {
    return "BASIC";
  } else {
    return "FREE";
  }
};

const ChartListingInfo = ({ selectedListingInfo }) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const toggleListingPreviewModal = () => {
    setShowPreviewModal(!showPreviewModal);
  };

  return (
    <div className="FTS-chart-listing-info-wrapper">
      <div className="FTS-chart-listing-info-wrapper-top">
        <div>
          <span className="FTS-chart-listing-info-name mr-1">
            {selectedListingInfo.name}
          </span>
          <span
            className={
              selectedListingInfo.priceId < 3
                ? "FTS-chart-listing-type-red"
                : "FTS-chart-listing-type-gray"
            }
          >
            {getListingTypeName(selectedListingInfo.priceId)}
          </span>
        </div>

        <div
          className="FTS-chart-listing-info-preview"
          onClick={() => toggleListingPreviewModal()}
        >
          <Icon className="mr-1" name="Eye" size={20} color={"#00B5F4"} />
          <span>Preview Listing</span>
        </div>
      </div>

      <div className="FTS-chart-listing-info-wrapper-bottom">
        <span className="mr-1">
          <MapPin className="mr-1" size={20} />
          {selectedListingInfo.city}, {selectedListingInfo.state}
        </span>

        <span>
          {" "}
          <Phone size={20} /> {selectedListingInfo.phoneNumber}
        </span>
      </div>

      {showPreviewModal && (
        <ListingPreviewModal
          modalTitle={"Preview listing"}
          isOpen={showPreviewModal}
          toggleListingPreviewModal={toggleListingPreviewModal}
          listingData={selectedListingInfo}
          // accountData={this.state.accountData}
          // originalWorkingHours={this.state.originalWorkingHours}
        />
      )}
    </div>
  );
};

export default ChartListingInfo;
