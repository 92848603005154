import React from "react";
import { useSelector } from "react-redux";
import {
  selectTemporaryLinkEditListingData,
  selectTemporaryLinkListingWorkingHours,
} from "../../../../../redux/reducers/v2/temporaryListingLink";
import { ListingWorkingHoursFields } from "../../components/ListingWorkingHoursFields";
import { setTemporaryLinkEditListingData } from "../../../../../redux/actions/v2/temporaryLinkListing";

export const TemporaryLinkListingWorkingHoursRow = () => {
  const listingData = useSelector(selectTemporaryLinkEditListingData);
  const listingWorkingHours = useSelector(
    selectTemporaryLinkListingWorkingHours,
  );

  return (
    <ListingWorkingHoursFields
      listingData={listingData || {}}
      setListingData={setTemporaryLinkEditListingData}
      listingWorkingHours={listingWorkingHours || []}
    />
  );
};
