import React from "react";
import Icon from "../Icons";
import formatShortHours from "../../../utility/formatShortHours";
import { differenceInMinutes, format, isBefore } from "date-fns";
import { checkSkipWorkingHours } from "../../../utility/checkSkipWorkingHours";

const WorkingHoursIcon = ({
  listingCurrentTime,
  specialHours,
  mobileRoadServiceOpen,
  towingRecoveryOpen,
  workingHours,
  alwaysOpen,
}) => {
  if (alwaysOpen) {
    return <Icon size={16} name="HoursOpen" />;
  }

  workingHours = workingHours || {};
  const day = format(new Date(), "EEEE").toLowerCase();
  const currentHours = !alwaysOpen && workingHours[day];
  const hoursSkipped = checkSkipWorkingHours(workingHours);
  if (!currentHours) return null;

  const isClosed =
    currentHours.trim() === "-" ||
    currentHours.toLowerCase().trim() === "closed -" ||
    currentHours.toLowerCase() === "closed";

  const hasSpecialHours =
    specialHours || mobileRoadServiceOpen || towingRecoveryOpen;

  let currentDate = format(new Date(), "P");
  let currentDateEnd = format(new Date(), "P");

  const [openHours, closeHours] = currentHours.split("-");
  const formatOpenHours = formatShortHours(openHours);
  const formatCloseHours = formatShortHours(closeHours);
  const isAM = (str) => str.indexOf("AM") !== -1;

  const isCloseBeforeStart = isBefore(
    new Date(`${currentDate} ${formatCloseHours}`),
    new Date(`${currentDate} ${formatOpenHours}`),
  );

  const hoursClosed = isBefore(
    new Date(listingCurrentTime),
    new Date(`${currentDate} ${formatOpenHours}`),
  );

  if (isAM(formatCloseHours) && isCloseBeforeStart) {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    currentDateEnd = format(date, "P");
  }

  const isTimeAfterOpenHours = isBefore(
    new Date(`${currentDate} ${formatOpenHours}`),
    new Date(listingCurrentTime),
  );
  const isTimeBeforeClosedHours = isBefore(
    new Date(listingCurrentTime),
    new Date(`${currentDateEnd} ${formatCloseHours}`),
  );

  const isDayAdded = isBefore(
    new Date(`${currentDate}`),
    new Date(`${currentDateEnd}`),
  );

  if (isTimeAfterOpenHours && isTimeBeforeClosedHours) {
    const hoursDiffrence = differenceInMinutes(
      new Date(`${currentDateEnd} ${formatCloseHours}`),
      new Date(listingCurrentTime),
    );

    const isClosingSoon = hoursDiffrence <= 60;

    if (isClosingSoon && !hasSpecialHours)
      return <Icon size={16} name="HoursClosingSoon" />;
  }

  if (isTimeAfterOpenHours && isDayAdded) {
    return <Icon size={16} name="HoursOpen" />;
  }

  if (isTimeAfterOpenHours) {
    const hoursDiffrence = differenceInMinutes(
      new Date(`${currentDateEnd} ${formatCloseHours}`),
      new Date(listingCurrentTime),
    );

    const closed = hoursDiffrence <= 0;

    if (closed && !hasSpecialHours) {
      return <Icon size={16} name="HoursClosed" />;
    }
  }

  if (hoursSkipped) {
    return <Icon size={16} name="HoursClosed" />;
  }

  if (hasSpecialHours) {
    return <Icon size={16} name="HoursOpen" />;
  }

  if (isClosed && !hasSpecialHours) {
    return <Icon size={16} name="HoursClosed" />;
  }

  if (hoursClosed) {
    return <Icon size={16} name="HoursClosed" />;
  }

  if (currentHours.toLowerCase().trim().startsWith("24 hrs"))
    return <Icon size={16} name="HoursOpen" />;

  if (currentHours.toLowerCase().trim() === "by appointment -")
    return <Icon size={16} name="HoursOpen" />;

  return <Icon size={16} name="HoursOpen" />;
};

export default WorkingHoursIcon;
