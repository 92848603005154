import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { useHistory } from "react-router-dom";

const UserDisabled = () => {
  const history = useHistory();
  return (
    <FtsWrapper mt="4xl">
      <Error
        title={"Account Deactivated"}
        subtitle={
          "Your account has been disabled. To regain access, please contact Find Truck Service for more information."
        }
        iconName="AlertOctagon"
        outlinedButton={{
          label: "Contact Find Truck Service",
          color: ButtonColorsValue.accent,
          onClick: () => {
            history.push("/contact");
          },
        }}
      />
    </FtsWrapper>
  );
};
export default UserDisabled;
