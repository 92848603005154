import { createSelector } from "reselect";

export const SET_LOGIN_DATA = "LOGIN/SET_LOGIN_DATA";
export const LOGIN_REQUEST = "LOGIN/LOGIN_REQUEST";
export const LOGIN_FAILED = "LOGIN/LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN/LOGIN_SUCCESS";
export const SET_LOGIN_INVALID_FORM_FIELDS =
  "LOGIN/SET_LOGIN_INVALID_FORM_FIELDS";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  inlineMessage: null,
  invalidFormFields: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOGIN_DATA:
      return {
        ...state,
        invalidFormFields: null,
        data: { ...state.data, ...action.payload.data },
      };

    case SET_LOGIN_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: action.payload.data,
      };

    case LOGIN_REQUEST:
      return { ...state, isLoading: true };

    case LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data.message,
        inlineMessage: action.payload.data.inlineMessage,
      };

    case LOGIN_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
}

const selectLoginState = (state) => state.loginState;

export const selectLoginData = createSelector(
  selectLoginState,
  (loginState) => loginState.data,
);

export const selectLoadingIsLoading = createSelector(
  selectLoginState,
  (loginState) => loginState.isLoading,
);

export const selectLoginError = createSelector(
  selectLoginState,
  (loginState) => loginState.error,
);

export const selectLoginInlineMessage = createSelector(
  selectLoginState,
  (loginState) => loginState.inlineMessage,
);

export const selectLoginInvalidFormFields = createSelector(
  selectLoginState,
  (loginState) => loginState.invalidFormFields,
);
