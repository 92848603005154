import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { history } from "../../../history";
import { extractParameter } from "../../../utility/param-extract";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import GetListedStepperIndicator from "../../../components/fts-components-v2/GetListedStepperIndicator";
import { ListingImagesMobile } from "../../../components/fts-components-v2/ListingBusinessInfo/components/ListingImagesMobile";
import { ListingImagesMobileFooter } from "../../../components/fts-components-v2/ListingBusinessInfo/CreateListing/Components/ListingImagesMobileFooter";
import {
  selectHasListingData,
  selectListingAdsAssets,
  selectListingAdsAssetsLength,
  selectListingData,
} from "../../../redux/reducers/v2/listing";
import {
  setListingData,
  setListingInvalidFormFields,
  uploadAdsAssetsRequest,
} from "../../../redux/actions/v2/listing";

const ListingImages = () => {
  const listingData = useSelector(selectListingData);
  const adsAssets = useSelector(selectListingAdsAssets);
  const hasListingData = useSelector(selectHasListingData);
  const adsAssetsSize = useSelector(selectListingAdsAssetsLength);

  function navigateToBusinessDetails() {
    const priceId = extractParameter("selectedListingPriceId");
    history.replace({
      pathname: `/dashboard/create-listing/business-details/`,
      search: priceId ? `?selectedListingPriceId=${priceId}` : "",
    });
  }

  useEffect(() => {
    if (!hasListingData) navigateToBusinessDetails();
  }, [hasListingData]);

  return (
    <FtsWrapper
      height={"100vh"}
      direction={"column"}
      display={{ xs: "flex", sm: "flex", md: "none", lg: "none" }}
    >
      <FtsWrapper display={"flex"} direction={"column"}>
        <GetListedStepperIndicator activeStepId={2} />
        <ListingImagesMobile
          adsAssets={adsAssets}
          listingData={listingData}
          adsAssetsSize={adsAssetsSize}
          setListingData={setListingData}
          uploadAdsAssetsRequest={uploadAdsAssetsRequest}
          setListingInvalidFormFields={setListingInvalidFormFields}
        />
      </FtsWrapper>
      <ListingImagesMobileFooter />
    </FtsWrapper>
  );
};

export default ListingImages;
