import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { BannerTypes } from "@find-truck-service/types/ui/banner";
const BannerIconWrapperBackgroundColor = {
    [BannerTypes.info]: ColorsValue["blue-accent-500"],
    [BannerTypes.success]: ColorsValue["green-success-500"],
    [BannerTypes.warning]: ColorsValue["yellow-warning-400"],
    [BannerTypes.error]: ColorsValue["red-error-500"],
};
export const BannerIconWrapper = styled(FtsWrapper).withConfig({
    shouldForwardProp: (prop) => prop !== "type",
}) `
  background-color: ${(props) => BannerIconWrapperBackgroundColor[props.type]};
  border-radius: ${Spacing["4xxl"]};
  padding: ${Spacing["2xs"]};

  svg {
    width: ${Spacing.xl};
    height: ${Spacing.xl};

    path {
      stroke: ${ColorsValue["greyscale-0"]};
    }
  }
`;
