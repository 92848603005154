import React, { PureComponent } from "react";
import { CustomInput, Col, Row } from "reactstrap";
import classnames from "classnames";
import { FastField } from "formik";

import FromTo from "../../../../components/fts/formik/hour-input";
import SpecialHours from "./SpecialHours";
import Divider from "../../../../components/fts/Divider";

class WorkingHours extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { editMode, weekdays, preview } = this.props;
    const additionalProps = !editMode ? { disabled: true } : {};
    const colCount = editMode ? 1 : 2;
    const weekdaysCopy = [...weekdays];
    const weekdayRows = [];

    while (weekdaysCopy.length > 0) {
      weekdayRows.push(weekdaysCopy.splice(0, colCount));
    }

    const Open24hrsSwitch = ({ value, name, form }) => (
      <Row>
        <Col>
          <>
            <CustomInput
              className="mt-1 mb-2"
              checked={value?.open24hrs}
              type="switch"
              id={name}
              inline
              onChange={(e) => {
                form.setFieldValue(`${name}.open24hrs`, e.target.checked);
              }}
              {...additionalProps}
            >
              <span className="ml-1">Are you open 24 hours?</span>
            </CustomInput>
          </>
        </Col>
      </Row>
    );

    const WorkHours = ({ value, name, form, meta }) => (
      <div className={`${preview ? "working-hours-switch" : null}`}>
        <Divider textColor="primary" position="left">
          Work hours
        </Divider>
        {(editMode || value?.open24hrs) && (
          <Open24hrsSwitch {...{ value, name, form }} />
        )}
        {!value?.open24hrs ? (
          <div className={classnames("mb-2", { "mt-1": editMode })}>
            {weekdayRows.map((weekdays, outerIndex) => {
              return editMode ? (
                <React.Fragment key={outerIndex}>
                  {weekdays.map((weekday, index) => {
                    return (
                      <FromTo
                        key={index}
                        weekday={weekday}
                        {...{
                          editMode,
                          value,
                          name,
                          form,
                          meta,
                        }}
                      />
                    );
                  })}
                </React.Fragment>
              ) : (
                <Row key={outerIndex}>
                  {weekdays.map((weekday, index) => {
                    return (
                      <Col key={index} xs="12">
                        <FromTo
                          weekday={weekday}
                          {...{
                            editMode,
                            value,
                            name,
                            form,
                            meta,
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </div>
        ) : null}
      </div>
    );

    return (
      <React.Fragment>
        <FastField name={"workingHours"} {...additionalProps}>
          {({ meta, field: { value, name }, form }) => {
            return !value?.open24hrs ? (
              <Row>
                <Col
                  md={editMode ? "12" : "12"}
                  lg={editMode ? "8" : "6"}
                  // xl={editMode ? "7" : "6"}
                >
                  <WorkHours {...{ value, name, form, meta }} />
                </Col>
                <Col
                  md={editMode ? "12" : "12"}
                  lg={editMode ? "4" : "6"}
                  // xl={editMode ? "5" : "6"}
                >
                  <SpecialHours {...{ editMode }} />
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col
                    md={editMode ? "12" : "12"}
                    lg={editMode ? "12" : "6"}
                    xl={editMode ? "12" : "6"}
                  >
                    <WorkHours {...{ value, name, form, meta }} />
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={editMode ? "12" : "12"}
                    lg={editMode ? "12" : "6"}
                    xl={editMode ? "12" : "6"}
                  >
                    <SpecialHours
                      open24hrs={value?.open24hrs}
                      {...{ editMode }}
                    />
                  </Col>
                </Row>
              </>
            );
          }}
        </FastField>
      </React.Fragment>
    );
  }
}
export default WorkingHours;
