import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import "./revenue-chart.scss";
import {
  // generateLabelsForChart,
  getDataForRevenueChart,
  getCustomRangeLabels,
} from "../../../utility/chart";
import ChartLegendItem from "../chart-legend-item";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const formatRevenueValue = (string) => {
  return string.replace(/: /g, ": $");
};

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "white";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "#32383A";
    tooltipEl.style.border = "1px solid #E5E8E9";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element

  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => {
      return [formatRevenueValue(b?.lines[0])];
    });

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      span.style.background = colors.borderColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginRight = "8px";
      span.style.height = "12px";
      span.style.width = "12px";
      span.style.display = "inline-block";
      span.style.borderRadius = "2px";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  let tooltipLeftPosition = positionX + tooltip.caretX + 25 + "px";
  let tooltipTopPosition = positionY + tooltip.caretY / 2;
  let isMobile = window.innerWidth < 900;

  const infoCardsWidth =
    document.getElementsByClassName("infoCards")[0].offsetWidth;

  if (
    tooltip.caretX + positionX >
    chart.chartArea.right - 120 + infoCardsWidth
  ) {
    tooltipLeftPosition =
      tooltip.caretX + infoCardsWidth - tooltip.width + "px";
  }

  if (isMobile) {
    tooltipLeftPosition = 100 + "px";
    tooltipTopPosition -= 250;
  }

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = tooltipLeftPosition;
  tooltipEl.style.top = tooltipTopPosition + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

const hoverBackgroundEffect = {
  id: "hoverBackgroundEffect",
  beforeDatasetsDraw(chart) {
    const {
      ctx,
      tooltip,
      chartArea: { top, height },
      // scales: { x, y },
    } = chart;
    const isTooltipActive = tooltip._active.length;

    if (isTooltipActive) {
      const isFirstElement = tooltip._active[0].index === 0;
      let bgWidth = isFirstElement ? 20 : 40;
      let offset = isFirstElement ? 0 : 20;

      const startingPoint = tooltip.caretX - offset;
      ctx.fillStyle = "#e6f8fe80";
      ctx.fillRect(startingPoint, top, bgWidth, height);
    }
  },
};

const options = {
  scales: {
    x: {
      grid: {
        borderDash: [4, 8],
        drawBorder: false,
      },
    },
    y: {
      grid: {
        borderDash: [4, 8],
        drawBorder: false,
      },
      ticks: {
        precision: 0,
      },
      beginAtZero: true,
      precision: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: "nearest",
      external: externalTooltipHandler,
    },
  },
  elements: {
    point: {
      // CAREFUL: THIS AFFECTS TOOLTIP STYLING: Line: 77 onwards
      pointBackgroundColor: "white",
      pointHoverBackgroundColor: "white",
      pointLabelFontSize: 20,
      pointRadius: 0.1,
      pointHoverRadius: 6,
    },
  },
  maintainAspectRatio: false,
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
};

const plugins = [hoverBackgroundEffect];

const RevenueChart = ({
  data: calls,
  revenue,
  myTicket,
  reportFrom,
  reportTo,
  infoCards,
  refChart,
}) => {
  const labels = getCustomRangeLabels(reportFrom, reportTo);
  const data = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: getDataForRevenueChart(calls, myTicket, labels),
        backgroundColor: "#00b5f4",
        borderColor: "#00b5f4",
        borderWidth: 2,
        hidden: myTicket ? false : true,
      },
      {
        label: "Towing and Recovery",
        data: getDataForRevenueChart(
          calls,
          revenue["Towing and Recovery"],
          labels
        ),
        backgroundColor: "#E7C5A9",
        borderColor: "#E7C5A9",
        borderWidth: 1.5,
      },
      {
        label: "Mobile Repair",
        data: getDataForRevenueChart(calls, revenue["Mobile Repair"], labels),
        backgroundColor: "#EB9797",
        borderColor: "#EB9797",
        borderWidth: 1.5,
      },

      {
        label: "Repair Shops",
        data: getDataForRevenueChart(calls, revenue["Repair Shops"], labels),
        backgroundColor: "#BDA3DF",
        borderColor: "#BDA3DF",
        borderWidth: 1.5,
      },
    ],
  };

  return (
    <>
      <div className={"chartWrapper"}>
        <div className={"chart"}>
          <Line
            data={data}
            options={options}
            plugins={plugins}
            ref={(e) => (refChart.current = e)}
          />
        </div>
      </div>
      <div className="FTS-revenue-chart-legend">
        {infoCards.map((infoCard, i) => {
          return (
            <ChartLegendItem
              key={i}
              item={infoCard}
              refChart={refChart}
              index={myTicket ? i : i + 1}
            />
          );
        })}
      </div>
    </>
  );
};

export default RevenueChart;
