import axios from "axios";
import appConfig from "../configs/appConfig";
import { handleUnauthorized } from "./handleUnauthorized";
import { sentryErrorHandler } from "./sentryErrorHandler";
import { TEMPORARY_TOKEN } from "../cookies";
import { rfc3986DecodeURIComponent } from "../utility/encode-rfc";

const instance = axios.create({ baseURL: appConfig.v2ApiURL });
const TIMEOUT_SEC = 30;

instance.interceptors.request.use(
  async (req) => {
    const temporaryToken = sessionStorage.getItem("temporaryToken");

    if (temporaryToken)
      req.headers = {
        ...req.headers,
        [TEMPORARY_TOKEN]: temporaryToken,
      };

    req.timeout = TIMEOUT_SEC * 1000;
    return req;
  },
  async (error) => Promise.reject(error),
);

instance.interceptors.response.use((response) => {
  if (response.data) {
    response.data = rfc3986DecodeURIComponent(response.data);
  }
  return handleUnauthorized(response);
}, sentryErrorHandler);

export default instance;
