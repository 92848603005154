import React from "react";

import "../../../assets/scss/components/fts/fts-sticky-payment-box.scss";
import PromoCodeInputFieldTemporary from "../promo-code-input-field-temporary";
import Icon from "../../../components/fts/Icons";
import { Button } from "reactstrap";

export default class StickyPaymentBox extends React.Component {
  state = {
    collapsed: true,
  };

  toggle = () => {
    this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
  };

  render() {
    const {
      promoCodeApplied,
      onApplyPromoCode,
      setPromoCodeErrorMessage,
      promoCodeErrorMessage,
      promoCode,
      discountAmount,
      subTotal,
      appliedCreditAmount,
      total,
      totalAmount,
      isCreditApplied,
      setIsOpenPaymentModal,
      formatMoney,
      invoicesCount,
      selectedInvoicesCount,
      setPromoCode,
      setDiscountForSelectedInvoices,
      setPromoCodeApplied,
    } = this.props;
    const { collapsed } = this.state;

    return (
      <>
        {!collapsed && window.innerWidth < 1271 && (
          <div className="relative">
            <div
              className="fts-sticky-payment-box-backdrop"
              onClick={() => {
                this.toggle();
              }}
            />
          </div>
        )}
        <div className="fts-sticky-payment-box">
          <div className="fts-sticky-payment-box-body">
            <div className="fts-sticky-payment-box-body-heading">
              <span>
                <div className="fts-sticky-payment-box-body-title">
                  Total to pay
                </div>
                <div className="fts-sticky-payment-box-body-counters">
                  {selectedInvoicesCount} of {invoicesCount} listings
                </div>
              </span>

              <div
                onClick={() => {
                  this.toggle();
                }}
                className="fts-sticky-payment-box-chevron"
                style={{
                  transform: collapsed ? "rotate(0deg)" : "rotate(-180deg)",
                }}
              >
                <Icon name="ChevronUp" size={24} />
              </div>
            </div>

            {(!collapsed || window.innerWidth > 1270) && (
              <div className="fts-sticky-payment-box-body-calculate">
                <div className="d-flex flex-column fts-sticky-payment-box-body-calculate-input">
                  <PromoCodeInputFieldTemporary
                    promoCode={promoCode}
                    applied={promoCodeApplied}
                    onApplyPromoCode={onApplyPromoCode}
                    setPromoCodeErrorMessage={setPromoCodeErrorMessage}
                    setPromoCode={setPromoCode}
                    setDiscountForSelectedInvoices={
                      setDiscountForSelectedInvoices
                    }
                    setPromoCodeApplied={setPromoCodeApplied}
                  />
                  <div className="promo-code-error-message">
                    {promoCodeErrorMessage}
                  </div>
                </div>
                <div className="invoice-overview-footer">
                  <span className="payment-total-box">
                    {promoCode?.id && discountAmount > 0 && (
                      <div className="pb-1 subtotal fts-sticky-payment-box-body-calculate-row">
                        <span>Subtotal: </span>
                        <span className="total">${formatMoney(subTotal)}</span>
                      </div>
                    )}
                    {discountAmount > 0 && (
                      <div className="pb-1 promo-code-applied fts-sticky-payment-box-body-calculate-row">
                        <span>Promo Code: </span>
                        <span className="total">
                          ${formatMoney(discountAmount)}
                        </span>
                      </div>
                    )}

                    {appliedCreditAmount > 0 && isCreditApplied && (
                      <div
                        style={{
                          marginBottom: "1rem",
                        }}
                        className="fts-sticky-payment-box-body-calculate-row"
                      >
                        <span>
                          Applied account credit <b>: </b>
                        </span>
                        <b className="total">
                          ${formatMoney(appliedCreditAmount)}
                        </b>
                      </div>
                    )}
                    <div className="fts-sticky-payment-box-body-calculate-row">
                      <span>Total: </span>
                      <span className="total">
                        {discountAmount > 0 || appliedCreditAmount > 0
                          ? `$${formatMoney(totalAmount)}`
                          : `$${formatMoney(subTotal)}`}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="fts-sticky-payment-box-footer">
            <Button
              disabled={!total}
              className={"w-100 mt-2"}
              color="primary"
              onClick={() => {
                setIsOpenPaymentModal(true);
              }}
            >
              Pay $(
              {discountAmount > 0 || appliedCreditAmount > 0
                ? `${formatMoney(totalAmount)}`
                : `${formatMoney(subTotal)}`}
              )
            </Button>
          </div>
        </div>
      </>
    );
  }
}
