import React from "react";
import * as Sentry from "@sentry/react";
import { history } from "../../../history";
import { HeaderWrapper } from "../HeaderWrapper";
import { Pages } from "../../../views/constants/pages";
import { SOMETHING_WENT_WRONG } from "../../../utility/errorMessages";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";

const ErrorFallback = ({ resetError }) => {
  return (
    <>
      <HeaderWrapper />
      <FtsWrapper mt="4xl">
        <Error
          title={SOMETHING_WENT_WRONG}
          subtitle={
            "We encountered an unexpected error. Please try again later."
          }
          iconName="AlertTriangle"
          containedButton={{
            label: "Go to Dashboard",
            color: ButtonColorsValue.accent,
            onClick: () => {
              resetError();
              history.push(Pages.DASHBOARD);
            },
          }}
        />
      </FtsWrapper>
    </>
  );
};

export const ErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary
    fallback={({ resetError }) => <ErrorFallback resetError={resetError} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);
