import {
  CREATE_LISTING_SET_USER_VALUES,
  CREATE_LISTING_REGISTER_REQUEST,
  CREATE_LISTING_REGISTER_SUCCESS,
  CREATE_LISTING_REGISTER_FAILED,
  CREATE_LISTING_SET_LISTING_DATA_INVALID_FORM_FIELDS,
  CREATE_LISTING_SET_LISTING_DATA_EMAIL_ALREADY_USED,
} from "../../reducers/createListingAccount";

export const createListingSetUserValues = (payload) => ({
  type: CREATE_LISTING_SET_USER_VALUES,
  payload,
});

export const createListingAccountRequest = (payload) => ({
  type: CREATE_LISTING_REGISTER_REQUEST,
  payload,
});

export const createListingAccountSuccess = (payload) => ({
  type: CREATE_LISTING_REGISTER_SUCCESS,
  payload,
});

export const createListingAccountFailed = (payload) => ({
  type: CREATE_LISTING_REGISTER_FAILED,
  payload,
});

export const createListingAccountSetListingDataInvalidFormFields = (
  payload,
) => ({
  type: CREATE_LISTING_SET_LISTING_DATA_INVALID_FORM_FIELDS,
  payload,
});

export const createListingAccountSetEmailAlreadyUsed = (payload) => {
  return {
    type: CREATE_LISTING_SET_LISTING_DATA_EMAIL_ALREADY_USED,
    payload,
  };
};
