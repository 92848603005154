import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserName } from "../../../redux/reducers/v2/auth";
import { Header } from "@find-truck-service/ui/src/components/react/Header";
import { Drawer } from "@find-truck-service/ui/src/components/react/Drawer";

export const HeaderWrapper = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const userName = useSelector(selectUserName);

  const closeSideOut = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  const openSideOut = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  return (
    <>
      <Header userName={userName} dataLoaded openSideOut={openSideOut} />
      {isDrawerOpen && (
        <Drawer userName={userName} closeSideOut={closeSideOut} />
      )}
    </>
  );
};
