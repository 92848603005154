import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";

import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { H3 } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { isObject } from "lodash";

import {
  selectAuthIsLoading,
  selectInvalidFormFields,
  selectUserData,
} from "../../../redux/reducers/v2/auth";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import { selectStates } from "../../../redux/reducers/essentialLists";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import {
  setInvalidFormFields,
  setUserData,
  updateUserRequest,
} from "../../../redux/actions/v2/auth";
import { completeAccountInfoValidator } from "./validators";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { PhoneNumberInput } from "@find-truck-service/ui/src/components/react/PhoneNumberInput";
import { ZipCodeInput } from "@find-truck-service/ui/src/components/react/ZipCodeInput";
import { USER_TYPE } from "../../../constants";
import CompleteProfile from "../../../views/pages/authentication/client-registration/CompleteProfile";

import { history } from "../../../history";
import { extractParameter } from "../../../utility/param-extract";
import { toast } from "react-toastify";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { Pages } from "../../../views/constants/pages";
import { USER_TYPE_NAMED_ENUM } from "@find-truck-service/types/constants";

const messageComponents = {
  ["paymentSuccessfull"]: (
    <Message type={"success"} title={"Payment successful!"} />
  ),
};

export const CompleteAccountInfo = ({ selectedAccountType }) => {
  const userData = useSelector(selectUserData);
  const isLoading = useSelector(selectAuthIsLoading);
  const dispatch = useDispatch();
  const messageComponent = extractParameter("messageComponent");

  const onUpdateVendorSuccess = () => {
    if (userData?.verified) {
      return history.push(Pages.DASHBOARD);
    }

    history.push(Pages.VERIFY_EMAIL);
  };

  const onUpdateVendorFailure = (e) => {
    const message = e.response
      ? e.response.data.message
      : "Error while updating user data";

    toast.error(message);
  };

  const onSubmit = useCallback(async () => {
    console.log("USER DATA", userData);
    try {
      await completeAccountInfoValidator.validate(userData);
      dispatch(
        updateUserRequest({
          data: {
            ...userData,
            onboardingFinished: true,
            type: USER_TYPE.SERVICE_VENDOR,
            userType: USER_TYPE.SERVICE_VENDOR,
            userTypeNamed: USER_TYPE_NAMED_ENUM.VENDOR,
          },
          onSuccess: onUpdateVendorSuccess,
          onError: onUpdateVendorFailure,
        }),
      );
    } catch (e) {
      console.log("SUBMIT ERROR", e);
      if (e.name === "ValidationError") {
        const data = { [e.path]: e.errors[0] };
        dispatch(setInvalidFormFields({ data }));
      }
    }
  }, [userData]);

  if (
    userData.userType === USER_TYPE.SERVICE_VENDOR ||
    selectedAccountType === USER_TYPE.SERVICE_VENDOR
  )
    return (
      <FtsContainer>
        <FtsRow
          mt={{ xs: "0", sm: "2xl", md: "4xl", lg: "4xl" }}
          pb={{ xs: "lg", sm: "2xl", md: "4xl", lg: "4xl" }}
        >
          <FtsColumn size={{ md: 12, lg: 6 }} offset={{ md: 0, lg: 3 }}>
            {isLoading && <FullPageLoader />}
            <FtsWrapper
              bg={ColorsValue["greyscale-0"]}
              px={{ xs: "xl", sm: "4xl", md: "4xl", lg: "4xl" }}
              py={{ xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" }}
              borderRadius="2xs"
              boxShadow="sm"
              gap={"4xl"}
              display={"flex"}
              direction="column"
            >
              {messageComponent && messageComponents[messageComponent]}
              <FtsWrapper>
                <H3
                  variant={VariantsValues["text-md"]}
                  color={ColorsValue["greyscale-800"]}
                  weight={WeightSize.bold}
                >
                  Vendor Info
                </H3>
              </FtsWrapper>

              <CompleteProfileVendorForm />

              <Button
                onClick={onSubmit}
                width="100%"
                size={ButtonSizes.md}
                color={ButtonColorsValue.accent}
                type={ButtonTypes.contained}
                label={"Save"}
              />
            </FtsWrapper>
          </FtsColumn>
        </FtsRow>
      </FtsContainer>
    );

  return (
    <CompleteProfile
      chosenAccount={userData.userType}
      redirectToWebsite={() => {
        const redirect = extractParameter("redirect");
        const openModal = extractParameter("openModal");
        const listingId = extractParameter("listingId");
        const search = window.location.search;

        if (redirect) {
          if (redirect.startsWith("/search")) {
            let searchIndex = search.indexOf("/search");
            let openmodalIndex = search.indexOf("&openModal");
            let substring = search.substring(searchIndex, openmodalIndex);
            return () => {
              history.push({
                pathname: `${substring}&openModal=${openModal}&listingId=${listingId}`,
              });
            };
          } else {
            return () => {
              history.push({
                pathname: redirect,
                search: `openModal=${openModal}&listingId=${listingId}`,
              });
            };
          }
        }
      }}
    />
  );
};

const CompleteProfileVendorForm = () => {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const states = useSelector(selectStates);
  const currentYear = new Date().getFullYear();
  const invalidFormFields = useSelector(selectInvalidFormFields);
  let years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) => ({
    label: currentYear - i,
    value: currentYear - i,
  }));

  const onChange = useCallback(
    (event) => {
      dispatch(
        setUserData({
          data: { ...userData, [event.target.name]: event.target.value },
        }),
      );
    },
    [userData],
  );

  return (
    <>
      <FtsRow
        columnGap={{ xs: "lg", sm: "lg", md: "lg", lg: "lg" }}
        rowGap={{ xs: "2xl", sm: "2xl", md: "2xl", lg: "2xl" }}
      >
        <FtsColumn size={{ xs: 12 }}>
          <Input
            name={"companyName"}
            required
            value={userData?.companyName}
            onChange={onChange}
            label={"Company name"}
            variant={InputVariants.outlined}
            size={InputSizes.md}
            errorMessage={
              isObject(invalidFormFields) && invalidFormFields["companyName"]
            }
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }}>
          <Input
            name={"address"}
            value={userData?.address}
            onChange={onChange}
            required
            label={"Mailing Address"}
            variant={InputVariants.outlined}
            size={InputSizes.md}
            errorMessage={
              isObject(invalidFormFields) && invalidFormFields["address"]
            }
          />
        </FtsColumn>

        <FtsColumn size={{ xs: 12, md: 6, sm: 6, lg: 6 }}>
          <Input
            name={"city"}
            value={userData?.city}
            onChange={onChange}
            required
            label={"City"}
            variant={InputVariants.outlined}
            size={InputSizes.md}
            errorMessage={
              isObject(invalidFormFields) && invalidFormFields["city"]
            }
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12, md: 6, sm: 6, lg: 6 }}>
          <FtsRow
            columnGap={{ xs: "lg", sm: "lg", md: "lg", lg: "lg" }}
            rowGap={{ xs: "2xl", sm: "2xl", md: "2xl", lg: "2xl" }}
          >
            <FtsColumn size={{ xs: 6 }}>
              <Dropdown
                required
                size={DropdownSizesValues.md}
                variant={DropdownVariantsValues.outlined}
                name={"state"}
                label={"State"}
                options={states}
                placeholder={""}
                value={states?.find((e) => e?.label === userData?.state)}
                errorMessage={
                  isObject(invalidFormFields) && invalidFormFields["state"]
                }
                onChange={(val) =>
                  onChange({
                    target: { name: "state", value: val?.label || "" },
                  })
                }
              />
            </FtsColumn>
            <FtsColumn size={{ xs: 6 }}>
              <ZipCodeInput
                name={"zip"}
                value={userData?.zip}
                onChange={onChange}
                required
                label={"Zip code"}
                variant={InputVariants.outlined}
                size={InputSizes.md}
                errorMessage={
                  isObject(invalidFormFields) && invalidFormFields["zip"]
                }
              />
            </FtsColumn>
          </FtsRow>
        </FtsColumn>

        <FtsColumn size={{ xs: 12, md: 6, sm: 6, lg: 6 }}>
          <PhoneNumberInput
            name={"phoneNumber"}
            value={userData?.phoneNumber}
            onChange={onChange}
            required
            label={"Phone (primary)"}
            variant={InputVariants.outlined}
            size={InputSizes.md}
            errorMessage={
              isObject(invalidFormFields) && invalidFormFields["phoneNumber"]
            }
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12, md: 6, sm: 6, lg: 6 }}>
          <PhoneNumberInput
            name={"phoneNumberSecondary"}
            value={userData?.phoneNumberSecondary}
            onChange={onChange}
            label={"Phone (secondary)"}
            variant={InputVariants.outlined}
            size={InputSizes.md}
            errorMessage={
              isObject(invalidFormFields) &&
              invalidFormFields["phoneNumberSecondary"]
            }
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12, md: 6, sm: 6, lg: 6 }}>
          <Input
            name={"jobTitle"}
            value={userData?.jobTitle}
            onChange={onChange}
            required
            label={"Job title"}
            variant={InputVariants.outlined}
            size={InputSizes.md}
            errorMessage={
              isObject(invalidFormFields) && invalidFormFields["jobTitle"]
            }
          />
        </FtsColumn>

        <FtsColumn size={{ xs: 12, md: 6, sm: 6, lg: 6 }}>
          <PhoneNumberInput
            name={"mobilePhone"}
            value={userData?.mobilePhone}
            onChange={onChange}
            label={"Mobile"}
            variant={InputVariants.outlined}
            size={InputSizes.md}
            errorMessage={
              isObject(invalidFormFields) && invalidFormFields["mobilePhone"]
            }
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12, md: 6, sm: 6, lg: 6 }}>
          <Dropdown
            menuPlacement={"top"}
            required
            size={DropdownSizesValues.md}
            variant={DropdownVariantsValues.outlined}
            name={"yearsBusiness"}
            label={"In business since?"}
            options={years}
            placeholder={""}
            value={years.find(
              (e) => e.label.toString() === userData.yearsBusiness,
            )}
            errorMessage={
              invalidFormFields && invalidFormFields["yearsBusiness"]
            }
            onChange={(val) =>
              onChange({
                target: { name: "yearsBusiness", value: val?.label || "" },
              })
            }
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12, md: 6, sm: 6, lg: 6 }}>
          <Input
            name={"referal"}
            value={userData?.referal}
            onChange={onChange}
            required
            label={"How did you hear about us?"}
            variant={InputVariants.outlined}
            size={InputSizes.md}
            errorMessage={
              isObject(invalidFormFields) && invalidFormFields["referal"]
            }
          />
        </FtsColumn>
      </FtsRow>
    </>
  );
};
