import React, { useEffect, useState } from "react";
import { CenteredLayout } from "../Layouts/CenteredLayout";
import { useDispatch, useSelector } from "react-redux";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { H3 } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

import { SelectableBox } from "@find-truck-service/ui/src/components/react/SelectableBox/SelectableBox";
import {
  selectAccountTypes,
  selectAuthIsLoading,
} from "../../../redux/reducers/v2/auth";
import { getUserTypesRequest } from "../../../redux/actions/v2/auth";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { history } from "../../../history";

export const ChooseAccountType = () => {
  const accountTypes = useSelector(selectAccountTypes);
  const isLoading = useSelector(selectAuthIsLoading);
  const dispatch = useDispatch();
  const [selectedAccountType, setSelectedAccountType] = useState(null);

  useEffect(() => {
    dispatch(getUserTypesRequest());
  }, [dispatch]);

  if (!accountTypes) {
    return <EmptyComponent />;
  }

  const handleSelectAccountType = (id) => {
    setSelectedAccountType(id);
  };

  const handleChooseAccountType = () => {
    history.push(`/dashboard/register?type=${selectedAccountType}`);
  };
  return (
    <CenteredLayout>
      {isLoading && <FullPageLoader />}
      <FtsWrapper
        bg={ColorsValue["greyscale-0"]}
        px={{ xs: "xl", sm: "4xl", md: "4xl", lg: "4xl" }}
        py={{ xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" }}
        borderRadius="2xs"
        boxShadow="sm"
        gap={"4xl"}
        display={"flex"}
        direction="column"
      >
        <FtsWrapper>
          <H3
            variant={VariantsValues["text-md"]}
            color={ColorsValue["greyscale-800"]}
            weight={WeightSize.bold}
          >
            Account Info
          </H3>
          <H3
            variant={VariantsValues["text-2xs"]}
            color={ColorsValue["greyscale-800"]}
          >
            Select what best describes your operation
          </H3>
        </FtsWrapper>
        <FtsWrapper display={"flex"} direction="column" gap={"lg"}>
          {accountTypes?.map((accountType, index) => (
            <SelectableBox
              id={accountType?.id}
              setSelectedAccountType={handleSelectAccountType}
              isSelected={accountType?.id === selectedAccountType}
              key={index}
              title={accountType?.categoryName}
              subtitle={accountType?.description}
            />
          ))}
        </FtsWrapper>
        <Button
          onClick={handleChooseAccountType}
          width="100%"
          size={ButtonSizes.md}
          color={ButtonColorsValue.accent}
          type={ButtonTypes.contained}
          label={"Continue"}
        />
      </FtsWrapper>
    </CenteredLayout>
  );
};
