import { createSelector } from "reselect";

export const OTHER_PROFILE_SET_FIELD_VALUES = "OTHER_PROFILE/SET_FIELD_VALUES";
export const OTHER_PROFILE_UPDATE_REQUEST = "OTHER_PROFILE/UPDATE_REQUEST";
export const OTHER_PROFILE_UPDATE_SUCCESS = "OTHER_PROFILE/UPDATE_SUCCESS";
export const OTHER_PROFILE_UPDATE_FAILED = "OTHER_PROFILE/UPDATE_FAILED";
export const OTHER_PROFILE_SET_INVALID_FORM_FIELDS =
  "OTHER_PROFILE/SET_INVALID_FORM_FIELDS";
export const OTHER_PROFILE_SET_IS_LOADING = "OTHER_PROFILE/SET_IS_LOADING";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  invalidFormFields: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OTHER_PROFILE_SET_FIELD_VALUES:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    case OTHER_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        invalidFormFields: null,
      };

    case OTHER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        error: null,
        invalidFormFields: null,
        updateLoading: false,
        finishLaterLoading: false,
      };
    case OTHER_PROFILE_UPDATE_FAILED:
      return {
        ...state,
        error: action.payload,
        invalidFormFields: null,
        updateLoading: false,
        finishLaterLoading: false,
      };

    case OTHER_PROFILE_SET_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: action.payload.data,
      };

    case OTHER_PROFILE_SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.data,
      };

    default:
      return state;
  }
}

const selectOtherProfileState = (state) => state.otherProfileState;

export const selectOtherProfileData = createSelector(
  selectOtherProfileState,
  (otherProfile) => {
    return otherProfile.data;
  },
);

export const selectOtherProfileInvalidFormFields = createSelector(
  selectOtherProfileState,
  (otherProfile) => {
    return otherProfile.invalidFormFields;
  },
);

export const selectOtherProfileUpdateLoading = createSelector(
  selectOtherProfileState,
  (otherProfile) => {
    return otherProfile.isLoading;
  },
);
