import React from "react";
import { Span } from "../../../FtsText";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { BannerButtons } from "./components/BannerButtons";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { IconNames } from "../../../FtsIcons/Types/iconNames";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { FtsBanner } from "@find-truck-service/ui/src/FtsBanner/FtsBanner";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { BannerTypes } from "@find-truck-service/types/ui/banner";
import { BannerIconWrapper } from "@find-truck-service/ui/src/FtsBanner/BannerIconWrapper";
import { BannerTitleHandler } from "@find-truck-service/ui/src/FtsBanner/BannerTitleHandler";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
const BannerIcon = {
    [BannerTypes.info]: "Info",
    [BannerTypes.success]: "Check",
    [BannerTypes.warning]: "AlertTriangle",
    [BannerTypes.error]: "AlertOctagon",
};
export const Banner = (props) => {
    const { type, title, onClose, outlinedButtonIcon, outlinedButtonLabel, containedButtonIcon, containedButtonLabel, onOutlinedButtonClick, onContainedButtonClick, ...defaultProps } = props;
    if (!type || !title)
        return React.createElement(EmptyComponent, null);
    const withButtons = !!onOutlinedButtonClick || !!onContainedButtonClick;
    return (React.createElement(FtsBanner, { type: type, ...defaultProps },
        React.createElement(FtsWrapper, { gap: "lg", display: "flex", align: "center", flex: 1, justify: "space-between" },
            React.createElement(BannerTitleHandler, { withButtons: withButtons },
                React.createElement(BannerIconWrapper, { type: type },
                    React.createElement(FtsIcons, { iconName: BannerIcon[type] })),
                React.createElement(FtsWrapper, null,
                    React.createElement(Span, { variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-800"] }, title))),
            !!onClose && (React.createElement(Button, { onClick: onClose, size: ButtonSizes.sm, type: ButtonTypes.text, iconName: IconNames.Close, color: ButtonColorsValue.neutral, display: { xs: "flex", sm: "none", md: "none", lg: "none" } }))),
        React.createElement(BannerButtons, { type: type, onClose: onClose, withButtons: withButtons, outlinedButtonIcon: outlinedButtonIcon, containedButtonIcon: containedButtonIcon, outlinedButtonLabel: outlinedButtonLabel, containedButtonLabel: containedButtonLabel, onOutlinedButtonClick: onOutlinedButtonClick, onContainedButtonClick: onContainedButtonClick })));
};
