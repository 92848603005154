import React from "react";

import Icon from "../Icons";
import { Button } from "reactstrap";
import { history } from "../../../history";
import "../../../assets/scss/components/fts/navigation-create-listing-btn.scss";

class NavigationCreateListingBtn extends React.Component {
  render() {
    return (
      <div className="navigation-create-listing-btn">
        <Button
          color="white"
          onClick={() => {
            document.body.style.overflow = "unset";
            history.push("/dashboard/vendor-advertise-listings");
          }}
        >
          <Icon name="Plus" />
          <div>Create Listing</div>
        </Button>
      </div>
    );
  }
}

export default NavigationCreateListingBtn;
