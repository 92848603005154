export const drawerLinksNonLogged = [
    {
        title: "Search",
        iconName: "Lupe",
        href: "/",
    },
    {
        title: "Fleet",
        iconName: "Truck",
        href: "/fleet",
    },
    {
        title: "Driver",
        iconName: "Users",
        href: "/driver",
    },
    {
        title: "Vendor",
        iconName: "Vendors",
        href: "/vendor",
    },
    {
        title: "Financing",
        iconName: "Financing",
        href: "/financing",
    },
];
