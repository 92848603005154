import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../redux/reducers/v2/auth";

const ListingNotFound = () => {
  const history = useHistory();
  const loggedUserData = useSelector(selectUserData);

  return (
    <FtsWrapper mt={{ xs: "0", sm: "0", md: "2xl", lg: "4xl" }}>
      <Error
        title={"Listing Not Found"}
        subtitle={"This listing is no longer on Find Truck Service."}
        iconName="AlertOctagon"
        containedButton={{
          label: "Create New Listing",
          color: ButtonColorsValue.accent,
          onClick: () => {
            if (loggedUserData) {
              history.push("/dashboard/vendor-advertise-listings");
            } else {
              window.location.href = "/vendor";
            }
          },
        }}
        outlinedButton={{
          label: "Go to Search",
          color: ButtonColorsValue.accent,
          onClick: () => {
            window.location.href = "/";
          },
        }}
      />
    </FtsWrapper>
  );
};
export default ListingNotFound;
