import * as Yup from "yup";
import {
  urlValidate,
  phoneNumberValidate,
  requiredPhoneNumberValidate,
} from "@find-truck-service/types/validators/global";

export const listingContactRowValidator = Yup.object().shape({
  website: urlValidate,
  fax: phoneNumberValidate,
  afterHours: phoneNumberValidate,
  phoneNumberSecondary: phoneNumberValidate,
  phoneNumber: requiredPhoneNumberValidate,
});
