import { createSelector } from "reselect";

export const SOCIAL_AUTH_REQUEST = "SOCIAL_AUTH/SOCIAL_AUTH_REQUEST";
export const SOCIAL_AUTH_SUCCESS = "SOCIAL_AUTH/SOCIAL_AUTH_SUCCESS";
export const SOCIAL_AUTH_FAILED = "SOCIAL_AUTH/SOCIAL_AUTH_FAILED";

const initialState = {
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SOCIAL_AUTH_REQUEST:
      return { ...state, isLoading: true };

    case SOCIAL_AUTH_SUCCESS:
    case SOCIAL_AUTH_FAILED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}

const selectSocialAuthState = (state) => state.socialAuthState;

export const selectSocialAuthIsLoading = createSelector(
  selectSocialAuthState,
  (socialAuthState) => socialAuthState.isLoading,
);
