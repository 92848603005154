import React from "react";
import { StepperWrapper } from "./styled/StepperWrapper";
import StepperDesktop from "./components/StepperDesktop";
import StepperMobile from "./components/StepperMobile";

const GetListedStepperIndicator = ({ activeStepId }) => {
  return (
    <StepperWrapper>
      <StepperDesktop activeStepId={activeStepId} />
      <StepperMobile activeStepId={activeStepId} />
    </StepperWrapper>
  );
};

export default GetListedStepperIndicator;
