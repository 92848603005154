import React from "react";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";

const ListingDisabled = (props) => {
  const { id } = props.match.params;

  if (!id) window.location.href = "/";

  return (
    <FtsWrapper mt={{ xs: "0", sm: "0", md: "2xl", lg: "4xl" }}>
      <Error
        title={"Listing Disabled"}
        subtitle={`Please contact Find Truck Service regarding listing #${id}.`}
        iconName="AlertOctagon"
        outlinedButton={{
          label: "Contact Find Truck Service",
          color: ButtonColorsValue.accent,
          onClick: () => {
            window.location.href = "/contact";
          },
        }}
      />
    </FtsWrapper>
  );
};
export default ListingDisabled;
