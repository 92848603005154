import React from "react";
import { NOTIFICATION } from "../../../constants";
import FormikInputValue from "../../../components/fts/formik/formik-input-value";
import "../../../assets/scss/components/fts/fts-client-notification.scss";
import Icon from "../Icons";

const ClientNotification = (props) => {
  const {
    notification = NOTIFICATION,
    handleRequestStatus,
    getAdminName,
  } = props;

  return (
    <div
      className="fts-client-notification"
      style={{
        margin: "-8px -19px -8px -8px",
        padding: 16,
        backgroundColor: "#fff",
        minWidth: 400,
        maxWidth: 400,
      }}
    >
      <div
        style={{
          padding: "5px 0px",
        }}
        className="d-flex  "
      >
        <Icon size={40} name={notification.icon} />
        <h4 className={"ml-1"}>{notification.title}</h4>
      </div>
      <div style={{ margin: "12px 0" }}>
        <h6 className={"m-0 p-0"}>
          {getAdminName(notification.sendBy)?.label}{" "}
          {notification.notification.replace(/\+/g, " ")}
        </h6>
      </div>
      <div
        style={{
          padding: 10,
          borderRadius: "3px",
          backgroundColor: "#F9FAFC",
          borderLeft: "3px solid #F5B053",
        }}
      >
        <FormikInputValue className={"text-dark"}>
          {decodeURIComponent(notification.description)}
        </FormikInputValue>
      </div>
      {notification.linkTitle === "requestParticipation" ? (
        <div
          style={{ marginTop: 16 }}
          className="d-flex justify-content-between"
        >
          <a href={notification.link}>
            View todo <Icon color={"#fff"} name={"ArrowLink"} />
          </a>
          <div className="d-flex">
            <p
              onClick={() => handleRequestStatus("Reject", notification)}
              className={"cursor-pointer d-flex align-items-center "}
              style={{ color: "#4B5457" }}
            >
              <Icon size={30} color={"#fff"} name={"Close"} />
              <span>Reject</span>
            </p>
            <p
              onClick={() => handleRequestStatus("Approve", notification)}
              className="ml-1 d-flex align-items-center  color-primary cursor-pointer"
            >
              <Icon size={30} color={"#fff"} name={"ApproveTodo"} />
              <span>Approve</span>
            </p>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: 16 }} className="d-flex justify-content-start">
          {notification.link && (
            <a href={notification.link}>
              {notification.linkTitle}{" "}
              <Icon color={"#fff"} name={"ArrowLink"} />
            </a>
          )}
        </div>
      )}
      {/*<div style={{ marginTop: 16 }} className="d-flex justify-content-end">*/}
      {/*  <a href={notification.link}>*/}
      {/*    {notification.linkTitle} <Icon color={"#fff"} name={"ArrowLink"} />*/}
      {/*  </a>*/}
      {/*</div>*/}
    </div>
  );
};

export default ClientNotification;
