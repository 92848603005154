import { createSelector } from "reselect";

export const SET_COMPLETE_REGISTRATION_INVALID_FORM_FIELDS =
  "COMPLETE_REGISTRATION/SET_COMPLETE_REGISTRATION_INVALID_FORM_FIELDS";
export const SET_COMPLETE_REGISTRATION_DATA =
  "COMPLETE_REGISTRATION/SET_COMPLETE_REGISTRATION_DATA";

export const COMPLETE_REGISTRATION_GET_INVITATION_REQUEST =
  "COMPLETE_REGISTRATION/COMPLETE_REGISTRATION_GET_INVITATION_REQUEST";
export const COMPLETE_REGISTRATION_GET_INVITATION_SUCCESS =
  "COMPLETE_REGISTRATION/COMPLETE_REGISTRATION_GET_INVITATION_SUCCESS";
export const COMPLETE_REGISTRATION_GET_INVITATION_FAILED =
  "COMPLETE_REGISTRATION/COMPLETE_REGISTRATION_GET_INVITATION_FAILED";

export const COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST =
  "COMPLETE_REGISTRATION/COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST";
export const COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST_FAILED =
  "COMPLETE_REGISTRATION/COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST_FAILED";
export const COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST_SUCCESS =
  "COMPLETE_REGISTRATION/COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST_SUCCESS";

const initialState = {
  data: null,
  email: null,
  error: null,
  isLoading: false,
  inlineMessage: null,
  invalidFormFields: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COMPLETE_REGISTRATION_GET_INVITATION_REQUEST:
      return { ...state, isLoading: false };

    case COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case COMPLETE_REGISTRATION_GET_INVITATION_FAILED:
    case COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data.message,
        inlineMessage: action.payload.data.inlineMessage,
      };

    case COMPLETE_REGISTRATION_GET_INVITATION_SUCCESS:
      return { ...initialState, email: action.payload.data, isLoading: false };

    case SET_COMPLETE_REGISTRATION_DATA:
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
      };

    case SET_COMPLETE_REGISTRATION_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: action.payload.data,
      };

    case COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        inlineMessage: null,
      };

    default:
      return state;
  }
}

const selectCompleteRegistrationState = (state) =>
  state.completeRegistrationState;

export const selectCompleteRegistrationInvalidFormFields = createSelector(
  selectCompleteRegistrationState,
  (completeRegistrationState) => {
    return completeRegistrationState.invalidFormFields;
  },
);

export const selectCompleteRegistrationData = createSelector(
  selectCompleteRegistrationState,
  (completeRegistrationState) => {
    return completeRegistrationState.data;
  },
);

export const selectCompleteRegistrationIsLoading = createSelector(
  selectCompleteRegistrationState,
  (completeRegistrationState) => {
    return completeRegistrationState.isLoading;
  },
);

export const selectCompleteRegistrationEmail = createSelector(
  selectCompleteRegistrationState,
  (completeRegistrationState) => {
    return completeRegistrationState.email;
  },
);
