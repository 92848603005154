import React from "react";
import { UncontrolledTooltip } from "reactstrap";

import Icon from "../Icons";
import "../../../../src/assets/scss/info-card.scss";

const InfoCard = ({
  label,
  amount,
  borderColor,
  fullWidth = false,
  tooltipText,
  cardId,
  showBorder = false,
}) => {
  return (
    <div
      className="FTS-info-card-wrapper"
      style={{
        width: fullWidth ? "100%" : "",
        border: showBorder ? "1px solid #e5e8e9" : null,
        borderLeft: showBorder ? `4px solid ${borderColor}` : null,
      }}
    >
      {!showBorder && (
        <div
          className="FTS-info-card-color-indicator"
          style={{
            backgroundColor: borderColor,
          }}
        ></div>
      )}
      <div className="FTS-info-card">
        <div className="FTS-info-card-label-wrapper">
          <span className="FTS-info-card-label">{label}</span>
          {cardId && tooltipText && (
            <>
              <Icon name="ReportInfo" size={17} id={cardId} />
              <UncontrolledTooltip target={cardId}>
                {tooltipText}
              </UncontrolledTooltip>
            </>
          )}
        </div>
        <span className="FTS-info-card-amount">{amount}</span>
      </div>
    </div>
  );
};

export default InfoCard;
