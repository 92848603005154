import React from "react";
import { ModalContentComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalContentComponent";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { LineHeightVariantsValues, VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
export const FreeListingNoticeModalContent = (props) => {
    return (React.createElement(ModalContentComponent, null,
        React.createElement(FtsWrapper, { display: "flex", direction: "column", gap: "2xl" },
            React.createElement(Span, { weight: WeightSize.regular, lineHeightVariant: LineHeightVariantsValues.tight, variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-800"] }, "Free listings have limited visibility and they may not best represent your business. To maintain a free listing, you must verify and update it by email every six months. Find Truck Service\u00AE can terminate free listings at any time without notice, and your listing may not always appear in search results."),
            React.createElement(FtsWrapper, { display: "flex" },
                React.createElement(Message, { type: "info", title: "", subtitle: "Paid listings receive the highest search visibility and priority." })),
            React.createElement(FtsWrapper, { display: "flex", width: "100%" },
                React.createElement(Checkbox, { size: CheckboxSizes.md, onClick: props.onClick, checked: props.proceedWithFreeListingChecked, label: "Proceed with free listing" })))));
};
