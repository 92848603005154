import React, { useCallback } from "react";
import { TemporaryLinkPages } from "../../../constants/pages";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { ButtonsFooter } from "@find-truck-service/ui/src/components/react/ButtonsFooter";
import { useHistory } from "react-router-dom";
import { SESSION_STORAGE_KEYS } from "../../../../utility/constants";
import { setTemporaryLinkSelectedListingPrice } from "../../../../redux/actions/v2/temporaryLinkListing";
import { useDispatch, useSelector } from "react-redux";
import { selectTemporaryLinkListingPreviewData } from "../../../../redux/reducers/v2/temporaryListingLink";

export const RelistListingExpiredFooter = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const listingData = useSelector(selectTemporaryLinkListingPreviewData);

  const onViewReport = useCallback(() => {
    const hash = sessionStorage.getItem(SESSION_STORAGE_KEYS.TEMPORARY_TOKEN);
    const href = `/dashboard/report/${hash}`;

    window.open(href, "_blank");
  }, []);

  const onRelistNow = useCallback(() => {
    dispatch(setTemporaryLinkSelectedListingPrice(listingData?.previousPrice));
    history.push(TemporaryLinkPages.UPGRADE_LISTING_PAYMENT);
  }, []);

  return (
    <ButtonsFooter
      outlinedButtonProps={{
        label: "View Report",
        color: ButtonColorsValue.accent,
        onClick: onViewReport,
      }}
      containedButtonProps={{
        label: "Relist Now",
        color: ButtonColorsValue.accent,
        onClick: onRelistNow,
      }}
    />
  );
};
