import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import styled from "styled-components";
import { FtsWrapper } from "../../../../FtsWrapper/FtsWrapper";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
export const IconWrapper = styled(FtsWrapper) `
  background-color: ${ColorsValue["yellow-warning-50"]};
  border-radius: ${Spacing["4xxl"]};
  padding: ${Spacing["md"]};
  display: inline-flex;
  width: fit-content;
  height: fit-content;
  svg {
    height: ${SvgIconSizeValues["4xl"]};
    width: ${SvgIconSizeValues["4xl"]};
  }
`;
