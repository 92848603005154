import { USER_TYPE } from "../constants";

export const getUserTypeName = (userType) => {
  switch (userType) {
    case USER_TYPE.FLEET_CARRIER:
      return "Fleet/Carrier";
    case USER_TYPE.SERVICE_VENDOR:
      return "Service Vendor";
    case USER_TYPE.OWNER_OPERATOR:
      return "Owner Operator";
    case USER_TYPE.COMPANY_DRIVER:
      return "Company Driver";
    case USER_TYPE.OTHER:
      return "Other";
    default:
      return "Other";
  }
};
