import platform from "platform";
import {
  ANALYTICS_SEARCH_TYPE,
  DEVICE_TAG_EXPIRES,
  EVENT_MEASUREMENTS,
  LISTING_TYPES,
} from "../constants/analytics";
import { isMobile, isTablet } from "react-device-detect";
import packageJson from "../../package.json";
import FTSCookies from "../cookies";
import FTSAxios from "../axios/fts.instance";
import FTSAnalyticsAxios from "../axios/fts-analytics.instance";
import logger from "./logger";
export const getListingTags = (listingData) => {
  let categories = listingData.adsCategories || listingData.categories;
  let subCategories = listingData.adsSubCategories || listingData.subCategories;

  if (Array.isArray(listingData.adsCategories)) {
    categories = listingData.adsCategories.map((e) => e.label).join(" | ");
  }

  if (Array.isArray(listingData.adsSubCategories)) {
    subCategories = listingData.adsSubCategories
      .map((e) => e.label)
      .join(" | ");
  }

  return {
    adId: (listingData.id || "").toString(),
    adLatitude: (listingData.latitude || "").toString(),
    adLongitude: (listingData.longitude || "").toString(),
    adType: LISTING_TYPES[listingData.priceId].toString(),
    adPriceId: (listingData.priceId || "").toString(),
    adName: (listingData.name || "").toString(),
    adUserId: (listingData.userId || "").toString(),
    adCity: (listingData.city || "").toString(),
    adState: (listingData.state || "").toString(),
    adAddress: (listingData.address || "").toString(),
    adZipCode: (listingData.zipCode || "").toString(),
    adPhoneNumberPrimary: (listingData.phoneNumber || "").toString(),
    adPhoneNumberSecondary: (listingData.phoneNumberSecondary || "").toString(),
    adAfterHours: (listingData.afterHours || "").toString(),
    adEmail: (listingData.email || "").toString(),
    adSeoUrlIdentifier: (listingData.seoUrlIdentifier || "").toString(),
    adIsFeatureVendor: (listingData.isFeatureVendor || false).toString(),
    adCategories: categories,
    adSubCategories: subCategories,
    adWebsite: listingData.website,
  };
};
export const getAccountTags = (accountData) => {
  let categories = accountData.adsCategories;
  let subCategories = accountData.adsSubCategories;

  if (Array.isArray(accountData.adsCategories)) {
    categories = accountData.adsCategories.map((e) => e.label).join(" | ");
  }

  if (Array.isArray(accountData.adsSubCategories)) {
    subCategories = accountData.adsSubCategories
      .map((e) => e.label)
      .join(" | ");
  }

  return {
    accountId: (accountData.id || "").toString(),
    accountLatitude: (accountData.latitude || "").toString(),
    accountLongitude: (accountData.longitude || "").toString(),
    accountType: accountData?.type?.toString(),
    accountPriceId: (accountData.priceId || "").toString(),
    accountName: (accountData.name || "").toString(),
    accountUserId: (accountData.userId || "").toString(),
    accountCity: (accountData.city || "").toString(),
    accountState: (accountData.state || "").toString(),
    accountAddress: (accountData.address || "").toString(),
    accountZipCode: (accountData.zipCode || "").toString(),
    accountPhoneNumberPrimary: (accountData.phoneNumber || "").toString(),
    accountPhoneNumberSecondary: (
      accountData.phoneNumberSecondary || ""
    ).toString(),
    accountAfterHours: (accountData.afterHours || "").toString(),
    accountEmail: (accountData.email || "").toString(),
    accountSeoUrlIdentifier: (accountData.seoUrlIdentifier || "").toString(),
    accountIsFeatureVendor: (accountData.isFeatureVendor || false).toString(),
    accountCategories: categories,
    accountSubCategories: subCategories,
    accountWebsite: accountData.website,
  };
};

export const getNoteTags = (noteData) => {
  return {
    noteAdId: noteData?.adId,
    noteOwnerId: noteData?.ownerId,
    noteSubOwnerId: noteData?.subOwnerId,
    noteUserId: noteData?.userId,
    noteRating: noteData?.rating,
    noteTypeId: noteData?.noteTypeId,
    noteType: noteData?.noteType,
  };
};

export const getDriverTags = (driverData) => {
  return {
    driverId: driverData?.id,
    driverUserId: driverData?.driverId,
    driverOwnerId: driverData?.ownerId,
    driverAddress: driverData?.address,
    driverZip: driverData?.zip,
    driverCity: driverData?.city,
    driverState: driverData?.state,
    driverPhoneNumber: driverData?.phoneNumber,
    driverUserType: driverData?.type,
    driverTruckType: driverData?.truckType,
    driverFirstName: driverData?.firstName,
    driverLastName: driverData?.lastName,
    driverTruckId: driverData?.truckId,
  };
};

export const getTruckTags = (truckData) => {
  return {
    truckId: truckData?.truckId,
    truckDriverId: truckData?.driverId,
    truckOwnerId: truckData?.ownerId,
    truckSubOwnerId: truckData?.subOwnerId,
    truckMake: truckData?.truck?.truckMake,
    truckModel: truckData?.truck?.truckModel,
    truckNumber: truckData?.truck?.truckNumber,
    truckPlates: truckData?.truck?.truckPlates,
    truckPlatesExpiration: truckData?.truck?.truckPlatesExpiration,
    dotInspection: truckData?.truck?.dotInspection,
    truckType: truckData?.truck?.truckType,
    truckVin: truckData?.truck?.truckVin,
    truckYear: truckData?.truck?.truckYear,
  };
};

export const getExperienceTags = (experienceData) => {
  return {
    experienceId: experienceData?.id,
    experienceExperienceId: experienceData?.experienceId,
    experienceOwnerId: experienceData?.ownerId,
    experienceSubOwnerId: experienceData?.subOwnerId,
  };
};

export const getDeviceTags = async (force) => {
  const keyName = "device_tags";
  let deviceTags = await FTSCookies.get(keyName);

  if (deviceTags && !force) return deviceTags;

  let deviceCategory = "desktop";
  if (isMobile) deviceCategory = "mobile";
  if (isTablet) deviceCategory = "tablet";

  let deviceLatitude = "";
  let deviceLongitude = "";
  let deviceCity = "";
  let deviceState = "";
  let deviceCounty = "";
  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (e) => {
          reject(e);
        },
      );
    });
    const { latitude, longitude } = position.coords ?? {};
    const { data } = await FTSAxios.post("/search/my-location", {
      latitude,
      longitude,
    });
    const { city, state, county } = data._source;
    deviceLatitude = latitude;
    deviceLongitude = longitude;
    deviceCity = city;
    deviceState = state;
    deviceCounty = county;
  } catch (e) {
    logger.error(e);
  }
  deviceTags = {
    deviceOs: platform?.os?.family?.toLowerCase() || "no_device_os", //Android || iOS || Windows
    deviceOsVersion: platform?.os?.version || "no_device_os_version", //10.15.7
    devicePlatform: platform?.name?.toLowerCase() || "no_device_platform", // Chrome || Sarafi || Monzila
    devicePlatformVersion:
      platform?.version?.toLowerCase() || "no_device_platform_version", //113.0.0.0
    deviceCategory: deviceCategory?.toLowerCase() || "no_device_category", //desktop mobile tablet
    deviceLatitude: deviceLatitude?.toString() || "no_device_latitude",
    deviceLongitude: deviceLongitude?.toString() || "no_device_longitude",
    deviceCity,
    deviceState,
    deviceCounty,
  };

  await FTSCookies.set(keyName, JSON.stringify(deviceTags), {
    expires: DEVICE_TAG_EXPIRES,
  });
  return deviceTags;
};
export const getSearchTags = (
  filters,
  searchType = ANALYTICS_SEARCH_TYPE.DEFAULT,
) => {
  let {
    mainCategory = "",
    subCategory = "",
    city = "",
    state = "",
    mainCategoryLabel = "",
    subCategoryLabel = "",
    latitude = "",
    longitude = "",
  } = filters;

  return {
    searchType: searchType.toString(),
    searchCategory: mainCategory.toString(),
    searchSubCategory: subCategory.toString(),
    searchCategoryLabel: mainCategoryLabel.toString(),
    searchSubCategoryLabel: subCategoryLabel.toString(),
    searchCity: city.toString(),
    searchState: state.toString(),
    searchLatitude: latitude.toString(),
    searchLongitude: longitude.toString(),
  };
};
export const getLoggedUserTags = (userData) => {
  if (!userData) return {};
  return {
    loggedUserId: (userData?.accountLink || userData?.id || "").toString(),
    loggedUserSubUserId: userData.id.toString(),
    loggedUserFirstName: userData.firstName,
    loggedUserLastName: userData.lastName,
    loggedUserType: userData?.userType?.toString(),
    loggedUserTypeNamed: userData?.userTypeNamed?.toString(),
  };
};

export const getReferralTags = (referralData) => {
  if (!referralData) return {};
  return {
    referralEmail: referralData?.email,
    referralCompanyName: referralData?.companyName,
    referralFirstName: referralData?.firstName,
    referralLastName: referralData?.lastName,
  };
};
export const storeSearchAnalytics = async ({
  topTenResult,
  filters,
  searchType,
  userData,
}) => {
  let promises = [];
  await getDeviceTags();

  for (let i = 0; i < topTenResult.length; i++) {
    const tags = {
      ...getListingTags(topTenResult[i]),
      ...getSearchTags(filters, searchType),
      ...getLoggedUserTags(userData),
    };

    if (i === 0) {
      promises.push(storeAnalytic(EVENT_MEASUREMENTS.TOP1, tags));
    }

    if (i <= 3) promises.push(storeAnalytic(EVENT_MEASUREMENTS.TOP3, tags));

    if (i <= 5) promises.push(storeAnalytic(EVENT_MEASUREMENTS.TOP5, tags));

    promises.push(storeAnalytic(EVENT_MEASUREMENTS.TOP10, tags));
  }
  promises.push(
    storeAnalytic(EVENT_MEASUREMENTS.SEARCH, {
      ...getSearchTags(filters, searchType),
      ...getLoggedUserTags(userData),
    }),
  );

  return Promise.all(promises);
};

export const storeAnalytic = async (measurementName, tags = {}) => {
  const deviceTags = await getDeviceTags();

  const tagsForSave = {
    ...tags,
    ...deviceTags,
    appVersion: packageJson.version,
    appSource: "client_dashboard",
    appHref: window.location.href,
  };

  if (window.dataLayer) {
    window.dataLayer.push({ event: measurementName, ...tagsForSave });
  }

  return FTSAnalyticsAxios.post("/event", {
    measurementName,
    tags: tagsForSave,
  });
};
