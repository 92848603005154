import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { InputVariants } from "@find-truck-service/types/ui/input";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import { ZipCodeInput } from "@find-truck-service/ui/src/components/react/ZipCodeInput";

export const ListingDetails = (props) => {
  const { onChange, states = [], listingData, invalidFormFields = {} } = props;
  return (
    <FtsRow columnGap={"lg"} rowGap={"2xl"} width={"100%"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          LISTING DETAILS
        </Span>
      </FtsColumn>

      <FtsColumn size={{ xs: 12 }}>
        <Input
          required
          size={"md"}
          name={"name"}
          onChange={onChange}
          label={"Company name"}
          value={listingData.name}
          variant={InputVariants.outlined}
          errorMessage={invalidFormFields["name"]}
        />
      </FtsColumn>

      <FtsColumn size={{ md: 12, lg: 6 }}>
        <Input
          required
          size={"md"}
          name={"address"}
          label={"Street"}
          onChange={onChange}
          value={listingData.address}
          variant={InputVariants.outlined}
          errorMessage={invalidFormFields["address"]}
        />
      </FtsColumn>

      <FtsColumn size={{ md: 12, lg: 6 }}>
        <FtsRow columnGap={"lg"} rowGap={"2xl"} width={"100%"}>
          <FtsColumn size={{ sm: 12, md: 6 }}>
            <Input
              required
              size={"md"}
              name={"city"}
              label={"City"}
              onChange={onChange}
              value={listingData.city}
              variant={InputVariants.outlined}
              errorMessage={invalidFormFields["city"]}
            />
          </FtsColumn>
          <FtsColumn size={{ sm: 6, md: 3 }}>
            <Dropdown
              required
              size={DropdownSizesValues.md}
              variant={DropdownVariantsValues.outlined}
              name={"state"}
              label={"State"}
              options={states}
              placeholder={""}
              value={states.find((e) => e.label === listingData.state)}
              errorMessage={invalidFormFields["state"]}
              onChange={(val) =>
                onChange({ target: { name: "state", value: val?.label || "" } })
              }
            />
          </FtsColumn>
          <FtsColumn size={{ sm: 6, md: 3 }}>
            <ZipCodeInput
              required
              size={"md"}
              name={"zip"}
              pattern="\d*"
              maxLength="5"
              label={"Zip code"}
              onChange={onChange}
              value={listingData.zip}
              variant={InputVariants.outlined}
              errorMessage={invalidFormFields["zip"]}
            />
          </FtsColumn>
        </FtsRow>
      </FtsColumn>

      <FtsColumn size={{ sm: 12, md: 6 }}>
        <Input
          size={"md"}
          name={"interstate"}
          onChange={onChange}
          label={"Interstate"}
          value={listingData.interstate}
          variant={InputVariants.outlined}
        />
      </FtsColumn>
      <FtsColumn size={{ sm: 12, md: 6 }}>
        <Input
          size={"md"}
          name={"milemarker"}
          onChange={onChange}
          label={"Mile marker"}
          value={listingData.milemarker}
          variant={InputVariants.outlined}
        />
      </FtsColumn>
    </FtsRow>
  );
};
