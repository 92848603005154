import { Form, Formik } from "formik";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { changePasswordSchema as validationSchema } from "../../../formik-validations/change-password";
import { history } from "../../../history";
import { changePassword } from "../../../redux/actions/my-profile";
import Breakpoint from "../../hoc/Breakpoint";
import Divider from "../Divider";
import FormikInput from "../formik/formik-input";
import Icon from "../Icons";
import { toast } from "react-toastify";
import logger from "../../../utility/logger";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

class ChangePasswordForm extends Component {
  state = {
    inProgress: false,
  };

  onFormSubmission = (values) => {
    this.setState({
      inProgress: true,
    });

    this.props
      .changePassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
      .then(() => {
        this.changePasswordFormRef &&
          this.changePasswordFormRef.current.setFieldError(
            "currentPassword",
            undefined,
          );

        toast.success("Password successfully updated!", {
          position: toast.POSITION.TOP_RIGHT,
        });

        history.push("/dashboard/my-profile");
      })
      .catch((err) => {
        if (err?.response?.data?.error?.statusCode === 400) {
          this.changePasswordFormRef &&
            this.changePasswordFormRef.current.setFieldError(
              "currentPassword",
              "Incorrect current password",
            );
        } else {
          logger.error(err);
          toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .finally(() => {
        this.setState({
          inProgress: false,
        });
      });
  };

  initialValues = {
    currentPassword: "",
    newPassword: "",
    repeatNewPassword: "",
  };

  changePasswordFormRef = createRef();

  render() {
    const { inProgress } = this.state;

    return (
      <Formik
        validationSchema={validationSchema}
        onSubmit={this.onFormSubmission}
        initialValues={this.initialValues}
        innerRef={this.changePasswordFormRef}
      >
        <Form>
          <CardBody>
            <Divider textColor="primary" position="left">
              Password
            </Divider>

            <Row>
              <Col sm="12" lg="12" xl="4">
                <FormGroup>
                  <Label for="currentPassword">Current password *</Label>
                  <FormikInput
                    type="password"
                    name="currentPassword"
                    id="currentPassword"
                    required
                  />
                </FormGroup>
              </Col>
              <Col sm="12" lg="12" xl="4">
                <FormGroup>
                  <Label for="newPassword">New password *</Label>
                  <FormikInput
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    required
                  />
                </FormGroup>
              </Col>
              <Col sm="12" lg="12" xl="4">
                <FormGroup>
                  <Label for="password">Repeat password *</Label>
                  <FormikInput
                    type="password"
                    name="repeatNewPassword"
                    id="repeatNewPassword"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="password-hints">
              <div className="d-flex">
                <Icon
                  name="InfoCircle"
                  size={20}
                  style={{ marginRight: "5px", stroke: "none" }}
                />
                <span>
                  <span className="password-hints-header">Password Hint:</span>
                  <Breakpoint md up>
                    <span className="ml-1">
                      Minimum 7 digits · 1 upper case letter (A-Z) · 1 number
                      (0-9) · 1 symbol (example: !@#$%^&*){" "}
                    </span>
                  </Breakpoint>
                </span>
              </div>
              <Breakpoint sm down>
                <ul>
                  <li>Minimum 7 digits</li>
                  <li>1 upper case letter (A-Z)</li>
                  <li>1 number (0-9)</li>
                  <li>1 symbol (example: !@#$%^&*)</li>
                </ul>
              </Breakpoint>
            </div>
          </CardBody>
          <hr />

          <div className="change-password-footer">
            <Button
              label="Cancel"
              size={ButtonSizes.md}
              type={ButtonTypes.outlined}
              color={ButtonColorsValue.neutral}
              onClick={() => history.push("/dashboard/my-profile")}
            />
            <Button
              loading={inProgress}
              size={ButtonSizes.md}
              label="Update Password"
              type={ButtonTypes.contained}
              color={ButtonColorsValue.accent}
            />
          </div>
        </Form>
      </Formik>
    );
  }
}

export default connect(null, { changePassword })(ChangePasswordForm);
