import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { MobileDescription } from "./MobileDescription";
import { DesktopDescription } from "./DesktopDescription";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

export const ListingDescriptionRow = () => {
  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} width={"100%"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          DESCRIPTION
        </Span>
      </FtsColumn>
      <FtsColumn size={{ xs: 12 }}>
        <DesktopDescription />
        <MobileDescription />
      </FtsColumn>
    </FtsRow>
  );
};
