/* eslint-disable indent */
import React, { createRef } from "react";
import "../../../assets/scss/components/fts/fleet-carrier-form.scss";

import RegisterTitleRow from "../register-title-row";

import { Col, FormGroup, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import Divider from "../../../components/fts/Divider";
import { Button } from "@find-truck-service/ui/src/components/react/Button";

import { createFleetCarrierSubAccountSchema as validationSchema } from "../../../formik-validations/customer-account-information";
import Section from "../../../views/pages/account-settings/Section";

import PromptWrapper from "../../../components/fts/PromptWrapper";
import FormValidationEffect from "../../../components/fts/form-validation-effect";

import "../../../assets/scss/components/fts/form-fields.scss";
import { toast } from "react-toastify";
import FormikInput from "../formik/formik-input";
import StatesPicker from "../states-picker";
import FormikMaskedInput from "../formik/masked-input";
import { phoneNumberMask, zipCodeMask } from "../../../utility/masks";

import { history } from "../../../history";

import { activities, USER_TYPE } from "../../../constants";
import { connect } from "react-redux";
import { editVendor, getUserDetails } from "../../../redux/actions/users";
import { createActivityVendor } from "../../../redux/actions/activities";
import logger from "../../../utility/logger";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";

class FleetCarrierSubAccountForm extends React.Component {
  state = {
    inProgress: false,
    initialValues: {
      state: "",
      zip: "",
      phoneNumber: "",
      phoneNumberSecondary: "",

      ...this.props.userData,
      referal: this.props.userData?.referal || "",
      website: this.props.userData?.website || "",
      city: this.props.userData?.city || "",
      address: this.props.userData?.address || "",
      companyName: this.props.userData?.companyName || "",
    },
  };

  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  componentDidMount() {
    this.ref.current.resetForm();
  }

  onFormSubmission = (values, { resetForm }) => {
    if (this.state.inProgress) {
      return;
    }

    window.scrollTo(0, 0);

    this.setState({ inProgress: true });

    const payload = {
      ...this.props.userData,
      ...values,
      adminStatus:
        typeof values?.adminStatus === "number"
          ? values?.adminStatus
          : values?.adminStatus?.value,
      leadStatus:
        typeof values?.leadStatus === "object"
          ? values?.leadStatus?.value
          : values?.leadStatus,
      state: values.state.value,
      companyName: encodeURIComponent(values?.companyName || ""),
      onboardingFinished: true,
      type: USER_TYPE.FLEET_CARRIER,
      userType: 1,
      otherUserData: {
        numberOfTrailers: values?.numberOfTrailers,
        numberOfTrucks: values?.numberOfTrucks,
        dot: values?.dotNumber,
        mc: values?.mcNumber,
      },
    };

    this.props
      .editVendor(payload)
      .then(async ({ data }) => {
        let activityPayload = {
          status: 2,
          userId: data.user.id,
          activityType: this.props.activitiesTypes.SET_ACCOUNT_STATUS,
          activity: activities.setAccountStatus
            .replace("{{accountId}}", data.user.id)
            .replace("{{status}}", "Fleet")
            .replace("{{email}}", data.user.email),
          iconName: "AccountsActivity",
        };

        await this.props.createActivityVendor(activityPayload);

        resetForm(this.state.initialValues);
        this.ref.current.resetForm();
        await this.props.getUserDetails();
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({
          inProgress: false,
        });

        const redirectToWebsite = this.props.redirectToWebsite();
        if (redirectToWebsite) {
          redirectToWebsite();
        } else {
          history.push("/dashboard");
        }
      })
      .catch((err) => {
        logger.error(err);
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({
          inProgress: false,
        });
      });
  };

  onSubmitError = () => {
    toast.error("Unable to proceed. Invalid form!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  render() {
    const { initialValues, inProgress } = this.state;
    const { handleCancel, userData } = this.props;

    return (
      <div className="fleet-carrier-form-wrapper">
        <RegisterTitleRow title="Account details" />
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={this.onFormSubmission}
          {...{ initialValues }}
          {...{ validationSchema }}
          innerRef={(ref) => {
            this.ref.current = ref;
            this.props.setFormikSidebarRef &&
              this.props.setFormikSidebarRef(ref);
          }}
          {...{ initialValues, validationSchema }}
        >
          {({ dirty, setFieldTouched, setFieldError, ...rest }) => (
            <>
              <FormValidationEffect
                formik={rest}
                onSubmissionError={this.onSubmitError}
              />
              <PromptWrapper dirty={dirty} />
              <Form autoComplete="off">
                <Section id="bussines-info-section">
                  <Divider textColor="primary" position="left">
                    Business Info
                  </Divider>

                  <Row>
                    <Col sm="12" lg="12" xl="3">
                      <FormikInput
                        disabled={false}
                        required
                        name="companyName"
                        label="Company name"
                        labelId="companyName"
                      />
                    </Col>
                    <Col sm="12" lg="12" xl="3">
                      <FormikInput
                        disabled={false}
                        required
                        name="address"
                        label="Street address"
                        labelId="address"
                      />
                    </Col>
                    <Col sm="12" lg="12" xl="3">
                      <FormikInput
                        disabled={false}
                        required
                        name="city"
                        label="City"
                        labelId="city"
                      />
                    </Col>
                    <Col sm="12" lg="12" xl="3">
                      <Row>
                        <Col sm="6" md="6">
                          <FormGroup>
                            <Field name="state">
                              {({ meta, form, field: { onBlur, value } }) => (
                                <StatesPicker
                                  accountEditMode
                                  isDisabled={false}
                                  required
                                  name="state"
                                  label="State"
                                  placeholder=""
                                  onStateChange={(option) => {
                                    if (option) {
                                      setFieldError("state", undefined);
                                    }
                                    form.setFieldValue("state", option);
                                    setTimeout(() => {
                                      setFieldTouched("state", true, true);
                                    }, 0);
                                  }}
                                  {...{ meta, onBlur, value }}
                                />
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col sm="6" md="6">
                          <FormikMaskedInput
                            disabled={false}
                            required
                            name="zip"
                            label="Zip code"
                            labelId="zip"
                            mask={zipCodeMask}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" lg="3" xl="3">
                      <FormikMaskedInput
                        disabled={false}
                        required
                        name="phoneNumber"
                        label="Phone"
                        labelId="phone"
                        mask={phoneNumberMask}
                      />
                    </Col>
                    <Col sm="12" lg="3" xl="3">
                      <FormikMaskedInput
                        disabled={false}
                        name="phoneNumberSecondary"
                        label="Phone (secondary)"
                        labelId="phoneNumberSecondary"
                        mask={phoneNumberMask}
                      />
                    </Col>
                    <Col sm="12" lg="3" xl="3">
                      <FormikInput
                        disabled={false}
                        name="website"
                        label="Website"
                        labelId="website"
                      />
                    </Col>
                    <Col sm="12" lg="3" xl="3">
                      <FormikInput
                        disabled={false}
                        name="referal"
                        label="How did you hear about us?"
                        labelId="referal"
                      />
                    </Col>
                  </Row>
                </Section>

                <FtsWrapper
                  flex={1}
                  gap="lg"
                  display="flex"
                  direction="row"
                  justify="flex-end"
                >
                  {!userData?.disableUserTypeChange && (
                    <Button
                      label="Cancel"
                      size={ButtonSizes.md}
                      onClick={handleCancel}
                      type={ButtonTypes.outlined}
                      color={ButtonColorsValue.neutral}
                      form="fleet-carrier-sub-account-form"
                    />
                  )}
                  <Button
                    loading={inProgress}
                    size={ButtonSizes.md}
                    label="Create Account"
                    type={ButtonTypes.contained}
                    color={ButtonColorsValue.accent}
                  />
                </FtsWrapper>
              </Form>
            </>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.authState.user,
    types: state.essentialLists.types,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

export default connect(mapStateToProps, {
  editVendor,
  createActivityVendor,
  getUserDetails,
})(FleetCarrierSubAccountForm);
