import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { H3, Span } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ListingItem } from "@find-truck-service/ui/src/components/react/ListingItem";

import {
  selectTemporaryLinkCompetitorsData,
  selectTemporaryLinkCompetitorsError,
  selectTemporaryLinkCompetitorsIsLoading,
  selectTemporaryLinkListingPreviewData,
  selectTemporaryLinkListingSelectedPrice,
} from "../../../redux/reducers/v2/temporaryListingLink";

import {
  setTemporaryLinkSelectedListingPrice,
  temporaryLinkGetCompetitorsRequest,
} from "../../../redux/actions/v2/temporaryLinkListing";
import { ListingItemsTypeValues } from "@find-truck-service/types/ui/listingItemComponent";
import { getListingImageSrc } from "../ListingBusinessInfo/helpers";
import { PreviewListingModalContentTypes } from "@find-truck-service/types/constants/previewListingModalContentTypes";
import { PreviewListingModal } from "@find-truck-service/ui/src/components/react/PreviewListingModal";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  selectAmenities,
  selectServiceAmenities,
} from "../../../redux/reducers/essentialLists";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";

import { Loader } from "@find-truck-service/ui/src/components/react/Loader";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { ADS_PRICES_ENUM } from "@find-truck-service/types/constants/adsPrices";
import { TemporaryLinkPages } from "../../../views/constants/pages";
import { useHistory } from "react-router-dom";

export const TemporaryLinkUpgradeListingOptions = ({
  handleChangeListingSelection,
  selectedUpgradeListingPrice,
  autoOpen,
}) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [previewListing, setPreviewListing] = useState(null);
  const [isUpgradeOptionsShown, setIsUpgradeOptionsShown] = useState(
    !!selectedUpgradeListingPrice,
  );

  const listingData = useSelector(selectTemporaryLinkListingPreviewData);
  const amenities = useSelector(selectAmenities);
  const serviceAmenities = useSelector(selectServiceAmenities);
  const listingOptions = useSelector(selectTemporaryLinkCompetitorsData);
  const isLoading = useSelector(selectTemporaryLinkCompetitorsIsLoading);
  const error = useSelector(selectTemporaryLinkCompetitorsError);
  const selectedListingPrice = useSelector(
    selectTemporaryLinkListingSelectedPrice,
  );

  useEffect(() => {
    if (listingData?.priceId !== ADS_PRICES_ENUM.PREMIUM) {
      dispatch(
        temporaryLinkGetCompetitorsRequest({
          data: {
            latitude: listingData.latitude,
            longitude: listingData.longitude,
            priceId: listingData.priceId,
          },
        }),
      );
    }
  }, [listingData]);

  useEffect(() => {
    if (autoOpen && listingOptions?.length) {
      toggleShowUpgradeOptions();
    }
  }, [listingOptions]);

  const toggleShowUpgradeOptions = useCallback(() => {
    dispatch(setTemporaryLinkSelectedListingPrice(null));
    setIsUpgradeOptionsShown((prevState) => !prevState);
  }, []);

  if (listingData.priceId === 1) return <EmptyComponent />;

  return (
    <FtsWrapper
      pt={{ xs: "2xl", sm: "2xl", md: "3xl", lg: "4xl" }}
      pb={{ xs: "2xl", sm: "2xl", md: "3xl", lg: "3xl" }}
    >
      <H3
        variant={"text-lg"}
        weight={WeightSize.bold}
        textAlign={{ xs: "left", sm: "left", md: "center", lg: "center" }}
        px={"xl"}
        mb={"3xl"}
      >
        Upgrade Now – Get Ahead of the Competition.
      </H3>

      <FtsWrapper px="xl" mb="3xl" flex={1} display="flex" justify="center">
        <Button
          size={ButtonSizes.md}
          onClick={toggleShowUpgradeOptions}
          color={ButtonColorsValue.accent}
          type={ButtonTypes.outlined}
          width={{ xs: "100%", sm: "auto", md: "auto", lg: "auto" }}
          label={`${isUpgradeOptionsShown ? "Hide" : "Show"} Upgrade Options`}
          iconName={
            isUpgradeOptionsShown ? IconNames.ChevronUp : IconNames.ChevronDown
          }
        />
      </FtsWrapper>

      {isUpgradeOptionsShown && (
        <FtsWrapper
          display={"flex"}
          gap={{ lg: "3xxl", md: "3xl", sm: "3xl", xs: "2xl" }}
          direction={"column"}
          position={"relative"}
        >
          {isLoading ? (
            <FtsWrapper minHeight={"200px"}>
              <Loader />
            </FtsWrapper>
          ) : error ? (
            <FtsWrapper minHeight={"200px"}>
              <Span variant={VariantsValues["text-md"]}>{error}</Span>
            </FtsWrapper>
          ) : (
            listingOptions?.map((item, index) => {
              return (
                <ListingItem
                  key={index}
                  type={ListingItemsTypeValues.DRAFT}
                  listingData={item}
                  selected={selectedListingPrice?.id === item.price.id}
                  footerActions={{
                    onSelect: () => {
                      handleChangeListingSelection(item.price);
                    },
                    onClickPreview: () => setPreviewListing(item),
                  }}
                />
              );
            })
          )}
          <PreviewListingModal
            showBenefits
            amenities={amenities}
            isOpen={!!previewListing}
            listingData={previewListing}
            price={previewListing?.price}
            serviceAmenities={serviceAmenities}
            imageSrc={getListingImageSrc(previewListing)}
            onClose={() => setPreviewListing(null)}
            contentType={PreviewListingModalContentTypes.PREVIEW_LISTING}
            title={
              previewListing?.price?.label
                ? `${previewListing.price.label} Preview`
                : "Preview listing"
            }
            modalFooterProps={{
              successLabel: `Select ${previewListing?.price?.label}`,
              onSuccess: () => {
                handleChangeListingSelection(previewListing?.price);
                history.push(TemporaryLinkPages.UPGRADE_LISTING_PAYMENT);
              },
            }}
          />
        </FtsWrapper>
      )}
    </FtsWrapper>
  );
};
