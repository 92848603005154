import React from "react";
import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import localImageLg from "../../images/backgrounds/map-overlay-1.png";
import localImageMd from "../../images/backgrounds/map-overlay-2.png";
import localImageXs from "../../images/backgrounds/map-overlay-3.png";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";

const descriptionErrorOutline = `${Spacing["3xs"]} solid ${ColorsValue["red-error-500"]}`;

export const ListingMapPlaceholder = ({
  onConvert,
  inProgress,
  mapHasError,
}) => {
  return (
    <StyledWrapper outline={mapHasError ? descriptionErrorOutline : ""}>
      <Button
        onClick={onConvert}
        size={ButtonSizes.md}
        disabled={inProgress}
        iconName={IconNames.MapPin}
        type={ButtonTypes.contained}
        color={ButtonColorsValue.onBrand}
        label={"View Location on Map"}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled(FtsWrapper).attrs({
  height: { xs: "504px", sm: "400px", md: "400px", lg: "560px" },
  borderRadius: "2xs",
  justify: "center",
  display: "flex",
  align: "center",
  width: "100%",
  bgImage: {
    xs: `url(${localImageXs})`,
    sm: `url(${localImageMd})`,
    md: `url(${localImageMd})`,
    lg: `url(${localImageLg})`,
  },
})`
  background-repeat: no-repeat;
  background-size: cover;
`;
