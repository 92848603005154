import FTSAxios from "../../../axios/fts.instance";
import { serialize } from "../../../utility/serialize";

export const getAllUpdates = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    const stringifiedParams = serialize(queryParams);
    return FTSAxios.get(`admin/updates/all?${stringifiedParams}`);
  };
};

export const getUpdateRequests = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    const stringifiedParams = serialize(queryParams);
    return FTSAxios.get(`admin/updates/requested?${stringifiedParams}`);
  };
};

export const getRejectedRequests = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    const stringifiedParams = serialize(queryParams);
    return FTSAxios.get(`admin/updates/rejected?${stringifiedParams}`);
  };
};

export const getSentRequests = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    const stringifiedParams = serialize(queryParams);
    return FTSAxios.get(`admin/updates/sent?${stringifiedParams}`);
  };
};

export const getCountAll = (params) => {
  return () => {
    const queryParams = { ...params };
    const stringifiedParams = serialize(queryParams);
    return FTSAxios.get(`admin/updates/count-all?${stringifiedParams}`);
  };
};

export const getOnHoldUpdates = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page, onHold: true };
    const stringifiedParams = serialize(queryParams);
    return FTSAxios.get(`admin/updates/all?${stringifiedParams}`);
  };
};

export const putUpdateOnHold = (params) => {
  return () => {
    return FTSAxios.post(`/admin/updates/on-hold`, { ...params });
  };
};

export const updateRequestStatus = (params) => {
  return () => {
    return FTSAxios.put(`/admin/updates/update-status`, { ...params });
  };
};

export const updateRequestUpdate = (payload) => {
  return () => {
    return FTSAxios.put(`/admin/updates/request-update`, payload);
  };
};

export const cancelRequestUpdate = (payload) => {
  return () => {
    return FTSAxios.put(`/admin/request-update/cancel`, payload);
  };
};

export const getUnassignedUpdatesCount = () => {
  return () => {
    return FTSAxios.get(`admin/updates/unassinged/count`);
  };
};

export const createUpdateRequest = (payload) => {
  return () => {
    return FTSAxios.post(`/request-update`, payload);
  };
};

export const getUpdateRequest = ({ id }) => {
  return () => {
    return FTSAxios.get(`/request-update/${id}`);
  };
};

export const cancelUpgrade = ({ id, adId }) => {
  return () => {
    return FTSAxios.post("/request-update/cancel", { id, adId });
  };
};

export const editUpgrade = ({ updateId, payload }) => {
  return () => {
    return FTSAxios.put(`/request-update/update/${updateId}`, payload);
  };
};
