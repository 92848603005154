import React, { useCallback } from "react";
import { Pages } from "../../../constants/pages";
import { isIOS } from "../../../../utility/isIOS";
import { useDispatch, useSelector } from "react-redux";
import { LinkSizes } from "@find-truck-service/types/ui/link";
import { loginValidator } from "../validators/loginValidator";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { extractParameter } from "../../../../utility/param-extract";
import { selectLoginData } from "../../../../redux/reducers/v2/login";
import { Link } from "@find-truck-service/ui/src/components/react/Link";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { socialAuthRequest } from "../../../../redux/actions/v2/socialAuth";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FbButton } from "@find-truck-service/ui/src/components/react/FbButton";
import { socialAuthProviders } from "../../../../redux/sagas/socialAuth/helpers";
import TextDivider from "@find-truck-service/ui/src/components/react/TextDivider";
import { AppleButton } from "@find-truck-service/ui/src/components/react/AppleButton";
import { GoogleButton } from "@find-truck-service/ui/src/components/react/GoogleButton";
import {
  loginRequest,
  setLoginInvalidFormFields,
} from "../../../../redux/actions/v2/login";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

const showAppleButton = isIOS();

export const LoginButtons = ({ setShowNoAccountError }) => {
  const dispatch = useDispatch();
  const accountData = useSelector(selectLoginData);
  const navigateTo = extractParameter("redirectTo") || Pages.DASHBOARD;

  const handleLogin = useCallback(async () => {
    try {
      await loginValidator.validate(accountData || {});
      dispatch(loginRequest({ data: { navigateTo } }));
      dispatch(setLoginInvalidFormFields({}));
    } catch (e) {
      if (e.name !== "ValidationError") return;
      const data = { [e.path]: e.errors[0] };
      dispatch(setLoginInvalidFormFields({ data }));
    }
  }, [accountData]);

  const handleApple = async () => {
    const provider = socialAuthProviders.APPLE;
    dispatch(
      socialAuthRequest({
        data: { provider, navigateTo },
        onError: handleAuthError,
      }),
    );
  };

  const handleGoogle = async () => {
    const provider = socialAuthProviders.GOOGLE;
    dispatch(
      socialAuthRequest({
        data: { provider, navigateTo },
        onError: handleAuthError,
      }),
    );
  };

  const handleFacebook = () => {
    const provider = socialAuthProviders.FACEBOOK;
    dispatch(
      socialAuthRequest({
        data: { provider, navigateTo },
        onError: handleAuthError,
      }),
    );
  };

  const handleAuthError = (e) => {
    if (e.response?.data?.message === "USER_TYPE_MISSING") {
      setShowNoAccountError(true);
    }
  };

  return (
    <FtsColumn size={{ xs: 12 }}>
      <FtsRow rowGap={"lg"}>
        <FtsColumn size={{ xs: 12 }}>
          <Button
            width={"100%"}
            label={"Login"}
            size={ButtonSizes.md}
            onClick={handleLogin}
            type={ButtonTypes.contained}
            color={ButtonColorsValue.accent}
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }} display={"flex"} justify={"center"}>
          <Link
            size={LinkSizes.lg}
            label={"Forgot Password?"}
            href={Pages.FORGOT_PASSWORD}
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }} py={"sm"}>
          <TextDivider text={"or"} />
        </FtsColumn>
        {showAppleButton && (
          <FtsColumn size={{ xs: 12 }}>
            <AppleButton
              width={"100%"}
              label={"Login with Apple"}
              onClick={handleApple}
            />
          </FtsColumn>
        )}
        <FtsColumn size={{ xs: 12 }}>
          <GoogleButton
            width={"100%"}
            label={"Login with Google"}
            onClick={handleGoogle}
          />
        </FtsColumn>
        <FtsColumn size={{ xs: 12 }}>
          <FbButton
            width={"100%"}
            label={"Login with Facebook"}
            onClick={handleFacebook}
          />
        </FtsColumn>
      </FtsRow>
    </FtsColumn>
  );
};
