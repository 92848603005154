import React from "react";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import Warning from "@find-truck-service/ui/src/components/react/Warning";

export const DraftListingExistComponent = ({ onClick }) => {
  return (
    <Warning
      iconName="AlertTriangle"
      title="Complete Your Listing"
      message="You have an unfinished listing waiting for you. Complete it now to maximize your visibility."
      containedButton={{
        label: "Complete Listing",
        color: ButtonColorsValue.accent,
        onClick,
      }}
    />
  );
};
