import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { history } from "../../../history";
import { extractParameter } from "../../../utility/param-extract";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import GetListedStepperIndicator from "../../../components/fts-components-v2/GetListedStepperIndicator";
import {
  setListingData,
  setListingInvalidFormFields,
  uploadAdsAssetsRequest,
} from "../../../redux/actions/v2/listing";
import { ListingImagesMobile } from "../../../components/fts-components-v2/ListingBusinessInfo/components/ListingImagesMobile";
import { EditListingImagesMobileFooter } from "../../../components/fts-components-v2/ListingBusinessInfo/EditListing/Components/ListingImagesMobileFooter";
import {
  selectIsLoadingListingData,
  selectListingAdsAssets,
  selectListingAdsAssetsLength,
  selectListingData,
  selectListingId,
} from "../../../redux/reducers/v2/listing";

const EditListingImages = (props) => {
  const listingId = useSelector(selectListingId);
  const listingData = useSelector(selectListingData);
  const adsAssets = useSelector(selectListingAdsAssets);
  const isLoading = useSelector(selectIsLoadingListingData);
  const adsAssetsSize = useSelector(selectListingAdsAssetsLength);

  function navigateToBusinessDetails() {
    if (!props.match.params.adId) return; //TODO Error handling - cant edit listing without adId
    const priceId = extractParameter("selectedListingPriceId");
    const convertedToVendor = extractParameter("convertedToVendor");
    const searchParams = new URLSearchParams();

    if (priceId) {
      searchParams.set("selectedListingPriceId", priceId);
    }

    if (convertedToVendor) {
      searchParams.set("convertedToVendor", convertedToVendor);
    }

    history.replace({
      pathname: `/dashboard/edit-listing/business-details/${props.match.params.adId}`,
      search: `?${searchParams.toString()}`,
    });
  }

  useEffect(() => {
    if (!listingId) navigateToBusinessDetails();
  }, [listingId]);

  return (
    <FtsWrapper
      height={"100dvh"}
      direction={"column"}
      display={{ xs: "flex", sm: "flex", md: "none", lg: "none" }}
    >
      <FtsWrapper display={"flex"} direction={"column"}>
        {isLoading && <FullPageLoader />}
        <GetListedStepperIndicator activeStepId={2} />
        <ListingImagesMobile
          adsAssets={adsAssets}
          listingData={listingData}
          adsAssetsSize={adsAssetsSize}
          setListingData={setListingData}
          uploadAdsAssetsRequest={uploadAdsAssetsRequest}
          setListingInvalidFormFields={setListingInvalidFormFields}
        />
      </FtsWrapper>
      <EditListingImagesMobileFooter />
    </FtsWrapper>
  );
};

export default EditListingImages;
