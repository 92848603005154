import moment from "moment";

export const calculateTimeDifference = ({ nextDate }) => {
  const now = moment();
  const futureDate = moment(nextDate);
  const diff = moment.duration(futureDate.diff(now));
  let formattedDiff = "";

  if (diff.years() > 0) {
    formattedDiff += `${diff.years()} years `;
  }

  if (diff.months() > 0) {
    formattedDiff += `${diff.months()} months `;
  }

  if (diff.days() > 0) {
    formattedDiff += `${diff.days()} days `;
  }

  if (diff.days() > 0) {
    formattedDiff += `${diff.days()} days `;
  }

  if (diff.hours() > 0) {
    formattedDiff += `${diff.hours()} hours `;
  }

  if (diff.minutes() > 0) {
    formattedDiff += `${diff.minutes()} minutes`;
  }

  if (diff.minutes() === 0) {
    formattedDiff += `${diff.seconds()} seconds`;
  }

  if (diff.years() > 1) {
    formattedDiff = `1+ years`;
  }

  return formattedDiff;
};
