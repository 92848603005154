import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { UploadButton } from "./UploadButton";
import { UploadedFiles } from "./UploadedFiles";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ListingImagesBanner } from "./ListingImagesBanner";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { LISTING_FILES_LIMIT } from "../../../../data/supported-file-types";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { listingUploadButtonValidator } from "../validators/listingUploadButtonValidator";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

export const ListingImagesRowField = ({
  adsAssets,
  listingData,
  adsAssetsSize,
  setListingData,
  uploadAdsAssetsRequest,
  setListingInvalidFormFields,
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const showUploadCTA = adsAssetsSize < LISTING_FILES_LIMIT;

  async function onUpload() {
    try {
      dispatch(setListingInvalidFormFields({ data: {} }));
      await listingUploadButtonValidator.validate(listingData, {
        abortEarly: false,
      });
      if (fileInputRef.current) fileInputRef.current.click();
    } catch (e) {
      if (e.name !== "ValidationError") return;
      const data = e.inner.reduce((acc, error) => {
        acc[error.path] = error.errors[0];
        return acc;
      }, {});
      dispatch(setListingInvalidFormFields({ data }));
    }
  }

  return (
    <FtsWrapper
      display={{ xs: "none", sm: "block", md: "block", lg: "block" }}
      width={"100%"}
    >
      <FtsRow rowGap={"sm"}>
        <FtsColumn size={{ xs: 12 }}>
          <Span
            weight={WeightSize.medium}
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["blue-accent-500"]}
          >
            LISTING IMAGE
          </Span>
          <Span
            weight={WeightSize.medium}
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["greyscale-500"]}
          >
            {" (OPTIONAL)"}
          </Span>
        </FtsColumn>
        <ListingImagesBanner />
        <FtsColumn
          size={{ xs: 12 }}
          display={{ lg: "none", md: "block", sm: "block", xs: "none" }}
        >
          <Button
            mt={"2xs"}
            onClick={onUpload}
            size={ButtonSizes.md}
            disabled={!showUploadCTA}
            color={ButtonColorsValue.accent}
            type={ButtonTypes.outlined}
            iconName={IconNames.Upload}
            label={"Upload Files"}
          />
        </FtsColumn>
        <FtsColumn
          size={{ xs: 12 }}
          display={{ lg: "block", md: "none", sm: "none", xs: "none" }}
        >
          <Button
            mt={"2xs"}
            onClick={onUpload}
            size={ButtonSizes.sm}
            disabled={!showUploadCTA}
            color={ButtonColorsValue.accent}
            type={ButtonTypes.outlined}
            iconName={IconNames.Upload}
            label={"Upload Files"}
          />
        </FtsColumn>
        <UploadButton
          adsAssets={adsAssets}
          fileInputRef={fileInputRef}
          adsAssetsSize={adsAssetsSize}
          setListingData={setListingData}
          uploadAdsAssetsRequest={uploadAdsAssetsRequest}
        />
        <UploadedFiles
          mt={"3xl"}
          adsAssets={adsAssets}
          listingData={listingData}
          setListingData={setListingData}
          uploadAdsAssetsRequest={uploadAdsAssetsRequest}
        />
      </FtsRow>
    </FtsWrapper>
  );
};
