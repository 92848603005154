import FTSAxios from "../../../axios/fts.instance";
import logger from "../../../utility/logger";

export const UPDATE_USER_DRIVERS = "trucks/UPDATE_USER_DRIVERS";
export const RESET_USER_DRIVERS = "trucks/RESET_USER_DRIVERS";
export const SET_SELECTED_TRUCK_DRIVER = "trucks/SET_SELECTED_TRUCK_DRIVER";
export const UPDATE_USER_TRUCKS = "trucks/UPDATE_USER_TRUCKS";
export const RESET_USER_TRUCKS = "trucks/RESET_USER_TRUCKS";
export const SET_SELECTED_TRUCK = "trucks/SET_SELECTED_TRUCK";

export const getTrucks = (params) => {
  return () => {
    return FTSAxios.post("/user-trucks", params);
  };
};

export const getTrucksCounters = () => {
  return () => {
    return FTSAxios.get("/user-trucks/counters");
  };
};

export const addTruck = (data) => {
  return () => {
    return FTSAxios.post("/user-truck", data);
  };
};

export const editTruck = (data) => {
  return () => {
    return FTSAxios.put(`user-truck/${data.id}`, data);
  };
};

export const removeTruck = (id) => {
  return () => {
    return FTSAxios.delete(`user-trucks/${id}`);
  };
};

export const addUserTruckType = (data) => {
  return () => {
    return FTSAxios.post("/user-truck-types", data);
  };
};

export const unlinkDriver = () => {
  return () => {
    return FTSAxios.get("/users-drivers-unlink");
  };
};

export const removeDriver = (driverId) => {
  return () => {
    return FTSAxios.delete(`/users-drivers/remove/${driverId}`);
  };
};

export const collectUserTruckTypes = () => {
  return (dispatch) => {
    FTSAxios.get("/user-truck-types").then((res) => {
      dispatch({ type: "UPDATE_USER_TRUCK_TYPES_LIST", data: res.data });
    });
  };
};

export const getDrivers = (params) => {
  return () => {
    return FTSAxios.get("/users-drivers", { params });
  };
};

export const selectTruckDriver = (payload) => {
  return (dispatch) => {
    return dispatch({ type: SET_SELECTED_TRUCK_DRIVER, payload });
  };
};

export const resetUserDrivers = (data) => {
  return (dispatch) => {
    dispatch({ type: RESET_USER_DRIVERS, data });
  };
};

export const getTrucksRedux = (params) => {
  return () => {
    return FTSAxios.get("/user-trucks", { params });
  };
};

export const resetTrucks = () => {
  return (dispatch) => {
    dispatch({ type: RESET_USER_TRUCKS });
  };
};

export const selectTruck = (payload) => {
  return (dispatch) => {
    return dispatch({ type: SET_SELECTED_TRUCK, payload });
  };
};

export const resetUserTrucks = (data) => {
  return (dispatch) => {
    dispatch({ type: RESET_USER_TRUCKS, data });
  };
};

export const getDriverTruck = ({ truckId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      FTSAxios.get(`/user-truck/${truckId}`)
        .then(({ data }) => {
          resolve(data);
          dispatch({ type: SET_SELECTED_TRUCK, payload: data?.truck });
        })
        .catch((error) => {
          reject(error);
          logger.error(error);
        });
    });
  };
};

export const updateUsersDrivers = (data) => {
  return (dispatch) => {
    return dispatch({ type: UPDATE_USER_DRIVERS, data });
  };
};

export const updateUsersTrucks = (data) => {
  return (dispatch) => {
    return dispatch({ type: UPDATE_USER_TRUCKS, data });
  };
};

export const getLoggedUserTruck = () => {
  return () => {
    return FTSAxios.get("/user-truck");
  };
};

export const getLinkedTruck = ({ ownerId }) => {
  return () => {
    return FTSAxios.get(`/user-truck/linked/${ownerId}`);
  };
};
