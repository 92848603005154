import React, { Component } from "react";
import { Input } from "reactstrap";
import "../../../assets/scss/components/fts/promo-code-input-field-temporary.scss";

class PromoCodeInputFieldTemporary extends Component {
  state = { promoCodeText: "" };

  handleChange = (e) => {
    if (!e || !e.target.value.length) {
      this.props.setPromoCodeErrorMessage(null);
    }

    this.setState({
      promoCodeText: e?.target?.value || null,
    });
  };

  render() {
    const {
      applied,
      promoCode,
      setPromoCodeErrorMessage,
      setPromoCode,
      setDiscountForSelectedInvoices,
      setPromoCodeApplied,
      handleKeypress,
      onRemove,
    } = this.props;

    return (
      <div className="d-flex">
        <div className="promo-code-input-field">
          {!applied && (
            <>
              <Input
                disabled={applied}
                onChange={this.handleChange}
                type="text"
                placeholder="Enter promo code"
                onKeyPress={handleKeypress && handleKeypress}
              />
              <div
                className={`apply-button-temporary ${
                  !this.state.promoCodeText?.length
                    ? "apply-button-temporary-disabled"
                    : "apply-button-temporary-active"
                }`}
                disabled={!this.state.promoCodeText?.length || applied}
                onClick={() => {
                  this.props.onApplyPromoCode(this.state.promoCodeText);
                }}
              >
                Apply
              </div>
            </>
          )}

          {applied && (
            <div className="promo-code-input-field-applied">
              <div className="promo-code-applied">
                Promo code <strong>{promoCode?.promoCode}</strong> has been
                applied.
              </div>
              <div
                className="promo-code-remove"
                onClick={() => {
                  this.setState({ promoCodeText: "" }, () => {
                    setPromoCodeErrorMessage(null);
                    setPromoCode(null);
                    setDiscountForSelectedInvoices(null);
                    setPromoCodeApplied && setPromoCodeApplied(null);
                    onRemove && onRemove();
                  });
                }}
              >
                Remove Code
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PromoCodeInputFieldTemporary;
