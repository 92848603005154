import React from "react";
import { Pages } from "../../../constants/pages";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { LinkSizes } from "@find-truck-service/types/ui/link";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Link } from "@find-truck-service/ui/src/components/react/Link";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

export const DontHaveAnAccountText = () => {
  return (
    <FtsColumn
      gap={"2xs"}
      align={"center"}
      display={"flex"}
      size={{ xs: 12 }}
      justify={"center"}
    >
      <Span
        weight={WeightSize.regular}
        variant={VariantsValues["text-2xs"]}
        color={ColorsValue["greyscale-800"]}
      >
        Don't have an account?
      </Span>
      <Link href={Pages.REGISTER} label={"Register"} size={LinkSizes.lg} />
    </FtsColumn>
  );
};
