import React, { Component } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import "../../../assets/scss/components/fts/fts-contact-form-modal.scss";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { contactUs } from "../../../redux/actions/contact";
import SubjectPicker from "../fts-subject-picker";
import FormikInput from "../formik/formik-input";
import { Field, Form, Formik } from "formik";
import CloseButton from "../CloseButton";
import FTSModal from "../fts-modal";
import ContactUsConfirmationModal from "../fts-contact-us-confirmation-modal";
import logger from "../../../utility/logger";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

class ContactFormModalFleet extends Component {
  state = {
    inProgress: false,
    initialFormData: {
      subject: "",
      message: "",
    },
    isOpenContactUsConfirmationModal: false,
  };

  formSchema = Yup.object().shape({
    subject: Yup.object().shape().required("Subject is required!"),
    // subject: Yup.string().required("Subject is required!"),
    message: Yup.string().required("Message is required!"),
  });

  handleContactUsConfirmationModal = () => {
    this.setState({
      isOpenContactUsConfirmationModal:
        !this.state.isOpenContactUsConfirmationModal,
    });
  };

  handleSubmit = (values) => {
    if (this.state.inProgress) return;

    this.setState({ inProgress: true });

    const payload = {
      ...values,
      fullName: `${this.props.userData?.firstName} ${this.props.userData?.lastName}`,
      companyName: `${this.props.userData?.companyName}`,
      email: `${this.props.userData?.email}`,
      phoneNumber: `${this.props.userData?.phoneNumber}`,
      subject: values?.subject.value,
    };

    this.props
      .contactUs(payload)
      .then(() => {
        this.handleContactUsConfirmationModal();
        this.setState({ inProgress: false });
      })
      .catch((err) => {
        logger.error(err);
        this.props.closeModal();
      });
  };

  render() {
    const { isOpen, closeModal } = this.props;
    const { inProgress } = this.state;

    return (
      <>
        <FTSModal
          {...{ isOpen, closeModal }}
          modalClasses={"fts-contact-form-modal"}
          centered={true}
          style={{ maxWidth: "650px" }}
        >
          <div className="fts-contact-form-modal-body">
            <div className="fts-contact-form-modal-heading">
              <h2>Contact Find Truck Service</h2>
              <CloseButton
                className="close-btn"
                onClick={() => {
                  this.setState({ inProgress: false });
                  closeModal();
                }}
              />
            </div>
            <div className="fts-contact-form-modal-subheading">
              Please use our contact form below to send us your direct message.
            </div>

            <Formik
              initialValues={this.state.initialFormData}
              validationSchema={this.formSchema}
              enableReinitialize={true}
              validateOnChange={false}
              validateOnBlur={true}
              onSubmit={this.handleSubmit}
              onSubmitError={this.onSubmitError}
            >
              {({ setFieldError, setFieldTouched }) => {
                return (
                  <Form id="fts-contact-form">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Subject *</Label>
                          <Field name="subject">
                            {({ meta, form, field: { onBlur, value } }) => {
                              logger.info("VALUE: ", value);
                              return (
                                <SubjectPicker
                                  name="subject"
                                  onChange={(option) => {
                                    logger.info("OPTION: ", option);
                                    setFieldError("subject", undefined);
                                    form.setFieldValue("subject", option);
                                    setTimeout(() => {
                                      setFieldTouched("subject", true, true);
                                    }, 100);
                                  }}
                                  placeholder=""
                                  onBlur={onBlur}
                                  required
                                  {...{ meta, onBlur, value }}
                                />
                              );
                            }}
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormikInput
                          required
                          label={"Message"}
                          type="textarea"
                          name={"message"}
                          labelId={"message"}
                          rows={5}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col className="mr-0">
                        <Button
                          width="100%"
                          label="Cancel"
                          onClick={closeModal}
                          size={ButtonSizes.md}
                          type={ButtonTypes.outlined}
                          color={ButtonColorsValue.neutral}
                        />
                      </Col>
                      <Col className="pl-0">
                        <Button
                          width="100%"
                          label="Send"
                          loading={inProgress}
                          size={ButtonSizes.md}
                          form="fts-contact-form"
                          type={ButtonTypes.contained}
                          color={ButtonColorsValue.accent}
                        />
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </FTSModal>
        <ContactUsConfirmationModal
          isOpen={this.state.isOpenContactUsConfirmationModal}
          confirmModal={closeModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.authState.user,
  };
};

export default connect(mapStateToProps, { contactUs })(ContactFormModalFleet);
