import React, { useEffect, useRef } from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
// import { selectPaymentsInlineError } from "../../../../../redux/reducers/v2/payments";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { useSelector } from "react-redux";
import { history } from "../../../../../history";
import { selectTemporaryLinkCardError } from "../../../../../redux/reducers/v2/temporaryLinkUserCards";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

const PaymentOverviewHeader = () => {
  const inlineMessage = useSelector(selectTemporaryLinkCardError);
  const messageElementRef = useRef(null);

  useEffect(() => {
    if (messageElementRef.current) {
      messageElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [inlineMessage, messageElementRef]);

  function handleOnBackClick() {
    history.goBack();
  }

  return (
    <FtsColumn
      display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}
      direction="column"
      size={{ xs: 12, sm: 12, md: 12, lg: 12 }}
    >
      <FtsWrapper mb={Spacing.sm}>
        <Button
          label="Back"
          size={ButtonSizes.xs}
          type={ButtonTypes.text}
          onClick={handleOnBackClick}
          iconName={IconNames.ChevronLeft}
          color={ButtonColorsValue.neutral}
        />
      </FtsWrapper>
      {inlineMessage && (
        <FtsWrapper my={"lg"} ref={messageElementRef}>
          <Message type={"error"} title={""} subtitle={inlineMessage} />
        </FtsWrapper>
      )}
      <Span
        variant={VariantsValues["text-md"]}
        weight={WeightSize.bold}
        color={ColorsValue["greyscale-800"]}
      >
        Payment Overview
      </Span>
    </FtsColumn>
  );
};

export default PaymentOverviewHeader;
