import React, { memo } from "react";
import { DescriptionModalContent } from "./DescriptionModalContent";
import { ModalHeaderComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalHeaderComponent";
import { ModalFooterComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalFooterComponent";
import { FullSizeModal } from "@find-truck-service/ui/src/components/react/Modal/FullSizeModal";

export const DescriptionModal = ({ showModal, setShowModal }) => {
  function onSave() {
    setShowModal(false);
  }

  function onClose() {
    setShowModal(false);
  }

  return (
    <FullSizeModal
      isOpen={showModal}
      modalHeaderComponent={() => (
        <ModalHeaderComponent onClose={onClose} title={"Add Description"} />
      )}
      modalContentComponent={() => <DescriptionModalContent />}
      modalFooterComponent={() => (
        <ModalFooterComponent successLabel={"Save"} onSuccess={onSave} />
      )}
    />
  );
};

export const MemoizedDescriptionModal = memo(DescriptionModal);
