import React, { Component, lazy } from "react";
import classnames from "classnames";
import { ContextLayout } from "../../../../utility/context/Layout";
import { connect } from "react-redux";

import PerfectScrollbar from "react-perfect-scrollbar";
import userImgPlaceholder from "../../../../assets/img/portrait/small/avatar-s-11.jpg";

import {
  getNotificationsCount,
  setNotificationUnreadCount,
  setOpenNotificationsSidebar,
} from "../../../../redux/actions/notifications";
import { accountAccessRequestsStatuses } from "../../../../utility/constants";
import moment from "moment";
import socketHandler from "../../../../utility/socket";
import { setLoggedInUserData } from "../../../../redux/actions/auth/loginActions";
import { history } from "../../../../history";
import NavigationCreateListingBtn from "../../../../components/fts/navigation-create-listing-btn";
import PermissionGate from "../../../../components/fts/permission-gate/PermissionGate";
import { PERMISSIONS, PERMISSION_GATE_PRINCIPLE } from "../../../../constants";

const UserInfo = lazy(
  () => import("../../../../components/fts/userInfo/index"),
);
const SidebarHeader = lazy(() => import("./SidebarHeader"));
const SideMenuContent = lazy(() => import("./sidemenu/SideMenuContent"));
const AccountAccessActiveBox = lazy(
  () => import("../../../../components/fts/account-access-active-box"),
);
const AccountAccessRequestedBox = lazy(
  () => import("../../../../components/fts/account-access-requested-box"),
);
const ActionSidebar = lazy(
  () => import("../../../../components/fts/action-bar/action-sidebar"),
);

const HideLogoBrandNameDiv = () => (
  <div
    style={{
      width: 20,
      backgroundColor: "#CE1B3B",
      height: 80,
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: 1,
    }}
  ></div>
);

class Sidebar extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.activePath !== state.activeItem) {
      return {
        activeItem: props.activePath,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }

  state = {
    width: window.innerWidth,
    activeIndex: null,
    hoveredMenuItem: null,
    activeItem: this.props.activePath,
    menuShadow: false,
    ScrollbarTag: PerfectScrollbar,
    sidebar: false,
    action: null,
    unReadCount: 0,
  };

  mounted = false;

  updateWidth = () => {
    if (this.mounted) {
      this.setState(() => ({
        width: window.innerWidth,
      }));
      this.checkDevice();
    }
  };

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      if (window !== "undefined") {
        window.addEventListener("resize", this.updateWidth, false);
      }
      this.checkDevice();
    }

    if (this.props.userData) {
      const { id: userId } = this.props.userData;
      this.handleSocket("subscribeToAccountAccessRequest", {
        userId,
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    const { id: userId } = this.props.userData;

    this.handleSocket("usSubscribeToAccountAccessRequest", {
      userId,
    });
  }

  checkDevice = () => {
    var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
    var mq = function (query) {
      return window.matchMedia(query).matches;
    };

    if ("ontouchstart" in window || window.DocumentTouch) {
      this.setState({
        ScrollbarTag: "div",
      });
    } else {
      this.setState({
        ScrollbarTag: PerfectScrollbar,
      });
    }
    var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
      "",
    );
    return mq(query);
  };

  changeActiveIndex = (id) => {
    if (id !== this.state.activeIndex) {
      this.setState({
        activeIndex: id,
      });
    } else {
      this.setState({
        activeIndex: null,
      });
    }
  };

  handleSidebarMouseEnter = (id) => {
    if (id !== this.state.hoveredMenuItem) {
      this.setState({
        hoveredMenuItem: id,
      });
    } else {
      this.setState({
        hoveredMenuItem: null,
      });
    }
  };

  handleActiveItem = (url) => {
    this.setState({
      activeItem: url,
    });
  };

  handleSocket = (event, message) => {
    socketHandler.subscribeEvent(event);
    socketHandler.events[event].onOpen = () => {
      socketHandler.send(event, {
        ...message,
      });
      socketHandler.events[event].onMessage = this.onMessage;
    };

    if (socketHandler.socket.readyState === WebSocket.OPEN) {
      socketHandler.send(event, {
        ...message,
      });
      socketHandler.events[event].onMessage = this.onMessage;
    }
  };

  onMessage = (data) => {
    const userData = this.props.userData;
    const { messageBody: accountAccessRequests } = data;

    this.props.setLoggedInUserData({
      loggedInUser: {
        ...userData,
        accountAccessRequests: { ...accountAccessRequests },
      },
      loggedInWith: "jwt",
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.unReadCount !== this.state.unReadCount) {
      this.setState({ unReadCount: this.props.unReadCount });
    }

    if (
      prevProps.openNotificationsSidebar !== this.props.openNotificationsSidebar
    ) {
      this.props.openNotificationsSidebar && this.handleNotificationsNavItem();
    }
  }

  handleNotificationsNavItem = () => {
    if (window.innerWidth >= 768) {
      this.setState({
        sidebar: true,
        action: "notifications",
        actionItem: {},
      });
    } else {
      history.push("/dashboard/notifications");
      this.props.sidebarVisibility(false);
      window.scrollTo(0, 0);
    }
  };

  render() {
    let {
      visibilityState,
      toggleSidebarMenu,
      sidebarHover,
      toggle,
      color,
      sidebarVisibility,
      activeTheme,
      collapsed,
      activePath,
      sidebarState,
      currentLang,
      permission,
      currentUser,
      userData,
      collapsedMenuPaths,
      switchSideTab,
      setOpenNotificationsSidebar,
      isMobile,
    } = this.props;
    const accountAccessRequests = userData?.accountAccessRequests;

    let {
      menuShadow,
      activeIndex,
      hoveredMenuItem,
      activeItem,
      ScrollbarTag,
      sidebar,
      action,
      actionItem,
    } = this.state;

    let scrollShadow = (container, dir) => {
      if (container && dir === "up" && container.scrollTop >= 100) {
        this.setState({ menuShadow: true });
      } else if (container && dir === "down" && container.scrollTop < 100) {
        this.setState({ menuShadow: false });
      } else {
        return;
      }
    };

    const userImg = userImgPlaceholder;

    return (
      <ContextLayout.Consumer>
        {() => {
          return (
            <React.Fragment>
              <div
                className={classnames(
                  `main-menu menu-fixed menu-light menu-accordion menu-shadow theme-${activeTheme} ${
                    isMobile && "main-menu-mobile"
                  }`,
                  {
                    collapsed: sidebarState === true,
                    "hide-sidebar":
                      (this.state.width < 768 && visibilityState === false) ||
                      activePath === "/dashboard/on-boarding",
                  },
                )}
                onMouseEnter={() => sidebarHover(false)}
                onMouseLeave={() => sidebarHover(true)}
              >
                {/*We need to hide the logo brand name when sidebar is closed*/}
                {!isMobile && <HideLogoBrandNameDiv />}
                <SidebarHeader
                  {...this.props}
                  toggleSidebarMenu={toggleSidebarMenu}
                  toggle={toggle}
                  sidebarBgColor={color}
                  sidebarVisibility={sidebarVisibility}
                  activeTheme={activeTheme}
                  collapsed={collapsed}
                  menuShadow={menuShadow}
                  activePath={activePath}
                  sidebarState={sidebarState}
                  isMobile={isMobile}
                  {...{ switchSideTab }}
                />
                <UserInfo
                  userFullname={userData}
                  collapsed={isMobile ? false : this.props.collapsed}
                  sidebarState={this.props.sidebarState}
                  isMobile={isMobile}
                  toggleMenu={sidebarVisibility}
                  {...{ userImg }}
                />

                {accountAccessRequests?.status ===
                  accountAccessRequestsStatuses.granted &&
                  moment(accountAccessRequests?.expiresAt) > moment() && (
                    <AccountAccessActiveBox
                      sidebarState={this.props.sidebarState}
                      accountAccessRequests={accountAccessRequests}
                    />
                  )}

                {accountAccessRequests?.status ===
                  accountAccessRequestsStatuses.pending &&
                  moment(accountAccessRequests?.expiresAt).isAfter(
                    moment(),
                  ) && (
                    <AccountAccessRequestedBox
                      sidebarState={this.props.sidebarState}
                      accountAccessRequests={accountAccessRequests}
                    />
                  )}

                <ScrollbarTag
                  className={classnames("main-menu-content", {
                    "overflow-hidden": ScrollbarTag !== "div",
                    "overflow-scroll": ScrollbarTag === "div",
                    "main-menu-content-with-footer":
                      this.props.userData?.userType === 3 &&
                      window.innerWidth < 768,
                  })}
                  {...(ScrollbarTag !== "div" && {
                    options: { wheelPropagation: false },
                    onScrollDown: (container) =>
                      scrollShadow(container, "down"),
                    onScrollUp: (container) => scrollShadow(container, "up"),
                    onYReachStart: () =>
                      menuShadow === true &&
                      this.setState({ menuShadow: false }),
                  })}
                >
                  <ul className="navigation navigation-main">
                    <SideMenuContent
                      setActiveIndex={this.changeActiveIndex}
                      activeIndex={activeIndex}
                      hoverIndex={hoveredMenuItem}
                      handleSidebarMouseEnter={this.handleSidebarMouseEnter}
                      activeItemState={activeItem}
                      handleActiveItem={this.handleActiveItem}
                      activePath={activePath}
                      lang={currentLang}
                      permission={permission}
                      currentUser={currentUser}
                      collapsedMenuPaths={collapsedMenuPaths}
                      toggleMenu={sidebarVisibility}
                      deviceWidth={this.props.deviceWidth}
                      handleNotificationsNavItem={
                        this.handleNotificationsNavItem
                      }
                      sidebarState={this.props.sidebarState}
                      collapsed={isMobile ? false : this.props.collapsed}
                      unRead={this.state.unReadCount}
                    />
                  </ul>
                </ScrollbarTag>
                <PermissionGate
                  permissions={[
                    PERMISSIONS[this.props.userData?.userType]
                      ?.VENDOR_CREATE_LISTING,
                  ]}
                  principle={PERMISSION_GATE_PRINCIPLE.ALL}
                >
                  <NavigationCreateListingBtn />
                </PermissionGate>
              </div>

              <ActionSidebar
                show={sidebar}
                action={action}
                actionItem={actionItem}
                handleSidebar={() => {
                  this.props.openNotificationsSidebar &&
                    setOpenNotificationsSidebar(false);
                  this.setState({
                    sidebar: false,
                    action: "",
                    actionItem: {},
                  });
                }}
              />
            </React.Fragment>
          );
        }}
      </ContextLayout.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // currentcurrentUser: state.authState.user?.permissions,
    userData: state.authState.user,
    currentUser: state.authState.user?.permissions,
    unReadCount: state.notifications.unReadCount,
    openNotificationsSidebar: state.notifications.openNotificationsSidebar,
  };
};

export default connect(mapStateToProps, {
  getNotificationsCount,
  setNotificationUnreadCount,
  setOpenNotificationsSidebar,
  setLoggedInUserData,
})(Sidebar);
