import {
  ALL_DAY,
  BY_APPOINTMENT,
  CLOSED,
} from "../../../fts-components-v2/ListingBusinessInfo/constants";

export const hoursListFrom = [
  {
    label: ALL_DAY,
    value: ALL_DAY,
  },
  {
    label: BY_APPOINTMENT,
    value: BY_APPOINTMENT,
  },
  {
    label: CLOSED,
    value: CLOSED,
  },
  {
    value: "12 AM (Midnight)",
    label: "12 AM (Midnight)",
  },
  {
    value: "12:30 AM",
    label: "12:30 AM",
  },
  {
    label: "1 AM",
    value: "1 AM",
  },
  {
    label: "1:30 AM",
    value: "1:30 AM",
  },
  {
    label: "2 AM",
    value: "2 AM",
  },
  {
    label: "2:30 AM",
    value: "2:30 AM",
  },
  {
    label: "3 AM",
    value: "3 AM",
  },
  {
    label: "3:30 AM",
    value: "3:30 AM",
  },
  {
    label: "4 AM",
    value: "4 AM",
  },
  {
    label: "4:30 AM",
    value: "4:30 AM",
  },
  {
    label: "5 AM",
    value: "5 AM",
  },
  {
    label: "5:30 AM",
    value: "5:30 AM",
  },
  {
    label: "6 AM",
    value: "6 AM",
  },
  {
    label: "6:30 AM",
    value: "6:30 AM",
  },
  {
    label: "7 AM",
    value: "7 AM",
  },
  {
    label: "7:30 AM",
    value: "7:30 AM",
  },
  {
    label: "8 AM",
    value: "8 AM",
  },
  {
    label: "8:30 AM",
    value: "8:30 AM",
  },
  {
    label: "9 AM",
    value: "9 AM",
  },
  {
    label: "9:30 AM",
    value: "9:30 AM",
  },
  {
    label: "10 AM",
    value: "10 AM",
  },
  {
    label: "10:30 AM",
    value: "10:30 AM",
  },
  {
    label: "11 AM",
    value: "11 AM",
  },
  {
    label: "11:30 AM",
    value: "11:30 AM",
  },
  {
    value: "12 PM (Noon)",
    label: "12 PM (Noon)",
  },
  {
    value: "12:30 PM",
    label: "12:30 PM",
  },
  {
    label: "1 PM",
    value: "1 PM",
  },
  {
    label: "1:30 PM",
    value: "1:30 PM",
  },
  {
    label: "2 PM",
    value: "2 PM",
  },
  {
    label: "2:30 PM",
    value: "2:30 PM",
  },
  {
    label: "3 PM",
    value: "3 PM",
  },
  {
    label: "3:30 PM",
    value: "3:30 PM",
  },
  {
    value: "4 PM",
    label: "4 PM",
  },
  {
    value: "4:30 PM",
    label: "4:30 PM",
  },
  {
    label: "5 PM",
    value: "5 PM",
  },
  {
    label: "5:30 PM",
    value: "5:30 PM",
  },
  {
    label: "6 PM",
    value: "6 PM",
  },
  {
    label: "6:30 PM",
    value: "6:30 PM",
  },
  {
    label: "7 PM",
    value: "7 PM",
  },
  {
    label: "7:30 PM",
    value: "7:30 PM",
  },
  {
    label: "8 PM",
    value: "8 PM",
  },
  {
    label: "8:30 PM",
    value: "8:30 PM",
  },
  {
    label: "9 PM",
    value: "9 PM",
  },
  {
    label: "9:30 PM",
    value: "9:30 PM",
  },
  {
    label: "10 PM",
    value: "10 PM",
  },
  {
    label: "10:30 PM",
    value: "10:30 PM",
  },
  {
    label: "11 PM",
    value: "11 PM",
  },
  {
    label: "11:30 PM",
    value: "11:30 PM",
  },
];

export const hoursListTo = [
  {
    value: "12 AM (Midnight)",
    label: "12 AM (Midnight)",
  },
  {
    value: "12:30 AM",
    label: "12:30 AM",
  },
  {
    label: "1 AM",
    value: "1 AM",
  },
  {
    label: "1:30 AM",
    value: "1:30 AM",
  },
  {
    label: "2 AM",
    value: "2 AM",
  },
  {
    label: "2:30 AM",
    value: "2:30 AM",
  },
  {
    label: "3 AM",
    value: "3 AM",
  },
  {
    label: "3:30 AM",
    value: "3:30 AM",
  },
  {
    label: "4 AM",
    value: "4 AM",
  },
  {
    label: "4:30 AM",
    value: "4:30 AM",
  },
  {
    label: "5 AM",
    value: "5 AM",
  },
  {
    label: "5:30 AM",
    value: "5:30 AM",
  },
  {
    label: "6 AM",
    value: "6 AM",
  },
  {
    label: "6:30 AM",
    value: "6:30 AM",
  },
  {
    label: "7 AM",
    value: "7 AM",
  },
  {
    label: "7:30 AM",
    value: "7:30 AM",
  },
  {
    label: "8 AM",
    value: "8 AM",
  },
  {
    label: "8:30 AM",
    value: "8:30 AM",
  },
  {
    label: "9 AM",
    value: "9 AM",
  },
  {
    label: "9:30 AM",
    value: "9:30 AM",
  },
  {
    label: "10 AM",
    value: "10 AM",
  },
  {
    label: "10:30 AM",
    value: "10:30 AM",
  },
  {
    label: "11 AM",
    value: "11 AM",
  },
  {
    label: "11:30 AM",
    value: "11:30 AM",
  },
  {
    value: "12 PM (Noon)",
    label: "12 PM (Noon)",
  },
  {
    value: "12:30 PM",
    label: "12:30 PM",
  },
  {
    label: "1 PM",
    value: "1 PM",
  },
  {
    label: "1:30 PM",
    value: "1:30 PM",
  },
  {
    label: "2 PM",
    value: "2 PM",
  },
  {
    label: "2:30 PM",
    value: "2:30 PM",
  },
  {
    label: "3 PM",
    value: "3 PM",
  },
  {
    label: "3:30 PM",
    value: "3:30 PM",
  },
  {
    value: "4 PM",
    label: "4 PM",
  },
  {
    value: "4:30 PM",
    label: "4:30 PM",
  },
  {
    label: "5 PM",
    value: "5 PM",
  },
  {
    label: "5:30 PM",
    value: "5:30 PM",
  },
  {
    label: "6 PM",
    value: "6 PM",
  },
  {
    label: "6:30 PM",
    value: "6:30 PM",
  },
  {
    label: "7 PM",
    value: "7 PM",
  },
  {
    label: "7:30 PM",
    value: "7:30 PM",
  },
  {
    label: "8 PM",
    value: "8 PM",
  },
  {
    label: "8:30 PM",
    value: "8:30 PM",
  },
  {
    label: "9 PM",
    value: "9 PM",
  },
  {
    label: "9:30 PM",
    value: "9:30 PM",
  },
  {
    label: "10 PM",
    value: "10 PM",
  },
  {
    label: "10:30 PM",
    value: "10:30 PM",
  },
  {
    label: "11 PM",
    value: "11 PM",
  },
  {
    label: "11:30 PM",
    value: "11:30 PM",
  },
];
