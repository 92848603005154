import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  PERMISSION_GATE_DEFAULT_FALLBACK,
  PERMISSION_GATE_PRINCIPLE,
} from "../../../constants";
import {
  selectUserPermissions,
  selectUserSubPermissions,
} from "../../../redux/reducers/v2/auth";

const PermissionGate = (props) => {
  const [isPassed, setIsPassed] = useState(false);
  const userPermissions = useSelector(selectUserPermissions);
  const userSubPermissions = useSelector(selectUserSubPermissions);
  const {
    fallbackComponent,
    permissions = [],
    principle = PERMISSION_GATE_PRINCIPLE.ALL,
    children,
    forcePassed,
    subPermissions = [],
  } = props;

  useEffect(() => {
    if (forcePassed) return;

    if (principle === PERMISSION_GATE_PRINCIPLE.ALL) {
      let passed = true;
      for (let permission of permissions) {
        let userPermission = userPermissions?.find((e) => e.id === permission);
        if (!userPermission) passed = false;
      }
      //If all permissions passed then check sub permissions :)
      if (passed) {
        for (let subPermission of subPermissions) {
          let userSubPermission = userSubPermissions?.find(
            (e) => e.id === subPermission,
          );
          if (!userSubPermission) passed = false;
        }
      }
      setIsPassed(passed);
    }
  }, [
    userPermissions,
    principle,
    permissions,
    fallbackComponent,
    setIsPassed,
    forcePassed,
    subPermissions,
    userSubPermissions,
  ]);

  const FallbackComponent =
    fallbackComponent || PERMISSION_GATE_DEFAULT_FALLBACK;

  if (isPassed || forcePassed) {
    return <>{children}</>;
  }

  return <FallbackComponent />;
};

export default PermissionGate;
