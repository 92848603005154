import {
  REACTIVATE_ACCOUNT_REQUEST_FAILED,
  REACTIVATE_ACCOUNT_REQUEST,
  REACTIVATE_ACCOUNT_REQUEST_SUCCESS,
  SET_REACTIVATE_ACCOUNT_DATA,
  SET_REACTIVATE_ACCOUNT_INVALID_FORM_FIELDS,
} from "../../../reducers/v2/reactivateAccount";

export const setReactivateAccountData = (payload) => ({
  type: SET_REACTIVATE_ACCOUNT_DATA,
  payload,
});

export const setReactivateAccountInvalidFormFields = (payload) => ({
  type: SET_REACTIVATE_ACCOUNT_INVALID_FORM_FIELDS,
  payload,
});

export const reactivateAccountRequest = (payload) => ({
  type: REACTIVATE_ACCOUNT_REQUEST,
  payload,
});

export const reactivateAccountFailed = (payload) => ({
  type: REACTIVATE_ACCOUNT_REQUEST_FAILED,
  payload,
});

export const reactivateAccountSuccess = (payload) => ({
  type: REACTIVATE_ACCOUNT_REQUEST_SUCCESS,
  payload,
});
