import React from "react";
import { Button, Modal } from "reactstrap";
import { getListingTitle } from "../../../utility/getListingTitle";
import Icon from "../Icons";
import { listingTypeBenefits } from "../../../constants";
import "../../../assets/scss/components/fts/modals/benefits-modal.scss";

const BenefitsModal = (props) => {
  const { priceId, action, toggleModal, isSelected } = props;

  const selectedButtonText = `Selected ${getListingTitle({
    priceId,
  })} Listing`;

  const selectButtonText = `Select ${getListingTitle({
    priceId,
  })} Listing`;

  return (
    <Modal
      centered={true}
      isOpen={true}
      toggle={toggleModal}
      onClosed={toggleModal}
    >
      <div className="benefits-modal">
        <div className="benefits-modal-header">
          <span>Benefits</span>
          <Icon
            name={"Exit"}
            size={32}
            onClick={() => {
              toggleModal();
            }}
          />
        </div>

        <div className="benefits-modal-body">
          <p className="benefits-modal-body-message">
            {`With ${getListingTitle({
              priceId,
            })} listing, you will have:`}
          </p>
          {listingTypeBenefits[priceId].map((listingBenefit, index) => {
            return (
              <div
                className="benefits-modal-body-benefit"
                key={`listingBenefit_${index}`}
              >
                <div className="benefits-modal-body-benefit-icon">
                  <Icon name={listingBenefit?.icon} size={32} />
                </div>
                <div className="benefits-modal-body-benefit-text">
                  {listingBenefit.benefit}
                </div>
              </div>
            );
          })}

          <Button
            color={isSelected ? "success" : "primary"}
            className="benefits-modal-body-action w-100"
            onClick={action}
          >
            {isSelected ? selectedButtonText : selectButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BenefitsModal;
