import { apps, initializeApp, auth } from "firebase/app";
import { history } from "../../../history";
import "firebase/auth";
import "firebase/database";
import axios from "axios";
import { config } from "../../../authServices/firebase/firebaseConfig";
import FTSAxios, { storeCookies } from "../../../axios/fts.instance";
import FTSAxiosV2 from "../../../axios/ftsv2.instance";
import handleError from "../../../handlers/error";
import appConfig from "../../../configs/appConfig";
// import collectEssentialData from "../../../axios/collect-data";
import { getVendorDataWithEssentialLists } from "../users";
import { TEMPORARY_TOKEN } from "../../../cookies";
import collectEssentialData from "../../../axios/collect-data";
import logger from "../../../utility/logger";

// Init firebase if not already initialized
if (!apps.length) {
  initializeApp(config);
}

let firebaseAuth = auth();

export const signupWithFirebase = (email, password, name) => {
  return (dispatch) => {
    let userEmail = null,
      loggedIn = false;
    // userName = null

    firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        firebaseAuth.onAuthStateChanged((user) => {
          result.user.updateProfile({
            displayName: name,
          });
          if (user) {
            userEmail = user.email;
            // let userName = user.displayName
            loggedIn = true;
            localStorage.setItem("loggedInWith", "firebase");
            dispatch({
              type: "SIGNUP_WITH_EMAIL",
              payload: {
                email: userEmail,
                name,
                isSignedIn: loggedIn,
              },
            });
            dispatch({
              type: "LOGIN_WITH_EMAIL",
              payload: {
                email: userEmail,
                name,
              },
            });
          }
        });
        history.push("/");
      })
      .catch((error) => logger.error(error));
  };
};

export const signupWithJWT = (payload, onSuccess, onError) => {
  return (dispatch) => {
    FTSAxios.post("/registration", payload)
      .then((response) => {
        if (response.data) {
          storeCookies(response.data);
          FTSAxiosV2.get(`/users/me`, {
            headers: {
              Authorization: `Bearer ${response.data.token}`,
            },
          })
            .then((res) => {
              onSuccess(res);
              collectEssentialData(dispatch);
            })
            .catch((err) => {
              onError(err);
              handleError(err);
            });
        }
      })
      .catch((err) => {
        onError(err);
        handleError(err);
      });
  };
};

export const createSubAccount = (payload) => {
  return () => {
    return FTSAxiosV2.post("/users/invite-subuser", payload);
  };
};

export const updatePassword = (
  payload,
  onError,
  tmpHash,
  isDriverInvitation,
  onSuccess,
) => {
  return (dispatch) => {
    FTSAxios.post("profile/update-password", payload)
      .then(async (response) => {
        var loggedInUser;

        if (isDriverInvitation) {
          await axios.put(
            `${appConfig.apiURL}users-drivers/invitation`,
            {},
            {
              headers: {
                [TEMPORARY_TOKEN]: tmpHash,
              },
            },
          );
        }

        if (response.data) {
          localStorage.setItem("token", response.data.token);

          axios
            .get(`${appConfig.apiURL}user`, {
              headers: {
                Authorization: `Bearer ${response.data.token}`,
              },
            })
            .then((res) => {
              loggedInUser = res?.data?.user;

              dispatch({
                type: "LOGIN_WITH_JWT",
                payload: { loggedInUser, loggedInWith: "jwt" },
              });

              if (typeof onSuccess === "function") {
                onSuccess();
              }

              localStorage.setItem("loggedInWith", "jwt");
              getVendorDataWithEssentialLists(dispatch, "jwt");
            })
            .catch(handleError);
        }
      })
      .catch((error) => {
        onError(error);
        logger.error(error);
      });
  };
};
