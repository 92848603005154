import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { InputVariants } from "@find-truck-service/types/ui/input";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { PhoneNumberInput } from "@find-truck-service/ui/src/components/react/PhoneNumberInput";

export const ListingContactFields = (props) => {
  const {
    listingData,
    invalidFormFields,
    setListingData,
    setListingInvalidFormFields,
  } = props;
  const dispatch = useDispatch();

  const onChange = useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.value;
      dispatch(setListingData({ data: { [name]: value } }));
      const invalidFields = { data: { ...invalidFormFields, [name]: "" } };
      dispatch(setListingInvalidFormFields(invalidFields));
    },
    [invalidFormFields],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} width={"100%"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          CONTACT
        </Span>
      </FtsColumn>
      <FtsColumn size={{ sm: 12, md: 6, lg: 3 }}>
        <PhoneNumberInput
          required
          size={"md"}
          color={"accent"}
          onChange={onChange}
          variant={"outlined"}
          name={"phoneNumber"}
          label={"Phone (primary)"}
          value={listingData.phoneNumber}
          errorMessage={invalidFormFields["phoneNumber"]}
        />
      </FtsColumn>
      <FtsColumn size={{ sm: 12, md: 6, lg: 3 }}>
        <PhoneNumberInput
          size={"md"}
          color={"accent"}
          onChange={onChange}
          variant={"outlined"}
          label={"Phone (secondary)"}
          name={"phoneNumberSecondary"}
          value={listingData.phoneNumberSecondary}
          errorMessage={invalidFormFields["phoneNumberSecondary"]}
        />
      </FtsColumn>
      <FtsColumn size={{ sm: 12, md: 6, lg: 3 }}>
        <PhoneNumberInput
          size={"md"}
          color={"accent"}
          variant={"outlined"}
          onChange={onChange}
          name={"afterHours"}
          label={"Phone (after hours)"}
          value={listingData.afterHours}
          errorMessage={invalidFormFields["afterHours"]}
        />
      </FtsColumn>
      <FtsColumn size={{ sm: 12, md: 6, lg: 3 }}>
        <PhoneNumberInput
          size={"md"}
          name={"fax"}
          label={"Fax"}
          color={"accent"}
          variant={"outlined"}
          onChange={onChange}
          value={listingData.fax}
          errorMessage={invalidFormFields["fax"]}
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 12 }}>
        <Input
          size={"md"}
          name={"website"}
          label={"Website"}
          onChange={onChange}
          value={listingData.website}
          variant={InputVariants.outlined}
          errorMessage={invalidFormFields["website"]}
        />
      </FtsColumn>
    </FtsRow>
  );
};
