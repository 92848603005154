import React from "react";

import Icon from "../Icons";
import { Button } from "reactstrap";

import "../../../assets/scss/components/fts/invoices-temp-empty-state.scss";

export default class InvoicesTempEmptyState extends React.Component {
  render() {
    const {
      title = "All listings are paid!",
      subtitle = "Click on the button below to log into your account and view your listing details, download invoices, track your listing performance and more.",
      buttonLabel = "Go to dashboard",
      onButtonClick = () => {},
    } = this.props;
    return (
      <div className="invoices-temp-empty-state">
        <Icon name="ListingPaid" size={320} />
        <h2>{title}</h2>
        <p>{subtitle}</p>
        <Button color="primary" onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      </div>
    );
  }
}
