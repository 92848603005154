import {
  FROM_DEFAULT,
  SUNDAY_FROM,
  SUNDAY_SHORT,
  TO_DEFAULT,
} from "../constants";
import appConfig from "../../../../configs/appConfig";
import { GOOGLE_MAPS_GEOCODE_API_URL } from "../../../../constants";
import { formatImageLink } from "../../../../utility";

export const capitalizeFirstLetter = (string = "") =>
  string && `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export function getDayValues(d, workingHours) {
  const day = d.toLowerCase();
  const dayFromName = day + "From";
  const dayToName = day + "To";

  // Default values - must match with the saga
  let dayFromValue = { label: FROM_DEFAULT, value: FROM_DEFAULT };
  if (d === SUNDAY_SHORT)
    dayFromValue = { label: SUNDAY_FROM, value: SUNDAY_FROM };
  if (workingHours && workingHours[dayFromName])
    dayFromValue = workingHours[dayFromName];

  let dayToValue = { label: TO_DEFAULT, value: TO_DEFAULT };
  if (workingHours && workingHours[dayToName])
    dayToValue = workingHours[dayToName];

  return { day, dayFromName, dayToName, dayFromValue, dayToValue };
}

export function formatFileSize(size) {
  if (!size) return "";
  let number = parseFloat(size);
  if (isNaN(number)) return "";
  let formattedNumber = number.toFixed(2);
  return formattedNumber + "MB";
}

const USA_CENTER_LATITUDE = 39.8283;
const USA_CENTER_LONGITUDE = -98.5795;

const centerOfUSA = {
  latitude: USA_CENTER_LATITUDE,
  longitude: USA_CENTER_LONGITUDE,
};

export async function fetchGeolocation(searchData) {
  try {
    const { address, city, state, zip } = searchData;
    const addressString = address + ", " + city + ", " + state + ", " + zip;
    const config = `&key=${appConfig.googleApiKey}`;
    const params = `address=${encodeURIComponent(addressString)}${config}`;
    const url = `${GOOGLE_MAPS_GEOCODE_API_URL}${params}`;
    const response = await fetch(url).then((res) => res.json());
    if (!response?.results?.length) return centerOfUSA;
    return {
      latitude: response.results[0].geometry.location.lat,
      longitude: response.results[0].geometry.location.lng,
    };
  } catch {
    return centerOfUSA;
  }
}

export function getListingImageSrc(listingData = {}) {
  return listingData?.approved?.toLowerCase() === "yes"
    ? formatImageLink(listingData?.image)
    : "";
}
