import { createSelector } from "reselect";

export const GET_USER_CARDS_REQUEST = "CARDS/GET_USER_CARDS_REQUEST";
export const GET_USER_CARDS_SUCCESS = "CARDS/GET_USER_CARDS_SUCCESS";
export const GET_USER_CARDS_FAILED = "CARDS/GET_USER_CARDS_FAILED";

export const CREATE_USER_CARD_REQUEST = "CARDS/CREATE_USER_CARD_REQUEST";
export const CREATE_USER_CARD_SUCCESS = "CARDS/CREATE_USER_CARD_SUCCESS";
export const CREATE_USER_CARD_FAILED = "CARDS/CREATE_USER_CARD_FAILED";

export const GET_USER_CARDS_BY_TMP_TOKEN_REQUEST =
  "CARDS/GET_USER_CARDS_BY_TMP_TOKEN_REQUEST";
export const GET_USER_CARDS_BY_TMP_TOKEN_SUCCESS =
  "CARDS/GET_USER_CARDS_BY_TMP_TOKEN_SUCCESS";
export const GET_USER_CARDS_BY_TMP_TOKEN_FAILED =
  "CARDS/GET_USER_CARDS_BY_TMP_TOKEN_FAILED";

export const CREATE_USER_CARD_BY_TMP_TOKEN_REQUEST =
  "CARDS/CREATE_USER_CARD_BY_TMP_TOKEN_REQUEST";
export const CREATE_USER_CARD_BY_TMP_TOKEN_SUCCESS =
  "CARDS/CREATE_USER_CARD_BY_TMP_TOKEN_SUCCESS";
export const CREATE_USER_CARD_BY_TMP_TOKEN_FAILED =
  "CARDS/CREATE_USER_CARD_BY_TMP_TOKEN_FAILED";

export const SET_NEW_CARD_DATA = "CARDS/SET_NEW_CARD_DATA";
export const SET_NEW_CARD_DATA_INVALID_FORM_FIELDS =
  "CARDS/SET_NEW_CARD_DATA_INVALID_FORM_FIELDS";

export const CLEAR_ERRORS = "CARDS/CLEAR_ERRORS";
export const initialNewCardData = {
  cardNumber: "",
  cardZipCode: "",
  cardHolderName: "",
  cardCvc: "",
  cardExpirationDate: "",
  isDefault: true,
};
const initialState = {
  isLoading: false,
  error: null,
  cardsData: null,
  newCardData: initialNewCardData,
  newCardInvalidFormFields: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_CARDS_REQUEST:
    case CREATE_USER_CARD_REQUEST:
    case GET_USER_CARDS_BY_TMP_TOKEN_REQUEST:
    case CREATE_USER_CARD_BY_TMP_TOKEN_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    case GET_USER_CARDS_BY_TMP_TOKEN_SUCCESS:
    case GET_USER_CARDS_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        cardsData: action.payload.data,
      };
    case CREATE_USER_CARD_BY_TMP_TOKEN_SUCCESS:
    case CREATE_USER_CARD_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case GET_USER_CARDS_FAILED:
    case CREATE_USER_CARD_FAILED:
    case GET_USER_CARDS_BY_TMP_TOKEN_FAILED:
    case CREATE_USER_CARD_BY_TMP_TOKEN_FAILED:
      return {
        ...state,
        error: action.payload.data,
        isLoading: false,
      };

    case SET_NEW_CARD_DATA:
      return {
        ...state,
        newCardData: action.payload.data,
      };
    case SET_NEW_CARD_DATA_INVALID_FORM_FIELDS:
      return {
        ...state,
        newCardInvalidFormFields: action.payload.data,
      };

    default:
      return state;
  }
}

const selectCardsState = (state) => state.cardsState;

export const selectCardsData = createSelector(
  selectCardsState,
  (cardsState) => {
    return cardsState.cardsData;
  },
);
export const selectIsLoadingCardsData = createSelector(
  selectCardsState,
  (cardsState) => {
    return cardsState.isLoading;
  },
);

export const selectNewCardData = createSelector(
  selectCardsState,
  (cardsState) => {
    return cardsState.newCardData;
  },
);

export const selectNewCardInvalidFormFields = createSelector(
  selectCardsState,
  (cardsState) => {
    return cardsState.newCardInvalidFormFields;
  },
);

export const selectCardError = createSelector(
  selectCardsState,
  (cardsState) => {
    return cardsState.error;
  },
);
