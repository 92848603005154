import React from "react";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { InvoiceSkeletonLoader } from "@find-truck-service/ui/src/components/react/InvoiceSkeletonLoader";

import ListingTemporaryLinkPaymentOverviewPaymentMethod from "./components/ListingTemporaryLinkPaymentOverviewPaymentMethod/ListingTemporaryLinkPaymentOverviewPaymentMethod";
import PaymentOverviewHeader from "./components/PaymentOverviewHeader/PaymentOverviewHeader";

import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { SOMETHING_WENT_WRONG, UNEXPECTED_ISSUE } from "utility/errorMessages";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { history } from "../../../history";
import { CenteredLayout } from "../Layouts/CenteredLayout";
import SingleInvoiceInfo from "../SingleInvoiceInfo/SingleInvoiceInfo";
import { useSelector } from "react-redux";
import {
  selectTemporaryListingLinkInvoices,
  selectTemporaryLinkIsLoading,
  selectTemporaryLinkError,
  selectTemporaryLinkInlineError,
  selectTemporaryLinkListingPreviewData,
} from "../../../redux/reducers/v2/temporaryListingLink";
import { selectTemporaryLinkPaymentsIsLoading } from "../../../redux/reducers/v2/temporaryLinkPayments";

const TemporaryListingLinkPaymentOverview = () => {
  const listingInvoices = useSelector(selectTemporaryListingLinkInvoices);
  const listingData = useSelector(selectTemporaryLinkListingPreviewData);

  const isLoading = useSelector(selectTemporaryLinkIsLoading);
  const isPaymentLoading = useSelector(selectTemporaryLinkPaymentsIsLoading);
  const inlineMessage = useSelector(selectTemporaryLinkInlineError);
  const error = useSelector(selectTemporaryLinkError);

  return (
    <>
      {error && !isLoading ? (
        <FtsWrapper mt="4xl">
          <Error
            title={SOMETHING_WENT_WRONG}
            subtitle={inlineMessage || UNEXPECTED_ISSUE}
            iconName="AlertTriangle"
            containedButton={{
              label: "Refresh",
              color: ButtonColorsValue.accent,
              onClick: () => {
                history.go(0);
              },
            }}
          />
        </FtsWrapper>
      ) : (
        <FtsWrapper bg={ColorsValue["greyscale-50"]}>
          <FtsContainer>
            {isLoading || (isPaymentLoading && <FullPageLoader />)}
            <CenteredLayout>
              <FtsRow
                px={{ xs: "xl", sm: "4xl", md: "4xl", lg: "4xl" }}
                py={{ xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" }}
                bg={ColorsValue["greyscale-0"]}
                borderRadius="2xs"
                boxShadow="sm"
                size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
              >
                <PaymentOverviewHeader />
                {!listingInvoices?.length ? (
                  <FtsColumn size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                    <InvoiceSkeletonLoader />
                  </FtsColumn>
                ) : (
                  <SingleInvoiceInfo
                    invoiceData={listingInvoices?.[0]}
                    listingData={listingData}
                  />
                )}
                <ListingTemporaryLinkPaymentOverviewPaymentMethod />
              </FtsRow>
            </CenteredLayout>
          </FtsContainer>
        </FtsWrapper>
      )}
    </>
  );
};

export default TemporaryListingLinkPaymentOverview;
