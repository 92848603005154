export const getListingTitle = ({ priceId }) => {
  switch (priceId) {
    case 1:
      return "Premium";
    case 2:
      return "Standard";
    case 3:
      return "Basic Plus";
    case 4:
      return "Basic";
    case 5:
      return "Free";
    default:
      return "Provide priceId";
  }
};
