import FTSAxios from "../../../axios/fts.instance";

export const createNotification = (payload) => {
  return () => {
    return FTSAxios.post("profile/notifications", payload);
  };
};

export const getNotifications = (params) => {
  return () => {
    return FTSAxios.get("profile/notifications", { params });
  };
};

export const getNotificationsCount = () => {
  return () => {
    return FTSAxios.get("profile/notifications/count");
  };
};

export const markAsRead = (id) => {
  return () => {
    return FTSAxios.get(`profile/notifications/read/${id}`);
  };
};

export const setNotificationUnreadCount = (payload) => {
  return (dispatch) => {
    return dispatch({ type: "SET_NOTIFICATIONS_UNREAD_COUNTER", payload });
  };
};

export const setNotificationsData = (payload) => {
  return (dispatch) => {
    return dispatch({ type: "SET_NOTIFICATIONS", payload });
  };
};

export const setOpenNotificationsSidebar = (payload) => {
  return (dispatch) => {
    return dispatch({ type: "SET_OPEN_NOTIFICATIONS", payload });
  };
};

export const createAdminNotification = (payload) => {
  return () => {
    return FTSAxios.post("profile/notifications/admin", payload);
  };
};
