import React from "react";
import { modalContentComponents } from "./Constants";
import { FullSizeModal } from "@find-truck-service/ui/src/components/react/Modal/FullSizeModal";
import { ModalFooterComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalFooterComponent";
import { ModalHeaderComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalHeaderComponent";
export const PreviewListingModal = (props) => {
    const { isOpen, onClose, title, modalFooterProps, ...modalContentProps } = props;
    const ModalContent = modalContentComponents[props.contentType];
    return (React.createElement(FullSizeModal, { isOpen: props.isOpen, modalHeaderComponent: () => React.createElement(ModalHeaderComponent, { title: title, onClose: onClose }), modalContentComponent: () => React.createElement(ModalContent, { ...modalContentProps }), modalFooterComponent: () => React.createElement(ModalFooterComponent, { ...modalFooterProps }) }));
};
