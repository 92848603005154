import React, { lazy } from "react";
import { Activity, Search, Phone, Share2, ThumbsUp } from "react-feather";
import Icons from "../components/fts/Icons";
import { PERMISSIONS, USER_TYPE } from "../constants";
import { EVENT_MEASUREMENTS } from "../constants/analytics";
import NotificationsFull from "../views/pages/notifications-full";

const recentSearches = lazy(() => import("../views/pages/recent-searches"));
const preferredVendors = lazy(() => import("../views/pages/preferred-vendors"));
const notes = lazy(() => import("../views/pages/notes"));
const callLogs = lazy(() => import("../views/pages/call-logs"));
const sharedVendors = lazy(() => import("../views/pages/shared-vendors"));
const ratings = lazy(() => import("../views/pages/recommendations"));
const companyDriverProfile = lazy(
  () => import("../views/pages/company-driver-profile"),
);
const linkedCompany = lazy(() => import("../views/pages/linked-company"));
const privateLocation = lazy(() => import("../views/pages/private-location"));
// const financing = lazy(() => import("../views/pages/financing"));
const changeMyPassword = lazy(() => import("../views/pages/change-password"));
const ownerOperatorDashboard = lazy(
  () => import("../views/pages/owner-operator-dashboard"),
);
const downloadApp = lazy(() => import("../views/pages/download-app"));

export const companyDriverRoutes = [
  {
    key: "notifications",
    path: "/dashboard/notifications",
    title: "Notifications",
    type: "item",
    notificationNavItem: true,
    isProtected: true,
    component: NotificationsFull,
    icon: <Activity size={20} />,
    permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_NOTIFICATIONS,
    interactionType: EVENT_MEASUREMENTS.VIEW_NOTIFICATIONS,
  },
  {
    key: "my-profile",
    path: "/dashboard/my-profile",
    isProtected: true,
    component: companyDriverProfile,
    permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_MY_PROFILE,
    interactionType: EVENT_MEASUREMENTS.VIEW_MY_PROFILE,
  },

  {
    key: "change-my-password",
    path: "/dashboard/change-my-password",
    isProtected: true,
    component: changeMyPassword,
    permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_CHANGE_PASSWORD,
    interactionType: EVENT_MEASUREMENTS.CHANGE_PASSWORD,
  },
  {
    key: "driver-dashboard",
    path: "/dashboard",
    title: "Dashboard",
    type: "item",
    isProtected: true,
    component: ownerOperatorDashboard,
    icon: <Activity size={20} />,
    permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_DASHBOARD,
    interactionType: EVENT_MEASUREMENTS.VIEW_DASHBOARD,
  },
  {
    key: "recent-searches",
    path: "/dashboard/recent-searches",
    title: "Searches",
    type: "item",
    component: recentSearches,
    icon: <Search size={20} />,
    permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_RECENT_SEARCHES,
    interactionType: EVENT_MEASUREMENTS.VIEW_RECENT_SEARCHES,
  },
  {
    key: "preferred-vendors",
    path: "/dashboard/preferred-vendors",
    title: "Vendors",
    type: "item",
    isProtected: true,
    redirectToMobile: "PreferredVendors",
    component: preferredVendors,
    icon: <Icons name="Vendors" size={20} />,
    permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_PREFFERED_VENDORS,
    interactionType: EVENT_MEASUREMENTS.VIEW_PREFERRED_VENDORS,
  },
  {
    key: "notes",
    path: "/dashboard/notes",
    title: "Notes",
    type: "item",
    isProtected: true,
    redirectToMobile: "Notes",
    component: notes,
    icon: <Icons name="TodoNote" size={20} />,
    permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_NOTES,
    interactionType: EVENT_MEASUREMENTS.VIEW_NOTES,
  },
  {
    key: "call-logs",
    path: "/dashboard/call-logs",
    title: "Call Logs",
    type: "item",
    isProtected: true,
    redirectToMobile: "CallsListing",
    component: callLogs,
    icon: <Phone size={20} />,
    permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_CALL_LOGS,
    interactionType: EVENT_MEASUREMENTS.VIEW_CALL_LOGS,
  },
  {
    key: "shared-vendors",
    path: "/dashboard/shared-vendors",
    title: "Shared",
    type: "item",
    isProtected: true,
    redirectToMobile: "SharedVendorsStack",
    component: sharedVendors,
    icon: <Share2 size={20} />,
    permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_SHARED_VENDORS,
    interactionType: EVENT_MEASUREMENTS.VIEW_SHARED_VENDORS,
  },
  {
    key: "ratings",
    path: "/dashboard/ratings",
    title: "Ratings",
    type: "item",
    isProtected: true,
    redirectToMobile: "RatingsStack",
    component: ratings,
    icon: <ThumbsUp size={20} />,
    permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_RECOMMENDATIONS,
    interactionType: EVENT_MEASUREMENTS.VIEW_RECOMMENDATIONS,
  },
  {
    key: "linkedCompany",
    path: "/dashboard/linked-company",
    isProtected: true,
    component: linkedCompany,
    icon: "",
    permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_LINKED_COMPANY,
    interactionType: EVENT_MEASUREMENTS.VIEW_LINKED_COMPANY,
    badge: "white",
    badgeText: "BETA",
  },
  // {
  //   key: "financing",
  //   path: "/dashboard/financing",
  //   title: "Financing",
  //   type: "item",
  //   isProtected: true,
  //   component: financing,
  //   icon: <Icons name="DollarSign" size={20} />,
  //   permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_FINANCING,
  //   interactionType: EVENT_MEASUREMENTS.VIEW_FINANCING,
  // },
  {
    key: "download-app",
    path: "/dashboard/download-app",
    isProtected: true,
    component: downloadApp,
    permission: PERMISSIONS[USER_TYPE.COMPANY_DRIVER].DRIVER_DOWNLOAD_APP,
    interactionType: EVENT_MEASUREMENTS.DOWNLOAD_APP,
  },

  {
    key: "private-location",
    path: "/dashboard/private-location",
    noSidebar: true,
    isProtected: true,
    exact: false,
    fullLayout: true,
    component: privateLocation,
    icon: "",
  },
];
