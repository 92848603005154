import React, { useCallback, useEffect, useState } from "react";
import { FtsContainer } from "../dist/ui/src/FtsContainer/FtsContainer";
import { FtsWrapper } from "../dist/ui/src/FtsWrapper/FtsWrapper";
import { FtsRow } from "../dist/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "../dist/ui/src/FtsColumn/FtsColumn";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { MessageInline } from "@find-truck-service/ui/src/components/react/MessageInline";
import VerificationRequiredActionsXs from "./components/VerificationRequiredActionsXs";
import VerificationRequiredActionsLg from "./components/VerificationRequiredActionsLg";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../history";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  generateUserLoginMfaRequest,
  setUserMfaCodeValidationMessage,
  temporaryTokenResendVerifuUserMfaRequest,
  temporaryTokenVerifyUserMfaRequest,
} from "../../../redux/actions/v2/userMfas";
import {
  selectUserMfasError,
  selectUserMfasErrorMessage,
  selectUserMfasIsGenerateLoading,
  selectUserMfasIsVerifyLoading,
  selectUserMfasValidationMessage,
} from "../../../redux/reducers/v2/userMfas";
import { SOMETHING_WENT_WRONG } from "../../../utility/errorMessages";
import { selectShareProviderUserData } from "../../../redux/reducers/v2/shareProvider";
import { SESSION_STORAGE_KEYS } from "../../../utility/constants";
import { extractParameter } from "../../../utility/param-extract";
import logger from "../../../utility/logger";

export const userMfaCodeValidator = Yup.object().shape({
  code: Yup.string()
    .test("len", "Required", (val) => val.length === 3)
    .required("Required"),
});

const VerificationRequired = () => {
  const dispatch = useDispatch();
  const isGenerateLoading = useSelector(selectUserMfasIsGenerateLoading);
  const isVerifyLoading = useSelector(selectUserMfasIsVerifyLoading);
  const error = useSelector(selectUserMfasError);
  const errorMessage = useSelector(selectUserMfasErrorMessage);
  const userData = useSelector(selectShareProviderUserData);
  const userMfaValidationMessage = useSelector(selectUserMfasValidationMessage);
  const [code, setCode] = useState(["", "", ""]);
  const temporaryTokenEmail = sessionStorage.getItem(
    SESSION_STORAGE_KEYS.TEMPORARY_TOKEN_USER_EMAIL,
  );

  const handleChange = (e, index) => {
    const value = e.target.value;
    const isCodeFull = !!code[code.length - 1] && !!value;
    if (isNaN(value) || isCodeFull) return;

    if (value.length > 1) {
      let newCode = value
        .toString()
        .split("")
        .map((f) => f)
        .splice(0, 3);

      setCode(newCode);
      const element = document.getElementById(`code-input-${code.length - 1}`);
      const input = element.querySelector("input");
      if (input) {
        input.focus();
      }
      return;
    }
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < 2) {
      const element = document.getElementById(`code-input-${index + 1}`);
      const input = element.querySelector("input");
      if (input) {
        input.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      const element = document.getElementById(`code-input-${index - 1}`);
      const input = element.querySelector("input");
      if (input) {
        input.focus();
      }
    }
  };

  const onVerifySuccess = () => {
    const redirectTo = extractParameter("redirectTo");
    if (redirectTo) return history.replace(redirectTo);
    return history.replace("/dashboard");
  };

  const onVeirfyError = ({ inlineMessage }) => logger.error(inlineMessage);

  const onGenerateLoginMfaRequestError = ({ inlineMessage }) => {
    toast.error(inlineMessage || SOMETHING_WENT_WRONG);
    return history.push("/dashboard/login");
  };

  useEffect(() => {
    dispatch(
      generateUserLoginMfaRequest({ onError: onGenerateLoginMfaRequestError }),
    );
  }, [dispatch]);

  const handleVerifyCode = useCallback(async () => {
    try {
      const codeValue = code.join("");
      await userMfaCodeValidator.validate({ code: codeValue });

      dispatch(
        temporaryTokenVerifyUserMfaRequest({
          data: { code: codeValue },
          onSuccess: onVerifySuccess,
          onError: onVeirfyError,
        }),
      );
    } catch (e) {
      if (e.name === "ValidationError") {
        dispatch(
          setUserMfaCodeValidationMessage({
            data: "Code required!",
          }),
        );
      }
    }
  }, [code]);

  const handleResendVerifcationCode = useCallback(() => {
    dispatch(temporaryTokenResendVerifuUserMfaRequest());
  }, [code]);

  if (isGenerateLoading) return <FullPageLoader />;

  return (
    <FtsContainer>
      {isVerifyLoading && <FullPageLoader />}
      <FtsWrapper mt={"2xl"}>
        <FtsRow>
          <FtsColumn
            size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
            offset={{ xs: 0, sm: 0, md: 0, lg: 3 }}
          >
            <FtsWrapper
              display="flex"
              direction="column"
              gap="2xl"
              bg={ColorsValue["greyscale-0"]}
              borderRadius="2xs"
              boxShadow="sm"
              py={{ xs: "xl", sm: "4xl", md: "4xl", lg: "4xl" }}
              px={{ xs: "2xl", sm: "4xl", md: "4xl", lg: "4xl" }}
            >
              {error && (
                <Message
                  type={"error"}
                  title={""}
                  subtitle={errorMessage || SOMETHING_WENT_WRONG}
                />
              )}

              <Span
                variant={VariantsValues["text-md"]}
                color={ColorsValue["greyscale-800"]}
                weight="bold"
              >
                Verification Required
              </Span>
              <FtsWrapper display="flex" direction="column">
                <Span
                  variant={VariantsValues["text-2xs"]}
                  color={ColorsValue["greyscale-500"]}
                >
                  A verification code has been sent to:
                </Span>
                <Span
                  variant={VariantsValues["text-2xs"]}
                  color={ColorsValue["greyscale-800"]}
                  weight="bold"
                >
                  {userData?.email || temporaryTokenEmail}
                </Span>
                <hr style={{ width: "100%" }} />
                <Span
                  variant={VariantsValues["text-2xs"]}
                  color={ColorsValue["greyscale-500"]}
                >
                  Please check your inbox and enter the verification code below
                  to verify your account. The code will expire in 24 hour.
                </Span>
                <Span
                  variant={VariantsValues["text-2xs"]}
                  color={ColorsValue["greyscale-800"]}
                  mt="xl"
                >
                  Don’t see the email? Please check your spam/junk folder.
                </Span>
              </FtsWrapper>
              <FtsWrapper
                display="flex"
                justify="center"
                direction="column"
                align="center"
                gap="md"
              >
                <FtsWrapper display="flex" gap="md">
                  {code.map((digit, index) => {
                    return (
                      <FtsWrapper width="48px" key={index}>
                        <Input
                          invalid={!!userMfaValidationMessage}
                          name={""}
                          id={`code-input-${index}`}
                          variant={InputVariants.outlined}
                          size={InputSizes.md}
                          value={digit}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          textAlign="center"
                          maxlength={1}
                        />
                      </FtsWrapper>
                    );
                  })}
                </FtsWrapper>
                {userMfaValidationMessage && (
                  <MessageInline
                    size="md"
                    message={userMfaValidationMessage}
                    type="error"
                  />
                )}
              </FtsWrapper>
              <VerificationRequiredActionsLg
                onResendCode={handleResendVerifcationCode}
                onVerify={handleVerifyCode}
              />
            </FtsWrapper>
          </FtsColumn>
        </FtsRow>
        <VerificationRequiredActionsXs
          onResendCode={handleResendVerifcationCode}
          onVerify={handleVerifyCode}
        />
      </FtsWrapper>
    </FtsContainer>
  );
};

export default VerificationRequired;
