import React from "react";
import { Input } from "@find-truck-service/ui/src/components/react/Input/index";
export const PriceInput = (props) => {
    const onChangePriceValue = (event) => {
        let value = event.target.value?.trim();
        // Replace commas with dots
        value = value.replace(/,/g, ".");
        if (value.startsWith("00"))
            value = "0.";
        // Remove multiple dots
        value = value.replace(/(\..*?)\./g, "$1");
        // Allow only numbers and a single dot
        value = value.replace(/[^0-9.]/g, "");
        // Prevent a dot at the beginning
        if (value.startsWith("."))
            value = "0" + value;
        // Limit to two decimal places
        if (value.includes(".")) {
            const [integerPart, decimalPart] = value.split(".");
            value = integerPart + "." + decimalPart.slice(0, 2);
        }
        const data = { ...event, target: { ...event.target, value } };
        props.onChange && props.onChange(data);
    };
    return (React.createElement(Input, { ...props, pattern: "^\\d*(\\.\\d{0,2})?$", maxLength: 10, onChange: onChangePriceValue, inputMode: "decimal" }));
};
