import React from "react";
import { useSelector } from "react-redux";
import { setListingData } from "../../../../redux/actions/v2/listing";
import { AmenitiesModalContentFields } from "./AmenitiesModalContentFields";
import { selectListingAdsAmenities } from "../../../../redux/reducers/v2/listing";

export const AmenitiesModalContent = () => {
  const selectedAmenities = useSelector(selectListingAdsAmenities);

  return (
    <AmenitiesModalContentFields
      selectedAmenities={selectedAmenities}
      setListingData={setListingData}
    />
  );
};
