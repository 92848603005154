import React from "react";
import { ButtonsFooter } from "@find-truck-service/ui/src/components/react/ButtonsFooter";
import { useHistory } from "react-router-dom";

export const CreatedFooterButtons = () => {
  const history = useHistory();

  return (
    <ButtonsFooter
      outlinedButtonProps={{
        label: "Search",
        onClick: () => (window.location.href = "/"),
      }}
      containedButtonProps={{
        label: "Dashboard",
        onClick: () => history.push("/dashboard"),
      }}
    />
  );
};
