import React from "react";
import FTSModal from "../fts-modal";
import ListingItem from "../listing-item";
import HeaderCTA from "./header-cta";
import "../../../assets/scss/components/free-listing-preview-modal.scss";

const ListingInfoModal = ({
  handleClose,
  listingData,
  titleDescription,
  hideActions,
}) => {
  listingData = { ...listingData };
  return (
    <div>
      <FTSModal
        isOpen
        centered
        title="Listing Preview:"
        titleDescription="free"
        modalClasses="fts-modal fts-modal__free-listing-preview"
        headerClasses="modal-header"
        closeModal={handleClose}
        headerCTA={() => {
          return (
            <HeaderCTA
              titleDescription={titleDescription}
              handleClose={handleClose}
            />
          );
        }}
      >
        <ListingItem data={listingData} hideActions={hideActions} />
      </FTSModal>
    </div>
  );
};

export default ListingInfoModal;
