import React, { useEffect, useState } from "react";
import FullPageLayout from "../../../layouts/FullpageLayout";
import { Button, Col, Row, Spinner } from "reactstrap";
import Icon from "../../../components/fts/Icons";
import axios from "axios";
import appConfig from "../../../configs/appConfig";
import queryString from "query-string";
import logger from "../../../utility/logger";

const PaymentStatus = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      try {
        // await removeA llCookies();
        if (props.match.params.status === "success") {
          setMessage("Payment successfully processed");
          setIsLoading(true);
          const { paymentId } = queryString.parse(props.location.search);
          const { token } = props.match.params;
          const url = `${appConfig.apiURL}payment/paypal/success`;
          const payload = {
            token,
            paymentId,
          };

          const { data } = await axios.post(url, payload);
          setInvoices(data.invoices);
          setIsLoading(false);
        }
      } catch (e) {
        logger.error(e);
        setError(
          e?.response?.data?.message ||
            "Oops! Something went wrong. Please try again.",
        );
      }
    })();
  }, [props.location.search, props.match.params]);
  if (error) {
    return (
      <div
        style={{ height: "100vh", width: "100vw" }}
        className={
          "d-flex flex-column justify-content-center align-items-center"
        }
      >
        <h2>{error}</h2>
      </div>
    );
  }
  if (isLoading || !invoices.length) {
    return (
      <div
        style={{ height: "100vh", width: "100vw" }}
        className={
          "d-flex flex-column justify-content-center align-items-center"
        }
      >
        <Spinner size={"lg"} color="primary" />
      </div>
    );
  }
  return (
    <FullPageLayout bgColor={"#CE1B3B"}>
      <Row>
        <Col
          md={{ size: 6, offset: 3 }}
          className="bg-white p-4 shadow-lg rounded-lg"
        >
          <Col md={12} className={"text-center mb-5"}>
            <Icon name="CheckCircle2" size={200} />
            <h3 style={{ color: "#86D5A7" }}>{message}</h3>
          </Col>
          <Row>
            <Col md={{ size: 6 }}>
              <Button className={"w-100"} color="secondary" onClick={() => {}}>
                Download invoice
              </Button>
            </Col>{" "}
            <Col md={{ size: 6 }}>
              <Button className={"w-100"} color="primary " onClick={() => {}}>
                Download receipt
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </FullPageLayout>
  );
};

export default PaymentStatus;
