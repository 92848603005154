import React from "react";
import { usePhoneAndBelowMediaQuery } from "../../../hooks";

import ChangePasswordHeader from "../../../components/fts/change-password-header";
import ChangePasswordForm from "../../../components/fts/change-password-form";
import "../../../assets/scss/pages/change-password.scss";

import { Card } from "reactstrap";
const ChangePassword = () => {
  const isMobile = usePhoneAndBelowMediaQuery();

  return (
    <>
      <Card>
        <ChangePasswordHeader />
        <ChangePasswordForm isMobile={isMobile} />
      </Card>
    </>
  );
};

export default ChangePassword;
