import React, { useState } from "react";

const ChartLegendItem = ({ item, refChart, index }) => {
  const [isLineVisible, setIsLineVisible] = useState(true);
  const onClickLegendItem = () => {
    if (!refChart.current) return;
    const isDatasetVisible = refChart.current?.isDatasetVisible(index);

    setIsLineVisible(!isDatasetVisible);
    if (isDatasetVisible) {
      return refChart.current.hide(index);
    }
    return refChart.current.show(index);
  };

  return (
    <div
      onClick={onClickLegendItem}
      style={{
        opacity: isLineVisible ? 1 : 0.5,
        textDecoration: isLineVisible ? "none" : "line-through",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 24,
      }}
    >
      <div
        style={{
          width: 12,
          height: 12,
          borderRadius: 2,
          marginRight: 8,
          backgroundColor: item?.borderColor,
        }}
      ></div>
      {item.label}
    </div>
  );
};

export default ChartLegendItem;
