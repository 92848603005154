import React from "react";
import { dontShowWorkingHoursToDropdown } from "../constants";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { hoursListTo } from "../../../fts/formik/hour-input/options";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";

export const WorkingHoursToDropdown = (props) => {
  const { dayToName, dayToValue, onChange, dayFromValue } = props;
  const showToDropdown = !dontShowWorkingHoursToDropdown.includes(
    dayFromValue?.label,
  );

  if (!showToDropdown) return <EmptyComponent />;
  return (
    <FtsColumn size={{ xs: 6 }}>
      <Dropdown
        placeholder={""}
        size={DropdownSizesValues.md}
        variant={DropdownVariantsValues.outlined}
        name={dayToName}
        value={dayToValue}
        options={hoursListTo}
        onChange={(val) => onChange(dayToName, val)}
      />
    </FtsColumn>
  );
};
