import * as Sentry from "@sentry/react";
import { handleUnauthorized } from "./handleUnauthorized";

function handleSentryTags(response) {
  return {
    tags: {
      url: response.config.url,
      type: response.data?.type,
      statusCode: response.status,
    },
    extra: {
      headers: response.headers,
      message: response.data?.message || "No message available",
      description: response.data?.description || "No description available",
      stacktrace: response.data?.stacktrace || "No stack trace available",
      inlineMessage:
        response.data?.inlineMessage || "No inline message available",
    },
  };
}

export const sentryErrorHandler = (error) => {
  handleUnauthorized(error?.response?.status);
  if (error.response) {
    if (error.response.status === 500)
      Sentry.captureException(
        new Error(`Internal Server Error: ${error.response.data?.message}`),
        handleSentryTags(error.response),
      );
    else if (error.response.status === 422)
      Sentry.captureException(
        new Error(`Unprocessable Entity: ${error.response.data?.message}`),
        handleSentryTags(error.response),
      );
  } else Sentry.captureException(error);

  return Promise.reject(error);
};
