import React from "react";
import "../../../assets/scss/components/fts/private-location-badge.scss";

const PrivateLocationBadge = () => {
  return (
    <div className="fts-private-location-badge">
      <span className="fts-private-location-badge-text">Private Location</span>
    </div>
  );
};

export default PrivateLocationBadge;
