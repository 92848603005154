import { ColorsValue } from "@find-truck-service/types/ui/colors";
import styled from "styled-components";
import { defaultProps, defaultStyleProps } from "../default";
import { RadioButtonSizes } from "@find-truck-service/types/ui/radio-button";
const defaultStyles = `
  cursor: pointer;
  appearance: none;
  margin: 0;
  border-radius: 50%;
  transition: all 0.1s ease-in-out;

  &:checked::after {
    background-color: ${ColorsValue["greyscale-0"]};
  }
  &:checked {
    background-color: ${ColorsValue["blue-accent-500"]};
  }

  &:hover {
    outline: 2px solid ${ColorsValue["blue-accent-300"]};
  }

  &:focus {
    outline: 2px solid ${ColorsValue["blue-accent-300"]};
  }
`;
const radioButtonStyles = (props) => {
    const style = {
        [RadioButtonSizes.sm]: `
        ${defaultStyles}
        width: 20px;
        height: 20px;
        outline: 1px solid ${ColorsValue["greyscale-100"]};
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          margin: 6px;
        }

      `,
        [RadioButtonSizes.md]: `
        ${defaultStyles}
        width: 24px;
        height: 24px;
        outline: 1px solid ${ColorsValue["greyscale-100"]};
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          margin: 6px;
        }
        `,
        [RadioButtonSizes.lg]: `
        ${defaultStyles}
        width: 32px;
        height: 32px;
        outline: 1px solid ${ColorsValue["greyscale-100"]};
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          margin: 8px;
        }
        `,
    };
    return style[props.size];
};
export const FtsRadioButton = styled.input
    .withConfig({
    shouldForwardProp: (prop) => ![...defaultProps, "size"].includes(prop),
})
    .attrs({
    type: "radio",
}) `
  ${radioButtonStyles}
  ${defaultStyleProps}
`;
