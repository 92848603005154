import React from "react";
import { ModalHeaderComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalHeaderComponent";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
export const FreeListingSubmittedModalHeader = (props) => {
    const { onClose } = props;
    return (React.createElement(ModalHeaderComponent, { title: "Your Free Listing is Submitted", subtitle: "We’ll call to verify details and request additional information if needed. Free listings may take up to 30 days to get approved.", onClose: onClose, prependIconProps: {
            iconName: IconNames.Listing,
            iconWrapperProps: {
                p: "md",
                bg: ColorsValue["yellow-warning-50"],
                borderRadius: "4xxl",
                display: "flex",
                justify: "center",
                align: "center",
                height: "fit-content",
            },
            svgProps: {
                width: SvgIconSizeValues.xl,
                height: SvgIconSizeValues.xl,
            },
            pathProps: { stroke: ColorsValue["yellow-warning-800"] },
        } }));
};
