import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { BannerTypes } from "@find-truck-service/types/ui/banner";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { StrokesValues } from "@find-truck-service/types/ui/stroke";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
const BannerBorderColor = {
    [BannerTypes.info]: ColorsValue["blue-accent-300"],
    [BannerTypes.success]: ColorsValue["green-success-300"],
    [BannerTypes.warning]: ColorsValue["yellow-warning-300"],
    [BannerTypes.error]: ColorsValue["red-error-300"],
};
const BannerBackgroundColor = {
    [BannerTypes.info]: ColorsValue["blue-accent-50"],
    [BannerTypes.success]: ColorsValue["green-success-50"],
    [BannerTypes.warning]: ColorsValue["yellow-warning-50"],
    [BannerTypes.error]: ColorsValue["red-error-50"],
};
export const FtsBanner = styled(FtsWrapper)
    .attrs(({ type }) => ({
    bg: BannerBackgroundColor[type],
    direction: { xs: "column", sm: "row", md: "row", lg: "row" },
    borderBottom: `${StrokesValues.sm} solid ${BannerBorderColor[type]}`,
}))
    .withConfig({
    shouldForwardProp: (prop) => prop !== "type",
}) `
  display: flex;
  gap: ${Spacing.lg};
  justify-content: space-between;
  padding: ${Spacing.lg} ${Spacing["2xl"]};
`;
