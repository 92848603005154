import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { Accordion } from "@find-truck-service/ui/src/components/react/Accordion";
import {
  selectCategories,
  selectSubCategories,
} from "../../../../redux/reducers/essentialLists";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ErrorInfoIconWrapper } from "./SearchNoResults";

export const CategoriesPickers = (props) => {
  const {
    selectedCategories,
    selectedSubCategories,
    setListingData,
    showErrorMsg,
    invalidFields,
    setInvalidFields,
  } = props;
  const errorMsgRef = useRef();
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);

  const onToggleCategory = useCallback(
    (id, value) => {
      let adsCategories = [...selectedCategories, { mainCategoryId: id }];
      if (!value)
        adsCategories = adsCategories.filter(
          ({ mainCategoryId }) => mainCategoryId !== id,
        );
      dispatch(setListingData({ data: { adsCategories } }));
    },
    [selectedCategories],
  );

  const onToggleSubCategory = useCallback(
    (id, value) => {
      let adsSubCategories = [...selectedSubCategories, { subCategoryId: id }];
      if (!value)
        adsSubCategories = adsSubCategories.filter(
          ({ subCategoryId }) => subCategoryId !== id,
        );
      dispatch(setListingData({ data: { adsSubCategories } }));
      if (!invalidFields) return;
      const data = { ...invalidFields, adsSubCategories: "" };
      dispatch(setInvalidFields({ data }));
    },
    [selectedSubCategories, invalidFields],
  );

  useEffect(() => {
    if (showErrorMsg && errorMsgRef.current)
      errorMsgRef.current?.scrollIntoView({
        block: "center",
        behavior: "auto",
      });
  }, [showErrorMsg]);

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} width={"100%"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          SEARCH CATEGORIES
        </Span>
      </FtsColumn>

      {/*TODO change with Message inline component*/}
      {showErrorMsg && (
        <FtsColumn
          size={{ xs: 12 }}
          display={"flex"}
          align={"center"}
          ref={errorMsgRef}
        >
          <ErrorInfoIconWrapper>
            <FtsIcons iconName={IconNames.AlertOctagon} />
          </ErrorInfoIconWrapper>
          <Span
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["red-error-500"]}
          >
            Please select at least 1 service you provide
          </Span>
        </FtsColumn>
      )}

      {categories.map(({ id, label }) => {
        const isExpanded = !!selectedCategories.find(
          ({ mainCategoryId: cId }) => cId === id,
        );

        const categorySubCategories = subCategories.filter(
          ({ categoryId }) => categoryId === id,
        );

        return (
          <FtsColumn key={id} size={{ xs: 12, md: 6, lg: 4 }}>
            <Accordion
              title={label}
              show={isExpanded}
              onClick={() => onToggleCategory(id, !isExpanded)}
            >
              <FtsWrapper
                display={"flex"}
                direction={"column"}
                gap={"md"}
                p={"lg"}
              >
                {categorySubCategories.map(({ id: subId, value: subValue }) => {
                  const isSubSelected = !!selectedSubCategories.find(
                    ({ subCategoryId }) => subCategoryId === subId,
                  );

                  return (
                    <Checkbox
                      key={subId}
                      label={subValue}
                      size={CheckboxSizes.md}
                      onClick={() => onToggleSubCategory(subId, !isSubSelected)}
                      checked={isSubSelected}
                    />
                  );
                })}
              </FtsWrapper>
            </Accordion>
          </FtsColumn>
        );
      })}
    </FtsRow>
  );
};
