import React from "react";

import { Button } from "reactstrap";
import Icon from "../../../components/fts/Icons";
import "../../../assets/scss/pages/check-your-email.scss";
import { history } from "../../../history";
import Breakpoint from "../../../components/hoc/Breakpoint";
import { HeaderWrapper } from "../../../components/fts-components-v2/HeaderWrapper";

class CheckYourEmail extends React.Component {
  render() {
    return (
      <div className="fts-check-your-email">
        <HeaderWrapper />
        <div className="fts-check-your-email-message">
          <div className="fts-check-your-email-message-box">
            <Breakpoint md up>
              <Icon name="EmailIllustration" />
            </Breakpoint>
            <Breakpoint sm down>
              <Icon name="EmailIllustrationMobile" />
            </Breakpoint>
            <div>
              <h3>Check your email</h3>
              <p>
                An email with password recovery instructions has been sent to
                you. Please check your inbox.
              </p>
            </div>

            <Button
              tag="a"
              onClick={() => history.push("/dashboard/login")}
              color="secondary"
              size="lg"
            >
              Back to Login
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default CheckYourEmail;
