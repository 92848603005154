import {
  RESET_PASSWORD_REQUEST_FAILED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  SET_RESET_PASSWORD_DATA,
  SET_RESET_PASSWORD_INVALID_FORM_FIELDS,
} from "../../../reducers/v2/resetPassword";

export const setResetPasswordData = (payload) => ({
  type: SET_RESET_PASSWORD_DATA,
  payload,
});

export const setResetPasswordInvalidFormFields = (payload) => ({
  type: SET_RESET_PASSWORD_INVALID_FORM_FIELDS,
  payload,
});

export const resetPasswordRequest = (payload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordFailed = (payload) => ({
  type: RESET_PASSWORD_REQUEST_FAILED,
  payload,
});

export const resetPasswordSuccess = (payload) => ({
  type: RESET_PASSWORD_REQUEST_SUCCESS,
  payload,
});
