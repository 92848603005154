import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { extractParameter } from "../../utility/param-extract";
import { useTemporaryLinkRedirectToPreviewHandler } from "./useTemporaryLinkRedirectToPreviewHandler";
import { temporaryLinkRegisterPaypalPaymentRequest } from "../../redux/actions/v2/temporaryLinkPayments";
import { registerUpgradePaypalPaymentRequest } from "../../redux/actions/v2/payments";

export const useTemporaryLinkPaymentSuccessfulRedirect = () => {
  const handleRedirectToPreview = useTemporaryLinkRedirectToPreviewHandler();
  const dispatch = useDispatch();

  useEffect(() => {
    const hash = extractParameter("hash");
    const gateway = extractParameter("gateway");
    const status = extractParameter("status");
    const tempToken = extractParameter("tempToken");
    const paymentId = extractParameter("paymentId");

    if (status !== "success") return;

    if (gateway === "paypal") {
      dispatch(
        temporaryLinkRegisterPaypalPaymentRequest({
          data: { paypalPayload: { paymentId, hash }, token: tempToken },
          onSuccess: handleRedirectToPreview,
        }),
      );
    }
    if (gateway === "upgrade-paypal") {
      dispatch(
        registerUpgradePaypalPaymentRequest({
          data: { paypalPayload: { paymentId, hash }, token: tempToken },
          // onSuccess: handleRedirectToPreview,
        }),
      );
    }
  }, []);
};
