import styled from "styled-components";
import { FtsRadioButton } from "../FtsRadioButton/FtsRadioButton";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const FtsRadioButtonLabel = styled.span `
  display: flex;
  align-items: center;
  &:checked::after {
    ${FtsRadioButton} {
      background-color: ${ColorsValue["greyscale-0"]};
    }
  }
  &:checked {
    ${FtsRadioButton} {
      background-color: ${ColorsValue["blue-accent-500"]};
    }
  }

  &:hover {
    ${FtsRadioButton} {
      outline: 2px solid ${ColorsValue["blue-accent-300"]};
    }
  }

  &:focus {
    ${FtsRadioButton} {
      outline: 2px solid ${ColorsValue["blue-accent-300"]};
    }
  }
`;
