import * as Yup from "yup";
import { requiredPhoneNumberValidate } from "@find-truck-service/types/validators/global";

export const getListedAccountValidator = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .matches(
      /^(?=(?:.*[a-z]))(?=(?:.*[A-Z]))(?=(?:.*[0-9]))(?=(?:.*[!@#$%^&*()_+=\-{}|":;'?/.>,<])).{7,}$/,
      "Password must be 7 or more digits with at least 1 number and 1 special character.",
    ),
  phoneNumber: requiredPhoneNumberValidate,
  jobTitle: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});
