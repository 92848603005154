import React from "react";
import { history } from "../../../../../history";
import { useDispatch, useSelector } from "react-redux";
import { useScrollToTopAndNavigate } from "../../../../../hooks";
import { TemporaryLinkPages } from "../../../../../views/constants/pages";
import { listingDetailsRowValidator } from "../../validators/listingDetailsRowValidator";
import { listingContactRowValidator } from "../../validators/listingContactRowValidator";
import { ListingInfoSmFooterComponent } from "../../components/ListingInfoSmFooterComponent";
import { selectTemporaryLinkEditListingData } from "../../../../../redux/reducers/v2/temporaryListingLink";
import {
  setTemporaryLinkInvalidFormFields,
  temporaryLinkUpdateListingRequest,
} from "../../../../../redux/actions/v2/temporaryLinkListing";
import { listingSearchCategoriesRowValidator } from "../../validators/listingSearchCategoriesRowValidator";

export const TemporaryLinkEditListingInfoSmFooter = () => {
  const listingData = useSelector(selectTemporaryLinkEditListingData);
  const scrollAndNavigate = useScrollToTopAndNavigate();
  const dispatch = useDispatch();

  function navigateToEditImages() {
    scrollAndNavigate(TemporaryLinkPages.EDIT_LISTING_IMAGES);
  }

  async function onSaveChanges() {
    try {
      dispatch(setTemporaryLinkInvalidFormFields({ data: {} }));
      await listingDetailsRowValidator.validate(listingData);
      await listingContactRowValidator.validate(listingData);
      await listingSearchCategoriesRowValidator.validate(listingData);
      dispatch(
        temporaryLinkUpdateListingRequest({ onSuccess: navigateToEditImages }),
      );
    } catch (e) {
      if (e.name !== "ValidationError") return;
      const data = { [e.path]: e.errors[0] };
      dispatch(setTemporaryLinkInvalidFormFields({ data }));
    }
  }

  function onCancel() {
    history.goBack();
  }

  const primaryButtonProps = {
    label: "Save Changes",
    onClick: onSaveChanges,
  };

  return (
    <ListingInfoSmFooterComponent
      primaryButtonProps={primaryButtonProps}
      onCancel={onCancel}
    />
  );
};
