import { createSelector } from "reselect";

export const SET_UPDATE_PASSWORD_DATA =
  "UPDATE_PASSWORD/SET_UPDATE_PASSWORD_DATA";
export const UPDATE_PASSWORD_REQUEST =
  "UPDATE_PASSWORD/UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_REQUEST_FAILED =
  "UPDATE_PASSWORD/UPDATE_PASSWORD_REQUEST_FAILED";
export const UPDATE_PASSWORD_REQUEST_SUCCESS =
  "UPDATE_PASSWORD/UPDATE_PASSWORD_REQUEST_SUCCESS";
export const SET_UPDATE_PASSWORD_INVALID_FORM_FIELDS =
  "UPDATE_PASSWORD/SET_UPDATE_PASSWORD_INVALID_FORM_FIELDS";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  inlineMessage: null,
  invalidFormFields: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_UPDATE_PASSWORD_DATA:
      return {
        ...state,
        invalidFormFields: null,
        data: { ...state.data, ...action.payload.data },
      };

    case SET_UPDATE_PASSWORD_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: action.payload.data,
      };

    case UPDATE_PASSWORD_REQUEST:
      return { ...state, isLoading: true };

    case UPDATE_PASSWORD_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data.message,
        inlineMessage: action.payload.data.inlineMessage,
      };

    case UPDATE_PASSWORD_REQUEST_SUCCESS:
      return { ...initialState, isLoading: false };

    default:
      return state;
  }
}

const selectUpdatePasswordState = (state) => state.updatePasswordState;

export const selectUpdatePasswordData = createSelector(
  selectUpdatePasswordState,
  (updatePasswordState) => updatePasswordState.data,
);

export const selectUpdatePasswordIsLoading = createSelector(
  selectUpdatePasswordState,
  (updatePasswordState) => updatePasswordState.isLoading,
);

export const selectUpdatePasswordError = createSelector(
  selectUpdatePasswordState,
  (updatePasswordState) => updatePasswordState.error,
);

export const selectUpdatePasswordInlineMessage = createSelector(
  selectUpdatePasswordState,
  (updatePasswordState) => updatePasswordState.inlineMessage,
);

export const selectUpdatePasswordInvalidFormFields = createSelector(
  selectUpdatePasswordState,
  (updatePasswordState) => updatePasswordState.invalidFormFields,
);
