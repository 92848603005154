import React from "react";
import { Button } from "reactstrap";
import { getListingTitle } from "../../../utility/getListingTitle";

const SelectListingTypeButton = ({ isSelected, isFree, priceId, action }) => {
  let color = isSelected ? "success" : isFree ? "secondary" : "primary";
  let text = isSelected
    ? "Selected"
    : `Select ${getListingTitle({ priceId })} Listing`;
  let buttonClassName = isFree ? "" : "choose-your-plan-container-button";

  return (
    <Button
      color={color}
      className={buttonClassName}
      onClick={() => action({ priceId })}
      id={getListingTitle({ priceId })}
    >
      {text}
    </Button>
  );
};

export default SelectListingTypeButton;
