import { call, put, takeLatest } from "redux-saga/effects";

import handleError from "../../../handlers/error";

import {
  TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_REQUEST,
  TEMPORARY_LINK_PAY_WITH_PAYPAL_REQUEST,
  TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_REQUEST,
} from "../../reducers/v2/temporaryLinkPayments";
import {
  temporaryLinkPayWithExistingCardFailed,
  temporaryLinkPayWithExistingCardSuccess,
  temporaryLinkPayWithPaypalFailed,
  temporaryLinkRegisterPaypalPaymentFailed,
  temporaryLinkRegisterPaypalPaymentSuccess,
} from "../../actions/v2/temporaryLinkPayments";
import { toast } from "react-toastify";
import { setTemporaryLinkActiveBanner } from "../../actions/v2/temporaryLinkListing";
import {
  bannerTitles,
  bannerTypes,
  bannerTypesIds,
} from "../../../constants/temporaryLinkBanners";
import FTSAxiosTemporaryLink from "../../../axios/ftsv2-temporary-link.instance";

function* temporaryLinkPayWithExistingCardSaga(action) {
  try {
    const { data: payloadData } = action.payload;
    yield call(
      FTSAxiosTemporaryLink.post,
      "/payments/temporary-token/stripe",
      payloadData.paymentData,
    );

    yield put(temporaryLinkPayWithExistingCardSuccess());
    yield put(
      setTemporaryLinkActiveBanner({
        data: {
          type: bannerTypes[bannerTypesIds.LISTING_LIVE_WAITING_PROOF],
          title: bannerTitles[bannerTypesIds.LISTING_LIVE_WAITING_PROOF],
        },
      }),
    );
    toast.success("Payment successful!");

    if (typeof action.payload.onSuccess === "function") {
      action.payload.onSuccess();
    }
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    toast.error(message);
    yield put(
      temporaryLinkPayWithExistingCardFailed({
        data: { message, inlineMessage },
      }),
    );
  }
}

function* temporaryLinkPayWithPaypalSaga(action) {
  try {
    const { data: payloadData, onSuccess } = action.payload;

    const { data } = yield call(
      FTSAxiosTemporaryLink.post,
      "/payments/temporary-token/paypal",
      payloadData.paymentData,
    );

    window.open(data.data.link, "_self");
    if (typeof onSuccess === "function") {
      onSuccess(data.data);
    }
  } catch (e) {
    const message = e.response
      ? e.response?.data?.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    toast.error(message);
    yield put(
      temporaryLinkPayWithPaypalFailed({ data: { message, inlineMessage } }),
    );
  }
}
function* temporaryLinkRegisterPaypalPaymentSaga(action) {
  try {
    const { data: payloadData, onSuccess } = action.payload;

    const { data } = yield call(
      FTSAxiosTemporaryLink.post,
      "/payments/temporary-token/paypal/success",
      payloadData.paypalPayload,
    );

    yield put(temporaryLinkRegisterPaypalPaymentSuccess());
    yield put(
      setTemporaryLinkActiveBanner({
        data: {
          type: bannerTypes[bannerTypesIds.LISTING_LIVE_WAITING_PROOF],
          title: bannerTitles[bannerTypesIds.LISTING_LIVE_WAITING_PROOF],
        },
      }),
    );
    toast.success("Payment successful!");

    if (typeof onSuccess === "function") {
      onSuccess(data.data);
    }
  } catch (e) {
    const message = e.response
      ? e.response?.data?.message
      : "An error occurred! Please try again.";
    const inlineMessage = e?.response?.data?.inlineMessage;
    handleError(e);
    toast.error(message);
    yield put(
      temporaryLinkRegisterPaypalPaymentFailed({
        data: { message, inlineMessage },
      }),
    );
  }
}

export default function* temporaryLinkPaymentsWatcher() {
  yield takeLatest(
    TEMPORARY_LINK_PAY_WITH_EXISTING_CARD_REQUEST,
    temporaryLinkPayWithExistingCardSaga,
  );
  yield takeLatest(
    TEMPORARY_LINK_PAY_WITH_PAYPAL_REQUEST,
    temporaryLinkPayWithPaypalSaga,
  );
  yield takeLatest(
    TEMPORARY_LINK_REGISTER_PAYPAL_PAYMENT_REQUEST,
    temporaryLinkRegisterPaypalPaymentSaga,
  );
}
