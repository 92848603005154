import React, { Component, Suspense, lazy } from "react";
import { Spinner } from "reactstrap";

// import "flatpickr/dist/themes/light.css";
import "../../../../assets/scss/plugins/forms/flatpickr/flatpickr.scss";
import "../../../../assets/scss/flatpickr_light.css";

import Sidebar from "../../sidebar";

const EditSubAccount = lazy(() => import("./EditSubAccountForm"));
const AddSubAccount = lazy(() => import("./AddSubAccountForm"));

const UpdateNoteClient = lazy(() => import("./UpdateNoteClient"));
const CreateListing = lazy(() => import("./CreateListing"));
const UpdateListing = lazy(() => import("./UpdateListing"));
const RequestedChange = lazy(() => import("./RequestedChange"));
const PaymentPreview = lazy(() => import("./PaymentOverview"));
const ReceiptDetails = lazy(() => import("./ReceiptDetails"));
const InvoiceDetails = lazy(() => import("./InvoiceDetails"));
const TemplatePreview = lazy(() => import("./TemplatePreview"));
const ReportPreview = lazy(() => import("./ReportPreview"));
const Notifications = lazy(() => import("./Notifications"));
const ChangeListingType = lazy(() => import("./ChangeListingType"));
const PreviewListingDetails = lazy(() => import("./PreviewListingDetails"));
const ChangePaymentMethod = lazy(() => import("./ChangePaymentMethod"));
const AddTruckForm = lazy(() => import("./AddTruckForm"));
const EditTruckForm = lazy(() => import("./EditTruckForm"));
const AddDriverForm = lazy(() => import("./AddDriverForm"));
const EditDriverForm = lazy(() => import("./EditDriverForm"));

const PlaceholderComponent = () => <div></div>;

class ActionSidebar extends Component {
  sidebarScreens = {
    listing: {
      title: "Create new listing",
      component: CreateListing,
      style: { width: "80rem", overflowY: "auto" },
    },
    update_listing: {
      title: "Listing preview",
      component: UpdateListing,
      style: { width: "80rem", overflowY: "auto" },
    },

    requestedChange: {
      title: "Requested change",
      component: RequestedChange,
      style: { width: "100%", overflowY: "auto" },
    },

    update_note_client: {
      title: "Edit note",
      component: UpdateNoteClient,
      style: { overflowY: "auto" },
    },

    payment_overview: {
      title: "Payment overview",
      component: PaymentPreview,
      style: { width: "1000px", overflowY: "auto" },
    },
    invoice_details: {
      title: "Invoice details",
      component: InvoiceDetails,
      style: { width: "850px", overflowY: "auto" },
    },
    receipt_details: {
      title: "Receipt details",
      component: ReceiptDetails,
      style: { width: "850px", overflowY: "auto" },
    },
    template_preview: {
      title: "Template Preview",
      component: TemplatePreview,
      style: { width: "850px", overflowY: "auto" },
    },
    system_template_preview: {
      title: "Template Preview",
      component: TemplatePreview,
      style: { width: "850px", overflowY: "auto" },
    },

    report_preview: {
      title: this.props.accountReportTitle || this.props.listingReportTitle,
      component: ReportPreview,
      style: { width: "70%", overflowY: "auto" },
    },
    proof_update_listing: {
      title: "Review Listing details",
      component: UpdateListing,
      style: { width: "80rem", overflowY: "auto" },
    },
    change_listing_type: {
      title: "Change Listing Type",
      component: ChangeListingType,
      style: { width: "80rem", overflowY: "auto" },
    },
    preview_listing_details: {
      title: "Preview Listing details",
      component: PreviewListingDetails,
      style: { width: "80rem", overflowY: "auto" },
    },
    edit_sub_account: {
      title: "Edit Account User",
      component: EditSubAccount,
      style: { width: "720px", overflowY: "auto" },
    },
    add_sub_account: {
      title: "Add Account User",
      component: AddSubAccount,
      style: { width: "720px", overflowY: "auto" },
    },
    change_payment_method: {
      title: "Change recurring payment method",
      component: ChangePaymentMethod,
      style: { width: "850px", overflowY: "auto" },
    },
    add_truck: {
      title: "Add Truck",
      component: AddTruckForm,
      style: { width: "740px", overflowY: "auto" },
    },
    edit_truck: {
      title: "Edit Truck",
      component: EditTruckForm,
      style: { width: "740px", overflowY: "auto" },
    },
    add_driver: {
      title: "Add Driver",
      component: AddDriverForm,
      style: { width: "740px", overflowY: "auto" },
    },
    edit_driver: {
      title: "Edit Driver",
      component: EditDriverForm,
      style: { width: "740px", overflowY: "auto" },
    },

    notifications: {
      title: "Notifications",
      component: Notifications,
      style: { overflowY: "auto" },
    },
  };

  getScreenData = (screen) => {
    return this.sidebarScreens[screen] ?? {};
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.isPersonal !== this.props.isPersonal ||
      nextProps.action !== this.props.action ||
      nextProps.show !== this.props.show ||
      nextProps.actionItem?.todoNotes?.length !==
        this.props.actionItem?.todoNotes?.length ||
      nextProps.actionItem?.replies?.length !==
        this.props.actionItem?.replies?.length ||
      nextProps.listings?.length !== this.props.listings?.length ||
      JSON.stringify(nextProps.accountData) !==
        JSON.stringify(this.props.accountData) ||
      JSON.stringify(nextProps.listingData) !==
        JSON.stringify(this.props.listingData) ||
      nextProps.accountReportTitle !== this.props.accountReportTitle ||
      nextProps.listingReportTitle !== this.props.listingReportTitle
    );
  }

  getPersonalMessage() {
    if (!this.props.updating) {
      return "Create new personal todo";
    }
  }

  render() {
    const {
      action,
      actionItem,
      adminData,
      accountData,
      listingData,
      onPrefillListingData,
      handleSidebar,
      show,
      updating,
      setEditMode,
      isPersonal,
      taskManagement,
      getAccountInfo,
      getListingInfo,
      onNotesListUpdate,
      onTodoListUpdate,
      filesTableGroupRef,
      getSystemTemplates,
      setFormikSidebarRef,
      resetFormikForm,
      listings,
      showAccountBox,
      callToAction,
      promotionType,
      listingName,
      listingId,
      accountInfo,
      noMarker,
      tableGroupRef,
      onPromotionCreate,
      fetchTodos,
      setPromotionsCount,
      onSubmitQuery,
      setPaymentStep,
      setDetailsRef,
      setActivityPreloadDate,
      activityPreloadDate,
      getSelectedInvoices,
      scrollToSelectedInvoice,
      reportAccountId,
      reportListingId,
      refetchReports,
      accountReportTitle,
      listingReportTitle,
      handleReviewListingData,
      handleSidebarSubmit,
      formikSidebarRef,
      downloadInvoiceOnLoad,
      showTableLoadingOverlay,
      hideTableLoadingOverlay,
      handleDriverAdded,
      temporaryLink,
      ...rest
    } = this.props;

    let isPersonalTodo;

    if (action === "todo") {
      if (isPersonal && !updating) {
        isPersonalTodo = true;
      }
    }

    const screen = `${action}${updating ? "_update" : ""}`;

    const Component =
      this.getScreenData(screen).component || PlaceholderComponent;

    const accountID = accountData?.id ?? listingData?.userId;

    const componentProps = {
      action,
      actionItem,
      handleSidebar,
      accountID,
      accountData,
      listingData,
      adminData,
      updating,
      setEditMode,
      isPersonal,
      taskManagement,
      getAccountInfo,
      getListingInfo,
      promotionType,
      listingName,
      listingId,
      onNotesListUpdate,
      fetchTodos,
      callToAction,
      onTodoListUpdate,
      listings,
      onPrefillListingData,
      filesTableGroupRef,
      setFormikSidebarRef,
      getSystemTemplates,
      resetFormikForm,
      onSubmitQuery,
      showAccountBox,
      accountInfo,
      noMarker,
      tableGroupRef,
      onPromotionCreate,
      setPromotionsCount,
      setPaymentStep,
      setDetailsRef,
      setActivityPreloadDate,
      activityPreloadDate,
      getSelectedInvoices,
      scrollToSelectedInvoice,
      reportAccountId,
      reportListingId,
      refetchReports,
      accountReportTitle,
      listingReportTitle,
      handleReviewListingData,
      handleSidebarSubmit,
      formikSidebarRef,
      downloadInvoiceOnLoad,
      showTableLoadingOverlay,
      hideTableLoadingOverlay,
      handleDriverAdded,
      temporaryLink,
    };

    return (
      <Sidebar
        action={action}
        show={show && action !== ""}
        style={this.getScreenData(screen).style}
        handleSidebar={handleSidebar}
        sidebarData={accountData}
        headerTitle={
          isPersonalTodo
            ? this.getPersonalMessage()
            : this.getScreenData(screen).title
        }
        showAccountBox={showAccountBox}
        noMarker={noMarker}
        accountInfo={accountInfo || actionItem?.owner}
        actionItem={actionItem}
        onPromotionCreate={this.onPromotionCreate}
        setPromotionsCount={setPromotionsCount}
        onSubmitQuery={onSubmitQuery}
        {...rest}
      >
        <Suspense
          fallback={
            <div className="text-center mt-1">
              <Spinner size="sm" />
            </div>
          }
        >
          <Component {...componentProps} {...rest} />
        </Suspense>
      </Sidebar>
    );
  }
}

export default ActionSidebar;
