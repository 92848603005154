import {
  FLEET_PROFILE_UPDATE_REQUEST,
  FLEET_PROFILE_SET_FIELD_VALUES,
  FLEET_PROFILE_UPDATE_FAILED,
  FLEET_PROFILE_UPDATE_SUCCESS,
  FLEET_PROFILE_SET_IS_LOADING,
  FLEET_PROFILE_SET_INVALID_FORM_FIELDS,
} from "../../../reducers/v2/fleet-driver-profile";

export const fleetProfileSetFieldValues = (payload) => ({
  type: FLEET_PROFILE_SET_FIELD_VALUES,
  payload,
});

export const fleetProfileUpdateRequest = (payload) => ({
  type: FLEET_PROFILE_UPDATE_REQUEST,
  payload,
});

export const fleetProfileUpdateSuccess = (payload) => ({
  type: FLEET_PROFILE_UPDATE_SUCCESS,
  payload,
});

export const fleetProfileUpdateFailed = (payload) => ({
  type: FLEET_PROFILE_UPDATE_FAILED,
  payload,
});

export const fleetProfileSetInvalidFormFields = (payload) => ({
  type: FLEET_PROFILE_SET_INVALID_FORM_FIELDS,
  payload,
});

export const fleetProfileSetUpdateLoading = (payload) => ({
  type: FLEET_PROFILE_SET_IS_LOADING,
  payload,
});
