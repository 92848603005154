import React, { useEffect, useState } from "react";
import { history } from "../../../history";
import { useDispatch, useSelector } from "react-redux";
import { TemporaryLinkPages } from "../../constants/pages";
import { SESSION_STORAGE_KEYS } from "../../../utility/constants";
import { LISTING_STATUSES, LISTING_TYPE } from "../../../constants";
import { DraftFooterButtons } from "./components/DraftFooterButtons";
import { selectAdsPrices } from "../../../redux/reducers/essentialLists";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { ListingItem } from "@find-truck-service/ui/src/components/react/ListingItem";
import { ListingItemsTypeValues } from "@find-truck-service/types/ui/listingItemComponent";
import { FullPageLoader } from "@find-truck-service/ui/src/components/react/Loader/FullPageLoader";

import TemporaryLinkListingOptions from "../../../components/fts-components-v2/TemporaryLinkListingOptions";
import { FreeListingNoticeModal } from "@find-truck-service/ui/src/components/react/FreeListingNoticeModal";
import { TemporaryLinkPreviewDraftListingModals } from "./components/TemporaryLinkPreviewDraftListingModals";
import { TemporaryLinkPageTitle } from "../../../components/fts-components-v2/TemporaryLink/TemporaryLinkPageTitle/TemporaryLinkPageTitle";
import { TemporaryLinkContentContainer } from "../../../components/fts-components-v2/TemporaryLink/TemporaryLinkContentContainer/TemporaryLinkContentContainer";
import {
  selectTemporaryLinkHasFreeOrActiveListings,
  selectTemporaryLinkIsLoading,
  selectTemporaryLinkListingInitialPrice,
  selectTemporaryLinkListingPreviewData,
  selectTemporaryLinkListingSelectedPrice,
  selectTemporaryLinkListingShowFreeListingModal,
} from "../../../redux/reducers/v2/temporaryListingLink";
import {
  setTemporaryLinkEditListingData,
  setTemporaryLinkInitialListingPrice,
  setTemporaryLinkSelectedListingPrice,
  setTemporaryLinkShowFreeListingModalNotice,
  temporaryLinkUpdateListingRequest,
} from "../../../redux/actions/v2/temporaryLinkListing";
import { getBiggerListingOptions, getSmallerListingOptions } from "./helpers";

const TemporaryLinkPreviewDraftListing = () => {
  const listingData = useSelector(selectTemporaryLinkListingPreviewData);
  const isLoading = useSelector(selectTemporaryLinkIsLoading);
  const prices = useSelector(selectAdsPrices);
  const hasFreeActiveListing = useSelector(
    selectTemporaryLinkHasFreeOrActiveListings,
  );
  const initialListingPrice = useSelector(
    selectTemporaryLinkListingInitialPrice,
  );
  const showFreeListingNoticeModal = useSelector(
    selectTemporaryLinkListingShowFreeListingModal,
  );
  const selectedListingPrice = useSelector(
    selectTemporaryLinkListingSelectedPrice,
  );

  const dispatch = useDispatch();

  const [previewListingPrice, setPreviewListingPrice] = useState(null);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [showAmenitiesModal, setShowAmenitiesModal] = useState(false);
  const [showServicesModal, setShowServicesModal] = useState(false);
  const [smallerOptions, setSmallerOptions] = useState(null);
  const [biggerOptions, setBiggerOptions] = useState(null);

  const handleChangeListingSelection = (price) => {
    dispatch(setTemporaryLinkSelectedListingPrice(price));
    sessionStorage.setItem(SESSION_STORAGE_KEYS.SELECTED_PRICE_ID, price?.id);
  };

  const openDescriptionModal = () => {
    closePreviewListingModal();
    dispatch(setTemporaryLinkEditListingData({ data: listingData }));
    setShowDescriptionModal(true);
  };

  const handleGoWithFreeListing = () => {
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.SELECTED_PRICE_ID);
    const data = {
      ...listingData,
      priceId: LISTING_TYPE.FREE,
      status: LISTING_STATUSES.INACTIVE,
      draftStep: null,
    };
    dispatch(setTemporaryLinkEditListingData({ data }));
    dispatch(
      temporaryLinkUpdateListingRequest({
        onSuccess: () => {
          dispatch(setTemporaryLinkShowFreeListingModalNotice({ data: false }));
          history.replace(TemporaryLinkPages.FREE_LISTING_PREVIEW);
        },
      }),
    );
  };

  const closePreviewListingModal = () => setPreviewListingPrice(null);

  useEffect(() => {
    // todo @muaz Can you provide me documentation where I can find what is exacly work this return in use effect
    if (!listingData?.price) return;
    dispatch(setTemporaryLinkSelectedListingPrice(listingData.price));
  }, [listingData?.price]);

  useEffect(() => {
    // todo @muaz Can you provide me documentation where I can find what is exacly work this return in use effect
    if (!listingData?.priceId || !prices) return;

    const selectedPriceId = sessionStorage.getItem(
      SESSION_STORAGE_KEYS.SELECTED_PRICE_ID,
    );

    const initialPriceId = selectedPriceId
      ? parseInt(selectedPriceId)
      : listingData.priceId;

    const listingPrice = prices?.find((price) => price.id === initialPriceId);
    const biggerOptions = getBiggerListingOptions(prices, initialPriceId);
    const smallerOptions = getSmallerListingOptions(
      prices,
      initialPriceId,
      hasFreeActiveListing,
    );

    dispatch(setTemporaryLinkInitialListingPrice(listingPrice));
    dispatch(setTemporaryLinkSelectedListingPrice(listingPrice));

    setSmallerOptions(smallerOptions);
    setBiggerOptions(biggerOptions);
  }, [listingData?.priceId, prices, hasFreeActiveListing]);

  if (!listingData || !initialListingPrice) return <FullPageLoader />;

  return (
    <>
      {isLoading && <FullPageLoader />}
      <TemporaryLinkContentContainer>
        <FtsWrapper flex={1} overflow={"auto"}>
          <FtsContainer mb={"2xl"}>
            <TemporaryLinkPageTitle title="Listing Preview" />
            <ListingItem
              type={ListingItemsTypeValues.DRAFT}
              footerActions={{
                onSelect: () =>
                  handleChangeListingSelection(initialListingPrice),
                onClickPreview: () =>
                  setPreviewListingPrice(initialListingPrice),
              }}
              listingData={{
                ...listingData,
                price: initialListingPrice,
                priceId: initialListingPrice.id,
              }}
              selected={selectedListingPrice?.id === initialListingPrice?.id}
            />

            <TemporaryLinkListingOptions
              listingOptions={biggerOptions}
              label="Listing Upgrade Options"
              type={ListingItemsTypeValues.DRAFT}
              setPreviewListingForPrice={setPreviewListingPrice}
              handleChangeListingSelection={handleChangeListingSelection}
            />

            <TemporaryLinkListingOptions
              label="Other Options"
              listingOptions={smallerOptions}
              type={ListingItemsTypeValues.DRAFT}
              setPreviewListingForPrice={setPreviewListingPrice}
              handleChangeListingSelection={handleChangeListingSelection}
            />
          </FtsContainer>
        </FtsWrapper>
        <DraftFooterButtons />
      </TemporaryLinkContentContainer>
      <TemporaryLinkPreviewDraftListingModals
        showServicesModal={showServicesModal}
        showAmenitiesModal={showAmenitiesModal}
        setShowServicesModal={setShowServicesModal}
        showDescriptionModal={showDescriptionModal}
        openDescriptionModal={openDescriptionModal}
        setShowAmenitiesModal={setShowAmenitiesModal}
        previewListingForPrice={previewListingPrice}
        setShowDescriptionModal={setShowDescriptionModal}
        closePreviewListingModal={closePreviewListingModal}
        setPreviewListingForPrice={setPreviewListingPrice}
        handleChangeListingSelection={handleChangeListingSelection}
      />
      {showFreeListingNoticeModal && (
        <FreeListingNoticeModal
          size={"sm"}
          isOpen={showFreeListingNoticeModal}
          onError={handleGoWithFreeListing}
          onClose={() => {
            dispatch(
              setTemporaryLinkShowFreeListingModalNotice({ data: false }),
            );
          }}
          onSuccess={() => {
            dispatch(
              setTemporaryLinkShowFreeListingModalNotice({ data: false }),
            );
          }}
          onBackdropClick={() => {
            dispatch(
              setTemporaryLinkShowFreeListingModalNotice({ data: false }),
            );
          }}
          errorIsLoading={isLoading}
          successDisabled={isLoading}
        />
      )}
    </>
  );
};

export default TemporaryLinkPreviewDraftListing;
