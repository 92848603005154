import React, { Component } from "react";
import { Row, Col, FormGroup } from "reactstrap";
import { connect } from "react-redux";

import FormikCheckbox from "../../../../components/fts/formik/checkbox";

import "../../../../assets/scss/components/fts/form-fields.scss";

const filterAmenities = (data, searchStr) => {
  const searchString = searchStr.toLowerCase();

  return data.filter(({ name: amenityName }) => {
    const name = amenityName.toLowerCase();
    return name.startsWith(searchString) || name.includes(searchString);
  });
};

class Amenities extends Component {
  state = {
    searchString: "",
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.searchString !== nextState.searchString ||
      (!this.props.createMode && this.props.editMode !== nextProps.editMode)
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      amenities: allAmenities,
      serviceAmenities: allServiceAmenities,
      selectedAmenities,
      editMode,
      areSearchable,
      areService,
      changedServiceAmenities,
      right,
      changedAmenities,
    } = this.props;
    let selectedAmenitiesIDs = [];
    if (selectedAmenities) {
      selectedAmenities.map((amenitiy) => {
        if (amenitiy.id) {
          return selectedAmenitiesIDs.push(amenitiy.id);
        } else {
          return selectedAmenitiesIDs.push(amenitiy);
        }
      });
    }

    const { searchString } = this.state;

    const amenities =
      areSearchable && !areService // && searchString.length
        ? filterAmenities(allAmenities, searchString)
        : allAmenities;

    const serviceAmenities =
      areSearchable && areService // && searchString.length
        ? filterAmenities(allServiceAmenities, searchString)
        : allServiceAmenities;
    let amenitiesData;
    let namePrefix;

    if (areService) {
      amenitiesData = serviceAmenities.filter((item) => {
        return item.status;
      });
      namePrefix = "serviceAmenities.serviceAmenity_";
    } else {
      amenitiesData = amenities.filter((item) => {
        return item.status;
      });
      namePrefix = "amenities.amenity_";
    }

    amenitiesData &&
      amenitiesData.sort(function (a, b) {
        return a.id - b.id;
      });
    return (
      <React.Fragment>
        {areSearchable && (
          <FormGroup>
            <label>{`Search ${areService ? "service " : ""}amenities`}</label>
            <input
              className="form-control"
              value={searchString}
              onChange={(e) => this.setState({ searchString: e.target.value })}
              placeholder="Search"
            />
          </FormGroup>
        )}
        <Row>
          {amenitiesData &&
            amenitiesData.map(({ id, name: label }) => {
              return editMode ? (
                <Col md="6" lg="4" key={id}>
                  <FormGroup
                    style={{
                      paddingTop: 3,
                      paddingBottom: 5,
                      paddingLeft: 0,
                    }}
                    check
                  >
                    <FormikCheckbox
                      name={`${namePrefix}${id}`}
                      label={label}
                      color="primary"
                      disabled={!editMode}
                    />
                  </FormGroup>
                </Col>
              ) : selectedAmenitiesIDs.includes(id) ? (
                <Col md="6" lg="4" key={id}>
                  <FormGroup
                    style={{
                      paddingTop: 3,
                      paddingBottom: 5,
                      paddingLeft: 0,
                    }}
                    check
                    className={`${
                      right &&
                      ((changedServiceAmenities?.includes(id) && areService) ||
                        changedAmenities?.includes(id))
                        ? "changed-checkbox"
                        : null
                    }`}
                  >
                    <FormikCheckbox
                      name={`${namePrefix}${id}`}
                      label={label}
                      color="primary"
                      disabled={!editMode}
                    />
                  </FormGroup>
                </Col>
              ) : null;
            })}
          {!amenitiesData?.length && <span className="ml-auto mr-auto my-1">No results for this specific search.</span>}
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    amenities: state.essentialLists.amenities,
    serviceAmenities: state.essentialLists.serviceAmenities,
  };
};

export default connect(mapStateToProps)(Amenities);
