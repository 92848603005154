import {
  SET_COMPLETE_REGISTRATION_DATA,
  SET_COMPLETE_REGISTRATION_INVALID_FORM_FIELDS,
  COMPLETE_REGISTRATION_GET_INVITATION_REQUEST,
  COMPLETE_REGISTRATION_GET_INVITATION_FAILED,
  COMPLETE_REGISTRATION_GET_INVITATION_SUCCESS,
  COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST_FAILED,
  COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST_SUCCESS,
  COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST,
} from "../../../reducers/v2/completeRegistration";

export const completeRegistrationGetInvitationRequest = (payload) => ({
  type: COMPLETE_REGISTRATION_GET_INVITATION_REQUEST,
  payload,
});

export const completeRegistrationGetInvitationRequestSuccess = (payload) => ({
  type: COMPLETE_REGISTRATION_GET_INVITATION_SUCCESS,
  payload,
});

export const completeRegistrationGetInvitationRequestFailed = (payload) => ({
  type: COMPLETE_REGISTRATION_GET_INVITATION_FAILED,
  payload,
});

export const setCompleteRegistrationData = (payload) => ({
  type: SET_COMPLETE_REGISTRATION_DATA,
  payload,
});

export const setCompleteRegistrationInvalidFormFields = (payload) => ({
  type: SET_COMPLETE_REGISTRATION_INVALID_FORM_FIELDS,
  payload,
});

export const completeRegistrationUpdatePasswordRequest = (payload) => ({
  type: COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST,
  payload,
});

export const completeRegistrationUpdatePasswordRequestFailed = (payload) => ({
  type: COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST_FAILED,
  payload,
});

export const completeRegistrationUpdatePasswordRequestSuccess = (payload) => ({
  type: COMPLETE_REGISTRATION_UPDATE_PASSWORD_REQUEST_SUCCESS,
  payload,
});
