import React from "react";
import {
  CardBody,
  FormGroup,
  Form,
  Alert,
  CustomInput,
  Button,
  Spinner,
} from "reactstrap";

import { connect } from "react-redux";
import { updatePassword } from "../../../../redux/actions/auth/registerActions";
import { insertUserPermissionsRequest } from "../../../../redux/actions/v2/auth";

import Icon from "../../../../components/fts/Icons";
// import StatesPicker from "../../../../components/fts/states-picker";
// import Select from "react-select";

import { TextField, styled } from "@material-ui/core";
// import { styled } from "@mui/system";
import ReCAPTCHA from "react-google-recaptcha";

import { Formik } from "formik";
import * as Yup from "yup";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "../../../../assets/scss/components/fts/register-form.scss";
import { toast } from "react-toastify";
import { history } from "../../../../history";

const CssTextField = styled(TextField)(() => ({
  input: {
    color: "#32383A",
    padding: "10px 5px",
  },
  "& border": {
    color: "#32383A",
  },
  "& label": {
    color: "#32383A",
  },
  "& label.Mui-focused": {
    color: "#32383A",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#32383A",
  },

  "& .MuiInput-underline:before": {
    borderBottomColor: "#32383A !important",
  },

  "& .MuiInput-underline:hover": {
    borderBottomColor: "#32383A",
  },
}));

const styles = () => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: 80,
  },
  selectEmpty: {
    marginTop: 16 * 2,
  },
});

class FinishRegisterForm extends React.Component {
  state = {
    inProgress: false,
    error: null,
  };

  recaptchaRef = React.createRef();
  formRef = React.createRef();

  initialData = {
    password: "",
    repeatPassword: "",
    primaryMarketingOptIn: true,
  };

  validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=(?:.*[a-z]))(?=(?:.*[A-Z]))(?=(?:.*[0-9]))(?=(?:.*[!@#$%^&*()_+=\-{}|":;'?/.>,<])).{7,}$/,
        "Password must be 7 or more digits with at least 1 number and 1 special character.",
      )
      .required("Password is required!"),
    repeatPassword: Yup.string()
      .required("Confirm password is required!")
      .oneOf([Yup.ref("password"), null], "Passwords do not match"),
  });

  onChangeRecaptcha = () => {
    this.setState({ recaptchaError: "" });
  };

  handleSubmit = (values) => {
    const rechaptchaValue = this.recaptchaRef.current.getValue();
    if (!rechaptchaValue) {
      this.setState({
        recaptchaError: "Please confirm that you're not a robot",
      });
      return;
    }

    this.setState({ inProgress: true });

    let payload = {
      password: values.password,
    };
    const hash = window.location.pathname.split("/").pop();
    this.props.updatePassword(
      payload,
      this.onError,
      hash,
      this.props.isDriverInvitation,
      this.onSuccess,
    );
  };

  onError = ({ response }) => {
    this.setState({
      error: {
        status: response?.status,
        message:
          response?.data?.errors?.message ||
          response?.data?.message ||
          `Failed to login: ${
            response?.status === 500 ? "Connection Error" : "Server Error"
          }.`,
      },
      inProgress: false,
    });
  };

  onSuccess = () => {
    this.props.insertUserPermissionsRequest({
      onSuccess: () => {
        history.push("/dashboard/on-boarding");
      },
      onError: () => {
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
    });
  };

  render() {
    const { error, recaptchaError } = this.state;
    return (
      <div style={{ maxWidth: 400 }}>
        {!!error && (
          <CardBody className="p-1">
            <Alert color="danger">{error.message}</Alert>
          </CardBody>
        )}

        <Formik
          innerRef={this.formRef}
          initialValues={this.initialData}
          validationSchema={this.validationSchema}
          onSubmit={this.handleSubmit}
        >
          {({
            touched,
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <>
              <Form onSubmit={handleSubmit}>
                <FormGroup className="register-form-label-group position-relative pt-2">
                  <CssTextField
                    label="Password"
                    type="password"
                    id="password"
                    fullWidth
                    variant="standard"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  {errors.password && touched.password ? (
                    <div className="FTS-Registration-error">
                      {errors.password}
                    </div>
                  ) : null}
                </FormGroup>

                <FormGroup className="register-form-label-group position-relative">
                  <CssTextField
                    label="Confirm password"
                    variant="standard"
                    type="password"
                    id="repeatPassword"
                    value={values.repeatPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />
                  {errors.repeatPassword && touched.repeatPassword ? (
                    <div className="FTS-Registration-error">
                      {errors.repeatPassword}{" "}
                    </div>
                  ) : null}
                </FormGroup>

                <FormGroup className="position-relative pt-2">
                  <CustomInput
                    defaultChecked={true}
                    type="switch"
                    id="primaryMarketingOptIn"
                    inline
                    onChange={handleChange}
                  >
                    <span className="ml-1 font-size-1p1">
                      Get email updates and news
                    </span>
                  </CustomInput>
                </FormGroup>

                <div className="FTS-robot-check pt-4">
                  <p>Security Check</p>
                  <span className="mb-1">
                    {"Just making sure you're not a robot 🙂"}
                  </span>
                  <span className="mb-1 FTS-Registration-error">
                    {recaptchaError}
                  </span>
                  <ReCAPTCHA
                    ref={this.recaptchaRef}
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={this.onChangeRecaptcha}
                  />
                  <span className={"pt-2"}>
                    {`By clicking on the "Arrow" button below you confirm that you agree to the Find Truck Service Terms
                    of Use and Privacy Policy.`}
                  </span>
                </div>

                <Button
                  color="primary"
                  type="submit"
                  className="FTS-register-btn"
                >
                  {this.state.inProgress ? (
                    <Spinner size={"sm"} />
                  ) : (
                    <Icon
                      name="ArrowRightLG"
                      stroke={"#fff"}
                      color={"#fff"}
                      size={38}
                    />
                  )}
                </Button>
              </Form>
            </>
          )}
        </Formik>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    states: state.essentialLists.states,
  };
};

FinishRegisterForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
  updatePassword,
  insertUserPermissionsRequest,
})(withStyles(styles)(FinishRegisterForm));
