import React from "react";
import { useSelector } from "react-redux";
import { setTemporaryLinkEditListingData } from "../../../../../redux/actions/v2/temporaryLinkListing";
import { ServiceAmenitiesModalContentFields } from "../../components/ServiceAmenitiesModalContentFields";
import { selectTemporaryLinkListingAdsServiceAmenities } from "../../../../../redux/reducers/v2/temporaryListingLink";

export const TemporaryLinkServiceAmenitiesModalContent = () => {
  const selectedServiceAmenities = useSelector(
    selectTemporaryLinkListingAdsServiceAmenities,
  );

  return (
    <ServiceAmenitiesModalContentFields
      setListingData={setTemporaryLinkEditListingData}
      selectedServiceAmenities={selectedServiceAmenities || []}
    />
  );
};
