import styled from "styled-components";
import { outlineLinkStyle, linkStyle } from "./constants/style";
import { defaultProps, defaultStyleProps } from "@find-truck-service/ui/src/default";
const logoStyle = "box-sizing: border-box;";
export const FtsLogoLink = styled.a.withConfig({
    shouldForwardProp: (prop) => !defaultProps.includes(prop),
}) `
  ${outlineLinkStyle};
  ${linkStyle};
  ${logoStyle};
  ${defaultStyleProps};
`;
